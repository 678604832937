import { Button, Col, Dropdown, Form, Input, Menu, message, Radio, Row, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import SubHeader from '../Header/Header';
import type { RadioChangeEvent } from 'antd';
import './PutAway.scss'
import AppButtons from '../../CustomComponent/AppButtons';
import { CloseOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import BoxIcon from '../../Images/BoxIcon';
import StorageStatusIcon from '../../Images/StorageStatusIcon';
import { getDashboardMenuId, getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import { useDispatch, useSelector } from 'react-redux';
import { getBarCodeCompleteDataAction, getPutawayAssignMoverAction, getPutawayHeaderAction, getReceivingInProgressBarCodeAction, startPutawayAcyion } from '../../Store/Actions/ProcessWMSAction';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import { isArrayEmpty, isArrayNotEmpty } from '../../Globals/Helper';
import AppReferences from '../../Globals/AppReferences';
import { height } from '@mui/system';
import WMSIcon4 from '../../Images/WMSIcon4';

const { Option } = Select;
const barCodeItem = [
    {
        key: 1,
        storageType: 'F'
    }, {
        key: 2,
        storageType: 'G'
    }, {
        key: 3,
        storageType: 'C'
    }, {
        key: 4,
        storageType: 'F'
    }, {
        key: 5,
        storageType: 'C'
    }
]

function PutAwayViewScreen() {
    const dispatch = useDispatch();
    let warehouse = getWareHouseId();
    const loactionProps = useLocation().state;
    const item = loactionProps?.item;
    const [value, setValue] = useState();
    const [radioValue, setRadioValue] = useState('Good');
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const {
        generatePutawayHeaderInfo,
        assignMoverInfo,
        assignMoverOnLoad,
        barCodeScanSuccessData,
        barCodeScanOnLoad,
        startPutawayOnLoad,
        barCodeMsg
    } = reducerState;
    const [assigningDetails, setAssigningDetails] = useState<any>({})
    const [onLoad, setOnLoad] = useState<any>({
        load: false,
        scanView: false,
        putaway: false,
        scanOnload: false,
        viewBox: false
    });
    const [barCode, setBarCode] = useState("");
    const navigate = useNavigate();
    const [form]: any = Form.useForm();

    useEffect(() => {
        let custOpnfor = (item?.id).split("|")
         if(custOpnfor[1] == 540100 ){
            getPutawayHeader();
            if (getDashboardMenuId() == 540200) { //this is to handle assignment pending screen. this screen used for both generate putaway and assignment pending
                setOnLoad({ ...onLoad, scanView: true })
            }
        }
        else{
            gettableData()
            if (getDashboardMenuId() == 540200) { //this is to handle assignment pending screen. this screen used for both generate putaway and assignment pending
                setOnLoad({ ...onLoad, scanView: true })
            }
        }
    }, []);

    const gettableData = () =>{
        try {
            let custOpnfor = (item?.id).split("|")
            let payload = {
                "activity": AppReferences.activities.av,
                "opnfor": custOpnfor[1],
                "customer": custOpnfor[0],
                "transaction": custOpnfor[2],
                "tableNo": custOpnfor[4],
                "user": getUserInfo()?.UserName,
                "warehouse": warehouse,
                "where": custOpnfor[3]
            }
            dispatch(getPutawayHeaderAction(payload))
        } catch (error) {
            console.log("Error in getPutawayHeader::", error)
        }
    }

    const getPutawayHeader = () => {
        try {
            let custOpnfor = (item?.id).split("|")
            let payload = {
                "activity": AppReferences.activities.av,
                "opnfor": custOpnfor[1],
                "customer": custOpnfor[0],
                "transaction": custOpnfor[2],
                "tableNo": custOpnfor[4],
                "user": getUserInfo()?.UserName,
                "warehouse": warehouse,
                "where": custOpnfor[3],
               "fieldArray": getUserInfo()?.CorporateID
            }
            dispatch(getPutawayHeaderAction(payload))
        } catch (error) {
            console.log("Error in getPutawayHeader::", error)
        }
    }

    useEffect(() => {
        if (onLoad?.load && !assignMoverOnLoad) {
            setOnLoad({ ...onLoad, scanView: true, load: false });
        }
    }, [onLoad.load, assignMoverOnLoad])

    const assignMover = () => {
        let custOpnfor = (item?.id).split("|")
        let getpayloadItem = generatePutawayHeaderInfo?.header[0]
        let array = getpayloadItem?.ID + '|' + getpayloadItem?.RECORDNO + '|' + assigningDetails?.sysLocType + '|' + assigningDetails?.strType + '|' + assigningDetails?.assignedTo + '|' + assigningDetails?.palletID;
        let payload = {
            "opnfor": custOpnfor[1],
            "activity": AppReferences.activities.ax01, // Create PutawayHeader
            "fieldarray": array,
            "transaction": getpayloadItem?.ino,
            "warehouse": warehouse,
            "customer": getpayloadItem?.cid,
            "user": getUserInfo()?.UserName,
        }
        dispatch(getPutawayAssignMoverAction(payload))
        setOnLoad({ ...onLoad, load: true })
    }

    const scanBarcode = (barcode: any) => {
        let dataList = generatePutawayHeaderInfo?.lineitemstwo;
        let getpayloadItem = generatePutawayHeaderInfo?.header[0]
        // let checkBarCodeMatch = dataList?.find((item: any) => item?.Barcode === barcode);
        let PAWAYHRECNO = assignMoverInfo?.header[0]?.PAWAYHRECNO
        let transactionData = PAWAYHRECNO + "|" + barcode + "|" + getUserInfo()?.UserName;
        //let containString = dataList?.find(({ Barcode }: any) => Barcode?.includes(barcode));
        let payload = {
            "activity": "A-01",
            "warehouse": warehouse,
            "user": getUserInfo()?.UserName,
            "opnfor": assignMoverInfo?.title?.stageNo,
            "transaction": transactionData,
            "where": getpayloadItem?.ino,
            "customer": getpayloadItem?.cid,
        }
        dispatch(getBarCodeCompleteDataAction(payload))
        setOnLoad({ ...onLoad, scanOnload: true })
        // if (checkBarCodeMatch) {
        //     let payload = {
        //         "activity": "A-01",
        //         "warehouse": warehouse,
        //         "user": getUserInfo()?.UserName,
        //         "opnfor": assignMoverInfo?.title?.stageNo,
        //         "transaction": transactionData,
        //         "where": getpayloadItem?.ino,
        //         "customer": getpayloadItem?.cid,
        //     }
        //     dispatch(getBarCodeCompleteDataAction(payload))
        //     setOnLoad({ ...onLoad, scanOnload: true })
        // } else if (!containString) {
        //     message.error("Wrong Barcode scanned");
        // }
    }


    useEffect(() => {
        if (onLoad.scanOnload === true && barCodeScanOnLoad === false) {
            setBarCode("");
            getPutawayHeader();
            setOnLoad({ ...onLoad, viewBox: true, scanOnload: false })
        }

    }, [onLoad.scanOnload, barCodeScanOnLoad])

    useEffect(() => {
        if (onLoad.putaway && startPutawayOnLoad === false) {
            navigate(-1);
            setOnLoad({ ...onLoad, putaway: false })
        }
    }, [onLoad.putaway, startPutawayOnLoad])

    const startPutaway = () => {
        let getpayloadItem = generatePutawayHeaderInfo?.header[0]
        let PAWAYHRECNO = assignMoverInfo?.header[0]?.PAWAYHRECNO
        let payload = {
            "opnfor": assignMoverInfo?.title?.stageNo,
            "activity": "AX-01",
            "user": getUserInfo()?.UserName,
            "warehouse": warehouse,
            "customer": getpayloadItem?.cid,
            "transaction": PAWAYHRECNO
        }
        dispatch(startPutawayAcyion(payload))
        setOnLoad({ ...onLoad, putaway: true })
    }

    const onChange = (e: any) => {
        setValue(e.target.value);
    }

    const barList = () => {
        try {
            return (
                <div className={onLoad.scanView === true ? 'bar-listing-container-scroll' : 'bar-listing-container height'}>
                    {isArrayNotEmpty(generatePutawayHeaderInfo?.lineitemstwo) ?
                        <Radio.Group
                            buttonStyle="solid"
                            onChange={onChange}
                            value={value} >
                            {(generatePutawayHeaderInfo?.lineitemstwo || []).map((item: any, index: any) => {
                                let left_align = (item?.SkuQuantity)?.length;
                                return (
                                    <Radio.Button value={index}>
                                        <div
                                            title={item?.Barcode}
                                            className={'barcode-button'}>
                                            <div className='d-flex align-c jc-sa'>
                                                <>
                                                    {(item?.Lines || []).map((x: any) => {
                                                        return (
                                                            <>
                                                                {x?.Condition && x?.Condition === "Good" &&
                                                                    <div className={item?.BinID === null ? 'd-flex align-c bin-container-green' : 'd-flex align-c movebin-container-green'}>
                                                                        {x?.Qty}
                                                                    </div>
                                                                }{x?.Condition && x?.Condition === "Bad" &&
                                                                    <div className={item?.BinID === null ? 'd-flex align-c bin-container-red-1' : 'd-flex align-c movebin-container-red'}>
                                                                        {x?.Qty}
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                    })}
                                                </>
                                                {item?.BinID === null ?
                                                    <div className='rip-count' style={{ marginLeft: 5 }} >{item?.SequenceNo + "/" + item?.RecievedBoxQty}</div> :
                                                    <div style={{ fontSize: 16, fontFamily: "redHat-bold" }}>{item?.BinID}</div>
                                                }
                                            </div>
                                            <div className='d-flex align-c jc-sa'>
                                                <div className='storage-type'>
                                                    {item?.StorageType}
                                                </div>
                                            </div>
                                        </div>
                                        {/* {getBarCode(item)} */}
                                    </Radio.Button>
                                )
                            })}
                        </Radio.Group> :
                        <div className='Box-info' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Box Not Available</div>
                    }
                </div >
            )
        } catch (ex) {
            console.log("Error in barList::" + ex)
        }
    }

    const formView = () => {
        try {
            return (
                <>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={AppConstants.storageType}
                                name='storageType'
                                required
                                rules={
                                    [{ required: true, message: "Select Storage Type" }]
                                }
                            >
                                <Select
                                    placeholder="Select"
                                    className='select-field'
                                    onChange={(e) => setAssigningDetails({ ...assigningDetails, sysLocType: e })}
                                >
                                    {(generatePutawayHeaderInfo?.StorageType || []).map((item: any) => {
                                        return (
                                            <Option value={item?.SystemLocationTypeID}>{item?.SystemType}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={24} style={{ marginTop: 15 }}>
                            <Form.Item
                                label={AppConstants.structureType}
                                name='structureType'
                                required
                                rules={
                                    [{ required: true, message: "Select Structure Type" }]
                                }
                            >
                                <Select
                                    placeholder="Select"
                                    className='select-field'
                                    onChange={(e) => setAssigningDetails({ ...assigningDetails, strType: e })}
                                >
                                    {(generatePutawayHeaderInfo?.StructureType || []).map((item: any) => {
                                        return (
                                            <Option value={item?.StructureTypeID}>{item?.StructureType}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ marginTop: 15 }}>
                            <Form.Item
                                label={AppConstants.palleteId}
                                name='palleteId'
                                required
                                rules={
                                    [{ required: true, message: "Select pallete Id" }]
                                }
                            >
                                <Select
                                    placeholder="Select"
                                    className='select-field'
                                    onChange={(e) => setAssigningDetails({ ...assigningDetails, palletID: e })}
                                >
                                    {(generatePutawayHeaderInfo?.PalletList || []).map((item: any) => {
                                        return (
                                            <Option value={item?.PalleteID}>{item?.PalleteName}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginTop: 15 }}>
                            <Form.Item
                                label={AppConstants.assignToMover}
                                name='assignToMover'
                                required
                                rules={
                                    [{ required: true, message: "Select assign to mover" }]
                                }
                            >
                                <div className="assignToMover-container">
                                    <Select
                                        placeholder="Select"
                                        className='select-field'
                                        onChange={(e) => setAssigningDetails({ ...assigningDetails, assignedTo: e })}
                                    >
                                        {(generatePutawayHeaderInfo?.MoverList || []).map((item: any) => {
                                            return (
                                                <Option value={item?.UserID}>
                                                    <div style={{ display: "flex", alignItems: "center" }} className="user-container">
                                                        <div className="user-img">
                                                            <img src={AppImages.userImg} alt="user-Img" />
                                                        </div>
                                                        <span className="manager-name" style={{ marginLeft: 10 }}>{item?.Name}</span>
                                                    </div>
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )
        } catch (ex) {
            console.log("Err in formView::" + ex)
        }
    }

    const scanBarcodeandSelect = (e: any) => {
        setBarCode((e?.target?.value)?.toUpperCase());
        scanBarcode((e?.target?.value)?.toUpperCase());
    }

    const contentView = () => {
        // let assignedInfo = getDashboardMenuId() == 540200 ?  generatePutawayHeaderInfo?.lineitems[0] : assignMoverInfo?.lineitems[0]; //condition is for assignment pending screen
        let assignedInfo = assignMoverInfo?.lineitems[0];
        try {
            return (
                <div className='put-away-view-container'>
                    <Row>
                        {getDashboardMenuId() != 540200 && <Col span={4}>
                            {(onLoad.scanView === true) &&
                                <div className='table-input-container' style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 10 }}>
                                    <Input
                                        value={barCode}
                                        // onBlur={(e) => (e?.target?.value) !== "" && scanBarcode()}	
                                        onPaste={(e: any) =>(e?.target?.value) !== "" && scanBarcodeandSelect(e)}
                                        onChange={(e: any) =>(e?.target?.value) !== "" && scanBarcodeandSelect(e)}
                                        placeholder='Scan Box-Sticker barcode'
                                        style={{ marginRight: 10 }}
                                        autoFocus
                                    />
                                </div>
                            }
                            {barList()}
                        </Col>}
                        {!onLoad.scanView &&
                            <Col span={20} style={{ padding: 10 }}>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <div style={{ height: "100%", width: "100%" }} className="content-container-put-away">
                                            <div className="create-content-view-container" style={{ padding: 20, height: "100%", width: '100%' }}>
                                                <Form
                                                    id='form'
                                                    form={form}
                                                    autoComplete="off"
                                                    noValidate
                                                    layout="vertical"
                                                >
                                                    {formView()}

                                                </Form>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={16} >
                                    </Col>
                                </Row>
                            </Col>
                        }
                        {onLoad.scanView &&
                            <Col span={20} style={{ padding: 20 }}>
                                <Row gutter={16}>
                                    <Col span={8} className="selected-option">
                                        <Row gutter={16} >
                                            <Col span={24} style={{ padding: 20 }}>
                                                <div className='title'>{AppConstants.storageType}</div>
                                                <div className='put-away-id-container '>
                                                    {assignedInfo?.SYSLOCTYPE}
                                                </div>
                                            </Col>
                                            <Col span={24} style={{ padding: 20 }}>
                                                <div className='title'>{AppConstants.structureType}</div>
                                                <div className='put-away-id-container'>
                                                    {assignedInfo?.STRTYPE}
                                                </div>
                                            </Col>
                                            <Col span={24} style={{ padding: 20 }}>
                                                <div className='title'>{AppConstants.palleteId}</div>
                                                <div className='put-away-id-container'>
                                                    {assignedInfo?.PALLETID}
                                                </div>
                                            </Col>
                                            <Col span={24} style={{ padding: 20 }}>
                                                <div className="assignToMover-container">
                                                    <div className='title'>
                                                        {AppConstants.assignToMover}
                                                    </div>
                                                    <div className='put-away-id-container'>
                                                        {assignedInfo?.ASSIGNEDTO}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={16} >
                                        <div className='scanned-box-list-container'>
                                            <div className='scanned-box-title'>
                                                <div>Scanned Box</div>
                                                <div>Pallete ID:<span>{assignedInfo?.PALLETID}</span></div>
                                            </div>
                                            {onLoad.viewBox === true &&
                                                <Row className='box-container' gutter={20}>
                                                    {(barCodeScanSuccessData?.lineitemstwo || []).map((item: any) => {
                                                        return (
                                                            <Col span={7}>
                                                                <div
                                                                    className={'Scanned-barcode-button'}>
                                                                    <div className='d-flex align-c jc-sa'>
                                                                        <>
                                                                            {(item?.Lines || []).map((x: any) => {
                                                                                return (
                                                                                    <>
                                                                                        {x?.Condition && x?.Condition === "Good" &&
                                                                                            <div className={item?.BinID === null ? 'd-flex align-c bin-container-green' : 'd-flex align-c movebin-container-green'}>
                                                                                                {x?.Qty}
                                                                                            </div>
                                                                                        }{x?.Condition && x?.Condition === "Bad" &&
                                                                                            <div className={item?.BinID === null ? 'd-flex align-c bin-container-red' : 'd-flex align-c movebin-container-red'}>
                                                                                                {x?.Qty}
                                                                                            </div>
                                                                                        }
                                                                                    </>

                                                                                )
                                                                            })}
                                                                        </>
                                                                        {item?.BinID === null ?
                                                                            <div className='rip-count' style={{ marginLeft: 5 }} >{item?.SequenceNo + "/" + item?.RecievedBoxQty}</div> :
                                                                            <div style={{ fontSize: 16, fontFamily: "redHat-bold" }}>{item?.BinID}</div>
                                                                        }
                                                                    </div>
                                                                    <div className={item?.BinID === null ? 'storage-type' : ' storage-type mt-0'}>
                                                                        {item?.StorageType}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Row >
                </div >
            )
        } catch (ex) {
            console.log("Error in contentView::" + ex)
        }
    }

    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                {Object?.keys(assigningDetails)?.length == 4 &&
                    <Button
                        className='add-boxes-btn'
                        disabled={onLoad.scanView}
                        onClick={() => assignMover()}
                        // type={AppConstants.buttonTypes.solidLinearGreen}
                        type='primary'
                    >
                        Add Boxes
                    </Button>
                }
                {(onLoad.viewBox === true && barCodeMsg === "Box Scanned Successfully !") &&
                    <div style={{ marginLeft: 5 }}>
                        <Button
                            className='add-boxes-button'
                            // disabled={!scanView}
                            onClick={() => startPutaway()}
                            type="primary"
                        >Start Putaway
                        </Button>
                    </div>
                }
                <Link to="/process/put-away">
                    <Button className="close-btn">
                        <CloseOutlined />
                    </Button>
                </Link>
            </div>
        )
    }
    return (
        <>
            {/* <NavbarLayout
                titleContent="sub-head"
                process={assignMoverInfo?.title ? assignMoverInfo?.title : generatePutawayHeaderInfo?.title}
            /> */}
            <SubHeader
                title={
                    <div className='d-flex align-c'>
                        <Link to={"/process/SAN"}>
                            <div className='c-p'>
                                <WMSIcon4 />
                            </div>
                        </Link>
                        <div className='ml-10'>
                            {'Create Putaway Task'}
                        </div>
                    </div>
                }
                btnView="true"
                inputView="true"
                paginationView="false" >
                {childrenView()}
            </SubHeader>
            <div>
                {contentView()}
            </div>
        </>
    );
}

export default PutAwayViewScreen;