import { Button, Form, Input, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import Email from '../../Images/Email';
import Password from '../../Images/Password';
import googleLogin from '../../Images/google-signin-button.png';

import "./Login.scss"
import Google from '../../Images/Google';
import { useDispatch, useSelector } from 'react-redux';
import { clearStorage, setMenuName, setUserInfo, setWareHouseId, setWarehouseInfo } from '../../Utils/SessionStorage';
import { Amplify, Auth, Hub } from "aws-amplify";
import axios from 'axios'
import awsExports from '../../aws-exports';
import { LoadingOutlined } from '@ant-design/icons';
import { getWarehousesAction } from '../../Store/Actions/ProcessWMSAction';
import AppReferences from '../../Globals/AppReferences';
import WMSIcon from '../../Images/WMSIcon';
let warehouse = "";
const SURL = 'https://bo3m77km31.execute-api.ap-south-1.amazonaws.com/dev/operations';

const signInURI = awsExports.oauth.redirectSignIn.split(',')
const signOutURI = awsExports.oauth.redirectSignOut.split(',')
const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

if (isLocalhost) {
    awsExports.oauth.redirectSignIn = signInURI[0];
    awsExports.oauth.redirectSignOut = signOutURI[0];
} else if (window.location.hostname === 'https://main.d211rykl745cll.amplifyapp.com/') {
    awsExports.oauth.redirectSignIn = signInURI[1]
    awsExports.oauth.redirectSignOut = signOutURI[1]
} else {
    console.log('This is not possible')
}
Amplify.configure(awsExports);

function Login() {
    const dispatch = useDispatch();
    const [loginForm] = Form.useForm();
    const [errorMsg, setErrorMsg] = useState("");
    const [onload, setOnload] = useState(false);
    const [selectWarehouse, setSelectWarehouse] = useState(false);
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const [corporateName, setCorporateName] = useState<any>("");
    const { warehouseList } = reducerState;
    const [warehouseOnload, setWarehouseonload] = useState(false);
    const [loginInputs, setLoginInputs] = useState<any>({
        email: null,
        password: null
    });
    const clientdomain: any = window.location.hostname?.split('.')[0];
    let name = (clientdomain == 'demo' || clientdomain == 'localhost') ? "360wms" : clientdomain;
    const logoSrc = `${process.env.REACT_APP_MEDIA_PREFIX}corporates/${name}.png`;

    useEffect(() => {
        if (warehouseOnload && !reducerState.onload) {
            try {
                setWarehouseonload(false);
                if (warehouseList?.length > 1) {
                    if (name === corporateName?.toLowerCase()) {
                        setSelectWarehouse(true);
                    } else {
                        message.error(`User id doesn't belongs to this ${name}`)
                        // window.location.reload()
                        loginForm.setFieldsValue({
                            ['email']: '',
                            ['password']: ''

                        })
                    }

                }
                else {
                    if (name === corporateName?.toLowerCase()) {
                        let warehouse = warehouseList && warehouseList[0];
                        console.log("warehousewarehouse",warehouse)
                        setWarehouseInfo(warehouse);
                        setWareHouseId(warehouse?.id);
                        setMenuName('WMS');
                        window.location.href = "/";
                    } else {
                        message.error(`User id doesn't belongs to this ${name}`)
                        // window.location.reload()
                        loginForm.setFieldsValue({
                            ['email']: '',
                            ['password']: ''

                        })
                    }
                }
            } catch (error) {
                console.log("Error in setWareHouseId::", error);
            }
        }
    }, [warehouseOnload, reducerState.onload])

    useEffect(() => {
        const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }: any) => {
            switch (event) {
                case 'signIn':
                    console.log("Hub listener", data);
                    checkUser();
                    break
                case 'signOut':
                    clearStorage()
                    break
                case "customOAuthState":
                    console.log("customAuthData", data)
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    setOnload(false);
                    setErrorMsg("Incorrect username or password.");
                    break;
                default:
                    break
            }
        })

        // Auth.currentAuthenticatedUser()
        //   .then(currentUser => setUser(currentUser))
        //   .catch(() => console.log("Not signed in"));
        return unsubscribe;
    }, []);

    const getWarehouses = (user: any, role: any, CorporateID: any) => {
        let payload = {
            "opnfor": "UserWH",
            "activity": AppReferences.activities.a01,
            "user": user,
            "fieldArray": role,
            "where": CorporateID
        }
        dispatch(getWarehousesAction(payload));
        setWarehouseonload(true)
    }

    const isUserAuthorized = async (email: any) => {
        try {
            const handlePromise = async () => {
                let header = {
                    "Content-Type": "application/json",
                    opnfor: "LoginCheck",
                    transaction: `${email}`
                }
                const response = await axios.get(
                    SURL, {
                    headers: header
                });
                const userData = response;
                setCorporateName(userData.data.body.CorporateName)
                console.log("userData", userData, userData.data.body.CorporateName)
                if (userData.data.msg !== '') {
                    // User not found
                    clearStorage()
                    Auth.signOut();
                    return false;
                } else {
                    let userDetails = {
                        openApp: true,
                        email: email,
                        RoleDescription: userData.data.body.Role,
                        roleId: userData.data.body.RoleID,
                        UserName: userData.data.body.UN,
                        FirstName: userData.data.body.UserName,
                        LandingPage: userData.data.body.LandingPage,
                        isMultipleWH: userData.data.body.isMultipleWH,
                        CorporateID: userData.data.body.CorporateID,
                        CorporateName: userData.data.body.CorporateName
                    }
                    getWarehouses(userDetails?.UserName, userDetails?.roleId, userDetails?.CorporateID);
                    setUserInfo(userDetails);
                    setOnload(false)
                    return true;
                }
            }
            handlePromise();
        } catch (ex) {
            console.log("Error in isUserAuthorized::" + ex);
        }
    }

    const checkUser = async () => {
        const handlePromise = async () => {
            const response = await Auth.currentAuthenticatedUser();
            const email = response.signInUserSession.idToken.payload.email;
            isUserAuthorized(email);
        }
        handlePromise();
    }

    const handleLogin = async () => {
        try {
            setOnload(true);
            const userObject = await Auth.signIn(
                // this.state.email.replace(/[@.]/g, '|'),
                loginInputs.email,
                loginInputs.password
            );
            if (userObject.challengeName) {
                // Auth challenges are pending prior to token issuance
                setLoginInputs({ userObject, stage: 1 });
            } else {
                // No remaining auth challenges need to be satisfied
                const session = Auth.currentSession();
                setLoginInputs({ stage: 0, email: '', password: '', code: '' });
                // this.props.history.replace('/app');
            }
        } catch (err) {
            console.error('Auth.signIn(): ', err);
        }
    }

    const googleSignIn = async () => {
        try {
            let provider: any = "Google"
            await Auth.federatedSignIn({ provider: provider });
        } catch (err) {
            alert(err);
            console.error('Auth.federatedSignIn: ', err);
        }
    }

    const loginCardView = () => {
        try {
            return (
                <div style={{ marginTop: 20 }}>
                    {/* <div className='lable'>Email ID</div> */}
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Enter email' }]}
                    >
                        <Input
                            // prefix={suffix}
                            onChange={(e: any) => {
                                setLoginInputs({ ...loginInputs, email: e.target.value })
                                setErrorMsg("")
                            }}
                            placeholder='User name or Email'
                            className={!errorMsg ? 'input-field' : "error-field"} />
                    </Form.Item>

                    {/* <div className='lable'>Password</div> */}
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Enter password' }]}
                    >
                        <Input.Password
                            // prefix={prefixIcon}
                            onChange={(e: any) => {
                                setLoginInputs({ ...loginInputs, password: e.target.value })
                                setErrorMsg("");
                            }}
                            onBlur={() => setErrorMsg("")}
                            placeholder='Password'
                            style={{ marginTop: '15px' }}
                            className={!errorMsg ? 'input-field' : "error-field"} />
                    </Form.Item>
                    <div className="forget-password-container">
                        Forgot Password
                    </div>
                    <div className="mt-5">
                        <AppButtons
                            block={true}
                            htmlType="submit"
                            buttonType={AppConstants.buttonTypes.solidLinearGreen6} text={"Login"}>
                        </AppButtons>

                    </div>
                    <div className="button-container">
                        {/* <button className="google-login-btn" onClick={() =>googleSignIn()}>
                            <img className="googleLoginImg" src={googleLogin} alt="" />
                        </button> */}
                        {/* <div className='googleLoginBtn' onClick={() => googleSignIn()}>
                            <div className='googlebtn-text'>
                                <div className='signin-text'>Sign in with Google</div>
                                <div className='google-image-icon'><img src={AppImages?.googleIcon} /></div>
                            </div>
                        </div> */}
                        <div className='google-button'
                            onClick={() => googleSignIn()}
                        >
                            <div className='btn-verbage'>
                                <div className='verbage-text'>
                                    Sign in with Google
                                </div>
                                <div className='icon'>
                                    <Google />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } catch (ex) {
            console.log("login form Error::" + ex)
        }
    }

    const loginView = () => {
        return (
            <div className={onload ? 'login-box-container loading' : 'login-box-container'}>
                <div className='login-title'>Login with your details</div>
                {errorMsg && <div className='login-error'>{errorMsg}</div>}
                <Form
                    hideRequiredMark
                    form={loginForm}
                    layout="vertical"
                    onFinish={!errorMsg ? handleLogin : () => { }}
                >
                    {loginCardView()}
                </Form>
            </div>
        )
    }

    const proceedLogin = () => {
        setWarehouse(warehouse);
        console.log("warehouse",warehouse)
        setWareHouseId(warehouse);
        setMenuName("WMS")
        window.location.href = "/";
    }

    const setWarehouse = (e: any) => {
        let warehouseInfo = warehouseList.find((x: any) => e == x?.id);
        setWarehouseInfo(warehouseInfo);
        warehouse = e
    }
    const warehouseSelectionView = () => {
        return (
            <div className={onload ? 'login-box-container loading' : 'login-box-container'}>
                <Form
                    hideRequiredMark
                    form={loginForm}
                    layout="vertical"
                    onFinish={proceedLogin}
                >
                    <div className="login-title">
                        Select Warehouse
                    </div>
                    <Form.Item rules={[{ required: true, message: "Select Warehouse" }]} name="warehouse" style={{ marginBottom: "10px" }}>
                        <Select onChange={(e: any) => {
                            warehouse = e
                        }}
                            className='select-field'
                            placeholder='Select'
                        >
                            {warehouseList?.map((item: any) =>
                                <Select.Option value={item?.id}>
                                    {item?.name}
                                </Select.Option>)}
                        </Select>
                    </Form.Item>


                    <div style={{ marginTop: 15 }}>
                        <AppButtons
                            block={true}
                            htmlType="submit"
                            buttonType={AppConstants.buttonTypes.solidLinearGreen6}
                            text={"Proceed"}>
                        </AppButtons>
                    </div>
                </Form>
            </div >
        )
    }


    return (
        <div className='login-container' style={{ height: '100vh' }}>
            <div className={"logo-container"}>
                <div className='logo'>

                    <div className='img-container-1'>
                        <div className='img-container'>
                            {name === "360wms" ? <WMSIcon /> : <img src={logoSrc} />}
                        </div>
                        <div className='details'>
                            Volutpat lacus turpis elit auctor ullamcorper diam vel. Eget semper mattis aenean.
                        </div>
                    </div>
                </div>
                <div className='product-logo'>
                    <div className='d-flex align-center'>
                        <div className='powered'>Powered By</div>
                        <WMSIcon />
                    </div>
                </div>
            </div>
            <div className='login-background'>
            </div>
            <div className='pin-design'>
            </div>
            <div className={'container'}>
                {!selectWarehouse ?
                    loginView()
                    :
                    warehouseSelectionView()

                }
            </div>
            {onload && <div className='loader'>
                <LoadingOutlined style={{ fontSize: 24 }} spin />
            </div>}

        </div >
    );
}

export default Login;