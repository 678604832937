import React from 'react';

function MenuCloseIcon() {
    return (
        <svg width="22" height="22" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="14.5461" height="2.42435" rx="1.21218" transform="matrix(0.707111 -0.707102 0.707111 0.707102 0 10.2856)" fill="white" />
            <rect width="14.5461" height="2.42435" rx="1.21218" transform="matrix(0.707111 0.707102 -0.707112 0.707102 1.71436 0)" fill="white" />
        </svg>
    );
}

export default MenuCloseIcon;

