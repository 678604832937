import { SearchOutlined, CaretRightOutlined, CloseOutlined, EditFilled } from '@ant-design/icons';
import { Input, Row, Col, Table, Button, Drawer, Steps, Modal, Select, Checkbox, message } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import AppButtons from '../../../CustomComponent/AppButtons';
import NoData from '../../../CustomComponent/NoData';
import AppConstants from '../../../Globals/AppConstants';
import { AppImages } from '../../../Globals/AppImages';
import AppReferences from '../../../Globals/AppReferences';
import { isArrayNotEmpty } from '../../../Globals/Helper';
import CSVFile from '../../../Images/CSVFile';
import { createManifestAction, handoverManifestAction } from '../../../Store/Actions/ProcessWMSAction';
import { getClientId, getUserInfo } from '../../../Utils/SessionStorage';
import SubHeader from '../../Header/Header';
import SpinLoader from '../../SpinLoader';
import SkeletonLoader from '../SkeletonLoader';
import RTSCartonDetails from './RTSCartonDetails';
const { Option } = Select;

function RTSListingScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        statusDetailsOnload,
        createManifestLoad,
        onload,
        cartonExpandList
    } = useSelector((state: any) => state?.ProcessReducerState)
    const [expandRow, setExpandRow] = useState<any>();
    const [openDrawer, setOpenDrawer] = useState<any>(false);
    const [showModal, setShowModal] = useState<any>(false);
    const user = getUserInfo();
    const cleintId = getClientId();
    const [selectedCourierService, setSelectedCourierService] = useState<any>();
    const [
        statusDetails,
        channel,
        tatClient,
        clientValue,
        process,
        getStatusDetails,
        getChannelStatus,
        setProcess,
        getChannelsApi
    ]: any = useOutletContext();
    const outerTableData = statusDetails?.lineitems;
    const [recordNumber, setRecordNumber] = useState<any>('');
    const [barcodeNumber, setBarcodeNumber] = useState<any>('');
    const [courierID, setCourierID] = useState<any>('');
    const [manifestCreateOnLoad, setManifestCreateOnLoad] = useState<any>(false)
    const b2b = AppReferences.channel.b2b;
    const [apiOnload, setOnload] = useState({
        handOverOnload: false
    });
    const reducerstate = useSelector((state: any) => state?.ProcessReducerState);

    useEffect(() => {
        if (apiOnload.handOverOnload && !reducerstate.onload) {
            if (reducerstate?.handover?.msg == 'Sales Order Moved to Gate-Out Ready Successfully') {
                message.success(reducerstate?.handover?.msg);
                getChannelsApi();
                setExpandRow(-1);
            }
            else {
                message.error(reducerstate?.handover?.msg);
            }
            setOnload({ ...apiOnload, handOverOnload: false })
        }
    }, [apiOnload.handOverOnload, reducerstate.onload])

    useEffect(() => {
        if (recordNumber != '' && barcodeNumber != '' && courierID != '') {
            createManifestApi();
        }
    }, [recordNumber]);

    useEffect(() => {
        if (manifestCreateOnLoad == true && createManifestLoad == false) {
            getChannelsApi()
            // getChannelStatus()
        }
    }, [manifestCreateOnLoad, createManifestLoad]);

    useEffect(() => {
        if (manifestCreateOnLoad == true && onload == false) {
            getStatusDetails()

        }

    }, [onload]);

    useEffect(() => {
        if (manifestCreateOnLoad == true && statusDetailsOnload == false) {
            setManifestCreateOnLoad(false);
            setProcess("206")
            navigate("/order-processing/manifested");
        }
    }, [statusDetailsOnload])

    const createManifestApi = () => {
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a16,
            transaction: courierID,
            where: barcodeNumber,
            fieldArray: recordNumber,
            user: user?.UserName
        }
        dispatch(createManifestAction(payload))
        setManifestCreateOnLoad(true)
    }

    const handOverToRTS = (data: any) => {
        let soRecords = cartonExpandList?.lineitems;
        let soRecNo = isArrayNotEmpty(soRecords) ? soRecords[0]?.SOReleaseRecordNo : '';
        soRecords?.slice(1, soRecords?.length)?.map((item: any) => {
            soRecNo += "|" + item?.SOReleaseRecordNo;
        })
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a40,
            transaction: soRecNo,
            where: cartonExpandList?.lineitems ? cartonExpandList?.lineitems?.length : 0,
            user: user?.UserName
        }
        dispatch(handoverManifestAction(payload));
        setOnload({ ...apiOnload, handOverOnload: true })

    }

    const drawerView = () => {
        try {

            return (
                <Drawer
                    className="drawer-view-verified"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "White" }}>
                            <div className='d-flex'>
                                <div>
                                    Sub Order ID:
                                </div>
                                <div>
                                    000001
                                </div>
                            </div>
                            <Button onClick={() => setOpenDrawer(false)}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>
                        </div>
                    }
                    onClose={() => setOpenDrawer(false)}
                    open={openDrawer == true}>
                    <div>
                        <div className='d-flex'>
                            <div className='icon-container'>
                                <img src={AppImages.productIcon} alt="" />
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <div className='sku-desc'>
                                    SKU-001-Lorem ipsm dolor
                                </div>
                                <div className='order-id'>
                                    Order ID :1243565467
                                </div>
                                <div className='d-flex' style={{ marginTop: 5 }}>
                                    <div className='brand-icon'>
                                        <img src={AppImages.brandIcon} alt='' />
                                    </div>
                                    <div className='time'>
                                        24 hrs
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: "#FAFAFA", marginTop: 25, borderRadius: 6, padding: 15 }}>
                        <div style={{ fontFamily: 'Redhat-Bold', marginTop: 5 }} >
                            Overview
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Steps
                                direction='vertical'
                                current={3}
                                items={[
                                    {
                                        title: 'Order Received ',
                                        description: "09-Sep-2022",
                                    },
                                    {
                                        title: 'Pick Released',
                                        description: "09-Sep-2022",
                                    },
                                    {
                                        title: 'Scanning Completed',
                                        description: "10-Sep-2022",
                                    },
                                ]}
                            />
                        </div>
                    </div>

                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }

    }
    const header = () => {
        return (
            <SubHeader
                title={"RTS"}
                paginationView="false"
                inputView="true"
                btnView="true"
                fromOrgin={'orderProcessing'}
            >
                <div className='d-flex flex-end'>
                    <div style={{ marginRight: 15, cursor: "pointer" }} className='csv-box'>
                        <CSVFile />
                        <div>CSV</div>
                    </div>
                    {/* <div>
                        <Input
                            prefix={<SearchOutlined />}
                            placeholder='Search By Batch ID '
                        />
                    </div>
                    <Link to="/order-processing/rts-createmanifest">
                    <div
                        onClick={() => setShowModal(true)}
                        className='createManifest-btn'
                    >
                        Create Manifest
                    </div>
                    </Link> */}
                </div>

            </SubHeader>
        )
    }

    const outerTableHeaderView = () => {
        try {
            return (
                <Row className='table-header'>
                    <Col span={channel == b2b ? 7 : 10} >
                        {channel == b2b ? AppConstants.orderId : AppConstants.courierService}
                    </Col>
                    <Col span={channel == b2b ? 7 : 10} className='d-flex jc-c'>
                        {channel == b2b ? AppConstants.rcdDate : "Total Boxes"}
                    </Col>
                    {channel == b2b &&
                        <Col span={7}>
                            {AppConstants.shipByDate}
                        </Col>}
                    <Col span={channel == b2b ? 3 : 4} className='d-flex jc-c'>
                        {"Action"}
                    </Col>
                </Row>
            )
        } catch (error) {
            console.log("Error in outerTableHeaderView::", error);
        }
    }


    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
        }
    }

    const createManifest = (item: any, courierId: any) => {
        try {
            let tempVar = item[0]?.RECNO;
            let tempvar1 = item[0]?.AWBNumber;
            (item || []).slice(1, item.length).map((x: any) => {
                tempVar += '|' + x?.RECNO;
                tempvar1 += '|' + x?.AWBNumber;
            })
            setRecordNumber(tempVar);
            setBarcodeNumber(tempvar1);
            setCourierID(courierId)
        } catch (error) {
            console.log("Error in createManifest::", error);
        }
    }

    const OuterTableBodyView = (item: any, index: any) => {
        try {
            let open = index == expandRow;
            return (
                <div className={open ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={channel == b2b ? 7 : 10} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                            <CaretRightOutlined
                                className={open ? "arrow arrow-down" : "arrow arrow-right"}
                            />
                            <div className='text-1'>
                                {channel == b2b ? item?.SOID : item?.Name}
                            </div>
                        </Col>
                        <Col span={channel == b2b ? 7 : 10} className='d-flex align-c jc-c'>
                            <div className='text-1'>
                                {channel == b2b ? moment(item?.ReceivedDate, 'DD-MMM-YYYY').format('DD-MM-YYYY') : item?.Cnt}
                            </div>
                        </Col>
                        {channel == b2b &&
                            <Col span={7} className='d-flex align-c'>
                                <div className='text-1'>
                                {moment(item?.ShipByDate, 'DD-MMM-YYYY').format('DD-MM-YYYY')}
                                </div>
                            </Col>
                        }
                        <Col span={channel == b2b ? 3 : 4} className='d-flex align-c jc-c'>
                            {channel == b2b ?
                                <AppButtons
                                    disabled={!open}
                                    onClick={() => handOverToRTS(item)}
                                    text="Handed over"
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen3} />
                                :
                                <AppButtons
                                    onClick={() => createManifest(item?.Lines, item?.ID)}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                    text={"Create Manifest"}>
                                </AppButtons>}
                        </Col>
                        {open &&
                            <Col span={24} style={{ background: "white", padding: 10, marginTop: 10, borderRadius: 10 }}>
                                {channel == b2b ?
                                    <RTSCartonDetails sodetails={item} clientValue={clientValue} />
                                    :
                                    innerTableView(item?.Lines)}
                            </Col>}
                    </Row>
                </div >
            )
        } catch (error) {
            console.log("Error in OuterTableBodyView::" + OuterTableBodyView);
        }
    }

    const innerTableView = (item: any) => {
        try {
            return (
                <div className='table-screen-container' style={{ marginTop: '10px' }}>
                    {tableHeaderView()}
                    {item?.map((x: any, index: any) => tableBodyView(x, index))}
                </div>
            )
        } catch (error) {
            console.log("Error in innerTableView::" + error);

        }
    }

    const tableHeaderView = () => {
        return (
            <Row className='table-header-2'>
                <Col span={7}>
                    {"AWB Number"}
                </Col>
                <Col span={6} className=' d-flex'>
                    {"Order ID"}
                </Col>
                <Col span={6} >
                    {"SKU,Description"}
                </Col>
                <Col span={5} className=' d-flex jc-c'>
                    {"Box Qty"}
                </Col>
            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        try {
            let barCodeUrl = `http://bwipjs-api.metafloor.com/?bcid=code128&text=${item?.AWBNumber}`
            return (
                <div className={"content-container"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={7} className="d-flex align-c ">
                            <img style={{ height: 40, width: "60%" }} src={barCodeUrl} alt="" />
                        </Col>
                        <Col span={6} className=' d-flex align-c'>
                            <div className='text-1'>
                                {item?.ID}
                            </div>
                        </Col>
                        <Col span={6} className=' d-flex align-c'>
                            <div className='text'>
                                {item?.ItemId + "," + item?.Description}
                            </div>
                        </Col>
                        <Col span={5} className=' d-flex align-c jc-c'>
                            <div className='text-1'>
                                {item?.BoxQty}
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        } catch (error) {
            console.log("Error in tableBodyView::" + error);

        }

    }

    const contentView = () => {
        return (
            <>
                {isArrayNotEmpty(outerTableData) ?
                    <div className='table-screen-container'>
                        {outerTableHeaderView()}
                        <div>
                            {outerTableData?.map((x: any, index: any) => OuterTableBodyView(x, index))}
                        </div>
                    </div>
                    :
                    <div className='p-20'>
                        <NoData
                            description={
                                <>{AppConstants.nodata}<span className='redhat-bold ml-5'>RTS</span></>
                            }
                        />
                    </div>}
            </>
        )
    }

    return (
        <>
            {!statusDetailsOnload ?
                <>
                    {header()}
                    {contentView()}
                    {drawerView()}
                    <SpinLoader loading={apiOnload?.handOverOnload} />
                </>
                :
                <SkeletonLoader />
            }
        </>
    );
}

export default RTSListingScreen;