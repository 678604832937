import { Layout } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIndividualStageAction, getProcessData, getStageDetailsAction } from '../../Store/Actions/ProcessWMSAction';
import { getDashboardMenuId, getUserInfo, getWareHouseId, setDashboardMenuId } from '../../Utils/SessionStorage';
import SANIcon from '../../Images/SANIcon';
import AppConstants from '../../Globals/AppConstants';
import PutAwayIcon from '../../Images/PutAway';
import QCIcon from '../../Images/QCIcon';
import GRNIcon from '../../Images/GRNIcon';
import AppReferences from '../../Globals/AppReferences';
import SideSubmenu from '../SideSubMenu/SideSubmenu';
import SkeletonLoader from '../OrderProcessing/SkeletonLoader';
import { Outlet } from 'react-router-dom';
import DashBoardIcon from '../../Images/DashBoardIcon';
import { AppModuleIds } from '../../Globals/AppReferenceIds';

const { Content, Sider } = Layout;

function Processess(props: any) {
    const [value, setValue] = useState<any>(getDashboardMenuId());
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const { individualStageData, onload, processDataInfo }: any = reducerState;
    const [dataOnload, setDataOnload] = useState(false);
    const [pageRefresh, setPageRefresh] = useState<any>(false);
    const [showScreen, setShowScreen] = useState<any>(false)

    useEffect(() => {
        if (value) {
            getIndividualStageDetails();
            setDashboardMenuId(value);
        }
    }, [value]);

    // useEffect(() =>{
    //     return() => {setDashboardMenuId("")}
    // }, [])

    const processData = () => {
        try {
            let payload = {
                "activity": AppReferences.activities.av,
                "warehouse": getWareHouseId(),
                "opnfor": AppReferences.opnfor.wms,
                "user": getUserInfo()?.UserName,
                "fieldarray": getUserInfo()?.roleId,
            }
            dispatch(getProcessData(payload));
            // setProcessOnlaod(true);
        } catch (error) {
            console.log("Error in processData", error)
        }
    }

    const getStatges = () => {
        setPageRefresh(true)
    }

    useEffect(() => {
        if (dataOnload == true && onload == false) {
            setShowScreen(true);
        }
    }, [dataOnload, onload])

    const getIndividualStageDetails = async () => {
        let user = await getUserInfo();
        let payload = {
            "activity": AppReferences.activities.av3,
            "warehouse": getWareHouseId(),
            "opnfor": AppReferences.opnfor.wms,
            "where": value,
            "user": user?.UserName,
            "fieldarray": user?.roleId,
            "tableno": getUserInfo()?.CorporateID
        }
        dispatch(getIndividualStageAction(payload));
        setDataOnload(true);
    }

    return (
        <Layout>
            <Sider className='sider-menu-360'>
                <SideSubmenu
                    setValue={setValue}
                    intialMenu={AppModuleIds.wms}
                    pageRefresh={pageRefresh}
                    warehouse={processDataInfo?.title?.warehouse}
                />
            </Sider>
            <Content style={{ height: "100%" }}>
                {(showScreen == true) ?
                    <Outlet context={[value, individualStageData, getStatges, getIndividualStageDetails, processData]} />
                    :
                    <SkeletonLoader />
                }
            </Content>
        </Layout>
    );
}

export default Processess;