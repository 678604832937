import { Row, Col, Table, Input, Modal, Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import '../CardContainer.scss';
import SubHeader from '../Header/Header';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import { CaretRightOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserInfo, getWareHouseId, setDashboardMenuId, setRTODetails } from '../../Utils/SessionStorage';
import { clearReducerAction, ExpandDataAction, getDetailedDataAction, getIndividualStageAction, startInwardAction } from '../../Store/Actions/ProcessWMSAction';
import AppReferences from '../../Globals/AppReferences';
import { useDispatch, useSelector } from 'react-redux';
import SpinLoader from '../SpinLoader';

function SearchConsignment(props: any) {
    const { rtoDetail, closeSearchConsignMent }: any = props;
    const navigate = useNavigate();
    const b2c = false;
    const [innerExpandRow, setInnerExpandRow] = useState<any>();
    const [errorModal, setErrorModal] = useState(false);
    // const {setProcessId} = props;
    const dispatch = useDispatch();
    const reducerState = useSelector((state: any) => state.ProcessReducerState);
    const { expandDetailedData, expandListForCarton, } = reducerState;
    const [onload, setOnload] = useState({
        scanOnload: false,
        startOnload: false
    });
    const [view, setView] = useState(false);

    useEffect(() => {
        dispatch(clearReducerAction("expandDetailedData"));
    }, []);

    useEffect(() => {
        if (onload.scanOnload && !reducerState.expandDetailedDataOnLoad) {
            setOnload({ ...onload, startOnload: false });
            if (expandDetailedData?.isCartonNumberFound == 'N') {
                setErrorModal(true)
                setView(false);
            }
        }
    }, [onload.scanOnload, reducerState.expandDetailedDataOnLoad]);

    useEffect(() => {
        if (onload.startOnload && !reducerState.onload) {
            setOnload({ ...onload, startOnload: false });
            rtoDetail?.setProcessId('30');
            setDashboardMenuId('130100');
            navigate('/process/QC')
        }
    }, [onload.startOnload, reducerState.onload]);

    const scanCartonBox = (value: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.rtoB2b,
            activity: AppReferences.activities.a01,
            transaction: value,
            customer: rtoDetail?.rtoDetails?.ClientID,
            user: getUserInfo()?.UserName
        }
        dispatch(getDetailedDataAction(payload));
        setOnload({ ...onload, scanOnload: true });
        setView(true)
    }

    const getcartonDetails = (data: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a30,
            transaction: data?.CartonNumber,
            customer: rtoDetail?.rtoDetails?.ClientID,
            user: getUserInfo()?.UserName
        }
        dispatch(ExpandDataAction(payload));
    }

    const startInwardApi = () => {
        let soidReleaseNo = (expandDetailedData?.SOID).split('-')
        let payload = {
            opnfor: AppReferences.opnfor.rtoB2b,
            activity: AppReferences.activities.ax01,
            transaction: rtoDetail?.rtoDetails?.GateINID,
            customer: rtoDetail?.rtoDetails?.ClientID,
            warehouse: getWareHouseId(),
            fieldArray: `${soidReleaseNo[0]}|${"R1"}`,
            user: getUserInfo()?.UserName
        }
        dispatch(startInwardAction(payload));
        setOnload({ ...onload, startOnload: true })
    }

    const newInward = (e: any) => {
        setErrorModal(false);
        navigate("/captureinvoicedetails");
        setRTODetails({ ...rtoDetail, invoiceFor: 'rto' })
    }

    const handleInnerExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == innerExpandRow) {
            setInnerExpandRow(-1)
        }
        else {
            setInnerExpandRow(index);
            getcartonDetails(data)
        }
    }


    const skuDetailsView = () => {
        return (
            <div className='table-theme-1' style={{ background: 'white', padding: 10, borderRadius: 6 }}>
                <Row className='table-header'>
                    <Col span={12} className="open-icon d-flex align-c"
                    >
                        {AppConstants.skuDescription}
                    </Col>
                    <Col span={12} className="open-icon d-flex align-c"
                    >
                        {AppConstants.packed}
                    </Col>
                </Row>
                {expandListForCarton?.lineitems?.map((item: any) => <Row className='table-body'>
                    <Col span={12} className="open-icon d-flex align-c"
                    >
                        {item.SKU + ', ' + item.Description}
                    </Col>
                    <Col span={12} className="open-icon d-flex align-c"
                    >
                        {item.Quantity}
                    </Col>
                </Row>)}
            </div>
        )
    }

    const cartonBoxTableView = () => {
        return (
            <>
                {expandDetailedData?.lineitems?.map((data: any, index: any) => {
                    let open = index == innerExpandRow;
                    return (
                        <Row className='content-container mb-5'>
                            <Col span={20} className="open-icon d-flex align-c"
                                onClick={(e: any) => handleInnerExpand(e, index, data)}
                            >
                                <CaretRightOutlined
                                    className={open ? "arrow arrow-down" : "arrow arrow-right"}
                                />
                                <div className='text'>
                                    Carton Box: {data?.CartonNumber}
                                </div>
                            </Col>
                            {open && <Col span={24}>
                                {skuDetailsView()}
                            </Col>}
                        </Row>
                    )
                })}
            </>
        )
    }

    const tableBodyView = () => {
        return (
            <div>
                <Row className='d-flex align-c'>
                    <Col span={7}>
                        <div className='redhat-semibold'>
                            Sales Order:<span className='redhat-bold'>{expandDetailedData?.SOID}</span>
                        </div>
                    </Col>
                    <Col span={7} className=' d-flex align-c jc-c'>
                        <div className='redhat-semibold'>
                            Client:<span className='redhat-bold'>{rtoDetail?.rtoDetails?.ClientName}</span>
                        </div>
                    </Col>
                    <Col span={7} className='d-flex align-c jc-c'>
                        {/* <div className='redhat-semibold'>
                            Courier:<span className='redhat-bold'>Blue Dart</span>
                        </div> */}
                    </Col>
                    <Col span={3} className='d-flex align-c jc-c'>
                        <AppButtons text={'Start Inward'}
                            onClick={startInwardApi}
                            buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                        />
                    </Col>
                </Row>
            </div>
        )
    }

    const tableView = () => {
        return (
            <div className='content-container1' style={{ marginTop: '10px' }}>
                {view && <>
                    {tableBodyView()}
                    <div className="sku-details">
                        {cartonBoxTableView()}
                    </div>
                </>}
            </div>
        )
    }

    const dataView = () => {
        return (
            <div>
                <SubHeader
                    title={
                        <>
                            <div>
                                {rtoDetail?.rtoDetails?.GateINID}
                            </div>
                            <div>
                                {rtoDetail?.rtoDetails?.ClientName}
                            </div>
                        </>
                    }
                    paginationView="false"
                    inputView="true"
                    btnView="true"
                    fromOrgin={'custom-sticker'}
                >
                    <div className='d-flex jc-sb'>
                        <div style={{ width: '310px', marginLeft: '10%' }} >
                            <Input
                                onPaste={(e: any) => e.target.value.length >= 5 && scanCartonBox(e.target.value)}
                                onChange={(e: any) => e.target.value.length >= 5 && scanCartonBox(e.target.value)}
                                // prefix={<SearchOutlined/>}
                                placeholder='Scan Carton Box Barcode/Sales Order'
                            />
                        </div>
                        <div className="btn-container d-flex flex-end">
                            <Button className="close-btn" onClick={closeSearchConsignMent}>
                                <CloseOutlined />
                            </Button>
                        </div>
                    </div>
                </SubHeader>
                {tableView()}
            </div>

        )
    }

    const errorPopup = () => {
        return (
            <Modal
                title={
                    <div className='d-flex jc-sb'>
                        <div className='redhat-bold mb-10 font-18'>
                            Consignment Not Found:
                        </div>
                        <Button
                            onClick={() => setErrorModal(false)}
                            className="cross-btn" style={{ borderRadius: "50%" }}
                        >
                            <CloseOutlined />
                        </Button>
                    </div>
                }
                className='modal-view'
                onCancel={() => setErrorModal(false)}
                // onOk={}
                style={{ width: "55%" }}
                open={errorModal}
                footer={null}
            >
                <div className='redhat-bold mb-10 font-18 p-20'>
                    There is no dispatch found matching this carton.
                    <div>
                        Do you want to start new Inward ?
                    </div>
                </div>

                <div className='d-flex flex-end mt-10'>
                    <AppButtons
                        style={{ width: "100px" }}
                        onClick={() => setErrorModal(false)}
                        text='No'
                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                    />
                    <AppButtons
                        style={{ width: "100px" }}
                        onClick={newInward}
                        text='Yes'
                        buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                    />
                </div>
            </Modal>
        )
    }

    return (
        <div>
            {dataView()}
            {errorPopup()}
            <SpinLoader loader={onload.startOnload} />
        </div>
    );
}

export default SearchConsignment;