
import React from 'react';
import AssociatePoListing from '../QC/AssociatePo/AssociatePoListing';
import StartRecievingListing from '../QC/StartingRecieving/StartRecievingListing';
import CustomDetailedQCListing from '../QC/CustomDetailedQC/CustomDetailedQCListing'
import {  useOutletContext } from 'react-router-dom';

function QCIndex(props: any) {
    const [value, individualStageData]: any = useOutletContext();

    return (
        <>
            {value == 130100 && <AssociatePoListing stageDetails = {individualStageData} />}
            {value == 130300 && <StartRecievingListing />}
            {value == 3 && <CustomDetailedQCListing />}
        </>
    );
}

export default QCIndex;