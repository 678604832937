import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import React from 'react';
import NoData from '../../../CustomComponent/NoData';
import { isArrayNotEmpty } from '../../../Globals/Helper';

function POModalView(props: any) {
    const {
        openSearch,
        close,
        data,
        poid,
    } = props;

    let status = (data?.lineitems || []).map((item: any) => item?.POStatus)

    const tableList = (data: any, index: any) => {
        try {
            return (
                <div className="content-container" style={{ marginTop: 10 }}>
                    <Row>
                        <Col className='d-flex align-c' span={8}>
                            <div className='text'>{data?.ItemID}</div>
                        </Col>
                        <Col className='d-flex align-c jc-c' span={8}>
                            <div className='text-1'>{data?.OrderedQuantity}</div>
                        </Col>
                        <Col className='d-flex align-c jc-c' span={8}>
                            <div className='text-1'>{data?.UnitPrice}</div>
                        </Col>
                    </Row>
                </div >
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const tableTitleContainer = () => {
        try {
            return (
                <Row>
                    <Col span={8}>
                        <div className='redhat-bold font-16'>SKU - Description</div>
                    </Col>
                    <Col className='d-flex align-c jc-c' span={8}>
                        <div className='redhat-bold font-16'> Quantity</div>
                    </Col>
                    <Col className='d-flex align-c jc-c' span={8}>
                        <div className='redhat-bold font-16'> MRP </div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }

    const tableView = (item: any) => {
        try {
            return (
                <div style={{ background: "white", padding: 10, borderRadius: 6 }}>
                    <div className='header-part-2'>
                        {tableTitleContainer()}
                    </div>
                    <div >
                        {(item?.Lines || []).map((data: any, index: any) =>
                                tableList(data, index)
                            )}

                    </div>
                </div>

            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const listingView = (data: any) => {
        try {
            return (
                <div className="content-container1" style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={4}
                            className='d-flex align-c'
                        >
                            <div className="title-container">
                                <div className='d-flex align-c'>
                                    <div style={{ width: 150, marginLeft: 5 }}>
                                        <div className='text-2'>
                                            {data?.POID}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={4}
                            className='d-flex align-c'
                        >
                            <div className='text-2'> {data?.Type}</div>
                        </Col>
                        <Col span={4}
                            className='d-flex align-c'
                        >
                            <div className='text-2 d-flex align-c'> {data?.CreatedDate}</div>
                        </Col>
                        <Col span={4}
                            className='d-flex align-c'
                        >
                            <div className='text-2 d-flex align-c'> {data?.RefernceNo}</div>
                        </Col>
                        <Col span={4}
                            className='d-flex align-c'>
                            <div className='text-2 d-flex align-c'> {data?.VendorName}</div>
                        </Col>
                        <Col className='d-flex align-c'
                            span={4}>
                            <div className='text-2 d-flex align-c'> {data?.ExpectedDate}</div>
                        </Col>
                    </Row>
                    <div style={{ marginTop: 10 }}>
                        {tableView(data)}
                    </div>
                </div >
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const tableHeader = () => {
        try {
            return (
                <Row className='table-header'>
                    <Col span={4}>
                        <div style={{ marginLeft: 25}} className='text colr-wh'>PO Number</div>
                    </Col>
                    <Col span={4}>
                        <div className='text colr-wh'> PO Type</div>
                    </Col>
                    <Col span={4}>
                        <div className='text colr-wh'> Created Date </div>
                    </Col>
                    <Col span={4}>
                        <div className='text colr-wh'> Ref no </div>
                    </Col>
                    <Col span={4}>
                        <div className='text colr-wh'> Vendor  </div>
                    </Col>
                    <Col span={4}>
                        <div className='text colr-wh'>Expected Date</div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }
    const dataView = () => {
        return (
            <div>

                {isArrayNotEmpty(data?.lineitems) ?
                    <>
                        {tableHeader()}
                        <div>
                            {(data?.lineitems || []).map((item: any, index: Number) =>
                                listingView(item)
                            )}
                        </div>
                    </>
                    :
                    <div style={{ height: '40vh' }}>
                        <NoData
                            height={'30vh'}
                            description={`No Data available`}
                        />
                    </div>
                }
            </div>
        )
    }

    const searchModal = () => {
        return (
            <Modal
                title={
                    <div className='d-flex align-c jc-sb'>
                        <div className='redhat-bold mb-10 font-18'>
                            {poid?.searchPoid}
                            <span style={{ marginLeft: 10 }}>Status : {status[0]}</span>
                        </div>
                        <Button onClick={close}
                            className="cross-btn">
                            <CloseOutlined />
                        </Button>
                    </div>}
                className='search-modal'
                onCancel={close}
                // onOk={}
                open={openSearch}
                footer={null}
            >
                {dataView()}
            </Modal>
        )
    }
    return (
        <div>
            {searchModal()}
        </div>
    );
}

export default POModalView;