import { takeEvery } from "redux-saga/effects";
import ApiConstants from "../../Globals/ApiConstants";

import {
    getGateInEntrySaga,
    getIndividualStageSaga,
    getStageDetailsSaga,
    getProcessDataSaga,
    getGateInEntryMetaSaga,
    gateInEntryAddSaga,
    getTransactionSaga,
    getPendingSanDetailSaga,
    getReceivingInProgressViewSaga,
    saveReceivingInProgressSaga,
    getReceivingInProgressBarcodeSaga,
    getUnloadingDetailSaga,
    sendStickeringSaga,
    getStickeringInfoSaga,
    getAssociatePoSaga,
    getAssociatePoSaveSaga,
    completeStickeringSaga,
    getSendSanInfoData,
    getGRNInfoSaga,
    getCaptureInvoiceDetailListSaga,
    getPutAwayDetailsSaga,
    getSkuSaga,
    getConditionSaga,
    getSaveSkuSaga,
    addInvoiceSaga,
    getMetaListSaga,
    getCaptureInvoiceSaga,
    captureSKUWiseInvoiceSaga,
    sendSanSaga,
    completeReceiveInProgressSaga, getCaptureSKUListSaga,
    getGeneratePutawaySaga,
    getAssignMoverSaga,
    getBarCodeScanSuccessSaga,
    getStartPutawaySaga,
    getWarehouseSaga,
    getClientDataSaga,
    getSKUDataSaga,
    getInventoryDataSaga,
    deleteReceieveInProgressSaga,
    assignMoverSaga,
    getCustomersSaga,
    getPOSaga,
    getSKUBAtchDetailsSaga,
    addSKUBAtchDetailsSaga,
    getSKUDetailsSaga,
    getSKUandPoSaga,
    createPoSaga,
    addSKUforPoSaga,
    editSKUforPoSaga,
    getSkuDescSaga,
    updatePoSaga,
    deleteSaga,
    getConsignmentSaga,
    getClientSaga,
    getLeftMenuSaga,
    getAdhocClientData,
    getAdhocSaveData,
    getAdhocExpenseData,
    getAdhocExpandData,
    getAdhocUpdateData,
    getChannelStatusSaga,
    getChannelDetailsSaga,
    getPickersSaga,
    getSKUSubordersSaga,
    generatePicklistSaga,
    pickingCompleteSaga,
    scanPickedSaga,
    getCourierServiceSaga,
    getShowOrdersSaga,
    scanSKUSaga,
    popupScanSKUSaga,
    completePackingSaga,
    createManifestSaga,
    validateBoxCountSaga,
    shipmentOTPSaga,
    handOverManifestSaga,
    continuePutawaySaga,
    getSalesClientSaga,
    getDetailsForSOSaga,
    getSalesOrdersTypesSaga,
    createSOSaga,
    getStockMovementCustomerListSaga,
    getStockMovementSKUListSaga,
    getStockMovementLocationQtySaga,
    getSalesOrderDetailsSaga,
    lockAndProcessSaga,
    getStockMovementDataSaga,
    getStockMovementUpdateAttrSaga,
    cancelSOSaga,
    searchPOSaga,
    whmConfigurationSaga,
    whmAddSaga,
    getClientListSaga,
    createCategorySaga,
    whmEditSaga,
    getStockMovementLocationDataSaga,
    configureSaga,
    getFormMetaSaga,
    getAdjustInventorySaga,
    getQuarantineInventorySaga,
    getViewConfigureModalSaga,
    configureWarehouseSaga,
    getOnboardMenuSaga,
    uploadFileSaga,
    getStorageDetailsSaga,
    getClientDedicatedLocationSaga,
    getOnboardingEditDataSaga,
    goLiveSaga,
    editSKUBAtchDetailsSaga,
    getSKULocationSaga,
    changeBinBoxLocationSaga,
    getFilesSaga,
    setattachemntAsDefaultSaga,
    getReportListSaga,
    getGateInReportListSaga,
    getExpandGateInReportListSaga,
    getSANReportListSaga,
    getExpandSANReportListSaga,
    getGRNReportListSaga,
    getExpandGRNReportListSaga,
    getGAEReportListSaga,
    getExpandGAEReportListSaga,
    getODReportListSaga,
    getExpandODReportListSaga,
    getSearchGateInReportListSaga,
    getDateReportListSaga,
    getExpandCartonListSaga,
    getCartonListSaga,
    getDimensionSaga,
    updateDimensionSaga,
    getReQCListSaga,
    closeCartonSaga,
    deleteCartonSaga,
    printAllPackingSlipSaga,
    printAllSticterSaga,
    printSpecificPackingSlipSaga,
    printSpecificSticterSaga,
    getScanSKUSaga,
    workOnThisSaga,
    moveToRTOSaga,
    saveReQcSaga,
    cycleCountScanSaga,
    cycleCountMasterDataSaga,
    cycleCountExpandDataSaga,
    startCycleCountSaga,
    getStorageSkuListSaga,
    getStorageSkuSelectSaga,
    getStorageSkuQTYUpdateSaga,
    closeCycleCountSaga,
    ApproveRejectCycleCountSaga,
    createClientSaga,
    createCONTRACTSaga,
    getClientDetailsSaga,
    editClientSaga,
    startOnBoardingSaga,
    getMoveToBinMetaListSaga,
}
    from "./ProcessSaga";

export default function* RootSaga() {
    yield takeEvery(ApiConstants.API_GET_GATE_IN_ENTRY_LOAD, getGateInEntrySaga);
    yield takeEvery(ApiConstants.API_GET_PROCESS_DATA_LOAD, getProcessDataSaga);
    yield takeEvery(ApiConstants.API_GET_STAGE_DETAILS_LOAD, getStageDetailsSaga);
    yield takeEvery(ApiConstants.API_GET_INDIVIDUAL_STAGE_LOAD, getIndividualStageSaga);
    yield takeEvery(ApiConstants.API_GET_GATE_IN_ENTRY_META_LOAD, getGateInEntryMetaSaga);
    yield takeEvery(ApiConstants.API_GATE_IN_ENTRY_ADD_LOAD, gateInEntryAddSaga);
    yield takeEvery(ApiConstants.API_GET_TRANSACTION_LOAD, getTransactionSaga);
    yield takeEvery(ApiConstants.API_GET_ASSOCIATEPO_LOAD, getAssociatePoSaga);
    yield takeEvery(ApiConstants.API_GET_PENDING_SAN_DETAILS_LOAD, getPendingSanDetailSaga);
    yield takeEvery(ApiConstants.API_GET_RECEIVING_IN_PROGRESS_VIEW_LOAD, getReceivingInProgressViewSaga);
    yield takeEvery(ApiConstants.API_SAVE_RECEIVING_IN_PROGRESS_LOAD, saveReceivingInProgressSaga);
    yield takeEvery(ApiConstants.API_GET_RECEIVING_IN_PROGRESS_BARCODE_LOAD, getReceivingInProgressBarcodeSaga);
    yield takeEvery(ApiConstants.API_GET_UNLOADING_DETAILS_LOAD, getUnloadingDetailSaga);
    yield takeEvery(ApiConstants.API_SEND_TO_STICKERING_LOAD, sendStickeringSaga);
    yield takeEvery(ApiConstants.API_GET_STICKER_DETAILS_LOAD, getStickeringInfoSaga);
    yield takeEvery(ApiConstants.API_GET_ASSOCIATEPO_SEND_LOAD, getAssociatePoSaveSaga);
    yield takeEvery(ApiConstants.API_GET_CAPTURE_INVOICE_DETAIL_LIST_LOAD, getCaptureInvoiceDetailListSaga);
    yield takeEvery(ApiConstants.API_COMPLETE_STICKERING_LOAD, completeStickeringSaga);
    yield takeEvery(ApiConstants.API_GET_PUTAWAY_DEATAILS_LOAD, getPutAwayDetailsSaga);
    yield takeEvery(ApiConstants.API_GET_META_DATA_LOAD, getMetaListSaga);
    yield takeEvery(ApiConstants.API_GET_MOVE_TO_BIN_META_DATA_LOAD, getMoveToBinMetaListSaga);

    
    yield takeEvery(ApiConstants.API_ADD_INVOICE_LOAD, addInvoiceSaga);
    yield takeEvery(ApiConstants.API_SEND_SAN_INFO_LOAD, getSendSanInfoData);
    yield takeEvery(ApiConstants.API_GRN_INFO_LOAD, getGRNInfoSaga);
    yield takeEvery(ApiConstants.API_GET_SKU_LOAD, getSkuSaga);
    yield takeEvery(ApiConstants.API_GET_CONDITION_LOAD, getConditionSaga);
    yield takeEvery(ApiConstants.API_SAVE_SKU_LOAD, getSaveSkuSaga);
    yield takeEvery(ApiConstants.API_GET_CAPTURE_INVOICE_DETAILS_LOAD, getCaptureInvoiceSaga);
    yield takeEvery(ApiConstants.API_CAPTURE_SKU_WISE_LOAD, captureSKUWiseInvoiceSaga);
    yield takeEvery(ApiConstants.API_SEND_SAN_LOAD, sendSanSaga);
    yield takeEvery(ApiConstants.API_COMPLETE_RECEIVE_IN_PROGRESS_LOAD, completeReceiveInProgressSaga)
    yield takeEvery(ApiConstants.API_GET_CAPTURE_SKU_DETAILS_LOAD, getCaptureSKUListSaga);
    yield takeEvery(ApiConstants.API_GET_GENERATE_PUTAWAY_INFO_LOAD, getGeneratePutawaySaga);
    yield takeEvery(ApiConstants.API_GET_ASSIGN_MOVER_INFO_LOAD, getAssignMoverSaga);
    yield takeEvery(ApiConstants.API_GET_BAR_CODE_SCAN_SUCCESS_DATA_LOAD, getBarCodeScanSuccessSaga);
    yield takeEvery(ApiConstants.API_START_PUTAWAY_LOAD, getStartPutawaySaga);
    yield takeEvery(ApiConstants.API_GET_WAREHOUSES_ACTION_LOAD, getWarehouseSaga);
    yield takeEvery(ApiConstants.API_GET_CLIENT_DATA_LOAD, getClientDataSaga);
    yield takeEvery(ApiConstants.API_GET_SKU_DATA_LOAD, getSKUDataSaga);
    yield takeEvery(ApiConstants.API_GET_INVENTORY_DATA_LOAD, getInventoryDataSaga);
    yield takeEvery(ApiConstants.API_DELETE_RECEIVE_INPROGRESS_LOAD, deleteReceieveInProgressSaga);
    yield takeEvery(ApiConstants.API_ASSIGN_MOVER_LOAD, assignMoverSaga);
    yield takeEvery(ApiConstants.API_GET_CUSTOMER_LOAD, getCustomersSaga);
    yield takeEvery(ApiConstants.API_GET_PO_LOAD, getPOSaga);
    yield takeEvery(ApiConstants.API_GET_SKU_DETAILS_LOAD, getSKUDetailsSaga);
    yield takeEvery(ApiConstants.API_GET_SKU_AND_PO_LOAD, getSKUandPoSaga);
    yield takeEvery(ApiConstants.API_CREATE_PO_LOAD, createPoSaga);
    yield takeEvery(ApiConstants.API_ADD_SKU_FOR_PO_LOAD, addSKUforPoSaga);
    yield takeEvery(ApiConstants.API_EDIT_SKU_FOR_PO_LOAD, editSKUforPoSaga);
    yield takeEvery(ApiConstants.API_GET_SKU_BATCH_DETAILS_LOAD, getSKUBAtchDetailsSaga);
    yield takeEvery(ApiConstants.API_ADD_SKU_BATCH_DETAILS_LOAD, addSKUBAtchDetailsSaga);
    yield takeEvery(ApiConstants.API_EDIT_SKU_BATCH_DETAILS_LOAD, editSKUBAtchDetailsSaga);
    yield takeEvery(ApiConstants.API_GET_SKU_DESC_LOAD, getSkuDescSaga);
    yield takeEvery(ApiConstants.API_UPDATE_PO_LOAD, updatePoSaga);
    yield takeEvery(ApiConstants.API_DELETE_LOAD, deleteSaga);
    yield takeEvery(ApiConstants.API_CONSIGNMENT_LOAD, getConsignmentSaga);
    yield takeEvery(ApiConstants.API_GET_CLIENT_LOAD, getClientSaga);
    yield takeEvery(ApiConstants.API_GET_LEFT_MENU_LOAD, getLeftMenuSaga);
    yield takeEvery(ApiConstants.API_GET_ADHOC_CLIENT_DATA_LOAD, getAdhocClientData);
    yield takeEvery(ApiConstants.API_GET_ADHOC_SAVE_DATA_LOAD, getAdhocSaveData);
    yield takeEvery(ApiConstants.API_GET_ADHOC_EXPENSE_NO_LOAD, getAdhocExpenseData);
    yield takeEvery(ApiConstants.API_GET_ADHOC_EXPAND_DATA_LOAD, getAdhocExpandData);
    yield takeEvery(ApiConstants.API_UPDATE_ADHOC_LOAD, getAdhocUpdateData);
    yield takeEvery(ApiConstants.API_GET_CHANNEL_STATUS_LOAD, getChannelStatusSaga);
    yield takeEvery(ApiConstants.API_GET_CHANNEL_DETAILS_LOAD, getChannelDetailsSaga);
    yield takeEvery(ApiConstants.API_GET_PICKER_LOAD, getPickersSaga);
    yield takeEvery(ApiConstants.API_GET_SKU_SUBORDER_LOAD, getSKUSubordersSaga);
    yield takeEvery(ApiConstants.API_GENERATE_PICKLIST_LOAD , generatePicklistSaga);
    yield takeEvery(ApiConstants.API_PICKING_COMPLETE_LOAD , pickingCompleteSaga);
    yield takeEvery(ApiConstants.API_SCAN_PICKED_LOAD , scanPickedSaga);
    yield takeEvery(ApiConstants.API_GET_COURIER_SERVICE_LOAD,getCourierServiceSaga);
    yield takeEvery(ApiConstants.API_GET_SHOW_ORDERS_LOAD,getShowOrdersSaga);
    yield takeEvery(ApiConstants.API_SCAN_SKU_LOAD,scanSKUSaga);
    yield takeEvery(ApiConstants.API_POPUP_SCAN_SKU_LOAD,popupScanSKUSaga);
    yield takeEvery(ApiConstants.API_CREATE_MANIFEST_LOAD,createManifestSaga);
    yield takeEvery(ApiConstants.API_COMPLETE_PACKING_LOAD, completePackingSaga);
    yield takeEvery(ApiConstants.API_VALIDATE_GATEOUT_BOX_COUNT_LOAD, validateBoxCountSaga);
    yield takeEvery(ApiConstants.API_SHIPMENT_OTP_LOAD, shipmentOTPSaga);
    yield takeEvery(ApiConstants.API_HAND_OVER_MANIFEST_ACTION_LOAD, handOverManifestSaga);
    yield takeEvery(ApiConstants.API_GET_CONTINUE_PUTAWAY_LOAD, continuePutawaySaga);
    yield takeEvery(ApiConstants.API_GET_SALES_ORDER_CLIENT_LOAD, getSalesClientSaga);
    yield takeEvery(ApiConstants.API_GET_DETAILS_FOR_SO_LOAD, getDetailsForSOSaga);
    yield takeEvery(ApiConstants.API_GET_SALES_ORDERS_TYPE_LOAD, getSalesOrdersTypesSaga);
    yield takeEvery(ApiConstants.API_CREATE_SO_LOAD, createSOSaga);
    yield takeEvery(ApiConstants.API_GET_CUSTOMER_LIST_LOAD, getStockMovementCustomerListSaga);
    yield takeEvery(ApiConstants.API_GET_SKU_LIST_LOAD, getStockMovementSKUListSaga);
    yield takeEvery(ApiConstants.API_GET_LOCATION_QTY_LOAD, getStockMovementLocationQtySaga);
    yield takeEvery(ApiConstants.API_GET_LOCATION_DATA_LOAD, getStockMovementLocationDataSaga);
    yield takeEvery(ApiConstants.API_GET_STOCK_DATA_LOAD, getStockMovementDataSaga);
    yield takeEvery(ApiConstants.API_GET_UPDATE_ATTRIBUTE_LOAD, getStockMovementUpdateAttrSaga);
    yield takeEvery(ApiConstants.API_GET_SALES_ORDER_STATUS_DETAILS_LOAD, getSalesOrderDetailsSaga);
    yield takeEvery(ApiConstants.API_LOCK_AND_PROCESS_LOAD, lockAndProcessSaga);
    yield takeEvery(ApiConstants.API_CANCEL_SALES_ORDER_LOAD, cancelSOSaga);
    yield takeEvery(ApiConstants.API_SEARCH_PURCHASE_ORDER_LOAD, searchPOSaga);
    yield takeEvery(ApiConstants.API_WM_CONFIGURE_LOAD, whmConfigurationSaga);
    yield takeEvery(ApiConstants.API_WM_ADD_LOAD, whmAddSaga);
    yield takeEvery(ApiConstants.API_WM_EDIT_LOAD, whmEditSaga);
    yield takeEvery(ApiConstants.API_GET_CATEGORY_LIST_LOAD, getClientListSaga);
    yield takeEvery(ApiConstants.API_CREATE_CATEGORY_LOAD, createCategorySaga);    
    yield takeEvery(ApiConstants.API_CONFIGURE_DATA_LOAD, configureSaga);  
    yield takeEvery(ApiConstants.API_GET_FORM_DROPDOWN_LOAD, getFormMetaSaga);  
    yield takeEvery(ApiConstants.API_MOVE_ADJUST_INVENTORY_LOAD, getAdjustInventorySaga); 
    yield takeEvery(ApiConstants.API_MOVE_QUARANTINE_INVENTORY_LOAD, getQuarantineInventorySaga); 
    yield takeEvery(ApiConstants. API_CHANGE_SKU_LOCATION_LOAD, getSKULocationSaga);
    yield takeEvery(ApiConstants. API_CHANGE_BIN_BOX_LOCATION_LOAD, changeBinBoxLocationSaga);
    yield takeEvery(ApiConstants.API_VIEW_CONFIGURE_MODAL_DATA_LOAD, getViewConfigureModalSaga); 
    yield takeEvery(ApiConstants.API_CONFIGURE_WAREHOUSE_LOAD, configureWarehouseSaga); 
    yield takeEvery(ApiConstants.API_GET_DASHBOARD_MENU_LOAD, getOnboardMenuSaga); 
    yield takeEvery(ApiConstants.API_GET_ON_BOARDING_EDIT_DATA_LOAD, getOnboardingEditDataSaga); 
    yield takeEvery(ApiConstants.API_UPLOAD_FILE_LOAD, uploadFileSaga); 
    yield takeEvery(ApiConstants.API_VIEW_STORAGE_DETAIL_LOAD, getStorageDetailsSaga); 
    yield takeEvery(ApiConstants.API_GET_CLIENT_DEDICATED_LOCATION_LOAD, getClientDedicatedLocationSaga); 
    yield takeEvery(ApiConstants.API_GO_LIVE_LOAD, goLiveSaga); 
    yield takeEvery(ApiConstants.API_GET_FILES_LOAD, getFilesSaga); 
    yield takeEvery(ApiConstants.API_SET_ATTACHMENT_AS_DEFUALT_LOAD, setattachemntAsDefaultSaga);
    yield takeEvery(ApiConstants.API_GET_REPORT_LIST_LOAD, getReportListSaga);
    yield takeEvery(ApiConstants.API_GET_DATE_REPORT_LIST_LOAD, getDateReportListSaga);
    yield takeEvery(ApiConstants.API_GET_GATEIN_REPORT_LIST_LOAD, getGateInReportListSaga);
    yield takeEvery(ApiConstants.API_GET_EXPAND_GATEIN_REPORT_LIST_LOAD, getExpandGateInReportListSaga);
    yield takeEvery(ApiConstants.API_GET_SEARCH_GATEIN_REPORT_LIST_LOAD, getSearchGateInReportListSaga);
    yield takeEvery(ApiConstants.API_GET_SAN_REPORT_LIST_LOAD, getSANReportListSaga);
    yield takeEvery(ApiConstants.API_GET_EXPAND_SAN_REPORT_LIST_LOAD, getExpandSANReportListSaga);
    yield takeEvery(ApiConstants.API_GET_GRN_REPORT_LIST_LOAD, getGRNReportListSaga);
    yield takeEvery(ApiConstants.API_GET_EXPAND_GRN_REPORT_LIST_LOAD, getExpandGRNReportListSaga);
    yield takeEvery(ApiConstants.API_GET_GAE_REPORT_LIST_LOAD, getGAEReportListSaga);
    yield takeEvery(ApiConstants.API_GET_EXPAND_GAE_REPORT_LIST_LOAD, getExpandGAEReportListSaga);
    yield takeEvery(ApiConstants.API_GET_OD_REPORT_LIST_LOAD, getODReportListSaga);
    yield takeEvery(ApiConstants.API_GET_EXPAND_OD_REPORT_LIST_LOAD, getExpandODReportListSaga);
    yield takeEvery(ApiConstants.API_GET_EXPAND_CARTON_DATA_LOAD, getExpandCartonListSaga);
    yield takeEvery(ApiConstants.API_GET_EXPAND_DATA_LOAD, getCartonListSaga);
    yield takeEvery(ApiConstants.API_GET_DIMENSION_LOAD, getDimensionSaga);
    yield takeEvery(ApiConstants.API_UPDATE_DIMENSION_LOAD, updateDimensionSaga);
    yield takeEvery(ApiConstants.API_CARTON_REQC_LOAD, getReQCListSaga);
    yield takeEvery(ApiConstants.API_CLOSE_CARTON_LOAD, closeCartonSaga);
    yield takeEvery(ApiConstants.API_DELETE_CARTON_LOAD, deleteCartonSaga);
    yield takeEvery(ApiConstants.API_PRINT_SPECIFIC_CARTON_STICKER_LOAD, printSpecificSticterSaga);
    yield takeEvery(ApiConstants.API_PRINT_ALL_CARTON_STICKER_LOAD, printAllSticterSaga);
    yield takeEvery(ApiConstants.API_PRINT_SPECIFIC_CARTON_PACKING_SLIP_LOAD, printSpecificPackingSlipSaga);
    yield takeEvery(ApiConstants.API_PRINT_ALL_CARTON_PACKING_SLIP_LOAD, printAllPackingSlipSaga);
    yield takeEvery(ApiConstants.API_SCAN_CARTON_SKU_LOAD, getScanSKUSaga);
    yield takeEvery(ApiConstants.API_WORK_ON_THIS_LOAD, workOnThisSaga);
    yield takeEvery(ApiConstants.API_MOVE_TO_RTO_LOAD, moveToRTOSaga);
    yield takeEvery(ApiConstants.API_REQC_SAVE_LOAD, saveReQcSaga);
    yield takeEvery(ApiConstants.API_CYCLE_COUNT_SCANNING_LOAD, cycleCountScanSaga);
    yield takeEvery(ApiConstants.API_CYCLE_COUNT_MASTER_DATA_LOAD, cycleCountMasterDataSaga);
    yield takeEvery(ApiConstants.API_CYCLE_COUNT_EXPAND_DATA_LOAD, cycleCountExpandDataSaga);
    yield takeEvery(ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_LIST_LOAD, getStorageSkuListSaga);
    yield takeEvery(ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_SELECT_LOAD, getStorageSkuSelectSaga);
    yield takeEvery(ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_QTY_UPDATE_LOAD, getStorageSkuQTYUpdateSaga);
    yield takeEvery(ApiConstants.API_CLOSE_CYCLE_COUNT_LOAD, closeCycleCountSaga);
    yield takeEvery(ApiConstants.API_CLOSE_CYCLE_APPROVE_REJECT_LOAD, ApproveRejectCycleCountSaga);
    yield takeEvery(ApiConstants.API_START_CYCLE_COUNT_LOAD, startCycleCountSaga);
    yield takeEvery(ApiConstants.API_CREATE_CLIENT_LOAD, createClientSaga);
    yield takeEvery(ApiConstants. API_EDIT_CLIENT_LOAD, editClientSaga);
    yield takeEvery(ApiConstants.API_CLIENT_INFO_LOAD, getClientDetailsSaga);
    yield takeEvery(ApiConstants.API_CREATE_CONTRACT_LOAD, createCONTRACTSaga);
    yield takeEvery(ApiConstants.API_START_ONBOARDING_LOAD, startOnBoardingSaga);
    

}

