const AppReferences = {
  activities: {
      av: "A-V",
      av1: "A-V1",
      av2: "A-V2",
      av3: "A-V3",
      a01: "A-01",
      a02: "A-02",
      a03: "A-03",
      a04: "A-04",
      a05: "A-05",
      a06: "A-06",
      a07: "A-07",
      a08: "A-08",
      a09: "A-09",
      a10: "A-10",
      a11: "A-11",
      a12:  "A-12",
      ax01: "AX-01",
      ax02: "AX-02",
      a21: "A-21",
      a16:"A-16",
      a22:"A-22",
      a23: "A-23",
      a40:"A-40",
      a41: 'A-41',
      a28: 'A-28',
      a30: 'A-30',
      a33: 'A-33',
      a35: 'A-35',
      a42: 'A-42'
    },
    opnfor:{
      salesOrder: 650000,
      wms: 100000,
      salesClient: 650050,
      createSo: 650051,
      onboard: 100001,
      itemMaster: 880000,
      warehouseMaster: 100006,
      GateIn: 110000,
      unloading: 120150,
      rtoB2b :729000,
      cycleCount: 'Z00001'
    },
    channel:{
      b2b: '23'
    },
    customStickering:{
      id:'33'
    }
}
export default AppReferences;