import React from 'react';

function CategoryIcon() {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.02178 0.0253689C0.637465 0.120604 0.287419 0.406523 0.105414 0.773805L0 0.986574V3.73129V6.47601L0.0960902 6.66883C0.247229 6.97202 0.419059 7.15117 0.709926 7.30884L0.979209 7.45475H3.72525H6.47129L6.73955 7.30935C7.03753 7.14785 7.16585 7.01534 7.33576 6.69334L7.4505 6.47601V3.73129V0.986574L7.30451 0.717422C7.14678 0.426694 6.96754 0.254947 6.6642 0.103881L6.47129 0.00783677L3.8104 0.000772825C2.34691 -0.00309957 1.09203 0.00796438 1.02178 0.0253689ZM10.5584 0.0253689C10.1741 0.120604 9.82406 0.406523 9.64206 0.773805L9.53664 0.986574V3.73129V6.47601L9.63273 6.66883C9.78387 6.97202 9.9557 7.15117 10.2466 7.30884L10.5159 7.45475H13.2619H16.0079L16.2762 7.30935C16.5742 7.14785 16.7025 7.01534 16.8724 6.69334L16.9871 6.47601V3.73129V0.986574L16.8412 0.717422C16.6834 0.426694 16.5042 0.254947 16.2008 0.103881L16.0079 0.00783677L13.347 0.000772825C11.8835 -0.00309957 10.6287 0.00796438 10.5584 0.0253689ZM1.02178 9.55742C0.637465 9.65265 0.287419 9.93857 0.105414 10.3059L0 10.5186V13.2633V16.0081L0.0960902 16.2009C0.247229 16.5041 0.419059 16.6832 0.709926 16.8409L0.979209 16.9868H3.72525H6.47129L6.73955 16.8414C7.03753 16.6799 7.16585 16.5474 7.33576 16.2254L7.4505 16.0081V13.2633V10.5186L7.30451 10.2495C7.14678 9.95875 6.96754 9.787 6.6642 9.63593L6.47129 9.53989L3.8104 9.53282C2.34691 9.52895 1.09203 9.54002 1.02178 9.55742ZM12.7723 9.54819C12.5178 9.58176 12.0004 9.729 11.7537 9.83811C9.9778 10.6235 9.10285 12.6301 9.72691 14.4863C10.0782 15.5312 10.993 16.4455 12.0383 16.7966C14.4151 17.5949 16.8511 15.9523 16.994 13.4548C17.1007 11.5896 15.7633 9.90462 13.9211 9.58351C13.5762 9.52338 13.0769 9.50806 12.7723 9.54819Z" fill="#FEAE3F"/>
        </svg>

    );
}

export default CategoryIcon;