import React, { useState } from 'react';
import B2CCreateNewManifest from './B2CCreateNewManifest';
import PendingManifest from './PendingManifest';
import PendingRTOReturnB2C from './PendingRTOReturnB2C';
import ProcessRTOB2C from './ProcessRTOB2C';

function RTOReturnB2CIndex(props: any) {
    const [process, setProcess] = useState("1");
    return (
        <div>
            {true ?
                <PendingRTOReturnB2C /> :
                <>
                    {true ?
                        <B2CCreateNewManifest /> :
                        <>
                            {true ? <ProcessRTOB2C /> : <PendingManifest />}
                        </>

                    }
                </>
            }
        </div>
    );
}

export default RTOReturnB2CIndex;