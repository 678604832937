import { CaretRightOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { useState } from 'react';
import NoData from '../../CustomComponent/NoData';
import { isArrayNotEmpty } from '../../Globals/Helper';
import "../Report/Report.scss"

let Arr = [1, 2, 3]

function KittingReport(props: any) {
    const [expandRow, setExpandRow] = useState<any>();

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
        }
    }
    const skuInvoiceHeaderView = () => {
        try {
            return (
                <Row className='table-header invoice-header'>
                    <Col span={8}>
                        {"SKU, Description"}
                    </Col>
                    <Col span={8} className=' d-flex jc-c'>
                        {"Ordered Qty"}
                    </Col>
                    <Col span={8} className=' d-flex jc-c'>
                        {"Kitting Qty"}
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in skuInvoiceHeaderView::" + ex)
        }
    }
    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={4}>
                    {"Sales Order #"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Client Name"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Customer Name"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Channel"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Received Date"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Ship By Date"}
                </Col>
            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        return (
            <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={4} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='title-blue'>
                            {"001"}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {"Mamaearth"}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {"Cust-01"}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {"Amazone"}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {"06-Jan-2023"}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {"09-Jan-2023"}
                        </div>
                    </Col>
                    {open && <Col span={24} style={{ padding: 10, marginTop: 10, background: "#FFFFFF", borderRadius: 6, }}>
                        {skuInvoiceHeaderView()}
                        <div className='scroll'>
                            {[1, 2].map((x: any) => {
                                return (
                                    <Row className='content-container border-blue d-flex align-c jc-sb mt-10'>
                                        <Col span={8} className='text-1'>{"SKU-01 , This is SKU-01"}</Col>
                                        <Col span={8} className=' d-flex align-c jc-c'>
                                            <div className='text-1'>
                                                {"2"}
                                            </div>
                                        </Col>
                                        <Col span={8} className=' d-flex align-c jc-c'>
                                            <div className='text-1'>
                                                {"2"}
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </div>
                    </Col>}
                </Row>
            </div >
        )
    }
    return (
        <div className='GateInReport-Container'>
            {isArrayNotEmpty(Arr) ?
                <div style={{ padding: 20 }}>
                    {tableHeaderView()}
                    <div className='table-scroll'>
                        {Arr.map((x: any, index: any) => tableBodyView(x, index))}
                    </div>
                </div>
                :
                <div className='p-20'>
                    <NoData
                        className='mt-45'
                        description={
                            <>There is no sales order available in <span className='redhat-bold ml-5'>Kitting Report</span></>
                        }
                    />
                </div>
            }
        </div>
    );
}

export default KittingReport;