import { EyeFilled, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Layout, Progress, Table } from "antd";
import { Link } from "react-router-dom";
import AppConstants from "../../../Globals/AppConstants";
import { AppImages } from "../../../Globals/AppImages";
import SubHeader from "../../Header/Header";

function CustomDetailedQCListing() {

    const suffix = (

        <SearchOutlined
            style={{
                fontSize: 20,
                color: 'black',
            }}
        />
    );

    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <Input placeholder="Search By Client Name / GateIn ID" className="search-input" prefix={suffix} />

                <div className="help-icon" style={{marginLeft:10,cursor:"pointer"}}>
                    <img src={AppImages.helpIcon} alt="help-icon" />
                </div> */}
            </div>
        )
    }

    const columns: any = [
        {
            title: "GateIn ID",
            dataIndex: 'gatein',
            width: 200,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='gatein-text'  >
                        <span title={item} className='overflow-tagline'>{item}</span>
                    </div>
                )
            }
        },
        {
            title: "Client",
            dataIndex: 'client',
            width: 400,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='client-text'>
                        <span title={item} className='overflow-tagline'> {item}</span>
                    </div>
                )
            }
        },
        {
            title: AppConstants.invoiceNum,
            dataIndex: "invoiceNo",
            width: 500,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Status",
            dataIndex: "",
            align: "left",
            width: 800,
            render: (item: any) => {
                return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className='text' style={{marginRight:10}}>{"15%  Custom QC on Each Box"}</div>
                    <div className="table-process"><Progress percent={30} size="small" status="active" /></div>
                    
                </div>
                )
            },
        },
        {
            title: "Action",
            dataIndex: "",
            align: "center",
            width: 200,
            render: (item: any) => {
                return (
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <Link to="/customdetailedqc">
                        <Button className="eye-btn" style={{cursor:"pointer"}}>
                            <EyeFilled />
                        </Button>
                        </Link>
                    </div>
                )
            },
        },
    ];

    const data = [
        {
            key: '1',
            gatein: "WH01-CUST01-20220218-140309",
            client: "Mama Earth",
            invoiceNo: "IN8732872232",
        },
        {
            key: '1',
            gatein: "WH01-CUST01-20220218-140309",
            client: "Mama Earth",
            invoiceNo: "IN8732872232",
        },
        {
            key: '1',
            gatein: "WH01-CUST01-20220218-140309",
            client: "Mama Earth",
            invoiceNo: "IN8732872232",
        },
    ];
    const listingView = () => {
        try {
            return (
                <div className='table-screen-container mt-45'>
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                    />
                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    return (
        <div className='sendsan-main-container'>
            <SubHeader
            title={"Custom/Detailed QC"}
            btnView="false"
            inputView="true"
            paginationView="false">
                {childrenView()}
            </SubHeader>
            {listingView()}
        </div>

    )
}


export default CustomDetailedQCListing;
