import { Col, Input, message, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { AppModuleIds } from '../../Globals/AppReferenceIds';
import AppReferences from '../../Globals/AppReferences';
import { isArrayNotEmpty } from '../../Globals/Helper';
import { clearReducerAction, cycleCountScanningAction, startCycleCountAction } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId, getWarehouseInfo } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';

function CycleCount(props: any) {
    const [enableTable, setEnableTable] = useState<any>();
    const dispatch = useDispatch();
    const [cycleCountId, setCycleCountId] = useState("");
    const reducerState = useSelector((state: any) => state.ProcessReducerState);
    const { cycleCountData, startCycleCountData } = reducerState;
    const [onload, setOnload] = useState({
        startOnload: false,
        scanOnload: false
    });
    const navigate = useNavigate();
    const [openModal, setopenModal] = useState(false);
    const cycleCountDetails = cycleCountData?.rawData;

    useEffect(() => {
        if (onload.startOnload && !reducerState.onload) {
            setOnload({ ...onload, startOnload: false });
            if (startCycleCountData?.msg == "Cycle Count Started Successfully") {
                message.success(startCycleCountData?.msg);
                navigate('/start-cycle-count', { state: { startCycleCountData: startCycleCountData, cycleCountId: cycleCountId } })
            }
            else {
                setopenModal(true)
            }
        }
    }, [onload.startOnload, reducerState.onload]);

    useEffect(() => {
        if (onload.scanOnload && !reducerState.onload) {
            setOnload({ ...onload, scanOnload: true })
            if (cycleCountData?.msg == "Warehouse Location Added to Cycle Count Successfully") {
                message.success(cycleCountData?.msg);
            }
            else {
                message.error(cycleCountData?.msg);
            }
        }
    }, [onload.scanOnload, reducerState.onload])

    const getScannedData = (value: any) => {
        if (cycleCountId) {
            let payload = {
                opnfor: AppReferences.opnfor.cycleCount,
                activity: AppReferences.activities.av,
                transaction: cycleCountId,
                where: value,
                warehouse: getWareHouseId(),
                user: getUserInfo()?.UserName
            }
            console.log("payload", payload);
            dispatch(cycleCountScanningAction(payload));
            setEnableTable(true);
            setOnload({ ...onload, scanOnload: true })
        }
        else {
            message.error("Create Cycle Count ID");
        }
    }

    const startCycleCount = () => {
        let payload = {
            opnfor: AppReferences.opnfor.cycleCount,
            activity: AppReferences.activities.a01,
            transaction: cycleCountId,
            warehouse: getWareHouseId(),
            user: getUserInfo()?.UserName
        }
        dispatch(startCycleCountAction(payload));
        setOnload({ ...onload, startOnload: true });
    }



    const pageTitle = {
        title: "Cycle Count",
        warehouse: getWarehouseInfo()?.name
    }

    const handleCreateCycleCount = () => {
        const currentDate = new Date();
        const date = `${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}-${currentDate.getHours()}${currentDate.getMinutes()}`;
        const cycleId = `${getWareHouseId()}-${date}`;
        setCycleCountId(cycleId);
        dispatch(clearReducerAction('cycleCountData'))
    }

    const header = () => {
        return (
            <SubHeader
                title={"Cycle Count"}
                paginationView="false"
                inputView="true"
                btnView="true"
            >
                <Row gutter={10} className='d-flex flex-end'>
                    <Col span={2}></Col>
                    <Col span={4}>
                        <div
                            onClick={() => handleCreateCycleCount()}
                            className='default-btn'
                            style={{ cursor: "pointer" }}>
                            {'Create Cycle Count'}
                        </div>
                    </Col>
                    <Col span={5}>
                        <div className='d-flex align-c ' style={{ background: 'white', height: 32 }}>
                            {<span className='ml-10'>
                                {cycleCountId}
                            </span>}
                        </div>
                    </Col>
                    <Col span={8}>
                        <div>
                            <Input placeholder='Scan Location ID'
                                onPressEnter={(e: any) => getScannedData(e.target.value)}
                            />
                        </div>
                    </Col>
                    <Col span={4}>
                        {isArrayNotEmpty(cycleCountDetails?.lineitems) &&
                            // <Link to={'/start-cycle-count'} state={{cycleCountId: cycleCountId}}>
                            <div
                                onClick={() => startCycleCount()}
                                className='default-btn'
                                style={{ cursor: "pointer" }}
                            >
                                {'Start Cycle Count'}
                            </div>
                            // </Link>
                        }
                    </Col>
                </Row>
            </SubHeader >
        )
    }

    const titleContainer = () => {
        try {
            return (
                <Row>
                    <Col span={10}>
                        <div className='text'>{'Location ID'}</div>
                    </Col>
                    <Col span={8}>
                        <div className='text '>{'Storage Type'}</div>
                    </Col>
                    <Col span={6}>
                        <div className='text'>{'Structure Type'}</div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }

    const tableView = (data: any) => {
        try {
            return (
                <div>
                    <div className="content-container-gray" style={{ marginTop: 10 }}>
                        <Row>
                            <Col span={10} className=' d-flex align-c'>
                                <div className='text'> {data?.LocationId}</div>
                            </Col>
                            <Col span={8} className='d-flex align-c'>
                                <div className='text'> {data?.SystemType}</div>
                            </Col>
                            <Col span={6} className='d-flex align-c'>
                                <div className='text'> {data?.StructureType}</div>
                            </Col>
                        </Row>
                    </div>
                </div>
            )
        } catch (error) {
            console.log("Error in tableview::", error)
        }
    }

    const errorModal = () => {
        return (
            <Modal
                title={"Cycle Count Info:"}
                className='modal-view'
                // onCancel={()=>setValidationPopup({...validationPopup, mismatched: false})}
                // onOk={}
                style={{ width: "55%" }}
                open={openModal}
                footer={null}
            >

                <div className='p-20 redhat-semibold font-16'>
                    {startCycleCountData?.msg}
                </div>
                <div className='d-flex flex-end'>
                    <AppButtons text={'Okay'}
                        onClick={() => setopenModal(false)}
                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                    />
                </div>
            </Modal>
        )
    }

    return (
        <>
            <NavbarLayout
                titleContent="main-head"
                process={pageTitle}
                initial={AppModuleIds.main}
                selectedMain={'10'}
            />
            <div className=''>
                {header()}
            </div>
            <div className='p-10'>
                {isArrayNotEmpty(cycleCountDetails?.lineitems) &&
                    <div style={{ marginTop: 10 }}>
                        <div className='header-part'>
                            {titleContainer()}
                        </div>
                        <div className='expand-collape-container'>
                            {isArrayNotEmpty(cycleCountDetails?.lineitems) ?
                                (cycleCountDetails?.lineitems || []).map((data: any) =>
                                    tableView(data)
                                )
                                :
                                <NoData />
                            }
                        </div>
                    </div>}
            </div>
            {errorModal()}
        </>
    );
}

export default CycleCount;