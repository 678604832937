

import { Pie } from '@ant-design/plots';
import React from 'react';

function CharComponent(props: any) {
  let { data } = props;


  interface PieChartData {
    type: any;
    value: number;
  }

  const pieChartData: any = [
    {
      type: "Sellable",
      value: 80,
    },
    {
      type: "Non-sellable",
      value: 20,
    },
  ];

  const cfg: any = {
    appendPadding: 10,
    data: data,
    angleField: "Quantity",
    colorField: "Name",
    radius: 1,
    legend: false,
    label:false,
    //innerRadius: 0.5,
    // label: {
    //   // type: "inner",
    //   offset: "-50%",
    //   content: "{value}",
    //   style: {
    //     textAlign: "center",
    //     fontSize: 14
    //   }
    // },
    // interactions: [{ type: "element-selected" }, { type: "element-active" }],
    statistic: {
      title: false as const,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }
        // formatter: function formatter() {
        //   return `total\n134`;
        // }
      }
    },
    theme: {
      colors10: [
        '#57BFAA',
        '#CC3333'
        
      ]
    }
    
  };
  const config = cfg;
  return <Pie {...config} />;
}

export default CharComponent;