import { CloseOutlined, DeleteFilled, EditFilled, } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Drawer, Form, Input, message, Modal, Radio, Row, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import AppConstants from '../../../Globals/AppConstants';
import SubHeader from '../../Header/Header';
import NavbarLayout from '../../NavbarLayout/NavbarLayout';
import '../QC.scss';
import AppButtons from '../../../CustomComponent/AppButtons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getUserInfo, getWareHouseId } from '../../../Utils/SessionStorage';
import { useDispatch, useSelector } from 'react-redux';
import {
    addSKUBatchDetailsAction,
    completeReceiveInProgressAction,
    deleteAction,
    deleteReceiveInProgressAction,
    getConditionAction,
    getMetaDataAction,
    getMoveToBinMetaDataAction,
    getReceivingInProgressBarCodeAction,
    getReceivingInProgressViewAction,
    getSkuAction,
    getSKUBatchDetailsAction,
    PrintSpecificCartonStickerAction,
    saveSkuAction
} from '../../../Store/Actions/ProcessWMSAction';
import { formatData } from '../../Helper/Helper';
import { AppImages } from '../../../Globals/AppImages';
import moment from 'moment';
import { fabClasses } from '@mui/material';
import NoData from '../../../CustomComponent/NoData';
import AppReferences from '../../../Globals/AppReferences';
import CartonningStickeringModal from '../../OrderProcessing/CartonningStickeringModal';
import WMSIcon4 from '../../../Images/WMSIcon4';
import SpinLoader from '../../SpinLoader';
import { isArrayEmpty, isArrayNotEmpty } from '../../../Globals/Helper';

const { Option } = Select;
let tempArray: any = [];
let weHere: any = [];
let fieldArrayval: any = [];


function StartRecievingView() {
    const dispatch = useDispatch();
    const location = useLocation();
    const reducerState = useSelector((state: any) => state?.ProcessReducerState);
    const { recievingInProgressViewData,
        recievingInProgressBarcode,
        conditionData,
        skuData,
        recievingInProgressViewOnLoad,
        saveSkuOnLoad,
        completeReceiveInProgressOnLoad,
        skuBatchDetail,
        onload,
        deleteOnload,
        cartonStickeringList,
        moveTOBinMetaList
    } = reducerState;
    const client_id = location?.state?.client_id;
    const transId = location?.state?.transId;
    const stage = location?.state?.stage;
    const [barcode, setBarcode] = useState<any>({
        barcodeValue: null,
        binId: null
    });
    const [openDrawer, setOpenDrawer] = useState<any>({ isOpen: false });
    const [formValues, setFormValues] = useState<any>({ sku: "", condtion: "", qty: "", bin: "" });
    const [deleteSKU, setDeleteSKU] = useState<any>();
    const userInfo = getUserInfo();
    const warehouseId = getWareHouseId();
    const [gateinDetails] = (recievingInProgressBarcode?.header || []);
    const [viewListOnLoad, setViewListOnLoad] = useState<any>();
    const rawData = recievingInProgressViewData?.rawData;
    const bodyData = recievingInProgressViewData?.body;
    const [filedArrayforCondition, setFiledArrayforCondition] = useState<any>();
    const conditionDropdown = conditionData?.rData;
    const skuDropdown = skuData?.rData;
    const [enabledrawer, setEnableDrawer] = useState<any>(false);
    const [saveOnLoad, setSaveOnLoad] = useState<any>(false);
    const [form]: any = Form.useForm();
    const [attributeForm]: any = Form.useForm();
    const [editValue, setEditValue] = useState<any>();
    const navigate = useNavigate();
    const [completeReceive_In_ProgressOnLoad, setCompleteReceive_In_ProgressOnLoad] = useState<any>(false);
    const [showModal, setShowMdal] = useState<any>({
        qtyMatch: false,
        delete: false,
        data: null
    })
    const [attributeDetails, setAttributeDetails] = useState<any>({ sku: "", batch: "", manuFactDate: "", importMth: "", expiryDate: "", mrp: "" });
    const [addBatcOnload, setAddBatchOnload] = useState(false);
    const isRTOB2B = recievingInProgressBarcode?.isThisRTOB2B == 'Y';
    const [scanModal, setScanModal] = useState<any>(false);
    const [stickerOnload, setStickerOnload] = useState(false);
    const [rtoB2bOnload, setRtoB2bOnload] = useState(false);
    const [IsScan, setIsScan] = useState(false);
    const [onLoad, setOnLoad] = useState<any>({
        moveOnload: false,
        value: false
    })

    const [binQty, setBinQty] = useState<any>("")


    useEffect(() => {
        if (viewListOnLoad == true && recievingInProgressViewOnLoad == false) {
            try {
                setViewListOnLoad(false);
                setEnableDrawer(true)
                IsScan && message.success("Box Scanned Successfully");
                setIsScan(false)
                form.resetFields();
                let val = bodyData?.lineMeta[3]?.mR?.split("|");
                setFiledArrayforCondition(val[9]);
                tempArray = [...formatData(bodyData)];

            } catch (error) {
                console.log("Error::", error)
            }
        }
    }, [viewListOnLoad, recievingInProgressViewOnLoad]);

    useEffect(() => {
        if (rtoB2bOnload == true && recievingInProgressViewOnLoad == false) {
            try {
                let msg = recievingInProgressViewData?.msg;
                if (msg == "Carton Box Scanned Successfully") {
                    setRtoB2bOnload(false);
                    setEnableDrawer(true)
                    message.success(recievingInProgressViewData?.msg);
                    getSubheaderApi();
                    form.resetFields();
                }
                else {
                    message.error(recievingInProgressViewData?.msg);
                }
            } catch (error) {
                console.log("Error::", error)
            }
        }
    }, [rtoB2bOnload, recievingInProgressViewOnLoad]);


    const conditionDatas = [
        {
            id: "1",
            label: "Good"
        }, {
            id: "2",
            label: "Bad"
        }
    ];
    useEffect(() => {
        if (formValues) {
            try {
                weHere = tempArray[0]?.names;
                fieldArrayval = tempArray[1]?.values.split(',');
            }
            catch (ex) {
                console.log("Error in Seperate Insert", ex);
            }
        }
    }, [formValues]);

    useEffect(() => {
        if (transId && client_id) {
            getSubheaderApi();
        }
    }, [transId]);


    useEffect(() => {
        if (barcode?.binId !== null) {
            let exist =recievingInProgressBarcode?.lineitemstwo?.find(({BinID}: any) => BinID == barcode?.binId);
            console.log("exist binID",exist)
            let containString = recievingInProgressBarcode?.lineitemstwo?.find(({ Barcode }: any) => Barcode?.includes(barcode?.barcodeValue));
            if (exist) {
                getRIPview();
            }
            else if (!containString) {
                message.error('Please Scan valid Barcode');
            }
        }else if(barcode?.barcodeValue !== null) {
            let exist = recievingInProgressBarcode?.lineitemstwo?.find(({ Barcode }: any) => Barcode == barcode?.barcodeValue)
            console.log("exist",exist)
            let containString = recievingInProgressBarcode?.lineitemstwo?.find(({ Barcode }: any) => Barcode?.includes(barcode?.barcodeValue));
            if (exist) {
                getRIPview();
            }
            else if (!containString) {
                message.error('Please Scan valid Barcode');
            }
        }
    }, [barcode?.binId, barcode?.barcodeValue]);

    useEffect(() => {
        if (stickerOnload && !reducerState.specificStickerOnLoad) {
            setStickerOnload(false);
            setScanModal(true);
        }
    }, [stickerOnload, reducerState.specificStickerOnLoad])

    useEffect(() => {
        if (onLoad?.moveOnload && !reducerState?.onLoad) {
            setFormValues({ ...formValues, moveToBin: onLoad?.value })
            setOnLoad(false)
        }
    }, [onLoad?.moveOnload, reducerState?.onLoad])

    useEffect(() => {
        if (attributeDetails?.sku || formValues?.sku) {
            getSkuBatches();
        }
    }, [attributeDetails?.sku, formValues?.sku]);

    useEffect(() => {
        if (showModal?.onload && !deleteOnload) {
            getSubheaderApi();
            setShowMdal({ ...showModal, onload: false });
            getRIPview();
        }
    }, [showModal?.onload, deleteOnload]);

    useEffect(() => {
        if (addBatcOnload && !onload) {
            getSkuBatches();
            clearAttributeForm();
            setAddBatchOnload(false);
            setOpenDrawer({ ...openDrawer, openAttribute: false });
        }
    }, [addBatcOnload, onload])

    const clearAttributeForm = () => {
        attributeForm.resetFields();
        setAttributeDetails({ sku: "", batch: "", manuFactDate: "", importMth: "", expiryDate: "", mrp: "" });
    }

    const getMoveToBinMetaData = (e: any) => {
        let payload = {
            activity: AppReferences.activities.a09,
            opnfor: 130300,
            transaction: formValues.batch,
            warehouse: warehouseId,
            fieldArray: formValues?.sku,
            where: formValues?.condition
        }
        dispatch(getMoveToBinMetaDataAction(payload))
        setOnLoad({ ...onload, moveOnload: true, value: e?.target?.checked })
    }

    const getMoveToBin = (e: any) => {
        if (formValues?.batch && formValues?.sku && formValues?.condition) {

            getMoveToBinMetaData(e)
            form.setFieldsValue({
                ['moveToBin']: "",
            });
            if (e?.target?.checked === false) {
                form.setFieldsValue({
                    ['qty']: "",
                });
            }
            setBinQty("")
        } else {
            message.error("Select the Attribute, SKU and Condition before moving to bin")
        }


    }

    const setMoveToBin = (e: any) => {
        setFormValues({ ...formValues, bin: e })
        let binQtyLimit = moveTOBinMetaList?.lineitems.find((item: any) => item?.BinID == e)
        setBinQty(binQtyLimit?.AvailableCapacity)
    }


    const getSkuBatches = () => {
        let payload = {
            opnfor: 130300,
            activity: AppReferences.activities.a03,
            transaction: gateinDetails?.GeInRecordNo,
            where: (attributeDetails?.sku || formValues?.sku)
        }
        dispatch(getSKUBatchDetailsAction(payload))
    }
    const addSKUBatch = () => {
        let findBatch = skuBatchDetail?.lineitems?.find((item: any) => item?.Lines[0]?.Value === attributeDetails.batch)
        if (findBatch) {
            message.error("Batch/Lot Id already exist")
        } else {
            let payload = {
                opnfor: 130300,
                activity: AppReferences.activities.a04,
                transaction: gateinDetails?.GeInRecordNo,
                where: `1|${attributeDetails.batch},2|${attributeDetails.manuFactDate},3|${attributeDetails.importMth},4|${attributeDetails.expiryDate},5|${attributeDetails.mrp}`,
                fieldArray: attributeDetails.sku,
                user: getUserInfo()?.UserName
            }
            dispatch(addSKUBatchDetailsAction(payload))
            setAddBatchOnload(true)
        }

    }

    useEffect(() => {
        if (saveOnLoad == true && saveSkuOnLoad == false) {
            setSaveOnLoad(false)
            getRIPview();
            form.resetFields();
            setFormValues([{ sku: "", condtion: "", qty: "" }]);
            tempArray = [];
            setOpenDrawer({ ...openDrawer, isOPen: false });
            getSubheaderApi();
        }
    }, [saveOnLoad, saveSkuOnLoad])

    useEffect(() => {
        if (completeReceive_In_ProgressOnLoad == true && completeReceiveInProgressOnLoad == false) {
            navigate("/process/QC");
        }
    }, [completeReceive_In_ProgressOnLoad, completeReceiveInProgressOnLoad])


    const getSubheaderApi = () => {
        let payload = {
            "activity": AppReferences.activities.av,
            "customer": client_id,
            "opnfor": 130300,
            " tableNo": "",
            "transaction": transId,
            "user": userInfo?.UserName,
            "warehouse": warehouseId,
            "where": ""
        }
        dispatch(getReceivingInProgressBarCodeAction(payload));
    }

    const getRIPview = () => {
        let payload = {
            "activity": AppReferences.activities.a01,
            "customer": recievingInProgressBarcode?.header[0]?.cid,
            "fieldArray": recievingInProgressBarcode?.header[0]?.ID,
            "opnfor": "130300",
            "tableNo": "",
            "transaction": barcode?.binId ? `${barcode?.barcodeValue}|${barcode?.binId}` : barcode?.barcodeValue,
            "user": userInfo?.UserName,
            "warehouse": warehouseId,
            "where": recievingInProgressBarcode?.header[0]?.ino,
        }
        console.log("getReceivingInProgressViewAction", payload);

        dispatch(getReceivingInProgressViewAction(payload));
        setViewListOnLoad(true);
    }

    const scanRTOB2BBox = (value: any) => {
        let exist = recievingInProgressBarcode?.lineitems?.find(({ CartonNumber }: any) => CartonNumber == value);
        let match = recievingInProgressBarcode?.lineitems?.find(({ CartonNumber }: any) => CartonNumber?.includes(value));
        let [details] = recievingInProgressBarcode?.header;
        if (exist) {
            let payload = {
                "opnfor": "130300",
                "activity": AppReferences.activities.a08,
                "transaction": value,
                "where": recievingInProgressBarcode?.RawSOID,
                "warehouse": details?.PoReleaseRecNumber,
                "fieldArray": recievingInProgressBarcode?.RawSOReleaseNumber,
                "user": userInfo?.UserName,
            }
            dispatch(getReceivingInProgressViewAction(payload));
            setRtoB2bOnload(true);
        }
        else if (!match) {
            message.error("Box Not Found")
        }
    }


    useEffect(() => {
        if (openDrawer?.isOPen == true || openDrawer?.openAttribute) {
            getSKUDropdownApi();
            getCondtionDropdownApi();

        }
        else if (openDrawer?.isOPen == false) {
            form.resetFields();
            setFormValues(null);
        }
        if (openDrawer?.isOPen == true) {
            getSKUDropdownApi();
            getCondtionDropdownApi();
        }

    }, [openDrawer, openDrawer,]);


    const getSKUDropdownApi = () => {
        let payload = {
            "customer": client_id,
            "fieldarray": `SELECT pol.RecordNo, CONCAT(pol.ItemID, ', ', Description) AS Description FROM PurchaseOrderLines pol INNER JOIN ItemMaster im ON pol.ItemId = im.ItemId INNER JOIN PurchaseOrders po ON pol.POID = po.POID AND po.ClientID = im.ClientID WHERE po.WarehouseID = '${warehouseId}' AND po.ClientID = '${client_id}' AND po.POID = '${recievingInProgressBarcode?.header[0]?.ID}'`,
            "opnfor": 2,
            "tableno": 16,
            "warehouse": warehouseId,
            "where": 2
        }
        dispatch(getSkuAction(payload));
    }

    const getCondtionDropdownApi = () => {
        let payload = {
            "customer": client_id,
            "fieldarray": filedArrayforCondition,
            "opnfor": 2,
            "tableno": 16,
            "warehouse": warehouseId,
            "where": 4
        }
        dispatch(getConditionAction(payload));
    }

    const addSku = () => {
        if (formValues?.moveToBin === true) {
            let payload = {
                opnfor: 130300,
                activity: AppReferences.activities.a05,
                Transaction: formValues?.batch,
                //fieldArray: `${fieldArrayval[0].replaceAll("'", '')}|${formValues?.sku}|${barcode}|${formValues?.condition}|${formValues?.qty}|${formValues?.batch}|${getUserInfo()?.UserName}`,
                User: getUserInfo()?.UserName,
                where: `${fieldArrayval[0].replaceAll("'", '')}|${formValues?.sku}|${barcode?.barcodeValue}|${formValues?.condition}|${formValues?.qty}|${formValues?.batch}|${getUserInfo()?.UserName}|${formValues?.bin}`

            }
            console.log("payload", payload)
            dispatch(saveSkuAction(payload));

        } else {
            let payload = {
                opnfor: 130300,
                activity: AppReferences.activities.a05,
                Transaction: formValues?.batch,
                fieldArray: `${fieldArrayval[0].replaceAll("'", '')}|${formValues?.sku}|${barcode?.barcodeValue}|${formValues?.condition}|${formValues?.qty}|${formValues?.batch}|${getUserInfo()?.UserName}`,
                User: getUserInfo()?.UserName
            }
            console.log("payload 2", payload)
            dispatch(saveSkuAction(payload));
        }

    }

    const saveSkuApi = () => {
        if (formValues?.isEdit) {
            editSkuApi()
        }
        else {

            addSku()
        }
        setSaveOnLoad(true);
    }

    const editSkuApi = () => {
        if( formValues?.moveToBin && isArrayEmpty(skuBatchDetail?.lineitems)) {
            message.error("Select Attribute") 
        } else{
            let payload = {
                opnfor: 130300,
                activity: AppReferences.activities.a06,
                transaction: `${editValue?.AttributeID ? editValue?.AttributeID : ""}|${formValues?.batch ? formValues?.batch == editValue?.AttributeID ? " " : formValues?.batch : ""}`,
                where: `${editValue?.PoRelHeaderRecNo}|${editValue?.PoLineRecNo}|${editValue?.BoxBarcode}|${editValue?.ConditionType}`,
                fieldArray: `${formValues?.sku}|${formValues?.condition}|${formValues?.qty}|${formValues?.batch}`     // Actual values user entered         user: user     
            }
            dispatch(saveSkuAction(payload));
        }
      

    }

    const deleteSKuApi = () => {
        showModal.delete = false;
        setShowMdal({ ...showModal })
        let sku = showModal.data;
        let payload = {
            "opnfor": 130300,
            "activity": AppReferences.activities.a07,
            "transaction": `${sku?.AttributeID ? sku?.AttributeID : '0000'}`,
            "where": `${sku?.PoRelHeaderRecNo}|${sku?.PoLineRecNo}|${sku?.BoxBarcode}|${sku?.ConditionType}`,
            "fieldArray": `${sku?.AttributeID ? sku?.AttributeID : '0000'}`,
            "user": getUserInfo()?.UserName,
            // "warehouse": getWareHouseId()
        }
        dispatch(deleteAction(payload));
        setShowMdal({ ...showModal, onload: true });
    }

    const completeReceiveInProgressApi = () => {
        let payload = {
            "activity": AppReferences.activities.ax01,
            "customer": client_id,
            "opnfor": "130300",
            "transaction": transId,
            "user": userInfo?.UserName,
            "warehouse": warehouseId,
            "fieldArray": getUserInfo()?.CorporateID,
        }
        dispatch(completeReceiveInProgressAction(payload));
        setCompleteReceive_In_ProgressOnLoad(true);
    }

    const printSticker = () => {
        let payload = {
            "opnfor": AppReferences.opnfor.salesOrder,
            "activity": "A-33",
            "transaction": recievingInProgressBarcode?.SOID,
            "where": '',
            "fieldArray": 'Y',
            "user": getUserInfo()?.UserName
        }
        dispatch(PrintSpecificCartonStickerAction(payload))
        setStickerOnload(true)
    }


    const completeOnHandle = () => {
        try {
            let isQtyMatched: any;
            recievingInProgressBarcode?.QuantityMatching?.map((x: any) => {
                if (isQtyMatched != "No") {
                    isQtyMatched = x?.QuantityMatching
                    if (x?.QuantityMatching == "No") {
                        isQtyMatched = "No";
                    }
                }

            });
            if (isQtyMatched == "Yes") {
                completeReceiveInProgressApi();
            }
            else {
                setShowMdal({ ...showModal, qtyMatch: true })
            }
        } catch (error) {
            console.log("Error in completeOnHandle::", error)
        }
    }

    const deleteSku = (item: any) => {
        setShowMdal({ ...showModal, data: item, delete: true });
        setDeleteSKU({ ...deleteSKU, sku: item?.PoLineRecNo, condition: item?.ConditionType, qty: item?.Quantity });
    }

    const setData = (item: any) =>{
        setBarcode({ ...barcode, barcodeValue: item?.Barcode, binId: item?.BinID })
    }

    const barList = (dataList: any) => {
        try {
            return (
                <div className='bar-listing-container-1'>
                    <Form form={form}>
                        <Form.Item name='scan'>
                            <Input
                                placeholder='Scan Box-Sticker barcode'
                                autoFocus
                                onPressEnter={(e: any) => { e.target.value && setBarcode({ ...barcode, barcodeValue: (e.target.value)?.toUpperCase(), binId: null }); setIsScan(true) }}
                                onPaste={(e: any) => { e.target.value && setBarcode({ ...barcode, barcodeValue: (e.target.value)?.toUpperCase(), binId: null }); setIsScan(true) }}
                                onChange={(e: any) => { e.target.value && setBarcode({ ...barcode, barcodeValue: (e.target.value)?.toUpperCase(), binId: null }); setIsScan(true) }}
                            />
                        </Form.Item>
                    </Form>
                    <div className='radio-container' style={{ height: "75vh", overflow: "auto" }}>
                        {(dataList || []).map((item: any) => {
                            let scanned_bar_code = item?.Barcode == barcode?.barcodeValue;
                            let isBin = item?.BinID == barcode?.binId
                            return (
                                <div
                                    onClick={() => setData(item)}
                                    className={!scanned_bar_code ? 'barcode-button' : isBin ? "barcode-button-1" : 'barcode-button'}>
                                    <div className='d-flex align-c jc-sa'>
                                        {item?.Lines?.length != 0 ?
                                            <>
                                                {item?.Lines?.map((x: any) => {
                                                    return (
                                                        <>
                                                            {item?.Lines?.length == 2 ?
                                                                <>
                                                                    {x?.Condition == "Good" ?
                                                                        (x?.Condition != "-" && <div className={item?.BinID === null ? 'd-flex align-c bin-container-green' : 'd-flex align-c movebin1-container-green'}>
                                                                            {x?.Qty}
                                                                        </div>)
                                                                        :
                                                                        (x?.Condition != "-" && <div className={item?.BinID === null ? 'd-flex align-c bin-container-red' : 'd-flex align-c movebin1-container-red'}>
                                                                            {x?.Qty}
                                                                        </div>)
                                                                    }
                                                                </> :
                                                                <>
                                                                    {x?.Condition == "Good" ?
                                                                        (x?.Condition != "-" && <div className={item?.BinID === null ? 'd-flex align-c bin-container-green' : 'd-flex align-c movebin-container-green'}>
                                                                            {x?.Qty}
                                                                        </div>)
                                                                        :
                                                                        (x?.Condition != "-" && <div className={item?.BinID === null ? 'd-flex align-c bin-container-red' : 'd-flex align-c movebin-container-red'}>
                                                                            {x?.Qty}
                                                                        </div>)
                                                                    }
                                                                </>
                                                            }

                                                        </>
                                                    )
                                                })}
                                            </>
                                            :
                                            <div className='redhat-regular'>
                                                No SKU Added
                                            </div>}
                                        {item?.BinID === null ?
                                            <div className='rip-count'>{item?.SequenceNo + "/" + item?.RecievedBoxQty}</div> :
                                            <div style={{ fontSize: 16, fontFamily: "redHat-bold" }}>{item?.BinID}</div>
                                        }

                                    </div>
                                    {item?.StorageType != "-" && <div className={item?.BinID === null ? 'storage-type' : ' storage-type mt-0'}>
                                        {item?.StorageType}
                                    </div>}
                                </div>
                            )
                        })}
                    </div>
                </div >
            )
        } catch (ex) {
            console.log("Error in barList::" + ex)
        }
    }

    const labelFormatter = (item: any) => {
        try {
            if (item?.ID == '1') {
                return ("Batch / Lot ID")
            }
            else if (item?.ID == '2') {
                return ("MFG Date")
            }
            else if (item?.ID == '3') {
                return ("Month of Import")
            }
            else if (item?.ID == '4') {
                return ("Expiry Date")
            }
            else if (item?.ID == '5') {
                return ("MRP")
            }
        } catch (error) {
            console.log("Error in labelFormatter::", error);

        }
    }

    const tableView = (data: any) => {
        try {
            return (
                <div>
                    <div className={data?.ConditionTypeName == "Good" ? "content-container-green" : "content-container-red"} style={{ marginTop: 10 }}>
                        <Row>
                            <Col span={8} className=' d-flex align-c'>
                                <div className='text-2'> {data?.ItemID}</div>
                            </Col>
                            <Col span={7} className='d-flex align-c jc-c'>
                                <div className='text-2'> {data?.ConditionTypeName}</div>
                            </Col>
                            <Col span={4} className='d-flex align-c jc-c'>
                                <div className='text-2'> {data?.Quantity}</div>
                            </Col>
                            <Col span={5} className='d-flex align-c jc-c'>
                                <div style={{ display: "flex", justifyContent: 'center' }}>
                                    <div
                                        onClick={() => openEditDrawerFields(data)}
                                        className="eye-btn"
                                        style={{ cursor: "pointer" }}>
                                        <EditFilled style={{ color: "white", marginLeft: 5, marginRight: 5 }} />
                                    </div >
                                    {!isRTOB2B && <div
                                        onClick={() => deleteSku(data)}
                                        className="eye-btn"
                                        style={{ cursor: "pointer", marginLeft: 30 }}>
                                        <DeleteFilled style={{ color: "white", marginLeft: 5, marginRight: 5 }} />
                                    </div >}
                                </div>
                            </Col>
                        </Row>
                        {data?.Lines?.length > 0 && <div className='selected-attribute ' style={{ padding: 10 }}>
                            <div style={{ marginTop: 10 }}>
                                <Row>
                                    {data?.Lines?.map((x: any) => {
                                        return (
                                            <Col span={x?.ID == "5" ? 4 : 5}>
                                                <div style={{ fontFamily: "RedHat-SemiBold" }}>
                                                    {labelFormatter(x)}
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <Row>
                                    {data?.Lines?.map((x: any) =>
                                        <Col span={x?.ID == "5" ? 4 : 5}>
                                            <div style={{ fontFamily: "RedHat-Regular" }}>
                                                {(x?.Value && x?.Value != "Invalid date") ? x?.Value : "-"}
                                            </div>
                                        </Col>)}
                                </Row>
                            </div>
                        </div>}
                    </div>
                </div>
            )
        } catch (error) {
            console.log("Error in tableview::", error)
        }
    }

    const titleContainer = () => {
        try {
            return (
                <Row>
                    <Col span={8}>
                        <div className='text'>SKU-Description</div>
                    </Col>
                    <Col span={7}>
                        <div className='text d-flex jc-c'>Condition</div>
                    </Col>
                    <Col span={4}>
                        <div className='text d-flex jc-c'>Quantity</div>
                    </Col>
                    <Col span={5}>
                        <div className='text d-flex jc-c'>Action</div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }

    const boxContentView = () => {
        try {
            let exist = recievingInProgressBarcode?.lineitemstwo?.find(({ Barcode }: any) => Barcode == barcode?.barcodeValue);
            return (
                <div>
                    {!exist && <div style={{ padding: 5, marginLeft: 15, marginRight: 15 }}>
                        <div style={{ color: "var(--app-004986)", fontSize: 18, fontFamily: 'RedHat-bold' }}>
                            {"Scan the Barcode on the Box Sticker"}
                        </div>
                        <div style={{ color: "var(--app-004986)", fontSize: 18, fontFamily: 'RedHat-Regular', marginTop: 20 }}>
                            {"This will select the Sticker on the left for you to provide SKU details"}
                        </div>
                    </div>}
                    {exist && <div style={{ marginTop: 10 }}>
                        <div className='header-part'>
                            {titleContainer()}
                        </div>

                        <div className='expand-collape-container'>
                            {rawData?.lineitems?.length != 0 ?
                                (rawData?.lineitems || []).map((data: any) =>
                                    tableView(data)
                                ) :
                                <NoData />}
                        </div>
                    </div>
                    }
                </div >

            )
        } catch (ex) {
            console.log("Error in boxContentView::" + ex)
        }
    }

    const setDataValue = (item: any) =>{
        setBarcode({ ...barcode, barcodeValue: item?.Barcode, binId: item?.BinID });
    }

    const RTOB2bBoxes = (dataList: any, key: any) => {
        try {
            return (
                <div className='bar-listing-container-1'>
                    {key == 'scan' && <Form form={form}>
                        <Form.Item name='scan'>
                            <Input
                                placeholder='Scan Box-Sticker barcode'
                                autoFocus
                                onPressEnter={(e: any) => e.target.value && scanRTOB2BBox(e.target.value)}
                                onPaste={(e: any) => e.target.value && scanRTOB2BBox(e.target.value)}
                                onChange={(e: any) => e.target.value && scanRTOB2BBox(e.target.value)}
                            />
                        </Form.Item>
                    </Form>}
                    <div className='radio-container' style={{ height: "75vh", overflow: "auto" }}>
                        {(dataList || []).map((item: any) => {
                            let scanned_bar_code = item?.Barcode == barcode?.barcodeValue;
                            let isBin = item?.BinID == barcode?.binId
                            return (
                                <div
                                    onClick={(e: any) => { key != 'scan' && setDataValue(item) }}
                                    className={!scanned_bar_code ? 'barcode-button' : isBin ? "barcode-button-1" : 'barcode-button'}>
                                    <div className='d-flex align-c jc-sa'>
                                        {item?.Lines ?
                                            <>
                                                {item?.Lines?.map((item: any) => {
                                                    return (
                                                        <>
                                                            {item?.Condition == "Good" ?
                                                                (item?.Condition != "-" && <div className={item?.BinID === null ? 'd-flex align-c bin-container-green' : 'd-flex align-c movebin-container-green'}>
                                                                    {item?.Qty}
                                                                </div>)
                                                                :
                                                                (item?.Condition != "-" && <div className={item?.BinID === null ? 'd-flex align-c bin-container-red' : 'd-flex align-c movebin-container-red'}>
                                                                    {item?.Qty}
                                                                </div>)
                                                            }
                                                        </>
                                                    )
                                                })}
                                            </>
                                            :
                                            <div className='d-flex align-c bin-container-green'>
                                                {item?.Quantity}
                                            </div>}
                                    </div>
                                    <div className='storage-type'>
                                        {item?.CartonNumber ? item?.CartonNumber : item?.Barcode}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div >
            )
        } catch (ex) {
            console.log("Error in RTOB2bBoxes::" + ex)
        }
    }


    const contentView = () => {
        try {
            return (
                <div className='start-recieving-view-container'>
                    <Row>
                        <Col span={4}>
                            <div style={{ marginLeft: 10, fontSize: 20, fontFamily: "RedHat-Bold", color: '#575757' }}>
                                {isRTOB2B ? "Dispatch Cartons" : "Pending Boxes"}
                            </div>
                            <div>
                                {!isRTOB2B ?
                                    barList(recievingInProgressBarcode?.lineitemstwo)
                                    :
                                    RTOB2bBoxes(recievingInProgressBarcode?.lineitems, 'scan')
                                }
                            </div>
                        </Col>
                        {isRTOB2B &&
                            <Col span={4}>
                                <div style={{ marginLeft: 10, fontSize: 20, fontFamily: "RedHat-Bold", color: '#575757' }}>
                                    {"Received Cartons"}
                                </div>
                                <div>
                                    {RTOB2bBoxes(recievingInProgressBarcode?.lineitemstwo, '')}
                                </div>
                            </Col>}
                        <Col span={isRTOB2B ? 16 : 20}>
                            <div className="box-container">
                                {boxContentView()}
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        } catch (ex) {
            console.log("Error in contentView::" + ex)
        }
    }

    const openDrawerFields = () => {
        setBinQty("")
        if (enabledrawer == true) {
            setOpenDrawer({ ...openDrawer, isOPen: true, isEdit: "No" })
            setFormValues([]);
        }
    }

    const closeDrawerFields: any = () => {
        setOpenDrawer({ ...openDrawer, isOPen: false });
        setFormValues([]);
    }

    const batchSelectionView = (attribute: any, index: any) => {

        let attributes = (attribute?.Lines || []);
        return (
            <Form.Item
                name={"batch"}
                rules={[
                    { required: true, message: "Select Batch" },
                    {
                        validator(rule, value, callback) {
                            if (value && value === "") {
                                return Promise.reject("Select Batch")
                            }
                            else {
                                return Promise.resolve()
                            }
                        }
                    }
                ]}>
                <Radio.Group
                    value={formValues.batch}
                    className="dispay-block"
                    onChange={(e: any) => setFormValues({ ...formValues, batch: e.target.value })}
                >
                    <div className='selected-attribute' style={{ marginTop: 10 }}>
                        <Radio value={attribute?.GUID}>
                            <div className='d-flex align-c'>
                                <div className='redhat-bold font-16'>
                                    Batch / Lot ID:
                                </div>
                                <div className='redhat-bold ml-5 font-14'>
                                    {attributes[0]?.Value}
                                </div>
                            </div>
                        </Radio>
                        {attributes?.length > 1 && <div style={{ marginTop: 10 }}>
                            <Row>
                                <Col span={24} className="attribute-header font-14">
                                    <Row>
                                        {attributes?.slice(1, attributes.length).map((x: any) =>
                                            <Col span={x.ID == 5 ? 3 : 7}>
                                                {getAttributeHeader(x?.ID)}
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                                <Col span={24} className="attribute-value font-14">
                                    <Row>
                                        {attributes?.slice(1, attributes.length).map((item: any) => {
                                            return (
                                                <Col span={item?.ID == 5 ? 3 : 7}>
                                                    {(item?.Value == "Invalid date" || item?.Value == "") ? "-" : item?.Value}
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        </div>}
                    </div>
                </Radio.Group>
            </Form.Item>
        )
    }

    const setSkUDescription = (e: any) => {
        setFormValues({ ...formValues, sku: e})
        form.setFieldsValue({
            ['batch']: ""
        });
    }

    useEffect(() => {
        if (openDrawer?.isEdit === 'No') {
            if (formValues?.moveToBin) {
                setFormValues({ ...formValues, bin: ""})
                form.setFieldsValue({
                    ['moveToBin']: ""
                });
                setBinQty("")
                getMoveToBinMetaData(onLoad?.value)
            }
        }

    }, [formValues?.condition])

    useEffect(() => {
        if(formValues?.moveToBin) {
            if(openDrawer?.isEdit === 'No') {
                setFormValues({ ...formValues, bin: "", batch : "",moveToBin: false})
                form.setFieldsValue({
                    ['moveToBin']: ""
                });
                setBinQty("")
                getMoveToBinMetaData(onLoad?.value)
            }
        }
    }, [formValues?.sku])


    useEffect(() => {
        if (openDrawer?.isEdit === 'No') {
            if (formValues?.moveToBin) {
                setFormValues({ ...formValues, bin: "" })
                form.setFieldsValue({
                    ['moveToBin']: ""
                });
                setBinQty("")
                getMoveToBinMetaData(onLoad?.value)
            }

        }

    }, [formValues?.batch])

    useEffect(() => {
        if (openDrawer?.isEdit === 'No') {
            if (formValues?.moveToBin && (formValues?.qty !== "")) {
                setFormValues({ ...formValues, bin: "" })
                form.setFieldsValue({
                    ['moveToBin']: ""
                });
                setBinQty("")
                getMoveToBinMetaData(onLoad?.value)
            }
        }
    }, [formValues?.qty])

    const drawerView = () => {
        try {

            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={430}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                {openDrawer?.isEdit == 'Yes' ? "Edit SKU" : "Add SKU"}
                            </div>
                            {/* <Button onClick={() => closeDrawerFields()}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button> */}
                            <img onClick={() => closeDrawerFields()} className="c-p" src={AppImages.closeimg} alt='' />
                        </div>
                    }
                    onClose={() => {
                        setOpenDrawer({ ...openDrawer, isOPen: false });
                        form.resetFields();
                        setFormValues(null);
                    }}
                    open={openDrawer?.isOPen == true}>
                    <div style={{ borderRadius: 2 }}>
                        <Form
                            onFinish={saveSkuApi}
                            form={form}
                            layout="vertical"

                        >
                            <div style={{ fontFamily: 'RedHat-Bold' }}>
                                SKU,Description
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    name='sku'
                                    rules={
                                        [
                                            { required: true, message: "Select SKU" },
                                            // {
                                            //     validator(rule, value, callback) {
                                            //         if(value){
                                            //             if(isArrayNotEmpty(rawData?.lineitems) && rawData?.lineitems?.find((x: any)=> x?.PoLineRecNo !== value)){
                                            //                 return Promise.reject("Could not mix the different SKU in the same bin/box")
                                            //             }else{
                                            //                 return Promise.resolve()
                                            //             }

                                            //         }
                                            //     }
                                            // }
                                        ]
                                    }
                                >
                                    <Select
                                        style={{ borderRadius: 4 }}
                                        // onChange={(e: any) => setFormValues({ ...formValues, sku: e})}
                                        onChange={(e: any) => setSkUDescription(e)}
                                        placeholder='Select'
                                    >
                                        {(skuDropdown || []).map((item: any) => {
                                            const val = item?.c?.split("|")
                                            return (
                                                <Option value={val[0]}>{val[1]}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div style={{ fontFamily: 'RedHat-Bold', marginTop: 10 }}>Condition</div>
                            <div>
                                <Form.Item
                                    name={'condition'}
                                    rules={
                                        [{ required: true, message: "Select condition" }]
                                    }
                                >
                                    <Radio.Group
                                        onChange={(e: any) => setFormValues({ ...formValues, condition: e.target.value })}
                                    >
                                        {(conditionDatas || []).map((item: any) => {
                                            return (
                                                <Radio value={item?.id}>{item?.label}</Radio>
                                            )
                                        })}

                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            <div style={{ marginTop: 5, fontFamily: 'RedHat-Bold' }}>Quantity</div>
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <Form.Item
                                    name={'qty'}
                                    rules={
                                        [{ required: true, message: "Enter Quantity" },
                                        { pattern: /^[0-9]*$/, message: "Enter Valid Quantity" },
                                        {
                                            validator(rule, value, callback) {
                                                if (formValues?.moveToBin === true) {
                                                    if (binQty) {
                                                        if (value && value > Number(binQty)) {
                                                            return Promise.reject('Quantity exceeds than the bin capacity')
                                                        }
                                                        else {
                                                            return Promise.resolve()
                                                        }
                                                    }
                                                } else {
                                                    if (value && value < 1) {
                                                        return Promise.reject('Enter Quantity')
                                                    }
                                                    else {
                                                        return Promise.resolve()
                                                    }
                                                }


                                            }
                                        }

                                        ]
                                    }
                                >
                                    <Input
                                        style={{ borderRadius: 4, height: 35 }}
                                        onChange={(e: any) => setFormValues({ ...formValues, qty: e.target.value })}
                                        placeholder='Enter'
                                    />
                                </Form.Item>
                            </div>
                            <hr />



                            <div style={{ marginTop: 10, fontFamily: 'RedHat-Bold' }}>
                                Selected Attribute
                            </div>
                            {formValues?.sku && <>
                                {skuBatchDetail?.lineitems?.map((attribute: any, index: any) => {
                                    return (
                                        batchSelectionView(attribute, index)
                                    )
                                })}
                            </>}

                            <div style={{ fontFamily: 'RedHat-Bold', marginTop: 10, }}>
                                <Checkbox
                                    checked={formValues?.moveToBin}
                                    onChange={(e: any) => getMoveToBin(e)} />
                                <span className='ml-10'>Move To Bin</span>
                            </div>
                            {formValues?.moveToBin &&
                                <>
                                    <div style={{ marginTop: 10 }}>
                                        <Form.Item
                                            name='moveToBin'
                                            required
                                            rules={
                                                [
                                                    { required: true, message: "Select Bin" },
                                                    {
                                                        validator(rule, value, callback) {
                                                            if (value && value === "") {
                                                                return Promise.reject("Select Bin")
                                                            }
                                                            else {
                                                                return Promise.resolve()
                                                            }
                                                        }
                                                    }
                                                ]
                                            }
                                        >
                                            <Select
                                                style={{ borderRadius: 4, height: 35 }}
                                                onChange={(e: any) => setMoveToBin(e)}
                                                value={formValues?.bin}

                                            >
                                                {(moveTOBinMetaList?.lineitems || []).map((x: any) => {
                                                    return (
                                                        <Option value={x?.BinID}>{x?.BinID}</Option>
                                                    )
                                                })}
                                            </Select>

                                        </Form.Item>
                                        {(binQty) &&
                                            <div className={(Number(binQty) >= Number(formValues?.qty)) ? 'greenColor' : 'redColor'} style={{ marginTop: 10, fontSize: 16, fontFamily: "redHat-Medium" }} >This Bin can hold  only {binQty} Qty</div>
                                        }
                                    </div>
                                </>

                            }
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, marginRight: 10 }}>
                                {/* <Button className='cancel-btn'
                                    onClick={() => closeDrawerFields()}
                                >
                                    Cancel
                                </Button> */}
                                <AppButtons
                                    onClick={() => closeDrawerFields()}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                    text="Cancel"
                                ></AppButtons>
                                <AppButtons
                                    block={true}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                    text={openDrawer?.isEdit == 'Yes' ? "Save" : "Add"}
                                    htmlType={'submit'}
                                >
                                </AppButtons>
                            </div>

                        </Form>
                    </div>
                </Drawer >
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }
    }



    const openEditDrawerFields = (item: any) => {
        console.log("item", item)
        setOpenDrawer({ ...openDrawer, isOPen: true, isEdit: "Yes" });
        form.setFieldsValue({
            ['sku']: item?.ItemID,
            ['condition']: item?.ConditionType,
            ['qty']: item?.Quantity,
            ['batch']: item?.AttributeID,
            ['moveToBin']: barcode?.binId ? barcode?.binId : null
        });
        if (barcode?.binId) {
            setFormValues({ ...formValues, moveToBin: true })
        }
        setEditValue(item);
        setFormValues({
            ...formValues,
            sku: item?.PoLineRecNo,
            condition: item?.ConditionType,
            qty: item?.Quantity,
            batch: item?.AttributeID,
            isEdit: true,
            moveToBin: barcode?.binId ? barcode?.binId : null
        });
    }

    const closeAttribute = () => {
        setOpenDrawer({ ...openDrawer, openAttribute: false })
        setAttributeDetails({ ...attributeDetails, sku: "", batch: "", manuFactDate: "", importMth: "", expiryDate: "", mrp: "" });

        attributeForm.setFieldsValue({
            ['sku']: "",
            ['batch']: "",
            ['manuFactDate']: "",
            ['importMth']: "",
            ['expirydate']: "",
            ['mrp']: ""
        })
    }

    const openAttributes = () => {
        setOpenDrawer({ ...openDrawer, openAttribute: true })
        attributeForm.setFieldsValue({
            ['mrp']: 1
        });
        setAttributeDetails({ ...attributeDetails, mrp: 1 });
    }

    const getAttributeHeader = (id: any) => {
        switch (id) {
            case 2:
                return "Mfg Date";
            case 3:
                return "Import Month"
            case 4:
                return "Expiry Date"
            case 5:
                return "MRP"
        }

    }

    const existingAttributeiew = () => {
        return (
            <>
                {/* {(skuBatchDetails?.lineitems?.length != 0 && attributeDetails?.sku) ?  */}
                <>
                    {skuBatchDetail?.lineitems?.map((attribute: any) => {
                        let attributes = (attribute?.Lines || []);
                        return (
                            <div className='selected-attribute' style={{ marginTop: 10 }}>
                                <div className='d-flex align-c'>
                                    <div className='redhat-bold font-16'>
                                        Batch / Lot ID:
                                    </div>
                                    <div className='redhat-bold ml-5 font-14'>
                                        {attributes[0]?.Value}
                                    </div>
                                </div>
                                {attributes?.length > 1 && <div style={{ marginTop: 10 }}>
                                    <Row>
                                        <Col span={24} className="attribute-header font-14">
                                            <Row>
                                                {attributes?.slice(1, attributes.length).map((x: any) =>
                                                    <Col span={x?.ID == "5" ? 3 : 7}>
                                                        {getAttributeHeader(x?.ID)}
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col span={24} className="attribute-value font-14">
                                            <Row>
                                                {attributes?.slice(1, attributes.length).map((x: any) =>
                                                    <Col span={x?.ID == "5" ? 3 : 7}>
                                                        {(x?.Value && x?.Value != "Invalid date") ? x?.Value : '-'}
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>}
                            </div>
                        )
                    })}
                </>
                {/* :
              <div className='selected-attribute'>
                No Attributes Available
             </div>
             } */}
            </>

        )
    }


    const addAttribute = () => {
        try {
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={420}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                Add Attribute
                            </div>
                            {/* <Button onClick={() => closeAttribute()}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button> */}
                            <img onClick={() => closeAttribute()} className="c-p" src={AppImages.closeimg} alt='' />

                        </div>
                    }
                    onClose={() => {
                        setOpenDrawer({ ...openDrawer, openAttribute: false });
                        clearAttributeForm();
                    }}
                    open={openDrawer?.openAttribute}>
                    <div style={{ borderRadius: 2 }}>
                        <Form
                            onFinish={addSKUBatch}
                            form={attributeForm}
                        >
                            <div className='redhat-bold'>Select SKU</div>
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    name='sku'
                                    rules={
                                        [{ required: true, message: "Select SKU" }]
                                    }
                                >
                                    <Select
                                        style={{ borderRadius: 4 }}
                                        onChange={(e: any) => setAttributeDetails({ ...attributeDetails, sku: e })}
                                        placeholder={'Select'}
                                    >
                                        {(skuDropdown || []).map((item: any) => {
                                            const val = item?.c?.split("|");
                                            return (
                                                <Option value={val[0]}>{val[1]}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className='attribute-container'>
                                <div className='Add-Attribute-container' style={{ marginTop: 10, marginBottom: 10 }}>
                                    <div>Added Attribute for selected SKU</div>
                                </div>
                                {(attributeDetails?.sku != "") && existingAttributeiew()}
                            </div>
                            <div className='redhat-bold mt-10'>Batch / Lot ID:</div>
                            <div className="mt-10">
                                <Form.Item
                                    name="batch"
                                    rules={[{ required: true, message: "Enter Batch ID" }]}
                                >
                                    <Input
                                        className='input-field'
                                        onChange={(e: any) => setAttributeDetails({ ...attributeDetails, batch: e.target.value })}
                                        placeholder={'Enter'}
                                    />
                                </Form.Item>
                            </div>
                            <div className='redhat-bold'>Manufacturing date</div>
                            <div className="mt-10">
                                <Form.Item name="manuFactDate">
                                    <DatePicker
                                        format={"DD-MMM-YYYY"}
                                        disabledDate={(e) => e > moment()}
                                        onChange={(e: any) => setAttributeDetails({ ...attributeDetails, manuFactDate: moment(e).format("DD-MMM-YYYY") })}
                                        className='input-field'
                                    />
                                </Form.Item>
                            </div>
                            <div className='redhat-bold '>Import month</div>
                            <div className="mt-10">
                                <Form.Item name="importMth">
                                    <DatePicker
                                        disabled={!attributeDetails?.manuFactDate}
                                        picker="month"
                                        format={"MMM-YYYY"}
                                        className='input-field'
                                        disabledDate={(e) => attributeDetails?.manuFactDate && (e < moment(attributeDetails?.manuFactDate) || e > moment())}
                                        onChange={(e: any) => setAttributeDetails({ ...attributeDetails, importMth: moment(e).format("MMM-YYYY") })}
                                    />
                                </Form.Item>
                            </div>
                            <div className='redhat-bold'>Expiry date</div>
                            <div className="mt-10">
                                <Form.Item name="expirydate">
                                    <DatePicker
                                        disabled={!attributeDetails?.manuFactDate}
                                        format={"DD-MMM-YYYY"}
                                        disabledDate={(e: any) => (e < moment(attributeDetails?.manuFactDate).add(3, 'M'))}
                                        className='input-field'
                                        onChange={(e: any) => setAttributeDetails({ ...attributeDetails, expiryDate: moment(e).format("DD-MMM-YYYY") })}
                                    />
                                </Form.Item>
                            </div>
                            <div className='redhat-bold '>MRP</div>
                            <div className="mt-10">
                                <Form.Item
                                    name="mrp"
                                    rules={[
                                        { pattern: /^[0-9]*$/, message: "Enter Valid MRP" },
                                        {
                                            validator(rule, value, callback) {
                                                if (value && value < 1) {
                                                    return Promise.reject('Enter Valid MRP')
                                                }
                                                else {
                                                    return Promise.resolve()
                                                }
                                            }
                                        }
                                    ]}
                                >
                                    <Input
                                        onChange={(e: any) => setAttributeDetails({ ...attributeDetails, mrp: e.target.value })}
                                        className='input-field'
                                        placeholder='Enter'
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, marginRight: 10, marginBottom: 20 }}>
                                {/* <Button className='close-btn-add'
                                    onClick={() => closeAttribute()}
                                >
                                    Cancel
                                </Button> */}
                                <AppButtons
                                    onClick={() => closeAttribute()}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                    text="Cancel"
                                ></AppButtons>
                                <AppButtons
                                    block={true}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                    text={"Save"}
                                    htmlType={'submit'}
                                >
                                </AppButtons>
                            </div>
                        </Form>
                    </div>
                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }

    }

    const childrenView = () => {
        try {
            let isReceivingCompleted: any;
            let exist;
            if (!isRTOB2B) {
                isReceivingCompleted = (recievingInProgressBarcode?.isReceivingCompleted?.BoxReceivingCompleted || recievingInProgressBarcode?.isReceivingCompleted[0]);
                exist = recievingInProgressBarcode?.lineitemstwo?.find(({ Barcode }: any) => Barcode == barcode?.barcodeValue);
            } return (
                <div className="btn-container d-flex align-c flex-end mt-5" >
                    <AppButtons
                        onClick={() => !isRTOB2B ? openAttributes() : () => { }}
                        text={!isRTOB2B ? "Add Attribute" : "Print Selected Stickers"}
                        buttonType={AppConstants.buttonTypes.whiteButton}
                    />
                    <AppButtons
                        text={!isRTOB2B ? "Add SKU" : "Print All Stickers"}
                        onClick={() => !isRTOB2B ? openDrawerFields() : printSticker()}
                        disabled={!exist && !isRTOB2B}
                        buttonType={AppConstants.buttonTypes.whiteButton}
                    />
                    <AppButtons
                        disabled={isReceivingCompleted?.BoxReceivingCompleted == "No" || isReceivingCompleted == "No"}
                        onClick={() => completeOnHandle()}
                        buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                        text={'Receiving Completed'}
                    />
                    <Link to="/process/QC" state={{ Option: 2 }}>
                        <Button className="close-btn">
                            <CloseOutlined />
                        </Button>
                    </Link>
                </div>
            )
        } catch (error) {
            console.log("Error in childrenView::", error)
        }

    }

    const quantityNotMatchModal = () => {
        return (
            <Modal
                title={'Confirm'}
                open={showModal.qtyMatch}
                onOk={() => setShowMdal({ ...showModal, qtyMatch: false })}
                onCancel={() => setShowMdal({ ...showModal, qtyMatch: false })}
                footer={null}
                className="start-receivingscreen-modal"
            >
                <div className='margin redhat-bold font-18'>
                    The Invoice SKU quantity is not matching with the Received SKU Quantity.
                </div>
                <div className='d-flex flex-end' style={{ marginTop: 10 }}>
                    <AppButtons
                        buttonType={AppConstants.buttonTypes.whiteButton}
                        onClick={() => setShowMdal({ ...showModal, qtyMatch: false })}
                        text="Continue Receiving"
                    />
                    <AppButtons
                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        onClick={() => completeReceiveInProgressApi()}
                        text="Okay to Continue"
                    />
                </div>
            </Modal>
        )
    }
    // deleteAction
    const deleteModal = () => {
        return (
            <Modal
                title={"Delete"}
                open={showModal?.delete}
                onOk={() => setShowMdal({ ...showModal, delete: false })}
                onCancel={() => setShowMdal({ ...showModal, delete: false })}
                footer={null}
                className="start-receivingscreen-modal"
            >
                {/* <div className='d-flex flex-end'>
                    <Button onClick={() => setShowMdal({ ...showModal, delete: false })}
                        className="cross-btn">
                        <CloseOutlined />
                    </Button>
                </div> */}
                <div className='margin redhat-bold font-18'>
                    Do you want to delete the <b>{showModal?.data?.ItemID}</b>?
                </div>
                <div className='d-flex flex-end' style={{ marginTop: 10 }}>
                    <AppButtons
                        buttonType={AppConstants.buttonTypes.whiteButton}
                        onClick={() => setShowMdal({ ...showModal, delete: false })}
                        text="Cancel"
                    />
                    <AppButtons
                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        onClick={() => deleteSKuApi()}
                        text="Confirm"
                    />
                </div>
            </Modal>
        )
    }

    return (
        <>
            {/* <NavbarLayout
                titleContent="sub-head"
                process={recievingInProgressBarcode?.title}
            /> */}
            <SubHeader
                title={(
                    <div>
                        <Row>
                            <Col span={8} style={{ fontFamily: "RedHat-Bold" }}>
                                <div className='d-flex align-c '>
                                    <Link to={"/process/SAN"} state={{ Option: 2 }}>
                                        <div className='c-p'>
                                            <WMSIcon4 />
                                        </div>
                                    </Link>
                                    <div className='ml-10'>Invoice No:</div>
                                    <div style={{ textIndent: "0.2em" }}>{gateinDetails?.RefernceNo}</div>
                                </div>
                            </Col>
                            <Col span={3} style={{ display: "flex", fontFamily: "RedHat-Bold" }}>
                                <div className='d-flex align-c '>
                                    <div>Boxes:</div>
                                    <div style={{ textIndent: "0.2em" }}>
                                        {gateinDetails?.RecievedBoxQty > "1" ?
                                            gateinDetails?.RecievedBoxQty
                                            : gateinDetails?.RecievedBoxQty}
                                    </div>
                                </div>
                            </Col>
                            <Col span={4} style={{ display: "flex", fontFamily: "RedHat-Bold" }}>
                                <div className='d-flex align-c '>
                                    <div>PO Number:</div>
                                    <div style={{ textIndent: "0.2em" }}>{gateinDetails?.ID}</div>
                                </div>
                            </Col>
                            <Col span={6} style={{ display: "flex", fontFamily: "RedHat-Bold" }}>
                                <div className='d-flex align-c'>
                                    <div>Release:</div>
                                    <div style={{ textIndent: "0.2em" }}>{!isRTOB2B ? gateinDetails?.RECORDNO : recievingInProgressBarcode?.SOID}</div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                )}
                btnView="false"
                inputView="true"
                paginationView="false" >
                {childrenView()}
            </SubHeader >
            <div>
                {contentView()}
            </div>
            <div>
                {drawerView()}
                {addAttribute()}
                {quantityNotMatchModal()}
                {deleteModal()}
            </div>
            <CartonningStickeringModal
                dataValue={cartonStickeringList?.lineitems}
                setScanModal={setScanModal}
                scanModal={scanModal}
            />
        </>
    );
}

export default StartRecievingView;