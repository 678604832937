import React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const HandleImage = (props: any) => {
    const{image} = props;
    const retry_count=20;
    const retry_delay=1000;
    const componentRef = useRef<any>();
    const [error, setError] = useState<boolean>(false);

    console.log("props",image)
    
    useEffect(() => {
        componentRef.current = retry_count;
      }, [image]);

    const handleError = useCallback(({ currentTarget }: any) => {
        setError(true);
        setError(false);
        if (componentRef && componentRef.current && componentRef.current > 0) {            
            setError(true);
            setTimeout(() => {
            currentTarget.onerror = null;
            currentTarget.src = image;
            componentRef.current = componentRef && componentRef.current && componentRef.current - 1;
            }, retry_delay);
        }
    }, []);

    const handleLoad = useCallback(() => {
        setError(false);
      }, []);

    const showImage = useMemo(() => {
        return(
            <img 
            src={image} 
            onLoad={handleLoad} 
            onError={handleError} 
            alt={"Loading..."}/>
        )
    },[image])

    if(error ){
        return (
            <>
                <img 
                src={image} 
                onLoad={handleLoad} 
                onError={handleError} 
                alt={"Loading..."}/>
            </>
        )
    }
    else{
        return showImage
    }    
    
}

export default HandleImage;