import axios from "axios";
// import { getRegAuthToken } from "../../../Utils/SessionStorage";

const http = axios.create({
    baseURL:
        `https://bo3m77km31.execute-api.ap-south-1.amazonaws.com/dev`
});

http.interceptors.request.use(function (config: any) {
    let token: any;
    // if(getRegAuthToken()){
    //     token = getRegAuthToken();
    // }else{
    token = localStorage.authToken;
    // }
    if (token) {
        config.headers.Authorization = token;
    }
    return config;
});

export { http };
