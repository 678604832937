import { Empty, Layout} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import AppConstants from '../../Globals/AppConstants';
import { AppModuleIds } from '../../Globals/AppReferenceIds';
import AppReferences from '../../Globals/AppReferences';
import { isArrayNotEmpty } from '../../Globals/Helper';
import { getChannelDetailsAction, getChannelStatusAction, getClientAction, getLeftMenuAction } from '../../Store/Actions/ProcessWMSAction';
import { getChannelId, getDashboardMenuId, getUserInfo, getWareHouseId, setChannelId, setClientId, setDashboardMenuId } from '../../Utils/SessionStorage';
import SideSubmenu from '../SideSubMenu/SideSubmenu';
import './OrderProcessing.scss';

const { Content, Sider } = Layout;

function OrderProcessingIndex() {
    const [channel, setChannel] = useState<any>(null);
    const [process, setProcess] = useState(getDashboardMenuId());
    const dispatch = useDispatch();
    const useData = getUserInfo();
    const reducerState = useSelector((state: any) => state?.ProcessReducerState);
    const { getClient,
        getLeftMenu,
        getLeftMenuOnload,
        channelStatus,
        statusDetails,
        pickers,
        channelStatusOnLoad,
        clientOnload } = reducerState;
    const clients = getClient?.Clients;
    const title = getClient?.title;
    const tats = getClient?.TAT;
    const channelList = getLeftMenu?.header;
    const [clientValue, setClientValue] = useState<any>("");
    const [tatClient, setTatClient] = useState<any>("");
    const navigate = useNavigate();
    const [onload, setOnload] = useState<any>({
        channelOnload: false,
        clientOnload: false,
        statusOnload: false,
        detailsOnlaod: false
    });

    useEffect(() => {
        getClientApi();
        return () => {
            setDashboardMenuId("");
            setClientId("");
        };
    }, []);
    console.log("tatClient", tatClient)
    useEffect(() => {
        if (channel) {
            getChannelStatus(channel);
            setChannelId(channel);
            // setProcess(channelStatus?.header[0]?.id);
        }
        else if (channel == '') {
            setChannelId("");
        }
    }, [channel]);
    console.log("channel", channel)
    useEffect(() => {
        if (process) {
            let menu = menus?.find((x: any) => x.id == process);
            navigate(menu?.path);
            setDashboardMenuId(process);
            getStatusDetails();
        }
    }, [process]);

    useEffect(() => {
        try {
            if (onload.statusOnload && channelStatusOnLoad == false) {
                setOnload({ ...onload, statusOnload: false });
                let id = channelStatus?.header[0]?.id;
                let exist = channelStatus?.header?.find((x: any) => x.id == getDashboardMenuId());
                if (getDashboardMenuId() && exist) {
                    setProcess(getDashboardMenuId());
                }
                else {
                    setProcess(id);
                }
            }
        } catch (error) {
            console.log("Error in setProcess::", error)
        }
    }, [onload.statusOnload, channelStatusOnLoad]);

    useEffect(() => {
        try {
            if (clients && tats) {
                setClientValue(clients[0].Id);
                setTatClient(tats[0]?.Id);
            }

        } catch (error) {
            console.log("Error in setTatClient::", error);
        }
    }, [getClient])

    useEffect(() => {
        if (clientValue) {
            setTatClient(tats[0]?.Id);
        }
    }, [clientValue]);

    useEffect(() => {
        try {
            if (clientValue || tatClient) {
                getChannelsApi()
            }

        } catch (error) {
            console.log("Error in clientValue || tatClient::", error);
        }
    }, [clientValue, tatClient])

    useEffect(() => {
        if (clientValue) {
            setClientId(clientValue)
        }
    }, [clientValue])

    useEffect(() => {
        if (onload.channelOnload == true && getLeftMenuOnload == false) {
            setOnload({ ...onload, channelOnload: false });
            // setChannelId("")
            setMenu();
        }
    }, [onload.channelOnload, getLeftMenuOnload]);

    const setMenu = async () => {
        try {
            let channelId = getChannelId();
            let [channelData] = (channelList || []);
            let exist = channelList?.find((x: any) => x.id == channelId);
            if (channelId && exist ) {
                setChannel(channelId);
            }
            else {
                if (channelList) {
                    setChannel(channelData?.id);
                }
                else {
                    setChannel('')
                }
            }
        } catch (error) {
            console.log("Error in setMenu::", error)
        }
    }

    const getClientApi = () => {
        try {
            let payload = {
                opnfor: AppReferences.opnfor.salesOrder,
                activity: AppReferences.activities.av,
                warehouse: getWareHouseId(),
                user: useData?.UserName,
                fieldArray: useData?.CorporateID
            }
            dispatch(getClientAction(payload));
            // onload.clientOnload = true;
            // setOnload({...onload})
        } catch (error) {
            console.log("Error In getClientApi::", error);
        }
    }

    const getStatusDetails = () => {
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a02,
            transaction: process,
            where: tatClient,
            fieldArray: channel,
            warehouse: getWareHouseId(),
            customer: clientValue,
            tableno: getUserInfo()?.CorporateID
        }
        dispatch(getChannelDetailsAction(payload))
        setOnload({ ...onload, detailsOnlaod: true })
    }


    const getChannelStatus = (channelId: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a01,
            transaction: tatClient,
            where: channelId,
            warehouse: getWareHouseId(),
            customer: clientValue
        }
        dispatch(getChannelStatusAction(payload));
        setOnload({ ...onload, statusOnload: true });
        setProcess(null);
    }

    const getChannelsApi = () => {
        try {
            let payload = {
                opnfor: AppReferences.opnfor.salesOrder,
                activity: AppReferences.activities.av1,
                transaction: tatClient,
                warehouse: getWareHouseId(),
                customer: clientValue,
                // user: getUserInfo()?.UserName
            }
            
            dispatch(getLeftMenuAction(payload));
            setChannel(null);
            setOnload({ ...onload, channelOnload: true });
        } catch (error) {
            console.log("Error In getLeftMenuApi::", error);
        }
    }

    const menus: any = [
        {
            id: "201",
            name: "Verified",
            cnt: "10",
            path: "/order-processing/verified"
        },
        {
            id: "202",
            name: "Picks Released",
            cnt: "10",
            path: "/order-processing/picks-released"
        },
        {
            id: "203",
            name: "Picked",
            cnt: "34",
            path: "/order-processing/scanning-pending"
        },
        {
            id: "204",
            name: "On Packing Station",
            cnt: "15",
            path: channel == "23" ? "/order-processing/cartonning" : "/order-processing/onpackingstation"
        },
        {
            id: "205",
            name: "RTS",
            cnt: "30",
            path: "/order-processing/rts"
        },
        {
            id: "206",
            name: "Manifested",
            cnt: "10",
            path: "/order-processing/manifested"
        },
        {
            id: "207",
            name: "Gate-Out Ready",
            cnt: "10",
            path: "/order-processing/gate-out-ready"
        },
        {
            id: "400",
            name: "Dispatched",
            cnt: "44",
            path: "/order-processing/dispatched"
        },
        {
            id: "301",
            name: "SKU Mismatch",
            cnt: "78",
            path: "/order-processing/sku-mismatched"
        },
        {
            id: "302",
            name: "Non-Servicable",
            cnt: "10",
            path: "/order-processing/non-servicable"
        },
        {
            id: "303",
            name: "No Inventory",
            cnt: "10",
            path: "/order-processing/no-inventory"
        },
        {
            id: "350",
            name: "Cancelled",
            cnt: "45",
            path: "/order-processing/cancelled"
        }
    ];

    const noData = () => {
        let client = clients?.find((x: any) => x?.Id == clientValue);
        let day = tats?.find((x: any) => x?.Id == tatClient);
        return (
            <div className='no-data-container'>
                <Empty
                    className='mr-15'
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    imageStyle={{
                        height: 40,
                    }}
                    description={
                        <div className='no-data'>
                            {AppConstants.nodata} <span className='redhat-bold ml-5 mr-5'>{client?.Name}</span> for <span className='redhat-bold ml-5 mr-5'>{day?.Name}</span> TAT.
                        </div>
                    }
                >
                </Empty>
            </div>
        )
    }

    return (
        <Layout>
            <Sider className='sider-menu-360'>
                <SideSubmenu
                    clientData={clients}
                    tatData={tats}
                    clientValue={clientValue}
                    setClientValue={setClientValue}
                    tatValue={tatClient}
                    setTatValue={setTatClient}
                    intialMenu={AppModuleIds.oms}
                    menus={menus}
                    channelList={channelList}
                    setChannel={setChannel}
                    channel={channel}
                    channelStatus={channelStatus?.header}
                    setProcess={setProcess}
                    process={process}
                    selectedOms={'102'}
                    warehouse={getClient?.title?.warehouse}
                    tatClient={tatClient}
                />
            </Sider>
            {isArrayNotEmpty(channelList) ?
                <Content>
                    <Outlet context={[statusDetails,
                        channel,
                        tatClient,
                        clientValue,
                        process,
                        getStatusDetails,
                        getChannelStatus,
                        setProcess,
                        getChannelsApi
                    ]} />
                </Content>
                :
                noData()
            }
        </Layout>
    );
}

export default OrderProcessingIndex;