import {
    Button,
    Col,
    Drawer,
    Dropdown,
    Input,
    Layout,
    Menu,
    Row,
    Table
} from "antd";
import AppConstants from "../../../Globals/AppConstants";
import { Link, Navigate, useNavigate, useOutletContext } from "react-router-dom";
import SubHeader from "../../Header/Header";
import { CloseOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { AppImages } from "../../../Globals/AppImages";
import File from "../../../Images/File";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, getWareHouseId } from "../../../Utils/SessionStorage";
import { useEffect, useState } from "react";
import AppButtons from "../../../CustomComponent/AppButtons";
import { consignmentAction } from "../../../Store/Actions/ProcessWMSAction";
import NoData from "../../../CustomComponent/NoData";

function InvoiceDetailsListingScreen() {
    const dispatch = useDispatch();
    const wareHouse_id = getWareHouseId();
    const [value, individualStageData]: any = useOutletContext();
    const navigate = useNavigate();
    const [captureInvoiceInfo, setCaptureInvoiceInfo] = useState<any>();
    const [openDrawer, setOpenDrawer] = useState<any>();
    const [consignment, setConsignment] = useState<any>();
    const { consignmentData } = useSelector((state: any) => state?.ProcessReducerState)
    const drawerData = consignmentData?.lineitems[0];

    const goToInvoiceDetails = (client: any) => {
        try {
            let gateIn = client?.GateEntryRecordNo;
            let invoiceData = individualStageData?.header?.find((item: any) => {
                let data = item?.id?.split("|");
                if (data[2] == gateIn) {
                    return item;
                }
            });
            navigate("/captureinvoicedetails", { state: { invoiceData: invoiceData } });
        } catch (error) {

        }

    }

    useEffect(() => {
        if (openDrawer?.isOPen == true) {
            consignmentApi();
        }
    }, [openDrawer]);

    const consignmentApi = () => {
        let payload = {
            opnfor: "110000",
            activity: "A-02",
            transaction: consignment,
            warehouse: wareHouse_id,
            where: getUserInfo()?.CorporateID
        }
        dispatch(consignmentAction(payload));
        console.log("hi");

    }

    const openConsignment = (id: any) => {
        setConsignment(id);
        setOpenDrawer({ ...openDrawer, isOPen: true });


    }

    const columns: any = [
        {
            title: "GateIn ID",
            dataIndex: "GateEntryRecordNo",
            width: "30%",
            align: "left",
            render: (item: any) => {
                // setCaptureInvoiceInfo(info);
                return (
                    <div className='gateinid-text'
                        onClick={() => openConsignment(item)}
                        style={{ cursor: "pointer" }}
                    >
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Client",
            dataIndex: "name",
            width: "20%",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Total SKU Qty",
            dataIndex: "SKUCount",
            width: "13%",
            align: "center",
            render: (item: any) => {
                return (
                    <div className={item == '-' ? 'text' : 'text-1'}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Total Units",
            dataIndex: "SKUSumQTY",
            width: "10%",
            align: "center",
            render: (item: any) => {
                return (
                    <div className={item == '-' ? 'text' : 'text-1'}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: AppConstants.action,
            dataIndex: "",
            align: "center",
            width: "27%",
            render: (item: any) => {
                return (
                    <div className="d-flex align-c jc-c">
                        <AppButtons
                            onClick={() => goToInvoiceDetails(item)}
                            buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                            text="Capture Invoice & SKU Details" />
                    </div>
                );
            },
        },
    ];

let arr:any=[];
    const listingView = () => {
        try {
            let dataList = individualStageData?.lineitems?.filter((x: any) => { // this is to check the data has gatein and client
                if (x?.GateEntryRecordNo || x?.name) {
                    return x;
                }
            })

            return (
                <div className='table-screen-container'>
                        <Table
                            columns={columns}
                            dataSource={dataList}
                            pagination={false}
                            // sticky={true}
                            // scroll={{ y: 485 }}
                        />
                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <Input placeholder="Search By GateIn ID" className="search-input" prefix={suffix} />
                <Button className="green-btn">
                    <File />
                </Button>

                <div className="help-icon" style={{ marginLeft: 10, cursor: "pointer" }}>
                    <img src={AppImages.helpIcon} alt="help-icon" />
                </div> */}
            </div>
        )
    }

    const drawerView = () => {
        try {
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                {drawerData?.TxnNo}
                            </div>
                            <Button onClick={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>
                        </div>
                    }
                    onClose={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                    open={openDrawer?.isOPen}>
                    <Row>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                {AppConstants.client}
                            </div>
                            <div className='value-field'>
                                {drawerData?.Customer}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Transaction Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.InwardTxnType}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.Type}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.VehicleNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Seal Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.SealNo}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Docket Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.DocketNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Dock Station
                            </div>
                            <div className='value-field'>
                                {drawerData?.DockStationName}
                            </div>
                        </Col>
                    </Row>
                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }

    }

    return (
        <>
            <SubHeader
                title={"Capture Invoice & SKU Details"}
                paginationView="true"
                inputView="true"
                btnView="false">
                {childrenView()}
            </SubHeader>
            {listingView()}
            <div>
                {drawerView()}
            </div>
        </>
    )
}

export default InvoiceDetailsListingScreen;