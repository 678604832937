import React from 'react';
import { Pie, G2 } from '@ant-design/plots';

function OverAllChartComponent(props: any) {
    let { data } = props;
    console.log("data", data)
    const G = G2.getEngine('canvas');

    interface PieChartData {
        type: any;
        value: number;
    }


    const cfg: any = {
        appendPadding: 10,
        data: data,
        angleField: "Qty",
        colorField: "Type",
        radius: 1,
        legend: false,
        //innerRadius: 0.5,
        label: false,
        // interactions: [{ type: "element-selected" }, { type: "element-active" }],
        statistic: {
            title: false as const,
            content: {
                style: {
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
                // formatter: function formatter() {
                //   return `total\n134`;
                // }
            }
        },
        theme: {
            colors10: [

                '#032454',
                '#1D99C6',
                '#99D1E5',
                '#ACB7C7',
                '#7487A1',
                '#334E75'
            ]
        }
    };
    const config = cfg;
    return <Pie {...config} />;
}

export default OverAllChartComponent;