import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from "redux-saga";
import RootReducer from './Reducers/RootReducer';
import RootSaga from './Saga/RootSaga';

const sagaMiddleware: any = createSagaMiddleware();
const Store = configureStore({
    reducer: RootReducer,
    middleware: () => [sagaMiddleware]
});

sagaMiddleware.run(RootSaga);

export default Store;