import { Button, Col, DatePicker, Form, Layout, Modal, Row, Select, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsOfOverAllOrderChartComponent from '../../CustomComponent/AnalyticsOfOverAllOrderChartComponent';
import CharComponent from '../../CustomComponent/CharComponent';
import InventoryDataChartComponent from '../../CustomComponent/InventoryDataChartComponent';
import OverAllChartComponent from '../../CustomComponent/OverAllChartComponent';
import ChartInventory from '../../Images/ChartInventory';
import NonSellableIcon from '../../Images/NonSellableIcon';
import SellableIcon from '../../Images/SellableIcon';
import TableInventoryIcon from '../../Images/TableInventoryIcon';
import { captureSKUWiseDetailsAndUnloadingAction, getClientListAction, getInventoryDataAction, getSKUListAction } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import type { DatePickerProps } from 'antd';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import "./InventoryManagement.scss"
import "../TableContainer.scss"
import Calender from '../../Images/Calender';
import ExportIcon from '../../Images/ExportIcon';
import { CloseOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { isArrayNotEmpty } from '../../Globals/Helper';
import { AppModuleIds } from '../../Globals/AppReferenceIds';
import { Content } from 'antd/lib/layout/layout';
import PackingMeterialIcon from '../../Images/PackingMeterialIcon';
import moment from 'moment';

const { Option } = Select;

const overallInventoryColorCode = [
    { color: '#20CD7A', Type: "On-Hand" },
    { color: '#70D3F7', Type: "Inward Processing" },
    { color: '#032454', Type: "GRN Pending" },
    { color: '#78E0AE', Type: "RTO" },
    { color: '#334E75', Type: "Return" },
    { color: '#ACB7C7', Type: "Dispatched" },
]


const allocationInventoryColorCode: any = [

    { color: '#032454', Type: "Picks Released" },
    { color: '#1D99C6', Type: "Picked" },
    { color: '#99D1E5', Type: "Scanning Pending" },
    { color: '#ACB7C7', Type: "On Packing Station" },
    { color: '#7487A1', Type: "RTS" },
    { color: '#334E75', Type: "Handed-Over" }
];

const totalInventoryData: any = [
    {
        Name: "Sellable",
        color: '#57BFAA'
    },
    {
        Name: "Non-Sellable",
        color: '#CC3333'
    },
];

function InventoryManagemet() {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const { clientData, inventorySkuData, inventoryDataOnLoad, inventoryData } = reducerState;
    const [clientType, setClientType] = useState<any>("")
    const [skuType, setSKUType] = useState<any>("")
    const [onLoad, setOnLoad] = useState(false)
    const [visible, setVisible] = useState(false)
    const [overallChartView, setOverallChartView] = useState(false)
    const [allocatedChartView, setAllocatedChartView] = useState(false)
    const [analyticChartView, setAnalyticChartView] = useState(false);
    const [showModal, setShowModal] = useState<any>(false);
    const [date, setDate] = useState<any>();

    // const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    //     console.log(date, dateString);
    // };

    const analyticcolumns: any = [
        {
            title: "Month",
            dataIndex: "time",
            width: 800,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item === 0 ? "-" : item}
                    </div>
                )
            }
        },
        {
            title: "Inward",
            dataIndex: "value",
            width: 350,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item === 0 ? "-" : item}
                    </div>
                )
            }
        },
        {
            title: "Outward",
            dataIndex: "count",
            width: 350,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item === 0 ? "-" : item}
                    </div>
                )
            }
        }
    ]

    const columns: any = [
        {
            title: "Process",
            dataIndex: "Type",
            width: 800,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item === 0 ? "-" : item}
                    </div>
                )
            }
        },
        {
            title: "Process Count",
            dataIndex: "Qty",
            width: 350,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item === 0 ? "-" : item}
                    </div>
                )
            }
        }
    ];



    useEffect(() => {
        { clientDataAction() }
    }, [])



    useEffect(() => {

        if (clientType !== "") {
            setSKUType("")
            setVisible(false)
            { SKUAction() }
        }

    }, [clientType])

    useEffect(() => {
        if (skuType !== "") {
            { ContentData() }
        }
    }, [skuType])


    const ContentData = () => {
        try {
            let payload = {
                "activity": "A-02",
                "customer": clientType,
                "opnfor": 540000,
                "transaction": skuType,
                "warehouse": getWareHouseId(),
                "user": getUserInfo()?.UserName,
            }
            dispatch(getInventoryDataAction(payload))
            setOnLoad(true)
        } catch (error) {
            console.log("Error in processData", error)
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (skuType !== "") {
                ContentData()
            }
        }, 60000);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [skuType])


    useEffect(() => {
        if (onLoad === true && inventoryDataOnLoad === false) {
            setVisible(true)
            setOnLoad(false)

        }
    }, [onLoad, inventoryDataOnLoad])

    const SKUAction = () => {
        try {
            let payload = {
                "activity": "A-01",
                "opnfor": 540000,
                "transaction": clientType,
                "warehouse": getWareHouseId(),
                "user": getUserInfo()?.UserName,
            }
            dispatch(getSKUListAction(payload))

        } catch (error) {
            console.log("Error in processData", error)
        }
    }

    const clientDataAction = () => {
        try {
            let payload = {
                "activity": "A-V",
                "opnfor": 540000,
                "transaction": getUserInfo()?.CorporateID,
                "warehouse": getWareHouseId(),
                "user": getUserInfo()?.UserName,
            }
            dispatch(getClientListAction(payload))
        } catch (error) {
            console.log("Error in processData", error)
        }

    }

    const refreshPage = () => {
        //window.location.reload();
        if (skuType !== "") {
            ContentData()
        }
    }

    const overallInventoryView = () => {
        try {
            return (
                <div className='chart-container'>
                    <OverAllChartComponent data={inventoryData?.Overall} />
                </div>
            )

        } catch (ex) {
            console.log("Err in overallInventoryView::" + ex)
        }
    }

    const allocationInventoryView = () => {
        try {
            return (
                <div className='chart-container'>
                    <InventoryDataChartComponent data={inventoryData?.StatusWise} />
                </div>
            )

        } catch (ex) {
            console.log("Err in overallInventoryView::" + ex)
        }
    }

    const overallAndAllocationInventoryView = () => {
        try {
            let overAllArr = inventoryData?.Overall.map((item: any, i: any) => Object.assign({}, item, overallInventoryColorCode[i]));
            let allocatedInventoryArr = inventoryData?.StatusWise.map((item: any, i: any) => Object.assign({}, item, allocationInventoryColorCode[i]));
            return (
                <Row gutter={16} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                    <Col span={12}>
                        <div className='overall-allocated-inventory-container'>
                            <div className='header'>
                                <div className='title'>Overall</div>
                                <div className='view-container'>
                                    {overallChartView === false ?

                                        <div onClick={() => setOverallChartView(true)} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                            <TableInventoryIcon />
                                            <span className='view-info'>Table View</span>
                                        </div> :
                                        <div onClick={() => setOverallChartView(false)} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                            <ChartInventory />
                                            <span className='view-info'>Chart View</span>
                                        </div>

                                    }
                                </div>
                            </div>
                            {overallChartView === false ?
                                <div className='bar-container'>
                                    <div className='total-label'> Inward Process </div>
                                    <Row >
                                        <Col span={10}>
                                            {(inventoryData?.Overall || []).map((item: any, index: any) => {
                                                let color = overallInventoryColorCode[index]?.color;
                                                return (
                                                    <Row gutter={16} className='content-percentage-container'>
                                                        <Col span={2}>
                                                            <div className={'color-box'} style={{ backgroundColor: color }}></div>
                                                        </Col>
                                                        <Col span={20} className='content' > {item?.Type}</Col>
                                                        <Col span={2} className='content'>{item?.Qty}</Col>
                                                    </Row>
                                                )
                                            })}
                                        </Col>
                                        <Col span={14} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            {overallInventoryView()}
                                        </Col>
                                    </Row>
                                </div> :
                                <div className="table-screen-container">

                                    <Table
                                        columns={columns}
                                        dataSource={inventoryData?.Overall}
                                        pagination={false}
                                        bordered
                                    />

                                </div>
                            }
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='overall-allocated-inventory-container'>
                            <div className='header'>
                                <div className='title'>Allocated Inventory</div>
                                <div className='view-container'>
                                    {allocatedChartView === false ?
                                        <div onClick={() => setAllocatedChartView(true)} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                            <TableInventoryIcon />
                                            <span className='view-info'>Table View</span>
                                        </div> :
                                        <div onClick={() => setAllocatedChartView(false)} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                            <ChartInventory />
                                            <span className='view-info'>Chart View</span>
                                        </div>

                                    }
                                </div>
                            </div>
                            {
                                allocatedChartView === false ?
                                    <div className='bar-container' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Row>
                                            <Col span={10}>
                                                {(inventoryData?.StatusWise || []).map((item: any, index: any) => {
                                                    let color = allocationInventoryColorCode[index]?.color
                                                    return (
                                                        <Row gutter={16} className='content-percentage-container'>
                                                            <Col span={2}>
                                                                <div className={'color-box'} style={{ backgroundColor: color }}></div>
                                                            </Col>
                                                            <Col span={20} className='content' > {item?.Type}</Col>
                                                            <Col span={2} className='content'>{item?.Qty}</Col>
                                                        </Row>
                                                    )
                                                })}

                                            </Col>
                                            <Col span={14} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                {allocationInventoryView()}
                                            </Col>
                                        </Row>

                                    </div> :
                                    <div className="table-screen-container">

                                        <Table
                                            columns={columns}
                                            dataSource={inventoryData?.StatusWise}
                                            pagination={false}
                                            bordered />

                                    </div>

                            }
                        </div>

                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in overallAndAllocationInventoryView::" + ex)
        }
    }

    const inventoryContent = () => {
        try {
            let overAllArr = inventoryData?.TotalInventorySUM.map((item: any, i: any) => Object.assign({}, item, totalInventoryData[i]));
            return (
                <Row gutter={16} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Col span={isArrayNotEmpty(inventoryData?.TotalInventorySUM) ? 4 : 12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div className='inventory-info' >
                            <div className='inventory-header'>Inventory</div>
                            <div className='info'>Total no of sellable and non-sellable {skuType} in warehouse</div>
                        </div>
                    </Col>
                    {isArrayNotEmpty(inventoryData?.TotalInventorySUM) &&
                        <Col span={8}>
                            <div className='bar-container'>
                                <Row>
                                    <Col span={10}>
                                        <div className='total-label'>Total Inventory</div>
                                        {/* <div className='total-Count'>100</div> */}
                                        {(overAllArr || []).map((item: any) => {
                                            let color = item?.color
                                            return (
                                                <Row gutter={8} className='content-percentage-container'>
                                                    <Col span={3}>
                                                        <div className={'color-box'} style={{ backgroundColor: color }}></div>
                                                    </Col>
                                                    <Col span={19} className='content'> {item?.Name}</Col>
                                                    <Col span={2} className='content'>{item?.Quantity}</Col>
                                                </Row>
                                            )
                                        })}

                                    </Col>
                                    <Col span={14} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                                        <div className='char-container'>
                                            <CharComponent data={inventoryData?.TotalInventorySUM} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    }
                    <Col span={6}>
                        <div className='main-container'>
                            <div className='sellable-container'>
                                {inventoryData?.noSellableInventory?.noSellableInventory === "True" ?
                                    <div className='label'>Sellable not available</div> :
                                    <div className='info-container'>
                                        <div className='label'>Sellable</div>
                                        {(inventoryData?.Sellable || []).map((x: any) => {
                                            return (
                                                <>
                                                    <div className='info'>Storage: {x?.Type}</div>
                                                    <div className='good-Count'>{x?.Qty}</div>
                                                </>
                                            )
                                        })}

                                    </div>
                                }
                            </div>
                            <div className='sellable-icon-container'><SellableIcon /></div>
                        </div>
                    </Col>
                    <Col span={6} >
                        <div className='main-container'>
                            <div className='sellable-container'>
                                {inventoryData?.noNonSellableInventory?.noNonSellableInventory === "True" ?
                                    <div className='label'> Non-Sellable not available</div> :
                                    <div className='info-container'>
                                        <div className='label'>Non Sellable</div>
                                        {(inventoryData?.NonSellable || []).map((x: any) => {
                                            return (
                                                <>
                                                    <div className='info'>Storage: {x?.Type}</div>
                                                    <div className='bad-Count'>{x?.Qty}</div>
                                                </>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                            <div className='non-sellable-icon-container'><NonSellableIcon /></div>
                        </div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in inventoryContent::" + ex)
        }
    }

    const formView = () => {
        try {
            return (
                <div>
                    <Row className='select-container'>
                        <Col span={12}>
                            <Form.Item
                                name="clientName"
                                label="Client"
                                required
                                rules={[{ required: true, message: 'Select Client Name' }]}
                            >
                                <div className='container'>
                                    <Select
                                        placeholder="Select"
                                        onChange={(e) => setClientType(e)}
                                    >
                                        {(clientData?.header || []).map((item: any) =>
                                            <Option value={item?.Id}>{item?.Name}</Option>
                                        )}
                                    </Select>
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="skuName"
                                label="SKU"
                            // required
                            // rules={[{ required: true, message: 'Select Client Name' }]}
                            >
                                <div className='container'>
                                    <Select
                                        placeholder="Select"
                                        onChange={(e) => setSKUType(e)}
                                        showSearch={true}
                                        optionFilterProp="children"
                                        value={skuType}
                                    >
                                        {(inventorySkuData?.SKUList || []).map((item: any) =>
                                            <Option value={item?.ItemID}>{item?.Description}</Option>
                                        )}
                                    </Select>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            )
        } catch (ex) {
            console.log("Err in formView::" + ex)
        }
    }


    const data = [
        {
            time: 'Nov',
            value: 40700,
            count: 30200,
        },
        {
            time: 'Dec',
            value: 40700,
            count: 20200,
        },
        {
            time: 'Jan',
            value: 35000,
            count: 30000,
        },
        {
            time: 'Feb',
            value: 90000,
            count: 600,
        },
        {
            time: 'Mar',
            value: 30000,
            count: 40000,
        },
        {
            time: 'Apr',
            value: 40050,
            count: 30080,
        },
        {
            time: 'May',
            value: 47000,
            count: 22000,
        },
        {
            time: 'June',
            value: 40070,
            count: 30000,
        },
        {
            time: 'July',
            value: 40700,
            count: 62990,
        },
        {
            time: 'Aug',
            value: 47000,
            count: 90020,
        },
        {
            time: 'Sept',
            value: 40700,
            count: 52000,
        },
        {
            time: 'Oct',
            value: 40700,
            count: 50200,
        },
    ];

    const packingMeterialColumns: any = [
        {
            title: "Packing Material",
            dataIndex: "",
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item?.packing_meterial}
                    </div>
                )
            }
        },
        {
            title: "Opening Balance",
            dataIndex: "",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item?.opening_balance}
                    </div>
                )
            }
        },
        {
            title: "Packing Material",
            dataIndex: "",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item?.inward_qty}
                    </div>
                )
            }
        },
        {
            title: "Packing Material",
            dataIndex: "",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item?.consumed_qty}
                    </div>
                )
            }
        },
        {
            title: "Packing Material",
            dataIndex: "",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item?.closing_balance}
                    </div>
                )
            }
        }
    ];

    const packingMeterialData = [
        {
            packing_meterial: "Customized Packing Tapes",
            opening_balance: 50,
            inward_qty: 20,
            consumed_qty: 50,
            closing_balance: 20
        },
        {
            packing_meterial: "Packing Tapes",
            opening_balance: 100,
            inward_qty: 50,
            consumed_qty: 50,
            closing_balance: 100
        },
        {
            packing_meterial: "Brown Papers",
            opening_balance: 20,
            inward_qty: 20,
            consumed_qty: 25,
            closing_balance: 15
        }
    ];

    useEffect(() => {
        setDate(moment());
    }, [])

    const increaseDate = () => {
        if (date?.format('DD-MMM-YYYY') != moment()?.format('DD-MMM-YYYY')) {
            let dateIncreased = moment(date, "DD-MMM-YYYY")?.add(1, 'd');
            setDate(dateIncreased);
        }
    }

    const decreaseDate = () => {
        let datedecreased = moment(date, "DD-MMM-YYYY")?.subtract(1, 'd');
        setDate(datedecreased);
    }

    const packingMaterialModal = () => {
        try {
            return (
                <Modal
                    className="start-receivingscreen-modal"
                    open={showModal}
                    onOk={() => setShowModal(false)}
                    width={'85%'}
                    title={
                        <div className='d-flex align-c jc-sb'>
                            <div className='d-flex align-c'>
                                <div>Packing material</div>
                            </div>
                            <div className='piece-box-container' style={{ cursor: "pointer" }}>
                                <Button
                                    style={{
                                        cursor: "pointer",
                                        marginRight: 10,
                                        textDecorationLine: "none",
                                        color: "white",
                                        background: 'transparent',
                                        border: 'none',
                                    }}
                                    onClick={() => decreaseDate()}
                                >
                                    Previous
                                </Button>
                                <DatePicker format={'DD-MM-YYYY'}
                                    allowClear={false}
                                    disabledDate={(e) => e > moment()}
                                    value={date}
                                    onChange={(e) => setDate(moment(e))}
                                    suffixIcon={<Calender />}
                                />
                                <Button
                                    style={{
                                        marginLeft: 10,
                                        background: 'transparent',
                                        border: 'none',
                                        textDecorationLine: "none",
                                        color: (date?.format('DD-MMM-YYYY') != moment()?.format('DD-MMM-YYYY')) ? "white" : "#CCD1D1",
                                        cursor: (date?.format('DD-MMM-YYYY') != moment()?.format('DD-MMM-YYYY')) ? "pointer" : "not-allowed",
                                        fontFamily: (date?.format('DD-MMM-YYYY') != moment()?.format('DD-MMM-YYYY')) ? "RedHat-Medium" : "RedHat-SemiBold"
                                    }}
                                    onClick={() => increaseDate()}

                                >
                                    Next
                                </Button>
                            </div>
                            <Button
                                onClick={() => setShowModal(false)}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>

                        </div>
                    }
                    onCancel={() => setShowModal(false)}
                    footer={null}
                >
                    <div className='table-screen-container'>
                        <Table
                            columns={packingMeterialColumns}
                            dataSource={packingMeterialData}
                            pagination={false}
                        />
                    </div>

                </Modal>
            )
        } catch (ex) {
            console.log("Err in skuScanModal")
        }
    }

    const handlePackingMeterial = () => {
        setShowModal(true);
    }

    return (
        <Layout>
            <Layout>
                <NavbarLayout
                    // callBack={processData}
                    titleContent="main-head"
                    process={clientData?.title}
                    selectedMain={'7'}
                    initial={AppModuleIds.main}
                />
            </Layout>
            <Content className="antd-content-white scroll-content">
                <div className='inventory-container'>
                    <Form
                        id='form'
                        form={form}
                        autoComplete="off"
                        noValidate
                    >
                        <Row gutter={16} style={{ display: "flex", alignItems: 'center', justifyContent: "space-between", marginLeft: 0, marginRight: 0 }} className="select-box">
                            <Col span={12}>{formView()}</Col>
                            <Col span={12} style={{ display: "flex", alignItems: 'center', justifyContent: "flex-end" }}>
                                {/* <div className='piece-box-container' style={{ cursor: "pointer" }}>
                                <span style={{ cursor: "pointer", marginRight: 10, textDecorationLine: "none" }}>Previous</span>
                                <DatePicker format={'DD-MM-YYYY'} onChange={onChange} suffixIcon={<Calender />} />
                                <span style={{ marginLeft: 10, cursor: "pointer", textDecorationLine: "none" }}>Next</span>
                            </div> */}
                                {visible === true &&
                                    <div className='date-container'>
                                        {inventoryData?.InventoryAsOfDateAndTime[0]?.InventoryDateAndTime}
                                    </div>
                                }
                                {/* <Tooltip placement="top" title={"Packing material"}>
                                    <div
                                        onClick={() => handlePackingMeterial()}
                                        className='export-btn'
                                        style={{ cursor: "pointer" }}>
                                        <PackingMeterialIcon />
                                    </div>
                                </Tooltip> */}
                                <Tooltip placement="top" title={"Export CSV"}>
                                    <div className='export-btn' style={{ marginLeft: 15, cursor: "pointer" }}>
                                        <ExportIcon />
                                    </div>
                                </Tooltip>
                                <Tooltip placement="top" title={"Refresh"}>
                                    <div className='export-btn'
                                        style={{ marginLeft: 15, cursor: "pointer" }}
                                        onClick={() => refreshPage()}
                                    >
                                        <ReloadOutlined />
                                    </div>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Form>
                    {visible === false ?
                        <div className='Info'>Please select Client and SKU</div>
                        :
                        <>
                            <div className='inventory-barchart-container'>
                                {inventoryContent()}
                            </div>
                            {/* <div className='overall-allocated-inventory-container'> */}
                            {overallAndAllocationInventoryView()}
                            {/* </div> */}
                            <div className='analytics-overall-container'>
                                <Row gutter={16} className='analytics-info-container' style={{ display: "flex", alignItems: "center" }}>
                                    <Col span={14} className='title'>Overall Orders on Yearly Basis :  {skuType}</Col>
                                    <Col span={6} className='info-analytics'>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <div className='content-percentage-container'>
                                                    <div className='inward-color-box'></div>
                                                    <div className='content'> {"Inward"}</div>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div className='content-percentage-container'>
                                                    <div className='outward-color-box'></div>
                                                    <div className='content'> {"Outward"}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={3}>
                                        <div className='view-container'>
                                            {analyticChartView === false ?
                                                <div onClick={() => setAnalyticChartView(true)} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                                    <TableInventoryIcon />
                                                    <span className='view-info'>Table View</span>
                                                </div> :
                                                <div onClick={() => setAnalyticChartView(false)} style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                                    <ChartInventory />
                                                    <span className='view-info'>Chart View</span>
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                {analyticChartView === false ?
                                    <AnalyticsOfOverAllOrderChartComponent /> :
                                    <div className="table-screen-container">
                                        <Table
                                            columns={analyticcolumns}
                                            dataSource={data}
                                            pagination={false}
                                            bordered />
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>
                {packingMaterialModal()}
            </Content>
        </Layout>
    );
}

export default InventoryManagemet;