import { CaretRightOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Layout, Modal, Row, Select } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoData from '../../CustomComponent/NoData';
import { AppModuleIds } from '../../Globals/AppReferenceIds';
import { isArrayNotEmpty, uniqueBy } from '../../Globals/Helper';
import { closeCycleCountAction, cycleCountApproveandRejectAction, getCycleCountExpandMaster, getCycleCountForMaster, getStorageSkuListAction, getStorageSkuQTYUpdateAction, getStorageSkuSelectAction } from '../../Store/Actions/ProcessWMSAction';
import { getAdhocExpandData, getDateReportListSaga } from '../../Store/Saga/ProcessSaga';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import CardContainer from '../CardContainer';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import SkeletonLoader from '../OrderProcessing/SkeletonLoader';


const { Option } = Select;

function WarehouseManagerCycleCount(props: any) {
    const pageTitle = {
        title: "Cycle Count"
    }
    const [form]: any = Form.useForm();
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const {
        cycleCountMasterData,
        cycleCountExpandData,
        cycleCountStorageSKUOnLoad,
        cycleCountStorageSKUList,
        cycleCountStorageSKUSelectList,
        cycleCountStorageSKUQTYUpdateOnLoad,
        cycleCountCloseOnLoad,
        approveRejectCycleCountOnLoad
    }: any = reducerState;
    const [expandRow, setExpandRow] = useState<any>();
    const [expandRowTwo, setExpandRowTwo] = useState<any>();
    const [openModal, setOpenModal] = useState<any>();
    const [onLoad, setOnLoad] = useState<any>({});
    const [ItemIDRecNo, setItemIDRecNo] = useState<any>();
    const [item, setItem] = useState<any>({});
    const [cycleCountRecNo, setCycleCountRecNo] = useState<any>();

    useEffect(() => {
        if (!cycleCountStorageSKUQTYUpdateOnLoad && onLoad.updateOnLoad === true) {
            if (cycleCountStorageSKUSelectList?.lineitems.length == 0) {
                setItemIDRecNo("")
            }
            getSelectSku()
            setItem({ ...item, qty: "" })
            form.setFieldsValue({
                ['qty']: "",
            });
            setOnLoad({ ...onLoad, updateOnLoad: false })
        }
    }, [cycleCountStorageSKUQTYUpdateOnLoad, onLoad.updateOnLoad])

    useEffect(() => {
        if (!cycleCountCloseOnLoad && onLoad.closeCycleCountOnLoad === true) {
            getData()
            setOnLoad({ ...onLoad, closeCycleCountOnLoad: false })
        }
    }, [cycleCountCloseOnLoad, onLoad.closeCycleCountOnLoad])

    useEffect(() => {
        if (!approveRejectCycleCountOnLoad && onLoad.approveAndRejectCycleCountOnLoad === true) {
            getExpandData(cycleCountRecNo)
            getData()
            setOnLoad({ ...onLoad, approveAndRejectCycleCountOnLoad: false })
        }
    }, [approveRejectCycleCountOnLoad, onLoad.approveAndRejectCycleCountOnLoad])

    useEffect(() => {
        if (onLoad.storageSkuListOnLoad === true && !cycleCountStorageSKUOnLoad) {
            setOpenModal(true)
            setItemIDRecNo("")
            setOnLoad({ ...onLoad, storageSkuListOnLoad: false })
        }
    }, [onLoad.storageSkuListOnLoad, cycleCountStorageSKUOnLoad])

    const closeCycleCount = (CycleCountRecNo: any) => {
        let payload = {
            "opnfor": "Z00002",
            "activity": "A-05",
            "transaction": CycleCountRecNo,
            "warehouse": getWareHouseId(),
            "user": getUserInfo()?.UserName
        }
        dispatch(closeCycleCountAction(payload))
        setOnLoad({ ...onLoad, closeCycleCountOnLoad: true })
    }

    const updateQty = () => {
        let payload = {
            "opnfor": "Z00002",
            "activity": "A-04",
            "transaction": item?.locationID,
            "where": ItemIDRecNo,
            "fieldArray": item.qty,
            "warehouse": getWareHouseId(),
            "user": getUserInfo()?.UserName
        }
        dispatch(getStorageSkuQTYUpdateAction(payload))
        setOnLoad({ ...onLoad, updateOnLoad: true })
    }

    const getSelectSku = () => {
        let payload = {
            "opnfor": "Z00002",
            "activity": "A-03",
            "transaction": ItemIDRecNo,
            "warehouse": getWareHouseId(),
            "user": getUserInfo()?.UserName
        }
        dispatch(getStorageSkuSelectAction(payload))
    }

    useEffect(() => {
        if (ItemIDRecNo !== "") {
            getSelectSku()
        }

    }, [ItemIDRecNo])

    const getStorageSkuList = () => {
        let payload = {
            "opnfor": "Z00002",
            "activity": "A-02",
            "transaction": getWareHouseId(),
            "user": getUserInfo()?.UserName
        }
        dispatch(getStorageSkuListAction(payload))
        setOnLoad({ ...onLoad, storageSkuListOnLoad: true })
    }

    const approveAndRejectCycleCount = (data: any, flag: any) => {
        let payload = {
            "opnfor": "Z00002",
            "activity": "A-06",
            "transaction": cycleCountRecNo,
            "fieldArray": data?.LocationID,
            "tableNo": `${flag}`,
            "where": data?.InventoryID,
            "customer": data?.CycleCountQty,
            "warehouse": data?.ItemIDRecNo,
            "user": getUserInfo()?.UserName
        }
        dispatch(cycleCountApproveandRejectAction(payload))
        setOnLoad({ ...onLoad, approveAndRejectCycleCountOnLoad: true })
    }

    const getData = () => {
        let payload = {
            "opnfor": "Z00002",
            "activity": "A-V",
            "transaction": getWareHouseId(),
            "user": getUserInfo()?.UserName
        }
        dispatch(getCycleCountForMaster(payload))
    }

    const getExpandData = (item: any) => {
        let payload = {
            "opnfor": "Z00002",
            "activity": "A-01",
            "transaction": `${item}`,
            "user": getUserInfo()?.UserName
        }
        dispatch(getCycleCountExpandMaster(payload))
    }

    useEffect(() => {
        getData()
    }, [])

    const titleContainer = () => {
        try {
            return (
                <Row>
                    <Col span={17}>
                        <div className='text'>{'Cycle Count ID'}</div>
                    </Col>
                    <Col span={7} className='d-flex jc-c'>
                        <div className='text '>{'Action'}</div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }


    const handleOuterExpand = (item: any, index: any) => {
        setExpandRow(expandRow == index ? null : index);
        setCycleCountRecNo(item?.CycleCountRecNo)
        getExpandData(item?.CycleCountRecNo)
    }

    const invoicetableHeaderView = () => {
        try {
            return (
                <Row className='table-header inner-table-blue-header'>
                    <Col span={24} className="title">
                        {"Location"}
                    </Col>
                    {/* <Col span={6} className='title d-flex align-c'>
                        {"Storage Type"}
                    </Col>
                    <Col span={6} className="title d-flex align-c">
                        {"Structure Type"}
                    </Col>
                    <Col span={6} className="title d-flex align-c jc-c">
                        {"Total SKU Count"}
                    </Col> */}
                </Row>
            )
        } catch (ex) {
            console.log("Err in cartontableHeaderView::" + ex)
        }
    }

    const innerTableHeader = () => {
        try {
            return (
                <Row className='table-header inner-table-skyblue-header align-c'>
                    <Col span={5} className="title">
                        {"SKU,Description"}
                    </Col>
                    <Col span={3} className="title">
                        {"Pallet"}
                    </Col>
                    <Col span={1} className="title">
                        {"Box"}
                    </Col>
                    <Col span={5} className="title d-flex align-c jc-c">
                        {"System Qty"}
                    </Col>
                    <Col span={4} className='title d-flex align-c jc-c'>
                        {"Current Inventory Count"}
                    </Col>
                    <Col span={6} className='title d-flex align-c jc-c'>
                        {"Action"}
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in cartontableHeaderView::" + ex)
        }
    }

    const skuTableBody = (data: any) => {
        try {
            let item = (data?.XofY).split(',')
            return (
                <div className='scroll'>
                    {/* {(lineitemsThree || []).map((x: any) => {
                                        return ( */}
                    <Row className={(Number(data?.SystemQty) >= Number(data?.CycleCountQty)) ? 'content-container table-background-green mt-10' : (Number(data?.SystemQty) <= Number(data?.CycleCountQty)) ? 'content-container table-background-red mt-10' : 'content-container border-blue mt-10'}>
                        <Col span={5} className='text d-flex align-c' >
                            {data?.SKU}
                        </Col>
                        <Col span={3} className='text d-flex align-c' >
                            {item[0]}
                        </Col>
                        <Col span={1} className='text d-flex align-c' >
                            {item[1]}
                        </Col>
                        <Col span={5} className='text d-flex align-c jc-c'>
                            {data?.SystemQty}
                        </Col>
                        <Col span={4} className='text d-flex align-c jc-c'>
                            {data?.CycleCountQty}

                        </Col>
                        <Col span={6} className='text d-flex align-c jc-c'>
                            {(data?.Status === "NA") ?
                                <div className='ml-25 d-flex align-c'>
                                    <div className='approve-btn ' style={{ cursor: "pointer" }} onClick={() => approveAndRejectCycleCount(data, "A")}>
                                        {'Approve'}
                                    </div>
                                    <div className='reject-btn ml-10' style={{ cursor: "pointer" }} onClick={() => approveAndRejectCycleCount(data, "R")}>
                                        {'Reject'}
                                    </div>
                                </div> :
                                <div className={(data?.Status === "A") ? 'stickering-text ' : "rejected-text"}>
                                    <span title={item} className='overflow-tagline'> {(data?.Status === "A") ? "Approved" : "Rejected"}</span>
                                </div>
                            }

                        </Col>
                    </Row>
                </div>
            )
        } catch (ex) {
            console.log("Err in skuTableBody" + ex)
        }
    }

    const handleMiddleExpand = (item: any, index: any) => {
        setExpandRowTwo(expandRowTwo == index ? null : index);
    }

    const middleTable = (data: any, index: any) => {
        try {
            let isExpandopen = (expandRowTwo == index);
            return (
                <div>
                    <div className={index === expandRowTwo ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                        <Row>
                            <Col span={24} className='d-flex align-c'>
                                <div className="title-container">
                                    <div className='d-flex align-c'>
                                        <CaretRightOutlined
                                            onClick={() => handleMiddleExpand(data, index)}
                                            className={index === expandRowTwo ? "arrow arrow-down" : "arrow arrow-right"} />
                                        <div className='ml-5' >
                                            {data?.LocationID}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col span={6} className='d-flex align-c'>
                                <div className='text'>
                                    {data?.storageType}
                                </div>
                            </Col>
                            <Col span={6} className='d-flex align-c'>
                                <div className='text'>
                                    {data?.structureType}
                                </div>
                            </Col>
                            <Col span={6} className='d-flex align-c jc-c'>
                                <div className='text'>
                                    {data?.totalSKUCount}
                                </div>
                            </Col> */}
                        </Row>
                        {isExpandopen == true &&
                            <div className='mt-10'>
                                {innerTableHeader()}
                                {skuTableBody(data)}
                            </div>
                        }
                    </div>
                </div>
            )
        } catch (error) {
            console.log("Error in tableview::", error)
        }
    }

    const excessAndShortageModal = () => {
        try {
            let uniqueSKUItem = uniqueBy(cycleCountStorageSKUList?.lineitems, 'ItemIDRecNo')
            return (
                <Modal
                    open={openModal}
                    footer={null}
                    className="modal-view-2"
                    title={<div className='d-flex align-c jc-sb'>
                        <div className='redhat-bold font-18'>
                            View Virtual Excess/shortage
                        </div>
                        <Button
                            onClick={() => setOpenModal(false)}
                            className="cross-btn">
                            <CloseOutlined />
                        </Button>
                    </div>}
                    closable={false}
                >
                    <div className='d-flex align-c w-100'>
                        <div className='text-16'>
                            Select SKU
                        </div>
                        <div className='ml-10 w-30'>
                            <Select style={{ width: "100%" }}
                                value={ItemIDRecNo}
                                onChange={(e: any) => setItemIDRecNo(e)}
                                placeholder="Select"
                            >
                                {(uniqueSKUItem || []).map((item: any) => {
                                    return (
                                        <Option key={item?.ItemIDRecNo}>{item?.SKU}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                    {(ItemIDRecNo === "") ?
                        <div className='p-20'>
                            <NoData
                                className='mt-45'
                                description={
                                    <>No data available</>
                                }
                            />
                        </div> :

                        <div className='mt-10'>
                            {isArrayNotEmpty(cycleCountStorageSKUSelectList?.lineitems) ?
                                <>
                                    <div className='header-part'>
                                        {excessAndShortageTitleContainer()}
                                    </div>

                                    <div>
                                        {(cycleCountStorageSKUSelectList?.lineitems || []).map((x: any) => {
                                            return (
                                                <Row className={x?.excess != '' ? 'content-container table-background-green mt-10' : x?.shortage != 0 ? 'content-container table-background-red mt-10' : 'content-container border-blue mt-10'}>
                                                    <Col span={4} className='text d-flex align-c' >
                                                        {x?.LocationID}
                                                    </Col>
                                                    <Col span={5} className='text d-flex align-c jc-c'>
                                                        {x?.CurrentInvQty}
                                                    </Col>
                                                    <Col span={6} className='text d-flex align-c jc-c'>
                                                        {x?.ExcessQty}
                                                    </Col>
                                                    <Col span={3} className='text d-flex align-c  jc-c'>
                                                        {x?.ShortQty}
                                                    </Col>
                                                    <Col span={6} className='text d-flex align-c'>
                                                        <div className='d-flex align-c'>
                                                            <Form.Item
                                                                name="qty"
                                                                rules={
                                                                    [
                                                                        { required: true, message: "Enter Expense Title" },
                                                                        { pattern: /^[0-9]+$/, message: "Alphabets and No Special Character allowed " },
                                                                    ]
                                                                }

                                                            >
                                                                <div className='mr-10'>
                                                                    <Input
                                                                        onChange={(e: any) => setItem({ ...item, qty: e, locationID: x?.LocationID })}
                                                                        placeholder='Update New Qty'
                                                                    />
                                                                </div>
                                                            </Form.Item>
                                                            <div className='approve-btn ' style={{ cursor: "pointer" }} onClick={() => { item?.qty !== "" && updateQty() }}>
                                                                {'Update'}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                    </div>
                                </> :
                                <div className='p-20'>
                                    <NoData
                                        className='mt-45'
                                        description={
                                            <>No data available</>
                                        }
                                    />
                                </div>
                            }
                        </div>
                    }
                </Modal>
            )
        } catch (error) {
            console.log("Error in exessAndShortageModal::", error);
        }
    }

    const handleExcessAndShortage = () => {

    }

    const outerTable = (data: any, index: any) => {
        try {
            let isExpandopen = (expandRow == index);
            return (
                <div>
                    <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                        <Row>
                            <Col span={17} className='d-flex align-c'>
                                <div className="title-container">
                                    <div className='d-flex align-c'>
                                        <CaretRightOutlined
                                            onClick={() => handleOuterExpand(data, index)}
                                            className={index === expandRow ? "arrow arrow-down" : "arrow arrow-right"} />
                                        <div className='text ml-5' >
                                            {data?.CycleCountRecNo}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={7} className='d-flex align-c jc-c'>
                                <div
                                    onClick={() => closeCycleCount(data?.CycleCountRecNo)}
                                    className='default-btn'
                                    style={{ cursor: "pointer" }}
                                >
                                    {'Close cycle count'}
                                </div>

                            </Col>
                        </Row>
                        {isExpandopen == true &&
                            <div style={{ padding: 10, background: "#FFFFFF", borderRadius: 6, marginTop: 10 }}>
                                {invoicetableHeaderView()}
                                {isArrayNotEmpty(cycleCountExpandData?.lineitems) ?
                                    <div className='body-view'>
                                        {(cycleCountExpandData?.lineitems || []).map((x: any, index: any) =>
                                            middleTable(x, index))}
                                    </div> :
                                    <div className='p-20'>
                                        <NoData
                                            className='mt-45'
                                            description={
                                                <>No data available</>
                                            }
                                        />
                                    </div>
                                }
                            </div>}
                    </div>
                </div>
            )
        } catch (error) {
            console.log("Error in tableview::", error)
        }
    }

    const header = () => {
        return (
            <SubHeader
                title={"Cycle Count Pending Approval"}
                paginationView="false"
                inputView="true"
                btnView="true"
            >
                <div className='d-flex flex-end'>
                    <div
                        onClick={() => getStorageSkuList()}
                        className='default-btn-2 mr-10'
                        style={{ cursor: "pointer" }}
                    >
                        {'View Virtual Excess / Shortage'}
                    </div>
                    <div>
                        <Input placeholder='Search By ID' />
                    </div>
                </div>
            </SubHeader >
        )
    }


    const excessAndShortageTitleContainer = () => {
        try {
            return (
                <Row>
                    <Col span={4}>
                        <div className='text'>{'Location'}</div>
                    </Col>
                    <Col span={5} className='d-flex jc-c'>
                        <div className='text '>{'Current Inventory Qty'}</div>
                    </Col>
                    <Col span={6} className='d-flex jc-c'>
                        <div className='text '>{'Excess Qty'}</div>
                    </Col>
                    <Col span={3} className='d-flex jc-c'>
                        <div className='text '>{'Shortage Qty '}</div>
                    </Col>
                    <Col span={6} className='d-flex jc-c'>
                        <div className='text '>{'Action'}</div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }

    return (
        <Layout>
            <NavbarLayout
                titleContent="main-head"
                process={pageTitle}
                initial={AppModuleIds.main}
                selectedMain={'10'}
            />
            <Content className="antd-content-white">

                <div className=''>
                    {header()}
                    <div className='p-10'>
                        <div className='header-part'>
                            {titleContainer()}
                        </div>
                        {isArrayNotEmpty(cycleCountMasterData?.lineitems) ?
                            < div className='expand-collape-container'>
                                {(cycleCountMasterData?.lineitems || []).map((data: any, index: any) =>
                                    outerTable(data, index)
                                )}
                            </div>
                            :
                            <div className='p-20'>
                                <NoData
                                    className='mt-45'
                                    description={
                                        <>No data available</>
                                    }
                                />
                            </div>
                        }
                    </div>
                </div>
            </Content>
            {excessAndShortageModal()}
        </Layout >
    );
}

export default WarehouseManagerCycleCount;