import { CaretRightOutlined, FileImageOutlined } from '@ant-design/icons';
import { Col, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoData from '../../CustomComponent/NoData';
import AppReferences from '../../Globals/AppReferences';
import { isArrayNotEmpty } from '../../Globals/Helper';
import { getAttachmentsAction, GetExpandGAEReportList, GetGAEReportList } from '../../Store/Actions/ProcessWMSAction';
import { getWareHouseId, getUserInfo } from '../../Utils/SessionStorage';
import ImgModelView from '../ImgModelView';
import "../Report/Report.scss"

let Arr = [1, 2, 3]

function GeneralAdminExpenseReport(props: any) {
    const {
        setSearchView,
        searchView,
        setDateView,
        dateView
    }=props
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);

    const [expandRow, setExpandRow] = useState<any>();
    const { reportGAEList, 
        reportExpandGAEList,
        attachementDetails,
        getAttachmentOnLoad,
        reportSearchGateInList,
        reportDateList,
        reportGAEOnload
     } = reducerState;
     const [OnLoad, setOnLoad] = useState<any>(false)
    const [attachmentOnLoad, setAttachmentOnLoad] = useState<any>(false);
    const [openModal, setOpenModal] = useState<any>({
        isopen: false,
        url: ''
    });

    useEffect(() => {
        if (OnLoad === true && reportGAEOnload === false) {
            setSearchView(false)
            setDateView(false)
        }
    }, [OnLoad, reportGAEOnload])

    const getData = (data: any) => {
        let payload = {
            "opnfor": "R00003",
            "activity": "A-07",
            "transaction": data ? data?.RecordNumber : 0,
            "where": data ? "Y" : "N",
            "warehouse": getWareHouseId(),
            "user": getUserInfo()?.UserName
        }
        data ? dispatch(GetExpandGAEReportList(payload)) : dispatch(GetGAEReportList(payload))
        setOnLoad(true)
    }

    useEffect(() => {
        getData(null)
    }, [])

    useEffect(() => {
        if (reportDateList) {
            setDateView(true)
            setSearchView(false)
        }
    }, [reportDateList])

    useEffect(() => {
        if (reportSearchGateInList) {
            setSearchView(true)
            setDateView(false)
        }
    }, [reportSearchGateInList])


    

    const openImageviewr = () =>{
        let attachments = attachementDetails?.media?.objects;
        let startIndex = attachments?.slice(-1, attachments.length);
        let restOfData = attachments?.slice(0, -1);
        if(attachments.length > 0){
            setOpenModal({isOpen: true, urlArray: [...startIndex, ...restOfData]});
        }else{
            message.info("No Attachment")
        }
        
    }

    useEffect(() => {
        if (attachmentOnLoad == true && getAttachmentOnLoad == false) {
            setAttachmentOnLoad(false)
            openImageviewr()
        }
    }, [attachmentOnLoad, getAttachmentOnLoad])

    const getAttachment = (expandData: any,item: any) => {
        let payload = {
            opnfor: "A00001",
            activity: AppReferences.activities.a09,
            transaction: item?.RecordNumber,
            warehouse: getWareHouseId(),
            customer: expandData?.CustCode
        }
        dispatch(getAttachmentsAction(payload));
        setAttachmentOnLoad(true);
    }

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
            getData(data)
        }
    }


    const skuInvoiceHeaderView = () => {
        try {
            return (
                <Row className='table-header invoice-header  align-c'>
                    <Col span={5}>
                        {"Warehouse"}
                    </Col>
                    <Col span={5} className=' d-flex jc-c'>
                        {"Client"}
                    </Col>
                    <Col span={4} className=' d-flex jc-c'>
                        {"Expense type"}
                    </Col>
                    <Col span={4} className=' d-flex jc-c'>
                        {"Approved By"}
                    </Col>
                    <Col span={3} className=' d-flex jc-c'>
                        {"Amount"}
                    </Col>
                    <Col span={3} className=' d-flex jc-c'>
                        {"Attachment"}
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in cartontableHeaderView::" + ex)
        }
    }

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={5}>
                    {"Expense ID #"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Date"}
                </Col>
                <Col span={5} className=' d-flex jc-c'>
                    {"Create - till days"}
                </Col>
                <Col span={5} className=' d-flex jc-c'>
                    {"Title"}
                </Col>
                <Col span={5} className=' d-flex jc-c'>
                    {"Amount"}
                </Col>

            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        return (
            <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={5} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='title-blue'>
                            {item?.RecordNumber}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1 textalign-c'>
                            {item?.ExpenseDate}
                        </div>
                    </Col>
                    <Col span={5} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.CreateTillDays}
                        </div>
                    </Col>
                    <Col span={5} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.Title}
                        </div>
                    </Col>
                    <Col span={5} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.Amount}
                        </div>
                    </Col>

                    {open && <Col span={24} style={{ padding: 10, marginTop: 10, background: "#FFFFFF", borderRadius: 6, }}>
                        {skuInvoiceHeaderView()}
                        <div className='scroll'>
                            {(reportExpandGAEList?.lineitems || []).map((x: any) => {
                                return (
                                    <Row className='content-container border-blue d-flex align-c jc-sb mt-10'>
                                        <Col span={5} className='text-1'>{x?.WarehouseName}</Col>
                                        <Col span={5} className=' d-flex align-c jc-c'>
                                            <div className='text-1'>
                                                {x?.CustomerName}
                                            </div>
                                        </Col>
                                        <Col span={4} className=' d-flex align-c jc-c'>
                                            <div className='text-1 textalign-c'>
                                                {x?.ExpenseType}
                                            </div>
                                        </Col>
                                        <Col span={4} className=' d-flex align-c jc-c'>
                                            <div className='text-1'>
                                                {x?.ApprovedBy}
                                            </div>
                                        </Col>
                                        <Col span={3} className=' d-flex align-c jc-c'>
                                            <div className='text-1'>
                                                {x?.Amount}
                                            </div>
                                        </Col>
                                        <Col span={3} className=' d-flex align-c jc-c'>
                                            <div className='file' title='View Attachment' onClick={()=>getAttachment(x,item)}>
                                                <FileImageOutlined width={50} height={50} />
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </div>
                    </Col>}
                </Row>
            </div >
        )
    }
    return (
        <div className='GateInReport-Container'>
            {(dateView ? isArrayNotEmpty(reportDateList?.lineitems) : searchView ? isArrayNotEmpty(reportSearchGateInList?.lineitems)  : isArrayNotEmpty(reportGAEList?.lineitems)) ?
                <div style={{ padding: 20 }}>
                    {tableHeaderView()}
                    <div className='table-scroll'>
                        {(dateView ? reportDateList?.lineitems : searchView?  reportSearchGateInList?.lineitems : reportGAEList?.lineitems || []).map((x: any, index: any) => tableBodyView(x, index))}
                    </div>
                    <ImgModelView
                    open={openModal?.isOpen}
                    // srcUrl={openModal?.url}
                    urlArray={openModal?.urlArray}
                    close={() => setOpenModal({isOpen: false, urlArray: []})}
                />
                </div>
                :
                <div className='p-20'>
                    <NoData
                        className='mt-45'
                        description={
                            <>There is no reports available in <span className='redhat-bold ml-5'>General Admin Expense</span></>
                        }
                    />
                </div>
            }
        </div>
    );
}

export default GeneralAdminExpenseReport;