import React from "react";

export default function EmizaIcon() {
    return (
        <svg width="124" height="29" viewBox="0 0 124 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M42.5954 10.5707C43.0062 8.13342 43.4031 5.77742 43.8001 3.42142C43.9718 2.40706 44.1575 1.39271 44.3061 0.37371C44.3502 0.0789198 44.4523 0.00696326 44.7401 0.00696326C47.7484 0.0185692 50.759 0.013927 53.7672 0.013927C55.471 0.013927 57.177 0.0208903 58.8808 0.00696326C59.1454 0.00696326 59.2266 0.0649929 59.2266 0.343535C59.2197 9.44488 59.2173 18.5439 59.2266 27.6453C59.2266 27.954 59.1268 27.9958 58.8552 27.9934C55.8725 27.9842 52.8875 27.9818 49.9048 27.9958C49.5682 27.9958 49.5125 27.8936 49.5125 27.5849C49.5218 23.047 49.5218 18.5091 49.5125 13.9735C49.5125 13.8505 49.6332 13.6393 49.3755 13.6044C49.1875 13.5789 49.039 13.5859 48.9949 13.8482C48.7186 15.538 48.4262 17.2232 48.136 18.9107C47.6463 21.7634 47.1518 24.6161 46.669 27.4712C46.6365 27.6615 46.5553 27.6917 46.3905 27.6917C43.8465 27.687 41.3025 27.687 38.7585 27.694C38.5542 27.694 38.4938 27.6197 38.4637 27.4387C37.9716 24.4931 37.4748 21.5498 36.9804 18.6066C36.7158 17.0328 36.4489 15.4591 36.1889 13.8853C36.1541 13.6787 36.0891 13.5743 35.8477 13.5905C35.5692 13.6114 35.6736 13.8017 35.6736 13.9248C35.669 18.4743 35.6643 23.0261 35.6782 27.5756C35.6782 27.9215 35.59 27.9934 35.2558 27.9911C32.2847 27.9772 29.3159 27.9772 26.3448 27.9911C26.0361 27.9911 25.9688 27.9076 25.9688 27.6081C25.9757 18.5323 25.9757 9.45881 25.9688 0.382995C25.9688 0.0858836 26.0361 0 26.3448 0C30.9175 0.0116059 35.4879 0.0116059 40.0606 0C40.3554 0 40.4251 0.109095 40.4738 0.35514C41.1446 3.69764 41.8247 7.03782 42.5025 10.378C42.5095 10.4128 42.535 10.443 42.5977 10.5637L42.5954 10.5707Z" fill="white" />
            <path d="M123.445 27.9928H123.076C120.068 27.9928 117.06 27.9882 114.049 28.0021C113.74 28.0021 113.631 27.9023 113.566 27.6191C113.369 26.7533 113.137 25.8968 112.94 25.0333C112.893 24.8267 112.812 24.7664 112.605 24.7664C110.655 24.7733 108.706 24.7757 106.756 24.7664C106.524 24.7664 106.452 24.8476 106.41 25.0635C106.241 25.9479 106.046 26.8276 105.869 27.7096C105.827 27.9139 105.755 28.0021 105.519 28.0021C102.497 27.9928 99.4743 27.9951 96.4545 27.9951C96.4034 27.9951 96.3524 27.9858 96.2734 27.9789C96.5682 26.6419 96.863 25.3119 97.1555 23.9841C98.7455 16.8024 100.336 9.62299 101.923 2.44126C102.079 1.73794 102.241 1.03694 102.385 0.331306C102.429 0.108472 102.501 0.015625 102.756 0.015625C107.443 0.0249097 112.132 0.0249097 116.818 0.015625C117.083 0.015625 117.155 0.106151 117.21 0.349875C118.668 6.83525 120.137 13.3183 121.602 19.8014C122.182 22.3732 122.763 24.9428 123.343 27.5147C123.376 27.6632 123.406 27.8141 123.443 27.9928H123.445ZM109.764 17.3966C110.358 17.3966 110.953 17.3827 111.544 17.4036C111.811 17.4129 111.872 17.334 111.83 17.0786C111.37 14.1563 110.918 11.2339 110.463 8.31152C110.354 7.61285 110.351 7.62213 109.63 7.61981C109.4 7.61981 109.307 7.66391 109.265 7.92389C108.778 10.9971 108.272 14.0657 107.761 17.1367C107.726 17.3479 107.766 17.4059 107.981 17.3989C108.576 17.385 109.168 17.3943 109.762 17.3943L109.764 17.3966Z" fill="white" />
            <path d="M84.578 0.0147762C88.1433 0.0147762 91.7063 0.0194184 95.2716 0.0078125C95.5827 0.0078125 95.6477 0.0960177 95.6477 0.390808C95.6361 2.78859 95.6384 5.18637 95.6477 7.58415C95.6477 7.81858 95.578 7.99267 95.4364 8.17373C92.5791 11.7808 89.7287 15.3926 86.8782 19.0044C86.7761 19.1343 86.6438 19.2574 86.5881 19.4082C86.4163 19.8818 86.5835 20.0999 87.0872 20.0999C89.8006 20.0999 92.5117 20.1092 95.2229 20.0907C95.592 20.0883 95.6523 20.2021 95.65 20.5363C95.6361 22.8947 95.6384 25.2553 95.65 27.6136C95.65 27.9061 95.592 27.9989 95.2763 27.9989C88.134 27.9896 80.9917 27.9896 73.8495 27.9989C73.5407 27.9989 73.4711 27.9154 73.4711 27.6183C73.485 25.3621 73.4827 23.1059 73.4711 20.8497C73.4711 20.5897 73.5384 20.3901 73.6986 20.1858C76.6209 16.4673 79.5364 12.7418 82.4564 9.02096C82.6421 8.7842 82.7907 8.56368 82.779 8.23175C82.7674 7.94625 82.6746 7.90215 82.4193 7.90215C79.5642 7.91143 76.7115 7.90215 73.8564 7.91375C73.5732 7.91375 73.4665 7.86733 73.4688 7.54701C73.485 5.16315 73.4827 2.77698 73.4688 0.393128C73.4688 0.0983385 73.5338 0.0101337 73.8448 0.0101337C77.4218 0.0217396 81.001 0.0170974 84.578 0.0170974V0.0147762Z" fill="white" />
            <path d="M71.4298 14.0069C71.4298 18.5285 71.4251 23.0525 71.439 27.5742C71.439 27.9154 71.3578 27.9989 71.0143 27.9966C68.0571 27.9804 65.1022 27.9827 62.145 27.9966C61.8247 27.9966 61.7087 27.9502 61.711 27.5858C61.7226 18.5285 61.7226 9.46894 61.7133 0.411698C61.7133 0.0913749 61.7969 0.00781255 62.1172 0.0101337C65.086 0.0217396 68.0548 0.0263819 71.0259 0.0078125C71.3903 0.0078125 71.439 0.119229 71.439 0.441873C71.4297 4.96353 71.4321 9.48751 71.4321 14.0092L71.4298 14.0069Z" fill="white" />
            <path d="M4.74219 19.4497C4.86985 19.4428 4.99984 19.4312 5.1275 19.4312C11.2995 19.4312 17.4715 19.4335 23.6436 19.4219C23.9917 19.4219 24.0614 19.5101 24.0591 19.8443C24.0451 22.4487 24.0498 25.0507 24.0544 27.6551C24.0544 27.8687 24.0544 27.9986 23.7596 27.9986C17.4715 27.9894 11.1835 27.9917 4.89539 27.9894C4.84432 27.9894 4.79325 27.9777 4.74219 27.9708C4.74219 25.1297 4.74219 22.2885 4.74219 19.4497Z" fill="white" />
            <path d="M4.74281 0.0637464C4.87048 0.0544617 4.99814 0.0405349 5.12581 0.0405349C10.8313 0.0405349 16.5344 0.0405347 22.2399 0.03125C22.5672 0.03125 22.6716 0.0916009 22.667 0.44442C22.6484 3.04646 22.6554 5.64851 22.6623 8.25055C22.6623 8.49428 22.6414 8.60569 22.342 8.60569C16.546 8.59641 10.7523 8.59873 4.95636 8.59641C4.87976 8.59641 4.80316 8.58248 4.72656 8.57319C4.7312 5.73671 4.73585 2.90023 4.73817 0.0614252L4.74281 0.0637464Z" fill="white" />
            <path d="M4.74219 9.7654C4.86985 9.75844 4.99984 9.74915 5.1275 9.74915C9.6863 9.74915 14.2451 9.75147 18.8039 9.74219C19.1289 9.74219 19.2356 9.79557 19.2333 10.153C19.2147 12.7435 19.2194 15.3339 19.2287 17.9243C19.2287 18.1936 19.1892 18.3027 18.8782 18.3027C14.2544 18.2911 9.63291 18.2957 5.00912 18.2934C4.92092 18.2934 4.83271 18.2748 4.74219 18.2632C4.74219 15.4314 4.74219 12.5972 4.74219 9.7654Z" fill="white" />
            <path d="M4.73606 0.0692377C4.73141 2.90572 4.72677 5.7422 4.72445 8.58101C3.22961 8.58797 1.73477 8.59029 0.239931 8.6019C0.0426302 8.6019 0.0078125 8.52066 0.0078125 8.34889C0.0124549 5.65864 0.0101337 2.97071 0.0078125 0.280465C0.0078125 0.129588 0.0333456 0.0390625 0.216719 0.0390625C1.72316 0.0529896 3.22961 0.0599529 4.73838 0.0669164L4.73606 0.0692377Z" fill="#20CD7A" />
            <path d="M4.7424 9.7672C4.7424 12.599 4.7424 15.4332 4.7424 18.265C3.24756 18.2743 1.75504 18.279 0.260198 18.2929C0.0675398 18.2929 0.0234375 18.2186 0.0234375 18.0422C0.0280799 15.3636 0.0280799 12.6872 0.0234375 10.0086C0.0234375 9.79273 0.104679 9.75559 0.295016 9.75791C1.77825 9.7672 3.26148 9.76487 4.74472 9.7672H4.7424Z" fill="#1FBAF2" />
            <path d="M4.73014 19.453C4.73014 22.2941 4.73014 25.1352 4.73014 27.974C3.2353 27.981 1.73814 27.9833 0.243295 27.9995C-0.0700646 28.0042 0.0088555 27.7953 0.0088555 27.6351C0.00653432 25.3186 0.0088555 22.9997 0.0088555 20.6832C0.0088555 20.3489 0.0158192 20.0124 0.00653449 19.6781C0.00189212 19.4994 0.0552792 19.4274 0.245616 19.4297C1.74046 19.4437 3.23762 19.446 4.73246 19.453H4.73014Z" fill="#032454" />
        </svg>

    )
}