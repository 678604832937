import { CaretRightOutlined, CloseOutlined, CloudUploadOutlined, EyeFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, InputNumber, message, Modal, Row, Table } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import AppReferences from '../../Globals/AppReferences';
import { isArrayNotEmpty } from '../../Globals/Helper';
import DownloadIcon from '../../Images/DownloadIcon';
import { getSKUSubordersAction, scanPickedAction } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import SpinLoader from '../SpinLoader';
import SkeletonLoader from './SkeletonLoader';

let expand = -1;
function ScanningPending(props: any) {
    const [pickedQtyValue, setPickedQtyValue] = useState<any>({ pickedQty: 0 });
    const [openDrawer, setOpenDrawer] = useState<any>(false);
    const [
        statusDetails,
        channel,
        tatClient,
        clientValue,
        process,
        getStatusDetails,
        getChannelStatus,
        setProcess,
        getChannelsApi,
    ]: any = useOutletContext();
    const dispatch = useDispatch();
    const reducerstate = useSelector((state: any) => state?.ProcessReducerState);
    const { skuSubOrders, scanningResponse, statusDetailsOnload } = reducerstate;
    const [modalDetails, setModalDetails] = useState({
        valueExceed: false,
        exceeded: false,
        index: "",
    });
    const [form]: any = Form.useForm();
    const [onload, setOnload] = useState({
        scanOnload: false
    });
    const [expandRow, setExpandRow] = useState<any>(expand);
    const [selectedSku, setSelecteSku] = useState<any>('');

    useEffect(() => {
        if (onload.scanOnload && !reducerstate.scanOnload) {
            setOnload({ ...onload, scanOnload: false });
            setModalDetails({
                valueExceed: false,
                exceeded: false,
                index: "",
            });
            form.resetFields();
            if (scanningResponse?.msg == "Scanning Completed Successfully for the given SKU") {
                message.success(`Scanning Completed Successfully for the ${selectedSku}`);
                getChannelsApi();
                setSelecteSku('');
            }
            else {
                message.error(scanningResponse?.msg);
            }
        }
    }, [onload.scanOnload, reducerstate.scanOnload]);

    const getSubOrders = (data: any) => {
        setOpenDrawer(true)
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a07,
            transaction: process,
            where: tatClient,
            fieldArray: channel,
            warehouse: getWareHouseId(),
            customer: clientValue,
            tableNo: data?.SoLineRecNo
        }
        dispatch(getSKUSubordersAction(payload))
    }

    const scanSku = (data: any, key: any, e: any, sku: any) => {
        setSelecteSku(key == 'scan' ? e.target.value : sku);
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a10,
            transaction: key == 'scan' ? e.target.value : data?.RecordNo,
            where: data?.BatchID,
            fieldArray: key == 'scan' ? 'Y' : 'N',
            user: getUserInfo()?.UserName,
            warehouse: key == 'scan' ? 1 : e.target.value,
            customer: clientValue
        }
        dispatch(scanPickedAction(payload));
        setOnload({ ...onload, scanOnload: true })
    }

    const header = () => {
        return (
            <SubHeader
                title={"Scanning Progress"}
                paginationView="false"
                inputView="true"
                btnView="false"
                fromOrgin={'orderProcessing'}
            >
                {/* <div className='d-flex flex-end'>
                    <div style={{ fontFamily: 'Redhat-Regular' }}>
                        <Input
                            prefix={<SearchOutlined />}
                            placeholder='Search By Batch ID '
                        />
                    </div>
                </div> */}
            </SubHeader>
        )
    }

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={6}>
                    {AppConstants.batchId}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {AppConstants.totalSubOrderId}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {AppConstants.orderqty}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {AppConstants.totalPickedQty}
                </Col>
                <Col span={6} className=' d-flex jc-c'>
                    {AppConstants.action}
                </Col>
            </Row>
        )
    }

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (data?.BatchID == expandRow) {
            setExpandRow(-1);
            expand = -1;
        }
        else {
            setExpandRow(data?.BatchID);
            expand = data?.BatchID;
        }
    }

    const skuDetailsView = (skuList: any, batch: any) => {
        return (
            <div style={{ background: 'white', padding: 10, borderRadius: 6 }}>
                <div className='header-part-2'>
                    {titleContainer()}
                </div>
                <div className='expand-collape-container-2'>
                    {(skuList || []).map((data: any, index: any) =>
                        innerTableView(data, index, batch)
                    )}
                </div>
            </div>
        )
    }

    const drawerContent = (list: any) => {
        return (list || []).map((item: any) => {
            return (
                < div style={{ background: "#FAFAFA", marginTop: 10, borderRadius: 6, padding: 15 }}>
                    <Row>
                        <Col span={17}>
                            <div className='d-flex align-c'>
                                <div className='text-1' >
                                    Sub Order ID
                                </div>
                                <div className='text-regular ml-5'>
                                    {item?.ID}
                                </div>
                            </div>
                            <div className='d-flex align-c mt-10'>
                                <div className='text-1' >
                                    SKU
                                </div>
                                <div className='text-regular ml-5'>
                                    {item?.SKU}
                                </div>
                            </div>
                            <div className='d-flex align-c mt-10'>
                                <div className='text-1' >
                                    Created Date
                                </div>
                                <div className='text-regular ml-5'>
                                    {item?.CreatedDate}
                                </div>
                            </div>
                            <div className='d-flex align-c mt-10'>
                                <div className='text-1' >
                                    Received Date
                                </div>
                                <div className='text-regular ml-5'>
                                    {item?.ReceivedDate}
                                </div>
                            </div>
                        </Col>
                        <Col span={7}>
                            <div className='d-flex align-c jc-sb'>
                                <div style={{ borderRadius: 6, padding: 6, background: "white" }}>
                                    Qty {item?.OrderedQuantity}
                                </div>
                                <div style={{ borderRadius: 10, padding: "0px 6px", background: "#F5D3D3", color: "#9D3636" }}>
                                    {item?.TAT}
                                </div>
                            </div>
                            {item?.isOrderFulfilled != 'False' && <div className='d-flex align-c jc-c' style={{ marginTop: 30 }}>
                                <img src={AppImages.tickIcon} />
                            </div>}
                        </Col>
                    </Row>
                </div>
            )
        })
    }

    const drawerView = () => {
        try {
            let [suborders,]: any = skuSubOrders?.lineitems;
            return (
                <Drawer
                    className="drawer-view-verified"
                    placement={"right"}
                    width={450}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "White" }}>
                            <div className='d-flex'>
                                <div>
                                    View:
                                </div>
                                <div>
                                    Batch ID - {suborders && suborders?.BatchID}
                                </div>
                            </div>
                            {/* <Button onClick={() => setOpenDrawer(false)}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button> */}
                            <img onClick={() => setOpenDrawer(false)} className="c-p" src={AppImages.closeimg} alt='' />
                        </div>
                    }
                    onClose={() => setOpenDrawer(false)}
                    open={openDrawer == true}>
                    <div style={{ fontFamily: 'Redhat-Bold', marginTop: 10, fontSize: 16 }} >
                        Sub Orders
                    </div>
                    {drawerContent(suborders?.Lines)}
                </Drawer >
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }
    }

    const tableBodyView = (item: any, index: any) => {
        let open = item?.BatchID == expandRow;
        return (
            <div className={open ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={6} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='text'>
                            {item?.BatchID}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.CountOfOrders}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.TotalQty}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.TotalPickedQty}
                        </div>
                    </Col>
                    <Col span={6} className="d-flex align-c jc-c">
                        <Form.Item name={`scanSku${item?.BatchID}`}>
                            <Input placeholder='Scan SKU'
                                autoFocus
                                onPaste={(e: any) => e.target.value && scanSku(item, 'scan', e, '')}
                                onChange={(e: any) => e.target.value && scanSku(item, 'scan', e, '')}
                                // onBlur={(e: any) => e.target.value && scanSku(item, 'scan', e, '')}
                            // onPressEnter={(e: any) => e.target.value && scanSku(item, 'scan', e, '')}
                            />
                        </Form.Item>
                        <div style={{ marginLeft: 15 }} className="RTS-btn-container d-flex align-c ">
                            <div>
                                <CloudUploadOutlined onClick={() => openUpload(index)} />
                            </div>
                        </div>
                    </Col>
                    {(pickedQtyValue[`index${index}`] == index) &&
                        <div className='dragger-container' style={{ marginTop: 20 }}>
                            {uploadContainerView(index)}
                        </div>
                    }
                    {open && <Col span={24} className="sku-details">
                        {skuDetailsView(item?.Lines, item?.BatchID)}
                    </Col>}
                </Row>
            </div>
        )
    }

    const openUpload = (index: any) => {
        setPickedQtyValue({ ...pickedQtyValue, [`index${index}`]: index });

    }

    const uploadContainerView = (index: any) => {
        try {
            return (
                <Dragger {...props} accept='jpeg, png'>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <DownloadIcon />
                        <div className='dragger-content ' style={{ marginLeft: 5, textAlign: "left" }}>
                            <div className='drag-txt '>Drag & Drop files or <span className='browse-txt'>Browse</span></div>
                            <div className='note-content '>Supported Formates: JPEG,PNG</div>
                        </div>
                    </div>
                    <Button className="close-btn" onClick={(e) => closeDragger(e, index)}>
                        <CloseOutlined />
                    </Button>
                </Dragger>
            )
        } catch (ex) {
            console.log("Error in uploadContainerView::" + ex)
        }
    }

    const closeDragger = (e: any, index: any) => {
        e.stopPropagation();
        setPickedQtyValue({ ...pickedQtyValue, [`index${index}`]: null });
    }

    const titleContainer = () => {
        try {
            return (
                <Row>
                    <Col span={4}>
                        <div className='text'>{AppConstants.sku}</div>
                    </Col>
                    <Col span={3}>
                        <div className='text d-flex jc-c'>{AppConstants.orderedqty}</div>
                    </Col>
                    <Col span={3}>
                        <div className='text d-flex jc-c'>{AppConstants.toPickQty}</div>
                    </Col>
                    <Col span={3} className=' d-flex align-c jc-c'>
                        <div className='text d-flex'>{AppConstants.pickedQty}</div>
                    </Col>
                    <Col span={3} className=' d-flex align-c jc-c'>
                        <div className='text'>{AppConstants.pendingQty}</div>
                    </Col>
                    <Col span={5}>
                        <div className='text d-flex'>{AppConstants.scannedQty}</div>
                    </Col>
                    <Col span={3} className=' d-flex jc-c'>
                        <div className='text'>{AppConstants.action}</div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }

    const checkValueExceed = (data: any, e: any, index: any, sku: any) => {
        setModalDetails({ ...modalDetails, index: index });
        form.validateFields([`toScan${index}`], (err: any, values: any) => {
            console.log("err", err)
        }).then(() => {
            scanSku(data, '', e, sku);
        }).catch(() => {
            form.setFields([{
                name: `toScan${index}`,
                error: "The scanned Qty must match with Picked Qty"
            }])
        })
        // let pending = data?.PickedQty - data?.PiecesScanned;
        // if(e.target.value > pending){
        //     setModalDetails({ ...modalDetails, valueExceed: true , exceeded: true, index: index});
        // }
        // else{
        //     scanSku(data, '', e);
        // }
    }

    const innerTableView = (data: any, index: any, batch: any) => {
        try {
            data.BatchID = batch;
            let pending = data?.PickedQty - data?.PiecesScanned;
            let [setSku,] = data?.SKU.split(',')
            return (
                <div className={pickedQtyValue[`index${index}`] == index ? "content-container-green-2" : "content-container-2"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={4} className=' d-flex align-c'>
                            <div className='text'> {data?.SKU}</div>
                        </Col>
                        <Col span={3} className=' d-flex align-c jc-c'>
                            <div className='text-1'> {data?.OrderedQuantity}</div>
                        </Col>
                        <Col span={3} className=' d-flex align-c jc-c'>
                            <div className='text-1'>{data?.ToPickQty}</div>
                        </Col>
                        <Col span={3} className=' d-flex align-c jc-c'>
                            <div className='text-1'>{data?.PickedQty}</div>
                        </Col>
                        <Col span={3} className=' d-flex align-c jc-c'>
                            <div className='text-1'>{pending}</div>
                        </Col>
                        <Col span={5} className=' d-flex align-c'>
                            <Form.Item
                                name={`toScan${index}`}
                                rules={[
                                    { pattern: /^[0-9]*$/, message: "Enter Valid Quantity" },
                                    {
                                        validator(rule, value, callback) {
                                            if ((data?.PickedQty == pending) && value != pending) {
                                                return Promise.reject('The scanned Qty must match with Picked Qty')
                                            }
                                            else if ((data?.PickedQty != pending) && value != pending) {
                                                return Promise.reject('The scanned Qty must match with Pending Qty')
                                            }
                                            else {
                                                return Promise.resolve();
                                            }
                                        },
                                    }]}
                            >
                                <Input
                                    style={{ borderRadius: 6 }}
                                    placeholder='Enter'
                                    onBlur={(e: any) => e.target.value && checkValueExceed(data, e, index, setSku)}
                                    onPressEnter={(e: any) => e.target.value && checkValueExceed(data, e, index, setSku)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={3} className='d-flex align-c jc-c'>
                            <Button
                                onClick={() => getSubOrders(data)}
                                className="eye-btn" >
                                <EyeFilled />
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        } catch (error) {
            console.log("Error in innerTableView::", error)
        }
    }

    const closeModal = () => {
        setModalDetails({ ...modalDetails, valueExceed: false });
        form.resetFields();
    }

    // const valueExceedsPopup = ()=>{
    //     return(
    //         <Modal
    //             open={modalDetails.valueExceed}
    //             // onOk={closeModal}
    //             // onCancel={() => setShowMdal({ ...showModal, qtyMatch: false })}
    //             footer={null}
    //             className="start-receivingscreen-modal"
    //         >
    //             <div className='margin redhat-bold font-18 height'>
    //                 The Scanned qty is excess than the pending qty
    //             </div>
    //             <div className='d-flex flex-end' style={{ marginTop: 10 }}>
    //                 <AppButtons
    //                     buttonType={AppConstants.buttonTypes.solidLinearGreen}
    //                     onClick={closeModal}
    //                     text="Okay"
    //                 />
    //             </div>
    //         </Modal>
    //     )
    // }

    const contentView = () => {
        return (
            <>
                {isArrayNotEmpty(statusDetails?.lineitems) ?
                    <Form form={form}>
                        <div className='table-screen-container'>
                            {tableHeaderView()}
                            <div>
                                {statusDetails?.lineitems?.map((x: any, index: any) => tableBodyView(x, index))}
                            </div>
                        </div>
                    </Form>
                    :
                    <div className='p-20'>
                        <NoData
                            description={
                                <>{AppConstants.nodata} <span className='redhat-bold ml-5'>Scanning Progress</span></>
                            }
                        />
                    </div>
                }
            </>
        )
    }

    return (
        <div>
            {!statusDetailsOnload ? 
            <>
                {header()}
                {contentView()}
                {drawerView()}
                <SpinLoader
                    loading={onload?.scanOnload}
                />
            </>
                :
                <SkeletonLoader />}
            {/* {valueExceedsPopup()} */}
        </div>
    );
}

export default ScanningPending;