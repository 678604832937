import { Row, Col, Drawer,Select } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HamburgerIcon from "../../Images/hamburgerIcon";
import './NavbarLayout.scss';
import {getUserInfo, getWarehouseInfo, setProcess } from "../../Utils/SessionStorage";
import AppButtons from "../../CustomComponent/AppButtons";
import AppConstants from "../../Globals/AppConstants";
import SideSubmenu from "../SideSubMenu/SideSubmenu";

interface DataType {
    titleContent?: string,
    process?: any,
    warehouse?: string,
    clientData?: any,
    tat?: any,
    clientValue?: any,
    tatClient?: any,
    setClientValue?: any,
    setTatClient?: any,
    callBack?: () => void,
    fromOrigin?: string,
    goLive?: any,
    initial?: any
    selectedGateIn?: string,
    selectedMain?: any,
    selectedOms?: any,
}

function NavbarLayout(props: DataType) {
    const {
        titleContent,
        process,
        warehouse,
        callBack,
        clientData,
        tat,
        clientValue,
        tatClient,
        setClientValue,
        setTatClient,
        fromOrigin,
        goLive,
        initial,
        selectedGateIn,
        selectedMain,
        selectedOms
    } = props;
    const location = useLocation();
    let screenPathName = location?.pathname.split("/")
    const [open, setOpen] = useState(false);
    const [viewProfile, setViewProfile] = useState(false)
    let user = getUserInfo();
    let path = screenPathName[1];
    const [value, setValue] = useState<any>();
    const navigate = useNavigate();

    const onClose = () => {
        setOpen(false);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (process) {
            setProcess(process);
        }
    }, [process]);

    const WarehouseProcessView = () => {
        return (
            <Col span={fromOrigin == 'onboarding' ? 22 : 24} style={{ display: "flex", alignItems: "center" }}>
                {fromOrigin != 'onboard' ?
                    <>
                        {path !== "admin-expense" ?
                            path !== "warehouse-master" ?
                                path !== "configure-warehouse" ?
                                    <div className="warehouse-process-container">
                                        <div className="content">
                                            <div className="selection-Process-title">{"Process" + " : "}</div>
                                            <div className="selection-Process-value">{path === "grn-pending" ? "Pending GRN" : process?.title}</div>
                                        </div>
                                        <div className="content"
                                            style={{
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                background: 'rgba(255, 255, 255, 0.15)',
                                                borderRadius: 5
                                            }}>
                                            <div className="selection-title">{(fromOrigin == 'onboarding' ? 'Client' : "Warehouse") + " : "}</div>
                                            <div className="selection-value">{fromOrigin == 'onboarding' ? process?.customer : process?.warehouse}</div>
                                        </div>
                                    </div>
                                    :
                                    <div className="warehouse-process-container">
                                        <div className="content">
                                            <div className="selection-Process-value">{process?.title}</div>
                                        </div>
                                    </div>
                                :
                                <div className="warehouse-process-container">
                                    <div className="content">
                                        <div className="selection-Process-value">{process?.title}</div>
                                    </div>
                                </div>
                            :
                            <Row>
                                <Col span={24}>
                                    <div className="warehouse-process-container">
                                        <div className="content">
                                            <div className="selection-Process-value">General Admin expenses</div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        }
                    </>
                    :
                    <div className="warehouse-process-container">
                        <div className="content">
                            <div className="selection-Process-value">{titleContent}</div>
                        </div>
                    </div>
                }
            </Col>
        )
    }

    const onboarding = () => {
        return (
            <Col span={2} className='d-flex flex-end'>
                {fromOrigin == 'onboarding' &&
                    <AppButtons
                        onClick={goLive}
                        text='Go Live'
                        buttonType={AppConstants.buttonTypes.whiteButton}
                    />}
            </Col>
        )
    }

    const contentView = () => {
        try {

            return (
                <Row className="main-header" style={{ display: "flex", alignItems: "center" }}>
                    <Col span={1}>
                        <div className="d-flex align-c">
                            <div className="menu-icon" onClick={() => showDrawer()}>
                                <HamburgerIcon />
                            </div>
                        </div>
                    </Col>
                    <Col span={23}>
                        <Row gutter={16} style={{ display: "flex", alignItems: "center",justifyContent:"flex-end" }}>
                            {WarehouseProcessView()}
                            {/* {fromOrigin == 'onboarding' && onboarding()} */}
                        </Row>
                    </Col>

                </Row>)
        } catch (error) {
            console.log("Error in NavBar::", error)
        }
    }

    return (
        <div className="">
            <Drawer
                className="drawer-container"
                placement={"left"}
                onClose={onClose}
                open={open}>
                <SideSubmenu
                    setValue={setValue}
                    intialMenu={initial}
                    selectedGateIn={selectedGateIn}
                    selectedMain={selectedMain}
                    selectedOms={selectedOms}
                    warehouse={getWarehouseInfo()?.name}
                />
            </Drawer>
            {contentView()}
        </div>
    )
}

export default NavbarLayout;