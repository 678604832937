import { CloseOutlined, DownOutlined, FileSearchOutlined, QuestionCircleFilled, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Input, Progress, Radio, Row, Select } from 'antd';
import React, { useState } from 'react';
import AppConstants from '../../../Globals/AppConstants';
import '../QC.scss';
import '../../../Images/sampleimage.png';
import { AppImages } from '../../../Globals/AppImages';
import NavbarLayout from '../../NavbarLayout/NavbarLayout';
import SubHeader from '../../Header/Header';
import EmizaIcon from '../../../Images/emizaIcon';
import BarCodeImg from '../../../Images/BarCodeImg';
import { Link } from 'react-router-dom';
import Upload from '../../../Images/Upload';
import File from '../../../Images/File';
import PlayIcon from '../../../Images/PlayIcon';

function CustomDetailedQC() {

    const [value, setValue] = useState();
    const [qcCondition,setQcCondition] = useState(false)
    const onChange = (e: any) => {
        setValue(e.target.value);
    }

    const detailsView = () => {
        try {
            return (
                <div className='details-view' style={{ marginBottom: 10 }}>
                    <Row gutter={25} style={{marginLeft:0,marginRight:0}}>

                        <Col span={7}>
                            <div className='box-num'>
                                {AppConstants.box} 01
                            </div>

                        </Col>
                        <Col span={5}>
                            <div>{AppConstants.sampling}</div>
                        </Col>
                        <Col span={4}>
                            <div className='box-row' style={{ paddingLeft: 15 }}>{AppConstants.good}</div>
                        </Col>
                        <Col span={4}>
                            <div className='box-row' style={{ paddingLeft: 5 }}>{AppConstants.damaged}</div>

                        </Col>
                        <Col span={3}>
                            <div className='repairable'>{AppConstants.repairable}</div>
                        </Col>
                    </Row>
                </div>

            )
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const detailsInput = () => {
        try {
            return (
                <div className='details-inputView'>
                    <Row gutter={25} style={{marginLeft:0,marginRight:0}}>

                        <Col span={7}>
                            <div className='box-input'>
                                <div className='s-no'>1</div>
                                <div className='s-data'>SKU001 - Lorem Ipsum 1</div>
                            </div>

                        </Col>
                        <Col span={5}>
                            <div>10% (10 of 100)</div>
                        </Col>
                        <Col span={4}>
                            <div className='box-row'><Input defaultValue={6} /></div>
                        </Col>
                        <Col span={4}>
                            <div className='box-row'><Input defaultValue={2} /></div>
                        </Col>
                        <Col span={3}>
                            <div className='box-row1'><Input defaultValue={1} /></div>
                        </Col>
                    </Row>
                </div>

            )
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const commentsInput = () => {
        try {
            return (
                <div className='canned-cmtd'>
                    <div className='c-headers'>
                        <div>{AppConstants.cannedReason}</div>
                        <div className='cmts'>{AppConstants.Comments}</div>
                    </div>

                    <div className='c-inputs'>
                        <Row gutter={30}>
                            <Col span={4}><Select defaultValue={"Others"} suffixIcon={<DownOutlined />} style={{ width: 170 }} /></Col>
                            <Col span={20}><Input placeholder='Enter' /></Col>
                        </Row>
                    </div>
                </div>
            )
        }
        catch (ex) {
            console.log("");
        }
    }

    const imgVideoContent = () => {
        try {
            return (
                <div className='img-videoContainer'>
                    <div>
                        <div className='notes'>
                            <ul style={{marginLeft:-20}}>
                                <li>
                                    <div className='notes-content'> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                                </li>
                                <li>
                                    <div className='notes-content'> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                                </li>
                                <li>
                                    <div className='notes-content'> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                                </li>

                            </ul>

                        </div>
                        <div className='img-header'>{AppConstants.image}</div>

                        <div className='card-container'>
                            <div className='cards'>
                                {([1, 2, 3, 4, 5]).map(() => {
                                    return (
                                        <div className='screenshot-img-container'>
                                            <img src={AppImages.sampleImage} alt="sample=Image" />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className='vdo-header'>{AppConstants.videos}</div>
                        <div className='vdo-container'>
                            <div className='vdo'>
                                <img src={AppImages.videoImage} alt="sample=vdo" />
                                <Button className='vdo-btn'>
                                    <PlayIcon />
                                </Button>
                            </div>

                        </div>
                    </div>
                    <Button className="close-btn" onClick={()=>setQcCondition(false)}>
                        <CloseOutlined />
                    </Button>
                </div>
            )
        }
        catch (ex) {
            console.log("");
        }
    }

    const VanButtons = () => {
        return (
            <Link to="/process/QC" state={{ Option: 3 }}>
                <div className="van-buttons">
                    <button className="cancel" style={{cursor:"pointer"}}>2/2 Complete</button>
                </div>
            </Link>
        )
    }

    const barList = () => {
        try {
            return (
                <div className='bar-listing-container'>
                    <Input
                        placeholder='Scan Box-Sticker barcode' />
                    <div className='radio-container'>
                        <Radio.Group
                            buttonStyle="solid"
                            onChange={onChange}
                            value={value} >
                            {([1, 2, 4] || []).map((item: any) => {
                                return (
                                    <Radio.Button value={item}>
                                        <div className='emiza-stickering-count'>
                                            <div className='emiza-logo'>
                                                <img src={AppImages.appLogo} alt="app-logo" />
                                            </div>
                                            <div className='count'>1/10</div>
                                        </div>
                                        <div className='bar-code-img'><BarCodeImg /></div>
                                    </Radio.Button>
                                )
                            })}
                        </Radio.Group>
                    </div>
                </div>
            )
        } catch (ex) {
            console.log("Error in barList::" + ex)
        }
    }

    const boxBar = () => {
        return (

            <div className='box-bar'>
                <Row>
                    <Col span={4}>
                        {barList()}
                    </Col>
                    <Col span={20} style={{paddingLeft:15,}} className="border-design">
                        <div style={{ padding: 10 }} >
                            {detailsView()}
                            {detailsInput()}
                            {commentsInput()}
                            <div className='detail-QC-cndtns'>{AppConstants.detailQcCondition}</div>
                            <div className='line'><hr /></div>
                            {detailsInput()}
                            <div className='detail-QC-cndtns' onClick={()=>setQcCondition(true)} style={{cursor:"pointer"}}>{AppConstants.detailQcCondition}</div>
                            {qcCondition  && imgVideoContent() }
                           { qcCondition  && <div className='line'><hr /></div>}
                            {VanButtons()}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <Button className="green-btn">
                    <File />
                </Button>
                <Button className="green-btn">
                    <QuestionCircleFilled />
                </Button> */}
                <Link to="/process/QC" state={{ Option: 3 }}>
                    <Button className="close-btn">
                        <CloseOutlined />
                    </Button>
                </Link>
            </div>
        )
    }

    return (
        <>
            <NavbarLayout
                titleContent="sub-head"
                process="Custom/Detailed QC"
            />
            <SubHeader
                title={(
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div>Custom/Detailed QC<span>- WH01-CUST01-20220706-140309 -  IN8732872232</span> </div>
                        <div style={{ display: "flex", alignItems: "center", marginLeft: 10 }}>
                            <div className='text' style={{ marginRight: 10 }}>{"QC on Consignment"}</div>
                            <div className="table-process"><Progress percent={30} size="small" status="active" /></div>
                        </div>
                    </div>
                )
                }
                inputView="true"
                paginationView="false"
                btnView="false">
                {childrenView()}
            </SubHeader>
            <div className='main-container mt-45'>
                {boxBar()}
            </div>
        </>
    )
}

export default CustomDetailedQC