import { Button, Col, DatePicker, Form, Input, message, Radio, Row, Select } from 'antd';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import './GateIn.scss'
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import { Link, useNavigate } from 'react-router-dom';
import { CloseOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { gateInEntryAddAction, getgateInEntryMetaAction, getTransactionAction } from '../../Store/Actions/ProcessWMSAction';
import { useEffect, useState } from 'react';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import AppReferences from '../../Globals/AppReferences';
import { AppModuleIds } from '../../Globals/AppReferenceIds';

function GateInAddScreen() {
    const [form] = Form.useForm();
    const location = useLocation()
    const { Option, header, data } = location.state;
    const dispatch = useDispatch();
    const { gateInEntryMetaData, gateInEntryAddOnLoad, getTransactionInfo, getTransactionOnLoad } = useSelector((state: any) => state.ProcessReducerState);
    const headerData = gateInEntryMetaData?.title;
    const getUserData = getUserInfo();
    const [saveOnLoad, setSaveOnLoad] = useState(false);
    const navigate = useNavigate();
    const [transactionNumber, setTransactionNumber] = useState<any>();
    const [customer, setCustomer] = useState<any>();
    const [warehouses, setWarehouses] = useState<any>();
    const [InwardType, setInwardType] = useState<any>();
    const [vehicleNumber, setVehicleNumber] = useState<any>();
    const [vehicleTypes, setVehicleTypes] = useState<any>();
    const [finalResult, setFinalResult] = useState<any>();
    const [dockNumber, setDockNumber] = useState<any>();
    const [sealNumber, setSealNumber] = useState<any>("");
    const [dockStations, setDockStations] = useState<any>();
    const [customerCodeOnload, setCustomerCodeOnload] = useState<any>(false);
    const currentDate = new Date();
    const whareHouseId = getWareHouseId();
    const date = `${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}-${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}-${currentDate.getMilliseconds()}`;
    

    useEffect(() => {
        getMetaApi();
    }, []);

    useEffect(() => {
        if (data != undefined) {
            form.setFieldsValue({
                ['clientName']: data?.Customer,
                ['inwardType']: data?.InwardTxnType,
                ['vehicleType']: data?.Type,
                ['vehicleNumber']: data?.VehicleNumber,
                ['sealNumber']: data?.SealNo,
                ['docketNumber']: data?.DocketNumber,
                ['docketStation']: data?.DockStationName,
            });
        }
    }, []);

    const getMetaApi = () => {
        let payload = {
            "activity": AppReferences.activities.av,
            "opnfor": "110100",
            "warehouse": whareHouseId,
            "user": getUserData?.UserName,
            where: getUserInfo()?.CorporateID
        };
        dispatch(getgateInEntryMetaAction(payload));
    }

    useEffect(() => {
        if (saveOnLoad == true && gateInEntryAddOnLoad == false) {
            let clientName = (gateInEntryMetaData?.ClientList).find((x: any) => x?.Id == customer)
            message.success(`GateIn entries for ${clientName?.Name} is added successfully`);
            navigate("/gateinentries");
        }
    }, [saveOnLoad, gateInEntryAddOnLoad]);

    useEffect(() => {
        if (customer != undefined) {
            getTransactionApi(customer);
        }
    }, [customer]);

    const getTransactionApi = (item: any) => {
        let payload = {
            "activity": AppReferences.activities.a01,
            "opnfor": 110100,
            "transaction": item,
            "user": getUserData?.UserName,
            // customer: getUserData?.CorporateID,
            // ware

        }
        dispatch(getTransactionAction(payload))
        setCustomerCodeOnload(true);
    }

    useEffect(()=>{
        form.setFieldsValue({
            ['clientName']: '',
        })
    },[InwardType])

    useEffect(() => {
        if (customerCodeOnload === true && getTransactionOnLoad === false) {
            // setTransactionNumber(trasactionId)
        }
    }, [customerCodeOnload, getTransactionOnLoad])

    const saveGateInEntriesApi = () => {
        const trasactionId = `${whareHouseId}-${customer}-${date}`;
        console.log("trasactionId",trasactionId)
        let payload = {
            "activity": AppReferences.activities.ax01,
            "customer": `${customer}`,
            "opnfor": 110100,
            "tableNo": 2,
            "transaction": trasactionId,
            "user": getUserData?.UserName,
            "warehouse": whareHouseId,
            "fieldarray": getUserData?.CorporateID,
            "where": [`'${trasactionId}'`,`'${getUserData?.CorporateID}'`, `'${customer}'`, `'${whareHouseId}'`, InwardType, `'${vehicleNumber}'`, `'${vehicleTypes}'`, `${dockStations}`, `'${dockNumber}'`, `'${sealNumber}'`, `'${finalResult}'`, `'${getUserData?.UserName}'`]
        }
        dispatch(gateInEntryAddAction(payload));
        setSaveOnLoad(true);
    }

    const formView = () => {
        try {
            let clientRequired = (InwardType == 3 || InwardType == 4 )
            return (
                <>
                    <Row gutter={16} >
                        <Col span={12}>
                            <Form.Item
                                label="Inward Type"
                                name="inwardType"
                                required
                                rules={[{ required: true, message: 'Select Inward Type' }]}
                            >
                                <Select
                                    placeholder="Inward Type"
                                    onChange={(e) => setInwardType(e)}
                                    value={data?.InwardTxnType}
                                >
                                    {gateInEntryMetaData?.InwardTypeList.map((inwarType: any) =>
                                        <Option value={inwarType?.ID}>{inwarType?.Description}</Option>
                                    )}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="clientName"
                                label="Client"
                                required={!clientRequired}
                                rules={[{ required: !clientRequired, message: 'Select Client Name' }]}
                            >
                                <Select
                                    disabled={clientRequired}
                                    placeholder="Select Client"
                                    onChange={(e) => setCustomer(e)}
                                    value={data?.Customer}
                                >
                                    {gateInEntryMetaData?.ClientList.map((client: any) =>
                                        <Option value={client?.Id} >{client?.Name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ marginTop: 20 }}>
                        <Col span={12}>
                            <div className='type-container'>
                                <div className='lable'>Vehicle</div>
                                <div className='content-container'>
                                    <Row gutter={16}>

                                        <Col span={12}>
                                            <Form.Item
                                                label="Vehicle Type"
                                                name="vehicleType"
                                                required
                                                rules={[{ required: true, message: 'Select Vehicle Type' }]}
                                            >
                                                <Select
                                                    placeholder="Select Vehicle Type"
                                                    onChange={(e) => setVehicleTypes(e)}
                                                    value={data?.Type}
                                                >
                                                    {gateInEntryMetaData?.VehicleTypeList.map((vehicle: any) =>
                                                        <Option value={vehicle?.Id}>{vehicle?.Type}</Option>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="vehicleNumber"
                                                label="Vehicle Number"
                                                required
                                                rules={[{ required: true, message: 'Enter Vehicle Number' },
                                                { pattern: /^[a-zA-Z0-9]+$/, message: "Space or No Special Character allowed" },
                                                    // { pattern: /^\S*$/, message: "Space not allowed" }
                                                ]}
                                            >
                                                <Input
                                                    placeholder="Enter Vehicle Number"
                                                    value={data?.VehicleNumber}
                                                    onChange={(e) => setVehicleNumber(e.target.value)}
                                                    maxLength={12}
                                                />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='type-container'>
                                <div className='lable'>Seal</div>
                                <div className='content-container'>
                                    <Row gutter={16}>

                                        <Col span={24}>
                                            <Form.Item
                                                name="sealNumber"
                                                label="Seal Number"
                                                required
                                                rules={[{ required: true, message: 'Enter Seal Number' },
                                                { pattern: /^[0-9]*$/, message: "Seal Number must be Numeric" },
                                                { pattern: /^\S*$/, message: "Space not allowed" }
                                                ]}
                                            >
                                                <Input
                                                    maxLength={6}
                                                    pattern=" "
                                                    placeholder="Enter Seal Number"
                                                    value={data?.SealNo}
                                                    onChange={(e) => setSealNumber(e.target.value)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ marginTop: 20 }}>
                        <Col span={12}>
                            <div className='type-container'>
                                <div className='lable'>Dock</div>
                                <div className='content-container'>
                                    <Row gutter={16}>

                                        <Col span={12}>
                                            <Form.Item
                                                name="docketNumber"
                                                label="Docket Number"
                                                required
                                                rules={[{ required: true, message: 'Enter Docket Number' },
                                                { pattern: /^\S*$/, message: "Space not allowed" }
                                                    // {pattern: /^.[0-9]*[1-9][0-9]*$/, message: "Docket Number must be Numeric"}
                                                ]}
                                            >
                                                <Input
                                                    maxLength={12}
                                                    placeholder="Enter Docket Number"
                                                    value={data?.DocketNumber}
                                                    onChange={(e) => setDockNumber(e.target.value)}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Dock Station"
                                                name="docketStation"
                                                required
                                                rules={[{ required: true, message: 'Select Docket Station' }]}
                                            >
                                                <Select
                                                    placeholder="Select Dock Station"
                                                    onChange={(e) => setDockStations(e)}
                                                    value={data?.DockStationName}
                                                >
                                                    {gateInEntryMetaData?.DockStationList.map((dockStation: any) =>
                                                        <Option value={dockStation?.DockStationID}>{dockStation?.DockStationName}</Option>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col span={12} style={{ display: "flex", alignItems: "center" }}>
                            <Form.Item
                                label="Final Result"
                                name="finalResult"
                                required
                                rules={[{ required: true, message: 'Select Final Result' }]}
                            >
                                <Select
                                    placeholder="Vehicle Allowed - General Vehicle"
                                    onChange={(e) => setFinalResult(e)}
                                >
                                    {gateInEntryMetaData?.ReasonList.map((finalList: any) =>
                                        <Option value={finalList?.ID}>{finalList?.Description}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='btn-container' style={{ marginTop: 10, marginRight: 10 }}>
                        <Link to="/gateinentries">
                            {/* <Button className='close-btn'>Cancel</Button> */}
                            <AppButtons
                                buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                text="Cancel"
                            ></AppButtons>
                        </Link>
                        <AppButtons
                            block={true}
                            htmlType="submit"
                            buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                            text={Option === "Add" ? "Add" : "Save"} />
                    </div>
                </>
            )
        } catch (ex) {
            console.log("Error in formView::" + ex)
        }
    }
    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <Button className="green-btn">
                    <QuestionCircleFilled />
                </Button> */}
                <Link to="/gateinentries">
                    <Button className="close-btn">
                        <CloseOutlined />
                    </Button>
                </Link>
            </div>
        )
    }
    return (
        <div>
            <NavbarLayout
                titleContent="sub-head"
                process={Option === "Add" ? headerData : header}
                initial={AppModuleIds.gateIn}
                selectedGateIn={'2'}
            />
            <SubHeader
                title={<div>{Option === "Add" ? "Add GateIn" : "Edit GateIn - "} <span>{data?.TxnNo}</span></div>}
                paginationView="false"
                inputView="false"
                btnView="true"
            >
                {childrenView()}
            </SubHeader>
            <div className='gate-in-add-container'>
                <Form
                    id='form'
                    form={form}
                    autoComplete="off"
                    noValidate
                    onFinish={saveGateInEntriesApi}
                    layout="vertical"
                >
                    {formView()}
                </Form>
            </div>
        </div>
    );
}

export default GateInAddScreen;