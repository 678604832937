import { CaretRightOutlined } from '@ant-design/icons';
import { Col, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { isArrayNotEmpty } from '../../Globals/Helper';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import "./PackagingMaterial.scss"

let Arr: any = [1, 2, 3];
const { Option } = Select;

function PackagingMaterialScreen(props: any) {

    const [expandRow, setExpandRow] = useState<any>();

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
            //getData(data)
        }
    }

    const packingMaterialHeaderView = () => {
        try {
            return (
                <Row className='table-header invoice-header'>
                    <Col span={12}>
                        {"Packing Materials"}
                    </Col>
                    <Col span={12} className=' d-flex jc-c'>
                        {"Received Qty"}
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in skuInvoiceHeaderView::" + ex)
        }
    }

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={8}>
                    {"GateIn ID"}
                </Col>
                <Col span={8} className=' d-flex jc-c'>
                    {"Client Name"}
                </Col>
                <Col span={8} className=' d-flex jc-c'>
                    {"Action"}
                </Col>
            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        return (
            <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={8} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='title-blue font-18'>
                            {`WH01-CUST-20230118-7265${index}`}
                        </div>
                    </Col>
                    <Col span={8} className=' d-flex align-c jc-c'>
                        <Select
                            className='select-field'
                            placeholder='Select'
                            style={{ width: "250px" }}
                        >
                            {(["Mamaearth", "Swiss Military", "Wow", "Beardo", "Dot & Key"] || []).map((x: any) => {
                                return (
                                    <Option value={x}>{x}</Option>
                                )
                            })}
                        </Select>
                    </Col>
                    <Col span={8} className=' d-flex align-c jc-c'>
                        <Link to="/packing-putaway">
                            <AppButtons
                                text={'Allocation Completed'}
                                buttonType={AppConstants.buttonTypes.solidLinearGreen}
                            />
                        </Link>
                    </Col>
                    {open && <Col span={24} style={{ padding: 10, marginTop: 10, background: "#FFFFFF", borderRadius: 6, }}>
                        {packingMaterialHeaderView()}
                        <div className='scroll'>
                            {(["Bubble Wrap", "Brown Papers", "Packing Tapes", "Customized Packing Tapes"] || []).map((x: any) => {
                                return (
                                    <Row className='content-container  d-flex align-c jc-sb mt-10'>
                                        <Col span={12} className='text-1'>{x}</Col>
                                        <Col span={12} className=' d-flex align-c jc-c'>
                                            <Input
                                                className='input-field'
                                                placeholder='Enter'
                                                style={{ width: "250px" }}
                                            />
                                        </Col>
                                    </Row>
                                )
                            })}
                        </div>
                    </Col>}
                </Row>
            </div >
        )
    }

    const header = () => {
        try {
            return (
                <SubHeader
                    title={<div>Packaging Material</div>}
                    paginationView="false"
                    inputView="true"
                    btnView="true"
                >
                </SubHeader>
            )
        } catch (ex) {
            console.log("Err in header::" + ex)
        }
    }
    const ContentView = () => {
        try {
            return (
                <div className='packing-material-container'>
                    {isArrayNotEmpty(Arr) ?
                        <div style={{ padding: 20 }}>
                            {tableHeaderView()}
                            <div className='table-scroll'>
                                {(Arr || []).map((x: any, index: any) => tableBodyView(x, index))}
                            </div>
                        </div>
                        :
                        <div className='p-20'>
                            <NoData
                                className='mt-45'
                                description={
                                    <>There is no reports available in <span className='redhat-bold ml-5'>Packing Material</span></>
                                }
                            />
                        </div>
                    }
                </div>
            )
        } catch (ex) {
            console.log("Err in ContentView::" + ex)
        }
    }

    return (
        <div>
            <NavbarLayout
                titleContent="sub-head"
            //process={reportList?.title}
            />
            <div style={{ marginTop: 50 }}>
                {header()}
            </div>
            {ContentView()}
        </div>
    );
}

export default PackagingMaterialScreen;