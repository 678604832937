import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Row,Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppButtons from '../../../CustomComponent/AppButtons';
import AppConstants from '../../../Globals/AppConstants';
import { AppImages } from '../../../Globals/AppImages';
import AppReferences from '../../../Globals/AppReferences';
import { isArrayEmpty } from '../../../Globals/Helper';
import { completeStickeringAction, getSickeringDetailsAction } from '../../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../../Utils/SessionStorage';
import SubHeader from '../../Header/Header';
import NavbarLayout from '../../NavbarLayout/NavbarLayout';
import '../SAN.scss';
import { $ } from 'react-jquery-plugin'
import SpinLoader from '../../SpinLoader';
import WMSIcon4 from '../../../Images/WMSIcon4';
import Sticker360 from '../../../Images/Sticker360';


function StickeringViewScreen() {

    const reducerState = useSelector((state) => state.ProcessReducerState);
    const { sendStickeringInfo } = reducerState;
    const loactionProps = useLocation().state;
    const sticker = loactionProps?.sticker;
    const dispatch = useDispatch();
    const [gateIndata] = (sendStickeringInfo?.header || []);
    const [ExecutiveAndLabourInfo] = (sendStickeringInfo?.ExecutiveAndLabourInfo || []);
    const [selectedForPrint, setSelectedForPrint] = useState([]);
    const title = sendStickeringInfo?.title;
    const [completeOnload, setCompleteOnload] = useState(false);
    const navigate = useNavigate();
    const warehouseId = getWareHouseId();
    const [printLoad, setPrintLoad] = useState(false);

    useEffect(() => {
        if (sticker) {// condition is to check 
            getStickerInfo();
        }
    }, []);

    useEffect(() => {
        if (completeOnload && reducerState?.onload) {
            setCompleteOnload(false);
            navigate('/process/SAN');
        }
    }, [completeOnload, reducerState?.onload]);


    const getStickerInfo = () => {
        try {
            let data = sticker?.id?.split("|");
            let [customer, opnfor, transaction] = (data || [])
            let payload = {
                "warehouse": getWareHouseId(),
                "activity": AppReferences.activities.av,
                "user": getUserInfo()?.UserName,
                "transaction": transaction,
                "customer": customer,
                "opnfor": opnfor
            }
            dispatch(getSickeringDetailsAction(payload))
        } catch (error) {
            console.log("Error in getStickerInfo::", error)
        }
    }

    const completStickering = () => {
        try {
            let payload = {
                "activity": AppReferences.activities.ax01,
                "transaction": gateIndata?.iNo,
                "opnfor": title?.stageNo,
                "customer": gateIndata?.cId,
                "user": getUserInfo()?.UserName,
                "warehouse": getWareHouseId()
            }
            dispatch(completeStickeringAction(payload));
            setCompleteOnload(true)
        } catch (error) {
            console.log("Error in completStickering")
        }
    }

    const selectSticker = (sticker) => {
        let alreadyExist = selectedForPrint?.find((x) => x == sticker?.Barcode);
        if (!alreadyExist) {
            setSelectedForPrint([...selectedForPrint, sticker?.Barcode]);
        }
        else {
            setSelectedForPrint(selectedForPrint.filter((x) => x != sticker?.Barcode));
        }
    }

    const stickerCard = (sticker) => {
        let barcodeUrl = "http://bwipjs-api.metafloor.com/?bcid=code128&text=" + sticker?.Barcode;
        let selected = selectedForPrint?.find((x) => x == sticker?.Barcode);
        try {
            return (
                <Col xl={4} lg={4} md={6} sm={6} xs={12} id={sticker?.Barcode}>
                    {/* <p></p> */}
                    <div
                        className={!selected ? 'sticker-box' : 'sticker-box selected'}
                        onClick={() => selectSticker(sticker)}
                    >
                        <div className='stickering-code'>
                            {gateIndata?.GateEntryRecordNo}
                        </div>
                        <div className='invoice-code' >
                            {gateIndata?.InvoiceNo}
                        </div>
                        <div className='emiza-stickering-count'>
                            <div className='emiza-logo d-flex align-c'>
                                <Sticker360 />
                            </div>
                            <div className='count'>
                                {sticker?.SequenceNo} / {sticker?.RecievedBoxQty}
                            </div>
                        </div>
                        <div className='bar-code-img'><img id={sticker?.Barcode + 'img'} src={barcodeUrl} alt={`Barcode ${sticker?.Barcode}`} /></div>
                        {selected && <div className='icon'><CheckCircleFilled className='select-icon' /></div>}
                    </div>
                </Col>
            )
        } catch (error) {
            console.log("Error in stickerCard::", error)
        }

    }

    const StickeringView = () => {
        try {
            return (
                <div className='stickering-view-container'>
                    <div className='sticker-list-container' id='stickerList'>
                        <Row>
                            {sendStickeringInfo?.lineitems?.map((sticker) =>
                                stickerCard(sticker)
                            )}
                        </Row>
                    </div>
                </div>
            )
        } catch (ex) {
            console.log("Error in StickeringView::" + ex)
        }
    }

    const viewAll = () => {
        try {
            return (
                <div className='stickering-view-screen-container'>
                    {sticker && StickeringView()}
                </div>
            )
        } catch (ex) {
            console.log("Error in viewAll::" + ex)
        }
    }

    const printStickers = (key) => {
        try {
            key == 'all' && setSelectedForPrint([])
            setPrintLoad(true);
            let stickers = ((key == 'all' ? sendStickeringInfo?.lineitems : selectedForPrint) || []);
            let frame1 = $('<iframe />');
            frame1[0].name = "frame1";

            $("body").append(frame1);
            var frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow : frame1[0].contentDocument.document ? frame1[0].contentDocument.document : frame1[0].contentDocument;
            frameDoc.document.open();
            //Create a new HTML document.
            frameDoc.document.write('<html><head><style type="text/css">');
            frameDoc.document.write('@page {size: 290px 170px !important;margin: 0 !important;padding: 0rem !important;}');
            frameDoc.document.write('@media print{.sticker-box{ page-break-inside: avoid; page-break-before: always; }}');
            // frameDoc.document.write('.stickering-box {page-break-after: always !important;}');
            frameDoc.document.write('.stickering-code {font-size: 1rem; font-family: "RedHat-Bold";display: flex;justify-content: center;align-items: center;text-align: center;padding-top: 0.8rem;}');
            frameDoc.document.write(' .invoice-code{font-family: "RedHat-Bold";display: flex;justify-content: center;align-items: center;font-size: 1.2rem;margin-top: 1.2rem; }');
            frameDoc.document.write('.emiza-stickering-count {display: flex;justify-content: space-between; align-items: center;padding: 10px;}');
            frameDoc.document.write('.emiza-logo img{  width: 5rem !important; }.count { font-size: 2rem; font-family: "RedHat-Bold"; }');
            frameDoc.document.write('.bar-code-img { display: flex;justify-content: center; align-items: center; height: 1rem; margin-top: 1.5rem; padding: 0 0.55rem 0.3rem 0.55rem}');
            frameDoc.document.write('.bar-code-img img {max-width: 100% !important; , max-height: 100% !important;}}');
            // frameDoc.document.write('svg{diplay: none !important;}');
            frameDoc.document.write('</style></head><body>');
            //Append the external CSS file.
            //Append the DIV contents.
            stickers?.map((sticker) => {
                let element = document.getElementById(key == 'all' ? sticker?.Barcode : sticker);
                let data = element.innerHTML;
                // let barcode = document.getElementById((key == 'all' ? sticker?.Barcode : sticker)+'img');
                // barcode.setAttribute('onError', '');
                let replacedValue = data.replaceAll("&amp;", '&');
                replacedValue = data.replace('<div class="icon"><span role="img" aria-label="check-circle" class="anticon anticon-check-circle select-icon"><svg viewBox="64 64 896 896" focusable="false" data-icon="check-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path></svg></span></div></div>', '');
                frameDoc.document.write(replacedValue);
            })
            frameDoc.document.write('</body></html>');
            frameDoc.document.close();
            let delay = stickers?.length > 6 ? (stickers?.length / 6) : 1; // to give delay for the barcode to load.
            setTimeout(() => {
                setPrintLoad(false);
                window.frames["frame1"].focus();
                window.frames["frame1"].print();
                frame1.remove();
            }, delay * 1000)

            return true;
        } catch (error) {
            console.log("Error in printStickers::", error);
        }
    }

    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                <div className='btn-container'>
                    <div style={{ width: 200, marginRight: 15 }}>
                        {/* <Link to="/process/SAN" state={{ Option: 4 }}> */}
                        <AppButtons
                            onClick={printStickers}
                            disabled={!!(isArrayEmpty(selectedForPrint))}
                            block={true}
                            buttonType={AppConstants.buttonTypes.whiteButton}
                            text={AppConstants.printSelectedStickers}>
                        </AppButtons>
                        {/* </Link> */}
                    </div>
                    <div style={{ width: 200, marginRight: 15 }}>
                        {/* <Link to="/process/SAN" state={{ Option: 4 }}> */}
                        <AppButtons
                            onClick={() => printStickers('all')}
                            block={true}
                            buttonType={AppConstants.buttonTypes.whiteButton}
                            text={AppConstants.printAllStickers}>
                        </AppButtons>
                        {/* </Link> */}
                    </div>
                    <div style={{ marginRight: 15, width: 200 }}>
                        <AppButtons
                            onClick={() => completStickering()}
                            block={true}
                            buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                            text={AppConstants.stickeringCompleted}>
                        </AppButtons>
                    </div>
                </div>
                <Link to="/process/SAN" state={{ Option: 4 }}>
                    <Button className="close-btn">
                        <CloseOutlined />
                    </Button>
                </Link>
            </div>
        )
    }

    return (
        <>
            <SubHeader
                title={<div className='d-flex align-c'>
                    <Link to={"/process/SAN"} state={{ Option: 4 }}>
                        <div className='c-p'>
                            <WMSIcon4 />
                        </div>
                    </Link>
                    <div className='ml-10'>Stickering<span>- {gateIndata?.InvoiceNo}</span></div>
                </div>}
                btnView="true"
                inputView="true"
                paginationView="false">
                {childrenView()}
            </SubHeader>
            <SpinLoader loading={printLoad} />
            {viewAll()}
        </>
    );
}

export default StickeringViewScreen;
