
import { CaretRightOutlined, CloseOutlined, EyeFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Input, Row, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AppButtons from '../../../CustomComponent/AppButtons';
import NoData from '../../../CustomComponent/NoData';
import AppConstants from '../../../Globals/AppConstants';
import { AppImages } from '../../../Globals/AppImages';
import { consignmentAction } from '../../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../../Utils/SessionStorage';
import SubHeader from '../../Header/Header';

const suffix = (
    <SearchOutlined
        style={{
            fontSize: 20,
            color: 'black',
        }}
    />
);

function StickeringListing(props: any) {
    const dispatch = useDispatch();
    const { stageDetails } = props;
    const [expandRow, setExpandRow] = useState<any>(-1);
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState<any>();
    const [stickeringInfo, setStickeringInfo] = useState<any>();
    const wareHouse_id = getWareHouseId();
    const [consignment, setConsignment] = useState<any>();
    const { consignmentData } = useSelector((state: any) => state?.ProcessReducerState)
    const drawerData = consignmentData?.lineitems[0];

    const navigateToStickeringScreen = (data: any) => {
        try {
            let details = stageDetails?.header?.find((x: any) => {
                let data2 = x?.show?.split(" : ");
                if (data2[1] == data?.InvoiceNo) {
                    return x
                }
            })
            navigate("/stickering-details", { state: { sticker: details } })
        } catch (error) {
            console.log("Error in navigateToStickeringScreen::", error);
        }
    }

    const columns: any = [
        {
            title: AppConstants?.invoiceNumber,
            dataIndex: 'InvoiceNo',
            width: "20%",
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'  >
                        {item}
                    </div>
                )
            }
        },
        {
            title: AppConstants.executive,
            dataIndex: 'UserName',
            width:"18%",
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: AppConstants.labours,
            dataIndex: 'LaboursAssigned',
            width:"18%",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: AppConstants.totalBoxes,
            dataIndex: 'TotalUnloadedBoxesIncludingDamaged',
            width:"15%",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: AppConstants.damagedBox,
            dataIndex: 'RecievedDamagedBoxQty',
            width:"15%",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: AppConstants.action,
            dataIndex: "",
            width:"11%",
            align: "center",
            render: (item: any) =>
                <AppButtons
                    onClick={(e: any) => navigateToStickeringScreen(item)}
                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                    text="Start Stickering"
                />
        },
    ];

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
            // getUnloadingData(data);
        }
    }

    const stickeringHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={12} >
                    {AppConstants.gateInId}
                </Col>
                <Col span={12}>
                    {AppConstants.client}
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        if (openDrawer?.isOPen == true) {
            consignmentApi();
        }
    }, [openDrawer]);

    const consignmentApi = () => {
        let payload = {
            opnfor: "110000",
            activity: "A-02",
            transaction: consignment,
            warehouse: wareHouse_id,
            where: getUserInfo()?.CorporateID
        }
        dispatch(consignmentAction(payload));
    }

    const openConsignment = (id: any) => {
        setConsignment(id);
        setOpenDrawer({ ...openDrawer, isOPen: true });


    }

    const drawerView = () => {
        try {
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                {drawerData?.TxnNo}
                            </div>
                            <Button onClick={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>
                        </div>
                    }
                    onClose={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                    open={openDrawer?.isOPen}>
                    <Row>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                {AppConstants.client}
                            </div>
                            <div className='value-field'>
                                {drawerData?.Customer}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Transaction Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.InwardTxnType}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.Type}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.VehicleNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Seal Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.SealNo}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Docket Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.DocketNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Dock Station
                            </div>
                            <div className='value-field'>
                                {drawerData?.DockStationName}
                            </div>
                        </Col>
                    </Row>
                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }

    }

    const stickeringBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        return (
            <Row className={open ? 'table-body-blue' : 'table-body'} >
                <Col span={12} className={"open-icon gateinid"}>
                    <div
                        onClick={(e) => handleExpand(e, index, item)}
                        className='d-flex align-c '
                    >
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                    </div>
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => openConsignment(item?.GateEntryRecordNo)}
                    >
                        {item?.GateEntryRecordNo}
                    </div>
                </Col>
                <Col span={12}>
                    <div className='text'>
                        {item?.name}
                    </div>
                </Col>
                <Col span={24}>
                    {open && invoiceListingView(item?.Lines)}
                </Col>
            </Row>
        )
    }

    const stickeringListView = () => {
        return (
            <div className='table-view'>
                {stickeringHeaderView()}
                <div style={{ marginTop: 10}}>

                    {stageDetails?.lineitems?.length != 0 ?
                        stageDetails?.lineitems?.map((item: any, index: any) => stickeringBodyView(item, index))
                        :
                        <NoData />}
                </div>
            </div>
        )
    }

    const invoiceListingView = (invoiceList: any) => {
        try {
            return (
                <div className='pending-san-view-container '>
                    <Table
                        columns={columns}
                        dataSource={invoiceList}
                        pagination={false}
                    />
                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <Input placeholder="Search By GateIn ID" className="search-input" prefix={suffix} />

                <div className="help-icon" style={{ marginLeft: 10,cursor:"pointer" }}>
                    <img src={AppImages.helpIcon} alt="help-icon" />
                </div> */}
            </div>
        )
    }

    return (
        <>
            <SubHeader
                title={"Inward Stickering"}
                paginationView="true"
                inputView="true"
                btnView="false">
                {childrenView()}
            </SubHeader>
            {stickeringListView()}
            <div>
                {drawerView()}
            </div>
        </>
    )
}


export default StickeringListing;