import { CaretRightOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, message, Radio, Row, Select } from 'antd';
import Input from 'antd/lib/input/Input';
import Layout from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import { AppModuleIds } from '../../Globals/AppReferenceIds';
import { isArrayNotEmpty } from '../../Globals/Helper';
import EditIcon from '../../Images/EditIcon';
import RedDeleteIcon from '../../Images/RedDeleteIcon';
import SettingIcon from '../../Images/SettingIcon';
import { addWhAction, editWhAction, getWHConfigureData } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import SkeletonLoader from '../OrderProcessing/SkeletonLoader';


const data = [
    {
        "PAN": "AAACC1206D",
        "City": "pune",
        "Type": "B2B",
        "GSTIN": "04AAACC1206D3ZM",
        "Region": "Test",
        "PinCode": "411037",
        "WarehouseId": "WH06",
        "AddressLine1": "pune",
        "WarehouseName": "Test-WH"
    },
    {
        "PAN": "AAACC1206D",
        "City": "pune",
        "Type": "B2B",
        "GSTIN": "04AAACC1206D3ZM",
        "Region": "Test",
        "PinCode": "411037",
        "WarehouseId": "WH06",
        "AddressLine1": "pune",
        "WarehouseName": "Test-WH"
    },
    {
        "PAN": "AAACC1206D",
        "City": "pune",
        "Type": "B2B",
        "GSTIN": "04AAACC1206D3ZM",
        "Region": "Test",
        "PinCode": "411037",
        "WarehouseId": "WH06",
        "AddressLine1": "pune",
        "WarehouseName": "Test-WH"
    }
]
function WarehouseMaster(props: any) {
    const dispatch = useDispatch();
    const [expandRow, setExpandRow] = useState<any>();
    const [form]: any = Form.useForm();
    const [wmform]: any = Form.useForm();
    const [openDrawer, setOpenDrawer] = useState<any>();
    const [editValue, setEditValue] = useState<any>();
    const [formValues, setFormValues] = useState<any>({ whID: "", whName: "", address: "", city: "", state: "", region: "", pinCode: "", type: "", gstIn: "", pan: "" });
    const [option, setOption] = useState<any>(1)
    const { whConfigurationData, whAddOnLoad, whEditOnLoad } = useSelector((state: any) => state.ProcessReducerState);
    let data = whConfigurationData?.lineitems[0]
    const [onLoad, setOnLoad] = useState(false)
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (onLoad === true && whAddOnLoad === false) {
            getData()
            clearFieldValue()
            setOnLoad(false)
        }

    }, [onLoad, whAddOnLoad])

    useEffect(() => {
        if (onLoad === true && whEditOnLoad === false) {
            getData()
            clearFieldValue()
            setOnLoad(false)
        }

    }, [onLoad, whEditOnLoad])

    const getData = () => {
        let user = getUserInfo();
        let payload = {
            "opnfor": "100003",
            "activity": "A-01",
            "transaction": user?.CorporateID,
            "user": user?.UserName,
        }
        dispatch(getWHConfigureData(payload))
    }


    useEffect(() => {
        getData();
    }, [])

    const onChangeSKULocation = (e: any) => {
        setOption(e?.target?.value)
        setFormValues({
            ...formValues,
            type: e?.target?.value
        })
    }

    const clearFieldValue = () => {
        setOpenDrawer({ ...openDrawer, isOPen: false });
        form.resetFields();
        form.setFieldsValue({
            ['whName']: "",
            ['address']: "",
            ['city']: "",
            ['state']: "",
            ['region']: "",
            ['pinCode']: ""
        });

    }

    const openWHDrawer = () => {
        setOpenDrawer({ ...openDrawer, isOPen: true, addToExist: 'No' })
        form.resetFields();
        form.setFieldsValue({
            ['whName']: "",
            ['address']: "",
            ['city']: "",
            ['state']: "",
            ['region']: "",
            ['pinCode']: "",
            ['type']: "",
            ['gst']: "",
            ['pan']: ""
        });
    }

    const openEditDrawerFields = (item: any) => {
        setOpenDrawer({ ...openDrawer, isOPen: true, addToExist: 'Yes', wareHouseId: item?.WarehouseId })
        setOption(item?.Type == "B2B" ? 2 : 1)
        form.setFieldsValue({
            ['whID']: item?.WarehouseId,
            ['whName']: item?.WarehouseName,
            ['address']: item?.AddressLine1,
            ['city']: item?.City,
            ['region']: item?.Region,
            ['pinCode']: item?.PinCode,
            ['type']: item?.Type == "B2B" ? 2 : 1,
            ['gstIn']: item?.GSTIN,
            ['pan']: item?.PAN,
            ['state']: item?.State ? item?.State : "-"
        });
        setEditValue(item);
        setFormValues({
            ...formValues,
            whName: item?.WarehouseName,
            type: item?.Type == "B2B" ? 2 : 1,
            city: item?.City,
            address: item?.AddressLine1,
            region: item?.Region,
            pinCode: item?.PinCode,
            whID: item?.WarehouseId,
            gstIn: item?.GSTIN,
            pan: item?.PAN,
            state: item?.State ? item?.State : "-"

        });
    }

    const AddWh = () => {
        let findData = data?.Lines.find((item: any) => item?.WarehouseId === openDrawer?.wareHouseId);
        console.log("findData", findData);
        let user = getUserInfo();
        if (findData) {
            message.error(" Warehouse id already exists")
        } else {
            setOnLoad(true)
            let payload = {
                "opnfor": 4,
                "tableno": 38,
                "where": `CorporateID,WarehouseId,WarehouseName,AddressLine1,City,State,PinCode,Region,GSTIN,PAN,WarehouseTypeID,CreatedBy`,
                "fieldarray": `'${user?.CorporateID}', '${formValues?.whID}' , '${formValues?.whName}','${formValues?.address}' ,'${formValues?.city}', '${formValues?.state}','${formValues?.pinCode}' , '${formValues?.region}','${formValues?.gstIn}','${formValues?.pan}','${formValues?.type}','${user?.UserName}' `
            }
            dispatch(addWhAction(payload))
        }
    }

    const editWh = () => {
        setOnLoad(true)
        let payload = {
            "opnfor": 3,
            "tableno": 38,
            "where": `WarehouseId = '${openDrawer?.wareHouseId}' AND CorporateID = '${getUserInfo()?.CorporateID}'`,
            "fieldarray": ` WarehouseId='${openDrawer?.wareHouseId}',WarehouseName= '${formValues?.whName}',AddressLine1='${formValues?.address}',City='${formValues?.city}',State='${formValues?.state}',PinCode='${formValues?.pinCode}',Region='${formValues?.region}',GSTIN='${formValues?.gstIn}',PAN='${formValues?.pan}',WarehouseTypeID='${option ? option : formValues?.type}',ModifiedBy='${getUserInfo()?.UserName}',ModifiedDate=now()`

        }
        dispatch(editWhAction(payload))
    }

    const drawerView = () => {
        try {
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={430}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                {openDrawer?.addToExist == 'Yes' ? "Edit Warehouse" : "Add Warehouse"}
                            </div>
                            {/* <Button
                                onClick={() => setOpenDrawer({ ...openDrawer, isOPen: false, addToExist: 'No' })}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button> */}
                            <div>
                                <img onClick={() => setOpenDrawer({ ...openDrawer, isOPen: false, addToExist: 'No' })} className="c-p" src={AppImages.closeimg} alt='' />
                            </div>
                        </div>
                    }
                    onClose={() => {

                        clearFieldValue()
                    }}
                    open={openDrawer?.isOPen}>
                    <div style={{ borderRadius: 2 }}>
                        <Form
                            id='form'
                            form={form}
                            autoComplete="off"
                            noValidate
                            layout="vertical"
                            onFinish={() => (openDrawer?.addToExist == 'Yes') ? editWh() : AddWh()}
                        >

                            <div style={{ marginTop: 10 }}>
                                {(openDrawer?.addToExist == 'Yes') ?
                                    <>
                                        <div style={{ fontFamily: 'RedHat-Bold', marginTop: 10 }}>Warehouse Id</div>
                                        <div className='showDate' style={{ marginTop: 10 }}>
                                            {openDrawer?.wareHouseId}
                                        </div>
                                    </> :
                                    <div className="mt-10">
                                        <Form.Item
                                            label="Warehouse ID"
                                            name="whID"
                                            required
                                            rules={
                                                [{ required: true, message: "Enter Warehouse Name" },
                                                { pattern: /^[a-zA-Z0-9]*$/, message: "Enter valid ID" }
                                                ]
                                            }
                                        >
                                            <Input
                                                onChange={(e: any) => setFormValues({ ...formValues, whID: e.target.value })}
                                                className='input-field'
                                                placeholder="Enter"
                                            />

                                        </Form.Item>
                                    </div>
                                }
                            </div>
                            <div className="mt-10">
                                <Form.Item
                                    label="Warehouse Name"
                                    name="whName"
                                    required
                                    rules={
                                        [{ required: true, message: "Enter Warehouse Name" },
                                        { pattern: /^[a-zA-Z0-9]*$/, message: "Enter valid Name" },
                                        ]
                                    }
                                >
                                    <Input
                                        onChange={(e: any) => setFormValues({ ...formValues, whName: e.target.value })}
                                        className='input-field'
                                        placeholder="Enter"
                                    />

                                </Form.Item>
                            </div>
                            <div className="mt-10">
                                <Form.Item
                                    label="Type"
                                    name="type"
                                    required
                                    rules={
                                        [{ required: true, message: "Select type" }]
                                    }
                                >
                                    <Radio.Group value={option} onChange={onChangeSKULocation}>
                                        <Radio className='radio' value={2} style={{ fontFamily: "RedHat-Bold" }}>B2B</Radio>
                                        <Radio className='radio' style={{ marginLeft: 15, fontFamily: "RedHat-Bold" }} value={1} >B2C</Radio>
                                        {/* <Radio className='radio' style={{ marginLeft: 15, fontFamily: "RedHat-Bold" }} value={"Both"} >Both</Radio> */}
                                    </Radio.Group>

                                </Form.Item>
                            </div>

                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    label="Full Address"
                                >
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <div className="mt-10">
                                                <Form.Item
                                                    label="Address"
                                                    name="address"
                                                    required
                                                    rules={
                                                        [{ required: true, message: "Enter address" }]
                                                    }>
                                                    <Input
                                                        onChange={(e: any) => setFormValues({ ...formValues, address: e.target.value })}
                                                        className='input-field'
                                                        placeholder="Enter"
                                                    />

                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <div className="mt-10">
                                                <Form.Item
                                                    label="City"
                                                    name="city"
                                                    required
                                                    rules={
                                                        [{ required: true, message: "Enter City" },
                                                        { pattern: /^[a-zA-Z0-9]*$/, message: "Enter valid City" },
                                                        ]
                                                    }>
                                                    <Input
                                                        onChange={(e: any) => setFormValues({ ...formValues, city: e.target.value })}
                                                        className='input-field'
                                                        placeholder="Enter"
                                                    />

                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <div className='mt-10'>
                                                <Form.Item
                                                    className='mt-10'
                                                    label="State"
                                                    name="state"
                                                    required
                                                    rules={[{ required: true, message: 'Please Select State!' }]}
                                                >
                                                    <Input
                                                        className='input-field'
                                                        onChange={(e: any) => setFormValues({ ...formValues, state: e.target.value })}
                                                        placeholder="Enter"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="mt-10">
                                                <Form.Item
                                                    label="Region"
                                                    name="region"
                                                    required
                                                    rules={
                                                        [{ required: true, message: "Enter region" }]
                                                    }>
                                                    <Input
                                                        onChange={(e: any) => setFormValues({ ...formValues, region: e.target.value })}
                                                        className='input-field'
                                                        placeholder="Enter"
                                                    />

                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="mt-10">
                                                <Form.Item
                                                    label="Pin Code"
                                                    name="pinCode"
                                                    required
                                                    rules={
                                                        [
                                                            { required: true, message: "Enter Pin Code" },
                                                            { pattern: /^[a-zA-Z0-9]*$/, message: "Enter valid Pin Code" },
                                                            { pattern: /^\S*$/, message: "Space not allowed" }
                                                        ]
                                                    }>
                                                    <Input
                                                        maxLength={6}
                                                        onChange={(e: any) => setFormValues({ ...formValues, pinCode: e.target.value })}
                                                        className='input-field'
                                                        placeholder="Enter"
                                                    />

                                                </Form.Item>
                                            </div>
                                        </Col>

                                    </Row>

                                </Form.Item>

                            </div>
                            <div className="mt-10">
                                <Form.Item
                                    label="GSTIN"
                                    name="gstIn"
                                    required
                                    rules={
                                        [
                                            { required: true, message: "Enter GSTIN" },
                                            { pattern: /^[a-zA-Z0-9]*$/, message: "Enter valid GSTIN Code" },
                                            { pattern: /^\S*$/, message: "Space not allowed" }
                                        ]
                                    }
                                >
                                    <Input
                                        minLength={3}
                                        maxLength={15}
                                        onChange={(e: any) => setFormValues({ ...formValues, gstIn: e.target.value })}
                                        className='input-field'
                                        placeholder="Enter"
                                    />

                                </Form.Item>
                            </div>
                            <div className="mt-10">
                                <Form.Item
                                    label="PAN"
                                    name="pan"
                                    required
                                    rules={
                                        [
                                            { required: true, message: "Enter PAN" },
                                            { pattern: /^[a-zA-Z0-9]*$/, message: "Enter valid PAN Code" },
                                            { pattern: /^\S*$/, message: "Space not allowed" }
                                        ]
                                    }
                                >
                                    <Input
                                        maxLength={10}
                                        onChange={(e: any) => setFormValues({ ...formValues, pan: e.target.value })}
                                        className='input-field'
                                        placeholder="Enter"
                                    />

                                </Form.Item>
                            </div>


                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, marginRight: 10 }}>
                                <Button className='cancel-btn'
                                    onClick={() => clearFieldValue()}
                                >
                                    Cancel
                                </Button>
                                <AppButtons
                                    block={true}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                    text={(openDrawer?.addToExist == 'Yes') ? "Save" : "Add"}
                                    htmlType={'submit'}
                                >
                                </AppButtons>
                            </div>

                        </Form>
                    </div>
                </Drawer >
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }
    }

    let titleHeading = {
        title: "Warehouse Master and Configuration"
    }

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={3}>
                    {"Warehouse ID"}
                </Col>
                <Col span={3}>
                    {"Warehouse Name"}
                </Col>

                <Col span={2} className=' d-flex'>
                    {"Type"}
                </Col>
                <Col span={3}>
                    {"City"}
                </Col>
                <Col span={4}>
                    {"GST"}
                </Col>
                <Col span={4}>
                    {"PAN"}
                </Col>
                <Col span={5} className=' d-flex jc-c'>
                    {AppConstants.action}
                </Col>
            </Row>
        )
    }

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
        }
    }

    const skuDetailsView = (item: any) => {
        return (
            <div style={{ marginTop: 10, background: "white", padding: 10, borderRadius: 10 }}>
                <div style={{ fontFamily: "RedHat-Bold", fontSize: 16 }}>Full Address</div>
                <div className='detail-box'>{item?.AddressLine1}</div>
            </div>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        return (
            <div className={open ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={3} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='text-2'>
                            {item?.WarehouseId}
                        </div>
                    </Col>
                    <Col span={3} className=' d-flex align-c'>
                        <div className='text-2 overflow-ellipse ' title={item?.WarehouseName}>
                            {item?.WarehouseName}
                        </div>
                    </Col>
                    <Col span={2} className=' d-flex align-c'>
                        <div className='text-2'>
                            {item?.Type}
                        </div>
                    </Col>
                    <Col span={3} className=' d-flex align-c'>
                        <div className='text-2 overflow-ellipse ' title={item?.City}>
                            {item?.City}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c'>
                        <div className='text-2 overflow-ellipse' title={item?.GSTIN}>
                            {item?.GSTIN}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c'>
                        <div className='text-2 overflow-ellipse' title={item?.PAN}>
                            {item?.PAN}
                        </div>
                    </Col>
                    <Col span={5} className="d-flex align-c">
                        <div className="d-flex align-c jc-c">
                            <Link to="/configure-warehouse" state={{ item: item }}>
                                <div
                                    className='Configure'
                                    style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 15 }}>
                                    <SettingIcon />
                                    <div style={{ marginLeft: 5 }}>Configure</div>
                                </div>
                            </Link>
                            <div
                                style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 15 }}
                                className='Configure'
                                onClick={() => openEditDrawerFields(item)}>
                                <EditIcon />
                                <div style={{ marginLeft: 10 }}>Edit</div>
                            </div>
                        </div>

                    </Col>
                    {open && <Col span={24} className="sku-details">
                        {skuDetailsView(item)}
                    </Col>}
                </Row>
            </div>
        )
    }

    const header = () => {
        return (
            <div>
                <SubHeader
                    title={"List of Warehouse(s)"}
                    paginationView="false"
                    inputView="true"
                    btnView="true"
                    fromOrgin="wareHouse-Master"
                >
                    <div className='d-flex flex-end'>
                        <AppButtons
                            text="Add New Warehouse"
                            buttonType={AppConstants.buttonTypes.solidLinearGreen}
                            onClick={() => openWHDrawer()}
                        />
                        <div style={{ marginLeft: 10 }}>
                            <Input
                                onChange={(e) => setSearchValue(e.target.value)}
                                prefix={<SearchOutlined />}
                                placeholder='Search By Warehouse Id'
                                style={{ height: 39, borderRadius: 4, fontFamily: "RedHat-SemiBold" }}
                            />
                        </div>
                    </div>
                </SubHeader>
            </div>
        )
    }


    const contentView = () => {
        let filteredData = data?.Lines?.filter(({ WarehouseId }: any) => WarehouseId?.toLowerCase()?.includes(searchValue?.toLowerCase()));
        let dataList = searchValue ? filteredData : data?.Lines;
        return (
            <div className='table-screen-container'>
                {tableHeaderView()}

                {isArrayNotEmpty(dataList) ?
                    <div className='table-scroll'>
                        {(dataList || []).map((x: any, index: any) => tableBodyView(x, index))}
                    </div>
                    :
                    <div className='p-20'>
                        <NoData
                            className='mt-45'
                            description={
                                <>There is no data available</>
                            }
                        />
                    </div>
                }


            </div>
        )
    }

    return (
        <Layout style={{ background: "white" }}>
            <NavbarLayout
                titleContent="main-head"
                process={titleHeading}
                initial={AppModuleIds.main}
                selectedMain={'8'}
            />
            <Layout>
                {header()}
                {isArrayNotEmpty(data?.Lines) ?
                    <>{contentView()}</>
                    :
                    <div className='p-20'>
                        <NoData
                            className='mt-45'
                            description={
                                <>There is no data available in <span className='redhat-bold ml-5'>Warehouse Master</span></>
                            }
                        />
                    </div>
                }
                {drawerView()}
            </Layout>
        </Layout>
    );
}

export default WarehouseMaster;