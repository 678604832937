import { Col, Row, Select, Skeleton, Table } from "antd";
import React, { useEffect, useState } from "react";
import AppConstants from "../../../Globals/AppConstants";
import '../GRN.scss';
import { CaretRightOutlined, CheckOutlined, CloseOutlined, LockFilled } from "@ant-design/icons";
import BoxIcon from "../../../Images/BoxIcon";
import { useDispatch, useSelector } from "react-redux";
import { assignMoverAction, getGrnDetailsAction, getIndividualStageAction } from "../../../Store/Actions/ProcessWMSAction";
import { getDashboardMenuId, getUserInfo, getWareHouseId } from "../../../Utils/SessionStorage";
import AppButtons from "../../../CustomComponent/AppButtons";
import { Link } from "react-router-dom";
import AppReferences from "../../../Globals/AppReferences";

const { Option } = Select;
let menuID = getDashboardMenuId()

function GrnPending(props: any) {
    const { pendingGrn, stageDetails, isCustomer, value }: any = props;
    const [visible, setVisible] = useState(false);
    const [selectMover, setSelectMover] = useState<any>([])
    const { view, item } = props
    const [expandOpen, setExpandOpen] = useState<any>(-1);
    const dispatch = useDispatch();
    const [mover, setMover] = useState("");
    const [onlaod, setonlaod] = useState(false);
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const { getGrnInfo } = reducerState;
    const [assignedMover, setassignedMover] = useState<any>();

    useEffect(() => {
        if (onlaod && !reducerState.assignOnload) {
            getIndividualStageDetails();
            setonlaod(false);
            setSelectMover([]);
        }
    }, [onlaod, reducerState.assignOnload]);

    const getIndividualStageDetails = () => {
        let payload = {
            "activity": AppReferences.activities.av3,
            "warehouse": getWareHouseId(),
            "opnfor": AppReferences.opnfor.wms,
            "where": '145100',
            "user": getUserInfo()?.UserName,
            "fieldarray": getUserInfo()?.roleId,
            "tableno": getUserInfo()?.CorporateID
        }
        dispatch(getIndividualStageAction(payload));
    }

    const getPutawayList = (putaway: any) => {
        let payload = {
            "opnfor": '145100',
            "activity": AppReferences.activities.a02,
            "transaction": putaway?.PutawayID,
            "user": getUserInfo()?.UserName
        }
        dispatch(getGrnDetailsAction(payload))
    }

    const assignMoverApi = (putawayId: any) => {
        let payload = {
            opnfor: '145100',
            where: mover,
            activity: AppReferences.activities.a01,
            transaction: putawayId,
            user: getUserInfo()?.UserName
        }
        dispatch(assignMoverAction(payload));
        setonlaod(true);
    }

    const columns: any = [
        {
            title: 'SKU Name',
            dataIndex: '',
            align: "left",
            width: 300,
            key: 'sku-name',
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item.ItemID}
                    </div>
                );
            },
        },
        {
            title: 'SKU Description',
            dataIndex: 'Description',
            key: 'sku-description',
            align: "left",
            width: 300,
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                );
            }
        },
        {
            title: 'Invoice Qty',
            dataIndex: 'InvQty',
            align: "center",
            key: 'grnQty',
            width: 200,
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                );
            }
        },
        {
            title: 'GRN Qty',
            dataIndex: 'GRNQTY',
            align: "center",
            key: 'grnQty',
            width: 200,
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                );
            }
        },
        {
            title: 'Sellable',
            dataIndex: 'Sellable',
            align: "center",
            key: 'sellable',
            width: 200,
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                );
            }
        },
        {
            title: 'Damaged',
            dataIndex: 'Damaged',
            align: "center",
            key: 'damaged',
            width: 300,
            render: (item: any) => {
                return (
                    <div className={item == '-' ? 'text' : 'text-1'}>
                        {item}
                    </div>
                );
            }
        },
    ];

    const skeletonView = () => {
        let list = [1, 2, 3, 4, 5, 6, 7]
        return (
            <div>
                {/* <div className='header-skeleton mt-45'>
                    <Skeleton paragraph={{ rows: 0 }} active />
                </div>
                <div className='table-header margin'>
                    <Skeleton paragraph={{ rows: 0 }} active />
                </div> */}
                {list?.map((item: any) =>
                    <div className='list-skeleton'>
                        <Skeleton paragraph={{ rows: 0 }} active />
                    </div>
                )}
            </div>
        )
    }
    const SkuCategories = () => {
        try {
            return (
                <div className={view === "true" ? "sku-table" : "sku-table1"}>
                    <Table
                        dataSource={pendingGrn?.SKULines}
                        columns={columns}
                        pagination={false}
                    />
                </div>
            )
        }
        catch (ex) {
            console.log("Error in SkuCategories::", ex);
        }
    }

    const putawayList = () => {
        let putaway = stageDetails?.lineitems?.find((x: any) => x?.InvoiceNo == pendingGrn?.InvoiceNo);
        return (
            <>
                {!reducerState.onload ?
                    (putaway?.PutawayData || []).map((putaway: any, index: any) => {
                        return (
                            putAwayCollapse(putaway, index)
                        )
                    })
                    :
                    skeletonView()
                }
            </>
        )
    }

    const reassignMoverView = (putaway: any, index: any) => {
        let assignMover = selectMover?.find((x: any) => x == putaway?.PalletID);
        const changeMover = (putawayId: any) => {
            assignMoverApi(putawayId);
            setassignedMover(pendingGrn?.MoverList?.find((x: any) => x.UserID == mover));
        }
        return (
            <div style={{ padding: 4 }} className={isCustomer ? "jc-c mover" : "mover"}>
                <div className="mvr-container">
                    <div className="mvr">{AppConstants.mover}</div>
                    {!assignMover ?
                        <>
                            {/* <div className="mvr-icon"><UserIcon /> </div> */}
                            <div className="mvr-user" style={{marginLeft: 10}}>{putaway?.MoverName}</div>
                        </> :
                        <div style={{ width: 160 }}>
                            <Select
                                placeholder="Select"
                                onChange={(e) => setMover(e)}
                                getPopupContainer={(trigger: any) => trigger.parentElement}
                                style={{ width: 160, marginLeft: 5 }}
                            >
                                {pendingGrn?.MoverList?.map((mover: any) => {
                                    if (putaway?.UserID != mover?.UserID)
                                        return (<Option value={mover?.UserID}>
                                            <span className="manager-name">{mover?.Name}</span>
                                        </Option>)
                                })}
                            </Select>
                        </div>
                    }
                </div>
                {!isCustomer && (!assignMover ?
                    <AppButtons
                        disabled={putaway?.Status == "Putaway Completed"}
                        text="Reassign Mover"
                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        onClick={() => setSelectMover([...selectMover, putaway?.PalletID])}
                    />
                    :
                    <div className="buttons">
                        <div className="cancel" onClick={() => setSelectMover(selectMover?.filter((x: any) => x != putaway?.PalletID))}>
                            <CloseOutlined />
                        </div>
                        <div className="save" onClick={() => { changeMover(putaway?.PutawayID) }}>
                            <CheckOutlined />
                        </div>
                    </div>)}
            </div>
        )
    }

    const handleExpand = (index: any, putaway: any) => {
        if (expandOpen == index) {
            setExpandOpen(-1);
        }
        else {
            setExpandOpen(index);
            getPutawayList(putaway)
        }
    }

    const putAwayCollapse = (putaway: any, index: any) => {
        try {
            let expanded = (expandOpen == index);
            let [, box_qty,] = putaway?.BoxCount?.split(/(?:,| )+/);
            return (
                <div className={view === "true" ? (expanded ? "putaway-container-1" : "putaway-container") : "putaway"}>
                    <div className="putaway-bar">
                        <Row className="put-row">
                            <Col span={3} style={{ display: "flex", alignItems: "center" }}>
                                <div className="put-id d-flex align-c" onClick={() => setVisible(!visible)}>
                                    {view === "true" &&
                                        <div className={expanded ? "caret-icon-expanded d-flex align-c" : "caret-icon d-flex align-c"} onClick={() => handleExpand(index, putaway)}>
                                            <CaretRightOutlined />
                                        </div>}
                                    <div className="put-id-txt">{AppConstants.palletId}
                                        <span title={putaway?.PalletID}>{putaway?.PalletID}</span>
                                    </div>

                                </div>
                            </Col>
                            <Col span={3}>
                                <div style={{ fontFamily: "Redhat-Bold" }}>{AppConstants.boxQuantity}
                                    <span>{box_qty}</span>
                                </div>
                            </Col>
                            <Col span={5} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                    <div className="category-datas">{putaway?.SystemType}</div>
                                    <div className="category-datas">{putaway?.StructureType}</div>
                                    {/* <div className="category-datas">{putaway?.Condition}</div> */}
                                </div>
                            </Col>
                            {value == 145100 ?
                                <Col span={8} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    {reassignMoverView(putaway, index)}
                                </Col>:
                                <Col span={8} style={{ fontFamily: "Redhat-Bold",display:"flex",alignItems:"center",justifyContent:"center" }}>
                                    {"Mover :"}
                                    <span style={{marginLeft:10}}>{putaway?.MoverName}</span>
                                </Col>
                            }
                            {value == 145100 ?
                                <Col span={5}>
                                    <div style={{ width: "90%", marginLeft: "5%" }} className="inprogress">
                                        {putaway?.Status}
                                    </div>
                                </Col>:
                                <Col span={5}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Link to="/continue-putaway" state={{ item: putaway }}>
                                            <AppButtons
                                                buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                                text="Continue Putaway" />
                                        </Link>
                                    </div>
                                </Col>

                            }
                        </Row>
                    </div>
                    {expanded &&
                        <div className="sub-container">
                            <div className="table-input-container">
                                <div className="box-table">
                                    <Row gutter={10}>
                                        {(getGrnInfo?.lineitems || []).map((item: any) => {
                                            return (
                                                <Col span={4}>
                                                    <div
                                                        className='barcode-button'
                                                        style={{ border: "2px solid #DB912B", padding: 10, background: "#FFF3E2" }}
                                                    >

                                                        <div className='d-flex align-c jc-sa'>
                                                            <>
                                                                {item?.Lines?.map((x: any) => {
                                                                    return (
                                                                        <>
                                                                            {x?.Condition == "Good" ?
                                                                                (x?.Condition != "-" && <div className={item?.BinID === null  ? 'd-flex align-c bin-container-green'  : 'd-flex align-c movebin-container-green' }>
                                                                                    {x?.Qty}
                                                                                </div>)
                                                                                :
                                                                                (x?.Condition != "-" && <div className={item?.BinID  === null ? 'd-flex align-c bin-container-red-1' :'d-flex align-c movebin-container-red'}>
                                                                                    {x?.Qty}
                                                                                </div>)
                                                                            }
                                                                        </>
                                                                    )
                                                                })}
                                                            </>
                                                            <div className='rip-count'>{item?.XofY}</div>
                                                        </div>
                                                        <div className={item?.BinID  === null ?'storage-type':"storage-type mt-0"}>
                                                            {item?.SystemType}
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            )
        }
        catch (ex) {
            console.log("");
        }
    }

    return (
        <div className="main-container">
            {value == 145100 && <>{SkuCategories()}</>}
            {putawayList()}
        </div>
    )
}

export default GrnPending;

