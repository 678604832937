import { CaretRightOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Row, Table } from 'antd';
import React, { useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import NoData from '../../../CustomComponent/NoData';
import AppConstants from '../../../Globals/AppConstants';
import SubHeader from '../../Header/Header';
import GrnEmailView from './GrnEmailView';

function GrnEmailListing(props: any) {
    const { getStatges, getIndividualStageDetails } = props;
    const [value, individualStageData]: any = useOutletContext();
    const [expandRow, setExpandRow] = useState(-1);
    const [openDrawer, setOpenDrawer] = useState<any>();

    const callBack = () => {
        getStatges();
        getIndividualStageDetails();
        setExpandRow(-1);
    }

    const drawerView = () => {
        try {
            let [, , warehouseId] = (openDrawer?.data?.id?.split("|") || []);//to get id from third index
            let drawerData = individualStageData?.header[0];
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                {warehouseId}
                            </div>
                            <Button onClick={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>
                        </div>
                    }
                    onClose={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                    open={openDrawer?.isOPen}>
                    <Row>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                {AppConstants.client}
                            </div>
                            <div className='value-field'>
                                {drawerData?.ClientID}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Transaction Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.InwardTxnTypeID}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.VehicleTypeId}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.VehicleNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Seal Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.SealNo}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Docket Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.DocketNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Dock Station
                            </div>
                            <div className='value-field'>
                                {drawerData?.DockStationID}
                            </div>
                        </Col>
                    </Row>
                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }

    }


    const columns: any = [
        // {
        //     title: "GateIn ID",
        //     dataIndex: '',
        //     width: 300,
        //     align: "left",
        //     render: (item: any) => {
        //         let data = item?.id?.split("|")
        //         return (
        //             <div style={{ cursor: "pointer" }} className='gatein-text' onClick={() => setOpenDrawer({ ...openDrawer, isOPen: true, data: item })}>
        //                 <span title={data[2]} className='overflow-tagline'>{data[2]}</span>
        //             </div>
        //         )
        //     }
        // },
        {
            title: AppConstants.invoiceNum,
            dataIndex: "show",
            width: 300,
            align: "left",
            render: (item: any) => {
                let invoiceNumber = item?.split(",");
                return (
                    <div className='text'>
                        {invoiceNumber[4]}
                    </div>
                )
            }
        },
        {
            title: "Client",
            dataIndex: 'name',
            width: 300,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='client-text'>
                        <span title={item} className='overflow-tagline'> {item}</span>
                    </div>
                )
            }
        },

        {
            title: "PO Number",
            dataIndex: "show",
            align: "center",
            width: 300,
            render: (item: any) => {
                let pO = item?.split(":");
                let pOnum = pO[1]?.split(",");
                return (
                    <div className='text'>
                        {pOnum[0]}
                    </div>
                )
            },
        },
        {
            title: "Release Number",
            dataIndex: "show",
            align: "center",
            width: 300,
            render: (item: any) => {
                let releaseNum = item?.split(",")
                return (
                    <div className='text'>
                        {releaseNum[3]}
                    </div>
                );
            },
        },
        {
            title: "Action",
            dataIndex: "",
            align: "center",
            width: 200,
            render: (item: any) => {
                return (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Link to="/grnemailview" state={{ item: item }}>
                            <Button className="green-btn" >
                                Send Email
                            </Button>
                        </Link>
                    </div>
                )
            },
        },
    ];


    const handleExpand = (data: any, index: any) => {
        setExpandRow(expandRow == index ? null : index);
    }

    const listingView = (data: any, index: any) => {
        try {
            let [, , warehouseId] = (data?.id?.split("|") || []);// to get id from third index 
            let [, , , , invoice_number] = (data?.show?.split(",") || []);
            let pO_Release = data?.show?.split(",");
            let pO_Number = pO_Release[2]?.split(':');
            let isExpandopen = (expandRow == index);
            return (
                <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={7} className='d-flex align-c '>
                            <div className="title-container">
                                <div className='d-flex align-c '>
                                    <CaretRightOutlined
                                        onClick={() => handleExpand(data, index)}
                                        className={index === expandRow ? "arrow arrow-down" : "arrow arrow-right"} />
                                <div className='text font-16' style={{marginLeft: 5}}> {invoice_number}</div>
                                </div>
                            </div>
                        </Col>
                        <Col span={7}>
                            <div className='text font-16'> {data?.name}</div>
                        </Col>

                        <Col span={5}>
                            <div className='text font-16 textalign-c'> {pO_Number[1]}</div>
                        </Col>
                        <Col span={5}>
                            <div className='text font-16 textalign-c'> {pO_Release[3]}</div>
                        </Col>
                    </Row>
                    {isExpandopen &&
                        <div style={{ marginTop: 10 }}>
                            <GrnEmailView
                                callBack={callBack}
                                item={data}
                            />
                        </div>

                    }
                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const titleContainer = () => {
        try {
            return (
                <Row>
                    <Col span={7}>
                        <div className='text'> Client</div>
                    </Col>
                    <Col span={7}>
                        <div className='text'> Invoice Number </div>
                    </Col>
                    <Col span={5}>
                        <div className='text textalign-c'> PO Number </div>
                    </Col>
                    <Col span={5}>
                        <div className='text textalign-c'> Release Number </div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }

    return (
        <div className='sendsan-main-container'>
            <SubHeader
                title={"GRN Ready, Email Pending"}
                inputView="true"
                paginationView="false"
                btnView="false">
            </SubHeader>
            <div
                style={{ padding: 15 }}
                className=""
            >
                <div className='header-part'
                >
                    {titleContainer()}
                </div>
                <div>
                    {individualStageData?.header?.length != 0 ?
                        (individualStageData?.header || []).map((item: any, index: any) =>
                            listingView(item, index)
                        )
                        :
                        <NoData />}
                </div>
            </div>
            {drawerView()}
        </div>

    )
}

export default GrnEmailListing