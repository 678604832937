import { CaretRightOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { Button, Checkbox, Col, Input, Row } from 'antd';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SubHeader from '../../Header/Header';
import "../OrderProcessing.scss"
import { AppImages } from '../../../Globals/AppImages';
import { useDispatch, useSelector } from 'react-redux';
import { getShowOrdersAction, selectProduct } from '../../../Store/Actions/ProcessWMSAction';
import { getClientId, getUserInfo } from '../../../Utils/SessionStorage';

function CreateManifestScreen() {
    const dispatch = useDispatch();
    // const { showOrders, getOrders } = useSelector((state: any) => state.ProcessReducerState);
    const [value, setValue] = React.useState('');
    const user = getUserInfo();
    const clientId = getClientId();
    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);
    };


    const checkAll = (checkedStatus: any, value: any, key: any) => {
        dispatch(selectProduct(checkedStatus, value, key))
    }
    // useEffect(() => {
    //     getShowOrderApi();
    // }, [])

    // const getShowOrderApi = () => {
    //     let payload = {
    //         opnfor: AppReferences.opnfor.salesOrder,
    //         activity: "A-15",
    //         transaction: clientId,
    //         where: selectedCouriers?.id,
    //         user: user?.UserName
    //     }
    //     dispatch(getShowOrdersAction(payload));
    // }

    // const getOrdersApi = () => {
    //     let payload = {
    //         opnfor: AppReferences.opnfor.salesOrder,
    //         activity: 'A-17',
    //         transaction: showOrders?.ManifestID?.AutoManifestID,
    //         where: AWB Number,
    //         user: user?.UserName
    //     }
    //     dispatch(getOrdersAction(payload))
    // }

    const header = () => {
        return (
            <SubHeader
                title={"RTS - Create Manifest  -  223456785"}
                paginationView="false"
                inputView="true"
                btnView="false"
                fromOrgin={"orderProcessing"}
            >
                <div className='d-flex flex-end '>
                    <div className='createManifest-btn'>
                        Complete Manifest
                    </div>
                    {/* <Link to="/order-processing/rts" >
                        <div className="close-btn ">
                            <CloseOutlined />
                        </div>
                    </Link> */}
                </div>

            </SubHeader>
        )
    }

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={4} className=' d-flex'>
                    {"AWB Number"}
                </Col>
                <Col span={8} className=' d-flex jc-c' >
                    {"Order ID"}
                </Col>
                <Col span={6} className=' d-flex'>
                    {"SKU,Description"}
                </Col>
                <Col span={6} className=' d-flex jc-c'>
                    {"Box Qty"}
                </Col>
            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        return (
            <div className={"content-container3"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={4} className=' d-flex align-c'>
                        <div className='orderid'>
                            {"10"}
                        </div>
                    </Col>
                    <Col span={8} className="d-flex align-c jc-c">
                        <div className='text-1'>
                            {"5"}
                        </div>
                    </Col>
                    <Col span={6} className=' d-flex align-c'>
                        <div className='text'>
                            {"ADS Courier"}
                        </div>
                    </Col>
                    <Col span={6} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {"10"}
                        </div>
                    </Col>

                </Row>
            </div>
        )
    }

    const contentView = () => {
        try {
            return (
                <div className='content-container'>
                    <Row className='select-container' gutter={16}>
                        <Col span={11}>
                            <div className='select-courier' style={{ marginLeft: 10 }}>
                                <div className='courier-label'>Courier Service</div>
                                <div className='courier'>
                                    {"Courier Name"}
                                </div>
                            </div>
                        </Col>
                        <Col span={6} className='d-flex align-c jc-c'>
                            {/* <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Show Orders</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={value}
                                    label="Show Orders"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>25</MenuItem>
                                    <MenuItem value={30}>50</MenuItem>
                                </Select>
                            </FormControl> */}
                            {/* <div className='d-flex align-c jc-c'
                                style={{ width: "70%", borderRadius: 6 }}>
                                <Input placeholder='Scan AWB Number'
                                    style={{ borderRadius: 5, height: 37, fontFamily: "RedHat-SemiBold", fontSize: "16px" }} />
                            </div> */}
                        </Col>
                    </Row>
                    <hr style={{ marginTop: 20, marginBottom: 20 }} />
                    <div className='table-screen-container'>
                        {tableHeaderView()}
                        {[1, 2, 3, 4, 5]?.map((x: any, index: any) => tableBodyView(x, index))}
                    </div>

                </div>
            )
        } catch (ex) {
            console.log("Err in contentView::" + ex)
        }
    }
    return (
        <div>
            {header()}
            <div className='create-manifest-screen-container mt-55'>
                {contentView()}
            </div>
        </div>
    );
}

export default CreateManifestScreen;