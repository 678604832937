import { CaretRightOutlined, FilterFilled } from '@ant-design/icons';
import { Checkbox, Col, Dropdown, Menu, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { isArrayNotEmpty, uniqueBy } from '../../Globals/Helper';
import EmizaIcon from '../../Images/emizaIcon';
import "../Report/Report.scss"
import "../GRN/GRN-Email/GrnEmailView.scss"
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import { GetExpandGRNReportList, GetGRNReportList } from '../../Store/Actions/ProcessWMSAction';
import WMSIcon2 from '../../Images/WMSIcon2';

let Arr: any = [];
let uniqueClient: any = [];

function GRNReport(props: any) {
    const {
        setSearchView, 
        searchView,
        setDateView,
        dateView
    } = props
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);

    const [filterData, setFilterData] = useState<any>();
    const [filterDataView,setFilterDataView] = useState<any>(false)
    const [OnLoad, setOnLoad] = useState<any>(false)
    const [expandRow, setExpandRow] = useState<any>();
    const { reportGRNList, reportExpandGRNList,reportSearchGateInList, reportDateList,reportGRNOnload  } = reducerState;
    let data = reportExpandGRNList?.lineitems[0];

    useEffect(() => {
        if (OnLoad === true && reportGRNOnload === false) {
            setFilterDataView(false)
            setSearchView(false)
            setDateView(false)
            getFilterData()
        }
    }, [OnLoad, reportGRNOnload])

    const getData = (data: any) => {
        let payload = {
            "opnfor": "R00003",
            "activity": "A-03",
            "transaction": data ? data?.PORelRecordNo : 0,
            "where": data ? "Y" : "N",
            "customer": data ? data?.ClientID : 0,
            "warehouse": getWareHouseId(),
            "user": getUserInfo()?.UserName
        }
        data ? dispatch(GetExpandGRNReportList(payload)) : dispatch(GetGRNReportList(payload))
        setOnLoad(true)
    }


    useEffect(() => {
        getData(null)
    }, [])

    const getSearchFilterData = () => {
        let arr = (reportSearchGateInList?.lineitems || []).map((item: any) => {
            let Obj = {
                "ClientName": item?.ClientName
            }
            return Obj
        })
        uniqueClient = uniqueBy(arr, 'ClientName')
    }

    const getDateFilterData = () => {
        let arr = (reportDateList?.lineitems || []).map((item: any) => {
            let Obj = {
                "ClientName": item?.ClientName
            }
            return Obj
        })
        uniqueClient = uniqueBy(arr, 'ClientName')
    }

    const getFilterData = () =>{
        let arr = (reportGRNList?.lineitems || []).map((item: any) => {
            let Obj = {
                "ClientName": item?.ClientName
            }
            return Obj
        })
        uniqueClient = uniqueBy(arr, 'ClientName')
    }


    useEffect(() => {
        if (searchView === false) {
            getFilterData()
        }
    }, [searchView])

    useEffect(() => {
        if (dateView === false) {
            getFilterData()
        }
    }, [dateView])

    useEffect(() => {
        if (reportDateList) {
            setDateView(true)
            setSearchView(false)
            setFilterDataView(false)
            getDateFilterData()
        }
    }, [reportDateList])

    useEffect(() => {
        if (reportSearchGateInList) {
            setSearchView(true)
            setDateView(false)
            setFilterDataView(false)
            getSearchFilterData()
        }
    }, [reportSearchGateInList])

    useEffect(() => {
        if (filterData?.length == 0) {
            setFilterDataView(false)
            setSearchView(false)
            setDateView(false)
        }
    }, [filterData])

    const filterTableData = () => {
        let filterData = (dateView ? reportDateList?.lineitems : searchView ? reportSearchGateInList?.lineitems : reportGRNList?.lineitems).filter((item: any) => {
            return Arr.includes(item?.ClientName)
        });
        setFilterData(filterData)
        setFilterDataView(true)
        setSearchView(false)
        setDateView(false)
    }


    const onChange = (e: any) => {
        
        if(e?.target.checked){
            Arr.push(e?.target.value)
            filterTableData()
        }else{
            Arr = Arr.filter((item: any) => item !== e?.target.value)
            filterTableData()
        }

        
    };

    const menu = () => {
        return (
            <Menu
                className="booth-representative-menu-view"
            >
                 {(uniqueClient || []).map((item: any) => {
                    return (
                        <Menu.Item className="booth-representative-menu-view-item" key={1}>
                            <div> <Checkbox value={item?.ClientName} onChange={onChange}>{item?.ClientName}</Checkbox></div>
                        </Menu.Item>
                    )
                })}
            </Menu>
        )
    }

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
            getData(data)
        }
    }

    const EmailView = () => {
        try {
            return (
                <div className='email-container'>
                    <div className='emz-icon'><WMSIcon2 /></div>
                    <div className="text-container">
                        <div className='content'>
                            <div style={{ fontFamily: "sans-serif", fontSize: 18, color: 'GrayText', marginLeft: 5, marginTop: 15 }}>Dear Customer,</div>
                            <div style={{ fontFamily: "sans-serif", fontSize: 18, color: 'GrayText', marginTop: 10, marginLeft: 5 }}>Greeting from Emiza,</div>
                        </div>
                    </div>
                    <div id='sanFinalMail' style={{ paddingLeft: 15, paddingRight: 15 }}>
                        <div style={{ fontSize: 18, fontFamily: "sans-serif", color: "GrayText", marginLeft: 20, marginTop: 5 }}>
                            <span>
                                This is Goods Received Note (GRN) for Invoice Number:{reportExpandGRNList?.lineitems[0]?.RefernceNo} ( {reportExpandGRNList?.lineitems[0]?.ID})
                            </span>
                        </div>
                        <div>
                            <div style={{ background: '#F0F0F4', padding: 20, marginTop: 10 }}>
                                {/* <table > */}
                                <div style={{ fontSize: 16, fontFamily: "sans-serif", fontStyle: "normal", color: "#4E4E4E", display: "flex" }}>
                                    <div style={{ width: "20%", marginLeft: 20 }}><b>{"Invoice Number"}</b></div>
                                    <div style={{ width: "20%", marginLeft: 20 }}><b>{"Vendor"}</b></div>
                                    <div style={{ width: "20%", marginLeft: 20 }}><b>{"PO Type"}</b></div>
                                    <div style={{ width: "20%", marginLeft: 20 }}><b>{"PO Number"}</b></div>
                                    <div style={{ width: "20%", marginLeft: 30 }}><b>{"Release No"}</b></div>
                                </div>
                                {/* {(getSendSanInfo?.lineitems || []).map((x: any) => {
                                return ( */}
                                <div style={{ fontSize: 16, fontFamily: "sans-serif", color: "#747474", display: "flex" }}>
                                    <div style={{ width: "20%", marginLeft: 20 }}>{data?.RefernceNo}</div>
                                    <div style={{ width: "20%", marginLeft: 20 }}>{data?.VendorName}</div>
                                    <div style={{ width: "20%", marginLeft: 20 }}>{data?.Type}</div>
                                    <div style={{ width: "20%", marginLeft: 20 }}>{data?.ID}</div>
                                    <div style={{ width: "20%", marginLeft: 30 }}>{data?.ReleaseNumber}</div>
                                </div>
                                {/* )
                            })} */}
                                {/* </table> */}
                                <br />
                                <table style={{ marginTop: "-13px" }} >
                                    <tr style={{ fontSize: 16, fontFamily: "sans-serif", color: "#FFFFFF", backgroundColor: "#022E3A" }}>
                                        <th style={{ width: 400, textAlign: "center", padding: 6, fontWeight: 200 }}>{"SKU Name"}</th>
                                        <th style={{ width: 400, textAlign: "center", padding: 6, fontWeight: 200 }}>{"SKU Description"}</th>
                                        <th style={{ width: 500, textAlign: "center", padding: 6, fontWeight: 200 }}>{"Invoice Qty"}</th>
                                        <th style={{ width: 500, textAlign: "center", padding: 6, fontWeight: 200 }}>{"GRN Qty"}</th>
                                        <th style={{ width: 500, textAlign: "center", padding: 6, fontWeight: 200 }}>{"Sellable"}</th>
                                        <th style={{ width: 500, textAlign: "center", padding: 6, fontWeight: 200 }}>{"Damaged"}</th>
                                    </tr>
                                    {(reportExpandGRNList?.lineitems || []).map((x: any) => {
                                        return (
                                            <tr style={{ fontSize: 16, fontFamily: "sans-serif", color: "#232323", backgroundColor: "rgb(255, 255, 255)", marginTop: 5 }}>
                                                <td style={{ width: 400, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{x?.ItemID}</td>
                                                <td style={{ width: 400, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{x?.Description}</td>
                                                <td style={{ width: 500, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{x?.InvoiceQty}</td>
                                                <td style={{ width: 500, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{x?.GRNQTY}</td>
                                                <td style={{ width: 500, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{x?.Good}</td>
                                                <td style={{ width: 500, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{x?.Damaged}</td>
                                            </tr>
                                        )
                                    })}
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="regards-container">
                        <div style={{ fontFamily: "sans-serif", fontSize: 18 ,color: "GrayText"}}>
                            {AppConstants.bestRegards}
                        </div>
                        <div style={{ fontFamily: "sans-serif", fontSize: 16 ,color: "GrayText" }}>
                            {AppConstants.emizaAdmin}
                        </div>
                        <div style={{ fontFamily: "sans-serif", fontSize: 16 ,color: "GrayText"}}>{reportExpandGRNList?.lineitems[0]?.WarehouseName}<span>, {reportExpandGRNList?.lineitems[0]?.AddressLine1}</span></div>
                        <div style={{ fontFamily: "sans-serif", fontSize: 16 ,color: "GrayText"}}>{reportExpandGRNList?.lineitems[0]?.City}<span>-{reportExpandGRNList?.lineitems[0]?.PinCode}</span>, <span>{reportExpandGRNList?.lineitems[0]?.State}</span></div>
                    </div>
                </div>
            )
        } catch (ex) {
            console.log("Err in EmailView")
        }
    }

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={7}>
                    {"Invoice Number"}
                </Col>
                <Col span={5} className=' d-flex jc-c'>
                    {"Clients"}
                    <span style={{ marginLeft: 20 }}>
                        <Dropdown
                            overlayStyle={{ width: 244, left: 1094 }}
                            overlay={() => menu()} placement="bottomRight" trigger={['click']}>
                            <div><FilterFilled /></div>

                        </Dropdown>
                    </span>
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"PO Number"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Release Number"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"GRN Sent Date & Time"}
                </Col>
            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        return (
            <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={7} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='title-blue'>
                            {item?.InvoiceNo}
                        </div>
                    </Col>
                    <Col span={5} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.ClientName}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.PurchaseOrderID}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.ReleaseNumber}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.GRNDateAndTime}
                        </div>
                    </Col>
                    {open && <Col span={24}>
                        <div style={{ marginTop: 10 }} className="Grn-container">
                            {EmailView()}
                        </div>
                    </Col>}
                </Row>
            </div >
        )
    }
    return (
        <div className='GateInReport-Container'>
            {(dateView ? isArrayNotEmpty(reportDateList?.lineitems) : searchView ? isArrayNotEmpty(reportSearchGateInList?.lineitems) : filterDataView ? isArrayNotEmpty(filterData) : isArrayNotEmpty(reportGRNList?.lineitems)) ?
                <div style={{ padding: 20 }}>
                    {tableHeaderView()}
                    <div className='table-scroll'>
                        {(dateView ? reportDateList?.lineitems : searchView?  reportSearchGateInList?.lineitems : filterDataView? isArrayNotEmpty(filterData)? filterData : reportGRNList?.lineitems :reportGRNList?.lineitems || []).map((x: any, index: any) => tableBodyView(x, index))}
                    </div>
                </div>
                :
                <div className='p-20'>
                    <NoData
                        className='mt-45'
                        description={
                            <>There is no reports available in <span className='redhat-bold ml-5'>GRN</span></>
                        }
                    />
                </div>
            }
        </div>
    );
}

export default GRNReport;