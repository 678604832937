import React from 'react';

function TableInventoryIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M15.4167 15.4167V12.6667H2.58333V15.4167H15.4167ZM15.4167 10.8333V7.16667H2.58333V10.8333H15.4167ZM15.4167 5.33333V2.58333H2.58333V5.33333H15.4167ZM2.58333 17.25C2.07917 17.25 1.64742 17.0706 1.28808 16.7119C0.929361 16.3526 0.75 15.9208 0.75 15.4167V2.58333C0.75 2.07917 0.929361 1.64742 1.28808 1.28808C1.64742 0.929361 2.07917 0.75 2.58333 0.75H15.4167C15.9208 0.75 16.3526 0.929361 16.7119 1.28808C17.0706 1.64742 17.25 2.07917 17.25 2.58333V15.4167C17.25 15.9208 17.0706 16.3526 16.7119 16.7119C16.3526 17.0706 15.9208 17.25 15.4167 17.25H2.58333Z" fill="var(--app-004986)" />
        </svg>
    );
}

export default TableInventoryIcon;