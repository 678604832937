import { Button, Col, Drawer, Form, Input, message, Radio, Row, Select, Skeleton, Spin, Table, Tooltip, } from 'antd';
import React, { useEffect, useState } from 'react';
import '../SAN.scss';
import { CaretRightOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import AppConstants from '../../../Globals/AppConstants';
import SubHeader from '../../Header/Header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppButtons from '../../../CustomComponent/AppButtons';
import Edit from '../../../Images/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { addInvoiceAndSkuDetailsAction, captureSKUWiseDetailsAndUnloadingAction, getCaptureInvoiceDetailsAction, getCaptureSKUDetailsAction, getMetaDataAction } from '../../../Store/Actions/ProcessWMSAction';
import { getProcess, getRTODetails, getUserInfo, getWareHouseId, setDashboardMenuId, setRTODetails } from '../../../Utils/SessionStorage';
import NavbarLayout from '../../NavbarLayout/NavbarLayout';
import NoData from '../../../CustomComponent/NoData';
import AppReferences from '../../../Globals/AppReferences';
import { AppImages } from '../../../Globals/AppImages';
import WMSIcon3 from '../../../Images/WMSIcon3';
import WMSIcon4 from '../../../Images/WMSIcon4';

const { Option } = Select;

function CaptureInvoiceDetails() {
    const locationProps: any = useLocation().state;
    const [openInvoiceDrawer, setOpenInvoiceDrawer] = useState(false);
    // const [view, setView] = useState(false);
    const [addVoiceData, setAddInvoiceData] = useState<any>();
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const { metaList, captureInvoiceDetails, captureSKUDetails, sendStickeringInfo, captureSKUDetailsOnLoad } = reducerState;
    let locationData = (locationProps?.invoiceData?.id?.split("|") || []);
    const [addOnload, setAddOnload] = useState(false);
    const [form]: any = Form.useForm();
    const [skuform]: any = Form.useForm();
    const [captureOnload, setCaptureOnload] = useState(false);
    const navigate = useNavigate();
    const [expandRow, setExpandRow] = useState<any>();
    const [skuDetails, setSkuDetails] = useState<any>();
    const [isEdit, setIsEdit] = useState(false);
    const [onload, setOnload] = useState(false);
    const [cutomerDetails]: any = (captureInvoiceDetails?.header || []);
    const [addSku, setAddSku] = useState(false);
    const [skuOnload, setSkuOnload] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState<any>();
    const [skuDeatailsOnload, setSkuDeatailsOnload] = useState<any>(false);
    const [skuTableData, setSkuTableData] = useState<any>([]);
    const rtoData = getRTODetails();
    let rto = rtoData?.rtoDetails;

    useEffect(() => {
        getGetCaptureInvoiceDetails();
        return () => setRTODetails("");
    }, []);

    useEffect(() => {
        if (skuDeatailsOnload == true && captureSKUDetailsOnLoad == false) {
            captureSKUDetails && setSkuTableData([...captureSKUDetails?.lineitemstwo])
        }
    }, [skuDeatailsOnload, captureSKUDetailsOnLoad]);


    useEffect(() => {
        if (captureOnload && !reducerState?.onload) {
            setCaptureOnload(false);
            let stage = sendStickeringInfo?.title?.stageNo;
            navigate(-1);
        }
    }, [captureOnload, reducerState?.onload]);

    useEffect(() => {
        if (onload && !reducerState?.onload) {
            setOnload(false);
            getCaptureSkuDetails(captureInvoiceDetails?.lineitems[0])
            // if(captureInvoiceDetails?.lineitems?.length > 0) {
            // moveToCaptureSkuWiseInvoice();
            // }
        }
    }, [onload, reducerState?.onload]);

    useEffect(() => {
        if (addOnload == true && !reducerState?.onload) {
            setAddOnload(false);
            closeInvoiceAdd();
            getGetCaptureInvoiceDetails();
            setAddInvoiceData(null);
            message.success("Invoice Added Successfully");
        }
    }, [addOnload, reducerState?.onload]);

    useEffect(() => {
        if (skuOnload && !reducerState?.onload) {
            setSkuOnload(false);
            message.success(isEdit ? "SKU Edited Successfully" : "SKU Added Successfully");
            onClose();
            getCaptureSkuDetails(invoiceDetails);

        }
    }, [skuOnload, reducerState?.onload]);

    const getGetCaptureInvoiceDetails = () => {
        try {
            let payload = {
                activity: AppReferences.activities.av,
                warehouse: getWareHouseId(),
                user: getUserInfo()?.UserName,
                transaction: rtoData?.invoiceFor == 'rto' ? rto?.GateINID : locationData[2], // this condition is for RTO invoice
                customer: rtoData?.invoiceFor == 'rto' ? rto?.ClientID : locationData[0],
                opnfor: rtoData?.invoiceFor == 'rto' ? '120100' : locationData[1]
            }
            dispatch(getCaptureInvoiceDetailsAction(payload))
            setOnload(true)
        } catch (error) {
            console.log("Error in getGetCaptureInvoiceDetails::", error);
        }
    }

    const getExecutive = () => {
        let payload = {
            tableno: 6,
            warehouse: getWareHouseId(),
            opnfor: 2,
            where: 8,
            customer: locationData[0],
            fieldarray: `SELECT U.UserID, U.Name FROM Users AS U INNER JOIN UserWarehouseClient AS UWC ON U.UserID = UWC.UserID WHERE U.RoleID = 2 AND WarehouseID IN ( '${getWareHouseId()}', '*') AND U.CorporateID = ${getUserInfo()?.CorporateID}`,

        }
        dispatch(getMetaDataAction(payload))
    }

    const getSkulist = () => {
        let payload = {
            tableno: 24,
            warehouse: getWareHouseId(),
            opnfor: 2,
            where: 2,
            customer: locationData[0],
            fieldarray: `SELECT im.ItemID, CONCAT(im.ItemID, ', ', Description) AS Description FROM ItemMaster im WHERE im.ClientID = '${locationData[0]}';`,

        }
        dispatch(getMetaDataAction(payload))
    }

    const getCaptureSkuDetails = (data: any) => { // get sku list service        
        let payload = {
            activity: AppReferences.activities.av,
            warehouse: getWareHouseId(),
            user: getUserInfo()?.UserName,
            transaction: data?.RecordNo ? data?.RecordNo : data?.InvRecNo,
            customer: cutomerDetails?.cId,
            opnfor: "120130"
        }
        console.log("payload", payload);
        dispatch(getCaptureSKUDetailsAction(payload))
        setSkuDeatailsOnload(true);
    }

    const addSkuDetails = () => { // sku add service
        try {
            let fieldArray = (isEdit
                ?
                `ItemID='${skuDetails?.skuDescription}',InvQty='${skuDetails?.invoiceQty}',ModifiedBy='${getUserInfo()?.UserName}', ModifiedDate=now()`
                :
                `'${skuDetails?.recNo}' ,'${skuDetails?.skuDescription}', '${skuDetails?.invoiceQty}','${getUserInfo()?.UserName}'`);// condition is to check editing r creating newly

            let where = (isEdit
                ? `GateInInvoicesRecNo='${skuDetails?.recNo}' AND ItemID='${invoiceDetails?.ItemID}'`
                : `GateInInvoicesRecNo,ItemID,InvQty,CreatedBy`);// condition is to check editing r creating newly

            let payload = {
                where: where,
                fieldarray: fieldArray,
                tableno: 24,
                // opnfor: isEdit ? selectValuechanged ? 2 : 3 : 4,
                opnfor: isEdit ? 3 : 4
            }

            dispatch(addInvoiceAndSkuDetailsAction(payload));
            setSkuOnload(true);
        } catch (error) {
            console.log("Error in addSkuDetails::", error);

        }

    }


    const addInvoice = () => {
        try {
            let invoiceNumber = rtoData ? 'RTO' + addVoiceData?.invoiceNumber : addVoiceData?.invoiceNumber;
            let payload = {
                where: `GateEntryRecordNo,InvoiceNo,InvoiceBoxQty,TotalInvoiceWeight,ExecutiveAssigned,LaboursAssigned,CreatedBy`,
                fieldarray: `'${locationData[2]}' ,'${invoiceNumber}', '${addVoiceData?.invoiceBox}','${addVoiceData?.invoiceWeight}','${addVoiceData?.executive}','${addVoiceData?.labours}', '${getUserInfo()?.UserName}'`,
                tableno: 6,
                opnfor: 4,
            }
            dispatch(addInvoiceAndSkuDetailsAction(payload));
            setAddOnload(true);
        } catch (error) {
            console.log("Error in addInvoice::", error);

        }

    }

    const invoiceCaptured = () => { // this is to complete invoice capturing
        try {
            let payload = {
                warehouse: getWareHouseId(),
                transaction: locationData[2],
                customer: locationData[0],
                opnfor: locationData[1],
                activity: AppReferences.activities.ax01,
                user: getUserInfo()?.UserName
            }
            console.log("payload", payload);
            dispatch(captureSKUWiseDetailsAndUnloadingAction(payload))
            setCaptureOnload(true)
        } catch (error) {
            console.log("Error in captureSkuWiseInvoice::", error);
        }
    }

    const moveToUnloading = (e: any, data: any) => {//
        e.stopPropagation();
        try {
            let payload = {
                warehouse: getWareHouseId(),
                transaction: data?.RecordNo,
                customer: locationData[0],
                opnfor: locationData[1],
                activity: AppReferences.activities.ax02,
                user: getUserInfo()?.UserName,
                where: locationData[2]
            }
            dispatch(captureSKUWiseDetailsAndUnloadingAction(payload))
            setCaptureOnload(true)
        } catch (error) {
            console.log("Error in captureSkuWiseInvoice::", error);
        }

    }

    const editSKu = (item: any) => {
        setAddSku(true)
        getSkulist();
        setInvoiceDetails(item);
        setIsEdit(true);
        setSkuDetails({
            ...skuDetails,
            skuDescription: item?.ItemID,
            invoiceQty: item?.InvQty,
            recNo: item?.InvRecNo
        });
        skuform.setFieldsValue({
            ['sku-description']: item?.ItemID,
            ['invoiceQty']: item?.InvQty,
        })
    }


    const showInvoiceAdd = () => {
        setOpenInvoiceDrawer(true);
        getExecutive();
    }
    const closeInvoiceAdd = () => {
        setOpenInvoiceDrawer(false);
        form.resetFields();
    }

    const onClose = () => {
        setAddSku(false);
        setIsEdit(false);
        skuform.resetFields();

    };

    const columns: any = [
        {
            title: AppConstants.sku,
            dataIndex: "ItemID",
            width: 350,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: AppConstants.SKUDescription,
            dataIndex: "Description",
            width: 350,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: AppConstants.invoiceQuantity,
            dataIndex: "InvQty",
            width: 200,
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: AppConstants.action,
            dataIndex: "",
            align: "center",
            width: 100,
            render: (item: any) => {
                return (
                    <div className='dropdown' style={{ cursor: "pointer" }} onClick={(e) => {
                        e.stopPropagation();
                        editSKu(item);
                    }}>
                        <EditOutlined />
                    </div>
                );
            },
        },
    ];

    const setInvoicedata = (value: any, key: any) => {
        setAddInvoiceData({ ...addVoiceData, [key]: value })
    }

    const addSkuData = (e: any, data: any, index: any) => {
        e.stopPropagation();
        setExpandRow(index);
        getCaptureSkuDetails(data);
        setInvoiceDetails(data);
        setAddSku(true);
        setSkuDetails({ ...skuDetails, recNo: data?.RecordNo });
        getSkulist();
    }

    const addInvoiceView = () => {
        try {
            return (
                <Drawer
                    title={(
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='add-invoice-box'>
                            <div className='add-invoice-text'>{"Add  Invoice Details"}</div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                {/* <Button onClick={() => closeInvoiceAdd()} className="cross-btn">
                                    <CloseOutlined />
                                    <img src={AppImages.closeimg} alt='' />
                                </Button> */}
                                <img onClick={() => closeInvoiceAdd()} className="c-p" src={AppImages.closeimg} alt='' />
                            </div>
                        </div>
                    )}
                    width={450}
                    open={openInvoiceDrawer == true}
                    bodyStyle={{ paddingBottom: 80, padding: 10 }}
                >
                    <>
                        <Form layout="vertical"
                            form={form}
                            style={{ padding: "10px" }}
                            onFinish={addInvoice}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="InvoiceNumber"
                                        label="Invoice Number"
                                        rules={[{ required: true, message: 'Enter Invoice Number' },
                                        ]}
                                    >
                                        <Input
                                            maxLength={15}
                                            prefix={rtoData && 'RTO'}
                                            placeholder="Enter"
                                            onChange={(e: any) => setInvoicedata(e.target.value, "invoiceNumber")}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <Form.Item
                                        name="InvoiceBox"
                                        label="Invoice Box(s)"
                                        rules={[{ required: true, message: 'Enter Invoice Box' },
                                        { pattern: /^[0-9]*$/, message: "Enter Valid Box Count" },
                                        {
                                            validator(rule, value, callback) {
                                                if (value && (value < 1 || value.length > 4)) {
                                                    return Promise.reject('Enter Valid Box Count')
                                                }
                                                else {
                                                    return Promise.resolve()
                                                }
                                            }
                                        }
                                        ]}
                                    >
                                        <Input
                                            onKeyPress={(event: any) => { return ((event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? "" : event.charCode >= 48 && event.charCode <= 57) }}
                                            placeholder="Enter "
                                            value={addVoiceData?.invoiceBox}
                                            onChange={(e) => setInvoicedata(e.target.value, "invoiceBox")}
                                            maxLength={10}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="InvoiceWeight"
                                        label="Invoice Weight (gms)"
                                        rules={[{ required: true, message: 'Enter Invoice Weight' },
                                        { pattern: /^[0-9]*$/, message: "Enter Valid Weight" },
                                        {
                                            validator(rule, value, callback) {
                                                if (value && value < 1) {
                                                    return Promise.reject('Enter Valid Weight')
                                                }
                                                else {
                                                    return Promise.resolve()
                                                }
                                            }
                                        }
                                        ]}
                                    >
                                        <Input
                                            placeholder="Enter "
                                            value={addVoiceData?.invoiceWeight}
                                            onChange={(e) => setInvoicedata(e.target.value, "invoiceWeight")}
                                            maxLength={8}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <Form.Item
                                        name="Executive"
                                        label="Executive"
                                        rules={[{ required: true, message: "Select Executive" }]}
                                    >
                                        <Select
                                            placeholder="Select "
                                            // className='select-field'
                                            value={addVoiceData?.executive}
                                            onChange={(e) => setInvoicedata(e, "executive")}
                                        >
                                            {metaList?.rData?.map((item: any) => {
                                                let data = item?.c?.split("|");
                                                return (
                                                    <Option value={data[0]}>
                                                        <div style={{ display: "flex", alignItems: "center" }} className="user-container">
                                                            {/* <div className="user-img">
                                                                <img src={AppImages.userImg} alt="user-Img" />
                                                             </div> */}
                                                            <span className="manager-name">{data[1]}</span>
                                                        </div>
                                                    </Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="Labours"
                                        label="Labours"
                                        rules={[{ required: true, message: 'Enter Labours Count' },
                                        { pattern: /^[1-4]*$/, message: "Enter Valid Count" }
                                        ]}
                                    >
                                        <Input
                                            maxLength={1}
                                            onKeyDown={(e: any) => { ((e.which || e.keyCode || e.charCode) != 8 && e.target.value > 4) && e.preventDefault(); setInvoicedata(e.target.value, "") }}
                                            placeholder="Enter"
                                            // className='input-field' 
                                            value={addVoiceData?.labours}
                                            onChange={(e) => setInvoicedata(e.target.value, "labours")}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: 20, paddingRight: '8px' }}>
                                {/* <Button className='cancel-btn' onClick={() => closeInvoiceAdd()}>Cancel</Button> */}
                                <AppButtons
                                    onClick={() => closeInvoiceAdd()}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                    text="Cancel"
                                ></AppButtons>
                                <AppButtons
                                    block={true}
                                    htmlType={"submit"}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                    text={"Add"}>
                                </AppButtons>
                            </div>
                        </Form>
                    </>
                </Drawer>
            )
        } catch (ex) {
            console.log("Error in viewDrawer::" + ex)
        }
    }

    const viewDrawer = () => {
        try {
            return (
                <Drawer
                    title={(
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='add-invoice-box'>
                            <div className='add-invoice-text'>{isEdit ? "Edit SKU Details" : "Add SKU Details"}</div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                {/* {view === true &&
                                    <Button className='edit-green-btn' onClick={() => showDrawer(null)} >
                                        <Edit />
                                    </Button>
                                } */}
                                {/* <Button onClick={() => onClose()} className="cross-btn">
                                    <CloseOutlined />
                                </Button> */}
                                <img onClick={() => onClose()} className="c-p" src={AppImages.closeimg} alt='' />
                            </div>
                        </div>
                    )}
                    width={450}
                    open={addSku == true}
                    bodyStyle={{ paddingBottom: 80, padding: 10 }}
                >
                    <>
                        <Form
                            form={skuform}
                            style={{ padding: "10px" }}
                            layout="vertical"
                            onFinish={addSkuDetails}
                        >
                            <div>

                            </div>
                            <Form.Item
                                name="sku-description"
                                label="SKU,Description"
                                rules={[{ required: true, message: "Select the SKU" }]}
                                style={{ marginTop: 10 }}
                            >
                                <Select
                                    value={skuDetails?.skuDescription}
                                    onChange={(e) => { setSkuDetails({ ...skuDetails, skuDescription: e }) }}
                                    placeholder="Select"
                                    showSearch={true}
                                >
                                    {metaList?.rData?.map((item: any) => {
                                        try {
                                            let data = item?.c?.split("|")
                                            return (
                                                <Select.Option value={data[0]}>
                                                    {data[1]}
                                                </Select.Option>
                                            )
                                        } catch (error) {
                                            console.log("Error in skuDescription::", error)
                                        }
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                style={{ marginTop: 10 }}
                                name="invoiceQty"
                                label="Invoice Quantity"
                                rules={[{ required: true, message: "Enter Invoice Quantity" },
                                { pattern: /^[0-9]*$/, message: "Enter Valid Quantity" },
                                {
                                    validator(rule, value, callback) {
                                        if (value && value < 1) {
                                            return Promise.reject('Enter Valid Quantity')
                                        }
                                        else {
                                            return Promise.resolve()
                                        }
                                    }
                                }
                                ]}>
                                <Input
                                    value={skuDetails?.invoiceQty}
                                    onChange={(e) => setSkuDetails({ ...skuDetails, invoiceQty: e.target.value })}
                                    placeholder="Enter" />
                            </Form.Item>
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: 20, paddingRight: "8px" }}>
                                {/* <Button className='cancel-btn' onClick={() => onClose()}>Cancel</Button> */}
                                <AppButtons
                                    onClick={() => onClose()}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                    text="Cancel"
                                >
                                </AppButtons>
                                <AppButtons
                                    block={true}
                                    htmlType="submit"
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                    text={isEdit == true ? "Save" : "Add"}>
                                </AppButtons>
                            </div>
                        </Form>
                    </>
                </Drawer>
            )
        } catch (ex) {
            console.log("Error in viewDrawer::" + ex)
        }
    }

    const skeletonView = () => {
        let list = [1, 2, 3, 4]
        return (
            <div className="mt-45">
                {list?.map((x: any) =>
                    <div className='list-skeleton'>
                        <Skeleton paragraph={{ rows: 0 }} active />
                    </div>
                )
                }
            </div>
        )
    }

    const skuDetailsView = () => {
        return (
            <div className='pending-san-view-container mt-10' onClick={(e) => e.stopPropagation()}>
                {!captureSKUDetailsOnLoad ?
                    <Table
                        columns={columns}
                        dataSource={skuTableData != undefined && skuTableData}
                        pagination={false}
                        bordered />
                    :
                    skeletonView()
                }
            </div>
        )
    }

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setSkuTableData([])
            setExpandRow(index);
            getCaptureSkuDetails(data);
        }
    }

    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                <AppButtons
                    onClick={() => showInvoiceAdd()}
                    buttonType={AppConstants.buttonTypes.whiteButton}
                    text="Add Invoice" />
                <Link to={getRTODetails() ? '/process/rto-b2b' : "/process/SAN"} state={{ Option: 2 }}>
                    <Button className="close-btn-3">
                        <CloseOutlined />
                    </Button>
                </Link>
            </div>
        )
    }

    const invoiceTableHeaderView = () => {
        return (
            <Row className='invoice-header'>
                <Col span={4} >
                    {AppConstants.invoiceNum}
                </Col>
                <Col span={4} className='d-flex align-c jc-c'>
                    {AppConstants.invoiceBoxs}
                </Col>
                <Col span={5} className='d-flex align-c jc-c'>
                    {AppConstants.invoiceWeight}
                </Col>
                <Col span={3}>
                    {AppConstants.executive}
                </Col>
                <Col span={3} className='d-flex align-c jc-c'>
                    {AppConstants.labours}
                </Col>
                <Col span={5} className='d-flex align-c jc-c'>
                    {AppConstants?.action}
                </Col>
            </Row>
        )
    }

    const tooltip = (data: any) => {
        return (
            <Tooltip title={data}>
                {data}
            </Tooltip>
        )
    }

    const invoiceTableDataView = (data: any, index: any) => {
        let open = index == expandRow;
        return (
            <Row className={!open ? 'invoice-body' : "selected-row"} onClick={(e: any) => handleExpand(e, index, data)}>
                <Col span={4} className="d-flex aling-c">
                    <div >
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                    </div>
                    <div className='text'>
                        {data?.InvoiceNo}
                    </div>
                </Col >
                <Col span={4} className='d-flex align-c jc-c'>
                    <div className='text-1'>
                        {data?.InvoiceBoxQty}
                    </div>
                </Col>
                <Col span={5} className='d-flex align-c jc-c'>
                    <div className='text-1'>
                        {data?.INVOICEWEIGHT}
                    </div>
                </Col>
                <Col span={3}>
                    <div className='text'>
                        {data?.Name}
                    </div>
                </Col>
                <Col span={3} className='d-flex align-c jc-c'>
                    <div className='text-1'>
                        {data?.LaboursAssigned}
                    </div>
                </Col>
                <Col span={5} className='d-flex align-c jc-c'>
                    <AppButtons
                        onClick={(e: any) => addSkuData(e, data, index)}
                        buttonType={AppConstants.buttonTypes.whiteButton}
                        text="Add SKU"
                    />
                    <AppButtons
                        disabled={captureSKUDetails?.lineitemstwo?.length == 0 || expandRow != index}
                        onClick={(e: any) => moveToUnloading(e, data)}
                        buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                        text="Start Unloading"
                    />
                </Col>
                {/* <Col span={3}>
                    
                </Col> */}
                <Col span={24}>
                    {open && skuDetailsView()}
                </Col>
            </Row >
        )
    }

    const invoiceView = () => {
        try {
            return (
                <div className='invoice-table'>
                    {invoiceTableHeaderView()}
                    <div className='tbl-srl'>
                        {captureInvoiceDetails?.lineitems?.length != 0 ?
                            captureInvoiceDetails?.lineitems?.map((item: any, index: any) => invoiceTableDataView(item, index))
                            :
                            <NoData />}
                    </div>
                </div>
            )
        } catch (error) {
            console.log("Error in invoiceView::", error);
        }
    }

    return (
        <>
            {/* <NavbarLayout
                titleContent="main-head"
                process={{
                    title: "Capture Invoice & SKU Details (120130)",
                    warehouse: captureInvoiceDetails?.title?.warehouse
                }}
            /> */}
            <SubHeader
                title={(
                    <div className='d-flex'>
                        <Link to={"/process/SAN"} state={{ Option: 2 }}>
                            <div className='c-p'>
                                <WMSIcon4 />
                            </div>
                        </Link>
                        <div className='ml-10'>
                            <div className=''>Capture Invoice & SKU Details</div>
                            <div className='wms-client-name'>{rtoData?.invoiceFor == 'rto' ? rto?.GateINID : locationData && locationData[2]} -<span className='client-name'> {rtoData?.invoiceFor == 'rto' ? rto?.ClientName : locationProps?.invoiceData?.name}</span></div>
                        </div>
                    </div>
                )}
                btnView="false"
                inputView="true"
                paginationView="false">
                {childrenView()}
            </SubHeader>
            <div className='capture-invoice-container'>
                {invoiceView()}
                {/* {TotalNumofInvc()} */}
                {<div className='view-container'>
                    {viewDrawer()}
                    {addInvoiceView()}
                </div>}
            </div>
            <Spin spinning={addOnload} />
        </>
    )
}


export default CaptureInvoiceDetails;
