import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Radio, Row, Select } from 'antd';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import { isArrayNotEmpty } from '../../Globals/Helper';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';

const { Option } = Select;

function PutAwayView(props: any) {
    const [form]: any = Form.useForm();
    const location = useLocation()
    const { wh } = location.state;
    const [packingMaterial, setPackingMaterial] = useState<any>()

    const onChange = (e: any) => {
        setPackingMaterial(e.target.value);
    };

    const formView = () => {
        try {
            return (
                <>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={AppConstants.storageType}
                                name='storageType'
                            >
                                <div className='put-away-id-container '>
                                    {"General"}
                                </div>
                            </Form.Item>

                        </Col>
                        <Col span={24} style={{ marginTop: 30 }}>
                            <Form.Item
                                label={AppConstants.structureType}
                                name='structureType'
                            >
                                <div className='put-away-id-container '>
                                    {"HDR"}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ marginTop: 30 }}>
                            <Form.Item
                                label={AppConstants.palleteId}
                                name='palleteId'
                                required
                                rules={
                                    [{ required: true, message: "Select pallete Id" }]
                                }
                            >
                                <Select
                                    placeholder="Select"
                                    className='select-field'
                                //onChange={(e) => setAssigningDetails({ ...assigningDetails, palletID: e })}
                                >
                                    {(["PO-286", "PO-2826", "PO-90886", "PO-21326", "PO-2086", "PO-0816", "PO-5326"] || []).map((item: any) => {
                                        return (
                                            <Option value={item}>{item}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginTop: 30 }}>
                            <Form.Item
                                label={AppConstants.assignToMover}
                                name='assignToMover'
                                required
                                rules={
                                    [{ required: true, message: "Select assign to mover" }]
                                }
                            >
                                <div className="assignToMover-container">
                                    <Select
                                        placeholder="Select"
                                        className='select-field'
                                    //onChange={(e) => setAssigningDetails({ ...assigningDetails, assignedTo: e })}
                                    >
                                        {(["Mover-1", "Mover-2", "Mover-3", "Mover-4"] || []).map((item: any) => {
                                            return (
                                                <Option value={item}>
                                                    <div style={{ display: "flex", alignItems: "center" }} className="user-container">
                                                        <div className="user-img">
                                                            <img src={AppImages.userImg} alt="user-Img" />
                                                        </div>
                                                        <span className="manager-name" style={{ marginLeft: 10 }}>{item}</span>
                                                    </div>
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )
        } catch (ex) {
            console.log("Err in formView::" + ex)
        }
    }

    const ContentView = () => {
        try {
            return (
                <div className='packing-material-container'>
                    <Row gutter={16} style={{ padding: 20, height: "80vh" }}>
                        <Col span={6}>
                            <div className="create-content-view-container" style={{ padding: 20, height: "100%", width: "100%" }}>
                                <div style={{ marginBottom: 10 }}>Packing Materials </div>
                                <div className="content-container-po">
                                    <Radio.Group
                                        style={{ width: "100%" }}
                                        buttonStyle="solid"
                                        onChange={onChange}
                                        value={packingMaterial} >
                                        {(["Bubble Wrap", "Brown Papers", "Packing Tapes", "Customized Packing"] || []).map((item: any) => {
                                            return (
                                                <Radio.Button value={item} >
                                                    <div className="card-title-container" >
                                                        <div className='label'>{item}</div>
                                                        {<div className="count">
                                                            {"54"}
                                                        </div>}
                                                    </div>
                                                </Radio.Button>
                                            )
                                        })}
                                    </Radio.Group>
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="create-content-view-container" style={{ padding: 20, height: "100%", width: "100%" }}>
                                <Form
                                    id='form'
                                    form={form}
                                    autoComplete="off"
                                    noValidate
                                    layout="vertical"
                                >
                                    {formView()}

                                </Form>
                            </div>
                        </Col>
                        <Col span={12}>
                            <Row className='selected-packing-header'>
                                <Col span={18}>Selected Packing Material</Col>
                                <Col span={6}>Pallet ID: P-0332</Col>
                            </Row>
                            <div style={{height:"65vh",overflow:"scroll" ,paddingBottom:40}}>
                                {(["Bubble Wrap", "Brown Papers", "Packing Tapes", "Customized Packing"] || []).map((x: any) => {
                                    return (
                                        <Row className='selected-packing d-flex align-c '>
                                            <Col span={16}>{x}</Col>
                                            <Col span={8} className="d-flex align-c jc-sb">
                                                <Input
                                                    className='input-field'
                                                    placeholder='Enter Oty'
                                                    style={{ width: "400px" }}
                                                />
                                                <div className="close-btn">
                                                    <CloseOutlined />
                                                </div>
                                            </Col>
                                        </Row>
                                    )
                                })}
                            </div>

                        </Col>

                    </Row>
                </div>
            )
        } catch (ex) {
            console.log("Err in ContentView::" + ex)
        }
    }

    const header = () => {
        try {
            return (
                <SubHeader
                    title={<div className='font-14'>Create Putaway- {wh}</div>}
                    paginationView="false"
                    inputView="true"
                    btnView="true"
                >
                    <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <AppButtons
                            // disabled={onLoad.scanView}
                            // onClick={() => assignMover()}
                            buttonType={AppConstants.buttonTypes.solidLinearGreen}
                            text="Add Packing Materials">
                        </AppButtons>
                        <div style={{ marginLeft: 5 }}>
                            <AppButtons
                                // disabled={!scanView}
                                // onClick={() => startPutaway()}
                                buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                text="Start Putaway">
                            </AppButtons>
                        </div>
                        <Link to="/packing-putaway">
                            <Button className="close-btn">
                                <CloseOutlined />
                            </Button>
                        </Link>
                    </div>
                </SubHeader>
            )
        } catch (ex) {
            console.log("Err in header::" + ex)
        }
    }
    return (
        <div>
            <NavbarLayout
                titleContent="sub-head"
            //process={reportList?.title}
            />
            <div style={{ marginTop: 50 }}>
                {header()}
            </div>
            {ContentView()}
        </div>
    );
}

export default PutAwayView;