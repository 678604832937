import { CaretRightOutlined, SearchOutlined } from '@ant-design/icons';
import { Col, Form, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import AppReferences from '../../Globals/AppReferences';
import { isArrayNotEmpty } from '../../Globals/Helper';
import { completeStickeringAction, consignmentAction, getDetailedDataAction } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';

const suffix = (
    <SearchOutlined
        style={{
            fontSize: 20,
            color: 'black',
        }}
    />
);

function CustomStickering(props: any) {
    const [value, individualStageData, processId, getStatges, getIndividualStageDetails]: any = useOutletContext();
    const navigate = useNavigate();
    const [expandRow, setExpandRow] = useState<any>(-1);
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = useState<any>();
    const [form]: any = Form.useForm();
    const wareHouse_id = getWareHouseId();
    const [consignment, setConsignment] = useState<any>();
    const reducerState = useSelector((state: any) => state.ProcessReducerState);
    const { expandDetailedData, customStickering } = reducerState;
    const [onload, setOnload] = useState(false);
    const [invoice, setInvoice] = useState<any>({})

    useEffect(() => {
        if (onload && !reducerState.onload) {
            if (customStickering?.msg?.includes("Stickering completed for Item")) {
                getSkuDetails(invoice);
                getIndividualStageDetails();
                message.success(customStickering?.msg);
            }
            else {
                message.error(customStickering?.msg);
            }
        }
    }, [onload, reducerState.onload])

    const getSkuDetails = (data: any) => {
        try {
            setInvoice(data);
            let details = data?.id?.split('|');
            let payload = {
                opnfor: details[1],
                activity: AppReferences.activities.a01,
                warehouse: getWareHouseId(),
                user: getUserInfo()?.UserName,
                transaction: details[2],
                customer: details[0]
            }
            dispatch(getDetailedDataAction(payload))
        } catch (error) {
            console.log("Error in getSkuDetails::", error);
        }
    }

    const completeStickering = (invoice: any, data: any) => {
        try {
            let details = invoice?.id?.split('|');
            let payload = {
                opnfor: details[1],
                activity: AppReferences.activities.a02,
                warehouse: getWareHouseId(),
                user: getUserInfo()?.UserName,
                transaction: details[2],
                customer: details[0],
                where: data?.ItemId
            }
            dispatch(completeStickeringAction(payload));
            setOnload(true)
        } catch (error) {
            console.log("Error in getSkuDetails::", error);
        }
    }

    const invoiceTableBodyView = (data: any, index: any, invoice: any) => {
        return (
            <Row className={'table-body'}>
                <Col span={8} >
                    <div className='text'>
                        {data?.ItemId + ", " + data?.Description}
                    </div>
                </Col>
                <Col span={8}>
                    <div className='text-1 text-center'>
                        {data?.Qty}
                    </div>
                </Col>
                <Col span={8} className='d-flex align-c jc-c'>
                    <AppButtons
                        onClick={() => completeStickering(invoice, data)}
                        buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                        text={"Complete Custom Stickering"}
                    />
                </Col>
            </Row>
        )
    }

    const invoiceHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={8}>
                    {AppConstants.skuDescription}
                </Col>
                <Col span={8} className='text-center'>
                    {AppConstants.receivedqty}
                </Col>
                <Col span={8} className='d-flex align-c jc-c'>
                    {AppConstants.stickeredqty}
                </Col>
            </Row>
        )
    }

    const listingView = (data: any) => {
        try {
            return (
                <div className='table-theme-1' onClick={(e) => e.stopPropagation()}>
                    {invoiceHeaderView()}
                    <Form
                        form={form}
                    //onFinish={() => sendToSickering(data, index)}
                    >
                        {isArrayNotEmpty(expandDetailedData?.lineitems) ?
                            expandDetailedData?.lineitems?.map((item: any, index: any) => invoiceTableBodyView(item, index, data))
                            :
                            <NoData />
                        }
                    </Form>
                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const headerView = () => {
        return (
            <Row className='table-header'>
                <Col span={6}>
                    {AppConstants.invoiceNo}
                </Col>
                <Col span={6}>
                    {AppConstants.client}
                </Col>
                <Col span={6}>
                    <div className='d-flex align-c jc-c'>
                        {AppConstants.ponumber}
                    </div>
                </Col>
                <Col span={6}>
                    <div className='d-flex align-c jc-c'>
                        {AppConstants.releaseNo}
                    </div>
                </Col>
            </Row>
        )
    }

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            getSkuDetails(data)
            setExpandRow(index);
        }
    }

    useEffect(() => {
        if (openDrawer?.isOPen == true) {
            consignmentApi();
        }
    }, [openDrawer]);

    const consignmentApi = () => {
        let payload = {
            opnfor: "110000",
            activity: "A-02",
            transaction: consignment,
            warehouse: wareHouse_id,
            where: getUserInfo()?.CorporateID,

        }
        dispatch(consignmentAction(payload));
    }

    const openConsignment = (id: any) => {
        setConsignment(id);
        setOpenDrawer({ ...openDrawer, isOPen: true });


    }

    const customStickeringBody = (data: any, index: any) => {
        let open = index == expandRow;
        let invoice = data?.show?.split(',');
        try {
            return (
                <Row className={open ? 'table-body-blue' : 'table-body'} >
                    <Col span={6} className={"open-icon gateinid"}>
                        <div onClick={(e: any) => handleExpand(e, index, data)}
                            className='d-flex align-c jc-c'
                        >
                            <CaretRightOutlined
                                className={open ? "arrow arrow-down" : "arrow arrow-right"}
                            />
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => openConsignment(invoice && invoice[4])}
                        >
                            {invoice && invoice[4]}
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className='text'>
                            {data?.name}
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className={data?.SKUCount == '-' ? 'text d-flex align-c jc-c' : 'text-1 d-flex align-c jc-c'}>
                            {invoice && invoice[2]?.split(':')[1]}
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className={data?.SKUSumQTY == '-' ? 'text d-flex align-c jc-c' : 'text-1 d-flex align-c jc-c'}>
                            {invoice && invoice[3]}
                        </div>
                    </Col>
                    {/* <Col span={4} className='d-flex align-c jc-c'>
                        <AppButtons
                            text={'Complete'}
                            buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        />
                    </Col> */}
                    <Col span={24}>
                        {open && listingView(data)}
                    </Col>
                </Row>
            )
        } catch (error) {
            console.log("Error in customStickeringBody::", error);

        }

    }

    const dummy = [
        {
            invoice: "INV-0978",
            client: "Swiss Military",
            po: 'PO809',
            release: 'Release R-1',
        },
        {
            invoice: "INV-0978",
            client: "Swiss Military",
            po: 'PO809',
            release: 'Release R-1',
        },
        {
            invoice: "INV-0978",
            client: "Swiss Military",
            po: 'PO809',
            release: 'Release R-1',
        },
        {
            invoice: "INV-0978",
            client: "Swiss Military",
            po: 'PO809',
            release: 'Release R-1',
        },
        {
            invoice: "INV-0978",
            client: "Swiss Military",
            po: 'PO809',
            release: 'Release R-1',
        },
    ]

    const unloadingListView = () => {
        return (
            <div className='table-view'>
                {headerView()}
                <div style={{ marginTop: 10}}>
                    {individualStageData?.header?.length != 0 ?
                        (individualStageData?.header || []).map((item: any, index: any) => customStickeringBody(item, index))
                        :
                        <NoData />}
                </div>
            </div>
        )
    }


    return (
        <>
            <SubHeader
                title={"Custom Stickering"}
                paginationView="true"
                inputView="true"
                btnView="false">
            </SubHeader>
            {unloadingListView()}
        </>
    )
}



export default CustomStickering;
