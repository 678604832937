import React from 'react';
import NavbarLayout from "../../NavbarLayout/NavbarLayout";
import SubHeader from "../../Header/Header";
import GrnPending from './GrnPending';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getProcess } from '../../../Utils/SessionStorage';
import { useSelector } from 'react-redux';
import WMSIcon4 from '../../../Images/WMSIcon4';


function GRNPendingView(props: any) {
    const navigate = useNavigate();
    const { pendingGrn, isCutomer, value } = useLocation().state;
    const process = getProcess();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const { individualStageData }: any = reducerState;

    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex",alignItems:"center", justifyContent: "flex-end" }}>
                <Button className="close-btn" onClick={() => navigate(-1)}>
                    <CloseOutlined />
                </Button>
            </div>
        )
    }
    return (
        <div>
            {/* <NavbarLayout
                titleContent="sub-head"
                process={process}
            /> */}
            <SubHeader
                title={
                    <div className='d-flex align-c'>
                        <Link to={"/process/SAN"} state={{ Option: 2 }}>
                            <div className='c-p'>
                                <WMSIcon4 />
                            </div>
                        </Link>
                        <div className='ml-10'>GRN Pending<span> : {pendingGrn?.InvoiceNo}</span></div>
                    </div>
                }
                btnView="true"
                inputView="true"
                paginationView="false">
                {childrenView()}
            </SubHeader>
            <div className='p-10'>
                <GrnPending
                    value={value}
                    isCustomer={isCutomer}
                    stageDetails={individualStageData}
                    pendingGrn={pendingGrn}
                    view="true" />
            </div>
        </div>
    );
}

export default GRNPendingView;