import { CloseOutlined, FileSearchOutlined, PlusOutlined, QuestionCircleFilled, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select, Table } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppButtons from '../../../CustomComponent/AppButtons';
import AppConstants from '../../../Globals/AppConstants';
import File from '../../../Images/File';
import Upload from '../../../Images/Upload';
import SubHeader from '../../Header/Header';
import NavbarLayout from '../../NavbarLayout/NavbarLayout';
import "../QC.scss";

function QcInvoiceDetailsView() {
    const [count, setCount] = useState(0);

    const handleAdd = () => {
        const newData: any = {
            key: count,
            sku: 'Select',
            qty: 'Enter',
        };
        setDataSource([...dataSource, newData]);
        setCount(count + 1);
    };

    const handleDelete = (key: React.Key) => {
        const newData = dataSource.filter(item => item.key !== key);
        setDataSource(newData);
        setCount(count - 1);
    };

    const displayButton = (item: any) => {
        const lastElement: any = dataSource[dataSource.length - 1]
        if (lastElement.key === item?.key) {
            return (
                <div className='action-container'>
                    <Button onClick={handleAdd} className="Add-btn">
                        <PlusOutlined />
                    </Button>
                </div>
            )
        } else {
            return (
                <div className='action-container'>
                    <Button onClick={() => handleDelete(item?.key)} className="delete-btn">
                        <CloseOutlined />
                    </Button>
                </div>
            )
        }
    }

    const boxescolumns: any = [
        {
            title: AppConstants.skuDescription,
            dataIndex: '',
            width: 500,
            render: (item: any) => {
                return (
                    <Select
                        placeholder="Select" />
                )
            }
        },
        {
            title: AppConstants.invoiceQty,
            dataIndex: 'qty',
            width: 250,
            render: (item: any) => {
                return (
                    <Input
                        placeholder="Enter" />
                )
            }
        },
        {
            dataIndex: '',
            render: (item: any) => {
                return (
                    displayButton(item)
                )
            }
        },
    ]

    const [dataSource, setDataSource] = useState([
        {
            key: '1',
            sku: 'SKU001 - Lorem Ipsum',
            qty: '90',
        },
        {
            key: '2',
            sku: 'SKU001 - Lorem Ipsum',
            qty: '90',
        },
        {
            key: '3',
            sku: 'SKU001 - Lorem Ipsum',
            qty: '90',
        },
        {
            key: '4',
            sku: 'SKU001 - Lorem Ipsum',
            qty: '90',
        },

    ])
    const contentView = () => {
        try {
            return (
                <div className='qc-invoice-details-screen-container'>
                    <Table
                        bordered
                        dataSource={dataSource}
                        columns={boxescolumns}
                        pagination={false}
                    />
                    {/* <div className='btn-container'>
                        <Link to="/QC">
                            <AppButtons
                                block={true}
                                buttonType={AppConstants.buttonTypes.solidLinearGreen} text={AppConstants.MoveToAssociatePO}>
                            </AppButtons>
                        </Link>
                    </div> */}
                </div>
            )
        } catch (ex) {
            console.log("Error in contentView::" + ex)
        }
    }

   
    return (
        <div>
            {contentView()}
        </div>
    );
}

export default QcInvoiceDetailsView;