import React from 'react';

function WarehouseIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3077 12.7688H4.70384C4.57692 12.7688 4.47307 12.8727 4.47307 12.9996L4.47019 14.3842C4.47019 14.5112 4.57403 14.615 4.70096 14.615H15.3077C15.4346 14.615 15.5385 14.5112 15.5385 14.3842V12.9996C15.5385 12.8727 15.4346 12.7688 15.3077 12.7688ZM15.3077 15.5381H4.69519C4.56826 15.5381 4.46442 15.6419 4.46442 15.7688L4.46153 17.1535C4.46153 17.2804 4.56538 17.3842 4.6923 17.3842H15.3077C15.4346 17.3842 15.5385 17.2804 15.5385 17.1535V15.7688C15.5385 15.6419 15.4346 15.5381 15.3077 15.5381ZM15.3077 9.99962H4.70961C4.58269 9.99962 4.47884 10.1035 4.47884 10.2304L4.47596 11.615C4.47596 11.7419 4.5798 11.8458 4.70673 11.8458H15.3077C15.4346 11.8458 15.5385 11.7419 15.5385 11.615V10.2304C15.5385 10.1035 15.4346 9.99962 15.3077 9.99962ZM18.3798 5.99L10.5308 2.72173C10.3621 2.65175 10.1812 2.61572 9.99855 2.61572C9.81591 2.61572 9.63505 2.65175 9.46634 2.72173L1.62019 5.99C1.10673 6.20635 0.769226 6.70827 0.769226 7.26789V17.1535C0.769226 17.2804 0.873072 17.3842 0.999995 17.3842H3.30769C3.43461 17.3842 3.53846 17.2804 3.53846 17.1535V9.99962C3.53846 9.49193 3.95961 9.07654 4.47884 9.07654H15.5211C16.0404 9.07654 16.4615 9.49193 16.4615 9.99962V17.1535C16.4615 17.2804 16.5654 17.3842 16.6923 17.3842H19C19.1269 17.3842 19.2308 17.2804 19.2308 17.1535V7.26789C19.2308 6.70827 18.8933 6.20635 18.3798 5.99Z" fill="white" />
        </svg>
    );
}

export default WarehouseIcon;

