import React from 'react';
import logo from './logo.svg';
import './App.css';
import { UserContext } from './contexts/UserContext';
import { useContext } from 'react'
import Login from './Components/Login/Login';
import { BrowserRouter } from 'react-router-dom';
import GateInEntries from './Components/GateIn/GateInEntriesScreen';
import Navigates from './Components/Navigates/Navigates';

function App() {
  return(
    <Navigates/>
  )
}

export default App;
