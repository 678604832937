import { message } from "antd";
import AppConstants from "../../Globals/AppConstants";
import { call, put } from "redux-saga/effects";
import ApiConstants from "../../Globals/ApiConstants";
import Axios from "../Http/Axios";
import { SagaIterator } from "redux-saga";
import { ConsoleLogger } from "@aws-amplify/core";

function* failSaga(result: any) {
    yield put({ type: ApiConstants.API_FAIL });
    let msg = result.result.data
        ? result.result.data.message
        : AppConstants.somethingwentwrong;
    message.config({
        duration: 1.5,
        maxCount: 1,
    });
    message.error(msg);
}

function* errorSaga(error: any) {
    yield put({
        type: ApiConstants.API_ERROR,
        error: error,
        status: error.status,
    });
    message.config({
        duration: 1.5,
        maxCount: 1,
    });
    message.error(AppConstants.somethingWentWrong);
}
export function* getGateInEntrySaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_GATE_IN_ENTRY_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getStageDetailsSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_STAGE_DETAILS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getIndividualStageSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_INDIVIDUAL_STAGE_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getProcessDataSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getProcessDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_PROCESS_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getGateInEntryMetaSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getGateInEntryMetaAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_GATE_IN_ENTRY_META_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* gateInEntryAddSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.gateInEntryAddAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GATE_IN_ENTRY_ADD_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getTransactionSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getTransactionAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_TRANSACTION_SUCCESS,
                result: result?.result?.data?.body,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getAssociatePoSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getAssociatePoAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_ASSOCIATEPO_SUCCESS,
                result: result.result.data.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getPendingSanDetailSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_PENDING_SAN_DETAILS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getUnloadingDetailSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_UNLOADING_DETAILS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}


export function* sendStickeringSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_SEND_TO_STICKERING_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getStickeringInfoSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_STICKER_DETAILS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getReceivingInProgressBarcodeSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getReceivingInProgressBarcodeAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_RECEIVING_IN_PROGRESS_BARCODE_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getAssociatePoSaveSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getAssociatePoSaveAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_ASSOCIATEPO_SEND_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* saveReceivingInProgressSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.saveReceivingInProgressAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_SAVE_RECEIVING_IN_PROGRESS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getReceivingInProgressViewSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getReceivingInProgressViewAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_RECEIVING_IN_PROGRESS_VIEW_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getCaptureInvoiceDetailListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getCaptureInvoiceDetailsListAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_CAPTURE_INVOICE_DETAIL_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* completeStickeringSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_COMPLETE_STICKERING_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getSendSanInfoData(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getSendSanInfo, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_SEND_SAN_INFO_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getGRNInfoSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getGRNAxios, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GRN_INFO_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getPutAwayDetailsSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_PUTAWAY_DEATAILS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getSkuSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getSkuAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_SKU_SUCCESS,
                result: result?.result?.data?.body,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getConditionSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getConditionAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_CONDITION_SUCCESS,
                result: result?.result?.data?.body,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getSaveSkuSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getConditionAxio, action.payload);
        let msg = result?.result?.data?.msg;
        if (msg == "SKU added successfully" || msg == "SKU updated successfully") {
            message.success(result?.result?.data?.msg);
        }
        else {
            message.error(result?.result?.data?.msg);
        }
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_SAVE_SKU_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getMetaListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_META_DATA_SUCCESS,
                result: result?.result?.data?.body,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getMoveToBinMetaListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_MOVE_TO_BIN_META_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}


export function* addInvoiceSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result?.result?.data?.msg) {
            message.error(result?.result?.data?.msg);
        }
        else if (result.status == 1) {
            yield put({
                type: ApiConstants.API_ADD_INVOICE_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getCaptureInvoiceSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_CAPTURE_INVOICE_DETAILS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getCaptureSKUListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_CAPTURE_SKU_DETAILS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* captureSKUWiseInvoiceSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CAPTURE_SKU_WISE_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* sendSanSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.sendSanAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_SEND_SAN_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        console.log("failed")
        yield call(errorSaga, error)
    }
}
export function* getGeneratePutawaySaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getGeneratePutawayAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_GENERATE_PUTAWAY_INFO_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getAssignMoverSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getAssignMoverAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_ASSIGN_MOVER_INFO_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getBarCodeScanSuccessSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getBarCodeScanSuccessAxio, action.payload);
        if (result.status == 1) {
            if (result?.result?.data?.msg == 'Box Scanned Successfully !') {
                message.success(result?.result?.data?.msg)
            }
            else {
                message.error('Wrong box barcode is scanned')
            }
            yield put({
                type: ApiConstants.API_GET_BAR_CODE_SCAN_SUCCESS_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                msg: result?.result?.data?.msg,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}




export function* completeReceiveInProgressSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.completeReceiveInProgressAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_COMPLETE_RECEIVE_IN_PROGRESS_SECCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getStartPutawaySaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getStartPutawayAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_START_PUTAWAY_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getWarehouseSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_WAREHOUSES_ACTION_SUCCESS,
                result: result?.result?.data?.rawData?.WHs,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getClientDataSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getClientAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_CLIENT_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getSKUDataSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getSKUAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_SKU_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getInventoryDataSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getInventoryAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_INVENTORY_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}


export function* deleteReceieveInProgressSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_DELETE_RECEIVE_INPROGRESS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* assignMoverSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_ASSIGN_MOVER_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getCustomersSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getCustomersAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_CUSTOMER_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getPOSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getPOAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_PO_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getSKUDetailsSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getSKUDetailsAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_SKU_DETAILS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getSKUandPoSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getSKUandPoAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_SKU_AND_PO_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* createPoSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.createPoAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CREATE_PO_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* addSKUforPoSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.addSKUforPoAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_ADD_SKU_FOR_PO_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* editSKUforPoSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.editSKUforPoAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_EDIT_SKU_FOR_PO_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getSKUBAtchDetailsSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);

        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_SKU_BATCH_DETAILS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* addSKUBAtchDetailsSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);

        if (result.status == 1) {
            if (result?.result?.data?.msg == "Attributes added successfully" || result?.result?.data?.msg == 'SKU added successfully') {
                message.success(result?.result?.data?.msg);
            }
            else {
                message.error(result?.result?.data?.msg);
            }
            yield put({
                type: ApiConstants.API_ADD_SKU_BATCH_DETAILS_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* editSKUBAtchDetailsSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);

        if (result.status == 1) {
            if (result?.result?.data?.msg == 'SKU updated successfully') {
                message.success(result?.result?.data?.msg);
            }
            else {
                message.error(result?.result?.data?.msg);
            }
            yield put({
                type: ApiConstants.API_EDIT_SKU_BATCH_DETAILS_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getSkuDescSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_SKU_DESC_SUCCESS,
                result: result?.result?.data?.body,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* updatePoSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.updatePoAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_UPDATE_PO_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* deleteSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            let msg = result?.result?.data?.msg
            if (msg == "Removed SKU from the Box successfully" || msg == "SKU deleted successfully") {
                message.success(result?.result?.data?.msg);
            }
            else {
                message.error(result?.result?.data?.msg);
            }
            yield put({
                type: ApiConstants.API_DELETE_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getConsignmentSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getConsignmentAxios, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CONSIGNMENT_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getClientSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getClientAxios, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_CLIENT_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getLeftMenuSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getLeftMenuAxios, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_LEFT_MENU_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getAdhocClientData(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getAdhocClientAxios, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_ADHOC_CLIENT_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getAdhocSaveData(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getAdhocSaveAxios, action.payload);
        if (result?.result?.data?.msg == 'New Expense added successfully') {
            message.success(result?.result?.data?.msg)
        }
        else {
            message.error(result?.result?.data?.msg)
        }
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_ADHOC_SAVE_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getAdhocExpenseData(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getAdhocExpenseDataAxios, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_ADHOC_EXPENSE_NO_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getAdhocExpandData(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getAdhocExpandDataAxios, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_ADHOC_EXPAND_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getAdhocUpdateData(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getAdhocUpdateDataAxios, action.payload);
        if (result?.result?.data?.msg == 'Expense Updated successfully') {
            message.success(result?.result?.data?.msg)
        }
        else {
            message.error(result?.result?.data?.msg)
        }
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_UPDATE_ADHOC_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getChannelStatusSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_CHANNEL_STATUS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getChannelDetailsSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_CHANNEL_DETAILS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getPickersSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_PICKER_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getSKUSubordersSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_SKU_SUBORDER_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}


export function* generatePicklistSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            if (result?.result?.data?.msg == 'Picklist generated successfully') {
                message.success(result?.result?.data?.msg);
            }
            else {
                message.error(result?.result?.data?.msg);
            }
            yield put({
                type: ApiConstants.API_GENERATE_PICKLIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* pickingCompleteSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            if (result?.result?.data?.msg == "Pick Completed Successfully") {
                message.success(result?.result?.data?.msg);
            }
            else {
                message.error(result?.result?.data?.msg);
            }
            yield put({
                type: ApiConstants.API_PICKING_COMPLETE_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* scanPickedSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_SCAN_PICKED_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* scanSKUSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.scanSKU, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_SCAN_SKU_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* popupScanSKUSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.popupScanSKUAxios, action.payload);
        if (result?.result?.data?.msg == "SKU Scanned Successfully") {
            message.success(result?.result?.data?.msg);
        }
        else {
            message.error(result?.result?.data?.msg);
        }
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_POPUP_SCAN_SKU_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* completePackingSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.completePackingAxios, action.payload);
        if (result?.result?.data?.msg == "Order Moved To RTS successfully") {
            message.success(result?.result?.data?.msg);
        }
        else {
            message.error(result?.result?.data?.msg);
        }
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_COMPLETE_PACKING_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getCourierServiceSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getCourierServiceAxios, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_COURIER_SERVICE_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getShowOrdersSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getShowOrdersAxios, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_SHOW_ORDERS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* createManifestSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.createManifestAxios, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CREATE_MANIFEST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* validateBoxCountSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_VALIDATE_GATEOUT_BOX_COUNT_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* shipmentOTPSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_SHIPMENT_OTP_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* handOverManifestSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_HAND_OVER_MANIFEST_ACTION_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* continuePutawaySaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.continuePutawayAxios, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_CONTINUE_PUTAWAY_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getSalesClientSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_SALES_ORDER_CLIENT_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getDetailsForSOSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_DETAILS_FOR_SO_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getSalesOrdersTypesSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_SALES_ORDERS_TYPE_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* createSOSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CREATE_SO_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getStockMovementCustomerListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getStockMovementCustomerListAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_CUSTOMER_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getStockMovementSKUListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getStockMovementSKUListAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_SKU_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getStockMovementLocationQtySaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getStockMovementLocationQtyAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_LOCATION_QTY_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getStockMovementLocationDataSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getStockMovementLocationDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_LOCATION_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}


export function* getStockMovementDataSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getStockMovementDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_STOCK_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getStockMovementUpdateAttrSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getStockMovementUpdateAttrAxio, action.payload);
        if (result?.result?.data?.msg == "Attribute updated successfully!") {
            message.success(result?.result?.data?.msg)
        } else {
            message.error(result?.result?.data?.msg)
        }
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_UPDATE_ATTRIBUTE_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}



export function* getSalesOrderDetailsSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_SALES_ORDER_STATUS_DETAILS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* lockAndProcessSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_LOCK_AND_PROCESS_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* cancelSOSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CANCEL_SALES_ORDER_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* searchPOSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.searchPOAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_SEARCH_PURCHASE_ORDER_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* whmConfigurationSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.whmConfigurationAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_WM_CONFIGURE_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* whmAddSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.whmAddAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_WM_ADD_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* whmEditSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.whmEditAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_WM_EDIT_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}


export function* getClientListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_CATEGORY_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* createCategorySaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CREATE_CATEGORY_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* configureSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.configureAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CONFIGURE_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getViewConfigureModalSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getViewConfigureModalAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_VIEW_CONFIGURE_MODAL_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}



export function* getFormMetaSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_FORM_DROPDOWN_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getAdjustInventorySaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getAdjustInventoryAxio, action.payload);
        if (result?.result?.data?.msg === "Quantity Adjusted Successfully!") {
            message.success(result?.result?.data?.msg)
        } else {
            message.error(result?.result?.data?.msg)
        }
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_MOVE_ADJUST_INVENTORY_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getQuarantineInventorySaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getQuarantineInventoryAxio, action.payload);
        if (result?.result?.data?.msg === "Quantity Quarantine Successfully!") {
            message.success(result?.result?.data?.msg)
        } else {
            message.error(result?.result?.data?.msg)
        }
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_MOVE_QUARANTINE_INVENTORY_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getSKULocationSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getSKULocationAxios, action.payload);
        if (result?.result?.data?.msg === "Location changed successfully!") {
            message.success(result?.result?.data?.msg)
        } else {
            message.error(result?.result?.data?.msg)
        }
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CHANGE_SKU_LOCATION_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* changeBinBoxLocationSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.changeBinBoxLocationAxios, action.payload);
        if (result?.result?.data?.msg === "Location changed successfully!") {
            message.success(result?.result?.data?.msg)
        } else {
            message.error(result?.result?.data?.msg)
        }
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CHANGE_BIN_BOX_LOCATION_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}




export function* configureWarehouseSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CONFIGURE_WAREHOUSE_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getOnboardMenuSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_DASHBOARD_MENU_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getOnboardingEditDataSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getOnboardingEditDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_ON_BOARDING_EDIT_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}


export function* uploadFileSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_UPLOAD_FILE_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getStorageDetailsSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_VIEW_STORAGE_DETAIL_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getClientDedicatedLocationSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_CLIENT_DEDICATED_LOCATION_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* goLiveSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GO_LIVE_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getFilesSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_GET_FILES_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* setattachemntAsDefaultSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            if (result?.result?.data?.statusCode == 200) {
                message.success(result?.result?.data?.msg)
            }
            else {
                message.error(result?.result?.data?.msg)
            }
            yield put({
                type: ApiConstants.API_SET_ATTACHMENT_AS_DEFUALT_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getReportListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg ? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_REPORT_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getDateReportListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg ? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_DATE_REPORT_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getGateInReportListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_GATEIN_REPORT_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getExpandGateInReportListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_EXPAND_GATEIN_REPORT_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getSearchGateInReportListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_SEARCH_GATEIN_REPORT_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getSANReportListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_SAN_REPORT_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getExpandSANReportListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_EXPAND_SAN_REPORT_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getGRNReportListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_GRN_REPORT_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getExpandGRNReportListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_EXPAND_GRN_REPORT_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* getGAEReportListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_GAE_REPORT_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getExpandGAEReportListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_EXPAND_GAE_REPORT_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getExpandCartonListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_EXPAND_CARTON_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getCartonListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_EXPAND_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getReQCListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_CARTON_REQC_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* closeCartonSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            if (result?.result?.data?.msg == "Carton Created Successfully") {
                message.success(result?.result?.data?.msg)
            }
            else {
                message.error(result?.result?.data?.msg)
            }
            yield put({
                type: ApiConstants.API_CLOSE_CARTON_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}


export function* deleteCartonSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            if(result?.result?.data?.msg  ==="Carton Deleted Successfully"){
                message.success(result?.result?.data?.msg)
            }
            else{
                message.error(result?.result?.data?.msg)
            }
            yield put({
                type: ApiConstants.API_DELETE_CARTON_SUCCESS,
                result: result?.result?.data?.msg,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}


export function* printSpecificSticterSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_PRINT_SPECIFIC_CARTON_STICKER_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}


export function* printAllSticterSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_PRINT_ALL_CARTON_STICKER_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* printSpecificPackingSlipSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_PRINT_SPECIFIC_CARTON_PACKING_SLIP_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}


export function* printAllPackingSlipSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_PRINT_ALL_CARTON_PACKING_SLIP_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getScanSKUSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            let msg = result?.result?.data?.msg;
            if (msg == "Scanned SKU added to Carton successfully" || msg ==  "Updated the Inventory Count Successfully") {
                message.success(result?.result?.data?.msg)
            }
            else {
                message.error(result?.result?.data?.msg)
            }
            yield put({
                type: ApiConstants.API_SCAN_CARTON_SKU_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}


export function* getDimensionSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_DIMENSION_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* updateDimensionSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            if (result?.result?.data?.msg == "Dimension Updated successfully") {
                message.success(result?.result?.data?.msg)
            }
            else {
                message.error(result?.result?.data?.msg)
            }
            yield put({
                type: ApiConstants.API_UPDATE_DIMENSION_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* moveToRTOSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            if(result?.result?.data?.msg == "Sales Order Moved to RTS Successfully"){
                message.success(result?.result?.data?.msg)
            }
            else{
                message.error(result?.result?.data?.msg)
            }
            yield put({
                type: ApiConstants.API_MOVE_TO_RTO_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}



export function* getODReportListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_OD_REPORT_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getExpandODReportListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            // if(result?.result?.data?.statusCode == 200){
            //     message.success(result?.result?.data?.msg? result?.result?.data?.msg: "success")
            // }
            // else{
            //     message.error(result?.result?.data?.msg)
            // }
            yield put({
                type: ApiConstants.API_GET_EXPAND_OD_REPORT_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* workOnThisSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_WORK_ON_THIS_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* saveReQcSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_REQC_SAVE_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}



export function* cycleCountMasterDataSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CYCLE_COUNT_MASTER_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* cycleCountExpandDataSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CYCLE_COUNT_EXPAND_DATA_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}



export function* cycleCountScanSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CYCLE_COUNT_SCANNING_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getStorageSkuListSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_LIST_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* closeCycleCountSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            if(result?.result?.data?.msg === "Cycle Count Closed Successfully"){
                message.success(result?.result?.data?.msg)
            }
            else{
                message.error(result?.result?.data?.msg)
            }
            yield put({
                type: ApiConstants.API_CLOSE_CYCLE_COUNT_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* ApproveRejectCycleCountSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            if(result?.result?.data?.msg === "Approved Successfully" || result?.result?.data?.msg === "Rejected Successfully"){
                message.success(result?.result?.data?.msg)
            }
            else{
                message.error(result?.result?.data?.msg)
            }
            yield put({
                type: ApiConstants.API_CLOSE_CYCLE_APPROVE_REJECT_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* getStorageSkuSelectSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_SELECT_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}



export function* getStorageSkuQTYUpdateSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);

        if (result.status == 1) {
            if(result?.result?.data?.msg === "Updated the Virtual Inventory Count Successfully"){
                message.success(result?.result?.data?.msg)
            }
            else{
                message.error(result?.result?.data?.msg)
            }
            yield put({
                type: ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_QTY_UPDATE_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}


export function* startCycleCountSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_START_CYCLE_COUNT_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* createClientSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CREATE_CLIENT_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}
export function* editClientSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_EDIT_CLIENT_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}


export function* getClientDetailsSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CLIENT_INFO_SUCCESS,
                result: result?.result?.data?.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}



export function* createCONTRACTSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            yield put({
                type: ApiConstants.API_CREATE_CONTRACT_SUCCESS,
                result: result?.result?.data,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}

export function* startOnBoardingSaga(action: any): SagaIterator {
    try {
        const result = yield call(Axios.getDataAxio, action.payload);
        if (result.status == 1) {
            if(result?.result?.data?.msg === "Client Onboarding Started Successfully"){
                message.success(result?.result?.data?.msg)
            }
            else{
                message.error(result?.result?.data?.msg)
            }
            yield put({
                type: ApiConstants.API_START_ONBOARDING_SUCCESS,
                result: result?.result?.data.rawData,
                status: result.status,
                payload: action?.payload
            })
        }
        else {
            yield call(failSaga, result)
        }
    } catch (error) {
        yield call(errorSaga, error)
    }
}



