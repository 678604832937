import React from 'react';
import { getUserInfo } from '../../Utils/SessionStorage';
import CycleCount from './CycleCount';
import WarehouseManagerCycleCount from './WarehouseManagerCycleCount';

function CycleCountIndex(props: any) {
    return (
        <>
            {getUserInfo()?.roleId == "2" ?
                <CycleCount />
                :
                <WarehouseManagerCycleCount />
            }
        </>
    );
}

export default CycleCountIndex;