import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Input, Row, Table } from 'antd';
import React, { useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import File from '../../Images/File';
import { getDashboardMenuId } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';

const suffix = (

    <SearchOutlined
        style={{
            fontSize: 20,
            color: 'black',
        }}
    />
);

function GeneratePutAwayListing() {
    const [value, individualStageData]: any = useOutletContext();
    const [openDrawer, setOpenDrawer] = useState<any>();

    const drawerView = () => {
        try {
            let [, , warehouseId] = (openDrawer?.data?.id?.split("|") || []);//to get id from third index
            let drawerData = individualStageData?.header[0];
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                {warehouseId}
                            </div>
                            <Button onClick={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>
                        </div>
                    }
                    onClose={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                    open={openDrawer?.isOPen}>
                    <Row>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                {AppConstants.client}
                            </div>
                            <div className='value-field'>
                                {drawerData?.ClientID}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Transaction Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.InwardTxnTypeID}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.VehicleTypeId}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.VehicleNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Seal Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.SealNo}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Docket Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.DocketNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Dock Station
                            </div>
                            <div className='value-field'>
                                {drawerData?.DockStationID}
                            </div>
                        </Col>
                    </Row>
                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }

    }


    const columns: any = [
        {
            title: AppConstants.invoiceNum,
            dataIndex: "show",
            width: "20%",
            align: "left",
            render: (item: any) => {
                let invoiceNumber = (item?.split(",") || []);
                return (
                    <div className='text'>
                        {invoiceNumber[3]}
                    </div>
                )
            }
        },
        {
            title: "Client",
            dataIndex: 'name',
            width: "30%",
            align: "left",
            render: (item: any) => {
                return (
                    <div className='client-text'>
                        <span title={item} className='overflow-tagline'> {item}</span>
                    </div>
                )
            }
        },
        {
            title: "PO Number",
            dataIndex: "show",
            align: "left",
            width: "15%",
            render: (item: any) => {

                let pO = (item?.split(",") || []);

                return (
                    <div className='text'>
                        {pO[1]}
                    </div>
                )
            },
        },
        {
            title: "Release Number",
            dataIndex: "show",
            align: "center",
            width: "15%",
            render: (item: any) => {
                let releaseNum = (item?.split(",") || [])
                return (
                    <div className='text'>
                        {releaseNum[2]}
                    </div>
                );
            },
        },
        {
            title: "Action",
            dataIndex: "",
            align: "center",
            width: "20%",
            render: (item: any) => {
                return (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Link to="/put-away-view" state={{ item: item }}>
                            <AppButtons
                                buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                text="Create Putaway" />
                        </Link>
                    </div>
                )
            },
        },
    ];


    const listingView = () => {
        try {
            return (
                <div className='table-screen-container'>
                    <Table
                        columns={columns}
                        dataSource={individualStageData?.header}
                        pagination={false}
                    />
                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    return (
        <div className='sendsan-main-container'>
            <SubHeader
                title={getDashboardMenuId() == 540200 ? "Assignment Pending" : "Create Putaway Task"}
                inputView="true"
                paginationView="false"
                btnView="false">
            </SubHeader>
            {listingView()}
            {drawerView()}
        </div>
    );
}

export default GeneratePutAwayListing;