import { CaretRightOutlined, CheckOutlined, CloseOutlined, DeliveredProcedureOutlined, SaveFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Modal, Row, Select, Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import AppReferences from '../../Globals/AppReferences';
import { isArrayEmpty, isArrayNotEmpty } from '../../Globals/Helper';
import CartonBoxIcon from '../../Images/CartonBoxIcon';
import CloseCartonIcon from '../../Images/CloseCartonIcon';
import PackingIcon from '../../Images/PackingIcon';
import PackingListIcon from '../../Images/PackingListIcon';
import ReQcIcon from '../../Images/ReQcIcon';
import ScanBarIcon from '../../Images/ScanBarIcon';
import { CartonExpandDataAction, closeCartonAction, deleteCartonAction, ExpandDataAction, getDimensionAction, MoveToRtoAction, PrintAllCartonPackinglipAction, PrintSpecificCartonPackinglipAction, PrintSpecificCartonStickerAction, ReQCDataAction, saveReQCAction, scan0rEnterToPackAction, scanOnPackingSkuAction, updateDimensionAction } from '../../Store/Actions/ProcessWMSAction';
import { getAdhocExpandData } from '../../Store/Saga/ProcessSaga';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import SpinLoader from '../SpinLoader';
import CartonningPackingListModal from './CartonningPackingListModal';
import CartonningStickeringModal from './CartonningStickeringModal';
import SkeletonLoader from './SkeletonLoader';

let columns: any = ["SKU", "Order Qty", "Picked Qty", "ReQc Count", "ReQC", 'To Pack', 'Packed Qty'];

function CartonningScreen() {
    const dispatch = useDispatch();
    const [form]: any = Form.useForm();
    const [reqcForm]: any = Form.useForm();
    const reducerstate = useSelector((state: any) => state?.ProcessReducerState);
    const { statusDetailsOnload,
        cartonExpandList,
        expandListForCarton,
        dimensionOnload,
        dimensionList,
        dimensionUpdateOnload,
        reQCOnLoad,
        reQCList,
        specificStickerOnLoad,
        allStickerOnLoad,
        specificPackingSlipOnLoad,
        allPackingSlipOnLoad,
        closeCartonOnLoad,
        PackingSlip,
        cartonStickeringList,
        deleteCartonOnLoad,
        deleteMsg,
        cartonOnload,
        moveToRtoOnload,
        completeCartonData,
        reqcResponse
    } = reducerstate;
    const [expandRow, setExpandRow] = useState<any>();
    const [expandCartonNumber, setExpandCartonNumber] = useState<any>();
    const [expandCartonRow, setExpandCartonRow] = useState<any>();
    const [openModal, setOpenModal] = useState<any>(false);
    const [scanModal, setScanModal] = useState<any>(false);
    const [viewPackingListModal, setPackingListModal] = useState<any>(false);
    const [cartonBoxViewModal, setCartonBoxViewModal] = useState<any>(false);
    const [locationData, setLocationData] = useState<any>({ length: "", width: "", height: "", weight: "" })
    const [viewAction, setViewAction] = useState<any>(false)
    const [expandSODetails, setExpandSODetails] = useState<any>()
    const [SKUTOPACKINPUTArr, setSKUTOPACKINPUTArr] = useState<any>([])
    const [cartonListArr, setCartonListArr] = useState<any>([])
    const [dimentionCartonNumber, setDimentionCartonNumber] = useState<any>()
    const [expandCartonNo, setExpandedCartonNo] = useState<any>()
    const [
        statusDetails,
        channel, tatClient,
        clientValue, process,
        getStatusDetails,
        getChannelStatus,
        setProcess,
        getChannelsApi,
    ]: any = useOutletContext();
    const [onLoad, setOnLoad] = useState<any>({})
    const [reqcDetails, setReqcDetails] = useState<any>([]);
    const [soid, setSoid] = useState<any>('');

    useEffect(() => {
        if (onLoad?.completeCartonOnLoad === true && !moveToRtoOnload) {

            if (completeCartonData?.msg === 'Sales Order Moved to RTS Successfully') {
                getChannelsApi();
                getExpandData(expandSODetails, "N")
            }
        }

    }, [onLoad?.completeCartonOnLoad, moveToRtoOnload]);

    useEffect(() => {
        if (onLoad?.reqcOnload === true && !reducerstate.onload) {
            setOnLoad({ ...onLoad, reqcOnload: false });
            setReqcDetails([]);
            if (reqcResponse.msg == "Updated the ReQC Count successfully") {
                message.success(reqcResponse.msg);
                ReQC(soid);
            }
            else {
                message.error(reqcResponse.msg);
            }
        }
    }, [onLoad?.reqcOnload, reducerstate.onload]);


    const toCreateCarton = () => {
        if (cartonExpandList?.lineitemstwo?.length > 0) {
            let CustNo: any
            const cartonNO = cartonExpandList?.lineitemstwo[cartonExpandList?.lineitemstwo.length - 1]
            if (cartonNO?.CartonNumber) {
                let findLast3Num = Number(cartonNO?.CartonNumber.slice(-3)) + 1
                let findLength = findLast3Num.toString().length
                if (findLength == 1) {
                    CustNo = `C00${findLast3Num}`
                } else if (findLength == 2) {
                    CustNo = `C0${findLast3Num}`
                } else {
                    CustNo = `C${findLast3Num}`
                }
            }
            let cartonNumber = `${expandSODetails?.RawSOID}-R${expandSODetails?.ReleaseNumber}-${CustNo}`
            let cartonObj = {
                "CartonNumber": cartonNumber,
                "IsDimensionUpdated": "N"
            }
            setCartonListArr([...cartonExpandList?.lineitemstwo, cartonObj])
        } else {
            let cartonNumber = `${expandSODetails?.RawSOID}-R${expandSODetails?.ReleaseNumber}-C001`
            let cartonObj = {
                "CartonNumber": cartonNumber,
                "IsDimensionUpdated": "N"
            }
            setCartonListArr([...cartonListArr, cartonObj])
        }
        setViewAction(false)
    }

    useEffect(() => {
        if (onLoad?.expandOnLoad === true && !cartonOnload) {
            setCartonListArr(cartonExpandList?.lineitemstwo);
            if (!checkAllSKuAddedTOCarton()) {
                toCreateCarton()
            }
        }
    }, [onLoad?.expandOnLoad, cartonOnload])

    useEffect(() => {
        if (onLoad?.deleteOnLoad === true && !deleteCartonOnLoad) {
            if (deleteMsg === "Carton Deleted Successfully") {
                // let filterCartonListArr = cartonListArr?.filter((x: any) => {
                //     return !cartonListArr.includes(onLoad?.cartonNumber)
                // })
                // setCartonListArr(filterCartonListArr)
                getExpandData(expandSODetails, "N")
            }
        }
    }, [onLoad?.deleteOnLoad, deleteCartonOnLoad])

    useEffect(() => {
        if (onLoad?.printSticker === true && !specificPackingSlipOnLoad) {
            setScanModal(true)
        }
    }, [onLoad?.printSticker, specificStickerOnLoad])

    useEffect(() => {
        if (onLoad?.printSticker === true && !allStickerOnLoad) {
            setScanModal(true)
        }
    }, [onLoad?.printSticker, allStickerOnLoad])

    useEffect(() => {
        if (onLoad?.packingSlip === true && !specificPackingSlipOnLoad) {
            setPackingListModal(true)
        }

    }, [onLoad?.packingSlip, specificPackingSlipOnLoad])

    useEffect(() => {
        if (onLoad?.packingSlip === true && !allPackingSlipOnLoad) {
            setPackingListModal(true)
        }

    }, [onLoad?.packingSlip, allPackingSlipOnLoad])


    useEffect(() => {
        if (onLoad?.reQCOnLoad === true && !reducerstate.onload) {
            setOnLoad({ ...onLoad, reQCOnLoad: false });
            setOpenModal(true)
        }

    }, [onLoad?.reQCOnLoad, reducerstate.onload])

    useEffect(() => {
        if (onLoad?.closeCartonOnLoad === true && !closeCartonOnLoad) {
            getExpandData(expandSODetails, "Y");
            setSKUTOPACKINPUTArr([]);
        }
    }, [onLoad?.closeCartonOnLoad, closeCartonOnLoad])

    useEffect(() => {
        if (onLoad?.getDimentionOnLoad === true && !dimensionOnload) {

            form.setFieldsValue({
                ['length']: isArrayNotEmpty(dimensionList?.lineitems) ? dimensionList?.lineitems[0]?.Length : 0,
                ['height']: isArrayNotEmpty(dimensionList?.lineitems) ? dimensionList?.lineitems[0]?.Height : 0,
                ['width']: isArrayNotEmpty(dimensionList?.lineitems) ? dimensionList?.lineitems[0]?.Width : 0,
                ['weight']: isArrayNotEmpty(dimensionList?.lineitems) ? dimensionList?.lineitems[0]?.Weight : 0,
            });
            setLocationData(
                {
                    ...locationData,
                    length: isArrayNotEmpty(dimensionList?.lineitems) ? dimensionList?.lineitems[0]?.Length : 0,
                    width: isArrayNotEmpty(dimensionList?.lineitems) ? dimensionList?.lineitems[0]?.Width : 0,
                    height: isArrayNotEmpty(dimensionList?.lineitems) ? dimensionList?.lineitems[0]?.Height : 0,
                    weight: isArrayNotEmpty(dimensionList?.lineitems) ? dimensionList?.lineitems[0]?.Weight : 0
                }
            )

            setCartonBoxViewModal(true)

        }
    }, [onLoad?.getDimentionOnLoad, dimensionOnload])

    useEffect(() => {
        if (onLoad?.updateDimentionOnLoad === true && !dimensionUpdateOnload) {
            setCartonBoxViewModal(false)

            form.setFieldsValue({
                ['length']: 0,
                ['height']: 0,
                ['width']: 0,
                ['weight']: 0,
            });
            setLocationData(
                {
                    ...locationData,
                    length: 0,
                    width: 0,
                    height: 0,
                    weight: 0
                }
            )
            getExpandData(expandSODetails, "N")
        }
    }, [onLoad?.updateDimentionOnLoad, dimensionUpdateOnload])

    const MoveToRTO = (item: any) => {
        let Arr = (cartonExpandList?.lineitems || []).map((x: any) => {
            let obj = {
                "SOReleaseRecordNumber": x?.SOReleaseRecordNo,
            }
            return obj
        })
        let transaction = Arr.map((data: any) => data?.SOReleaseRecordNumber).join('|')
        let payload = {
            "opnfor": AppReferences.opnfor.salesOrder,
            "activity": "A-39",
            "transaction": Arr?.length == 1 ? `${Arr[0]?.SOReleaseRecordNumber}` : `${transaction}`,
            "where": `${cartonExpandList?.lineitems.length}`,
            "user": getUserInfo()?.UserName
        }
        dispatch(MoveToRtoAction(payload))
        setOnLoad({ ...onLoad, completeCartonOnLoad: true })

    }

    const getExpandData = (data: any, flag: any) => {
        let payload = {
            "opnfor": AppReferences.opnfor.salesOrder,
            "activity": "A-28",
            "transaction": data?.RawSOID,
            customer: clientValue,
            warehouse: getWareHouseId(),
            "user": getUserInfo()?.UserName
        }
        dispatch(CartonExpandDataAction(payload))
        flag === "Y" && setOnLoad({ ...onLoad, expandOnLoad: true })

    }

    const getExpandCartonData = (cartonNumber: any) => {
        let payload = {
            "opnfor": AppReferences.opnfor.salesOrder,
            "activity": "A-30",
            "transaction": `${cartonNumber}`,
            customer: clientValue,
            "user": getUserInfo()?.UserName
        }
        dispatch(ExpandDataAction(payload))
    }

    const getDimention = (cartonNumber: any) => {
        let payload = {
            "opnfor": AppReferences.opnfor.salesOrder,
            "activity": "A-31",
            "transaction": `${cartonNumber}`,
            "user": getUserInfo()?.UserName
        }
        dispatch(getDimensionAction(payload))
        setDimentionCartonNumber(cartonNumber)
        setOnLoad({ ...onLoad, getDimentionOnLoad: true })
    }

    const updateDimension = (cartonNumber: any) => {
        let payload = {
            "opnfor": AppReferences.opnfor.salesOrder,
            "activity": "A-32",
            "transaction": `CartonNumber='${cartonNumber}'`,
            "where": `Length='${locationData?.length}',Width='${locationData?.width}',Height='${locationData?.height}',Weight='${locationData?.weight}',ModifiedBy='${getUserInfo()?.UserName}',ModifiedDate=now()`,
            "user": getUserInfo()?.UserName,
            "tableNo": "94"
        }
        dispatch(updateDimensionAction(payload))
        setOnLoad({ ...onLoad, updateDimentionOnLoad: true })
    }

    const ReQC = (SOID: any) => {
        setSoid(SOID);
        let payload = {
            "opnfor": AppReferences.opnfor.salesOrder,
            "activity": "A-37",
            "transaction": SOID,
            customer: clientValue,
            "user": getUserInfo()?.UserName
        }
        dispatch(ReQCDataAction(payload))
        setOnLoad({ ...onLoad, reQCOnLoad: true })
    }

    const callDeleteService = (cartonNumber: any) => {
        let Arr = (expandListForCarton?.lineitems || []).map((x: any) => {
            let obj = {
                "SOReleaseRecordNo": x?.SOReleaseRecordNumber,
                "Qty": x?.Quantity
            }
            return obj
        })
        let transaction = Arr?.map((item: any) => {
            let data = `${item?.SOReleaseRecordNo}|${item?.Qty}`
            return data
        })
        let combinedData = transaction.map((data: any) => data).join(',')
        let payload = {
            "opnfor": AppReferences.opnfor.salesOrder,
            "activity": "A-36",
            "transaction": Arr?.length == 1 ? `${transaction[0]}` : `${combinedData}`,
            "where": `${cartonNumber}`,
            "fieldArray": `${expandListForCarton?.lineitems?.length}`,
            "user": getUserInfo()?.UserName
        }
        dispatch(deleteCartonAction(payload))
        setOnLoad({ ...onLoad, deleteOnLoad: true, cartonNumber: cartonNumber })
    }

    const deleteCarton = (item: any) => {
        callDeleteService(item)
    }

    const closeCarton = (CartonNumber: any) => {
        // let Arr = (isArrayNotEmpty(expandListForCarton?.lineitems) ? expandListForCarton?.lineitems : SKUTOPACKINPUTArr || []).map((x: any) => {
        //     let obj = {
        //         "SOReleaseRecordNo": x?.SOReleaseRecordNo,
        //         "Qty": x?.Quantity,
        //         "SKU": x?.SKU
        //     }
        //     return obj
        // })
        let warehouse = SKUTOPACKINPUTArr?.map((item: any) => {
            let data = `${item?.SOReleaseRecordNo}|${item?.Quantity}`
            return data
        })
        setExpandCartonRow(-1);
        let transaction = SKUTOPACKINPUTArr?.map((item: any) => {
            let data = `${item?.SKU}|${item?.Quantity}`
            return data
        })
        // let combinedSKUData = transaction?.map((data: any) => data).join(',');
        // let combainedData = warehouse?.map((data: any) => data).join(',');
        let payload = {
            "opnfor": AppReferences.opnfor.salesOrder,
            "activity": "A-29",
            "transaction": expandCartonNumber,
            "where": `${CartonNumber}`,
            "fieldArray": transaction,
            "tableNo": SKUTOPACKINPUTArr?.length,
            "warehouse": warehouse,
            "user": getUserInfo()?.UserName
        }
        dispatch(closeCartonAction(payload))
        setOnLoad({ ...onLoad, closeCartonOnLoad: true })
    }

    const printSticker = (SOID: any, cartonNumber: any, flag: any) => {
        let payload = {
            "opnfor": AppReferences.opnfor.salesOrder,
            "activity": "A-33",
            "transaction": SOID,
            "where": `${cartonNumber}`,
            "fieldArray": flag,
            "user": getUserInfo()?.UserName
        }
        dispatch(PrintSpecificCartonStickerAction(payload))
        setOnLoad({ ...onLoad, printSticker: true })
    }

    const printPackingSlip = (SOID: any, cartonNumber: any, flag: any) => {
        let payload = {
            "opnfor": AppReferences.opnfor.salesOrder,
            "activity": "A-35",
            "transaction": SOID,
            "where": `${cartonNumber}`,
            "fieldArray": flag,
            "user": getUserInfo()?.UserName
        }
        {
            flag == "N" ?
                dispatch(PrintSpecificCartonPackinglipAction(payload)) : dispatch(PrintAllCartonPackinglipAction(payload))
        }
        setOnLoad({ ...onLoad, packingSlip: true })
    }

    const saveReqc = () => {
        reqcForm.validateFields().then(() => {
            let transaction = "";
            reqcDetails?.map((x: any) => {
                if (transaction.length > 0) {
                    transaction += `,${x?.SOReleaseRecordNo}|${x?.qty}`
                } else {
                    transaction += `${x?.SOReleaseRecordNo}|${x?.qty}`
                }

            });

            let payload = {
                opnfor: AppReferences.opnfor.salesOrder,
                activity: "A-38",
                transaction: transaction,
                where: reQCList?.lineitems?.length,
                user: getUserInfo()?.UserName
            }
            dispatch(saveReQCAction(payload))
            setOnLoad({ ...onLoad, reqcOnload: true });
            reqcForm.resetFields();
        })
    }


    const scan0rEnterToPack = (input: any, data: any) => {
        let payload = {
            "opnfor": AppReferences.opnfor.salesOrder,
            "activity": "A-34",
            "transaction": input,
            "where": data?.RawSOID,
            "user": getUserInfo()?.UserName
        }
        dispatch(scanOnPackingSkuAction(payload))
        let sku = cartonExpandList?.lineitems?.find((x: any) => input == x?.SKU);
        generateCarton(1, sku, '');
    }

    const generateCarton = async (e: any, item: any, index: any) => {
        let checkExist = await SKUTOPACKINPUTArr?.find((x: any) => {
            if (x?.SOReleaseRecordNo == item?.SOReleaseRecordNo && x?.SKU == item?.SKU) {
                return x;
            }
        }
        )
        if (checkExist) {
            if (Number(e) + Number(checkExist?.Quantity) <= Number(item?.PiecesPicked) - Number(item?.PiecesPacked)) {
                checkExist.Quantity = Number(checkExist?.Quantity) + Number(e);
                setSKUTOPACKINPUTArr([...SKUTOPACKINPUTArr]);
            }
            else {
                message.error("To-Pack Qty excess than picked Qty");
            }
        }
        else {
            if (Number(e) <= (Number(item?.PiecesPicked) - Number(item?.PiecesPacked))) {
                let obj = {
                    SOReleaseRecordNo: item?.SOReleaseRecordNo,
                    SKU: item?.SKU,
                    Description: item?.Description,
                    Quantity: e
                }
                setSKUTOPACKINPUTArr([...SKUTOPACKINPUTArr, obj])
            }
            else {
                message.error("To-Pack Qty excess than picked Qty");
            }
        }
        form.resetFields()
    }

    const headerView = () => {
        return (
            <SubHeader
                title={"Cartoning"}
                paginationView="false"
                inputView="true"
                btnView="false"
                fromOrgin="orderProcessing"
            >
                <div className='d-flex flex-end'>
                    {/* <div className='reQc' style={{ marginRight: 16 }} onClick={() => setOpenModal(true)}>ReQC</div> */}
                    <div style={{ fontFamily: 'Redhat-Regular' }}><Input placeholder='Search By Order ID' prefix={<SearchOutlined />} /></div>
                </div>
            </SubHeader>
        )
    }




    const skutableHeaderView = () => {
        try {
            return (
                <Row className='table-header'>
                    <Col span={5} className=" title d-flex align-c">
                        {columns[0]}
                    </Col>
                    <Col span={5} className='title d-flex align-c jc-c'>
                        {columns[1]}
                    </Col>
                    <Col span={5} className='title d-flex align-c jc-c'>
                        {columns[2]}
                    </Col>
                    <Col span={4} className="title d-flex align-c jc-c">
                        {columns[5]}
                    </Col>
                    <Col span={5} className='title d-flex align-c jc-c'>
                        {columns[6]}
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in skutableHeaderView::" + ex)
        }
    }

    const skuBodyView = (item: any, data: any, index: any) => {
        try {
            let cartonAdded = SKUTOPACKINPUTArr?.find((x: any) => item?.SKU == x?.SKU);
            let isCartonClosed = (Number(cartonAdded?.Quantity) == (Number(item?.PiecesPicked) - Number(item?.PiecesPacked)) || Number(item?.PiecesPacked) >= Number(item?.PiecesPicked));
            return (
                <div className={"content-container"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={5} className="d-flex align-c">
                            <div className='text-1'>
                                {item?.SKU}
                            </div>
                        </Col>
                        <Col span={5} className='d-flex align-c jc-c'>
                            <div className='text-1'>
                                {item?.OrderedQuantity}
                            </div>
                        </Col>
                        <Col span={5} className='d-flex align-c jc-c'>
                            <div className='text-1'>
                                {item?.PiecesPicked}
                            </div>
                        </Col>
                        <Col span={4} className=" d-flex align-c jc-c">
                            {isCartonClosed ?
                                <div className='text-1 d-flex align-c jc-c'
                                    style={{ background: "#00819D", borderRadius: "50%", width: "25px", height: '25px', }}>
                                    <CheckOutlined style={{ color: 'white' }} />
                                </div>
                                :
                                <div className='text-1'>
                                    <Form.Item name={index + 'carton'}>
                                        <Input
                                            onPressEnter={(e: any) => generateCarton(e?.target?.value, item, index)}
                                            placeholder='Enter'
                                            className='input-field' />
                                    </Form.Item>
                                </div>}
                        </Col>
                        <Col span={5} className='d-flex align-c jc-c'>
                            <div className='text-1'>
                                {item?.PiecesPacked}
                            </div>
                        </Col>
                    </Row>
                </div >
            )
        } catch (ex) {
            console.log("Err in skuBodyView::" + ex)
        }
    }

    const setReqcInfo = (e: any, data: any) => {
        let exist = reqcDetails?.find((x: any) => (x?.SOReleaseRecordNo == data?.SOReleaseRecordNo && x?.SKU == data?.SKU));
        if (exist) {
            exist.qty = e.target.value;
            setReqcDetails([...reqcDetails])
        }
        else {
            let obj = {
                SOReleaseRecordNo: data?.SOReleaseRecordNo,
                qty: e.target.value,
                SKU: data?.SKU
            }
            setReqcDetails([...reqcDetails, obj])
        }

    }

    const modalSkuBodyView = (item: any, index: any) => {
        try {
            return (
                <div className={"content-container"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={5} className="d-flex align-c">
                            <div className='text-1'>
                                {item?.SKU}
                            </div>
                        </Col>
                        <Col span={5} className='d-flex align-c jc-c'>
                            <div className='text-1'>
                                {item?.OrderedQuantity}
                            </div>
                        </Col>
                        <Col span={5} className='d-flex align-c jc-c'>
                            <div className='text-1'>
                                {item?.PiecesPicked}
                            </div>
                        </Col>
                        <Col span={4} className='d-flex align-c jc-c'>
                            <div className='text-1'>
                                {item?.ReQCQty}
                            </div>
                        </Col>
                        <Col span={5} className='d-flex align-c jc-c'>
                            <div>
                                <Form.Item name={'reqcCount' + index}
                                    rules={[{
                                        validator(rule, value, callback) {
                                            if (value && (Number(value) > Number(item?.PiecesPicked))) {
                                                return Promise.reject("Enter Valid Count")
                                            }
                                            else {
                                                return Promise.resolve()
                                            }
                                        },
                                    }]}>
                                    <Input
                                        disabled={item?.ReQCQty == item?.PiecesPicked}
                                        placeholder='Enter'
                                        className='input-field'
                                        onChange={(e) => setReqcInfo(e, item)}
                                        style={{ textAlign: "center" }} />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </div >
            )
        } catch (ex) {
            console.log("Err in modalSkuBodyView::" + ex)
        }
    }

    const modalSkutableHeaderView = () => {
        try {
            return (
                <Row className='table-header'>
                    <Col span={5} className=" title d-flex align-c">
                        {columns[0]}
                    </Col>
                    <Col span={5} className='title d-flex align-c jc-c'>
                        {columns[1]}
                    </Col>
                    <Col span={5} className='title d-flex align-c jc-c'>
                        {columns[2]}
                    </Col>
                    <Col span={4} className='title d-flex align-c jc-c'>
                        {columns[3]}
                    </Col>
                    <Col span={5} className='title d-flex align-c jc-c'>
                        {columns[4]}
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in skutableHeaderView::" + ex)
        }
    }

    const listingView = (item: any) => {
        try {
            return (

                <div className='cartonning-table-container'>
                    {skutableHeaderView()}
                    <Form form={form}>
                        <div className='body-view'>{(cartonExpandList?.lineitems || []).map((x: any, index: any) => skuBodyView(x, item, index))}</div>
                    </Form>
                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const cartonBoxModal = () => {
        try {
            let cartonNumber = isArrayNotEmpty(dimensionList?.lineitems) ? `Enter dimension for ${dimensionList?.lineitems[0].CartonNumber}` : `Enter dimension for ${dimentionCartonNumber}`
            return (
                <Modal
                    title={
                        <div className='d-flex align-c jc-sb'>
                            <div className='redhat-bold mb-10 font-18'>
                                {cartonNumber}
                            </div>
                            <div className='d-flex align-c'>
                                <Button
                                    style={{ marginLeft: 16 }}
                                    onClick={() => setCartonBoxViewModal(false)}
                                    className="cross-btn">
                                    <CloseOutlined />
                                </Button>
                            </div>
                        </div>}
                    className='modal-view'
                    onCancel={() => setCartonBoxViewModal(false)}
                    // onOk={}
                    open={cartonBoxViewModal}
                    footer={null}
                >
                    <Form
                        onFinish={() => updateDimension(isArrayNotEmpty(dimensionList?.lineitems) ? dimensionList?.lineitems[0].CartonNumber : dimentionCartonNumber)}
                        form={form}
                        layout="vertical"
                    >
                        <div className='dimension-container' style={{ padding: 10 }}>
                            <Row gutter={16} style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <Form.Item
                                        name="length"
                                        label="Length"
                                        rules={[
                                            { required: true, message: 'Enter Length' },
                                            { pattern: /^[0-9]*$/, message: "Enter Valid Quantity" },
                                        ]}
                                    >
                                        <Input
                                            maxLength={3}
                                            className='input-field'
                                            placeholder="Enter"
                                            onChange={(e) => setLocationData({ ...locationData, length: e.target.value })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="width"
                                        label="Width"
                                        rules={[
                                            { required: true, message: 'Enter Width' },
                                            { pattern: /^[0-9]*$/, message: "Enter Valid Quantity" },
                                        ]}
                                    >
                                        <Input
                                            maxLength={3}
                                            className='input-field'
                                            placeholder="Enter"
                                            onChange={(e) => setLocationData({ ...locationData, width: e.target.value })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ marginTop: 10 }}>
                                <Col span={12}>
                                    <Form.Item
                                        name="height"
                                        label="Height"
                                        rules={[
                                            { required: true, message: "Enter Height" },
                                            { pattern: /^[0-9]*$/, message: "Enter Valid Quantity" },
                                        ]}
                                    >
                                        <Input
                                            maxLength={3}
                                            className='input-field'
                                            placeholder="Enter"
                                            onChange={(e) => setLocationData({ ...locationData, height: e.target.value })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="weight"
                                        label="Weight"
                                        rules={[
                                            { required: true, message: 'Enter Weight' },
                                            { pattern: /^[0-9]*$/, message: "Enter Valid Quantity" },
                                        ]}
                                    >
                                        <Input
                                            maxLength={3}
                                            className='input-field'
                                            placeholder="Enter"
                                            onChange={(e) => setLocationData({ ...locationData, weight: e.target.value })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, marginRight: 10, marginBottom: 20 }}>
                                <AppButtons
                                    block={true}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                    text={"Save"}
                                    htmlType={'submit'}
                                >
                                </AppButtons>
                            </div>
                        </div>
                    </Form>

                </Modal>
            )
        } catch (ex) {
            console.log("Err in cartonBoxModal")
        }
    }


    const ReQcModalView = () => {
        try {
            let combinedData = isArrayNotEmpty(SKUTOPACKINPUTArr) ? reQCList?.lineitems.map((item: any, i: any) => Object.assign({}, item, SKUTOPACKINPUTArr[i])) : reQCList?.lineitems
            return (
                <Modal
                    title={
                        <div className='d-flex align-c jc-sb'>
                            <div className='redhat-bold mb-10 font-18'>
                                ReQC
                            </div>
                            <div className='d-flex align-c'>
                                <Tooltip title="Save">
                                    <Button
                                        onClick={() => { isArrayNotEmpty(reqcDetails) && saveReqc() }}
                                        className='save-btn'
                                    >
                                        <SaveFilled />
                                    </Button>
                                </Tooltip>
                                <Button
                                    style={{ marginLeft: 10 }}
                                    onClick={() => setOpenModal(false)}
                                    className="cross-btn">
                                    <CloseOutlined />
                                </Button>
                            </div>
                        </div>}
                    className='modal-view'
                    onCancel={() => setOpenModal(false)}
                    // onOk={}
                    open={openModal}
                    footer={null}
                >
                    <div className='modal-Sku-table-container'>
                        {modalSkutableHeaderView()}
                        <Form form={reqcForm}>
                            <div className='body-view'>
                                {(combinedData || []).map((x: any, index: any) => modalSkuBodyView(x, index))}
                            </div>
                        </Form>
                    </div>
                </Modal>
            )
        } catch (ex) {
            console.log("Err in View Modal::" + ex)
        }
    }

    const cartontableHeaderView = (data: any) => {
        try {
            return (
                <Row className='table-header  align-c'>
                    <Col span={6} className="title">
                        {"Cartons"}
                    </Col>
                    <Col span={18} className=' d-flex flex-end'>
                        <div className='d-flex'>
                            <div className={isArrayNotEmpty(cartonExpandList?.lineitemstwo) ? 'white-box' : 'gray-box'} 
                            title='Packing List' 
                            onClick={() => isArrayNotEmpty(cartonExpandList?.lineitemstwo) && printPackingSlip(data?.RawSOID, "", "Y")} >
                                <PackingListIcon />
                            </div>
                            <div className={isArrayNotEmpty(cartonExpandList?.lineitemstwo) ? 'white-box' : 'gray-box'} 
                            title="Scan Bar" 
                            onClick={() => isArrayNotEmpty(cartonExpandList?.lineitemstwo) && printSticker(data?.RawSOID, "", "Y")}>
                                <ScanBarIcon />
                            </div>
                        </div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in cartontableHeaderView::" + ex)
        }
    }

    const handleCartonExpand = (e: any, index: any, item: any) => {
        e.stopPropagation();
        if (index == expandCartonRow) {
            setExpandCartonRow(-1)
        }
        else {
            setExpandCartonRow(index);
            setExpandedCartonNo(item?.CartonNumber)
            getExpandCartonData(item?.CartonNumber)
        }
    }

    const cartontableBodyView = (item: any, data: any, index: any) => {
        let LastItem = cartonListArr[cartonListArr.length - 1]
        let open = index == expandCartonRow;
        try {
            return (
                <div className={index === expandCartonRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={9} className="open-icon d-flex align-c" onClick={(e: any) => handleCartonExpand(e, index, item)}>
                            <CaretRightOutlined
                                className={open ? "arrow arrow-down" : "arrow arrow-right"}
                            />
                            <div className='title-blue'>
                                {item?.CartonNumber}
                            </div>
                        </Col>
                        <Col span={15} className=' d-flex flex-end'>
                            {(!checkAllSKuAddedTOCarton() && cartonListArr?.length - 1 == index) ?
                                <div className='d-flex'>
                                    {viewAction && <div title="Carton Box" className={item?.IsDimensionUpdated == "Y" ? 'green-box' : 'gray-box '} onClick={() => getDimention(item?.CartonNumber)}><CartonBoxIcon /></div>}
                                    {viewAction && <div className={open ? 'white-box' : 'blue-box'} onClick={() => printPackingSlip(data?.RawSOID, item?.CartonNumber, "N")} title="Packing List"><PackingListIcon /></div>}
                                    {viewAction && <div className={open ? 'white-box' : 'blue-box'} title="Scan BarCode" onClick={() => printSticker(data?.RawSOID, item?.CartonNumber, "N")}><ScanBarIcon /></div>}
                                    {viewAction && <div className='red-box' onClick={() => deleteCarton(item?.CartonNumber)}><CloseCartonIcon /></div>}
                                    {<div
                                        className={isArrayNotEmpty(SKUTOPACKINPUTArr) ? 'close-carton' : 'close-carton cursor-not-allowed'}
                                        onClick={() => isArrayNotEmpty(SKUTOPACKINPUTArr) && closeCarton(item?.CartonNumber)}>
                                        Close Carton
                                    </div>}
                                </div>
                                :
                                <div className='d-flex'>
                                    <div title="Carton Box" className={item?.IsDimensionUpdated == "Y" ? 'green-box' : 'gray-box '} onClick={() => getDimention(item?.CartonNumber)}><CartonBoxIcon /></div>
                                    <div className={open ? 'white-box' : 'blue-box'} onClick={() => printPackingSlip(data?.RawSOID, item?.CartonNumber, "N")} title="Packing List"><PackingListIcon /></div>
                                    <div className={open ? 'white-box' : 'blue-box'} title="Scan BarCode" onClick={() => printSticker(data?.RawSOID, item?.CartonNumber, "N")}><ScanBarIcon /></div>
                                    {open && <div className='red-box' onClick={() => deleteCarton(item?.CartonNumber)}><CloseCartonIcon /></div>}
                                </div>

                            }
                        </Col>
                        {open && <Col span={24} className="sku-scroll ">
                            {((!checkAllSKuAddedTOCarton() && cartonListArr?.length - 1 == index) ? SKUTOPACKINPUTArr : expandListForCarton?.lineitems || []).map((x: any) => {
                                return (
                                    <div className='content-container border-blue d-flex align-c jc-sb mt-10'>
                                        <div className='text-1'>{x?.SKU} , {x?.Description}</div>
                                        <div className='text-1'>{x?.Quantity}</div>
                                    </div>
                                )
                            })}
                        </Col>}
                    </Row>
                </div >
            )
        } catch (ex) {
            console.log("Err in cartontableBodyView::" + ex)
        }
    }

    const cartonTableView = (item: any) => {
        try {
            return (
                <div className='cartonning-table-container'>
                    {cartontableHeaderView(item)}
                    <div className='body-view'>{(cartonListArr || []).map((x: any, index: any) => cartontableBodyView(x, item, index))}</div>

                </div>
            )
        } catch (ex) {
            console.log("Err in cartonTableView::" + ex)
        }
    }



    const ViewExpandDetails = (item: any) => {
        try {
            return (
                <Row gutter={16} className="mt-10">
                    <Col span={12}>{listingView(item)}</Col>
                    <Col span={12}>{cartonTableView(item)}</Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in ViewExpandDetails")
        }
    }

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        setSKUTOPACKINPUTArr([]);
        setCartonListArr([]);
        setExpandCartonRow(-1);
        if (index == expandRow) {
            setExpandRow(-1);
        }
        else {
            setExpandRow(index);
            setExpandCartonNumber(data?.CartonRecordNumber)
            getExpandData(data, "Y");
            setExpandSODetails(data)
        }
    }

    const checkAllSKuAddedTOCarton = () => {
        let checkAllSKUIsAdded = cartonExpandList?.lineitems?.filter((x: any) => {
            if (Number(x?.PiecesPacked) >= Number(x?.PiecesPicked)) {
                return x;
            }
        })
        let isComplete = checkAllSKUIsAdded?.length == cartonExpandList?.lineitems?.length;
        return isComplete;
    }

    const tableBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        let isComplete = checkAllSKuAddedTOCarton();
        return (
            <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={5} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='title-blue'>
                            {item?.SOID}
                        </div>
                    </Col>
                    <Col span={5} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {moment(item?.ReceivedDate, 'DD-MMM-YYYY').format('DD-MM-YYYY')}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {"-"}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {moment(item?.ShipByDate, 'DD-MMM-YYYY').format('DD-MM-YYYY')}
                        </div>
                    </Col>
                    <Col span={6} className=' d-flex align-c jc-c'>

                        {open &&
                            <div className={open ? 'white-box' : 'blue-box'}
                                style={{ marginRight: 5, marginLeft: 5 }}
                                onClick={() => ReQC(item?.RawSOID)}>
                                <ReQcIcon />
                            </div>}
                        {open &&
                            <Input
                                onPressEnter={(e: any) => scan0rEnterToPack(e?.target?.value, item)}
                                className='input-field w-100px'
                                placeholder='Scan SKU'
                            />
                        }
                        <AppButtons
                            disabled={!open || !isComplete}
                            onClick={() => MoveToRTO(item?.RawSOID)}
                            text={'Complete Carton'}
                            buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        />
                    </Col>
                    {open && <Col span={24}>
                        {ViewExpandDetails(item)}
                    </Col>}
                </Row>
            </div >
        )
    }


    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={5}>
                    {"Order ID"}
                </Col>
                <Col span={5} className=' d-flex jc-c'>
                    {"Received Date"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Picked Date"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Ship By Date"}
                </Col>
                <Col span={6} className=' d-flex jc-c'>
                    {"Action"}
                </Col>
            </Row>
        )
    }

    const tableView = () => {
        return (
            <div>
                {isArrayNotEmpty(statusDetails?.lineitems) ?
                    <>
                        {tableHeaderView()}
                        <div style={{ height: '72vh', overflow: 'scroll' }}>
                            {(statusDetails?.lineitems || []).map((x: any, index: any) => tableBodyView(x, index))}
                        </div>
                    </>
                    :
                    <div className='p-20'>
                        <NoData
                            description={
                                <>There is no sales order available in <span className='redhat-bold ml-5'>Cartoning</span></>
                            }
                        />
                    </div>}
            </div>
        )
    }

    const contentView = () => {
        return (
            <div className='table-screen-container'>
                {tableView()}
            </div>
        )
    }

    return (
        <div>
            {!statusDetailsOnload ?
                <div className='pick-released-screen'>
                    {headerView()}
                    {contentView()}
                    {ReQcModalView()}
                    {cartonBoxModal()}
                    <CartonningPackingListModal
                        dataValue={PackingSlip?.lineitems}
                        viewPackingListModal={viewPackingListModal}
                        setPackingListModal={setPackingListModal}
                    />
                    <CartonningStickeringModal
                        dataValue={cartonStickeringList?.lineitems}
                        setScanModal={setScanModal}
                        scanModal={scanModal}
                    />

                </div>
                :
                <SkeletonLoader />
            }
        </div>
    );
}

export default CartonningScreen;
