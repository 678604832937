import React from 'react';

function PlayIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
            <path d="M12 6.5L0.75 0.00480938V12.9952L12 6.5Z" fill="var(--app-004986)" />
        </svg>
    );
}

export default PlayIcon;