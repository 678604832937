import { CloseOutlined } from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { AppImages } from '../../Globals/AppImages';
import { setDashboardMenuId } from '../../Utils/SessionStorage';
interface DataType {
    setShowPopupScreen: any,
    clientTatData?: any
    indicator?: any
    setClientValue?: any,
    setTatValue?:any,
    channels?:any,
    setChannel?:any,
    menus?:any,
    clientValue?: any,
    channel?: any,
    tatClient?: any
}
function SearchScreen(props: DataType) {
    const {
        setShowPopupScreen,
        clientTatData,
        indicator,
        setClientValue,
        setTatValue,
        channels,
        setChannel,
        menus,
        clientValue,
        channel,
        tatClient
    } = props

    const [searchAlphabets, setSearchAlphabets] = useState<any>('All');
    let valueSelected = '';
    const alphabetsArray = [
        {
            id: "#",
            label: "#",
        },
        {
            id: "A",
            label: "A",
        },
        {
            id: "B",
            label: "B",
        },
        {
            id: "C",
            label: "C",
        },
        {
            id: "D",
            label: "D",
        },
        {
            id: "E",
            label: "E",
        },
        {
            id: "F",
            label: "F",
        }
        ,
        {
            id: "G",
            label: "G",
        },
        {
            id: "H",
            label: "H",
        },
        {
            id: "I",
            label: "I",
        },
        {
            id: "J",
            label: "J",
        },
        {
            id: "K",
            label: "K",
        },
        {
            id: "L",
            label: "L",
        },
        {
            id: "M",
            label: "M",
        },
        {
            id: "N",
            label: "N",
        },
        {
            id: "O",
            label: "O",
        },
        {
            id: "P",
            label: "P",
        },
        {
            id: "Q",
            label: "Q",
        },
        {
            id: "R",
            label: "R",
        },
        {
            id: "S",
            label: "S",
        },
        {
            id: "T",
            label: "T",
        },
        {
            id: "U",
            label: "U",
        },
        {
            id: "V",
            label: "V",
        },
        {
            id: "W",
            label: "W",
        },
        {
            id: "X",
            label: "X",
        },
        {
            id: "Y",
            label: "Y",
        },
        {
            id: "Z",
            label: "Z",
        }
    ]

    const handleSearch = (id: any) => {
        console.log("id",id)
        setSearchAlphabets(id);
    }
    const handleClient=(id:any)=>{
        setClientValue(id);
        setShowPopupScreen(false);
        valueSelected = id;
    }
    const handleTat=(id:any)=>{
        setTatValue(id);
        setShowPopupScreen(false);
        valueSelected = id;
    }
    const handleChannel = (id: any) => {
        setDashboardMenuId("");
        setChannel(id);
        setShowPopupScreen(false);
        valueSelected = id;
    }
    const handlePopup = () => {
        setShowPopupScreen(false);
    }

    const channelView = ()=>{
        let dataList = (indicator == 'client' || indicator == 'tat') ? clientTatData : channels;
        let filterDataList =searchAlphabets == "#" ? dataList?.filter(({name}: any) => (Number.isInteger(parseInt(name?.charAt(0)))))  : dataList?.filter(({name}: any) => name?.toLowerCase().startsWith(searchAlphabets.toLowerCase()));
        console.log("dataList",dataList,filterDataList)
        let finalDataList = (indicator == 'channel' && searchAlphabets != "All") ?  filterDataList : dataList;  
        return(
            <Row>
                {(finalDataList || []).map((item: any) => {
                    let isSelected = (indicator == 'client' ? clientValue == item?.Id : indicator == 'tat' ? tatClient == item?.Id : channel == item?.id);
                    return (
                        <Col span={12} className='d-flex align-c mt-10'>
                            <div onClick={() =>{indicator == 'client' ? handleClient(item?.Id) : indicator == 'tat' ? handleTat(item?.Id) : handleChannel(item?.id)}}
                                className={isSelected ? 'label-selected' : 'label-color-blue-1'}>
                                {item?.name ? item?.name : item?.Name}
                            </div>
                        </Col>
                    )})}
            </Row>
        )
    }

    return (
        <div className='main-popup-container'>
            <div className='header-container-view'>
                <div className='header-title'>
                    {indicator == 'client' ? 'Select Client' : indicator == 'tat' ? "Select TAT" : 'Select Channel'}
                </div>
                <div
                    onClick={() => handlePopup()}
                    className='close-icon'>
                    <img src={AppImages.closeIcon} alt='' />
                </div>
            </div>
            {(indicator != 'client' && indicator != 'tat') && (<>
                <div className='search-alphabets'>
                    <div
                        onClick={() => handleSearch("All")}
                        className={searchAlphabets == 'All' ? 'label-color-black mt-10' : 'label-color-blue mt-10'}>
                        All
                    </div>
                    <div className='alphabets-container'>
                        <Row>
                            {(alphabetsArray || []).map((item: any) => {
                                return (
                                    <Col span={2}
                                        className='d-flex align-c jc-c mt-10'>
                                        <div onClick={() => handleSearch(item.id)}
                                            className={searchAlphabets == item.label ? 'label-color-black' : 'label-color-blue'}>
                                            {item.label}
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </div>
                <Divider className='divider-line' />
                <div className='label-color-blue-2 mt-10'>
                    All
                </div>
            </>)}
            {channelView()}
        </div >
    );
}

export default SearchScreen;