import React from 'react';

function BarCodeImg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="180" height="28" viewBox="0 0 180 28" fill="none">
            <path d="M0 0H6.75V28H0V0Z" fill="black" />
            <path d="M9 0H11.25V28H9V0Z" fill="black" />
            <path d="M20.25 0H15.75V28H20.25V0Z" fill="black" />
            <path d="M22.5 0H27V28H22.5V0Z" fill="black" />
            <path d="M29.25 0H31.5V28H29.25V0Z" fill="black" />
            <path d="M40.5 0H33.75V28H40.5V0Z" fill="black" />
            <path d="M42.75 0H45V28H42.75V0Z" fill="black" />
            <path d="M45 0H51.75V28H45V0Z" fill="black" />
            <path d="M54 0H56.25V28H54V0Z" fill="black" />
            <path d="M65.25 0H60.75V28H65.25V0Z" fill="black" />
            <path d="M67.5 0H72V28H67.5V0Z" fill="black" />
            <path d="M74.25 0H76.5V28H74.25V0Z" fill="black" />
            <path d="M85.5 0H78.75V28H85.5V0Z" fill="black" />
            <path d="M87.75 0H90V28H87.75V0Z" fill="black" />
            <path d="M90 0H96.75V28H90V0Z" fill="black" />
            <path d="M99 0H101.25V28H99V0Z" fill="black" />
            <path d="M110.25 0H105.75V28H110.25V0Z" fill="black" />
            <path d="M112.5 0H117V28H112.5V0Z" fill="black" />
            <path d="M119.25 0H121.5V28H119.25V0Z" fill="black" />
            <path d="M130.5 0H123.75V28H130.5V0Z" fill="black" />
            <path d="M132.75 0H135V28H132.75V0Z" fill="black" />
            <path d="M135 0H141.75V28H135V0Z" fill="black" />
            <path d="M144 0H146.25V28H144V0Z" fill="black" />
            <path d="M155.25 0H150.75V28H155.25V0Z" fill="black" />
            <path d="M157.5 0H162V28H157.5V0Z" fill="black" />
            <path d="M164.25 0H166.5V28H164.25V0Z" fill="black" />
            <path d="M175.5 0H168.75V28H175.5V0Z" fill="black" />
            <path d="M177.75 0H180V28H177.75V0Z" fill="black" />
        </svg>
    );
}

export default BarCodeImg;