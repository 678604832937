import { getUserInfo } from "../../Utils/SessionStorage";
import { http } from "./Http";

const Axios = {
    getDataAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getGateInEntryAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getIndividualStageAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getStageDetailsAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getProcessDataAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getGateInEntryMetaAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    gateInEntryAddAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getTransactionAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getAssociatePoAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getAssociatePoSaveAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getReceivingInProgressViewAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    saveReceivingInProgressAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getReceivingInProgressBarcodeAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getGRNAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getCaptureInvoiceDetailsListAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getSkuAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getConditionAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getSaveSkuAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    sendSanAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    completeReceiveInProgressAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getGeneratePutawayAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getAssignMoverAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getBarCodeScanSuccessAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getStartPutawayAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getClientAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getSKUAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getInventoryAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    deleteReceieveInProgressAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getCustomersAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getPOAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getSKUDetailsAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getSKUandPoAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    createPoAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    addSKUforPoAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    editSKUforPoAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getSkuDescAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    updatePoAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getSendSanInfo(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getConsignmentAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getClientAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getLeftMenuAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getAdhocClientAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getAdhocSaveAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getAdhocExpenseDataAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getAdhocExpandDataAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getAdhocUpdateDataAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getCourierServiceAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getShowOrdersAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    scanSKU(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    popupScanSKUAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    createManifestAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    completePackingAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    continuePutawayAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getStockMovementCustomerListAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getStockMovementSKUListAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getStockMovementLocationQtyAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getStockMovementLocationDataAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getStockMovementDataAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getStockMovementUpdateAttrAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    searchPOAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    whmConfigurationAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    whmAddAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    whmEditAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    configureAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getViewConfigureModalAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getAdjustInventoryAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getQuarantineInventoryAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getSKULocationAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    changeBinBoxLocationAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    getOnboardingEditDataAxio(payload: any) {
        return Method.dataGet("/operations", payload)
    },
    handOverAxios(payload: any) {
        return Method.dataGet("/operations", payload)
    },
}

const Method = {
    async dataGet(newurl: any, payload: any) {
        const url = newurl;
        return await new Promise((resolve, reject) => {
            http
                .get(url, {
                    headers: {
                        "Content-Type": "application/json",
                        ...payload
                    },
                })
                .then((result: any) => {
                    if (result.status === 200) {
                        return resolve({
                            status: 1,
                            result: result,
                        });
                    } else if (result.status === 212) {
                        return resolve({
                            status: 4,
                            result: result,
                        });
                    } else {
                        if (result) {
                            return reject({
                                status: 3,
                                error: result.data.message,
                            });
                        } else {
                            return reject({
                                status: 4,
                                error: "Something went wrong.",
                            });
                        }
                    }
                })
                .catch((err: any) => {
                    console.log("error", err)
                    if (err.response) {
                        if (
                            err.response.status !== null &&
                            err.response.status !== undefined
                        ) {
                            if (err.response.status === 401) {
                                let unauthorizedStatus = err.response.status;
                                if (unauthorizedStatus === 401) {
                                    // logout();
                                    // message.error(ValidationConstants.messageStatus401);
                                }
                            } else {
                                return reject({
                                    status: 5,
                                    error: err,
                                });
                            }
                        }
                    } else {
                        return reject({
                            status: 5,
                            error: err,
                        });
                    }
                });
        });
    },
}
export default Axios