import React from "react";
import NavbarLayout from "../NavbarLayout/NavbarLayout";
import '../GateIn/GateIn.scss';
import EmizaIcon from "../../Images/emizaIcon";
import { Col, Input, Row, Button } from "antd";
import AppConstants from "../../Globals/AppConstants";

function PreviewVan() {

    const GateId = (key1: any) => {
        return (
            <div className="details">
                <div className="prev-key">
                    {key1}:
                </div>
                <div className="prev-value">
                    WH01-GN01-20220706-1403
                </div>
            </div>
        )
    }

    const items = (key1: any, key2: any) => {
        return (
            <div className="prev-table" >
                <Row>
                    <Col span={6}>
                        <div className="c-row">
                            <div className="c-key">{key1}: </div>
                            <div className="c-value"> Client 1</div>
                        </div>

                    </Col>
                    <Col span={6}>
                        <div className="c-row">
                            <div className="c-key">{key2}: </div>
                            <div className="c-value">Inward Stock</div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    const NumOf = (key1: any, key2: any, key3: any) => {
        return (
            <div className="num">
                <Row>
                    <Col span={6}>
                        <div className="c-row">
                            <div className="c-key">{key1}: </div>
                            <div className="c-value">3</div>
                        </div>

                    </Col>
                    <Col span={6}>
                        <div className="c-row">
                            <div className="c-key">{key2}: </div>
                            <div className="c-value">3</div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="c-row">
                            <div className="c-key">{key3}: </div>
                            <div className="c-value">Yes</div>
                        </div>
                    </Col>

                </Row>
            </div>
        )
    }

    const Comment = () => {
        return (
            <>
                <div className="comment-container">
                    <div className="add-comments">
                        {AppConstants.addComment}
                    </div>
                    <div className="input-cmt">
                        <Input placeholder="Enter your additional comment" />
                    </div>

                </div>

                <div className="regards-container">
                    <div className="best-regards">
                        {AppConstants.bestRegards}
                    </div>
                    <div className="wisher">
                        {AppConstants.WarehouseManager}

                    </div>

                </div>
            </>
        )
    }

    const VanButtons = () => {
        return (
            <div className="van-buttons">
                {/* <div className="cancel">
                    
                </div>
                <div className="send-van">

                </div> */}
                <button className="cancel">{AppConstants.vanCancel}</button>
                <button className="send-van">{AppConstants.vanSend}</button>
            </div>
        )
    }

    return (
        <div className="main-container">
            <div className="content-container">
                <div className="emiza-icon">
                    <EmizaIcon/>
                </div>
                <div className="text-container">
                    <div className="notes-sub-title">Dear Client,</div>
                    <div className="notes-sub1-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis volutpat nisl sed fermentum interdum.</div>
                    <div className='notes-sub1-title'> Nullam feugiat tellus a sapien fermentum convallis eget quis turpis.</div>

                    {GateId("Gate-In Transaction ID")}

                    {items("Client Name", "Inward Type")}
                    {items("Vehicle Type", "License Plate Number")}
                    {items("Seal Matched", "Seal Number")}
                    {items("Docket Number", "Docket Station")}
                    {items("No of Invoices  ", "Inward Type")}

                    {NumOf("No of Invoices", "No of Boxes", "Attachments")}

                    {Comment()}
                </div>
            </div>
            {VanButtons()}
        </div>

    )
}

export default PreviewVan;

