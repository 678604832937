import { Carousel, Modal } from 'antd';
import React, { useRef, useState } from 'react';
import AppButtons from '../CustomComponent/AppButtons';
import AppConstants from '../Globals/AppConstants';

function ImgModelView(props: any) {
    const {
        open,
        close,
        srcUrl,
        urlArray,
        fromOrigin,
        callBack,
        imageUrl
    } = props;
    const carouselref = useRef<any>(null);
    const [index, setindex] = useState<any>(0);

    const carouserAction = (key: any) => {
        if (key == 'next') {
            carouselref.current.next()
            setindex(index + 1)
        }
        else {
            carouselref.current.prev()
            setindex(index - 1)
        }
    }

    const closeModal = () => {
        setindex(0);
        close()
    }


    const carouselView = () => {
        return (
            <div className='position-relative'>
                <Carousel ref={carouselref} className="carousel-view" dots={false}>
                    {urlArray?.map((url: any, index: any) => {
                        return (
                            <div className='carousel-img'>
                                <img
                                    src={'https://amplify-cognitodemoapp-dev-210647-deployment.s3.ap-south-1.amazonaws.com/' + url}
                                />
                                {fromOrigin == 'sku' && (imageUrl != url && <div className='setAsButton'>
                                    <AppButtons
                                        onClick={() => callBack(url)}
                                        text={'Set as Default'}
                                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                    />
                                </div>)}
                            </div>
                        )
                    })}
                </Carousel>
                <AppButtons
                    disabled={index == (urlArray?.length - 1)}
                    shape={'circle'}
                    text={'>'}
                    buttonType={AppConstants.buttonTypes.solidLinearGreen + " " + 'next-button'}
                    onClick={() => carouserAction('next')}
                //  onClick={carouserAction}
                />
                <AppButtons
                    disabled={index == 0}
                    shape={'circle'}
                    text={'<'}
                    buttonType={AppConstants.buttonTypes.solidLinearGreen + " " + 'previous-button'}
                    onClick={() => carouserAction('prev')}
                //  onClick={carouserAction}
                />
            </div>
        )
    }

    return (
        <div>
            <Modal
                className='img-view-modal'
                onCancel={closeModal}
                open={open}
                footer={null}
            >
                {carouselView()}
            </Modal>
        </div>
    );
}

export default ImgModelView;