import { cardActionAreaClasses } from "@mui/material";
import ApiConstants from "../../Globals/ApiConstants";
import AppReferences from "../../Globals/AppReferences";

const initialState = {
    loginDetails: null,
    onload: false,
    dataInfo: null,
    stageOnload: false,
    stageDetails: null,
    individualStageData: null,
    processDataInfo: null,
    gateInEntryMetaOnLoad: false,
    gateInEntryMetaData: null,
    gateInEntryAddOnLoad: false,
    getTransactionOnLoad: false,
    recievingInProgressViewOnLoad: false,
    saveRecievingInProgressOnLoad: false,
    recievingInProgressBarcodeOnLoad: false,
    getAssociatePoLoad: false,
    getAssociatePoInfo: null,
    getAssociatePoSendLoad: false,
    pendingSanDetails: null,
    sendOnload: false,
    unloadingInfo: null,
    sendStickeringInfo: null,
    completedStickeringInfo: null,
    putawayInfo: null,
    metaList: null,
    getSendSanInfo: null,
    getSendSanOnLoad: false,
    getGrnInfo: null,
    getGrnOnLoad: false,
    skuOnLoad: false,
    skuData: null,
    conditionOnLoad: false,
    conditionData: null,
    saveSkuOnLoad: false,
    sendSanonload: false,
    captureInvoiceDetails: null,
    completeReceiveInProgressOnLoad: false,
    captureSKUDetails: null,
    generatePutawayHeaderInfo: null,
    generatePutawayHeaderOnLoad: false,
    assignMoverInfo: null,
    assignMoverOnLoad: false,
    barCodeScanSuccessData: null,
    barCodeScanOnLoad: false,
    startPutawayOnLoad: false,
    processLoad: false,
    warehouseList: null,
    clientData: null,
    clientDataOnLoad: false,
    inventorySkuData: null,
    inventorySkuDataOnLoad: false,
    inventoryDataOnLoad: false,
    inventoryData: null,
    deleteRIPOnLoad: false,
    assignOnload: false,
    customerOnLoad: false,
    customers: null,
    getPOonLoad: false,
    getPO: null,
    getSKUonLoad: false,
    getSKU: null,
    getSKUandPOonLoad: false,
    getSKUandPO: null,
    createPOonLoad: false,
    createPO: null,
    addSKUonLoad: false,
    editSKUonLoad: false,
    skuBatchDetail: null,
    skuBatchDetails: null,
    getSkuDescOnLoad: false,
    getSkuDesc: null,
    updatePoOnLoad: false,
    barCodeMsg: false,
    deleteOnload: false,
    consignmentData: null,
    consignmentOnload: false,
    clientOnload: false,
    getClient: null,
    getLeftMenuOnload: false,
    getLeftMenu: null,
    selectedProductArr: [],
    captureSKUDetailsOnLoad: false,
    emailSent: null,
    adhocClient: null,
    adhocClientOnLoad: false,
    expenseDataOnLoad: false,
    expenseData: null,
    expenseNumber: null,
    expenseNumberOnLoad: false,
    expandDetailedData: null,
    expandDetailedDataOnLoad: false,
    updateExpenseData: null,
    updateExpenseOnLoad: true,
    channelStatus: null,
    statusDetails: null,
    pickers: null,
    skuSubOrders: null,
    courierServiceOnLoad: false,
    courierService: null,
    showOrderLoad: false,
    showOrders: null,
    scanOnload: false,
    scanningResponse: null,
    skuScanningResponse: null,
    popupSkuScanningResponse: null,
    createManifestLoad: false,
    completePackingLoad: false,
    completePackingData: null,
    validateDetails: null,
    shipmentDetails: null,
    handover: null,
    continuePutawayOnLoad: false,
    continuePutawayData: null,
    salesClients: null,
    slelsOrderTypes: null,
    SODetails: null,
    pickedListOnLoad: false,
    channelStatusOnLoad: false,
    custListOnLoad: false,
    stockMovementCustList: null,
    skuListOnLoad: false,
    stockMovementSKUList: null,
    locationQTYOnLoad: false,
    stockMovementlocationQTY: null,
    locationDataOnLoad: false,
    stockMovementlocationData: null,
    createSOInfo: null,
    salesOrderStatusDetails: null,
    lockAndProcess: null,
    processOnload: false,
    stockMovementDataOnLoad: false,
    stockMovementData: null,
    stockMovementATTOnLoad: false,
    searchSOData: null,
    cancelSO: null,
    searchPoOnLoad: false,
    searchPOData: null,
    whConfigurationOnLoad: false,
    whConfigurationData: null,
    whAddOnLoad: false,
    categoryInfo: null,
    editCategory: null,
    createCategoryInfo: null,
    createOnload: false,
    whEditOnLoad: false,
    configureOnLoad: false,
    configureData: null,
    formMeta: null,
    moveAdjustInventoryOnload: false,
    moveQuarantineInventoryOnload: false,
    viewConfigureModalOnLoad: false,
    viewConfigureModalData: null,
    warehouseConfigure: null,
    onboardingDetails: null,
    uploadFile: null,
    uploadOnload: false,
    storageDetails: null,
    dedicatedLocation: null,
    onBoardingEditOnLoad: false,
    onBoardingEditData: null,
    liveEnable: null,
    editOnload: false,
    changeSKULocationOnload: false,
    changeBinBoxLocationOnload: false,
    attachementDetails: null,
    getAttachmentOnLoad: false,
    reportOnload: false,
    reportList: null,
    reportGateInOnload: false,
    reportGateInList: null,
    reportExpandGateInOnload: false,
    reportExpandGateInList: null,
    reportSearchGateInOnload: false,
    reportSearchGateInList: null,
    reportSANOnload: false,
    reportSANList: null,
    reportExpandSANOnload: false,
    reportExpandSANList: null,
    reportGRNOnload: false,
    reportGRNList: null,
    reportExpandGRNOnload: false,
    reportExpandGRNList: null,
    reportGAEOnload: false,
    reportGAEList: null,
    reportExpandGAEOnload: false,
    reportExpandGAEList: null,
    reportODOnload: false,
    reportODList: null,
    reportExpandODOnload: false,
    reportExpandODList: null,
    reportDateOnload: false,
    reportDateList: null,
    cartonOnload: false,
    cartonExpandList: null,
    cartonExpandOnload: false,
    expandListForCarton: null,
    dimensionUpdateOnload: false,
    dimensionUpdateList: null,
    dimensionOnload: false,
    dimensionList: null,
    reQCList: null,
    reQCOnLoad: false,
    deleteCartonOnLoad: false,
    closeCartonOnLoad: false,
    specificStickerOnLoad: false,
    allStickerOnLoad: false,
    specificPackingSlipOnLoad: false,
    allPackingSlipOnLoad: false,
    scanSKUOnLoad: false,
    PackingSlip: null,
    cartonStickeringList: null,
    deleteMsg: null,
    handOverOnLoad: false,
    moveToRtoOnload: false,
    completeCartonData: null,
    workOnthis: null,
    skuBatchOnLoad: false,
    reqcResponse: null,
    customStickering: null,
    cycleCountData: null,
    cycleCountDataOnload: false,
    cycleCountMasterData: null,
    cycleCountExpandDataOnload: false,
    cycleCountExpandData: null,
    startCycleCountData: null,
    cycleCountStorageSKUList: null,
    cycleCountStorageSKUOnLoad: false,
    cycleCountStorageSKUSelectList: null,
    cycleCountStorageSKUSelectOnLoad: false,
    cycleCountStorageSKUQTYUpdateOnLoad: false,
    approveRejectCycleCountOnLoad: false,
    cycleCountCloseOnLoad: false,
    demoonload: false,
    demo: null,
    createClientInfo: null,
    createContractInfo: null,
    clientDataInfo: null,
    clientInfoLoad: false,
    createContractonload: false,
    onBoardingLoad: false,
    moveTOBinMetaList: null


}

const setSelectedProduct = (state: any, action: any) => {
    try {
        if (action.key == "individualSelect") {
            let productArr: any = [...state.selectedProductArr];
            if (action.checked) {
                productArr.push(action?.value);
            } else {
                let filteredNotice = productArr.filter((x: any) => x !== action.value)
                productArr = filteredNotice;
            }
            state.selectedProductArr = productArr;
        }
        else if (action.key == "selectAll") {
            let productArr: any = [...state.selectedProductArr];
            if (action.checked) {
                productArr = action?.value;

            } else {
                productArr = []
            }
            state.selectedProductArr = productArr;
        }
    } catch (ex) {
        console.log("Error in SelectProduct::" + ex);
    }
}

const ProcessReducerState = (state: any = initialState, action: any) => {
    switch (action.type) {
        case ApiConstants.API_GET_GATE_IN_ENTRY_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_GET_GATE_IN_ENTRY_SUCCESS:
            return {
                ...state,
                onload: false,
                dataInfo: action.result
            }
        case ApiConstants.API_GET_STAGE_DETAILS_LOAD:
            return {
                ...state,
                stageOnload: true
            }
        case ApiConstants.API_GET_STAGE_DETAILS_SUCCESS:
            return {
                ...state,
                stageOnload: false,
                stageDetails: action.result
            }

        case ApiConstants.API_GET_INDIVIDUAL_STAGE_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_GET_INDIVIDUAL_STAGE_SUCCESS:
            return {
                ...state,
                onload: false,
                individualStageData: action.result
            }
        case ApiConstants.API_GET_PROCESS_DATA_LOAD:
            return {
                ...state,
                processLoad: true
            }
        case ApiConstants.API_GET_PROCESS_DATA_SUCCESS:
            return {
                ...state,
                processLoad: false,
                processDataInfo: action.result
            }
        case ApiConstants.API_GET_GATE_IN_ENTRY_META_LOAD:
            return {
                ...state,
                gateInEntryMetaOnLoad: true
            }
        case ApiConstants.API_GET_GATE_IN_ENTRY_META_SUCCESS:
            return {
                ...state,
                gateInEntryMetaOnLoad: false,
                gateInEntryMetaData: action.result
            }
        case ApiConstants.API_GATE_IN_ENTRY_ADD_LOAD:
            return {
                ...state,
                gateInEntryAddOnLoad: true
            }
        case ApiConstants.API_GATE_IN_ENTRY_ADD_SUCCESS:
            return {
                ...state,
                gateInEntryAddOnLoad: false,
            }
        case ApiConstants.API_GET_TRANSACTION_LOAD:
            return {
                ...state,
                getTransactionOnLoad: true
            }
        case ApiConstants.API_GET_TRANSACTION_SUCCESS:
            return {
                ...state,
                getTransactionOnLoad: false,
                getTransactionInfo: action.result
            }
        case ApiConstants.API_GET_ASSOCIATEPO_LOAD:
            return {
                ...state,
                getAssociatePoLoad: true,
            }
        case ApiConstants.API_GET_ASSOCIATEPO_SUCCESS:
            return {
                ...state,
                getAssociatePoLoad: false,
                getAssociatePoInfo: action.result
            }
        case ApiConstants.API_GET_PENDING_SAN_DETAILS_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_GET_PENDING_SAN_DETAILS_SUCCESS:
            return {
                ...state,
                onload: false,
                pendingSanDetails: action.result
            }
        case ApiConstants.API_GET_RECEIVING_IN_PROGRESS_VIEW_LOAD:
            return {
                ...state,
                recievingInProgressViewOnLoad: true,
                recievingInProgressViewData: null
            }
        case ApiConstants.API_GET_RECEIVING_IN_PROGRESS_VIEW_SUCCESS:
            return {
                ...state,
                recievingInProgressViewOnLoad: false,
                recievingInProgressViewData: action.result,

            }
        case ApiConstants.API_SAVE_RECEIVING_IN_PROGRESS_LOAD:
            return {
                ...state,
                saveRecievingInProgressOnLoad: true
            }
        case ApiConstants.API_SAVE_RECEIVING_IN_PROGRESS_SUCCESS:
            return {
                ...state,
                saveRecievingInProgressOnLoad: false,
            }

        case ApiConstants.API_GET_RECEIVING_IN_PROGRESS_BARCODE_LOAD:
            return {
                ...state,
                recievingInProgressBarcodeOnLoad: true,
                recievingInProgressBarcode: null
            }
        case ApiConstants.API_GET_RECEIVING_IN_PROGRESS_BARCODE_SUCCESS:
            return {
                ...state,
                recievingInProgressBarcodeOnLoad: false,
                recievingInProgressBarcode: action?.result
            }

        case ApiConstants.API_GET_UNLOADING_DETAILS_LOAD:
            state.unloadingInfo = null;
            return {
                ...state,
                onload: true,

            }
        case ApiConstants.API_GET_UNLOADING_DETAILS_SUCCESS:
            return {
                ...state,
                unloadingInfo: action.result,
                onload: false,
            }
        case ApiConstants.API_SEND_TO_STICKERING_LOAD:
            return {
                ...state,
                sendOnload: true,
                unloadingComplete: null
            }
        case ApiConstants.API_SEND_TO_STICKERING_SUCCESS:
            return {
                ...state,
                sendOnload: false,
                unloadingComplete: action.result
            }

        case ApiConstants.API_GET_STICKER_DETAILS_LOAD:
            return {
                ...state,
                sendOnload: true,
                sendStickeringInfo: null
            }
        case ApiConstants.API_GET_STICKER_DETAILS_SUCCESS:
            return {
                ...state,
                sendOnload: false,
                sendStickeringInfo: action.result
            }

        case ApiConstants.API_GET_ASSOCIATEPO_SEND_LOAD:
            return {
                ...state,
                getAssociatePoSendLoad: true,
            }
        case ApiConstants.API_GET_ASSOCIATEPO_SEND_SUCCESS:
            return {
                ...state,
                getAssociatePoSendLoad: false,
            }
        case ApiConstants.API_GET_CAPTURE_INVOICE_DETAIL_LIST_LOAD:
            return {
                ...state,
                captureInvoiceDetailListOnLoad: true,
            }
        case ApiConstants.API_GET_CAPTURE_INVOICE_DETAIL_LIST_SUCCESS:
            return {
                ...state,
                captureInvoiceDetailListOnLoad: false,
                captureInvoiceDetailList: action.result
            }
        case ApiConstants.API_COMPLETE_STICKERING_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_COMPLETE_STICKERING_SUCCESS:
            return {
                ...state,
                onload: false,
                completedStickeringInfo: action.result?.rawData,
                customStickering: action.result
            }
        case ApiConstants.API_SEND_SAN_INFO_LOAD:
            return {
                ...state,
                getSendSanOnLoad: false
            }

        case ApiConstants.API_SEND_SAN_INFO_SUCCESS:
            return {
                ...state,
                getSendSanOnLoad: true,
                getSendSanInfo: action.result
            }
        case ApiConstants.API_GRN_INFO_LOAD:
            return {
                ...state,
                getGrnOnLoad: false,
            }
        case ApiConstants.API_GRN_INFO_SUCCESS:
            return {
                ...state,
                getGrnOnLoad: true,
                getGrnInfo: action.result
            }

        case ApiConstants.API_GET_PUTAWAY_DEATAILS_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_GET_PUTAWAY_DEATAILS_SUCCESS:
            return {
                ...state,
                onload: false,
                putawayInfo: action.result
            }
        case ApiConstants.API_GET_SKU_LOAD:
            return {
                ...state,
                skuOnLoad: true,
            }
        case ApiConstants.API_GET_SKU_SUCCESS:
            return {
                ...state,
                skuOnLoad: false,
                skuData: action.result
            }
        case ApiConstants.API_GET_META_DATA_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_GET_META_DATA_SUCCESS:
            return {
                ...state,
                onload: false,
                metaList: action.result
            }
        case ApiConstants.API_GET_MOVE_TO_BIN_META_DATA_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_GET_MOVE_TO_BIN_META_DATA_SUCCESS:
            return {
                ...state,
                onload: false,
                moveTOBinMetaList: action.result
            }
           
        case ApiConstants.API_ADD_INVOICE_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_ADD_INVOICE_SUCCESS:
            return {
                ...state,
                onload: false,
            }
        case ApiConstants.API_SEND_SAN_LOAD:
            return {
                ...state,
                sendSanonload: true,
            }
        case ApiConstants.API_SEND_SAN_SUCCESS:
            return {
                ...state,
                sendSanonload: false,
                emailSent: action.result
            }

        case ApiConstants.API_GET_CAPTURE_INVOICE_DETAILS_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_GET_CAPTURE_INVOICE_DETAILS_SUCCESS:
            return {
                ...state,
                onload: false,
                captureInvoiceDetails: action.result
            }
        case ApiConstants.API_CAPTURE_SKU_WISE_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_CAPTURE_SKU_WISE_SUCCESS:
            return {
                ...state,
                onload: false,
                sendStickeringInfo: action.result
            }

        case ApiConstants.API_GET_CONDITION_LOAD:
            return {
                ...state,
                conditionOnLoad: true,
            }
        case ApiConstants.API_GET_CONDITION_SUCCESS:
            return {
                ...state,
                conditionOnLoad: false,
                conditionData: action.result
            }
        case ApiConstants.API_SAVE_SKU_LOAD:
            return {
                ...state,
                saveSkuOnLoad: true,
            }
        case ApiConstants.API_SAVE_SKU_SUCCESS:
            return {
                ...state,
                saveSkuOnLoad: false,
            }
        case ApiConstants.API_COMPLETE_RECEIVE_IN_PROGRESS_LOAD:
            return {
                ...state,
                completeReceiveInProgressOnLoad: true,
            }
        case ApiConstants.API_COMPLETE_RECEIVE_IN_PROGRESS_SECCESS:
            return {
                ...state,
                completeReceiveInProgressOnLoad: false,
            }
        case ApiConstants.API_GET_CAPTURE_SKU_DETAILS_LOAD:
            return {
                ...state,
                captureSKUDetailsOnLoad: true,
            }
        case ApiConstants.API_GET_CAPTURE_SKU_DETAILS_SUCCESS:
            return {
                ...state,
                captureSKUDetailsOnLoad: false,
                captureSKUDetails: action.result
            }
        case ApiConstants.API_GET_GENERATE_PUTAWAY_INFO_LOAD:
            return {
                ...state,
                generatePutawayHeaderOnLoad: true,
            }
        case ApiConstants.API_GET_GENERATE_PUTAWAY_INFO_SUCCESS:
            return {
                ...state,
                generatePutawayHeaderOnLoad: false,
                generatePutawayHeaderInfo: action?.result
            }
        case ApiConstants.API_GET_ASSIGN_MOVER_INFO_LOAD:
            return {
                ...state,
                assignMoverOnLoad: true
            }
        case ApiConstants.API_GET_ASSIGN_MOVER_INFO_SUCCESS:
            return {
                ...state,
                assignMoverOnLoad: false,
                assignMoverInfo: action?.result
            }
        case ApiConstants.API_GET_BAR_CODE_SCAN_SUCCESS_DATA_LOAD:
            return {
                ...state,
                barCodeScanOnLoad: true,
            }
        case ApiConstants.API_GET_BAR_CODE_SCAN_SUCCESS_DATA_SUCCESS:
            return {
                ...state,
                barCodeScanOnLoad: false,
                barCodeScanSuccessData: action?.result,
                barCodeMsg: action?.msg
            }
        case ApiConstants.API_START_PUTAWAY_LOAD:
            return {
                ...state,
                startPutawayOnLoad: true,
            }
        case ApiConstants.API_START_PUTAWAY_SUCCESS:
            return {
                ...state,
                startPutawayOnLoad: false,
            }
        case ApiConstants.API_GET_WAREHOUSES_ACTION_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_GET_WAREHOUSES_ACTION_SUCCESS:
            return {
                ...state,
                onload: false,
                warehouseList: action.result
            }
        case ApiConstants.API_GET_CLIENT_DATA_LOAD:
            return {
                ...state,
                clientDataOnLoad: true
            }
        case ApiConstants.API_GET_CLIENT_DATA_SUCCESS:
            return {
                ...state,
                clientDataOnLoad: false,
                clientData: action?.result
            }
        case ApiConstants.API_GET_SKU_DATA_LOAD:
            return {
                ...state,
                inventorySkuDataOnLoad: true
            }
        case ApiConstants.API_GET_SKU_DATA_SUCCESS:
            return {
                ...state,
                inventorySkuDataOnLoad: false,
                inventorySkuData: action?.result
            }

        case ApiConstants.API_GET_INVENTORY_DATA_LOAD:
            return {
                ...state,
                inventoryDataOnLoad: true,
            }

        case ApiConstants.API_GET_INVENTORY_DATA_SUCCESS:
            return {
                ...state,
                inventoryDataOnLoad: false,
                inventoryData: action?.result
            }

        case ApiConstants.API_DELETE_RECEIVE_INPROGRESS_LOAD:
            return {
                ...state,
                deleteRIPOnLoad: true,
            }

        case ApiConstants.API_DELETE_RECEIVE_INPROGRESS_SUCCESS:
            return {
                ...state,
                deleteRIPOnLoad: false,
            }

        case ApiConstants.API_ASSIGN_MOVER_LOAD:
            return {
                ...state,
                assignOnload: true,
            }

        case ApiConstants.API_ASSIGN_MOVER_SUCCESS:
            return {
                ...state,
                assignOnload: false,
            }

        case ApiConstants.API_GET_CUSTOMER_LOAD:
            return {
                ...state,
                customerOnLoad: true,
            }

        case ApiConstants.API_GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                customerOnLoad: false,
                customers: action.result
            }

        case ApiConstants.API_GET_PO_LOAD:
            return {
                ...state,
                getPOonLoad: true,
            }

        case ApiConstants.API_GET_PO_SUCCESS:
            return {
                ...state,
                getPOonLoad: false,
                getPO: action.result
            }


        case ApiConstants.API_GET_SKU_DETAILS_LOAD:
            return {
                ...state,
                getSKUonLoad: true,
            }
        case ApiConstants.API_GET_SKU_DETAILS_SUCCESS:
            return {
                ...state,
                getSKUonLoad: false,
                getSKU: action?.result
            }
        case ApiConstants.API_GET_SKU_BATCH_DETAILS_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_GET_SKU_BATCH_DETAILS_SUCCESS:
            return {
                ...state,
                onload: false,
                skuBatchDetail: action.result
            }
        case ApiConstants.API_ADD_SKU_BATCH_DETAILS_LOAD:
            return {
                ...state,
                skuBatchOnLoad: true,
            }
        case ApiConstants.API_ADD_SKU_BATCH_DETAILS_SUCCESS:
            return {
                ...state,
                skuBatchOnLoad: false,
                skuBatchDetails: action.result
            }
        case ApiConstants.API_EDIT_SKU_BATCH_DETAILS_LOAD:
            return {
                ...state,
                editOnload: true,
            }
        case ApiConstants.API_EDIT_SKU_BATCH_DETAILS_SUCCESS:
            return {
                ...state,
                editOnload: false,
                skuBatchEditDetails: action.result
            }



        // case ApiConstants.API_GET_SKU_DETAILS_SUCCESS:
        //     return {
        //         ...state,
        //         getSKUonLoad: false,
        //         getSKU: action.result
        //     }

        case ApiConstants.API_GET_SKU_AND_PO_LOAD:
            return {
                ...state,
                getSKUandPOonLoad: true,
            }

        case ApiConstants.API_GET_SKU_AND_PO_SUCCESS:
            return {
                ...state,
                getSKUandPOonLoad: false,
                getSKUandPO: action.result
            }
        case ApiConstants.API_CREATE_PO_LOAD:
            return {
                ...state,
                createPOonLoad: true,
            }

        case ApiConstants.API_CREATE_PO_SUCCESS:
            return {
                ...state,
                createPOonLoad: false,
                createPO: action.result
            }
        case ApiConstants.API_ADD_SKU_FOR_PO_LOAD:
            return {
                ...state,
                addSKUonLoad: true,
            }

        case ApiConstants.API_ADD_SKU_FOR_PO_SUCCESS:
            return {
                ...state,
                addSKUonLoad: false,
            }
        case ApiConstants.API_EDIT_SKU_FOR_PO_LOAD:
            return {
                ...state,
                editSKUonLoad: true,
            }

        case ApiConstants.API_EDIT_SKU_FOR_PO_SUCCESS:
            return {
                ...state,
                editSKUonLoad: false,
            }
        case ApiConstants.API_GET_SKU_DESC_LOAD:
            return {
                ...state,
                getSkuDescOnLoad: true,
            }

        case ApiConstants.API_GET_SKU_DESC_SUCCESS:
            return {
                ...state,
                getSkuDescOnLoad: false,
                getSkuDesc: action.result
            }
        case ApiConstants.API_UPDATE_PO_LOAD:
            return {
                ...state,
                updatePoOnLoad: true,
            }

        case ApiConstants.API_UPDATE_PO_SUCCESS:
            return {
                ...state,
                updatePoOnLoad: false,
            }
        case ApiConstants.API_DELETE_LOAD:
            return {
                ...state,
                deleteOnload: true,
            }

        case ApiConstants.API_DELETE_SUCCESS:
            return {
                ...state,
                deleteOnload: false,
            }

        case ApiConstants.API_CONSIGNMENT_LOAD:
            return {
                ...state,
                consignmentOnload: true,
            }

        case ApiConstants.API_CONSIGNMENT_SUCCESS:
            return {
                ...state,
                consignmentOnload: false,
                consignmentData: action.result
            }

        case ApiConstants.API_GET_CLIENT_LOAD:
            return {
                ...state,
                clientOnload: true,
            }

        case ApiConstants.API_GET_CLIENT_SUCCESS:
            return {
                ...state,
                clientOnload: false,
                getClient: action.result
            }
        case ApiConstants.API_GET_LEFT_MENU_LOAD:
            return {
                ...state,
                getLeftMenuOnload: true,
                getLeftMenu: null
            }

        case ApiConstants.API_GET_LEFT_MENU_SUCCESS:
            return {
                ...state,
                getLeftMenuOnload: false,
                getLeftMenu: action.result
            }
        case ApiConstants.GET_SELECTED_PRODUCT:
            setSelectedProduct(state, action)
            return {
                ...state
            }

        case ApiConstants.API_GET_ADHOC_CLIENT_DATA_LOAD:
            return {
                ...state,
                adhocClientOnLoad: true
            }
        case ApiConstants.API_GET_ADHOC_CLIENT_DATA_SUCCESS:
            return {
                ...state,
                adhocClient: action.result,
                adhocClientOnLoad: false
            }
        case ApiConstants.API_GET_ADHOC_SAVE_DATA_LOAD:
            return {
                ...state,
                expenseDataOnLoad: true,
            }
        case ApiConstants.API_GET_ADHOC_SAVE_DATA_SUCCESS:
            return {
                ...state,
                expenseData: action?.result,
                expenseDataOnLoad: false,
            }
        case ApiConstants.API_GET_CHANNEL_STATUS_LOAD:
            return {
                ...state,
                channelStatusOnLoad: true,
                channelStatus: null
            }
        case ApiConstants.API_GET_CHANNEL_STATUS_SUCCESS:
            return {
                ...state,
                channelStatusOnLoad: false,
                channelStatus: action.result
            }
        case ApiConstants.API_GET_CHANNEL_DETAILS_LOAD:
            return {
                ...state,
                statusDetails: null,
                statusDetailsOnload: true
            }
        case ApiConstants.API_GET_CHANNEL_DETAILS_SUCCESS:
            return {
                ...state,
                statusDetailsOnload: false,
                statusDetails: action.result
            }

        case ApiConstants.API_GET_ADHOC_EXPENSE_NO_LOAD:
            return {
                ...state,
                expenseNumberOnLoad: true
            }
        case ApiConstants.API_GET_ADHOC_EXPENSE_NO_SUCCESS:
            return {
                ...state,
                expenseNumber: action?.result,
                expenseNumberOnLoad: false
            }
        case ApiConstants.API_GET_ADHOC_EXPAND_DATA_LOAD:
            return {
                ...state,
                expandDetailedDataOnLoad: true,
                expandDetailedData: null
            }
        case ApiConstants.API_GET_ADHOC_EXPAND_DATA_SUCCESS:
            return {
                ...state,
                expandDetailedData: action?.result,
                expandDetailedDataOnLoad: false,
            }
        case ApiConstants.API_UPDATE_ADHOC_LOAD:
            return {
                ...state,
                updateExpenseOnLoad: true
            }
        case ApiConstants.API_UPDATE_ADHOC_SUCCESS:
            return {
                ...state,
                updateExpenseData: action?.result,
                updateExpenseOnLoad: false
            }
        case ApiConstants.API_GET_PICKER_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_GET_PICKER_SUCCESS:
            return {
                ...state,
                pickers: action?.result,
                onload: false
            }
        case ApiConstants.API_GET_SKU_SUBORDER_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_GET_SKU_SUBORDER_SUCCESS:
            return {
                ...state,
                skuSubOrders: action?.result,
                onload: false
            }
        case ApiConstants.API_GENERATE_PICKLIST_LOAD:
            return {
                ...state,
                pickedListOnLoad: true
            }
        case ApiConstants.API_GENERATE_PICKLIST_SUCCESS:
            return {
                ...state,
                pickedListOnLoad: false
            }
        case ApiConstants.API_PICKING_COMPLETE_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_PICKING_COMPLETE_SUCCESS:
            return {
                ...state,
                onload: false
            }
        case ApiConstants.API_SCAN_PICKED_LOAD:
            return {
                ...state,
                scanOnload: true
            }
        case ApiConstants.API_SCAN_PICKED_SUCCESS:
            return {
                ...state,
                scanOnload: false,
                scanningResponse: action.result
            }
        case ApiConstants.API_GET_COURIER_SERVICE_LOAD:
            return {
                ...state,
                courierServiceOnLoad: true
            }
        case ApiConstants.API_GET_COURIER_SERVICE_SUCCESS:
            return {
                ...state,
                courierServiceOnLoad: false,
                courierService: action.result
            }
        case ApiConstants.API_GET_SHOW_ORDERS_LOAD:
            return {
                ...state,
                showOrdersLoad: true
            }
        case ApiConstants.API_GET_SHOW_ORDERS_SUCCESS:
            return {
                ...state,
                showOrdersLoad: false,
                showOrders: action.result
            }
        case ApiConstants.API_SCAN_SKU_LOAD:
            return {
                ...state,
                scanOnload: true
            }
        case ApiConstants.API_SCAN_SKU_SUCCESS:
            return {
                ...state,
                scanOnload: false,
                skuScanningResponse: action.result
            }
        case ApiConstants.API_POPUP_SCAN_SKU_LOAD:
            return {
                ...state,
                scanOnload: true
            }
        case ApiConstants.API_POPUP_SCAN_SKU_SUCCESS:
            return {
                ...state,
                scanOnload: false,
                popupSkuScanningResponse: action.result
            }

        case ApiConstants.API_CREATE_MANIFEST_LOAD:
            return {
                ...state,
                createManifestLoad: true
            }
        case ApiConstants.API_CREATE_MANIFEST_SUCCESS:
            return {
                ...state,
                createManifestLoad: false,
            }
        case ApiConstants.API_COMPLETE_PACKING_LOAD:
            return {
                ...state,
                completePackingLoad: true
            }
        case ApiConstants.API_COMPLETE_PACKING_SUCCESS:
            return {
                ...state,
                completePackingLoad: false,
                completePackingData: action.result
            }
        case ApiConstants.API_GET_CONTINUE_PUTAWAY_LOAD:
            return {
                ...state,
                continuePutawayOnLoad: true
            }
        case ApiConstants.API_GET_CONTINUE_PUTAWAY_SUCCESS:
            return {
                ...state,
                continuePutawayOnLoad: false,
                continuePutawayData: action.result
            }
        case ApiConstants.API_VALIDATE_GATEOUT_BOX_COUNT_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_VALIDATE_GATEOUT_BOX_COUNT_SUCCESS:
            return {
                ...state,
                onload: false,
                validateDetails: action.result
            }
        case ApiConstants.API_SHIPMENT_OTP_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_SHIPMENT_OTP_SUCCESS:
            return {
                ...state,
                onload: false,
                shipmentDetails: action.result
            }
        case ApiConstants.API_HAND_OVER_MANIFEST_ACTION_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_HAND_OVER_MANIFEST_ACTION_SUCCESS:
            return {
                ...state,
                onload: false,
                handover: action.result
            }
        case ApiConstants.API_GET_SALES_ORDER_CLIENT_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_GET_SALES_ORDER_CLIENT_SUCCESS:
            return {
                ...state,
                onload: false,
                salesClients: action.result
            }
        case ApiConstants.API_GET_DETAILS_FOR_SO_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_GET_DETAILS_FOR_SO_SUCCESS:
            return {
                ...state,
                onload: false,
                SODetails: action.result
            }
        case ApiConstants.API_GET_SALES_ORDERS_TYPE_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_GET_SALES_ORDERS_TYPE_SUCCESS:
            return {
                ...state,
                onload: false,
                salesOrderTypes: action.result
            }
        case ApiConstants.API_CREATE_SO_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_CREATE_SO_SUCCESS:
            return {
                ...state,
                onload: false,
                createSOInfo: action.result
            }
        case ApiConstants.API_GET_CUSTOMER_LIST_LOAD:
            return {
                ...state,
                custListOnLoad: true
            }
        case ApiConstants.API_GET_CUSTOMER_LIST_SUCCESS:
            return {
                ...state,
                custListOnLoad: false,
                stockMovementCustList: action?.result
            }
        case ApiConstants.API_GET_SKU_LIST_LOAD:
            return {
                ...state,
                skuListOnLoad: true
            }
        case ApiConstants.API_GET_SKU_LIST_SUCCESS:
            return {
                ...state,
                skuListOnLoad: false,
                stockMovementSKUList: action?.result
            }
        case ApiConstants.API_GET_LOCATION_QTY_LOAD:
            return {
                ...state,
                locationQTYOnLoad: true
            }
        case ApiConstants.API_GET_LOCATION_QTY_SUCCESS:
            return {
                ...state,
                locationQTYOnLoad: false,
                stockMovementlocationQTY: action?.result
            }
        case ApiConstants.API_GET_LOCATION_DATA_LOAD:
            return {
                ...state,
                locationDataOnLoad: true
            }
        case ApiConstants.API_GET_LOCATION_DATA_SUCCESS:
            return {
                ...state,
                locationDataOnLoad: false,
                stockMovementlocationData: action?.result
            }
        case ApiConstants.API_GET_STOCK_DATA_LOAD:
            return {
                ...state,
                stockMovementDataOnLoad: true
            }
        case ApiConstants.API_GET_STOCK_DATA_SUCCESS:
            return {
                ...state,
                stockMovementDataOnLoad: false,
                stockMovementData: action?.result
            }
        case ApiConstants.API_GET_UPDATE_ATTRIBUTE_LOAD:
            return {
                ...state,
                stockMovementATTOnLoad: true
            }
        case ApiConstants.API_GET_UPDATE_ATTRIBUTE_SUCCESS:
            return {
                ...state,
                stockMovementATTOnLoad: false
            }

        case ApiConstants.API_GET_SALES_ORDER_STATUS_DETAILS_LOAD:
            return {
                ...state,
                stageOnload: true
            }
        case ApiConstants.API_GET_SALES_ORDER_STATUS_DETAILS_SUCCESS:
            if (action?.payload.activity == AppReferences.activities.a06) {
                state.salesOrderStatusDetails = action?.result;
            }
            else {
                console.log("reducerrrrrr", action?.result)
                state.searchSOData = action?.result;
            }
            return {
                ...state,
                stageOnload: false,
            }

        case ApiConstants.API_LOCK_AND_PROCESS_LOAD:
            return {
                ...state,
                processOnload: true
            }
        case ApiConstants.API_LOCK_AND_PROCESS_SUCCESS:
            return {
                ...state,
                processOnload: false,
                lockAndProcess: action?.result
            }
        case ApiConstants.API_CANCEL_SALES_ORDER_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_CANCEL_SALES_ORDER_SUCCESS:
            return {
                ...state,
                onload: false,
                cancelSO: action?.result
            }
        case ApiConstants.API_SEARCH_PURCHASE_ORDER_LOAD:
            return {
                ...state,
                searchPoOnLoad: true
            }
        case ApiConstants.API_SEARCH_PURCHASE_ORDER_SUCCESS:
            return {
                ...state,
                searchPoOnLoad: false,
                searchPOData: action?.result
            }
        case ApiConstants.API_WM_CONFIGURE_LOAD:
            return {
                ...state,
                whConfigurationOnLoad: true
            }
        case ApiConstants.API_WM_CONFIGURE_SUCCESS:
            return {
                ...state,
                whConfigurationOnLoad: false,
                whConfigurationData: action?.result
            }
        case ApiConstants.API_GET_CATEGORY_LIST_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_GET_CATEGORY_LIST_SUCCESS:
            if (action.payload.activity == AppReferences.activities.a03) {
                state.editCategory = action.result
            }
            else {
                state.categoryInfo = action?.result
            }
            return {
                ...state,
                onload: false,
            }
        case ApiConstants.API_CREATE_CATEGORY_LOAD:
            return {
                ...state,
                createOnload: true
            }
        case ApiConstants.API_CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                createOnload: false,
                createCategoryInfo: action?.result
            }
        case ApiConstants.API_WM_ADD_LOAD:
            return {
                ...state,
                whAddOnLoad: true
            }
        case ApiConstants.API_WM_ADD_SUCCESS:
            return {
                ...state,
                whAddOnLoad: false,

            }
        case ApiConstants.API_WM_EDIT_LOAD:
            return {
                ...state,
                whEditOnLoad: true
            }
        case ApiConstants.API_WM_EDIT_SUCCESS:
            return {
                ...state,
                whEditOnLoad: false
            }
        case ApiConstants.API_CONFIGURE_DATA_LOAD:
            return {
                ...state,
                configureOnLoad: true,

            }
        case ApiConstants.API_CONFIGURE_DATA_SUCCESS:
            return {
                ...state,
                configureOnLoad: false,
                configureData: action?.result
            }
        case ApiConstants.API_VIEW_CONFIGURE_MODAL_DATA_LOAD:
            return {
                ...state,
                viewConfigureModalOnLoad: true,

            }
        case ApiConstants.API_VIEW_CONFIGURE_MODAL_DATA_SUCCESS:
            return {
                ...state,
                viewConfigureModalOnLoad: false,
                viewConfigureModalData: action?.result
            }


        case ApiConstants.API_GET_FORM_DROPDOWN_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_GET_FORM_DROPDOWN_SUCCESS:
            return {
                ...state,
                onload: false,
                formMeta: action.result
            }
        case ApiConstants.API_MOVE_ADJUST_INVENTORY_LOAD:
            return {
                ...state,
                moveAdjustInventoryOnload: true
            }
        case ApiConstants.API_MOVE_ADJUST_INVENTORY_SUCCESS:
            return {
                ...state,
                moveAdjustInventoryOnload: false,
            }
        case ApiConstants.API_MOVE_QUARANTINE_INVENTORY_LOAD:
            return {
                ...state,
                moveQuarantineInventoryOnload: true
            }
        case ApiConstants.API_MOVE_QUARANTINE_INVENTORY_SUCCESS:
            return {
                ...state,
                moveQuarantineInventoryOnload: false,
            }
        case ApiConstants.API_CHANGE_SKU_LOCATION_LOAD:
            return {
                ...state,
                changeSKULocationOnload: true
            }
        case ApiConstants.API_CHANGE_SKU_LOCATION_SUCCESS:
            return {
                ...state,
                changeSKULocationOnload: false,
            }
        case ApiConstants.API_CHANGE_BIN_BOX_LOCATION_LOAD:
            return {
                ...state,
                changeBinBoxLocationOnload: true
            }
        case ApiConstants.API_CHANGE_BIN_BOX_LOCATION_SUCCESS:
            return {
                ...state,
                changeBinBoxLocationOnload: false,
            }
        case ApiConstants.API_CONFIGURE_WAREHOUSE_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_CONFIGURE_WAREHOUSE_SUCCESS:
            return {
                ...state,
                onload: false,
                warehouseConfigure: action.result
            }
        case ApiConstants.API_GET_DASHBOARD_MENU_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_GET_DASHBOARD_MENU_SUCCESS:
            return {
                ...state,
                onload: false,
                onboardingDetails: action.result
            }
        case ApiConstants.API_UPLOAD_FILE_LOAD:
            return {
                ...state,
                uploadOnload: true
            }
        case ApiConstants.API_UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                uploadOnload: false,
                uploadFile: action.result
            }

        case ApiConstants.API_VIEW_STORAGE_DETAIL_LOAD:
            return {
                ...state,
                onload: true,
                storageDetails: null
            }
        case ApiConstants.API_VIEW_STORAGE_DETAIL_SUCCESS:
            return {
                ...state,
                onload: false,
                storageDetails: action.result
            }
        case ApiConstants.API_GET_CLIENT_DEDICATED_LOCATION_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_GET_CLIENT_DEDICATED_LOCATION_SUCCESS:
            return {
                ...state,
                onload: false,
                dedicatedLocation: action.result
            }
        case ApiConstants.API_GET_ON_BOARDING_EDIT_DATA_LOAD:
            return {
                ...state,
                onBoardingEditOnLoad: true,
            }
        case ApiConstants.API_GET_ON_BOARDING_EDIT_DATA_SUCCESS:
            return {
                ...state,
                onBoardingEditOnLoad: false,
                onBoardingEditData: action.result
            }

        case ApiConstants.API_GO_LIVE_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_GO_LIVE_SUCCESS:
            return {
                ...state,
                onload: false,
                liveEnable: action.result
            }
        case ApiConstants.API_GET_FILES_LOAD:
            return {
                ...state,
                getAttachmentOnLoad: true,
                attachementDetails: null
            }
        case ApiConstants.API_GET_FILES_SUCCESS:
            return {
                ...state,
                getAttachmentOnLoad: false,
                attachementDetails: action.result
            }
        case ApiConstants.API_SET_ATTACHMENT_AS_DEFUALT_LOAD:
            return {
                ...state,
                onload: true
            }
        case ApiConstants.API_SET_ATTACHMENT_AS_DEFUALT_SUCCESS:
            return {
                ...state,
                onload: false,
                // attachementDetails: action.result
            }
        case ApiConstants.API_GET_REPORT_LIST_LOAD:
            return {
                ...state,
                reportOnload: true,
            }
        case ApiConstants.API_GET_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reportOnload: false,
                reportList: action.result
            }
        case ApiConstants.API_MOVE_TO_RTO_LOAD:
            return {
                ...state,
                moveToRtoOnload: true,

            }
        case ApiConstants.API_MOVE_TO_RTO_SUCCESS:
            return {
                ...state,
                moveToRtoOnload: false,
                completeCartonData: action?.result
            }
        case ApiConstants.API_GET_EXPAND_CARTON_DATA_LOAD:
            return {
                ...state,
                cartonOnload: true,
            }
        case ApiConstants.API_GET_EXPAND_CARTON_DATA_SUCCESS:
            return {
                ...state,
                cartonOnload: false,
                cartonExpandList: action.result
            }

        case ApiConstants.API_GET_EXPAND_DATA_LOAD:
            return {
                ...state,
                cartonExpandOnload: true,
            }
        case ApiConstants.API_GET_EXPAND_DATA_SUCCESS:
            return {
                ...state,
                cartonExpandOnload: false,
                expandListForCarton: action.result
            }
        case ApiConstants.API_GET_DIMENSION_LOAD:
            return {
                ...state,
                dimensionOnload: true,
            }
        case ApiConstants.API_GET_DIMENSION_SUCCESS:
            return {
                ...state,
                dimensionOnload: false,
                dimensionList: action.result
            }
        case ApiConstants.API_UPDATE_DIMENSION_LOAD:
            return {
                ...state,
                dimensionUpdateOnload: true,
            }
        case ApiConstants.API_UPDATE_DIMENSION_SUCCESS:
            return {
                ...state,
                dimensionUpdateOnload: false,
                dimensionUpdateList: action.result
            }
        case ApiConstants.API_CARTON_REQC_LOAD:
            return {
                ...state,
                reQCOnLoad: true,
            }
        case ApiConstants.API_CARTON_REQC_SUCCESS:
            return {
                ...state,
                reQCOnLoad: false,
                reQCList: action.result,
            }
        case ApiConstants.API_CLOSE_CARTON_LOAD:
            return {
                ...state,
                closeCartonOnLoad: true,
            }
        case ApiConstants.API_CLOSE_CARTON_SUCCESS:
            return {
                ...state,
                closeCartonOnLoad: false
            }
        case ApiConstants.API_SCAN_CARTON_SKU_LOAD:
            return {
                ...state,
                scanSKUOnLoad: true,
            }
        case ApiConstants.API_SCAN_CARTON_SKU_SUCCESS:
            return {
                ...state,
                scanSKUOnLoad: false
            }
        case ApiConstants.API_DELETE_CARTON_LOAD:
            return {
                ...state,
                deleteCartonOnLoad: true,
            }
        case ApiConstants.API_DELETE_CARTON_SUCCESS:
            console.log("deleteMsg action?.msg", action.result)
            return {
                ...state,
                deleteCartonOnLoad: false,
                deleteMsg: action.result
            }
        case ApiConstants.API_PRINT_SPECIFIC_CARTON_STICKER_LOAD:
            return {
                ...state,
                specificStickerOnLoad: true,
            }
        case ApiConstants.API_PRINT_SPECIFIC_CARTON_STICKER_SUCCESS:
            return {
                ...state,
                specificStickerOnLoad: false,
                cartonStickeringList: action.result,
            }
        case ApiConstants.API_PRINT_ALL_CARTON_STICKER_LOAD:
            return {
                ...state,
                allStickerOnLoad: true,
            }
        case ApiConstants.API_PRINT_ALL_CARTON_STICKER_SUCCESS:
            return {
                ...state,
                allStickerOnLoad: false,
                cartonStickeringList: action.result,
            }
        case ApiConstants.API_PRINT_SPECIFIC_CARTON_PACKING_SLIP_LOAD:
            return {
                ...state,
                specificPackingSlipOnLoad: true,

            }
        case ApiConstants.API_PRINT_SPECIFIC_CARTON_PACKING_SLIP_SUCCESS:
            return {
                ...state,
                specificPackingSlipOnLoad: false,
                PackingSlip: action.result,

            }
        case ApiConstants.API_PRINT_ALL_CARTON_PACKING_SLIP_LOAD:
            return {
                ...state,
                allPackingSlipOnLoad: true,
            }
        case ApiConstants.API_PRINT_ALL_CARTON_PACKING_SLIP_SUCCESS:
            return {
                ...state,
                allPackingSlipOnLoad: false,
                PackingSlip: action.result,
            }

        case ApiConstants.API_GET_DATE_REPORT_LIST_LOAD:
            return {
                ...state,
                reportDateOnload: true,
            }
        case ApiConstants.API_GET_DATE_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reportDateOnload: false,
                reportDateList: action.result
            }
        case ApiConstants.API_GET_GATEIN_REPORT_LIST_LOAD:
            return {
                ...state,
                reportGateInOnload: true,
            }
        case ApiConstants.API_GET_GATEIN_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reportGateInOnload: false,
                reportGateInList: action.result
            }
        case ApiConstants.API_GET_EXPAND_GATEIN_REPORT_LIST_LOAD:
            return {
                ...state,
                reportExpandGateInOnload: true,
            }
        case ApiConstants.API_GET_EXPAND_GATEIN_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reportExpandGateInOnload: false,
                reportExpandGateInList: action.result
            }
        case ApiConstants.API_GET_SEARCH_GATEIN_REPORT_LIST_LOAD:
            return {
                ...state,
                reportSearchGateInOnload: true,
            }
        case ApiConstants.API_GET_SEARCH_GATEIN_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reportSearchGateInOnload: false,
                reportSearchGateInList: action.result
            }
        case ApiConstants.API_GET_SAN_REPORT_LIST_LOAD:
            return {
                ...state,
                reportSANOnload: true,
            }
        case ApiConstants.API_GET_SAN_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reportSANOnload: false,
                reportSANList: action.result
            }
        case ApiConstants.API_GET_EXPAND_SAN_REPORT_LIST_LOAD:
            return {
                ...state,
                reportExpandSANOnload: true,
            }
        case ApiConstants.API_GET_EXPAND_SAN_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reportExpandSANOnload: false,
                reportExpandSANList: action.result
            }
        case ApiConstants.API_GET_GRN_REPORT_LIST_LOAD:
            return {
                ...state,
                reportGRNOnload: true,
            }
        case ApiConstants.API_GET_GRN_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reportGRNOnload: false,
                reportGRNList: action.result
            }
        case ApiConstants.API_GET_EXPAND_GRN_REPORT_LIST_LOAD:
            return {
                ...state,
                reportExpandGRNOnload: true,
            }
        case ApiConstants.API_GET_EXPAND_GRN_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reportExpandGRNOnload: false,
                reportExpandGRNList: action.result
            }
        case ApiConstants.API_GET_GAE_REPORT_LIST_LOAD:
            return {
                ...state,
                reportGAEOnload: true,
            }
        case ApiConstants.API_GET_GAE_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reportGAEOnload: false,
                reportGAEList: action.result
            }
        case ApiConstants.API_GET_EXPAND_GAE_REPORT_LIST_LOAD:
            return {
                ...state,
                reportExpandGAEOnload: true,
            }
        case ApiConstants.API_GET_EXPAND_GAE_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reportExpandGAEOnload: false,
                reportExpandGAEList: action.result
            }
        case ApiConstants.API_GET_OD_REPORT_LIST_LOAD:
            return {
                ...state,
                reportODOnload: true,
            }
        case ApiConstants.API_GET_OD_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reportODOnload: false,
                reportODList: action.result
            }
        case ApiConstants.API_GET_EXPAND_OD_REPORT_LIST_LOAD:
            return {
                ...state,
                reportExpandODOnload: true,
            }
        case ApiConstants.API_GET_EXPAND_OD_REPORT_LIST_SUCCESS:
            return {
                ...state,
                reportExpandODOnload: false,
                reportExpandODList: action.result
            }
        case ApiConstants.API_WORK_ON_THIS_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_WORK_ON_THIS_SUCCESS:
            return {
                ...state,
                onload: false,
                workOnthis: action.result
            }
        case ApiConstants.API_REQC_SAVE_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_REQC_SAVE_SUCCESS:
            return {
                ...state,
                onload: false,
                reqcResponse: action.result
            }
        case ApiConstants.CLEAR_REDUCER:
            state[action.key] = null;
            return {
                ...state,
                onload: false,
            }
        case ApiConstants.API_CYCLE_COUNT_SCANNING_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_CYCLE_COUNT_SCANNING_SUCCESS:
            return {
                ...state,
                onload: false,
                cycleCountData: action.result
            }
        case ApiConstants.API_CYCLE_COUNT_MASTER_DATA_LOAD:
            return {
                ...state,
                cycleCountDataOnload: true,
            }
        case ApiConstants.API_CYCLE_COUNT_MASTER_DATA_SUCCESS:
            return {
                ...state,
                cycleCountDataOnload: false,
                cycleCountMasterData: action.result
            }
        case ApiConstants.API_CYCLE_COUNT_EXPAND_DATA_LOAD:
            return {
                ...state,
                cycleCountExpandDataOnload: true,
            }
        case ApiConstants.API_CYCLE_COUNT_EXPAND_DATA_SUCCESS:
            return {
                ...state,
                cycleCountExpandDataOnload: false,
                cycleCountExpandData: action.result
            }
        case ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_LIST_LOAD:
            return {
                ...state,
                cycleCountStorageSKUOnLoad: true,
            }
        case ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_LIST_SUCCESS:
            return {
                ...state,
                cycleCountStorageSKUOnLoad: false,
                cycleCountStorageSKUList: action.result
            }

        case ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_SELECT_LOAD:
            return {
                ...state,
                cycleCountStorageSKUSelectOnLoad: true,
            }
        case ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_SELECT_SUCCESS:
            return {
                ...state,
                cycleCountStorageSKUSelectOnLoad: false,
                cycleCountStorageSKUSelectList: action.result
            }

        case ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_QTY_UPDATE_LOAD:
            return {
                ...state,
                cycleCountStorageSKUQTYUpdateOnLoad: true,
            }
        case ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_QTY_UPDATE_SUCCESS:
            return {
                ...state,
                cycleCountStorageSKUQTYUpdateOnLoad: false,
            }
        case ApiConstants.API_CLOSE_CYCLE_COUNT_LOAD:
            return {
                ...state,
                cycleCountCloseOnLoad: true,
            }
        case ApiConstants.API_CLOSE_CYCLE_COUNT_SUCCESS:
            return {
                ...state,
                cycleCountCloseOnLoad: false,
            }

        case ApiConstants.API_CLOSE_CYCLE_APPROVE_REJECT_LOAD:
            return {
                ...state,
                approveRejectCycleCountOnLoad: true,
            }
        case ApiConstants.API_CLOSE_CYCLE_APPROVE_REJECT_SUCCESS:
            return {
                ...state,
                approveRejectCycleCountOnLoad: false,
            }
        case ApiConstants.API_START_CYCLE_COUNT_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_START_CYCLE_COUNT_SUCCESS:
            return {
                ...state,
                onload: false,
                startCycleCountData: action.result
            }
        case ApiConstants.API_CREATE_CLIENT_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_CREATE_CLIENT_SUCCESS:
            return {
                ...state,
                onload: false,
                createClientInfo: action.result
            }
        case ApiConstants.API_EDIT_CLIENT_LOAD:
            return {
                ...state,
                onload: true,
            }
        case ApiConstants.API_EDIT_CLIENT_SUCCESS:
            return {
                ...state,
                onload: false,
            }
        case ApiConstants.API_CREATE_CONTRACT_LOAD:
            return {
                ...state,
                createContractonload: true,
            }
        case ApiConstants.API_CREATE_CONTRACT_SUCCESS:
            return {
                ...state,
                createContractonload: false,
            }
        case ApiConstants.API_CLIENT_INFO_LOAD:
            return {
                ...state,
                clientInfoLoad: true
            }
        case ApiConstants.API_CLIENT_INFO_SUCCESS:
            return {
                ...state,
                clientInfoLoad: false,
                clientDataInfo: action.result,
            }
        case ApiConstants.API_START_ONBOARDING_LOAD:
            return {
                ...state,
                onBoardingLoad: true,
            }
        case ApiConstants.API_START_ONBOARDING_SUCCESS:
            return {
                ...state,
                onBoardingLoad: false,
            }



        default:
            return {
                ...state
            }
    }
}
export default ProcessReducerState;