import React from 'react';

function ExportIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
            <path d="M17.1889 12.2007L13.9837 9.91878C13.951 9.89528 13.9123 9.88133 13.8721 9.87848C13.8319 9.87562 13.7917 9.88399 13.7559 9.90264C13.6823 9.94049 13.6372 10.0231 13.637 10.1063L13.6386 11.1865H10.7552C10.6326 11.1865 10.5137 11.2728 10.5137 11.3955V13.3823C10.5137 13.505 10.6326 13.5896 10.7552 13.5896H13.6677V14.6783C13.6677 14.7613 13.6997 14.8375 13.7735 14.8755C13.8473 14.9135 13.9216 14.9071 13.9891 14.8587L17.1896 12.5618C17.2477 12.5203 17.2724 12.4531 17.2724 12.3816V12.3813C17.2724 12.3094 17.2474 12.2424 17.1889 12.2007Z" fill="var(--app-004986)" />
            <path d="M11.9406 14.4577H10.8137C10.734 14.4577 10.6576 14.4893 10.6013 14.5457C10.545 14.602 10.5133 14.6784 10.5133 14.7581H10.5121V15.2807H3.45526V7.09681H6.53258C6.61225 7.09681 6.68865 7.06516 6.74499 7.00883C6.80132 6.9525 6.83297 6.87609 6.83297 6.79643V3.71911H10.5119V10.0317H10.5138C10.5155 10.1101 10.5478 10.1848 10.6038 10.2396C10.6598 10.2945 10.7351 10.3253 10.8135 10.3254H11.9404C12.1041 10.3254 12.2365 10.1944 12.2401 10.0317H12.2408V2.98863H12.2399V2.29062C12.2399 2.21095 12.2083 2.13455 12.1519 2.07822C12.0956 2.02188 12.0192 1.99023 11.9395 1.99023H6.23219L1.72656 6.49604V16.709C1.72656 16.875 1.86096 17.0094 2.02695 17.0094H11.9395C12.0192 17.0094 12.0956 16.9778 12.1519 16.9214C12.2083 16.8651 12.2399 16.7887 12.2399 16.709V16.284H12.2408V14.7577C12.2408 14.6781 12.2091 14.6018 12.1528 14.5456C12.0965 14.4893 12.0202 14.4577 11.9406 14.4577Z" fill="var(--app-004986)" />
        </svg>
    );
}

export default ExportIcon;