import { Button, Checkbox, Col, message, Row} from 'antd';
import React, { useEffect, useState } from 'react';
import AppConstants from '../../../Globals/AppConstants';
import NavbarLayout from '../../NavbarLayout/NavbarLayout';
import SubHeader from "../../Header/Header";
import '../QC.scss';
import { Link, useLocation } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import AppButtons from '../../../CustomComponent/AppButtons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { getUserInfo, getWareHouseId } from '../../../Utils/SessionStorage';
import { AssociatePoAction, createOrAssociatePoSendAction } from '../../../Store/Actions/ProcessWMSAction';
import { useDispatch, useSelector } from 'react-redux';
import AppReferences from '../../../Globals/AppReferences';


function AssociatePo() {

    let warehouse = getWareHouseId();
    const getUserData = getUserInfo();
    const [color, setColor] = useState(false)
    let location = useLocation();
    const { item, invoice } = location.state;
    const dispatch = useDispatch();
    const { getAssociatePoInfo } = useSelector((state: any) => state.ProcessReducerState);
    const [poItem, setPoItem] = useState<any>([]);

    const onChange = (e: CheckboxChangeEvent, item: any) => {
        if((poItem?.find((x: any) => x == item?.ID))){
            setPoItem((poItem?.filter((x: any) => x != item?.ID)));
        }else{
            setPoItem([...poItem, item?.ID]);
        }
    };

    useEffect(() => {
        try {
            let custOpnfor = (item?.id).split("|")
            let payload = {
                "activity": AppReferences.activities.av,
                "warehouse": warehouse,
                "opnfor": custOpnfor[1],
                "customer": custOpnfor[0],
                "transaction": custOpnfor[2],
                "user": getUserData?.UserName,
                "where": ""
            }
            dispatch(AssociatePoAction(payload))
        } catch (error) {
            console.log("Error in AssociatePoAction Call", error);
        }

    }, [])

    const createPo = ( ) =>{
        let payload = {
            opnfor: '130200',
            activity: AppReferences.activities.ax01, // Create PO
            transaction: getAssociatePoInfo?.header[0].iNo,
            warehouse: warehouse,
            customer:  getAssociatePoInfo?.header[0].cId,
            user: getUserInfo()?.UserName
        }
        dispatch(createOrAssociatePoSendAction(payload))   
    }

    const puchaseOrder = (item: any) => {
        return (
            <div className='purchase-order-bar'>
                <Row gutter={20}>
                    <Col span={6}>
                        <div className='purchse-order'>
                            {"Purchase Order - "}<span>{item?.ID}</span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className='purchse-order'>
                            {"Reference Number - "}<span>{item?.RefernceNo}</span>
                        </div>
                    </Col>
                    <Col span={7}>
                        <div className='purchse-order'>
                            {"Created On - "}<span>{item?.CreatedDate}</span>
                        </div>
                    </Col>
                    <Col span={5} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: 10, paddingBottom: 10 }}>
                        <Checkbox onChange={(e: any) => onChange(e, item)}>Associate this PO</Checkbox>
                    </Col>
                </Row>
            </div>
        )
    }

    const PurchaseCatogeries = () => {
        return (

            <div className='purchase-categories'>
                <Row gutter={25}>
                    <Col span={8} style={{ textAlign: "center" }}>
                        {AppConstants.skuDescription}
                    </Col>
                    <Col span={8} style={{ textAlign: "center" }}>
                        {AppConstants.orderQuantity}
                    </Col>
                    {/* <Col span={6}>
                        {AppConstants.invoiceQuantity}
                    </Col> */}
                    <Col span={8} style={{ textAlign: "center" }}>
                        {"Balance Qty"}
                    </Col>
                </Row>
            </div>
        )
    }

    const purchaseDatas = (value: any) => {
        return (
            <div className='purchase-datas'>
                <Row gutter={25}>
                    <Col span={8} style={{ textAlign: "center" }}>
                        {value?.SKU}
                    </Col>
                    <Col span={8} style={{ textAlign: "center" }}>
                        {value?.OrderedQuantity}
                    </Col>
                    {/* <Col span={6}>
                        40
                    </Col> */}
                    <Col span={8} style={{ textAlign: "center" }}>
                        {value?.Balance}
                    </Col>
                </Row>
            </div>
        )
    }

    const sentAssociatePo = () => {
        try {
            if (color === true) {
                let payload = {
                    "activity": AppReferences.activities.ax02,
                    "warehouse": warehouse,
                    "opnfor": getAssociatePoInfo?.title?.stageNo,
                    "customer": getAssociatePoInfo?.header[0].cId,
                    "transaction": getAssociatePoInfo?.header[0].iNo,
                    "user": getUserData?.UserName,
                    "where": poItem[0]
                }
                dispatch(createOrAssociatePoSendAction(payload))
            } else {
                message.error("Associate this PO")
            }
        } catch (error) {
            console.log("Error in sentAssociatePo::", error);

        }

    }


    const contentView = () => {
        try {
            return (
                <div className='mt-45'>
                    {(getAssociatePoInfo?.POs || []).map((item: any, index: any) => {
                        return (
                            <div style={{ marginTop: 10 }} className={(poItem?.find((x: any) => x == item?.ID)) ? "associatepo-container1" : 'associatepo-container'}>
                                {puchaseOrder(item)}
                                <div style={{ padding: 10 }}>
                                    {PurchaseCatogeries()}
                                    {(item.Lines || []).map((value: any) => {
                                        return (
                                            purchaseDatas(value)
                                        )
                                    })}
                                </div>
                            </div>)
                    })}


                </div>
            )
        } catch (ex) {
            console.log("Error in contentView::" + ex)
        }
    }




    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                <AppButtons
                    onClick={() => createPo()}
                    buttonType={AppConstants.buttonTypes.whiteButton}
                    text="Create New PO">
                </AppButtons>
                <AppButtons
                    disabled={poItem?.length != 0}
                    onClick={() => sentAssociatePo()}
                    buttonType={AppConstants.buttonTypes.solidLinearGreen}
                    text="Associate PO">
                </AppButtons>
                <Link to="/process/QC" state={{ Option: 1 }}>
                    <Button className="close-btn">
                        <CloseOutlined />
                    </Button>
                </Link>
            </div>
        )
    }

    return (
        <div>
            <NavbarLayout
                titleContent="sub-head"
                process={getAssociatePoInfo?.title}
            />
            <SubHeader
                title={<div>Associate PO -<span> {invoice} </span></div>}
                btnView="true"
                inputView="true"
                paginationView="false">
                {childrenView()}
            </SubHeader>
            {contentView()}
        </div>
    )
}

export default AssociatePo;