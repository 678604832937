import { Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import Search from 'antd/lib/transfer/search';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { isArrayNotEmpty } from '../../Globals/Helper';
import SubHeader from '../Header/Header';
import SkeletonLoader from './SkeletonLoader';

function DispatchedScreen(props: any) {
    const [boxQty, setBoxQty] = useState<any>({});
    const [form]: any = Form.useForm();
    const [validationPopup, setValidationPopup] = useState<any>({
        matched: false,
        mismatched: false
    });
    const [validationForm]: any = Form.useForm();
    const reducerstate = useSelector((state: any) => state?.ProcessReducerState);
    const { statusDetailsOnload } = reducerstate;
    const [
        statusDetails,
        channel,
        tatClient,
        clientValue,
        process,
        getStatusDetails,
        getChannelStatus
    ]: any = useOutletContext();

    const headerView = () => {
        return (
            <SubHeader
                title={"Dispatched (Last 24 hours)"}
                paginationView="false"
                inputView="true"
                btnView="false"
                fromOrgin={'orderProcessing'}
            >
                {/* <div className='d-flex flex-end'>
                    <div style={{fontFamily:'Redhat-Regular'}}>
                        <Search placeholder='Search' />
                    </div>
                </div> */}
            </SubHeader>
        )
    }

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={5}>
                    {AppConstants.orderId}
                </Col>
                <Col span={5}>
                    SKU, Description
                </Col>
                <Col span={9} className='d-flex jc-c'>
                    {AppConstants.orderedqty}
                </Col>
                <Col span={5} className='d-flex jc-c'>
                    Dispatched Qty
                </Col>
            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        return (
            <Row className={'table-body mt-10'}>
                <Col span={5} className="open-icon d-flex align-c">
                    <div className='text'>
                        {item?.OrderID}
                    </div>
                </Col>
                <Col span={5} >
                    <div className='text'>
                        {item?.ItemID + ", " + item?.Description}
                    </div>
                </Col>
                <Col span={9} className=' d-flex align-c jc-c'>
                    <div className='text-1'>
                        {item?.OrderedQuantity}
                    </div>
                </Col>
                <Col span={5} className=' d-flex align-c jc-c'>
                    <div className='text-1'>
                        {item?.PiecesPicked}
                    </div>
                </Col>
            </Row>
        )
    }

    const tableView = () => {
        return (
            <div>
                {tableHeaderView()}
                <Form
                    form={form}
                >
                    {statusDetails?.lineitems?.map((x: any, index: any) => tableBodyView(x, index))}
                </Form>
            </div>
        )
    }

    const contentView = () => {
        return (
            <>
           { isArrayNotEmpty(statusDetails?.lineitems) ?
            <div className='table-screen-container'>
                {tableView()}
            </div>
            :
            <div className='p-20'>
                <NoData
                    className='mt-45'
                    description={
                        <>{AppConstants.nodata}<span className='redhat-bold ml-5'>Dispatched</span></>
                    }
                />
            </div>}
        </>
        )
    }

    const boxesMismatched = () => {
        return (
            <>
                <div className='redhat-bold font-16 mb-10' style={{ color: '#CD3232' }}>
                    Stop the shipment. Number of boxes are incorrect.
                </div>
                <div className='mb-20 redhat-semibold font-16'>
                    Contact warehouse manager for OTP
                </div>
                <div className='redhat-bold'>
                    Enter OTP:
                </div>
                <div className='mt-10'>
                    <Form.Item
                        name="otp"
                        rules={[{ required: true, message: 'Enter OTP to Process' }]}
                    >
                        <Input
                            placeholder='Enter'
                        />
                    </Form.Item>
                </div>
                <div className='d-flex flex-end' style={{ marginTop: 10 }}>
                    <AppButtons
                        buttonType={AppConstants.buttonTypes.whiteButton}
                        onClick={() => setValidationPopup({ ...validationPopup, mismatched: false })}
                        text="Cancel"
                    />
                    <AppButtons
                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        // onClick={() => completeReceiveInProgressApi()}
                        text="Proceed"
                    />
                </div>
            </>
        )
    }

    const boxesMtched = () => {
        return (
            <>
                <Row gutter={20}>
                    <Col span={10} className="mb-15">
                        <Form.Item>
                            <Select className='w-100'>
                                <Select.Option>
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={10} className="mb-15">
                        <Form.Item>
                            <Input className='w-100' />
                        </Form.Item>
                    </Col>
                    <Col span={4} className="mb-15">
                        <AppButtons
                            text="Process"
                            buttonType={AppConstants.buttonTypes.whiteButton}
                        />
                    </Col>
                    <Col span={8} className="mb-15">
                        <div className='redhat-semibold mb-5'>
                            {AppConstants.Courier}
                        </div>
                        <Form.Item>
                            <Select className='w-100'>
                                <Select.Option>
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} className="mb-15">
                        <div className='redhat-semibold mb-5'>
                            {AppConstants.awbNumber}
                        </div>
                        <Form.Item>
                            <Input className='w-100' />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="mb-15">
                        <div className='redhat-semibold mb-5'>
                            {AppConstants.docketNumber}
                        </div>
                        <Form.Item>
                            <Input className='w-100' />
                        </Form.Item>
                    </Col>
                    <Col span={8} >
                        <div className='redhat-semibold mb-5'>
                            {AppConstants.vehicleNumber}
                        </div>
                        <Form.Item>
                            <Input className='w-100' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <div className='redhat-semibold mb-5'>
                            {AppConstants.drivercontact}
                        </div>
                        <Form.Item>
                            <Input className='w-100' />
                        </Form.Item>
                    </Col>
                </Row>
                <div className='d-flex flex-end' style={{ marginTop: 10 }}>
                    <AppButtons
                        buttonType={AppConstants.buttonTypes.whiteButton}
                        onClick={() => setValidationPopup({ ...validationPopup, matched: false })}
                        text="Cancel"
                    />
                    <AppButtons
                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        // onClick={() => completeReceiveInProgressApi()}
                        text="Save"
                    />
                </div>
            </>
        )
    }

    const popupView = () => {
        return (
            <Modal
                title={validationPopup?.matched && <div className='redhat-bold mb-10 font-18'>
                    Please Provide additional shipment Information
                </div>}
                className='modal-view'
                // onCancel={()=>setValidationPopup({...validationPopup, mismatched: false})}
                // onOk={}
                style={{ width: "55%" }}
                open={validationPopup?.mismatched || validationPopup?.matched}
                footer={null}
            >
                <Form form={validationForm}>
                    {validationPopup?.mismatched ?
                        boxesMismatched()
                        :
                        boxesMtched()
                    }
                </Form>
            </Modal>
        )
    }

    return (

        <>
            {!statusDetailsOnload ?
                <div className='gate-out-screen'>
                    {headerView()}
                    {contentView()}
                    {popupView()}
                </div>
                :
                <SkeletonLoader />
            }
        </>
    );
}

export default DispatchedScreen;