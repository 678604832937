import ApiConstants from "../../Globals/ApiConstants";

export function getGateInEntryAction(payload: any) {
    const action = {
        type: ApiConstants.API_GET_GATE_IN_ENTRY_LOAD,
        payload
    }
    return action;
}

export function getWarehousesAction(payload: any) {
    const action = {
        type: ApiConstants.API_GET_WAREHOUSES_ACTION_LOAD,
        payload
    }
    return action;
}

export function getStageDetailsAction(payload: any) {
    const action = {
        type: ApiConstants.API_GET_STAGE_DETAILS_LOAD,
        payload
    }
    return action;
}

export function getIndividualStageAction(payload: any) {
    const action = {
        type: ApiConstants.API_GET_INDIVIDUAL_STAGE_LOAD,
        payload
    }
    return action;
}

export function getProcessData(payload: any) {
    const action = {
        type: ApiConstants.API_GET_PROCESS_DATA_LOAD,
        payload
    }
    return action;
}
export function getClientData(payload: any){
    const action = {
        type: ApiConstants.API_GET_ADHOC_CLIENT_DATA_LOAD,
        payload
    }
    return action;
}

export function saveExpenseAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_ADHOC_SAVE_DATA_LOAD,
        payload
    }
    return action;
}

export function getadhocRecordNumberAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_ADHOC_EXPENSE_NO_LOAD,
        payload
    }
    return action;
}

export function getDetailedDataAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_ADHOC_EXPAND_DATA_LOAD,
        payload
    }
    return action;
}
export function getgateInEntryMetaAction(payload: any) {
    const action = {
        type: ApiConstants.API_GET_GATE_IN_ENTRY_META_LOAD,
        payload
    }
    return action;
}
export function gateInEntryAddAction(payload: any) {    
    const action = {
        type: ApiConstants.API_GATE_IN_ENTRY_ADD_LOAD,
        payload
    }
    return action;
}
export function getTransactionAction(payload: any) {    
    const action = {
        type: ApiConstants.API_GET_TRANSACTION_LOAD,
        payload
    }
    return action;
}

export function AssociatePoAction(payload: any) {
    const action = {
        type: ApiConstants.API_GET_ASSOCIATEPO_LOAD,
        payload
    }
    return action;
}
export function getPendingSanDetailsAction(payload: any) {    
    const action = {
        type: ApiConstants.API_GET_PENDING_SAN_DETAILS_LOAD,
        payload
    }
    return action;
}

export function createOrAssociatePoSendAction(payload : any) {
    const action = {
        type : ApiConstants.API_GET_ASSOCIATEPO_SEND_LOAD,
        payload
    }
    return action;}
export function getReceivingInProgressViewAction(payload: any) {    
    const action = {
        type: ApiConstants.API_GET_RECEIVING_IN_PROGRESS_VIEW_LOAD,
        payload
    }
    return action;
}
export function saveReceivingInProgressAction(payload: any) {    
    const action = {
        type: ApiConstants.API_SAVE_RECEIVING_IN_PROGRESS_LOAD,
        payload
    }
    return action;
}
export function getReceivingInProgressBarCodeAction(payload: any) {    
    const action = {
        type: ApiConstants.API_GET_RECEIVING_IN_PROGRESS_BARCODE_LOAD,
        payload
    }
    return action;
}
export function getUloadingDetailsAction(payload: any) {    
    const action = {
        type: ApiConstants.API_GET_UNLOADING_DETAILS_LOAD,
        payload
    }
    return action;
}
export function sendStickeringAction(payload: any) {    
    const action = {
        type: ApiConstants.API_SEND_TO_STICKERING_LOAD,
        payload
    }
    return action;
}
export function getSickeringDetailsAction(payload: any) {    
    const action = {
        type: ApiConstants.API_GET_STICKER_DETAILS_LOAD,
        payload
    }
    return action;
}
export function getCaptureInvoiceDetailListAction(payload: any) {    
    const action = {
        type: ApiConstants.API_GET_CAPTURE_INVOICE_DETAIL_LIST_LOAD,
        payload
    }
    return action;}

export function completeStickeringAction(payload: any) {    
    const action = {
        type: ApiConstants.API_COMPLETE_STICKERING_LOAD,
        payload
    }
    return action;
}

export function getSendSanInfoAction(payload : any) {
    const action = {
        type: ApiConstants.API_SEND_SAN_INFO_LOAD,
        payload
    }
    return action;
}

export function getGrnDetailsAction(payload : any) {
    const action = {
        type: ApiConstants.API_GRN_INFO_LOAD,
        payload
    }
    return action;
}

export function getPutAwayDetailsAction(payload: any) {    
    const action = {
        type: ApiConstants.API_GET_PUTAWAY_DEATAILS_LOAD,
        payload
    }
    return action;
}

export function getMetaDataAction(payload: any) {    
    const action = {
        type: ApiConstants.API_GET_META_DATA_LOAD,
        payload
    }
    return action;
}

export function getMoveToBinMetaDataAction(payload: any) {    
    const action = {
        type: ApiConstants.API_GET_MOVE_TO_BIN_META_DATA_LOAD,
        payload
    }
    return action;
}

export function addInvoiceAndSkuDetailsAction(payload: any) {    
    const action = {
        type: ApiConstants.API_ADD_INVOICE_LOAD,
        payload
    }
    return action;
}

export function getCaptureInvoiceDetailsAction(payload: any) {    
    const action = {
        type: ApiConstants.API_GET_CAPTURE_INVOICE_DETAILS_LOAD,
        payload
    }
    return action;
}

export function getCaptureSKUDetailsAction(payload: any) {    
    const action = {
        type: ApiConstants.API_GET_CAPTURE_SKU_DETAILS_LOAD,
        payload
    }
    return action;
}

export function captureSKUWiseDetailsAndUnloadingAction(payload: any) {    
    const action = {
        type: ApiConstants.API_CAPTURE_SKU_WISE_LOAD,
        payload
    }
    return action;
}

export function sendSanDetails(payload: any){
    const action = {
        type: ApiConstants.API_SEND_SAN_LOAD,
        payload
    }
    return action;
}

export function getSkuAction(payload : any) {
    const action = {
        type: ApiConstants.API_GET_SKU_LOAD,
        payload
    }
    return action;
}

export function getConditionAction(payload: any) {    
    const action = {
        type: ApiConstants.API_GET_CONDITION_LOAD,
        payload
    }
    return action;
}
export function saveSkuAction(payload: any) {    
    const action = {
        type: ApiConstants.API_SAVE_SKU_LOAD,
        payload
    }
    return action;
}
export function completeReceiveInProgressAction(payload: any) {    
    const action = {
        type: ApiConstants.API_COMPLETE_RECEIVE_IN_PROGRESS_LOAD,
        payload
    }
    return action;}

export function getPutawayHeaderAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_GENERATE_PUTAWAY_INFO_LOAD,
        payload
    }
    return action;
}

export function getPutawayAssignMoverAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_ASSIGN_MOVER_INFO_LOAD,
        payload
    }
    return action;
}
export function getBarCodeCompleteDataAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_BAR_CODE_SCAN_SUCCESS_DATA_LOAD,
        payload
    }
    return action;
}

export function startPutawayAcyion(payload: any){
    const action = {
        type: ApiConstants.API_START_PUTAWAY_LOAD,
        payload
    }
    return action;
}

export function getClientListAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_CLIENT_DATA_LOAD,
        payload
    }
    return action;
}

export function getSKUListAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_SKU_DATA_LOAD,
        payload
    }
    return action;
}

export function getInventoryDataAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_INVENTORY_DATA_LOAD,
        payload
    }
    return action;
}

export function deleteReceiveInProgressAction(payload: any){
    const action = {
        type: ApiConstants.API_DELETE_RECEIVE_INPROGRESS_LOAD,
        payload
    }
    return action;
}

export function assignMoverAction(payload: any){
    const action = {
        type: ApiConstants.API_ASSIGN_MOVER_LOAD,
        payload
    }
    return action;
}

export function getCustomersAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_CUSTOMER_LOAD,
        payload
    }
    return action;
}

export function getPOAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_PO_LOAD,
        payload
    }
    return action;
}

export function getSkuDetailAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_SKU_DETAILS_LOAD,
        payload
    }
    return action;
}

export function getSKUandPOAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_SKU_AND_PO_LOAD,
        payload
    }
    return action;
}

export function createPoAction(payload: any){
    const action = {
        type: ApiConstants.API_CREATE_PO_LOAD,
        payload
    }
    return action;
}
export function addSKUforPoAction(payload: any){
    const action = {
        type: ApiConstants.API_ADD_SKU_FOR_PO_LOAD,
        payload
    }
    return action;
}
export function editSKUforPoAction(payload: any){
    const action = {
        type: ApiConstants.API_EDIT_SKU_FOR_PO_LOAD,
        payload
    }
    return action;
}

export function getSKUBatchDetailsAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_SKU_BATCH_DETAILS_LOAD,
        payload
    }
    return action;
}

export function addSKUBatchDetailsAction(payload: any){
    const action = {
        type: ApiConstants.API_ADD_SKU_BATCH_DETAILS_LOAD,
        payload
    }
    return action;
}

export function editSKUBatchDetailsAction(payload: any){
    const action = {
        type: ApiConstants.API_EDIT_SKU_BATCH_DETAILS_LOAD,
        payload
    }
    return action;
}

export function getSkuDescAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_SKU_DESC_LOAD,
        payload
    }
    return action;
}

export function updatePoAction(payload: any){
    const action = {
        type: ApiConstants.API_UPDATE_PO_LOAD,
        payload
    }
    return action;
}

export function deleteAction(payload: any){
    const action = {
        type: ApiConstants.API_DELETE_LOAD,
        payload
    }
    return action;
}

export function consignmentAction(payload: any){
    const action = {
        type: ApiConstants.API_CONSIGNMENT_LOAD,
        payload
    }
    return action;
}

export function getClientAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_CLIENT_LOAD,
        payload
    }
    return action;
}

export function getLeftMenuAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_LEFT_MENU_LOAD,
        payload
    }
    return action;
}

export function selectProduct(checked: any,value: any,key: any){
    const action = {
        type: ApiConstants.GET_SELECTED_PRODUCT,
        checked,
        value,
        key
    }
    return action;
}

export function updateExpenseAction(payload : any){
    const action = {
        type: ApiConstants.API_UPDATE_ADHOC_LOAD,
        payload
    }
    return action;
}

export function getChannelStatusAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_CHANNEL_STATUS_LOAD,
        payload
    }
    return action;
}

export function getChannelDetailsAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_CHANNEL_DETAILS_LOAD,
        payload
    }
    return action;
}

export function getPickerAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_PICKER_LOAD,
        payload
    }
    return action;
}

export function getSKUSubordersAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_SKU_SUBORDER_LOAD,
        payload
    }
    return action;
}

export function generatePicklistAction(payload: any){
    const action = {
        type: ApiConstants.API_GENERATE_PICKLIST_LOAD,
        payload
    }
    return action;
}

export function pickingCompleteAction(payload: any){
    const action = {
        type: ApiConstants.API_PICKING_COMPLETE_LOAD,
        payload
    }
    return action;
}

export function scanPickedAction(payload: any){
    const action = {
        type: ApiConstants.API_SCAN_PICKED_LOAD,
        payload
    }
    return action;
}
export function scanOnPackingSkuAction(payload: any){
    const action = {
        type: ApiConstants.API_SCAN_SKU_LOAD,
        payload
    }
    return action;
}

export function popupSKUScanAction(payload: any){
    const action = {
        type: ApiConstants.API_POPUP_SCAN_SKU_LOAD,
        payload
    }
    return action;
}

export function completePackingAction(payload: any){
    const action = {
        type: ApiConstants.API_COMPLETE_PACKING_LOAD,
        payload
    }
    return action;
}

export function getCourierServiceAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_COURIER_SERVICE_LOAD,
        payload
    }
    return action;
}

export function getShowOrdersAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_SHOW_ORDERS_LOAD,
        payload
    }
    return action;
}

export function createManifestAction(payload: any){
    const action = {
        type: ApiConstants.API_CREATE_MANIFEST_LOAD,
        payload
    }
    return action;
}

export function getContinuePutAway(payload: any){
    const action = {
        type: ApiConstants.API_GET_CONTINUE_PUTAWAY_LOAD,
        payload
    }
    return action;
}
export function validateGateOutBoxCountAction(payload: any){
    const action = {
        type: ApiConstants.API_VALIDATE_GATEOUT_BOX_COUNT_LOAD,
        payload
    }
    return action;
}

export function ShiptmentetailsAndOTPAction(payload: any){
    const action = {
        type: ApiConstants.API_SHIPMENT_OTP_LOAD,
        payload
    }
    return action;
}

export function handoverManifestAction(payload: any){
    const action = {
        type: ApiConstants.API_HAND_OVER_MANIFEST_ACTION_LOAD,
        payload
    }
    return action;
}

export function getSalesOrderlientAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_SALES_ORDER_CLIENT_LOAD,
        payload
    }
    return action;
}

export function getDetailsForSoAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_DETAILS_FOR_SO_LOAD,
        payload
    }
    return action;
}

export function getSalesOrderTypeAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_SALES_ORDERS_TYPE_LOAD,
        payload
    }
    return action;
}

export function createSOAction(payload: any){
    const action = {
        type: ApiConstants.API_CREATE_SO_LOAD,
        payload
    }
    return action;
}

export function getCustomerListForUserAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_CUSTOMER_LIST_LOAD,
        payload
    }
    return action;
}
export function getSKUAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_SKU_LIST_LOAD,
        payload
    }
    return action;
}
export function getLocationWiseQtyAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_LOCATION_QTY_LOAD,
        payload
    }
    return action;
}

export function getLocationDataAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_LOCATION_DATA_LOAD,
        payload
    }
    return action;
}

export function getStockMovementDataAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_STOCK_DATA_LOAD,
        payload
    }
    return action;
}
export function updateAttributeAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_UPDATE_ATTRIBUTE_LOAD,
        payload
    }
    return action;
}

export function getSalesOrderStatusDetailsAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_SALES_ORDER_STATUS_DETAILS_LOAD,
        payload
    }
    return action;
}

export function lockAndProcessAction(payload: any){
    const action = {
        type: ApiConstants.API_LOCK_AND_PROCESS_LOAD,
        payload
    }
    return action;
}

export function cancelSalesOrderAction(payload: any){
    const action = {
        type: ApiConstants.API_CANCEL_SALES_ORDER_LOAD,
        payload
    }
    return action;
}

export function getPurchaseOrderAction(payload: any){
    const action = {
        type: ApiConstants.API_SEARCH_PURCHASE_ORDER_LOAD,
        payload
    }
    return action;
}

export function getWHConfigureData(payload: any){
    const action = {
        type: ApiConstants.API_WM_CONFIGURE_LOAD,
        payload
    }
    return action;
}

export function addWhAction(payload: any){
    const action = {
        type: ApiConstants.API_WM_ADD_LOAD,
        payload
    }
    return action;
}

export function editWhAction(payload: any){
    const action = {
        type: ApiConstants.API_WM_EDIT_LOAD,
        payload
    }
    return action;
}
export function getCategoryListingAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_CATEGORY_LIST_LOAD,
        payload
    }
    return action;
}

export function createCategoryAction(payload: any){
    const action = {
        type: ApiConstants.API_CREATE_CATEGORY_LOAD,
        payload
    }
    return action;
}

export function ConfigureData(payload: any){
    const action = {
        type: ApiConstants.API_CONFIGURE_DATA_LOAD,
        payload
    }
    return action;
}

export function viewConfigurationModalDataAction(payload: any){
    const action = {
        type: ApiConstants.API_VIEW_CONFIGURE_MODAL_DATA_LOAD,
        payload
    }
    return action;
}

export function getFormDropdownsAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_FORM_DROPDOWN_LOAD,
        payload
    }
    return action;
}

export function getMoveAdjustInventoryAction(payload: any){
    const action = {
        type: ApiConstants.API_MOVE_ADJUST_INVENTORY_LOAD,
        payload
    }
    return action;
}

export function getQuarantineInventoryAction(payload: any){
    const action = {
        type: ApiConstants.API_MOVE_QUARANTINE_INVENTORY_LOAD,
        payload
    }
    return action;
}

export function moveChangeSKULocationAction(payload: any){
    const action = {
        type: ApiConstants.API_CHANGE_SKU_LOCATION_LOAD,
        payload
    }
    return action;
}

export function changeBinOrBoxLocationAction(payload: any){
    const action = {
        type: ApiConstants.API_CHANGE_BIN_BOX_LOCATION_LOAD,
        payload
    }
    return action;
}


export function configureWarehouseAction(payload: any){
    const action = {
        type: ApiConstants.API_CONFIGURE_WAREHOUSE_LOAD,
        payload
    }
    return action;
}

export function getOnboardMenuAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_DASHBOARD_MENU_LOAD,
        payload
    }
    return action;
}

export function getEditData(payload: any){
    const action = {
        type: ApiConstants.API_GET_ON_BOARDING_EDIT_DATA_LOAD,
        payload
    }
    return action;
}

export function uploadFileAction(payload: any){
    const action = {
        type: ApiConstants.API_UPLOAD_FILE_LOAD,
        payload
    }
    return action;
}

export function viewStorageDetailsAction(payload: any){
    const action = {
        type: ApiConstants.API_VIEW_STORAGE_DETAIL_LOAD,
        payload
    }
    return action;
}

export function getClientDedicatedLocations(payload: any){
    const action = {
        type: ApiConstants.API_GET_CLIENT_DEDICATED_LOCATION_LOAD,
        payload
    }
    return action;
}

export function goLiveAction(payload: any){
    const action = {
        type: ApiConstants.API_GO_LIVE_LOAD,
        payload
    }
    return action;
}

export function getAttachmentsAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_FILES_LOAD,
        payload
    }
    return action;
}

export function setAttachmentAsDefaultAction(payload: any){
    const action = {
        type: ApiConstants.API_SET_ATTACHMENT_AS_DEFUALT_LOAD,
        payload
    }
    return action;
}
export function GetReportList(payload: any){
    const action = {
        type: ApiConstants.API_GET_REPORT_LIST_LOAD,
        payload
    }
    return action;
}

export function GetDateReportList(payload: any){
    const action = {
        type: ApiConstants.API_GET_DATE_REPORT_LIST_LOAD,
        payload
    }
    return action;
}

export function ExpandDataAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_EXPAND_DATA_LOAD,
        payload
    }
    return action;
}

export function CartonExpandDataAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_EXPAND_CARTON_DATA_LOAD,
        payload
    }
    return action;
}

export function MoveToRtoAction(payload: any){
    const action = {
        type: ApiConstants.API_MOVE_TO_RTO_LOAD,
        payload
    }
    return action;
}

export function getDimensionAction(payload: any){
    const action = {
        type: ApiConstants.API_GET_DIMENSION_LOAD,
        payload
    }
    return action;
}

export function updateDimensionAction(payload: any){
    const action = {
        type: ApiConstants.API_UPDATE_DIMENSION_LOAD,
        payload
    }
    return action;
}

export function ReQCDataAction(payload: any){
    const action = {
        type: ApiConstants.API_CARTON_REQC_LOAD,
        payload
    }
    return action;
}

export function saveReQCAction(payload: any){
    const action = {
        type: ApiConstants.API_REQC_SAVE_LOAD,
        payload
    }
    return action;
}

export function closeCartonAction(payload: any){
    const action = {
        type: ApiConstants.API_CLOSE_CARTON_LOAD,
        payload
    }
    return action;
}
export function deleteCartonAction(payload: any){
    const action = {
        type: ApiConstants.API_DELETE_CARTON_LOAD,
        payload
    }
    return action;
}


export function PrintSpecificCartonStickerAction(payload: any){
    const action = {
        type: ApiConstants.API_PRINT_SPECIFIC_CARTON_STICKER_LOAD,
        payload
    }
    return action;
}

export function PrintALLCartonStickerAction(payload: any){
    const action = {
        type: ApiConstants.API_PRINT_ALL_CARTON_STICKER_LOAD,
        payload
    }
    return action;
}

export function PrintSpecificCartonPackinglipAction(payload: any){
    const action = {
        type: ApiConstants.API_PRINT_SPECIFIC_CARTON_PACKING_SLIP_LOAD,
        payload
    }
    return action;
}
export function PrintAllCartonPackinglipAction(payload: any){
    const action = {
        type: ApiConstants.API_PRINT_ALL_CARTON_PACKING_SLIP_LOAD,
        payload
    }
    return action;
}
export function scan0rEnterToPackAction(payload: any){
    const action = {
        type: ApiConstants.API_SCAN_CARTON_SKU_LOAD,
        payload
    }
    return action;
}


export function GetGateInReportList(payload: any){
    const action = {
        type: ApiConstants.API_GET_GATEIN_REPORT_LIST_LOAD,
        payload
    }
    return action;
}

export function GetExpandGateInReportList(payload: any){
    const action = {
        type: ApiConstants.API_GET_EXPAND_GATEIN_REPORT_LIST_LOAD,
        payload
    }
    return action;
}

export function GetSearchGateInReportList(payload: any){
    const action = {
        type: ApiConstants.API_GET_SEARCH_GATEIN_REPORT_LIST_LOAD,
        payload
    }
    return action;
}

export function GetGAEReportList(payload: any){
    const action = {
        type: ApiConstants.API_GET_GAE_REPORT_LIST_LOAD,
        payload
    }
    return action;
}

export function GetExpandGAEReportList(payload: any){
    const action = {
        type: ApiConstants.API_GET_EXPAND_GAE_REPORT_LIST_LOAD,
        payload
    }
    return action;
}
export function GetOrderDispatchedReportList(payload: any){
    const action = {
        type: ApiConstants.API_GET_OD_REPORT_LIST_LOAD,
        payload
    }
    return action;
}


export function GetExpandOrderDispatchedReportList(payload: any){
    const action = {
        type: ApiConstants.API_GET_EXPAND_OD_REPORT_LIST_LOAD,
        payload
    }
    return action;
}

export function GetSANReportList(payload: any){
    const action = {
        type: ApiConstants.API_GET_SAN_REPORT_LIST_LOAD,
        payload
    }
    return action;
}

export function GetExpandSANReportList(payload: any){
    const action = {
        type: ApiConstants.API_GET_EXPAND_SAN_REPORT_LIST_LOAD,
        payload
    }
    return action;
}
export function GetGRNReportList(payload: any){
    const action = {
        type: ApiConstants.API_GET_GRN_REPORT_LIST_LOAD,
        payload
    }
    return action;
}

export function GetExpandGRNReportList(payload: any){
    const action = {
        type: ApiConstants.API_GET_EXPAND_GRN_REPORT_LIST_LOAD,
        payload
    }
    return action;
}

export function startInwardAction(payload: any){
    const action = {
        type: ApiConstants.API_WORK_ON_THIS_LOAD,
        payload
    }
    return action;
}

export function clearReducerAction(key: any){
    const action = {
        type: ApiConstants.CLEAR_REDUCER,
        key
    }
    return action;
}

export function getCycleCountForMaster(payload: any){
    const action = {
        type: ApiConstants.API_CYCLE_COUNT_MASTER_DATA_LOAD,
        payload
    }
    return action;
}

export function getCycleCountExpandMaster(payload: any){
    const action = {
        type: ApiConstants.API_CYCLE_COUNT_EXPAND_DATA_LOAD,
        payload
    }
    return action;
}

export function getStorageSkuListAction(payload: any){
    const action = {
        type: ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_LIST_LOAD,
        payload
    }
    return action;
}

export function getStorageSkuSelectAction(payload: any){
    const action = {
        type: ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_SELECT_LOAD,
        payload
    }
    return action;
}

export function getStorageSkuQTYUpdateAction(payload: any){
    const action = {
        type: ApiConstants.API_CYCLE_COUNT_STORAGE_SKU_QTY_UPDATE_LOAD,
        payload
    }
    return action;
}

export function closeCycleCountAction(payload: any){
    const action = {
        type: ApiConstants.API_CLOSE_CYCLE_COUNT_LOAD,
        payload
    }
    return action;
}



export function cycleCountApproveandRejectAction(payload: any){
    const action = {
        type: ApiConstants.API_CLOSE_CYCLE_APPROVE_REJECT_LOAD,
        payload
    }
    return action;
}


export function cycleCountScanningAction(payload: any){
    const action = {
        type: ApiConstants.API_CYCLE_COUNT_SCANNING_LOAD,
        payload
    }
    return action;
}

export function startCycleCountAction(payload: any){
    const action = {
        type: ApiConstants.API_START_CYCLE_COUNT_LOAD,
        payload
    }
    return action;
}

export function createClientAction(payload: any){
    const action = {
        type: ApiConstants.API_CREATE_CLIENT_LOAD,
        payload
    }
    return action;
}
export function editClientAction(payload: any){
    const action = {
        type: ApiConstants.API_EDIT_CLIENT_LOAD,
        payload
    }
    return action;
}

export function startOnBoardingAction(payload: any){
    const action = {
        type: ApiConstants.API_START_ONBOARDING_LOAD,
        payload
    }
    return action;
}


export function getClientDetailsAction(payload: any){
    const action = {
        type: ApiConstants.API_CLIENT_INFO_LOAD,
        payload
    }
    return action;
}

export function saveContractData(payload: any){
    const action = {
        type: ApiConstants.API_CREATE_CONTRACT_LOAD,
        payload
    }
    return action;
}




