import { CaretRightOutlined, CheckCircleOutlined, CheckOutlined, CloseOutlined, CloudUploadOutlined, EyeFilled } from '@ant-design/icons';
import { SelectChangeEvent } from '@mui/material';
import { Button, Col, Drawer, Form, InputNumber, Modal, Progress, Row, Select, Table } from 'antd';
import Search from 'antd/lib/transfer/search';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import AppReferences from '../../Globals/AppReferences';
import { isArrayNotEmpty } from '../../Globals/Helper';
import DownloadIcon from '../../Images/DownloadIcon';
import {
    getSKUSubordersAction,
    assignMoverAction,
    pickingCompleteAction,
} from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import SpinLoader from '../SpinLoader';
import SkeletonLoader from './SkeletonLoader';

function PicksReleasedScreen(props: any) {
    const [expandRow, setExpandRow] = useState<any>();
    const [pickedQtyValue, setPickedQtyValue] = useState<any>([]);
    const [openDrawer, setOpenDrawer] = useState<any>(false);
    const [
        statusDetails,
        channel,
        tatClient,
        clientValue,
        process,
        getStatusDetails,
        getChannelStatus,
        setProcess,
        getChannelsApi
    ]: any = useOutletContext();
    const reducerstate = useSelector((state: any) => state?.ProcessReducerState);
    const { skuSubOrders, statusDetailsOnload } = reducerstate;
    const [selectPicker, setSelectPicker] = useState<any>([])
    const [picker, setPicker] = useState("");
    const dispatch = useDispatch();
    const [onload, setOnload] = useState({
        assignPickerOnlaod: false,
        pickingOnload: false
    });
    const [modalDetails, setModalDetails] = useState({
        valueExceed: false,
        exceeded: false,
        index: "",
        recNo: ''
    });
    const [form]: any = Form.useForm();

    useEffect(() => {
        if (onload.assignPickerOnlaod && !reducerstate.assignOnload) {
            setOnload({ ...onload, assignPickerOnlaod: false });
            getStatusDetails();
            setSelectPicker([]);
        }
    }, [onload.assignPickerOnlaod, reducerstate.assignOnload])

    useEffect(() => {
        if (onload.pickingOnload && !reducerstate.onload) {
            
            // getChannelStatus()
            // getStatusDetails();
            getChannelsApi()
            setPickedQtyValue([]);
            setModalDetails({
                valueExceed: false,
                exceeded: false,
                index: "",
                recNo: ''
            });
            form.resetFields();
            setOnload({ ...onload, pickingOnload: false });
        }
    }, [onload.pickingOnload, reducerstate.onload])



    const getSubOrders = (data: any) => {
        setOpenDrawer(true)
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a07,
            transaction: process,
            where: tatClient,
            fieldArray: channel,
            warehouse: getWareHouseId(),
            customer: clientValue,
            tableNo: data?.SoLineRecNo
        }
        dispatch(getSKUSubordersAction(payload))
    }

    const assignPickerApi = (data: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a08,
            transaction: data?.BatchID,
            where: picker,
            user: getUserInfo()?.UserName
        }
        dispatch(assignMoverAction(payload));
        setOnload({ ...onload, assignPickerOnlaod: true })
    }

    const completePicking = (data: any) => {
        let transac = '';
        pickedQtyValue.map((x: any, index: any) => {
            let val = `${x.recNo}|${x.value}`
            transac += val + (index != pickedQtyValue.length - 1 ? ',' : '')
        })
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a09,
            transaction: transac,
            where: data?.BatchID,
            fieldArray: "M",
            user: getUserInfo()?.UserName,
            warehouse: data?.Lines?.length
        }
        dispatch(pickingCompleteAction(payload));
        setOnload({ ...onload, pickingOnload: true })
    }

    const headerView = () => {
        return (
            <SubHeader
                title={"Pick Released"}
                paginationView="false"
                inputView="true"
                btnView="false"
                fromOrgin="orderProcessing"
            >
                {/* <div className='d-flex flex-end'>
                    <div style={{ fontFamily: 'Redhat-Regular' }}><Search placeholder='Search' /></div>
                </div> */}
            </SubHeader>
        )
    }
    const handlePickedQty = (value: any, data: any, index: any) => {
        try {
            if (value > JSON.parse(data?.ToPickQty)) {
                setModalDetails({ ...modalDetails, valueExceed: true, exceeded: true, recNo: data?.RecordNo, index: index });
            } else {
                setModalDetails({ ...modalDetails, exceeded: false });
                let exists = pickedQtyValue?.find((x: any) => x?.recNo == data?.RecordNo);
                if (exists) {
                    exists.value = value;
                }
                else {
                    let obj = {
                        recNo: data?.RecordNo,
                        value: value
                    }
                    setPickedQtyValue([...pickedQtyValue, obj]);
                }
            }
        } catch (error) {
            console.log("Error in handlePickedQty::", error);
        }

    }

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
        }
    }

    const titleContainer = () => {

        try {
            return (
                <Row>
                    <Col span={6}>
                        <div className='text'>{AppConstants.sku}</div>
                    </Col>
                    <Col span={5}>
                        <div className='text d-flex jc-c'>{AppConstants.orderedqty}</div>
                    </Col>
                    <Col span={4}>
                        <div className='text d-flex jc-c'>{AppConstants.toPickQty}</div>
                    </Col>
                    <Col span={6} className='d-flex jc-c'>
                        <div className='text'>{AppConstants.pickedQty}</div>
                    </Col>
                    <Col span={3}>
                        <div className='text d-flex jc-c'>{AppConstants.action}</div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }

    const innerTableView = (data: any, index: any, isReassign: string) => {
        try {
            let enableReassign = isReassign == 'False';
            let value = isArrayNotEmpty(pickedQtyValue) && pickedQtyValue?.find((x: any) => x?.recNo == data?.RecordNo);
            return (
                <div>
                    <div className={value ? (value?.value == data?.ToPickQty ? "content-container-green-2" : "content-container-red") : "content-container-2"}
                        style={{ marginTop: 10 }}>
                        <Row>
                            <Col span={6} className=' d-flex align-c'>
                                <div className='text'> {data?.SKU}</div>
                            </Col>
                            <Col span={5} className=' d-flex align-c jc-c'>
                                <div className='text-1'> {data?.OrderedQuantity}</div>
                            </Col>
                            <Col span={4} className=' d-flex align-c jc-c'>
                                <div className='text-1'>{data?.ToPickQty ? data?.ToPickQty : '-'}</div>
                            </Col>
                            <Col span={6} className=' d-flex align-c jc-c '>
                                {(value && value?.value == data?.ToPickQty) ?
                                    <div className='text-1'>
                                        {value?.value}
                                    </div>
                                    :
                                    <Form.Item name={`pickqty${index}`}
                                        rules={[
                                            { pattern: /^[0-9]*$/, message: "Enter Valid Quantity" }
                                        ]}
                                    >
                                        <InputNumber
                                            disabled={!enableReassign}
                                            onChange={(e: any) => handlePickedQty(e, data, index)}
                                            style={{ borderRadius: 6, marginLeft: 20 }}
                                            placeholder='Enter'
                                        />
                                    </Form.Item>}
                            </Col>
                            <Col span={3} className='d-flex align-c jc-c'>
                                <Button
                                    onClick={() => getSubOrders(data)}
                                    className="eye-btn" >
                                    <EyeFilled />
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            )
        } catch (error) {
            console.log("Error in innerTableView::", error)
        }
    }

    const skuDetailsView = (skuList: any, isReassign: string) => {
        return (
            <div style={{ background: 'white', padding: 10, borderRadius: 6 }}>
                <div className='header-part-2'>
                    {titleContainer()}
                </div>

                <div className='expand-collape-container-2'>
                    <Form form={form}>
                        {(skuList || []).map((data: any, index: any) =>
                            innerTableView(data, index, isReassign)
                        )}
                    </Form>
                </div>
            </div>
        )
    }

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={4}>
                    {AppConstants.batchId}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {AppConstants.hashOfOrders}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {AppConstants.totalQty}
                </Col>
                {/* <Col span={4}>
                    {AppConstants.picked}
                </Col> */}
                <Col span={6}>
                    {AppConstants.picker}
                </Col>
                <Col span={6} className=' d-flex jc-c'>
                    {AppConstants.action}
                </Col>
            </Row>
        )
    }

    const openUpload = (index: any) => {
        setPickedQtyValue({ ...pickedQtyValue, [`index${index}`]: index });

    }

    const reassignPickerView = (pickReleased: any, index: any) => {
        let assignPicker = selectPicker?.find((x: any) => x == pickReleased?.BatchID);
        let enableReassign = pickReleased?.IsPickingStartedByHandheld == 'False';
        return (
            <div style={{ padding: 4 }} className={"picker"}>
                <div >
                    {!assignPicker ?
                        <div className="user-details">
                            <div >Picker: </div>
                            <div className="name ml-5">{pickReleased?.PickerName}</div>
                        </div>
                        :
                        <div style={{ width: 160 }}>
                            <Select
                                placeholder="Select"
                                onChange={(e) => setPicker(e)}
                                getPopupContainer={(trigger: any) => trigger.parentElement}
                                style={{ width: 160, marginLeft: 5 }}
                            >
                                {statusDetails?.PickerList?.map((picker: any) => {
                                    if (pickReleased?.PickerID != picker?.UserID)
                                        return (
                                            <Select.Option value={picker?.UserID}>
                                                <span className="manager-name">{picker?.Name}</span>
                                            </Select.Option>
                                        )
                                })}
                            </Select>
                        </div>
                    }
                </div>
                {(!assignPicker ?
                    <div className={enableReassign ? 'reassign-button' : 'reassign-button  cursor-not-allowed'}
                        style={{ fontFamily: "Redhat-Medium" }}
                        onClick={() => enableReassign && setSelectPicker([...selectPicker, pickReleased?.BatchID])}
                    >
                        Reassign
                    </div>
                    :
                    <div className="buttons">
                        <div className="cancel" onClick={() => setSelectPicker(selectPicker?.filter((x: any) => x != pickReleased?.BatchID))}>
                            <CloseOutlined />
                        </div>
                        <div className="save"
                            onClick={() => { assignPickerApi(pickReleased) }}
                        >
                            <CheckOutlined />
                        </div>
                    </div>)}
            </div>
        )
    }

    const hasValue = (data: any) => {
        if (pickedQtyValue.length < 1) {
            return true
        }
        else if (pickedQtyValue.length == 1) {
            if (pickedQtyValue[0]?.value) return false
            else return true
        }
        else {
            return false
        }
    }

    const tableBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        let enableReassign = item?.IsPickingStartedByHandheld == 'False';
        return (
            <div className={open ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={4} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='text'>
                            {item?.BatchID}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.CountOfOrders}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.TotalQty}
                        </div>
                    </Col>
                    {/* <Col span={3} className=' d-flex align-c'>
                        <Progress percent={item?.PickedPercentage} size="small" />
                    </Col> */}
                    <Col span={6}>
                        {reassignPickerView(item, index)}
                    </Col>
                    <Col span={6} className="d-flex align-c jc-c">
                        <div className="d-flex align-c">
                            {/* <div className='assignement-status' style={{ fontFamily: "Redhat-SemiBold" }}>
                            </div> */}
                            {enableReassign ?
                                <AppButtons
                                    disabled={(modalDetails.exceeded || hasValue(item)) || !open}
                                    text="Picking Complete"
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                    onClick={() => completePicking(item)}
                                />
                                :
                                <div style={{
                                    fontFamily: 'RedHat-SemiBold',
                                    color: 'var(--app-004986)'
                                }}>
                                    Picking Started By Handheld
                                </div>
                            }
                            <div style={{ marginLeft: 15 }} className="RTS-btn-container d-flex align-c ">
                                <div className='d-flex align-c'>
                                    <CloudUploadOutlined onClick={() => openUpload(index)} />
                                </div>
                                <div className='d-flex align-c' style={{ cursor: "pointer", marginLeft: 15 }}>
                                    <img src={AppImages.downloadIcon} alt="" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    {(pickedQtyValue[`index${index}`] == index) &&
                        <Col span={24} className='dragger-container' style={{ marginTop: 20 }}>
                            {uploadContainerView(index)}
                        </Col>
                    }
                    {open && <Col span={24} className="sku-details">
                        {skuDetailsView(item?.Lines, item?.IsPickingStartedByHandheld)}
                    </Col>}
                </Row>
            </div >
        )
    }

    const uploadContainerView = (index: any) => {
        try {
            return (
                <Dragger >
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <DownloadIcon />
                        <div className='dragger-content ' style={{ marginLeft: 5, textAlign: "left" }}>
                            <div className='drag-txt '>Drag & Drop files or <span className='browse-txt'>Browse</span></div>
                            <div className='note-content '>Supported Formates: JPEG,PNG</div>
                        </div>
                    </div>
                    <Button className="close-btn" onClick={(e) => closeDragger(e, index)}>
                        <CloseOutlined />
                    </Button>
                    {/* <img  onClick={(e) => closeDragger(e, index)}  src={AppImages.closeimg} alt='' /> */}
                </Dragger>
            )
        } catch (ex) {
            console.log("Error in uploadContainerView::" + ex)
        }
    }

    const closeDragger = (e: any, index: any) => {
        e.stopPropagation();
        setPickedQtyValue({ ...pickedQtyValue, [`index${index}`]: null });
    }

    const drawerContent = (list: any) => {
        return (list || []).map((item: any) => {
            return (
                < div style={{ background: "#FAFAFA", marginTop: 10, borderRadius: 6, padding: 15 }}>
                    <Row>
                        <Col span={17}>
                            <div className='d-flex align-c'>
                                <div className='text-1' >
                                    Order ID
                                </div>
                                <div className='text-regular ml-5'>
                                    {item?.ID}
                                </div>
                            </div>
                            <div className='d-flex align-c mt-10'>
                                <div className='text-1' >
                                    SKU
                                </div>
                                <div className='text-regular ml-5'>
                                    {item?.SKU}
                                </div>
                            </div>
                            <div className='d-flex align-c mt-10 '>
                                <div className='text-1' >
                                    Created Date
                                </div>
                                <div className='text-regular ml-5'>
                                    {item?.CreatedDate}
                                </div>
                            </div>
                            <div className='d-flex align-c mt-10'>
                                <div className='text-1' >
                                    Received Date
                                </div>
                                <div className='text-regular ml-5'>
                                    {item?.ReceivedDate}
                                </div>
                            </div>
                        </Col>
                        <Col span={7}>
                            <div className='d-flex align-c jc-sb'>
                                <div style={{ borderRadius: 6, padding: 6, background: "white" }}>
                                    Qty {item?.OrderedQuantity}
                                </div>
                                <div style={{ borderRadius: 10, padding: "0px 6px", background: "#F5D3D3", color: "#9D3636" }}>
                                    {item?.TAT}
                                </div>
                            </div>
                            {item?.isOrderFulfilled != 'False' && <div className='d-flex align-c jc-c' style={{ marginTop: 30 }}>
                                <img src={AppImages.tickIcon} />
                            </div>}
                        </Col>
                    </Row>
                </div>
            )
        })
    }

    const drawerView = () => {
        try {
            let [suborders] = skuSubOrders?.lineitems;
            return (
                <Drawer
                    className="drawer-view-verified"
                    placement={"right"}
                    width={450}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "White" }}>
                            <div className='d-flex'>
                                <div>
                                    View:
                                </div>
                                <div>
                                    Batch ID - {suborders?.BatchID}
                                </div>
                            </div>
                            {/* <Button onClick={() => setOpenDrawer(false)}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button> */}
                            <img onClick={() => setOpenDrawer(false)} className="c-p" src={AppImages.closeimg} alt='' />
                        </div>
                    }
                    onClose={() => setOpenDrawer(false)}
                    open={openDrawer == true}>
                    <div style={{ fontFamily: 'Redhat-Bold', marginTop: 10, fontSize: 16 }} >
                        Orders
                    </div>
                    {drawerContent(suborders?.Lines)}
                </Drawer >
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }
    }

    const closeModal = () => {
        setModalDetails({ ...modalDetails, valueExceed: false });
        setPickedQtyValue(pickedQtyValue.filter((x: any) => x.recNo != modalDetails?.recNo));
        form.setFieldsValue({
            [`pickqty${modalDetails?.index}`]: ""
        })
    }

    const valueExceedsPopup = () => {
        return (
            <Modal
                open={modalDetails.valueExceed}
                onOk={closeModal}
                // onCancel={() => setShowMdal({ ...showModal, qtyMatch: false })}
                footer={null}
                className="start-receivingscreen-modal"
                title={'Not Matching'}
            >
                <div className='margin redhat-bold font-18 height'>
                    The Picked Qty should match with the To Pick Qty
                </div>
                <div className='d-flex flex-end' style={{ marginTop: 10 }}>
                    <AppButtons
                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        onClick={closeModal}
                        text="Continue to Pick"
                    />
                </div>
            </Modal>
        )
    }

    const tableView = () => {
        return (
            <div>
                {isArrayNotEmpty(statusDetails?.lineitems) ?
                    <>
                        {tableHeaderView()}
                        {statusDetails?.lineitems?.map((x: any, index: any) => tableBodyView(x, index))}
                    </>
                    :
                    <div className='p-20'>
                        <NoData
                            description={
                                <>There is no sales order available in <span className='redhat-bold ml-5'>Pick Released</span></>
                            }
                        />
                    </div>}
            </div>
        )
    }

    const contentView = () => {
        return (
            <div className='table-screen-container'>
                {tableView()}
            </div>
        )
    }

    return (
        <>
            {!statusDetailsOnload ?
                <div className='pick-released-screen'>
                    {headerView()}
                    {contentView()}
                    {drawerView()}
                    {valueExceedsPopup()}
                    <SpinLoader
                        loading={onload?.assignPickerOnlaod || onload.pickingOnload}
                    />
                </div>
                :
                <SkeletonLoader />
            }
        </>
    );
}

export default PicksReleasedScreen;