import React from 'react';

function InwardEmail() {
    return (
        <div id='sanFinalMail' style={{ paddingLeft: 15, paddingRight: 15 }}>
            <span style={{ fontSize: 18, fontFamily: "sans-serif", color: "GrayText", marginLeft: 40, marginTop: 10 }}>Inward Completed for the Invoice: INV-14-NOV-04</span>
            <br />
            <div>
                <div style={{ borderRadius: 20, background: '#F0F0F4', padding: 20, marginTop: 10 }}>
                    <div style={{ fontSize: 16, fontFamily: "sans-serif", fontStyle: "normal", color: "#4E4E4E", display: "flex" }}>
                        <div style={{ width: "25%", marginLeft: 20 }}><b>{"Invoice Number"}</b></div>
                        <div style={{ width: "25%", marginLeft: 20 }}><b>{"Total Boxes"}</b></div>
                        <div style={{ width: "25%", marginLeft: 20 }}><b>{"Damaged Boxes"}</b></div>
                        <div style={{ width: "25%", marginLeft: 30 }}><b>{"Release No"}</b></div>
                    </div>
                    <div style={{ fontSize: 16, fontFamily: "sans-serif", color: "#747474", display: "flex" }}>
                        <div style={{ width:  "25%", marginLeft: 20 }}>{"INV-14-NOV-04"}</div>
                        <div style={{ width:  "25%", marginLeft: 20 }}>{"10"}</div>
                        <div style={{ width:  "25%", marginLeft: 20 }}>{"2"}</div>
                        <div style={{ width:  "25%", marginLeft: 30 }}>{"R-1"}</div>
                    </div>
                </div>
                <div style={{ borderRadius: 20, background: '#F0F0F4', padding: 20, marginTop: 10 }}>
                    <br />
                    <table style={{ marginTop: "-13px" }} >
                        <tr style={{ fontSize: 16, fontFamily: "sans-serif", color: "#FFFFFF", backgroundColor: "#022E3A" }}>
                            <th style={{ width: 200, textAlign: "center", padding: 6 }}>{"Box"}</th>
                            <th style={{ width: 500, textAlign: "center", padding: 6 }}>{"SKU ,Description"}</th>
                            <th style={{ width: 500, textAlign: "center", padding: 6 }}>{"Condition"}</th>
                            <th style={{ width: 500, textAlign: "center", padding: 6 }}>{"Quantity"}</th>
                            <th style={{ width: 500, textAlign: "center", padding: 6 }}>{"Batch ID"}</th>
                            <th style={{ width: 400, textAlign: "center", padding: 6 }}>{"MFG Date"}</th>
                            <th style={{ width: 400, textAlign: "center", padding: 6 }}>{"Import Month"}</th>
                            <th style={{ width: 400, textAlign: "center", padding: 6 }}>{"Expiry Date"}</th>
                            <th style={{ width: 300, textAlign: "center", padding: 6 }}>{"MRP"}</th>
                        </tr>
                        <tr style={{ fontSize: 16, fontFamily: "sans-serif", color: "#232323", backgroundColor: "rgb(255, 255, 255)", marginTop: 5 }}>
                            <td style={{ width: 200, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0" }}>{"20"}</td>
                            <td style={{ width: 500, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0" }}>{"SKU-10, This is SKU-10"}</td>
                            <td style={{ width: 500, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0" }}>{"Good"}</td>
                            <td style={{ width: 500, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0" }}>{"20"}</td>
                            <td style={{ width: 500, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0" }}>{"1235"}</td>
                            <td style={{ width: 400, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0" }}>{"20-Sep-2022"}</td>
                            <td style={{ width: 400, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0" }}>{"20-Sep-2022"}</td>
                            <td style={{ width: 400, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0" }}>{"20-Sep-2022"}</td>
                            <td style={{ width: 300, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0" }}>{"3000"}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default InwardEmail;