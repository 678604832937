import { Col, Row } from 'antd';
import React from 'react';
import AppButtons from '../../../CustomComponent/AppButtons';
import AppConstants from '../../../Globals/AppConstants';
import SubHeader from '../../Header/Header';

function PendingRTOReturnB2C(props: any) {



    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={12}>
                    {AppConstants.gateInId}
                </Col>
                <Col span={12} className='text-center'>
                    {AppConstants.action}
                </Col>
            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        return (
            <div className={"content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={12} className="open-icon d-flex align-c"
                    >
                        <div className='text-1 app-blue'>
                            {`WH01-CUST02-20220218-140${item}`}
                        </div>
                    </Col>
                    <Col span={12} className='d-flex align-c jc-c'>
                        <AppButtons
                            //onClick={() =>callBack({rtoDetails:item, setProcessId: setProcessId})}
                            text="Work on this"
                            buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        />
                    </Col>
                </Row>
            </div>
        )
    }

    const tableView = () => {
        return (
            <div className='table-screen-container' style={{ marginTop: '60px' }}>
                {tableHeaderView()}
                <div style={{ height: '71.7vh', overflow: 'scroll' }}>
                    {[198, 247, 897, 789, 389].map((data: any, index: any) => tableBodyView(data, index))}
                </div>
            </div>
        )
    }
    return (
        <div>
            <SubHeader
                title={"Pending RTO/Return - B2C"}
                paginationView="false"
                inputView="true"
                btnView="false"
            >
            </SubHeader>
            {tableView()}
        </div>
    );
}

export default PendingRTOReturnB2C;