import React from 'react';

function ScanBarIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M5 7H7V17H5V7ZM14 7H15V17H14V7ZM10 7H13V17H10V7ZM8 7H9V17H8V7ZM16 7H19V17H16V7Z" fill="var(--app-004986)" />
            <path d="M4 5H8V3H4C2.897 3 2 3.897 2 5V9H4V5ZM4 21H8V19H4V15H2V19C2 20.103 2.897 21 4 21ZM20 3H16V5H20V9H22V5C22 3.897 21.103 3 20 3ZM20 19H16V21H20C21.103 21 22 20.103 22 19V15H20V19Z" fill="var(--app-004986)" />
        </svg>
    );
}

export default ScanBarIcon;