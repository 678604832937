import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import RTOB2BPending from './RTOB2BPending';
import SearchConsignment from './SearchConsignment';

function RTOB2B(props: any) {
    const [value, 
        individualStageData,
        processId, 
        getStatges,
        getIndividualStageDetails, 
        setProcessId
    ]: any = useOutletContext();
    const [workOn, setWorkOn] = useState(true);
    const [rtoDetail, setRTODetails] = useState<any>();

    const workOnThis = (data: any)=>{
        setRTODetails(data);
        setWorkOn(false)
    }

    const closeSearchConsignMent = () =>{
        setRTODetails(null);
        setWorkOn(true)
    }
         
    return (
        <div>
            {workOn ? 
            <RTOB2BPending callBack={workOnThis} individualStageData={individualStageData} setProcessId={setProcessId}/>
            :
            <SearchConsignment rtoDetail={rtoDetail} closeSearchConsignMent={closeSearchConsignMent}/>}
        </div>
    );
}

export default RTOB2B;