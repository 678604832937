/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:0fed2e35-15bf-46d3-b794-c938dc515509",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_wGjzBalCU",
    "aws_user_pools_web_client_id": "1ptdtdunusepaj6440qh7c11b2",
    "oauth": {
        "domain": "revo360wms.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "email",
            "openid"
        ],
        "redirectSignIn": "http://10.10.12.26:3000/",
        //"redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://10.10.12.26:3000/",
        "responseType": "token",
        "mandatorySignIn": true,
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
