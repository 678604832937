import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { AnyIfEmpty, useDispatch, useSelector } from 'react-redux';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import { GetSANReportList } from '../../Store/Actions/ProcessWMSAction';

function RTOReturnReport(props: any) {
    const {
        date,
        setDate
    } = props
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const { reportSANList } = reducerState;
    const [onload, setOnload] = useState({
        filterOnload: false
    });

    useEffect(() =>{
        getData(null);
    }, []);

    useEffect(() =>{
        if(date){
            getData(date?.dateString);
        }
    }, [date]);

    // useEffect(() => {
    //     if(onload.filterOnload && !reducerState.reportSANOnload){
    //         setOnload({...onload, filterOnload: false})
    //     }
    // }, [onload.filterOnload, reducerState.reportSANOnload])

    const getData = (data: any) => {
        let payload = {
            "opnfor": "R00003",
            "activity": "A-06",
            "transaction": (data && data[0]) ? `${data[0]}|${data[1]}` : 0,
            "where": (data && data[0]) ? "D" : "N",
            "warehouse": getWareHouseId(),
            "user": getUserInfo()?.UserName
        }
        dispatch(GetSANReportList(payload));
        data && setOnload({...onload, filterOnload: true})
        // setDate({});
    }

    const getFilterData = (key: any)=>{
        try {
            let filterData: any = [];
            reportSANList?.lineitems?.map((item: any) =>{
                let exist = filterData?.find(({value}: any) => value == item[key]);
                if(!exist){
                    let obj = {
                        value: item[key],
                        text: item[key],
                    }
                    filterData.push(obj);
                }
            });
            return filterData;
        } catch (error) {
            console.log("Error in getFilterData::", error);
        }
    }

    const columns: any = [
        {
            title: "Warehouse",
            dataIndex: "WarehouseName",
            align: "left",
            filters: getFilterData('WarehouseName'),
            onFilter: (value: string, record: any) => record?.WarehouseName?.startsWith(value),
            filterSearch: true,
        },
        {
            title: "Clients",
            dataIndex: "ClientName",
            align: "center",
            filters: getFilterData('ClientName'),
            filterSearch: true,
            onFilter: (value: string, record: any) =>record?.name?.startsWith(value) ,
        },
        {
            title: "Opening",
            dataIndex: "OpeningCount",
            align: "center",
            render: (item: any) => {
                return (
                    <div className={item == '-' ? 'text' : 'text-2'}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Received",
            dataIndex: "ReceivedCount",
            align: "center",
            render: (item: any) => {
                return (
                    <div className={item == '-' ? 'text' : 'text-2'}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Processed",
            dataIndex: "ProcessedCount",
            align: "center",
            render: (item: any) => {
                return (
                    <div className={item == '-' ? 'text' : 'text-2'}>
                        {item}
                    </div>
                )
            }
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    return (
        <div className='table-screen-container'>
            <Table
                columns={columns}
                dataSource={reportSANList?.lineitems}
                pagination={false}
                onChange={onChange}
                // sticky={true}
                scroll={{ y: 485 }}
            />
        </div>
    );
}

export default RTOReturnReport;