import { CloseOutlined } from "@ant-design/icons";
import { Button, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AppButtons from "../../../CustomComponent/AppButtons";
import AppConstants from "../../../Globals/AppConstants";
import AppReferences from "../../../Globals/AppReferences";
import { getUloadingDetailsAction, sendStickeringAction } from "../../../Store/Actions/ProcessWMSAction";
import { getUserInfo, getWareHouseId } from "../../../Utils/SessionStorage";
import SubHeader from "../../Header/Header";
import NavbarLayout from "../../NavbarLayout/NavbarLayout";
import "../SAN.scss";

function UnloadingScreen() {
  const loactionProps: any = useLocation().state;
  const { unloadingData } = loactionProps;
  const dispatch = useDispatch();
  const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
  const { unloadingInfo } = reducerState;
  const [onload, setOnload] = useState(false);
  const [stickeringOnload, sendStickeringOnload] = useState(false);
  const [boxdetails, setBoxDetails] = useState({
    totalBox: 0,
    damagedBox: 0
  });
  let [uloadedBox] = (unloadingInfo?.header || []);
  const navigate = useNavigate();
  const warehouseId = getWareHouseId();

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (onload && !reducerState?.onload) {
      boxdetails.totalBox = uloadedBox?.RecievedBoxQty;
      boxdetails.damagedBox = uloadedBox?.RecievedDamagedBoxQty;
      setBoxDetails({ ...boxdetails });
      setOnload(false);
    }
  }, [onload, reducerState?.onload])

  useEffect(() => {
    if (stickeringOnload && !reducerState?.sendOnload) {
      navigate("/stickering-details");
      sendStickeringOnload(false);
    }
  }, [stickeringOnload, reducerState?.sendOnload])


  const getData = () => {
    try {
      let id = unloadingData?.id?.split("|");
      let payload = {
        "customer": id[0],
        "activity": AppReferences.activities.av,
        "opnfor": id[1],
        "transaction": id[2],
        "warehouse": getWareHouseId(),
        "user": getUserInfo()?.UserName,
      }
      dispatch(getUloadingDetailsAction(payload));
      setOnload(true)
    } catch (error) {
      console.log("Error in getData::", error);
    }

  }

  const sendToSickering = () => {
    let payload = {
      "warehouse": getWareHouseId(),
      "Where": `${boxdetails?.totalBox} | ${boxdetails?.damagedBox}`,
      "user": getUserInfo()?.UserName,
      "transaction": uloadedBox?.iNo,
      "customer": uloadedBox?.cId,
      "activity": AppReferences.activities.ax01,
      "opnfor": unloadingInfo?.title?.stageNo
    }
    dispatch(sendStickeringAction(payload))
    sendStickeringOnload(true)
  }

  const columns: any = [
    {
      title: AppConstants.invoiceNum,
      dataIndex: "InvoiceNo",
      width: 150,
      align: "left",
    },
    {
      title: AppConstants.invoiceBox,
      dataIndex: "InvoiceBoxQty",
      width: 130,
      align: "left",
    },
    {
      title: AppConstants.executive,
      dataIndex: "Name",
      width: 190,
      align: "left",
    },
    {
      title: AppConstants.labours,
      dataIndex: "LaboursAssigned",
      width: 130,
      align: "left",
    },
    {
      title: AppConstants.totalBoxes,
      dataIndex: "RecievedBoxQty",
      width: 200,
      align: "left",
      render: (item: any) => {
        return <Input
          className="box-input"
          value={boxdetails.totalBox}
          onChange={(e: any) => setBoxDetails({ ...boxdetails, totalBox: e.target.value })} />;
      },
    },
    {
      title: <div style={{ textAlign: "left", marginLeft: 60 }}>{AppConstants.damagedBox}</div>,
      dataIndex: "RecievedDamagedBoxQty",
      align: "left",
      width: 200,
      render: (item: any) => {
        return <Input
          className="box-input"
          value={boxdetails.damagedBox}
          onChange={(e: any) => setBoxDetails({ ...boxdetails, damagedBox: e.target.value })} />;
      },
    },
  ];

  const tableView = () => {
    try {
      return (
        <div>
          <Table
            columns={columns}
            dataSource={unloadingInfo?.header}
            pagination={false}
            bordered />
        </div>
      );
    } catch (ex) {
      console.log("Error in tableView::" + ex);
    }
  };

  const childrenView = () => {
    return (
      <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
        {/* <Link to="/process/SAN" state={{ Option: 2 }}> */}
        <AppButtons
          onClick={() => sendToSickering()}
          block={true}
          buttonType={AppConstants.buttonTypes.solidLinearGreen}
          text={AppConstants.sendTostickering}>
        </AppButtons>
        {/* </Link> */}
        <Link to="/process/SAN" state={{ Option: 4 }}>
          <Button className="close-btn">
            <CloseOutlined />
          </Button>
        </Link>
      </div>
    )
  }

  const titleView = () => {
    return (
      <div>
        <div>
          View
        </div>
        <div>
          <span>Gate In: {uloadedBox?.GateEntryRecordNo + " - "}</span>
          <span>{unloadingInfo?.title?.customer}</span>
        </div>
      </div>
    )
  }

  return (
    <>
      <NavbarLayout
        titleContent="sub-head"
        process={unloadingInfo?.title}
      />
      <SubHeader
        title={titleView()}
        btnView="true"
        inputView="true"
        paginationView="false" >
        {childrenView()}
      </SubHeader>
      <div className="unloading-screen-container mt-45">
        {/* <div className="titlebar">
          <div className="title">{AppConstants.totalnoofInvoices}</div>
        </div> */}
        <div className="table-view">
          {tableView()}
        </div>
      </div>
    </>
  );
}

export default UnloadingScreen;
