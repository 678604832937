import React from 'react';

function DownloadIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="56" viewBox="0 0 64 56" fill="none">
            <path d="M33.2514 13.621L33.3445 13.6488L33.3489 13.6438C33.7561 13.7138 34.1584 13.469 34.2781 13.0651C35.358 9.43632 38.7605 6.90121 42.5512 6.90121C43.007 6.90121 43.3766 6.53156 43.3766 6.0758C43.3766 5.62003 43.007 5.25039 42.5512 5.25039C37.8809 5.25039 33.9562 8.35994 32.6961 12.5946C32.5658 13.0316 32.8148 13.4908 33.2514 13.621Z" fill="#00819D" stroke="#F9FFF9" stroke-width="0.3" />
            <path d="M52.0065 39.1715H47.9541C47.5812 39.1715 47.2787 38.8691 47.2787 38.4961C47.2787 38.1232 47.5812 37.8207 47.9541 37.8207H52.0065C57.5925 37.8207 62.1375 33.2757 62.1375 27.6897C62.1375 22.1037 57.5925 17.5587 52.0065 17.5587H51.909C51.7131 17.5587 51.5269 17.4738 51.3986 17.3257C51.2703 17.1775 51.2124 16.9812 51.2404 16.7873C51.3007 16.3666 51.3311 15.944 51.3311 15.5325C51.3311 10.6913 47.3919 6.75219 42.5508 6.75219C40.6674 6.75219 38.8713 7.34075 37.3564 8.45467C37.0235 8.69926 36.5507 8.59072 36.3582 8.22455C32.068 0.0551037 20.8624 -1.04197 15.0528 6.06473C12.6055 9.05868 11.6439 12.9533 12.4144 16.7492C12.4994 17.1684 12.1785 17.5592 11.7525 17.5592H11.4819C5.89589 17.5592 1.35087 22.1042 1.35087 27.6902C1.35087 33.2762 5.89589 37.8212 11.4819 37.8212H15.5343C15.9072 37.8212 16.2097 38.1237 16.2097 38.4967C16.2097 38.8696 15.9072 39.1721 15.5343 39.1721H11.4819C5.15092 39.1721 0 34.0212 0 27.6902C0 21.5368 4.86575 16.4982 10.9517 16.2204C10.38 12.2822 11.4732 8.30991 14.0069 5.20987C20.2269 -2.39948 32.1469 -1.54658 37.1858 6.93842C38.7934 5.93061 40.6328 5.40192 42.5505 5.40192C48.4159 5.40192 53.0637 10.3941 52.657 16.2267C58.6869 16.5649 63.4881 21.5768 63.4881 27.6897C63.4881 34.0212 58.3371 39.1716 52.0061 39.1716L52.0065 39.1715Z" fill="#00819D" />
            <path d="M14.6186 38.1157C14.6186 47.5092 22.2605 55.151 31.6538 55.151C41.0473 55.151 48.6891 47.5091 48.6891 38.1157C48.6891 28.7223 41.0473 21.0805 31.6538 21.0805C22.2604 21.0805 14.6186 28.7224 14.6186 38.1157ZM16.2697 38.1157C16.2697 29.6332 23.1711 22.7316 31.6538 22.7316C40.1364 22.7316 47.038 29.6331 47.038 38.1157C47.038 46.5983 40.1364 53.4999 31.6538 53.4999C23.1713 53.4999 16.2697 46.5984 16.2697 38.1157Z" fill="#00819D" stroke="#F9FFF9" stroke-width="0.3" />
            <path d="M31.3168 44.9133C31.3168 45.2692 31.6054 45.5578 31.9613 45.5578C32.3171 45.5578 32.6057 45.2696 32.6057 44.9133V32.057C32.6057 31.7011 32.3171 31.4125 31.9613 31.4125C31.6054 31.4125 31.3168 31.7011 31.3168 32.057V44.9133Z" fill="#00819D" stroke="#00819D" stroke-width="0.3" />
            <path d="M31.962 32.9704L28.4621 36.4704L31.962 32.9704ZM31.962 32.9704L35.4621 36.4705C35.5877 36.5961 35.7532 36.6592 35.9177 36.6592L31.962 32.9704ZM27.5505 36.4705C27.8022 36.7222 28.2105 36.7223 28.462 36.4705L27.5505 36.4705ZM27.5505 36.4705C27.2988 36.2187 27.2988 35.8108 27.5505 35.559L31.5062 31.6034C31.5062 31.6033 31.5063 31.6033 31.5063 31.6033C31.7578 31.3514 32.1661 31.3516 32.4177 31.6033L36.3735 35.559C36.6252 35.8107 36.6252 36.2185 36.3737 36.4703C36.2477 36.5967 36.0821 36.6592 35.9178 36.6592L27.5505 36.4705Z" fill="#00819D" stroke="#00819D" stroke-width="0.3" />
        </svg>
    );
}

export default DownloadIcon;