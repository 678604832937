import moment from "moment";

export const isArrayNotEmpty = (array:any) => {
  if(array && array.length > 0){
    return true;
  }else{
    return false;
  }
}

export const isArrayEmpty = (array: any) => {
  if (!array || array?.length == 0) {
    return true;
  }else {
    return false;
  }
}
export const uniqueBy = (arr: any, prop:any) => {
  let uniqueValue: any =new Map(arr.map((m: any) => [m[prop], m])).values()
  return [...uniqueValue];
};
export const getUserProfileDefaultThumbnail = (firstName: any, lastName: any) => {
  try {
 
    let flName = "";
    flName = firstName ? firstName[0].toUpperCase() : "";
    flName += lastName ? lastName[0].toUpperCase() : "";
    return flName;
  } catch (ex) {
    console.log("Error in getUserProfileDefaultThumbnail::" + ex);
  }
}

export const getFullName = (firstName: any,lastName: any) =>{
  let fullName= [firstName, lastName].filter(it => !!it).join(" ") || null
  return fullName
}

export const checkHasValue = (value: any) =>{
  if(value && value != 0 && value != '-'){
    return value;
  }
  else{
    return '-';
  }
}

export const pipefy = (arr: any)=>{
  try {
    let value = arr[0];
    arr?.slice(1, arr?.length)?.map((x: any) => 
    value += `|${x}`);
    return value;
  } catch (error) {
    console.log("Error in pipefy::", error)
  }
}

export const generateGuId = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const getLocalDateTime = (date: any)=>{
  let gmtDateTime = moment.utc(date, "DD-MMM-YYYY HH:mm:ss")
  let local = gmtDateTime.local().format('YYYY-MM-DD h:mm A');
  return local
}