import { CaretRightOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Input, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useOutletContext } from 'react-router-dom';
import NoData from '../../../CustomComponent/NoData';
import AppConstants from '../../../Globals/AppConstants';
import { AppImages } from '../../../Globals/AppImages';
import File from '../../../Images/File';
import { consignmentAction } from '../../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../../Utils/SessionStorage';
import SubHeader from '../../Header/Header';
import '../SAN.scss';
import SendSANViewScreen from './SendSANViewScreen';

const suffix = (

    <SearchOutlined
        style={{
            fontSize: 20,
            color: 'black',
        }}
    />
);

function SendSanListing(props: any) {
    const dispatch = useDispatch();
    const { getIndividualStageDetails, getStatges , processData} = props;
    const [value, individualStageData]: any = useOutletContext();
    const [openDrawer, setOpenDrawer] = useState<any>();
    const [expandRow, setExpandRow] = useState(-1);
    const wareHouse_id = getWareHouseId();
    const [consignment, setConsignment] = useState<any>();
    const { consignmentData } = useSelector((state: any) => state?.ProcessReducerState)
    const drawerData = consignmentData?.lineitems[0];

    const callBack = () => {
        getStatges();
        processData();
        getIndividualStageDetails();
        setExpandRow(-1);
    }

    useEffect(() => {
        if (openDrawer?.isOPen == true) {
            consignmentApi();
        }
    }, [openDrawer]);

    const consignmentApi = () => {
        let payload = {
            opnfor: "110000",
            activity: "A-02",
            transaction: consignment,
            warehouse: wareHouse_id,
            where: getUserInfo()?.CorporateID
        }
        dispatch(consignmentAction(payload));
    }

    const openConsignment = (id: any) => {
        setConsignment(id);
        setOpenDrawer({ ...openDrawer, isOPen: true });


    }

    const drawerView = () => {
        try {
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                {drawerData?.TxnNo}
                            </div>
                            <Button onClick={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>
                        </div>
                    }
                    onClose={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                    open={openDrawer?.isOPen}>
                    <Row>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                {AppConstants.client}
                            </div>
                            <div className='value-field'>
                                {drawerData?.Customer}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Transaction Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.InwardTxnType}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.Type}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.VehicleNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Seal Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.SealNo}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Docket Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.DocketNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Dock Station
                            </div>
                            <div className='value-field'>
                                {drawerData?.DockStationName}
                            </div>
                        </Col>
                    </Row>
                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }

    }

    const handleExpand = (data: any, index: any) => {
        setExpandRow(expandRow == index ? null : index);
    }

    const listingView = (data: any, index: any) => {
        try {
            let [, , warehouseId] = (data?.id?.split("|") || []);// to get id from third index 
            let isExpandopen = (expandRow == index);
            return (
                < div className={index === expandRow ? "content-container1" : "content-container"
                } style={{ marginTop: 10 }
                }>
                    <Row>
                        <Col span={12} className='d-flex align-c '>
                            <div className="title-container">
                                <div className='d-flex align-c '>
                                    <CaretRightOutlined
                                        onClick={() => handleExpand(data, index)}
                                        className={index === expandRow ? "arrow arrow-down" : "arrow arrow-right"} />

                                    <div className='mama-container' style={{ width: 150, marginLeft: 5 }}>
                                        <div
                                            onClick={() => openConsignment(warehouseId)}
                                            className='title font-16'
                                            title={warehouseId}
                                        >
                                            {warehouseId}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='text font-16'> {data?.name}</div>
                        </Col>
                    </Row>
                    {
                        isExpandopen &&
                        <div style={{ marginTop: 10 }}>
                            <SendSANViewScreen
                                callBack={callBack}
                                item={data}
                            />
                        </div>

                    }
                </div >
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const titleContainer = () => {
        try {
            return (
                <Row>
                    <Col span={12}>
                        <div className='text'>GateIn ID</div>
                    </Col>
                    <Col span={12}>
                        <div className='text'>Client</div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }

    return (
        <div className='sendsan-main-container'>
            <SubHeader
                title={"Approve & Send SAN Email"}
                paginationView="true"
                inputView="true"
                btnView="false">
            </SubHeader>
            <div style={{ padding: 10 }} >
                <div className='header-part'>
                    {titleContainer()}
                </div>
                <div style={{ marginTop: 10}}>
                    {individualStageData?.header?.length != 0 ?
                        (individualStageData?.header || []).map((item: any, index: any) => listingView(item, index))
                        :
                        <NoData />
                    }
                </div>
                {drawerView()}
            </div>
        </div>

    )
}

export default SendSanListing;
