import { Table } from 'antd';
import React from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import AppButtons from '../../../CustomComponent/AppButtons';
import AppConstants from '../../../Globals/AppConstants';
import { getDashboardMenuId } from '../../../Utils/SessionStorage';
import SubHeader from '../../Header/Header';


function StartRecievingListing() {
    const [value, individualStageData]: any = useOutletContext();
    const columns: any = [
        {
            title: AppConstants.invoiceNum,
            dataIndex: "show",
            width: "15%",
            align: "left",
            render: (item: any) => {
                try {
                    let invoiceNumber = item?.split(",");
                    return (
                        <div className='text'>
                            {invoiceNumber[4]}
                        </div>
                    )
                } catch (error) {
                    console.log("Error in invoiceNumber split");

                }

            }
        },
        {
            title: "Client",
            dataIndex: 'name',
            width: "20%",
            align: "left",
            render: (item: any) => {
                return (
                    <div className='client-text'>
                        <span title={item} className='overflow-tagline'> {item}</span>
                    </div>
                )
            }
        },
        {
            title: "PO Number",
            dataIndex: "show",
            align: "center",
            width: "20%",
            render: (item: any) => {
                try {
                    let pO = item?.split(":");
                    let pOnum = pO[1]?.split(",");
                    return (
                        <div className='text'>
                            {pOnum[0]}
                        </div>
                    )
                } catch (error) {
                    console.log("Error in Po split", error);
                }
            },
        },
        {
            title: "Release Number",
            dataIndex: "show",
            align: "center",
            width: "15%",
            render: (item: any) => {
                try {
                    let releaseNum = item?.split(",")
                    return (
                        <div className='text'>
                            {releaseNum[3]}
                        </div>
                    );
                } catch (error) {
                    console.log("Error in release number split", error);

                }

            },
        },
        {
            title: "Action",
            dataIndex: "",
            align: "center",
            width: "20%",
            render: (item: any) => {
                try {
                    let clientId_transId = item?.id?.split("|");
                    return (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center",marginRight:15 }}>
                            <Link to="/start-recieving-view"
                                state={{ client_id: clientId_transId[0], transId: clientId_transId[2] , stage: getDashboardMenuId()}}
                            >
                                <AppButtons
                                    block={true}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen5}
                                    text={"Continue Receiving"}
                                />
                            </Link>
                        </div >
                    )
                } catch (error) {
                    console.log("Error in continue receiving button", error);
                }
            },
        }
    ].filter((item: any) => !item?.hidden);

    const listingView = () => {
        try {
            return (
                <div className='table-screen-container'>
                    <Table
                        columns={columns}
                        dataSource={individualStageData?.lineitems}
                        pagination={false}                        
                    />
                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    return (
        <>
            <SubHeader
                title={"Receiving in Progress (B2B)"}
                inputView="true"
                paginationView="false"
                btnView="false" >
                {/* {childrenView()} */}
            </SubHeader>
            {listingView()}
        </>
    )
}

export default StartRecievingListing;
