import React from 'react';

function NonSellableIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
            <path d="M0.5 15.1111V4.94444C0.5 3.72222 0.934815 2.67556 1.80444 1.80444C2.67556 0.934815 3.72222 0.5 4.94444 0.5H36.0556C37.2778 0.5 38.3244 0.934815 39.1956 1.80444C40.0652 2.67556 40.5 3.72222 40.5 4.94444V19.5556L35.3889 14.4444C35.1667 14.2222 34.9259 14.0644 34.6667 13.9711C34.4074 13.8793 34.1296 13.8333 33.8333 13.8333C33.537 13.8333 33.2593 13.8793 33 13.9711C32.7407 14.0644 32.5 14.2222 32.2778 14.4444L24.9444 21.7778L17.6111 14.4444C17.3889 14.2222 17.1481 14.0644 16.8889 13.9711C16.6296 13.8793 16.3519 13.8333 16.0556 13.8333C15.7593 13.8333 15.4815 13.8793 15.2222 13.9711C14.963 14.0644 14.7222 14.2222 14.5 14.4444L7.16667 21.7778L0.5 15.1111ZM4.94444 40.5C3.72222 40.5 2.67556 40.0652 1.80444 39.1956C0.934815 38.3244 0.5 37.2778 0.5 36.0556V21.3889L5.61111 26.5C5.83333 26.7222 6.07407 26.8793 6.33333 26.9711C6.59259 27.0644 6.87037 27.1111 7.16667 27.1111C7.46296 27.1111 7.74074 27.0644 8 26.9711C8.25926 26.8793 8.5 26.7222 8.72222 26.5L16.0556 19.1667L23.3889 26.5C23.6111 26.7222 23.8519 26.8793 24.1111 26.9711C24.3704 27.0644 24.6481 27.1111 24.9444 27.1111C25.2407 27.1111 25.5185 27.0644 25.7778 26.9711C26.037 26.8793 26.2778 26.7222 26.5 26.5L33.8333 19.1667L40.5 25.8333V36.0556C40.5 37.2778 40.0652 38.3244 39.1956 39.1956C38.3244 40.0652 37.2778 40.5 36.0556 40.5H4.94444Z" fill="#CC3333" />
        </svg>
    );
}

export default NonSellableIcon;