import { CaretRightOutlined } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppReferences from '../../../Globals/AppReferences';
import PackingListIcon from '../../../Images/PackingListIcon';
import ScanBarIcon from '../../../Images/ScanBarIcon';
import { CartonExpandDataAction, ExpandDataAction, PrintAllCartonPackinglipAction, PrintSpecificCartonPackinglipAction, PrintSpecificCartonStickerAction } from '../../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../../Utils/SessionStorage';
import CartonningPackingListModal from '../CartonningPackingListModal';
import CartonningStickeringModal from '../CartonningStickeringModal';

function RTSCartonDetails(props: any) {
    const {sodetails, clientValue} = props;
    let columns: any = ["SKU", "Order Qty", "Picked Qty", "To Pack", "Packed Qty", "ReQC Count"];
    const reducerstate = useSelector((state: any) => state?.ProcessReducerState);
    const { 
        cartonExpandList,
        expandListForCarton,
        specificPackingSlipOnLoad,
        allPackingSlipOnLoad,
        PackingSlip,
        specificStickerOnLoad,
        cartonStickeringList
    } = reducerstate;
    const [viewPackingListModal, setPackingListModal] = useState<any>(false);
    const [expandCartonRow, setExpandCartonRow] = useState<any>();
    const dispatch = useDispatch();
    const [onLoad, setOnLoad] = useState<any>({})
    const [scanModal, setScanModal] = useState<any>(false);

    useEffect(() =>{
        getExpandData('Y')
    }, []);

    useEffect(() => {
        if (onLoad?.packingSlip === true && !specificPackingSlipOnLoad) {
            setPackingListModal(true)
        }
    }, [onLoad?.packingSlip, specificPackingSlipOnLoad])

    useEffect(() => {
        if (onLoad?.printSticker && !specificPackingSlipOnLoad) {
            setScanModal(true)
        }
    }, [onLoad?.printSticker, specificStickerOnLoad])

    useEffect(() => {
        if (onLoad?.packingSlip === true && !allPackingSlipOnLoad) {
            setPackingListModal(true)
        }

    }, [onLoad?.packingSlip, allPackingSlipOnLoad])

    const getExpandData = (flag: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a42,
            transaction: sodetails?.RawSOID,
            customer: clientValue,
            warehouse: getWareHouseId(),
            user: getUserInfo()?.UserName
        }
        dispatch(CartonExpandDataAction(payload))
        flag === "Y" && setOnLoad({ ...onLoad, expandOnLoad: true })
    }

    const getExpandCartonData = (cartonNumber: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a30,
            transaction: `${cartonNumber}`,
            customer: clientValue,
            user: getUserInfo()?.UserName
        }
        dispatch(ExpandDataAction(payload))
    }

    const printPackingSlip = (SOID: any, cartonNumber: any, flag: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a35,
            transaction: SOID,
            where: `${cartonNumber}`,
            fieldArray: `'${flag}'`,
            user: getUserInfo()?.UserName
        }
        {
            flag == "N" ?
                dispatch(PrintSpecificCartonPackinglipAction(payload)) : dispatch(PrintAllCartonPackinglipAction(payload))
        }
        setOnLoad({ ...onLoad, packingSlip: true })
    }

    const printSticker = (SOID: any, cartonNumber: any, flag: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a33,
            transaction: SOID,
            where: `${cartonNumber}`,
            fieldArray: `'${flag}'`,
            user: getUserInfo()?.UserName
        }
        dispatch(PrintSpecificCartonStickerAction(payload))
        setOnLoad({ ...onLoad, printSticker: true })
    }

    const skutableHeaderView = () => {
        try {
            return (
                <Row className='table-header'>
                    <Col span={5} className=" title d-flex align-c">
                        {columns[0]}
                    </Col>
                    <Col span={5} className='title d-flex align-c jc-c'>
                        {columns[1]}
                    </Col>
                    <Col span={5} className='title d-flex align-c jc-c'>
                        {columns[2]}
                    </Col>
                    <Col span={4} className="title d-flex align-c jc-c">
                        {columns[3]}
                    </Col>
                    <Col span={5} className='title d-flex align-c jc-c'>
                        {columns[4]}
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in skutableHeaderView::" + ex)
        }
    }

    const handleCartonExpand = (e: any, index: any, item: any) => {
        e.stopPropagation();
        if (index == expandCartonRow) {
            setExpandCartonRow(-1)
        }
        else {
            setExpandCartonRow(index);
            getExpandCartonData(item?.CartonNumber)
        }
    }


    const cartontableBodyView = (item: any, data: any, index: any) => {
        let open = index == expandCartonRow;
        try {
            return (
                <div className={index === expandCartonRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={9} className="open-icon d-flex align-c"
                         onClick={(e: any) => handleCartonExpand(e, index, item)}
                         >
                            <CaretRightOutlined
                                className={open ? "arrow arrow-down" : "arrow arrow-right"}
                            />
                            <div className='title-blue'>
                                {item?.CartonNumber}
                            </div>
                        </Col>
                        {open && <Col span={24} className="sku-scroll ">
                            {(expandListForCarton?.lineitems || []).map((x: any) => {
                                return (
                                    <div className='content-container border-blue d-flex align-c jc-sb mt-10'>
                                        <div className='text-1'>{x?.SKU} , {x?.Description}</div>
                                        <div className='text-1'>{x?.Quantity}</div>
                                    </div>
                                )
                            })}
                        </Col>}
                    </Row>
                </div >
            )
        } catch (ex) {
            console.log("Err in cartontableBodyView::" + ex)
        }
    }

    const cartontableHeaderView = (data: any) => {
        try {
            return (
                <Row className='table-header  align-c'>
                    <Col span={6} className="title">
                        {"Cartons"}
                    </Col>
                    <Col span={18} className=' d-flex flex-end'>
                        <div className='d-flex'>
                            <div className='white-box' title='Packing List' 
                            onClick={() => printPackingSlip(data?.RawSOID, "", "Y")} 
                            ><PackingListIcon /></div>
                            <div className='white-box' title="Scan Bar" 
                            onClick={() => printSticker(data?.RawSOID, "", "Y")}
                            ><ScanBarIcon /></div>
                        </div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in cartontableHeaderView::" + ex)
        }
    }

    const cartonTableView = () => {
        try {
            return (
                <div className='cartonning-table-container'>
                    {cartontableHeaderView(sodetails)}
                    <div className='body-view'>{(cartonExpandList?.lineitemstwo || []).map((x: any, index: any) => cartontableBodyView(x, sodetails, index))}</div>
                </div>
            )
        } catch (ex) {
            console.log("Err in cartonTableView::" + ex)
        }
    }

    
    const skuBodyView = (item: any, data: any, index: any) => {
        try {
            return (
                <div className={"content-container"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={5} className="d-flex align-c text-1">
                            {item?.SKU}
                        </Col>
                        <Col span={5} className='d-flex align-c jc-c text-1'>
                            {item?.OrderedQuantity}
                        </Col>
                        <Col span={5} className='d-flex align-c jc-c text-1'>
                            {item?.PiecesPicked}
                        </Col>
                        <Col span={4} className="text-1 d-flex align-c jc-c">
                            {item?.PiecesPicked - item?.PiecesPacked}
                        </Col>
                        <Col span={5} className='d-flex align-c jc-c '>
                            {item?.PiecesPacked}
                        </Col>
                    </Row>
                </div >
            )
        } catch (ex) {
            console.log("Err in skuBodyView::" + ex)
        }
    }

    const listingView = () => {
        try {
            return (

                <div className='cartonning-table-container'>
                    {skutableHeaderView()}
                    <div className='body-view'>{(cartonExpandList?.lineitems || []).map((x: any, index: any) => skuBodyView(x, sodetails, index))}</div>

                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }


    const ViewExpandDetails = () => {
        try {
            return (
                <Row gutter={16} className="mt-10">
                    <Col span={12}>{listingView()}</Col>
                    <Col span={12}>{cartonTableView()}</Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in ViewExpandDetails")
        }
    }

    return (
        <div>
            {ViewExpandDetails()}
        <CartonningPackingListModal
                dataValue={PackingSlip?.lineitems}
                viewPackingListModal={viewPackingListModal}
                setPackingListModal={setPackingListModal}
                fromOrgin={'rts'}
            />
            <CartonningStickeringModal
                dataValue={cartonStickeringList?.lineitems}
                setScanModal={setScanModal}
                scanModal={scanModal}
                fromOrgin={'rts'}
            />
        </div>
    );
}

export default RTSCartonDetails;