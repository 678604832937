import { Button } from 'antd';
import React from 'react';
import AppConstants from '../Globals/AppConstants';
import '../CustomComponent/CustomComponent.scss';

function AppButtons(props: any) {
    const {
        buttonType,
        text,
        onClick,
        htmlType,
        form,
        disabled,
        block,
        children,
        value,
        id
    } = props;

    const getClassName = (buttonType: any) => {
        let className;
        switch (buttonType) {
            case AppConstants.buttonTypes.solidLinearGreen:
                className = 'solid-linear-green';
                break;
            case AppConstants.buttonTypes.solidLightGreen:
                className = 'solid-light-green';
                break;
            case AppConstants.buttonTypes.whiteButton:
                className = 'solid-white-button';
                break;
            case AppConstants.buttonTypes.whiteButton:
                    className = 'solid-white-button-1';
                    break;
            case AppConstants.buttonTypes.solidLinearGreen1:
                className = 'solid-linear-green-1';
                break;
            case AppConstants.buttonTypes.solidLinearGreen2:
                className = 'solid-linear-green-2';
                break; 
            case AppConstants.buttonTypes.solidLinearGreen3:
                    className = 'solid-linear-green-3';
                    break;     
            case AppConstants.buttonTypes.solidLinearGreen5:
                className = 'solid-linear-green-5';
                break;                

            default:
                break;
        }
        return className;
    }
    return (
        <div className="app-buttons-container">
            <Button
                className={buttonType}
                onClick={onClick}
                htmlType={htmlType}
                form={form}
                disabled={disabled}
                block={block}
                value={value}
                id={id}
            >
                {children}
                {text}
            </Button>
        </div>
    );
}

export default AppButtons;