import { AppstoreOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import AppReferences from '../../Globals/AppReferences';
import BoxIcon1 from '../../Images/BoxIcon1';
import CategoryIcon from '../../Images/CategoryIcon';
import OthersIcon from '../../Images/othersIcon';
import { getOnboardMenuAction } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo } from '../../Utils/SessionStorage';

function OnBoardingMenu(props: any) {
    const {onChange, selectedKey} = props;
    const navigate = useNavigate();
    
    const menus = [
        {
            id: '1',
            icon: <CategoryIcon/>,
            name: AppConstants.category,
            path: "/on-boarding/categories"
        },
        {
            id: '2',
            icon: <BoxIcon1/>,
            name: AppConstants.sku,
            path: "/on-boarding/SKUs"
        },
        // {
        //     id: '3',
        //     icon: <OthersIcon/>,
        //     name: AppConstants.others,

        // },

    ];
    
    return (
        <div >
            <Menu
            selectedKeys={[selectedKey]} 
            className='on-borading-menu' 
            onClick={onChange}
            >
               {menus?.map((item: any) => 
                    <Menu.Item key={item?.id} onClick={()=>navigate(item?.path)}>
                        <div className='d-flex align-c'>
                            <div>{item?.icon}</div>
                            <div className='ml-10'>
                                {item?.name}
                            </div>
                        </div>
                    </Menu.Item>
                )}
            </Menu>
            
        </div>
    );
}

export default OnBoardingMenu;