import { CloseOutlined } from '@ant-design/icons';
import { SelectChangeEvent, FormControl, InputLabel, MenuItem ,Select} from '@mui/material';
import { Drawer, Button, Steps, Row, Col,  Input, Table } from 'antd';
import React, { useState } from 'react';
import AppButtons from '../../../CustomComponent/AppButtons';
import AppConstants from '../../../Globals/AppConstants';
import { AppImages } from '../../../Globals/AppImages';
import CSVFile from '../../../Images/CSVFile';
import { Radio } from 'antd';

function B2BVerifiedScreen() {
    const [expandRow, setExpandRow] = useState<any>();
    const [value, setValue] = React.useState();
    const [checkedValue, setCheckedValue] = useState<any>([]);
    const [openDrawer, setOpenDrawer] = useState<any>(false);
    const handleChange = (event: any) => {
        setValue(event.target.value);
    };


    const drawerView = () => {
        try {

            return (
                <Drawer
                    className="drawer-view-verified"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "White" }}>
                            <div className='d-flex'>
                                <div>
                                    Sub Order ID:
                                </div>
                                <div>
                                    000001
                                </div>
                            </div>
                            <Button onClick={() => setOpenDrawer(false)}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>
                        </div>
                    }
                    onClose={() => setOpenDrawer(false)}
                    open={openDrawer == true}>
                    <div>
                        <div className='d-flex'>
                            <div className='icon-container'>
                                <img src={AppImages.productIcon} alt="" />
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <div className='sku-desc'>
                                    SKU-001-Lorem ipsm dolor
                                </div>
                                <div className='order-id'>
                                    Order ID :1243565467
                                </div>
                                <div className='d-flex' style={{ marginTop: 5 }}>
                                    <div className='brand-icon'>
                                        <img src={AppImages.brandIcon} alt='' />
                                    </div>
                                    <div className='time'>
                                        24 hrs
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: "#FAFAFA", marginTop: 25, borderRadius: 6, padding: 15 }}>
                        <div style={{ fontFamily: 'Redhat-Bold', marginTop: 5 }} >
                            Overview
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Steps
                                direction='vertical'
                                current={3}
                                items={[
                                    {
                                        title: 'Order Received ',
                                        description: "09-Sep-2022",
                                    },
                                    {
                                        title: 'Pick Released',
                                        description: "09-Sep-2022",
                                    },
                                    {
                                        title: 'Scanning Completed',
                                        description: "10-Sep-2022",
                                    },
                                ]}
                            />
                        </div>
                    </div>

                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }

    }

    const header = () => {
        return (
            <Row gutter={16} style={{ alignItems: "center" }}>
                <Col span={8}>
                    <div className="title">Batch ID: B0000154 </div>
                </Col>
                <Col span={16}>
                    <Row gutter={16}>
                        <Col span={4} style={{ display: "flex", alignItems: "center" ,paddingLeft : 0, paddingRight : 0}} >
                            <AppButtons
                                text="Add To Batch"
                                buttonType={AppConstants.buttonTypes.solidLinearGreen}
                            ></AppButtons>
                        </Col>
                        <Col span={4}>
                            <FormControl fullWidth style={{marginLeft: 10}}>
                                <InputLabel id="demo-simple-select-label">Picker</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={value}
                                    label="Picker"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={10}>Picker 1</MenuItem>
                                    <MenuItem value={20}>Picker 2</MenuItem>
                                    <MenuItem value={30}>Picker 3</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col span={7} style={{ display: "flex", alignItems: "center" }}>
                            <div className='generate-box'>Generate Picklist</div>
                            <div className='csv-box'>
                                <CSVFile />
                                <div>CSV</div>
                            </div>
                        </Col>
                        <Col span={9} style={{ display: "flex", alignItems: "center" }}>
                            <Input placeholder='Search By Sub order ID ' className="input" />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Show Orders</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={value}
                                    label="Show Orders"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>25</MenuItem>
                                    <MenuItem value={30}>50</MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                    </Row>
                </Col>

            </Row>
        )
    }

    const columns: any = [
        {
            title: AppConstants.sku,
            dataIndex: "sku",
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: AppConstants.orderedqty,
            dataIndex: "orderQty",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        }
    ];

    const dataSource: any = [
        {
            sku: 'SKU-01: In nunc lorem nulla convallis',
            orderQty: "20",

        },
        {
            sku: 'SKU-02: In nunc lorem nulla convallis',
            orderQty: "20",

        }

    ];

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={8}>
                    {"Order ID"}
                </Col>
                <Col span={8} className=' d-flex jc-c'>
                    {"Received Data"}
                </Col>
                <Col span={8} className=' d-flex jc-c'>
                    {"Ship By Date"}
                </Col>
            </Row>
        )
    }


    const skuDetailsView = () => {
        return (
            <div className='table-view-container' style={{ background: 'white', padding: 10, borderRadius: 6 }}>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    scroll={{ y: '490px' }}
                    pagination={false}
                />
            </div>
        )
    }
    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
        }
    }

    const tableBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        return (
            <div className={open? "selected-container":"content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={8} className="open-icon d-flex align-c">
                        <Radio 
                        checked={open}
                        onClick={(e: any) => handleExpand(e, index, item)}/>
                        <div className='orderid'>
                            {"23801"}
                        </div>
                    </Col>
                    <Col span={8} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {"09-Sep-2022"}
                        </div>
                    </Col>
                    <Col span={8} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {"14-Sep-2022"}
                        </div>
                    </Col>
                    <Col span={24} className="sku-details">
                        {skuDetailsView()}
                    </Col>
                </Row>
            </div>
        )
    }
    
    const data: any = [
        {
            batchId: "B0000154",
            totalSubOrderId: "25",
            orderQty: "50",
            totalPickedQty: "50"

        },
        {
            batchId: "B0000155",
            totalSubOrderId: "25",
            orderQty: "50",
            totalPickedQty: "50"

        },
        {
            batchId: "B0000156",
            totalSubOrderId: "25",
            orderQty: "50",
            totalPickedQty: "50"

        },
        {
            batchId: "B0000157",
            totalSubOrderId: "25",
            orderQty: "50",
            totalPickedQty: "50"

        }
    ];

    const contentView = () => {
        return (
            <div className='table-screen-container'>
                {tableHeaderView()}
                {data?.map((x: any, index: any) => tableBodyView(x, index))}
            </div>
        )
    }

    return (
        <div className='verified-container'>
            <div className="title-header mt-45" >
                {header()}
            </div>
            {contentView()}
            {drawerView()}
        </div>

    );
}

export default B2BVerifiedScreen;