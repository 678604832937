import { CaretDownFilled, EditOutlined, FileImageOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Table, Tooltip } from 'antd';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import AppReferences from '../../Globals/AppReferences';
import { generateGuId, isArrayEmpty, isArrayNotEmpty, pipefy } from '../../Globals/Helper';
import DownloadIcon from '../../Images/DownloadIcon';
import { getAttachmentsAction, getDetailedDataAction, getEditData, getFormDropdownsAction, getSkuDescAction, getSKUListAction, setAttachmentAsDefaultAction } from '../../Store/Actions/ProcessWMSAction';
import { getClientId, getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import { Amplify, Auth, Storage } from "aws-amplify";
import ImgModelView from '../ImgModelView';
import { AppImages } from '../../Globals/AppImages';
import CreatSku from './CreatSku';
import HandleImage from '../HandleImage';
import NoData from '../../CustomComponent/NoData';

Amplify.configure({
    Auth: {
        identityPoolId: "ap-south-1:0fed2e35-15bf-46d3-b794-c938dc515509", //REQUIRED - Amazon Cognito Identity Pool ID
        region: "ap-south-1", // REQUIRED - Amazon Cognito Region
        userPoolId: "ap-south-1_wGjzBalCU", //OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: "1ptdtdunusepaj6440qh7c11b2", //OPTIONAL - Amazon Cognito Web Client ID 
    },
    Storage: {
        AWSS3: {
            bucket: process.env.REACT_APP_BUCKET_NAME, //REQUIRED -  Amazon S3 bucket name
            region: "ap-south-1", //OPTIONAL -  Amazon service region
        }
    }
});

function SKUListing(props: any) {
    const [openDrawer, setOpenDrawer, setClient]: any = useOutletContext();

    const [childSkuArr, setChildSkuArr] = useState<any>([]);
    const [eanCode, setEanCodeArr] = useState<any>({
        code: '',
        peices: '',
        eanArr: []
    });
    const dispatch = useDispatch();
    const reducerState = useSelector((state: any) => state.ProcessReducerState);
    const { inventorySkuData,
        expandDetailedData,
        onBoardingEditData,
        attachementDetails,
        skuBatchOnLoad
    } = reducerState;
    const [expandRow, setExpandRow] = useState(-1);
    const client = getClientId();
    const [editOnLoad, setEditOnload] = useState(false)
    const [recordNo, setRecordNo] = useState<any>()
    const [openModal, setOpenModal] = useState<any>({});
    const [imageUrl, setImageUrl] = useState<any>();
    const [form]: any = Form.useForm();
    const [onload, setOnload] = useState({
        addOnload: false,
        validateBom: true,
        validateEan: true,
        editOnload: false,
        attachmentOnload: false,
        sku: null,
        defaultOnload: false
    });

    useEffect(() => {
        getSKUList()
        getFormDropdowns()
    }, []);

    useEffect(() => {
        if (openDrawer.addSKU) {
            getMetaData();
        }
    }, [openDrawer.addSKU])

    useEffect(() => {
        if (onload.attachmentOnload && !reducerState.getAttachmentOnLoad) {
            setOnload({ ...onload, attachmentOnload: false });
            setOpenModal({ isOpen: true, urlArray: attachementDetails?.media?.objects, sku: onload.sku })
        }
        if (onload.defaultOnload && !reducerState.onload) {
            setOnload({ ...onload, defaultOnload: false });
            getSKUList()
        }
    }, [onload.attachmentOnload, reducerState.getAttachmentOnLoad, onload.defaultOnload, reducerState.onload])

    const getSKUList = () => {
        let payload = {
            opnfor: AppReferences.opnfor.itemMaster,
            activity: AppReferences.activities.av,
            warehouse: getWareHouseId(),
            customer: client?.Id,
        }
        dispatch(getSKUListAction(payload))
    }

    const getMetaData = () => {
        let payload = {
            opnfor: AppReferences.opnfor.itemMaster,
            activity: AppReferences.activities.a01,
            customer: client?.Id,
        }
        dispatch(getFormDropdownsAction(payload))
    }

    const setAttachmentDefault = (imageUrl: any) => {
        let url = imageUrl?.split('/');
        let payload = {
            opnfor: AppReferences.opnfor.itemMaster,
            activity: AppReferences.activities.a06,
            transaction: openModal?.sku?.ItemId,
            where: url[url.length - 1],
            customer: client?.Id,
            user: getUserInfo()?.UserName
        }
        dispatch(setAttachmentAsDefaultAction(payload));
        setOnload({ ...onload, defaultOnload: true })
    }

    const getattchemnts = (data: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.itemMaster,
            activity: AppReferences.activities.a07,
            transaction: data?.ItemId,
            where: inventorySkuData?.customerCode
        }
        dispatch(getAttachmentsAction(payload))
        setOnload({ ...onload, attachmentOnload: true, sku: data })
    }

    const getFormDropdowns = () => {
        let payload = {
            customer: client?.Id,
            fieldarray: `SELECT ItemId, CONCAT(ItemId, ', ',Description) FROM ItemMaster WHERE ClientID = '${client?.Id}' order by CreatedDate desc;`,
            opnfor: 2,
            tableno: 33,
            warehouse: getWareHouseId(),
            where: 3
        }
        dispatch(getSkuDescAction(payload));
    }

    const getExpandViewData = (sku: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.itemMaster,
            activity: AppReferences.activities.a02,
            customer: client?.Id,
            transaction: sku,
        }
        dispatch(getDetailedDataAction(payload))
    }

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={3}>{AppConstants.attachment}</Col>
                <Col span={3}>{AppConstants.skuCode}</Col>
                <Col span={3} >{AppConstants.SKUDescription}</Col>
                <Col span={3} >{AppConstants.category}</Col>
                <Col span={3} >{AppConstants.EANCode}</Col>
                <Col span={2} className='text-center'>{AppConstants.length}</Col>
                <Col span={2} className='text-center'>{AppConstants.breadth}</Col>
                <Col span={2} className='text-center'>{AppConstants.height}</Col>
                <Col span={2} className='text-center'>{AppConstants.weight}</Col>
                {/* <Col span={2} className='text-center'>{AppConstants.shelfLife}</Col>
            <Col span={2} className='text-center'>{AppConstants.bom}</Col> */}
                <Col span={1} className='text-center'>{AppConstants.action}</Col>
            </Row>
        )
    }

    const bomSKUList = () => {
        const column = [
            {
                title: AppConstants.SKUDescription,
                dataIndex: '',
                render: (item: any) =>
                    <div>
                        {toolTip(item?.ChildItemID + "," + item?.Description)}
                    </div>
            },
            {
                title: AppConstants.requiredQty,
                dataIndex: 'PiecesRequired'
            }
        ]
        return (
            <div className='pending-san-view-container pl-10'>
                <Table
                    columns={column}
                    dataSource={expandDetailedData?.ChildItemList}
                />
            </div>
        )
    }

    const expandRecord = (index: any, data: any) => {
        setExpandRow(expandRow == index ? null : index);
        getExpandViewData(data?.ItemId)
    }

    const editSku = (data: any) => {
        setRecordNo(data?.RecordNo)
        let payload = {
            "opnfor": "880000",
            "activity": "A-05",
            "transaction": data?.RecordNo,
            "customer": client?.Id,
        }
        dispatch(getEditData(payload))
        setEditOnload(true)
    }

    const toolTip = (data: any) => {
        return (
            <Tooltip title={data}>
                {data}
            </Tooltip>
        )
    }


    const tableBodyView = (data: any, index: number,link: any) => {
        return (
            <Row className={index === expandRow ? "content-container1 mt-10" : "content-container mt-10"} gutter={10}>
                <Col span={3}>
                    {data?.uploadLink == '-' ?
                        <div className='file'>
                            <FileImageOutlined width={50} height={50} />
                        </div>
                        :
                        <div className='img-preview' onClick={() => openImageviewr(data?.uploadLink, index, data)}>
                            <HandleImage image={inventorySkuData?.S3Prefix + link} />
                        </div>
                    }

                </Col>
                <Col span={3}
                    onClick={() => (data?.IsBOM != 'N' && expandRecord(index, data))}
                    className={data?.IsBOM != 'N' ? 'd-flex align-c overflow-ellipse  show-label cursor-pointer' : 'd-flex align-c overflow-ellipse'}
                >
                    {toolTip(data?.ItemId)}
                </Col>
                <Col span={3} className='overflow-ellipse'>
                    {toolTip(data?.Description)}
                </Col>
                <Col span={3} className='overflow-ellipse'>
                    {toolTip(data?.Category)}
                </Col>
                <Col span={3} className='overflow-ellipse'>
                    {toolTip(data?.EANCodes)}
                </Col>
                <Col span={2} className='text-center'>{data?.L}</Col>
                <Col span={2} className='text-center'>{data?.B}</Col>
                <Col span={2} className='text-center'>{data?.H}</Col>
                <Col span={2} className='text-center'>{data?.Weight}</Col>
                {/* <Col span={2} className='text-center overflow-ellipse'>{data?.ShelfLife}</Col>
                <Col span={2} className='text-center'>
                    {data?.IsBOM == 'N' ? "No" : 'Yes'}
                </Col> */}
                <Col span={1} className='d-flex jc-c'>
                    <AppButtons
                        disabled={data?.isEdit == 'N' || !client?.isEditable}
                        onClick={() => editSku(data)}
                        text={<EditOutlined />}
                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                    />

                </Col>
                {(expandRow == index && data?.IsBOM != 'N') && <Col span={24}>
                    {bomSKUList()}
                </Col>}
            </Row>
        )
    }

    const openImageviewr = (from: any, index: any, data: any) => {
        setImageUrl(from)
        if (!data) {
            let attachments = onBoardingEditData?.media?.objects;
            let startIndex = attachments?.slice(index, attachments.length);
            let restOfData = attachments?.slice(0, index);
            setOpenModal({ isOpen: true, urlArray: [...startIndex, ...restOfData], index: index });
        }
        else {
            getattchemnts(data)
        }
    }

    const contentView = () => {
        return (
            <div className='p-10 font-16'>
                {tableHeaderView()}
                {isArrayNotEmpty(inventorySkuData?.header) ?
                    <div style={{ height: '70.5vh', overflow: 'scroll' }}>
                        {inventorySkuData?.header?.map((x: any, index: number) =>
                            tableBodyView(x, index,x?.uploadLink)
                        )}
                    </div> :
                    <div className='p-20'>
                        <NoData
                            className='mt-45'
                            description={
                                <>There is no data available</>
                            }
                        />
                    </div>
                }
            </div>
        )
    }

    return (
        <div className='category-listing-screen'>
            {/* {headerView()} */}
            {contentView()}
            <CreatSku
                openDrawer={openDrawer}
                onload={onload}
                setOnload={setOnload}
                setOpenModal={setOpenModal}
                openImageviewr={openImageviewr}
                recordNo={recordNo}
                openModal={openModal}
                setOpenDrawer={setOpenDrawer}
                editOnLoad={editOnLoad}
                setEditOnload={setEditOnload}
                getSKUList={getSKUList}
            />
            <ImgModelView
                open={openModal?.isOpen}
                callBack={setAttachmentDefault}
                fromOrigin='sku'
                urlArray={openModal?.urlArray}
                close={() => setOpenModal({ isOpen: false, urlArray: [] })}
                imageUrl={imageUrl}
            />
        </div>
    );
}

export default SKUListing;