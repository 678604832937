import { CaretDownFilled, CaretRightOutlined, CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, message, Modal, Row, Select, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import AppReferences from '../../Globals/AppReferences';
import { checkHasValue, isArrayEmpty, isArrayNotEmpty, pipefy } from '../../Globals/Helper';
import { createCategoryAction, getCategoryListingAction, getDetailedDataAction } from '../../Store/Actions/ProcessWMSAction';
import { getClientId, getUserInfo } from '../../Utils/SessionStorage';

function CategoryListing(props: any) {
    const [expandRow, setExpandRow] = useState(-1);
    const [openDrawer, setOpenDrawer]: any = useOutletContext();
    const reducerState = useSelector((state: any) => state.ProcessReducerState);
    const { categoryInfo, expandDetailedData, editCategory, createCategoryInfo } = reducerState;
    const categoryObj = {
        attribute: [],
        previewattribute: [],
        packingMaterial: [],
        previewpackingMaterial: [],
        boxes: [],
        previewboxes: []
    }
    const [categoryDetails, setCategoryDetails] = useState<any>(categoryObj);
    const [onload, setOnload] = useState({
        createOnload: false,
        editOnload: false,
        categoryListOnload: false,
        isModalOpen: false
    })
    const dispatch = useDispatch();
    let user = getUserInfo();
    const [form] = Form.useForm();
    const client = getClientId();

    useEffect(() => {
        if (onload.categoryListOnload && !reducerState.onload) {
            setOnload({ ...onload, categoryListOnload: false });
        }
    }, [onload.categoryListOnload, reducerState.onload]);

    useEffect(() => {
        getCategory();
    }, []);

    useEffect(() => {
        if (openDrawer?.isEnableAddCategory) {
            if (categoryInfo?.isClientOptedCategoryWiseStorage == 'False') {
                setOpenDrawer({ ...openDrawer, addCategory: true })
            }
            else {
                setOnload({ ...onload, isModalOpen: true });
            }
        }
    }, [openDrawer?.isEnableAddCategory])

    useEffect(() => {
        if (onload.createOnload && !reducerState.createOnload) {
            setOnload({ ...onload, createOnload: false });
            if (createCategoryInfo?.msg == 'New Category added successfully' || createCategoryInfo?.msg == "Category Updated successfully") {
                message.success(createCategoryInfo?.msg)
                form.resetFields();
                setOpenDrawer({ ...openDrawer, addCategory: false, isEnableAddCategory: false });
                getCategory();
                setCategoryDetails(categoryObj)
            }
            else {
                message.error(createCategoryInfo?.msg)
            }
        }

    }, [onload.createOnload, reducerState.createOnload]);


    useEffect(() => {
        if (onload.editOnload && !reducerState.onload) {
            setOnload({ ...onload, editOnload: false });
            setFormForEditCategory()
        }
    }, [reducerState.onload, onload.editOnload])

    const getCategory = () => {
        let payload = {
            opnfor: AppReferences.opnfor.onboard,
            activity: AppReferences.activities.a01,
            transaction: client?.Id,
            where: getUserInfo()?.CorporateID,
            user: user?.UserName,
            warehouse: getClientId()?.WarehouseId
        }
        dispatch(getCategoryListingAction(payload));
        setOnload({ ...onload, categoryListOnload: true })
    }

    const getSkuList = (category: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.onboard,
            activity: AppReferences.activities.a05,
            transaction: category?.CategoryID,
            customer: client?.Id,
            user: user?.UserName
        }
        dispatch(getDetailedDataAction(payload))
    }

    const createCategory = () => {
        let attributes = categoryDetails?.attribute;
        let where = pipefy(categoryDetails?.attribute);
        let tableNo = `${pipefy(categoryDetails?.packingMaterial)}|${pipefy(categoryDetails?.boxes)}`;
        let payload = {
            opnfor: AppReferences.opnfor.onboard,
            activity: AppReferences.activities.a02,
            transaction: `${getUserInfo()?.CorporateID}|${client?.Id}|${categoryDetails?.categoryName}|${categoryDetails?.storage}|${categoryDetails?.picking}`,
            where: where,
            tableNo: tableNo,
            customer: client?.Id,
            fieldArray: attributes?.length,
            user: user?.UserName,
            warehouse: categoryDetails?.packingMaterial?.length + categoryDetails?.boxes?.length
        }
        dispatch(createCategoryAction(payload));
        setOnload({ ...onload, createOnload: true });
    }

    const updateCategory = () => {
        let attributes = categoryDetails?.attribute;
        let where = pipefy(attributes);
        let tableNo = `${pipefy(categoryDetails?.packingMaterial)}|${pipefy(categoryDetails?.boxes)}`;
        let payload = {
            opnfor: AppReferences.opnfor.onboard,
            activity: AppReferences.activities.a04,
            transaction: `${categoryDetails?.categoryName}|${categoryDetails?.storage}|${categoryDetails?.picking}`,
            where: where,
            customer: client?.Id,
            tableNo: tableNo,
            user: user?.UserName,
            warehouse: `${attributes?.length}|${categoryDetails?.packingMaterial?.length + categoryDetails?.boxes?.length}`,
            fieldArray: categoryDetails?.categoryId
        }
        dispatch(createCategoryAction(payload));
        setOnload({ ...onload, createOnload: true });
    }

    const toolTip = (data: any) => {
        return (
            <Tooltip title={data}>
                {data}
            </Tooltip>
        )
    }


    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={3}>{AppConstants.name}</Col>
                <Col span={3} className='text-center'>{AppConstants.storageType}</Col>
                <Col span={3} className='text-center'>{AppConstants.pickingCode}</Col>
                <Col span={4} className='text-center'>{AppConstants.attributes}</Col>
                <Col span={4} className='text-center'>{AppConstants.packingMaterial}</Col>
                <Col span={4} className='text-center'>{AppConstants.boxes}</Col>
                <Col span={3} className='text-center'>{AppConstants.action}</Col>
            </Row>
        )
    }

    const handleExpand = (data: any, index: any) => {
        if (expandRow == index) {
            setExpandRow(-1);
        }
        else {
            getSkuList(data)
            setExpandRow(index)
        }
    }

    const setFormForEditCategory = () => {
        try {
            let attribute = editCategory?.SelectedAttributeList?.map((x: any) => x?.RecordNo);
            let category = editCategory?.SelectedCategoryDetails[0];
            let packing = editCategory?.SelectedPackingMaterial?.map((x: any) => x?.SKU);
            let boxes = editCategory?.SelectedBoxes?.map((x: any) => x?.Box);
            form.setFieldsValue({
                ['categoryName']: category?.CategoryName,
                ['storage']: category?.SystemLocationTypeID,
                ['picking']: category?.PickingCode,
            });
            setCategoryDetails({
                ...categoryDetails,
                categoryName: category?.CategoryName,
                storage: category?.SystemLocationTypeID,
                picking: category?.PickingCode,
                attribute: attribute,
                previewattribute: editCategory?.SelectedAttributeList,
                categoryId: category?.CategoryID,
                packingMaterial: packing,
                previewpackingMaterial: editCategory?.SelectedPackingMaterial,
                boxes: boxes,
                previewboxes: editCategory?.SelectedBoxes
            })
        } catch (error) {
            console.log("Error in setFormForEditCategory::", error);
        }
    }

    const editCategoryInfo = (data: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.onboard,
            activity: AppReferences.activities.a03,
            transaction: data?.CategoryID,
            user: user?.UserName,
        }
        dispatch(getCategoryListingAction(payload))
        setOnload({ ...onload, editOnload: true })
        setOpenDrawer({ ...openDrawer, addCategory: true, isEdit: true })
    }

    const expandedView = () => {
        let dataSource = expandDetailedData?.lineitems;
        return (
            <div className='expanded-view'>
                <div className='font-16 redhat-bold'>
                    {AppConstants.skus}{isArrayEmpty(dataSource) && <span className='redhat-regular'> - No SKUs Available</span>}
                </div>
                <Row gutter={20}>
                    {dataSource?.map((x: any) =>
                        <Col span={6} className="p-10">
                            <div className="data">
                                {x?.SKU}
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        )
    }

    const tableBodyView = (data: any, index: number, showAction: string) => {
        try {
            let showAction = categoryInfo?.isClientOptedCategoryWiseStorage
            let expanded = expandRow == index;
            let boxVal = '';
            let packing = '';
            if (data?.Box != '-') {
                boxVal = data?.Box[0]?.Boxes;
                data?.Box?.slice(1, data?.Box?.length).map((x: any) => {
                    boxVal += ',' + x?.Boxes;
                })
            }
            if (data?.Packaging != '-') {
                packing = data?.Packaging[0]?.Packaging;
                data?.Packaging?.slice(1, data?.Packaging?.length).map((x: any) => {
                    packing += ',' + x?.Packaging;
                })
            }
            return (
                <Row
                   
                    key={JSON.stringify(data)}
                    className={index === expandRow ? "content-container1 d-flex align-c mt-10" : "content-container  d-flex align-c mt-10"}
                >
                    <Col span={3} className='d-flex align-c jc-c'>
                        <CaretRightOutlined
                            onClick={() => handleExpand(data, index)}
                            className={index === expandRow ? "arrow arrow-down" : "arrow arrow-right"} />
                        <div className='ml-10 overflow-ellipse'>
                            {toolTip(checkHasValue(data?.CategoryName))}
                        </div>
                    </Col>
                    <Col span={3} className='overflow-ellipse text-center'>
                        {toolTip(data?.SystemType)}
                    </Col>
                    <Col span={3} className='overflow-ellipse  text-center' >
                        {toolTip(checkHasValue(data?.Description))}
                    </Col>
                    <Col span={4} className='overflow-ellipse text-center'>
                        {toolTip(checkHasValue(data?.Attributes))}
                    </Col>
                    <Col span={4} className='overflow-ellipse text-center' >
                        {toolTip(packing ? packing : '-')}
                    </Col>
                    <Col span={4} className='overflow-ellipse text-center'>
                        {toolTip(boxVal ? boxVal : '-')}
                    </Col>
                    <Col span={3} className='d-flex align-c jc-c'>
                        <AppButtons
                            disabled={data?.ContractCategory != 'N' || !client?.isEditable}
                            onClick={() => editCategoryInfo(data)}
                            text={<EditOutlined />}
                            buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        />
                    </Col>
                    {expanded &&
                        <Col span={24}>
                            {expandedView()}
                        </Col>
                    }
                </Row>
            )
        } catch (error) {

        }
    }

    const setFormDetails = (value: any, key: any) => {
        setCategoryDetails({ ...categoryDetails, [key]: value })
    }

    const closeDrawer = () => {
        setOpenDrawer({ ...openDrawer, addCategory: false, isEnableAddCategory: false });
        form.resetFields();
        setCategoryDetails(categoryObj);
    }


    const setMultiSelectData = (value: any, key: any, subkey: any) => {
        form.setFieldsValue({
            [subkey]: ''
        });
        let isExist = categoryDetails[subkey]?.find((x: any) => x == value)
        try {
            if (key == 'add' && !isExist) {
                let obj = subkey == 'attribute' ? categoryInfo?.AttributeList?.find((x: any) => x?.RecordNo == value)
                    : subkey == 'packingMaterial' ? categoryInfo?.PackingMaterialList?.find((x: any) => x?.SKU == value) :
                        categoryInfo?.BoxesList?.find((x: any) => x?.Box == value);

                setCategoryDetails({
                    ...categoryDetails,
                    [subkey]: [...categoryDetails[subkey], value],
                    [`preview${subkey}`]: [...categoryDetails[`preview${subkey}`], obj],
                })
            }
            else if (key == 'remove') {
                let keyVal = subkey == 'attribute' ? 'RecordNo' : subkey == 'packingMaterial' ? 'SKU' : 'Box';
                let attributesArr = categoryDetails[subkey]?.filter((x: any) => x != value);
                let previewArr = categoryDetails[`preview${subkey}`]?.filter((x: any) => x[keyVal] != value);
                setCategoryDetails({
                    ...categoryDetails,
                    [subkey]: attributesArr,
                    [`preview${subkey}`]: previewArr,
                });
            }
        } catch (error) {
            console.log("Error in setMultiSelectData::", error);
        }
    }

    const attributeView = () => {
        let attributeValue = ''
        return (
            <>
                <Form.Item
                    label={AppConstants.attributes}
                    name={'attribute'}
                >
                    <Select
                        className='mb-10 select-field'
                        showArrow
                        suffixIcon={<CaretDownFilled />}
                        onChange={(e) =>
                            setMultiSelectData(e, 'add', 'attribute')
                        }
                    >
                        {categoryInfo?.AttributeList?.map((item: any, index: number) =>
                            <Select.Option value={item?.RecordNo} key={JSON.stringify(item)}>
                                {item?.AttributeName}
                            </Select.Option>)}
                    </Select>
                </Form.Item>
                {isArrayNotEmpty(categoryDetails?.previewattribute) &&
                    <Row className='d-flex align-c mb-10'>
                        {categoryDetails?.previewattribute?.map((x: any) =>
                            <Col className='preview-attribute' key={JSON.stringify(x)}>
                                <div>
                                    {x?.AttributeName}
                                </div>
                                <div onClick={() => setMultiSelectData(x?.RecordNo, 'remove', 'attribute')}
                                    className='cross'>
                                    x
                                </div>
                            </Col>
                        )}
                    </Row>
                }
            </>
        )
    }

    const packingMaterialView = () => {
        let packMaterial = ''
        return (
            <>
                <Form.Item
                    label={AppConstants.packingMaterial}
                    name={'packingMaterial'}
                    required
                    rules={[
                        {
                            validator(rule, value, callback) {
                                if (isArrayEmpty(categoryDetails?.packingMaterial)) {
                                    return Promise.reject('Select and add Packing Material')
                                }
                                else {
                                    return Promise.resolve()
                                }
                            },
                        }
                    ]}
                >
                    <Select
                        className='mb-10 select-field'
                        showArrow
                        suffixIcon={<CaretDownFilled />}
                        onChange={(e) =>
                            setMultiSelectData(e, 'add', 'packingMaterial')
                        }
                    >
                        {categoryInfo?.PackingMaterialList?.map((item: any) =>
                            <Select.Option value={item?.SKU} key={JSON.stringify(item)}>
                                {item?.PackingMaterial}
                            </Select.Option>)}
                    </Select>
                </Form.Item>
                <Row className='d-flex align-c mb-10'>
                    {categoryDetails?.previewpackingMaterial?.map((x: any) =>
                        <Col className='preview-attribute' key={JSON.stringify(x)}>
                            <div>
                                {x?.PackingMaterial}
                            </div>
                            <div onClick={() => setMultiSelectData(x?.SKU, 'remove', 'packingMaterial')}
                                className='cross'>
                                x
                            </div>
                        </Col>
                    )}
                </Row>
            </>
        )
    }

    const boxView = () => {
        let box = ''
        return (
            <>
                {/* <div className='font-16 redhat-bold'>
                    {AppConstants.Box}
                </div> */}
                <Form.Item
                    label={AppConstants.Box}
                    name={'boxes'}
                    required
                    rules={[
                        {
                            validator(rule, value, callback) {
                                if (isArrayEmpty(categoryDetails?.boxes)) {
                                    return Promise.reject('Select and add Boxes')
                                }
                                else {
                                    return Promise.resolve()
                                }
                            },
                        }
                    ]}
                >
                    <Select
                        className='mb-10 select-field'
                        showArrow
                        suffixIcon={<CaretDownFilled />}
                        onChange={(e) =>
                            setMultiSelectData(e, 'add', 'boxes')
                        }
                    >
                        {categoryInfo?.BoxesList?.map((item: any) =>
                            <Select.Option value={item?.Box} key={JSON.stringify(item)}>
                                {item?.Boxes}
                            </Select.Option>)}
                    </Select>
                </Form.Item>
                <Row className='d-flex align-c mb-10'>
                    {categoryDetails?.previewboxes?.map((x: any) =>
                        <Col className='preview-attribute' key={JSON.stringify(x)}>
                            <div>
                                {x?.Boxes}
                            </div>
                            <div onClick={() => setMultiSelectData(x?.Box, 'remove', 'boxes')}
                                className='cross'>
                                x
                            </div>
                        </Col>
                    )}
                </Row>
            </>
        )
    }

    const addCategoryFormView = () => {
        return (
            <Form
                form={form}
                onFinish={openDrawer?.isEdit ? updateCategory : createCategory}
                id='form'
                autoComplete="off"
                noValidate
                layout="vertical"
            >
                <Row className='d-flex align-c mt-10' gutter={16}>
                    <Col span={12}>
                        <div className='mb-10 select-field'>
                            <Form.Item
                                label={AppConstants.categoryName}
                                name={'categoryName'}
                                required
                                rules={[{ required: true, message: "Enter Category" }]}
                            >
                                <Input className="input-field" onChange={(e) => setFormDetails(e.target.value, 'categoryName')} />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={AppConstants.storageType}
                            name={'storage'}
                            required
                            rules={[{ required: true, message: "Select Storage type" }]}
                        >
                            <Select
                                className='mb-10 select-field'
                                suffixIcon={<CaretDownFilled />}
                                onChange={(e) => setFormDetails(e, 'storage')}
                            >
                                {categoryInfo?.StorageLocations?.map((item: any) =>
                                    <Select.Option value={item?.SystemLocationTypeID}>
                                        {item?.SystemType}
                                    </Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className='d-flex mt-10' gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label={AppConstants.pickingCode}
                            required
                            name={'picking'}
                            rules={[{ required: true, message: "Select Picking Code" }]}
                        >
                            <Select
                                className='select-field'
                                suffixIcon={<CaretDownFilled />}
                                onChange={(e) => setFormDetails(e, 'picking')}
                            >
                                {categoryInfo?.PickingCode?.map((item: any) =>
                                    <Select.Option value={item?.PickingCode}>
                                        {item?.Description}
                                    </Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {attributeView()}
                    </Col>
                </Row>

                {/* <Row className='d-flex  mt-10' gutter={16}>
                    <Col span={12}>{packingMaterialView()}</Col>
                    <Col span={12}>{boxView()}</Col>
                </Row> */}

                <div className='d-flex flex-end mt-20'>
                    <AppButtons
                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        text={AppConstants.cancel}
                        onClick={closeDrawer}
                    />
                    <AppButtons
                        htmlType='submit'
                        buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                        text={AppConstants.save}
                    />
                </div>
            </Form>
        )
    }

    const modalView = () => {
        return (
            <Modal
                title={
                    <div className='d-flex jc-sb'>
                        <div className='redhat-bold mb-10 font-18'>
                            Note:
                        </div>
                        <Button
                            onClick={() => setOnload({ ...onload, isModalOpen: false })}
                            className="cross-btn"
                        >
                            <CloseOutlined />
                        </Button>
                    </div>
                }
                className='modal-view'
                onCancel={() => setOnload({ ...onload, isModalOpen: false })}
                // onOk={}
                style={{ width: "55%" }}
                open={onload.isModalOpen}
                footer={null}
            >
                <div className='redhat-bold mb-10 font-18 p-20'>
                    {getClientId().Name} is opted for categorywise storage in contract.
                </div>
                <div className='d-flex flex-end mt-10'>
                    <AppButtons
                        onClick={() => setOnload({ ...onload, isModalOpen: false })}
                        text='Okay'
                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                    />
                </div>
            </Modal>
        )
    }

    const addCategory = () => {
        return (

            <Modal
                title={<div className='d-flex align-c jc-sb'>
                    <div className='redhat-bold font-18'>
                        {"Add Category"}
                    </div>
                    <Button
                        onClick={closeDrawer}
                        className="round-cross-btn">
                        <CloseOutlined />
                    </Button>
                </div>}
                style={{ top: 5 }}
                className='modal-view-2'
                open={openDrawer?.addCategory}
                footer={null}
                closable={false}
            >
                {addCategoryFormView()}
            </Modal>
        )
    }

    const contentView = () => {
        let categoryList = categoryInfo?.lineitems[0]?.Lines;
        let showAction = categoryInfo?.isClientOptedCategoryWiseStorage
        return (
            <div className='p-10 font-16'>
                {tableHeaderView()}
                {isArrayNotEmpty(categoryList) ?
                    <div style={{ height: '74vh', overflow: 'scroll' }}>
                        {categoryList?.map((x: any, index: number) => tableBodyView(x, index, showAction))}
                    </div>
                    :
                    <div className='p-20'>
                        <NoData
                            className='mt-45'
                            description={
                                <>There is no data available</>
                            }
                        />
                    </div>
                }
            </div>
        )
    }

    return (
        <div className='category-listing-screen'>
            {contentView()}
            {addCategory()}
            {modalView()}
        </div>
    );
}

export default CategoryListing;