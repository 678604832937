import {  Col, Input, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import InvoiceDetailBox from '../ExpandCollapseContainer';
import AppConstants from '../../Globals/AppConstants';
import './PutAway.scss'
import SubHeader from '../Header/Header';
import { AppImages } from '../../Globals/AppImages';
import { SearchOutlined } from '@ant-design/icons';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import { getPutAwayDetailsAction } from '../../Store/Actions/ProcessWMSAction';
import AppReferences from '../../Globals/AppReferences';

function PutAwayListing() {

    const [expandRow, setExpandRow] = useState(1);
    const [value, individualStageData]: any = useOutletContext();
    const [client_Id, setClient_Id] = useState<any>();
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const {putawayInfo} = reducerState;
    const warehouseId=getWareHouseId();

    useEffect(() => {
        if(individualStageData?.header){
            getData(individualStageData?.header[0])
        }
    }, [individualStageData?.header])

    const getData = (putaway: any) =>{
        try {
            let id = putaway?.id?.split("|");
            let payload = {
                "activity": AppReferences.activities.av,
                'user': getUserInfo()?.UserName,
                "warehouse":getWareHouseId(),
                "transaction": id[2],
                "customer": id[0],
                "opnfor": id[1]
            }
            dispatch(getPutAwayDetailsAction(payload));
        } catch (error) {
            console.log("Error in getData::", error )
        }
    }


    const boxescolumns: any = [
        {
            title: AppConstants.invoiceNumber,
            dataIndex: 'InvoiceNo',
            width: 300,
        },
        {
            title: AppConstants.receivedBox,
            dataIndex: 'RecievedBoxQty',
            width: 150,
            align: "left"
        },
        {
            title: AppConstants.Mover,
            dataIndex: 'Mover',
            width: 250,
            align: "center"
        },
        {
            title: AppConstants.palleteId,
            dataIndex: 'PalletID',
            width: 250,
            align: "center"
        },
        {
            title: AppConstants.putawayBoxes,
            dataIndex: 'PutawayBoxes',
            width: 250,
            align: "center"
        },
        {
            title: AppConstants.status,
            dataIndex: 'Status',
            width: 400,
            align: "center"
        },

    ]

    const setExpand= (index:any, data: any) => {
        setExpandRow(index)
        getData(data)
    }

    
    const dataView = () => {
        return (
            <div className="put-away-container" style={{ margin: 0 }}>
                <Table
                    bordered
                    dataSource={putawayInfo?.lineitems}
                    columns={boxescolumns}
                    pagination={false}
                />
            </div>
        )
    }


    const suffix = (

        <SearchOutlined
            style={{
                fontSize: 20,
                color: 'black',
            }}
        />
    );

    const titleContainer = () => {
        try {
            return (
                <Row>
                    <Col span={12}>
                        <div className='text'>  GateIn ID</div>
                    </Col>
                    <Col span={12}>
                        <div className='text'> Client</div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }

    return (
        <>
            <SubHeader
                title={"Pending Putaway"}
                paginationView="false"
                inputView="true"
                btnView="false" >
            </SubHeader>
            <div style={{ padding: 10 }} className="mt-55">
                <div className='header-part'>
                    {titleContainer()}
                </div>
                {(individualStageData?.header || []).map((item: any, index: any) => {
                    return (
                        <InvoiceDetailBox
                            expandedRow={expandRow}
                            setExpandRow={setExpand}
                            clientId={setClient_Id}
                            key={"expand-item" + (index + 1)}
                            item={item}
                            index={index + 1}
                            path="/put-away"
                            expandIconView="true">
                            <div className='data-view'>
                                {dataView()}
                            </div>
                        </InvoiceDetailBox >
                    )
                })}
            </div>
        </>
    );
}

export default PutAwayListing;