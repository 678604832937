import { Button, Col, Drawer, Dropdown, Form, Input, Menu, Row, Select, Spin, Table, } from 'antd';
import React, { useEffect, useState } from 'react';
import '../SAN.scss';
import { CloseOutlined, ConsoleSqlOutlined, MoreOutlined, PlusOutlined, QuestionCircleFilled } from '@ant-design/icons';
import AppConstants from '../../../Globals/AppConstants';
import SubHeader from '../../Header/Header';
import NavbarLayout from '../../NavbarLayout/NavbarLayout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import File from '../../../Images/File';
import AppButtons from '../../../CustomComponent/AppButtons';
import { AppImages } from '../../../Globals/AppImages';
import QcInvoiceDetailsView from '../../QC/InvoiceDetails/QcInvoiceDetailsView';
import Edit from '../../../Images/Edit';
import { getUserInfo, getWareHouseId } from '../../../Utils/SessionStorage';
import { addInvoiceAndSkuDetailsAction,  captureSKUWiseDetailsAndUnloadingAction, getCaptureInvoiceDetailsAction, getMetaDataAction } from '../../../Store/Actions/ProcessWMSAction';
import { useDispatch, useSelector } from 'react-redux';
import AppReferences from '../../../Globals/AppReferences';

const { Option } = Select;

function CaptureInvoiceDetails() {
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(false);
    const [dataValue, setDataValue] = useState<any>();
    const locationProps = useLocation().state;
    let locationData = locationProps?.invoiceData?.id?.split("|");
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const {captureInvoiceDetails, metaList} = reducerState;
    const [addOnload, setAddOnload] = useState(false);
    const [skuDetails, setSkuDetails] = useState<any>();
    const [form]: any = Form.useForm();
    const [isEdit, setIsEdit] = useState(false);
    const [captureOnload, setCaptureOnload] = useState(false);
    const navigate = useNavigate();
    // captureInvoiceDetails.title.title = "Capture SKU Details";

    useEffect(() => {
        getGetCaptureInvoiceDetails();
        getMetaList();
    }, []);

    useEffect(() => {
        if(addOnload && !reducerState?.onload){
            setAddOnload(false);
            setOpen(false);
            getGetCaptureInvoiceDetails();
            clearFormFields();
            setIsEdit(false);
        }
    }, [addOnload, reducerState?.onload]);

    useEffect(() => {
        if(captureOnload && !reducerState?.onload){
            setCaptureOnload(false);
            navigate(-1);
        }
    }, [captureOnload, reducerState?.onload]);

    const clearFormFields = ()=>{
        form.setFieldsValue({
            ['sku-description']: "",
            ['invoiceQty']: "",
        })
    }
    
    const getMetaList = () => {        
        let payload =  {
            tableno: 24,
            warehouse: getWareHouseId(),
            opnfor: 2,
            where: 2,
            customer: 'A',
            fieldarray: `SELECT im.ItemID, CONCAT(im.ItemID, ', ', Description) AS Description FROM ItemMaster im WHERE im.ClientID = 'A';`,

        }
        dispatch(getMetaDataAction(payload))
    }

    const moveToUnloading = () => {//
        try {
            let payload = {
                warehouse: getWareHouseId(),
                transaction:locationData[2] ,
                customer: locationData[0],
                opnfor: locationData[1],
                activity: AppReferences.activities.ax01,
                user: getUserInfo()?.UserName
            }
           dispatch(captureSKUWiseDetailsAndUnloadingAction(payload))
           setCaptureOnload(true)
        } catch (error) {
            console.log("Error in captureSkuWiseInvoice::", error);
        }
        
    }

    const getGetCaptureInvoiceDetails = () => {
        let payload =  {
            activity: AppReferences.activities.av,
            warehouse: getWareHouseId(),
            user: getUserInfo()?.UserName,
            transaction:locationData[2] ,
            customer: locationData[0],
            opnfor: locationData[1]
        }
        dispatch(getCaptureInvoiceDetailsAction(payload))
    }
    
    const addSkuDetails = () => {
        let fieldArray = (isEdit 
        ? 
        `ItemID='${skuDetails?.skuDescription}',InvQty='${skuDetails?.invoiceQty}',ModifiedBy='${getUserInfo()?.UserName}', ModifiedDate=now()`
        :
        `'${locationData[2]}' ,'${skuDetails?.skuDescription}', '${skuDetails?.invoiceQty}','${getUserInfo()?.UserName}'`);// condition is to check editing r creating newly
        let where = (isEdit 
        ? `GateInInvoicesRecNo='${locationData[2]}' AND ItemID='${skuDetails?.skuDescription}'` 
        : `GateInInvoicesRecNo,ItemID,InvQty,CreatedBy`);// condition is to check editing r creating newly

        let payload = {
          where: where,
          fieldarray: fieldArray,
          tableno: 24,
          opnfor: isEdit ? 3 : 4,
        }
        dispatch(addInvoiceAndSkuDetailsAction(payload));
        setAddOnload(true);
     }
 

    const showDrawer = (item: any) => {
        setOpen(true);
        setSkuDetails({...skuDetails,
            skuDescription: item?.ItemID,
            invoiceQty: item?.InvQty
        });
        form.setFieldsValue({
            ['sku-description']: item?.ItemID,
            ['invoiceQty']: item?.InvQty,
        })
        setView(false);
        {item && setIsEdit(true)};
    };

    const onClose = () => {
        setOpen(false);
        setView(false);
    };

    const onChange = (e: any, item: any) => {
        if (e.key === "1") {
           
        }
    }
    const viewDrawerContent = () => {
        setView(true)
        setOpen(true);
    }

    const menu = (item: any) => {
        return (
            <Menu
                className="booth-representative-menu-view"
                onClick={(e) => onChange(e, item)}
            >
                <Menu.Item className="booth-representative-menu-view-item" key={1}>
                    <div onClick={()=> showDrawer(item)}>{"Edit"}</div>
                </Menu.Item>
                {/* <Menu.Item className="booth-representative-menu-view-item" key={1}>
                    <div>{"Move to Unloading"}</div>
                </Menu.Item> */}
            </Menu>
        )
    }


    const columns: any = [
        {
            title: AppConstants.sku,
            dataIndex: "ItemID",
            width: 350,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: AppConstants.SKUDescription,
            dataIndex: "Description",
            width: 350,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: AppConstants.invoiceQuantity,
            dataIndex: "InvQty",
            width: 200,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        // {
        //     title: AppConstants.executive,
        //     dataIndex: "assignee",
        //     width: 200,
        //     align: "left",
        //     render: (item: any) => {
        //         return (
        //             <div className='text'>
        //                 {item}
        //             </div>
        //         )
        //     }
        // },
        // {
        //     title: AppConstants.labours,
        //     dataIndex: "labours",
        //     align: "center",
        //     width: 200,
        //     render: (item: any) => {
        //         return (
        //             <div className='text'>
        //                 {item}
        //             </div>
        //         )
        //     }
        // },
        {
            title: AppConstants.action,
            dataIndex: "",
            align: "center",
            width: 100,
            render: (item: any) => {
                return (
                    <div className='dropdown' style={{cursor:"pointer"}}>
                        <Dropdown overlay={() => menu(item)} placement="bottomRight"  trigger={['click']}>
                            <MoreOutlined />
                        </Dropdown>
                    </div>
                );
            },
        },
    ];

    const viewDrawer = () => {
        try {
            return (
                <Drawer
                    title={(
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className='add-invoice-box'>
                            <div className='add-invoice-text'>{view === true ? " View Invoice &  SKU details" : (dataValue === null) ? "Add  Invoice & SKU details" : " Edit Invoice & SKU details"}</div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                {view === true &&
                                    <Button className='edit-green-btn' onClick={() => showDrawer([])} >
                                        <Edit />
                                    </Button>
                                }
                                <Button onClick={onClose} className="cross-btn">
                                    <CloseOutlined />
                                </Button>
                            </div>
                        </div>
                    )}
                    width={450}
                    open={open}
                    bodyStyle={{ paddingBottom: 80 }}
                >  {view === false &&
                    <> 
                  
                    </>
                    }
                    {view === true &&
                        <>
                            <div className='entry-view-container'>
                                <Row justify="space-around" gutter={16}>
                                    <Col span={8}>
                                        <div>
                                            <div className='vehicle-info-box1'>
                                                <div className='vehicle-info-title'>
                                                    {AppConstants.invoiceNum}
                                                </div>
                                                <div className='vehicle-info'>
                                                    IN83267098
                                                </div>
                                            </div>
                                            <div className='vehicle-info-box2'>
                                                <div className='vehicle-info-title'>
                                                    {AppConstants.executive}
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center" }} className="user-container">
                                                    <div className="user-img">
                                                        <img src={AppImages.userImg} alt="user-Img" />
                                                    </div>
                                                    <span className="manager-name">John Doe</span>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div>
                                            <div className='vehicle-info-box1'>
                                                <div className='vehicle-info-title'>
                                                    {AppConstants.invoiceBoxs}
                                                </div>
                                                <div className='vehicle-info'>
                                                    50
                                                </div>
                                            </div>
                                            <div className='vehicle-info-box2'>
                                                <div className='vehicle-info-title'>
                                                    {AppConstants.labours}
                                                </div>
                                                <div className='vehicle-info' style={{ marginTop: 5 }}>
                                                    3
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div>
                                            <div className='vehicle-info-box1'>
                                                <div className='vehicle-info-title'>
                                                    {AppConstants.invoiceWeight}
                                                </div>
                                                <div className='vehicle-info'>
                                                    100
                                                </div>
                                            </div>
                                            <div className='vehicle-info-box2'>
                                                {/* <div className='vehicle-info-title'>{AppConstants.noOfInvoices}</div>
                                        <div className='vehicle-info'>4</div> */}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='sku-container'>SKU-Description (Invoice Qty)</div>
                                {([1, 2, 3] || []).map((x: any) => {
                                    return (
                                        <div className='value'>SKU001 - Lorem Ipsum (40)</div>
                                    )
                                })}

                            </div>
                        </>}
                </Drawer>
            )
        } catch (ex) {
            console.log("Error in viewDrawer::" + ex)
        }
    }

    const TotalNumofInvc = () => {
        return (
            <div className='table-screen-container '>
                <Table
                    columns={columns}
                    dataSource={captureInvoiceDetails?.lineitemstwo}
                    pagination={false}
                    bordered />
            </div>
        )
    }

    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button className='green-btn' onClick={() => showDrawer(null)} >
                    <PlusOutlined />
                </Button>
                <AppButtons
                   onClick={() => moveToUnloading()}
                    buttonType={AppConstants.buttonTypes.solidLinearGreen}
                    text="Move to Unloading"
                />
                <Link to="/process/SAN" state={{ Option: 2 }}>
                    <Button className="close-btn">
                        <CloseOutlined />
                    </Button>
                </Link>
            </div>
        )
    }


    return (
        <>
            <NavbarLayout
                titleContent="sub-head"
                process={captureInvoiceDetails?.title}
            />
            <SubHeader
                title={(
                    <>
                        <div>Capture SKU Details</div>
                        <div className='wms-client-name'>{locationData && locationData[2]} -<span className='client-name'> {locationProps?.invoiceData?.name}</span></div>
                    </>
                )}
                btnView="true"
                inputView="true"
                paginationView="false">
                {childrenView()}
            </SubHeader>
            <div className='capture-invoice-container mt-45'>
                {TotalNumofInvc()}
                {open && <div className='view-container'>{viewDrawer()}</div>}
            </div>
            <Spin spinning={addOnload}/>
        </>
    )
}


export default CaptureInvoiceDetails;
