import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import NoData from '../../CustomComponent/NoData';
import { isArrayNotEmpty } from '../../Globals/Helper';
import { setClientId } from '../../Utils/SessionStorage';

function BeingOnboarded(props: any) {
    const navigate = useNavigate();
    const reducerState = useSelector((state: any) => state.ProcessReducerState);
    const [clientMenu, stageDetails, , setMenuId, warehouseDetails]: any = useOutletContext();
    const { clientData } = reducerState;

    console.log("stageDetails",stageDetails?.ThirdColumn)

   

    const goToOnboarding = (clientId: any, data: any) => {
        setMenuId('1');
        let client = clientData?.Clients?.find((x: any) => x?.Id == clientId);
        client.isEditable = warehouseDetails?.isEditable == 'Y';
        setClientId({ ...client, ...data });
        navigate('/on-boarding/categories')
    }

    const contentView = () => {
        return (
            <>
                {(stageDetails?.ThirdColumn || []).map((x: any) =>
                    <div className='warehouse-version'
                        onClick={() => goToOnboarding(clientMenu, x)}
                    >
                        {x?.WarehouseName} - Contract Version {x?.VersionNo}
                    </div>
                )}
            </>
        )
    }
    return (
        <>{isArrayNotEmpty(stageDetails?.ThirdColumn) ?
            <div >
                {contentView()}
            </div>
            :
            <div className='p-20'>
                <NoData
                    className='mt-45'
                    description={
                        <>There is no data available</>
                    }
                />
            </div>
        }
        </>
    );
}

export default BeingOnboarded;