import { CaretRightOutlined, CloseOutlined, CloseSquareFilled, CloseSquareOutlined, DeleteFilled, PlusOutlined, SaveFilled, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Layout, message, Radio, Row, Select, Tooltip } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import { AppModuleIds } from '../../Globals/AppReferenceIds';
import { checkHasValue, isArrayNotEmpty } from '../../Globals/Helper';
import AppReferences from '../../Globals/AppReferences';
import Calender from '../../Images/Calender';
import {
    cancelSalesOrderAction, deleteAction, generatePicklistAction, getChannelStatusAction,
    getPickerAction,
    getSalesOrderlientAction,
    getSalesOrderStatusDetailsAction,
    getSalesOrderTypeAction,
    getSkuDescAction,
    lockAndProcessAction
} from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId, setClientId, getClientId, setDashboardMenuId, getDashboardMenuId } from '../../Utils/SessionStorage';
import CardContainer from '../CardContainer';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import SkeletonLoader from '../OrderProcessing/SkeletonLoader';
import SpinLoader from '../SpinLoader';
import SalesOrderDrawerView from './SalesOrderDrawerView';
import SalesOrderMenu from './SalesOrderMenu';
import SOModalView from './SOModalView';
import NoData from '../../CustomComponent/NoData';
import zIndex from '@mui/material/styles/zIndex';

function SalesOrderScreen(props: any) {
    const [process, setProcess] = useState<any>('');
    const [ClientID, setClient] = useState<any>('');
    const [expandRow, setExpandRow] = useState(-1);
    const [openDrawer, setOpenDrawer] = useState<any>({
        openAddSku: false,
        openEditSku: false,
        openSo: false,
        delete: false,
        searchPopup: false,
        isCancelSo: false,
        cancelSoData: {}
    });
    const dispatch = useDispatch();
    const reducerstate: any = useSelector((state: any) => state?.ProcessReducerState);
    const {
        salesClients,
        channelStatus,
        salesOrderStatusDetails,
        lockAndProcess,
        searchSOData,
        pickers,
        pickedListOnLoad
    } = reducerstate;
    const [onload, setOnload] = useState<any>({
        statusOnload: false,
        clientOnload: false,
        SODetailsOnload: false,
        createOnload: false,
        skuOnload: false,
        deleteOnload: false,
        processOnload: false,
        cancelOnload: false
    });
    const statusSaved = '101';
    const [salesOrder, setSalesOrder] = useState<any>();
    const [date, setDate] = useState<any>("");
    const [picker, setpicker] = useState('');
    const [isInTable, setIsInTable] = useState<any>(false);

    useEffect(() => {
        getSalesClients();
        getSalesOrderType();
        getPickers();
        return () => setDashboardMenuId('');
    }, []);

    useEffect(() => {
        if (ClientID) {
            let findClient = salesClients?.header.find((x: any)=> x?.id == ClientID)
            console.log("findClient",findClient,ClientID)
            if(findClient){
                getStatuses();
            }else{
                let clientId = salesClients?.header[0]?.id;
                setClient(clientId)
            }
            
            
        }
    }, [ClientID]);

    useEffect(() => {
        if (process) {
            getSOstatusDetails();
        }
    }, [process])

    useEffect(() => {
        if (searchSOData && openDrawer?.search) {
            setOpenDrawer({ ...openDrawer, searchPopup: true, search: false, soid: "" });
        }
    }, [searchSOData])


    useEffect(() => {
        if (salesClients?.header) {
            let clientId = salesClients?.header[0]?.id;
            getClientId() ? setClient(getClientId()) : setClient(clientId);
        }
    }, [salesClients])

    useEffect(() => {
        try {
            if (isArrayNotEmpty(channelStatus?.lineitems)) {
                setProcess(null);
                let statusId = channelStatus?.lineitems[0]?.id;
                let menuId = getDashboardMenuId();
                if (menuId) {
                    let exist = channelStatus?.lineitems?.find(({ id }: any) => id == menuId);
                    if (exist) {
                        setProcess(exist?.id);
                    }
                    else {
                        setProcess(statusId)
                    }
                }
                else {
                    setProcess(statusId);
                    setDashboardMenuId(statusId);
                }
                setOnload({ ...onload, statusOnload: false })
            }
        } catch (error) {
            console.log("Error in setProcess::", error)
        }
    }, [channelStatus]);

    useEffect(() => {
        if (onload.deleteOnload && !reducerstate.deleteOnload) {
            setOnload({ ...onload, deleteOnload: false });
            setOpenDrawer({ ...openDrawer, delete: false })
            getSalesClients();
        }
        if (onload.cancelOnload && !reducerstate.onload) {
            setOnload({ ...onload, cancelOnload: false });
            setOpenDrawer({ ...openDrawer, isCancelSo: false });
            getSalesClients();
        }
    }, [onload.deleteOnload, reducerstate.deleteOnload, onload.cancelOnload, reducerstate.onload]);

    useEffect(() => {
        if (onload.processOnload && !reducerstate.processOnload) {
            setOnload({ ...onload, processOnload: false });
            if (lockAndProcess?.msg == 'Sales Order updated successfully') {
                message.success(lockAndProcess?.msg);
                getSalesClients();
                getStatuses();
                getSOstatusDetails();
            }
            else {
                message.error(lockAndProcess?.msg)
            }
        }
    }, [onload.processOnload, reducerstate.processOnload]);

    useEffect(() => {
        if (onload?.generateOnload == true && pickedListOnLoad == false) {
            setOnload({ ...onload, generateOnload: false });
            getSalesClients();
            getStatuses();
            getSOstatusDetails();
            setpicker('')
        }
    }, [onload?.generateOnload, pickedListOnLoad])

    useEffect(() => {
        try {
            if (onload.clientOnload == true && reducerstate.onload == false) {
                setOnload({ clientOnload: false });
            }
        } catch (error) {
            console.log("Error in setClient::", error)
        }
    }, [onload.clientOnload, reducerstate.onload]);

    const getPickers = () => {
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a06,
            warehouse: getWareHouseId(),
            fieldarray: getUserInfo()?.CorporateID
        }
        dispatch(getPickerAction(payload))
    }


    const searchSalesOrder = (e: any) => {
        setOpenDrawer({ ...openDrawer, search: true })
        let payload = {
            opnfor: AppReferences.opnfor.salesClient,
            activity: AppReferences.activities.a09,
            transaction: e?.target?.value,
            user: getUserInfo()?.UserName,
            warehouse: getWareHouseId()
        }
        dispatch(getSalesOrderStatusDetailsAction(payload));
    }

    const getSalesOrderType = () => {
        let payload = {
            opnfor: AppReferences.opnfor.salesClient,
            activity: AppReferences.activities.a04,
        }
        dispatch(getSalesOrderTypeAction(payload));
    }

    const lockANdProcess = (data: any) => {
        let soRecLines = data?.Lines[0]?.SoRelLineRecNo;
        let length = data?.Lines?.length;
        data?.Lines?.slice(1, length)?.map((x: any) => {
            soRecLines += `| ${x?.SoRelLineRecNo}`;
        })
        let payload = {
            opnfor: AppReferences.opnfor.salesClient,
            activity: AppReferences.activities.a08,
            transaction: soRecLines,
            where: length,
            user: getUserInfo()?.UserName
        }
        dispatch(lockAndProcessAction(payload));
        setOnload({ ...onload, processOnload: true });
    }

    const generatePicklist = (data: any) => {
        if (picker) {
            let payload = {
                opnfor: AppReferences.opnfor.salesClient,
                activity: AppReferences.activities.a12,
                transaction: data?.SOID,
                customer: ClientID,
                warehouse: getWareHouseId(),
                user: getUserInfo()?.UserName,
                where: picker,
            }
            dispatch(generatePicklistAction(payload));
            setOnload({ ...onload, generateOnload: true })
        }
        else {
            message.info("Select Picker");
        }

    }

    const getSalesClients = () => {
        let payload = {
            opnfor: AppReferences.opnfor.salesClient,
            activity: AppReferences.activities.av,
            where: "0", // Fixed value from frontend
            warehouse: getWareHouseId(),
            user: getUserInfo()?.UserName,
            fieldArray: getUserInfo()?.CorporateID
        }
        dispatch(getSalesOrderlientAction(payload));
        setOnload({ clientOnload: true });
        setClient(null);
    }

    const getStatuses = () => {
        let payload = {
            opnfor: AppReferences.opnfor.salesClient,
            activity: AppReferences.activities.a01,
            customer: ClientID,
            warehouse: getWareHouseId(),
            user: getUserInfo()?.UserName
        }
        dispatch(getChannelStatusAction(payload));
        setOnload({ statusOnload: true });
        setProcess(null);
    }

    const getSOstatusDetails = () => {
        let payload = {
            opnfor: AppReferences.opnfor.salesClient,
            activity: AppReferences.activities.a06,
            transaction: process,
            warehouse: getWareHouseId(),
            customer: ClientID
        }
        dispatch(getSalesOrderStatusDetailsAction(payload));
    }

    const openDeletePopup = (sku: any) => {
        setOpenDrawer({ ...openDrawer, delete: true, sku: sku })
    }

    const deleteSku = () => {
        let payload = {
            opnfor: AppReferences.opnfor.salesClient,
            activity: AppReferences.activities.a07,
            transaction: openDrawer?.sku?.id
        }
        dispatch(deleteAction(payload))
        setOnload({ ...onload, deleteOnload: true });
    }

    const onChange = (e: any) => {
        setProcess(e.key);
        setDashboardMenuId(e.key);
    };

    const customerOnChange = (e: any) => {
        setClient(e.target.value);
        setClientId(e.target.value);
        setProcess("")
    };

    const openCancelSOConfirmation = (data: any) => {
        setOpenDrawer({ ...openDrawer, isCancelSo: true, cancelSoData: data })
    }

    const cancelSo = () => {
        let payload = {
            opnfor: AppReferences.opnfor.salesClient,
            activity: AppReferences.activities.a10,
            transaction: openDrawer.cancelSoData?.SOID,
            where: openDrawer.cancelSoData?.ReleaseNumber,
            warehouse: getWareHouseId(),
            customer: ClientID,
            user: getUserInfo()?.UserName

        }
        dispatch(cancelSalesOrderAction(payload))
        setOnload({ ...onload, cancelOnload: true })
    }

    const addSku = (soId: any) => {
        if (soId?.SoType == "B2C Offline") {
            setSalesOrder(3);
        }
        setIsInTable(true);
        setOpenDrawer({ ...openDrawer, soId: soId?.SOID, openAddSku: true });
    }

    const customerView = () => {
        try {
            return (
                <>{isArrayNotEmpty(channelStatus?.lineitems) ?
                    <div className="select-right-container">
                        <Input
                            className='sales-input'
                            suffix={<SearchOutlined />}
                            placeholder="Search SO"
                            value={openDrawer?.searchSoid}
                            onChange={(e) => setOpenDrawer({ ...openDrawer, searchSoid: e?.target?.value })}
                            onPressEnter={(e) => searchSalesOrder(e)}
                        />
                        <SalesOrderMenu
                            menuId={ClientID}
                            menus={salesClients?.header}
                            onChange={customerOnChange}
                        />
                    </div> :
                    <div className='p-20'>
                        <NoData
                            className='mt-45'
                            description={
                                <> No Data</>
                            }
                        />
                    </div>
                }
                </>
            )
        } catch (error) {
            console.log("Error in customeView::", error);
        }
    }

    const handleExpand = (data: any, index: any) => {
        setExpandRow(expandRow == index ? null : index);
    }

    const header = () => {
        try {
            let obj = (salesClients?.header).find((x: any) => x?.id == ClientID);
            return (
                <SubHeader
                    title={obj?.name}
                    paginationView="false"
                    inputView="true"
                    btnView="true"
                    className='d-flex align-c'
                >
                    <Row className='align-c'>
                        <Col span={12} className='pl-10'>
                            <div className='d-flex align-c jc-c'>
                                <span className='font-16 redhat-bold mr-10'>
                                    Previous
                                </span>
                                <DatePicker format={'DD-MM-YYYY'}
                                    className='input-field c-p'
                                    // allowClear={false}
                                    // disabledDate={(e) => e > moment()}
                                    // value={date}
                                    onChange={(e) => setDate(moment(e).format('DD-MMM-YYYY'))}
                                    suffixIcon={<Calender />}
                                />
                                <span
                                    className='font-16 redhat-bold ml-10'
                                >
                                    Next
                                </span>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='d-flex flex-end mr-10'>
                                <AppButtons
                                    block={true}
                                    onClick={openSoDrawer}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                    text={'Create New SO'} />
                            </div>
                        </Col>
                    </Row>
                </SubHeader>
            )
        } catch (error) {
            console.log("Error in header::", error);
        }
    }

    const listingView = (data: any, index: any) => {
        try {
            let isExpandopen = (expandRow == index);
            return (
                <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                    <Row className='align-c'>
                        <Col span={5}>
                            <div className="title-container">
                                <div className='d-flex align-c'>
                                    <CaretRightOutlined
                                        onClick={() => handleExpand(data, index)}
                                        className={index === expandRow ? "arrow arrow-down" : "arrow arrow-right"} />
                                    <div
                                        className='text overflow-ellipse d-flex align-c'
                                        title={data?.SOID}
                                        style={{ width: 150, marginLeft: 5 }}>
                                        {data?.id}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className='text  '>
                                {checkHasValue(data?.ReferenceId)}
                            </div>
                        </Col>
                        <Col span={(Number(process) == 400 || Number(process) == 350 || process != '101') ? 4 : 3}>
                            <div className='text  '>
                                {checkHasValue(data?.SoType)}
                            </div>
                        </Col>
                        <Col span={(Number(process) == 400 || Number(process) == 350) ? 5 : 4}>
                            <div className='text  '>
                                {checkHasValue(data?.CreatedDate) !== '-' ? moment(checkHasValue(data?.CreatedDate)).format("DD-MM-YYYY") : '-'}
                            </div>
                        </Col>
                        <Col span={(Number(process) == 400 || Number(process) == 350) ? 5 : 4}>
                            <div className='text  '>
                                {checkHasValue(data?.ShipByDate) !== '-' ? moment(checkHasValue(data?.ShipByDate)).format("DD-MM-YYYY") : '-'}
                            </div>
                        </Col>
                        {/* {statusSaved == process &&  */}
                        {(Number(process) != 400 && Number(process) != 350) &&
                            <Col span={process == '101' ? 4 : 3} className='pr-10 d-flex align-c ' >
                                {(data?.SoType == 'Kitting' && process == '101') &&
                                    <Select placeholder={'Pickers'} className={'picker-select'} onChange={(e) => setpicker(e)}>
                                        {pickers?.Pickers?.map((user: any) =>
                                            <Select.Option value={user?.UserID}>{user?.Name}</Select.Option>
                                        )}
                                    </Select>
                                }
                                {statusSaved == process && <div className={"app-buttons-container"} >
                                    <Tooltip title={data?.SoType != 'Kitting' ? "Lock and process" : 'Generate Picklist'}>
                                        <Button
                                            disabled={data.Lines.length < 1}
                                            onClick={() => { (data?.SoType != 'Kitting') ? lockANdProcess(data) : generatePicklist(data) }}
                                            className='solid-linear-green-1'
                                        >
                                            <SaveFilled />
                                        </Button>
                                    </Tooltip>
                                </div>}
                                {(data?.SoType == 'Kitting' && process == '101') ?
                                    <></>
                                    :
                                    <div className='app-buttons-container'>
                                        <Tooltip title="Cancel Order">
                                            <AppButtons
                                                onClick={() => openCancelSOConfirmation(data)}
                                                text={<CloseOutlined />}
                                                buttonType={AppConstants.buttonTypes.solidRedOutline}
                                            />
                                        </Tooltip>
                                    </div>}
                            </Col>
                        }
                    </Row>
                    {isExpandopen &&
                        <div style={{ marginTop: 10 }}>
                            {tableView(data?.Lines, data)}
                        </div>
                    }
                </div >
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const tableView = (skuList: any, soid: any) => {
        try {
            let size = skuList?.length;
            return (
                <div style={{ background: "white", padding: 10, borderRadius: 6 }}>
                    {/* {detailsView(item)} */}
                    {skuList && <div>
                        <div className='header-part-2'>
                            {tableTitleContainer()}
                        </div>
                        <div >
                            {(skuList || []).map((data: any, index: any) =>
                                tableList(data, index, size, soid)
                            )}
                        </div>
                    </div>}
                </div>

            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const tableTitleContainer = () => {
        let saved = statusSaved == process;
        return (
            <Row>
                <Col span={6}>
                    <div className='redhat-semibold font-16'>
                        {AppConstants.skuDescription}
                    </div>
                </Col>
                <Col span={saved ? 6 : 4} className='d-flex jc-c'>
                    <div className='redhat-semibold font-16'>
                        {saved ? AppConstants.quality : AppConstants.orderedqty}
                    </div>
                </Col>
                <Col span={saved ? 6 : 4} className='d-flex jc-c'>
                    <div className='redhat-semibold font-16'>
                        {saved ? AppConstants.shelfLife : AppConstants.fullfilled}
                    </div>
                </Col>
                {!saved &&
                    <Col span={4} className='d-flex jc-c'>
                        <div className='redhat-semiboldfont-16'>
                            {AppConstants.Picked}
                        </div>
                    </Col>}
                {!saved &&
                    <Col span={3} className='d-flex jc-c'>
                        <div className='redhat-semibold font-16'>
                            {AppConstants.packed}
                        </div>
                    </Col>}
                <Col span={saved ? 6 : 3} className={'d-flex jc-c'}>
                    <div className='redhat-semibold font-16'>
                        {saved ? AppConstants.action : AppConstants.balance}
                    </div>
                </Col>
            </Row>
        )
    }

    const tableList = (data: any, index: any, size: any, soid: any) => {
        try {
            let saved = statusSaved == process;
            return (
                <div className="content-container" style={{ marginTop: 10 }}>
                    <Row className='d-flex align-c'>
                        <Col span={6} className='d-flex align-c'>
                            <div className='text font-16'>
                                {checkHasValue(data?.SKU)}
                            </div>
                        </Col>
                        <Col span={saved ? 6 : 4} className='d-flex align-c jc-c'>
                            <div className='text-1 font-16'>
                                {checkHasValue(saved ? data?.Quantity : data?.Ordered)}
                            </div>
                        </Col>
                        <Col span={saved ? 6 : 4} className='d-flex jc-c'>
                            <div className='text-1 font-16'>
                                {checkHasValue(saved ? data?.ExpectedShelfLife : data?.Allocated)}
                            </div>
                        </Col>
                        {!saved &&
                            <Col span={4} className='d-flex jc-c'>
                                <div className='redhat-bold font-16'>
                                    {checkHasValue(data?.Picked)}
                                </div>
                            </Col>}
                        {!saved &&
                            <Col span={3} className='d-flex jc-c'>
                                <div className='redhat-bold font-16'>
                                    {checkHasValue(data?.Packed)}
                                </div>
                            </Col>}
                        <Col span={saved ? 6 : 3} className={!saved ? 'd-flex jc-c' : ''}>
                            {saved ?
                                <div className='d-flex align-c' style={{ paddingLeft: '40%' }}>
                                    <div
                                        onClick={() => openDeletePopup(data)}
                                        className="delete-btn"
                                        style={{ cursor: "pointer" }}>
                                        <DeleteFilled />
                                    </div>
                                    {(size - 1 == index) &&
                                        <div className='eye-btn font-16 ml-10 cursor-pointer' onClick={() => addSku(soid)}>
                                            <PlusOutlined />
                                        </div>}
                                </div>
                                :
                                <div className='redhat-bold font-16'>
                                    {data?.Ordered - data?.Allocated}
                                </div>
                            }
                        </Col>
                    </Row>
                </div >
            )
        } catch (error) {
            console.log("Error in tableList::", error)
        }
    }

    const openSoDrawer = () => {
        setOpenDrawer({ ...openDrawer, openSo: true });
    }

    const tableHeader = () => {
        return (
            <Row className='table-header'>
                <Col span={5}>
                    {AppConstants.salesOrder}
                </Col>
                <Col span={4}>
                    {AppConstants.referenceNumber}
                </Col>
                <Col span={(Number(process) == 400 || Number(process) == 350 || process != '101') ? 4 : 3}>
                    {AppConstants.soType}
                </Col>
                <Col span={(Number(process) == 400 || Number(process) == 350) ? 5 : 4}>
                    {AppConstants.crtDate}
                </Col>
                <Col span={(Number(process) == 400 || Number(process) == 350) ? 5 : 4}>
                    {AppConstants.shipByDate}
                </Col>
                {(Number(process) == 400 || Number(process) == 350) ?
                    <Col></Col> :
                    <Col span={process != '101' ? 3 : 4}>
                        {AppConstants.action}
                    </Col>
                }
            </Row>
        )
    }

    const dataView = () => {
        let filteredData = [];
        if (date && date != 'Invalid date') {
            filteredData = salesOrderStatusDetails?.lineitems?.filter((x: any) => x?.ShipByDate == date);
        }
        else {
            filteredData = salesOrderStatusDetails?.lineitems;
        }
        return (
            <div className="select-right-container" style={{ height: '100%' }}>
                {header()}
                <div className='p-10'>
                    {tableHeader()}
                    {isArrayNotEmpty(filteredData) ?
                        <div style={{ height: '450px',overflow:"scroll" }}>
                            {(filteredData || []).map((item: any, index: Number) =>
                                listingView(item, index)
                            )}
                        </div>
                        :
                        <div className='p-20'>
                            <NoData
                                className='mt-45'
                                description={
                                    <> No sales order available</>
                                }
                            />
                        </div>
                    }

                </div>
            </div>
        )
    }

    const contentView = () => {
        return (
            <Row className="row-container">
                <Col span={4}
                    style={
                        {
                            backgroundColor: "white",
                            paddingTop: 2,
                            height: '92.2vh',
                            // marginTop: "50px",
                            overflow: "scroll",
                            borderRight: "1px solid #D1D1D1",
                            zIndex: 1,
                        }}
                >
                    {customerView()}
                </Col>
                <Col span={3} 
                style={{ 
                    padding: 10, 
                    height: '92.4vh', 
                    borderRight: "1px solid #D1D1D1",
                    // marginTop: "50px", 
                }}
                    className="sub-sider-menu">
                    {isArrayNotEmpty(channelStatus?.lineitems) ?
                        <CardContainer
                            fromOrigin="RTO"
                            scrollable={true}
                            menu={channelStatus?.lineitems}
                            handleMenuSelect={onChange}
                            value={process}
                            load={onload?.statusOnload}
                        />
                        :
                        <div className='p-20'>
                            <NoData
                                className='mt-45'
                                description={
                                    <> No Data</>
                                }
                            />
                        </div>
                    }
                </Col>
                <Col span={17}>
                    {!reducerstate.stageOnload ?
                        dataView()
                        :
                        <SkeletonLoader />
                    }
                </Col>
            </Row>
        )
    }


    const deletePopup = () => {
        return (
            <Modal
                title={'Delete'}
                className='modal-view'
                open={openDrawer.delete}
                footer={null}
            >
                <div className='text'>
                    Do you want delete the sku <span className='redhat-bold ml-10'>{openDrawer?.sku?.SKU}</span> ?
                </div>
                <div className='d-flex flex-end mt-10'>
                    <AppButtons
                        onClick={() => setOpenDrawer({ ...openDrawer, delete: false })}
                        text={AppConstants.cancel}
                        buttonType={AppConstants.buttonTypes.whiteButton}
                    />
                    <AppButtons
                        onClick={deleteSku}
                        text={AppConstants.confirm}
                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                    />
                </div>
            </Modal>
        )
    }

    return (
        <Layout>
            <Layout>
                <NavbarLayout
                    titleContent="main-head"
                    process={salesClients?.title}
                    initial={AppModuleIds.oms}
                    selectedOms={'103'}
                />
            </Layout>
            <Content className="antd-content-white">
                {contentView()}
                {deletePopup()}
                <SalesOrderDrawerView
                    setSalesOrder={setSalesOrder}
                    salesOrder={salesOrder}
                    openDrawer={openDrawer}
                    setOpenDrawer={setOpenDrawer}
                    Client={ClientID}
                    setClient={setClient}
                    getSOstatusDetails={getSOstatusDetails}
                    getSalesClients={getSalesClients}
                    setIsInTable={setIsInTable}
                    isInTable={isInTable}
                />
                <SOModalView
                    process={process}
                    statusSaved={statusSaved}
                    soid={openDrawer}
                    openSearch={openDrawer?.searchPopup}
                    close={() => setOpenDrawer({ ...openDrawer, searchPopup: false, isCancelSo: false })}
                    data={searchSOData}
                    cancelSo={cancelSo}
                />
                <SpinLoader loading={onload.processOnload} />
            </Content>
        </Layout>
    );
}

export default SalesOrderScreen;