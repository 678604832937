const setUserInfo = (user: any) => {
    let stringifyUser = JSON.stringify(user);
    localStorage.setItem("user", stringifyUser);
}

const getUserInfo = () => {
    let stringifyUser: any = localStorage.getItem("user");
    return JSON.parse(stringifyUser);
}

const clearStorage = () => {
    localStorage.clear();
    window.history.replaceState(null, '', "/login");
    window.location.href = "/";
}
const setWareHouseId = (id: any) => {
    localStorage.setItem("warehouse", id);
}
const getWareHouseId = () => {
    let wareHouseId: any = localStorage.getItem("warehouse");
    return wareHouseId;
}
const setDashboardMenuId = (id: any) => {
    localStorage.setItem("menuId", id);
}
const getDashboardMenuId = () => {
    let menuId: any = localStorage.getItem("menuId");
    return menuId;
}
const setProcess = (title: any) => {
    let stringifyUser = JSON.stringify(title);
    localStorage.setItem("title", stringifyUser);
}
const getProcess = () => {
    let stringifyUser: any = localStorage.getItem("title");
    return JSON.parse(stringifyUser);
}

const setChannelId = (title: any) => {
    let stringifyUser = JSON.stringify(title);
    localStorage.setItem("channelId", stringifyUser);
}
const getChannelId = () => {
    let stringifyUser: any = localStorage.getItem("channelId");
    return JSON.parse(stringifyUser);
}
const setClientId = (title: any) => {
    let stringifyUser = JSON.stringify(title);
    localStorage.setItem("clientId", stringifyUser);
}
const getClientId = () => {
    let stringifyUser: any = localStorage.getItem("clientId");
    return JSON.parse(stringifyUser);
}
const setRTODetails = (data: any) =>{
    let stringifyData = JSON.stringify(data);
    localStorage.setItem("rtoDetails", stringifyData);
}

const getRTODetails = () => {
    let stringifyData: any = localStorage.getItem("rtoDetails");
    return JSON.parse(stringifyData);
}
const setMenuName = (menu: any) => {
    let menuId = JSON.stringify(menu);
    localStorage.setItem("menu",menuId);
}

const getMenuName = () => {
    let labelName: any = localStorage.getItem("menu");
    return JSON.parse(labelName);
}
const setWarehouseInfo = (title: any) => {
    let stringifyUser = JSON.stringify(title);
    localStorage.setItem("warehouseDetail", stringifyUser);
}
const getWarehouseInfo = () => {
    let stringifyUser: any = localStorage.getItem("warehouseDetail");
    return JSON.parse(stringifyUser);
}

export {
    setUserInfo,
    getUserInfo,
    clearStorage,
    setWareHouseId,
    getWareHouseId,
    setDashboardMenuId,
    getDashboardMenuId,
    setProcess,
    getProcess,
    setChannelId,
    getChannelId,
    setClientId,
    getClientId,
    setRTODetails,
    getRTODetails,
    setWarehouseInfo,
    getWarehouseInfo,
    setMenuName,
    getMenuName,}