import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, } from 'antd';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import { $ } from 'react-jquery-plugin'
import { useState } from 'react';
import { getBarCodeCompleteDataAction } from '../../Store/Actions/ProcessWMSAction';

function CartonningStickeringModal(props) {
    const [printLoad, setPrintLoad] = useState(false);
    const {
        setScanModal,
        scanModal,
        dataValue,
        fromOrgin
    } = props

    const printStickers = () => {
        try {
            setPrintLoad(true);
            let frame1 = $('<iframe />');
            frame1[0].name = "frame1";

            $("body").append(frame1);
            var frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow : frame1[0].contentDocument.document ? frame1[0].contentDocument.document : frame1[0].contentDocument;
            frameDoc.document.open();
            //Create a new HTML document.
            frameDoc.document.write('<html><head><style type="text/css">');
            frameDoc.document.write('@page {size: 290px 170px !important;margin: 0 !important;padding: 0rem !important;}');
            frameDoc.document.write('@media print{.Barcode-Img-Container{ page-break-inside: avoid; page-break-before: always; }}');
            frameDoc.document.write('body{-webkit-print-color-adjust:exact !important; print-color-adjust:exact !important;}');
            frameDoc.document.write('.bar-code-img { display: flex;justify-content: center; align-items: center; width: 100%; height: 2rem; margin-top: 1rem;}');
            frameDoc.document.write('.bar-code-img img {width: 100% !important; height: 100% !important;}');

            // frameDoc.document.write('.stickering-box {page-break-after: always !important;}');

            // frameDoc.document.write('svg{diplay: none !important;}');
            frameDoc.document.write('</style></head><body>');
            let element = document.getElementById("stickerComponentRef");
            let data = element.innerHTML;
            // let barcode = document.getElementById((key == 'all' ? sticker?.Barcode : sticker)+'img');
            // barcode.setAttribute('onError', '');
            let replacedValue = data.replaceAll("&amp;", '&');
            replacedValue = data.replace('<div class="icon"><span role="img" aria-label="check-circle" class="anticon anticon-check-circle select-icon"><svg viewBox="64 64 896 896" focusable="false" data-icon="check-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path></svg></span></div></div>', '');
            frameDoc.document.write(replacedValue);
            frameDoc.document.write('</body></html>');
            frameDoc.document.close();
            setTimeout(() => {
                setPrintLoad(false);
                window.frames["frame1"].focus();
                window.frames["frame1"].print();
                frame1.remove();
            }, 2000)

            return true;
        } catch (error) {
            console.log("Error in printStickers::", error);
        }
    }

    const getBarCode = (CartonNumber) => {
        try {
            let barcodeUrl = `http://bwipjs-api.metafloor.com/?bcid=code128&text=${CartonNumber}`;
            return (
                <div
                    className='bar-code-img'
                // style={{ marginTop: 10, alignItems: "center", display: "flex", justifyContent: "center",width:"100%",height:"40px" }}
                ><img style={{ width: "100%", height: "40px" }} src={barcodeUrl} alt={`Barcode ${CartonNumber}`} /></div>
            )
        } catch (ex) {
            console.log("Err in getBarCode")
        }
    }

    const scanBarCodeModal = () => {
        try {
            return (
                <Modal
                    title={
                        <div className='d-flex align-c jc-sb'>
                            <div className='redhat-bold mb-10 font-18'>
                                Printing Carton
                            </div>
                            <div className='d-flex align-c'>
                                {fromOrgin != 'rts' && <AppButtons
                                    onClick={() => printStickers()}
                                    buttonType={AppConstants.buttonTypes.whiteButton}
                                    text="Print"
                                />}
                                <Button
                                    style={{ marginLeft: 16 }}
                                    onClick={() => setScanModal(false)}
                                    className="cross-btn">
                                    <CloseOutlined />
                                </Button>
                            </div>
                        </div>}
                    className='modal-view'
                    onCancel={() => setScanModal(false)}
                    // onOk={}
                    open={scanModal}
                    footer={null}
                >
                    <div
                        id="stickerComponentRef">
                        {(dataValue || []).map((item) => {
                            return (
                                <div
                                    style={{ padding: 10, marginTop: 10, fontSize: 16, fontFamily: "RedHat-Bold", color: "#2D2D2D", backgroundColor: "#FFFFFF", alignItems: "center", display: "flex", justifyContent: "center" }}
                                    className='Barcode-Img-Container'>
                                    <div>
                                        <div style={{ alignItems: "center", display: "flex", justifyContent: "center", fontSize: '2.5rem' }}>{item?.CustomerName}</div>
                                        <div style={{ alignItems: "center", display: "flex", justifyContent: "center", fontSize: '1.8rem' }}>{item?.CityPinCode}</div>
                                        {getBarCode(item?.CartonNumber)}
                                        <div style={{ marginTop: 5, alignItems: "center", display: "flex", justifyContent: "center", fontSize: '2rem' }}>{item?.CartonNumber}</div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </Modal>
            )
        } catch (ex) {
            console.log("Err in View Modal::" + ex)
        }
    }

    return (
        <div>
            {scanBarCodeModal()}
        </div>
    );
}

export default CartonningStickeringModal;