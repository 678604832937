import { CaretRightOutlined, FilterFilled } from '@ant-design/icons';
import { Checkbox, Col, Dropdown, Menu, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoData from '../../CustomComponent/NoData';
import { isArrayNotEmpty, uniqueBy } from '../../Globals/Helper';
import { GetExpandOrderDispatchedReportList, GetOrderDispatchedReportList } from '../../Store/Actions/ProcessWMSAction';
import { getWareHouseId, getUserInfo } from '../../Utils/SessionStorage';
import "../Report/Report.scss"

let Arr: any = []
let uniqueClient: any = [];
let uniqueChannel: any = [];
let channelArr: any = []

function OrderDispatchedReport(props: any) {
    const {
        setSearchView,
        searchView,
        setDateView,
        dateView
    } = props
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);

    const [filterData, setFilterData] = useState<any>();
    const [filterDataView, setFilterDataView] = useState<any>(false)
    const [OnLoad, setOnLoad] = useState<any>(false)
    const [expandRow, setExpandRow] = useState<any>();
    const { reportODList, reportExpandODList, reportSearchGateInList, reportDateList, reportODOnload } = reducerState;


    useEffect(() => {
        if (OnLoad === true && reportODOnload === false) {
            setFilterDataView(false)
            setSearchView(false)
            setDateView(false)
            getFilterData()
            getChannelFilterData()
        }
    }, [OnLoad, reportODOnload])

    const getData = (data: any) => {
        let payload = {
            "opnfor": "R00003",
            "activity": "A-04",
            "transaction": data ? data?.SOID : 0,
            "where": data ? "Y" : "N",
            "warehouse": getWareHouseId(),
            "user": getUserInfo()?.UserName
        }
        data ? dispatch(GetExpandOrderDispatchedReportList(payload)) : dispatch(GetOrderDispatchedReportList(payload))
        setOnLoad(true)
    }

    useEffect(() => {
        getData(null)
    }, [])

    const getSearchFilterData = () => {
        let arr = (reportSearchGateInList?.lineitems || []).map((item: any) => {
            let Obj = {
                "ClientName": item?.ClientName
            }
            return Obj
        })
        uniqueClient = uniqueBy(arr, 'ClientName')
    }

    const getDateFilterData = () => {
        let arr = (reportDateList?.lineitems || []).map((item: any) => {
            let Obj = {
                "ClientName": item?.ClientName
            }
            return Obj
        })
        uniqueClient = uniqueBy(arr, 'ClientName')
    }

    const getChannelFilterData = () => {
        let arr = (reportODList?.lineitems || []).map((item: any) => {
            let Obj = {
                "ChannelName": item?.ChannelName,
            }
            return Obj
        })
        uniqueChannel = uniqueBy(arr, 'ChannelName')
    }

    const getFilterData = () => {
        let arr = (reportODList?.lineitems || []).map((item: any) => {
            let Obj = {
                "ClientName": item?.ClientName,
            }
            return Obj
        })
        uniqueClient = uniqueBy(arr, 'ClientName')
    }


    useEffect(() => {
        if (searchView === false) {
            getFilterData()
        }
    }, [searchView])

    useEffect(() => {
        if (dateView === false) {
            getFilterData()
        }
    }, [dateView])

    useEffect(() => {
        if (reportDateList) {
            setDateView(true)
            setSearchView(false)
            setFilterDataView(false)
            getDateFilterData()
        }
    }, [reportDateList])

    useEffect(() => {
        if (reportSearchGateInList) {
            setSearchView(true)
            setDateView(false)
            setFilterDataView(false)
            getSearchFilterData()
        }
    }, [reportSearchGateInList])

    useEffect(() => {
        if (filterData?.length == 0) {
            setFilterDataView(false)
            setSearchView(false)
            setDateView(false)
        }
    }, [filterData])



    const filterChannelWiseData = (filterData: any) => {
        let Arr = (filterData || []).filter((item: any) => {
            return channelArr.includes(item?.ChannelName)
        });
        if (Arr?.length == 0) {
            message.error("No Match Found")
        } else {
            setFilterData(Arr)
            setFilterDataView(true)
            setSearchView(false)
            setDateView(false)
        }

    }



    const filterTableData = () => {
        if (channelArr?.length == 0) {
            let filterData = (dateView ? reportDateList?.lineitems : searchView ? reportSearchGateInList?.lineitems : reportODList?.lineitems).filter((item: any) => {
                return Arr.includes(item?.ClientName)
            });
            setFilterData(filterData)
            setFilterDataView(true)
            setSearchView(false)
            setDateView(false)
        } else if (Arr?.length == 0) {
            let filterData = (dateView ? reportDateList?.lineitems : searchView ? reportSearchGateInList?.lineitems : reportODList?.lineitems).filter((item: any) => {
                return channelArr.includes(item?.ChannelName)
            });
            setFilterData(filterData)
            setFilterDataView(true)
            setSearchView(false)
            setDateView(false)
        } else if ((channelArr?.length > 0) && (Arr?.length > 0)) {
            let filterData = (dateView ? reportDateList?.lineitems : searchView ? reportSearchGateInList?.lineitems : reportODList?.lineitems).filter((item: any) => {
                return Arr.includes(item?.ClientName)
            });
            filterChannelWiseData(filterData)
            setFilterDataView(true)
            setSearchView(false)
            setDateView(false)
        }

    }


    const chennalOnChange = (e: any) => {

        if (e?.target.checked) {
            channelArr.push(e?.target.value)
            filterTableData()
        } else {
            channelArr = channelArr.filter((item: any) => item !== e?.target.value)
            filterTableData()
        }
    };


    const onChange = (e: any) => {

        if (e?.target.checked) {
            Arr.push(e?.target.value)
            filterTableData()
        } else {
            Arr = Arr.filter((item: any) => item !== e?.target.value)
            filterTableData()
        }


    };

    const channelmenu = () => {
        return (
            <Menu
                className="booth-representative-menu-view"
            >
                {(uniqueChannel || []).map((item: any) => {
                    return (
                        <Menu.Item className="booth-representative-menu-view-item" key={1}>
                            <div> <Checkbox value={item?.ChannelName} onChange={chennalOnChange}>{item?.ChannelName}</Checkbox></div>
                        </Menu.Item>
                    )
                })}
            </Menu>
        )
    }

    const menu = () => {
        return (
            <Menu
                className="booth-representative-menu-view"
            >
                {(uniqueClient || []).map((item: any) => {
                    return (
                        <Menu.Item className="booth-representative-menu-view-item" key={1}>
                            <div> <Checkbox value={item?.ClientName} onChange={onChange}>{item?.ClientName}</Checkbox></div>
                        </Menu.Item>
                    )
                })}
            </Menu>
        )
    }

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
            getData(data)
        }
    }
    const skuInvoiceHeaderView = () => {
        try {
            return (
                <Row className='table-header invoice-header'>
                    <Col span={8}>
                        {"SKU, Description"}
                    </Col>
                    <Col span={8} className=' d-flex jc-c'>
                        {"Ordered Qty"}
                    </Col>
                    <Col span={8} className=' d-flex jc-c'>
                        {"Dispatched Qty"}
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in skuInvoiceHeaderView::" + ex)
        }
    }
    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={4}>
                    {"Sales Order #"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Client Name"}
                    <span style={{ marginLeft: 20 }}>
                        <Dropdown
                            overlayStyle={{ width: 244, left: 1094 }}
                            overlay={() => menu()} placement="bottomRight" trigger={['click']}>
                            <div><FilterFilled /></div>

                        </Dropdown>
                    </span>
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Customer Name"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Channel"}
                    <span style={{ marginLeft: 20 }}>
                        <Dropdown
                            overlayStyle={{ width: 244, left: 1094 }}
                            overlay={() => channelmenu()} placement="bottomRight" trigger={['click']}>
                            <div><FilterFilled /></div>

                        </Dropdown>
                    </span>
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Received Date"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Ship By Date"}
                </Col>
            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        return (
            <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={4} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='title-blue'>
                            {item?.SOID}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.ClientName}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.CustomerName}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.ChannelName}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.ReceivedDate}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.ShipByDate}
                        </div>
                    </Col>
                    {open && <Col span={24} style={{ padding: 10, marginTop: 10, background: "#FFFFFF", borderRadius: 6, }}>
                        {skuInvoiceHeaderView()}
                        <div className='scroll'>
                            {(reportExpandODList?.lineitems || []).map((x: any) => {
                                return (
                                    <Row className='content-container border-blue d-flex align-c jc-sb mt-10'>
                                        <Col span={8} className='text-1'>{x?.SKU}</Col>
                                        <Col span={8} className=' d-flex align-c jc-c'>
                                            <div className='text-1'>
                                                {x?.OrderedQty}
                                            </div>
                                        </Col>
                                        <Col span={8} className=' d-flex align-c jc-c'>
                                            <div className='text-1'>
                                                {x?.DispatchedQty}
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </div>
                    </Col>}
                </Row>
            </div >
        )
    }
    return (
        <div className='GateInReport-Container'>
            {(dateView ? isArrayNotEmpty(reportDateList?.lineitems) : searchView ? isArrayNotEmpty(reportSearchGateInList?.lineitems) : filterDataView ? isArrayNotEmpty(filterData) : isArrayNotEmpty(reportODList?.lineitems)) ?
                <div style={{ padding: 20 }}>
                    {tableHeaderView()}
                    <div className='table-scroll'>
                        {(dateView ? reportDateList?.lineitems : searchView ? reportSearchGateInList?.lineitems : filterDataView ? isArrayNotEmpty(filterData) ? filterData : reportODList?.lineitems : reportODList?.lineitems || []).map((x: any, index: any) => tableBodyView(x, index))}
                    </div>
                </div>
                :
                <div className='p-20'>
                    <NoData
                        className='mt-45'
                        description={
                            <>There is no reports available in <span className='redhat-bold ml-5'>Order Dispatched</span></>
                        }
                    />
                </div>
            }
        </div>
    );
}

export default OrderDispatchedReport;