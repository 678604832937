import React from 'react';

function OmsIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5538 18.8615L11.8462 15.9077L12.9883 14.7655L14.5538 16.331L18.0886 12.7963L19.2308 14.1846M10.4185 18.8615H2.73846C2.21618 18.8615 1.7153 18.654 1.346 18.2847C0.976698 17.9154 0.769226 17.4145 0.769226 16.8923V3.10766C0.769226 2.01474 1.64553 1.13843 2.73846 1.13843H16.5231C17.0453 1.13843 17.5462 1.3459 17.9155 1.7152C18.2848 2.0845 18.4923 2.58539 18.4923 3.10766V10.7877C17.6258 10.2855 16.6117 9.99997 15.5385 9.99997L14.5538 10.0787V9.01535H4.70769V10.9846H12.2794C11.4647 11.5216 10.7962 12.2526 10.3339 13.1119C9.8716 13.9712 9.62999 14.9319 9.63077 15.9077C9.63077 16.9809 9.9163 17.995 10.4185 18.8615ZM9.63077 12.9538H4.70769V14.923H9.63077M14.5538 5.07689H4.70769V7.04612H14.5538" fill="white" />
        </svg>
    );
}

export default OmsIcon;

