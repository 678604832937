import { Col, Radio, Row } from 'antd';
import React from 'react';

function SalesOrderMenu(props: any) {
    const {onChange, menuId, menus} = props;

    return (
        <Row>
            <Col span={24} className='select-right-container'>
                <div className="content-container-po">
                    <Radio.Group 
                        style={{ width: "100%" }}
                        buttonStyle="solid"
                        onChange={onChange}
                        value={menuId} >
                        {(menus || []).map((item: any) => {
                            return (
                                <Radio.Button value={item?.id ? item?.id : item?.Id} >
                                    <div className="card-title-container" >
                                        <div className='label'>{item?.name ? item?.name : item?.Name}</div>
                                        {<div className="count">
                                            {item?.cnt ? item?.cnt : item?.Cnt}
                                        </div>}
                                    </div>
                                </Radio.Button>
                            )
                        })}
                    </Radio.Group>
                </div>
            </Col>
        </Row>
    );
}

export default SalesOrderMenu;