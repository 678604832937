import React from 'react';

function InventryManagementIconNew() {
    return (
        <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6667 1.66699H3.33341C2.50008 1.66699 1.66675 2.41699 1.66675 3.33366V5.84199C1.66675 6.44199 2.02508 6.95866 2.50008 7.25032V16.667C2.50008 17.5837 3.41675 18.3337 4.16675 18.3337H15.8334C16.5834 18.3337 17.5001 17.5837 17.5001 16.667V7.25032C17.9751 6.95866 18.3334 6.44199 18.3334 5.84199V3.33366C18.3334 2.41699 17.5001 1.66699 16.6667 1.66699ZM11.6667 11.667H8.33341C7.87508 11.667 7.50008 11.292 7.50008 10.8337C7.50008 10.3753 7.87508 10.0003 8.33341 10.0003H11.6667C12.1251 10.0003 12.5001 10.3753 12.5001 10.8337C12.5001 11.292 12.1251 11.667 11.6667 11.667ZM16.6667 5.83366H3.33341V3.33366H16.6667V5.83366Z" fill="white" />
        </svg>
    );
}

export default InventryManagementIconNew;

