import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppConstants from '../../../Globals/AppConstants';
import AppReferences from '../../../Globals/AppReferences';
import EmizaIcon from '../../../Images/emizaIcon';
import WMSIcon2 from '../../../Images/WMSIcon2';
import { getSendSanInfoAction, sendSanDetails } from '../../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../../Utils/SessionStorage';
import SpinLoader from '../../SpinLoader';
import '../GRN-Email/GrnEmailView.scss'
interface DataType {
    item: any,
    callBack: any,
}
function GrnEmailView(props: DataType) {
    const {
        item,
        callBack,
    } = props;
    let warehouse = getWareHouseId();
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const { getSendSanInfo, sendSanonload, emailSent } = reducerState;
    const [comment, setComment] = useState("");
    const [onLoad, setOnLoad] = useState(false);
    const navigate = useNavigate();
    const sanInfo = getSendSanInfo?.header[0];

    useEffect(() => {
        if (item) {
            getSendSanInfoData();
        }
    }, [item]);

    const getSendSanInfoData = () => {
        try {
            let custOpnfor = item?.id?.split("|")
            let payload = {
                "activity": AppReferences.activities.av,
                "opnfor": custOpnfor[1],
                "customer": custOpnfor[0],
                "transaction": custOpnfor[2],
                "user": getUserInfo()?.UserName,
                "warehouse": warehouse,
            }
            dispatch(getSendSanInfoAction(payload))
        } catch (error) {
            console.log("Error In getSendSanInfoData::", error);
        }
    }

    useEffect(() => {
        if (onLoad === true && sendSanonload === false) {
            setOnLoad(false);
            if (emailSent?.msg == "") {
                message.success("Email sent Successfully");
                callBack();
                getSendSanInfoData()
            }
            else {
                message.error(emailSent?.msg);
            }
        }
    }, [onLoad, sendSanonload])

    const sendSan = () => {
        let custOpnfor = (item?.id).split("|")
        let additionalComment = document.getElementById("textAdditionalComment");;
        if (comment === " " || comment === undefined) { // If no value in Additional comment, remove the specific Div

            additionalComment?.remove()
        }
        // Remove the Text-Area even if the comment is present or not 
        let emailBody: any = document.getElementById('sanFinalMail'); // get the email body which needs to send
        let tag = document.createElement("p");
        tag.setAttribute("id", "emailContent");
        tag.setAttribute("style", "font-size: 16px; font-family: sans-serif;color: GrayText; padding:20px");
        let content = comment?.replaceAll(/[\r\n]/gm, " "); //to replace newlines with single space
        let text = document.createTextNode(content.replaceAll(/  +/g, ' '));//to replace multiple spaces with single space
        tag.appendChild(text);
        emailBody.append(tag);   // As it's String and we have to pass this body to JSON we need to make this email body in single quotes(')  
        let finalBody = emailBody?.innerHTML.replace(/"/g, "'");

        // Making of final email, concat the email body and the style/css into the emailFinal
        let emailFinal = "<html><head><style>.text-field{width:100%,border:1px solid darkgrey;height:40px}</style></head><body>" + finalBody + "</body></html>"

        let payload = {
            "activity": AppReferences.activities.ax01,
            "transaction": custOpnfor[2],
            "warehouse": warehouse,
            "opnfor": custOpnfor[1],
            "customer": custOpnfor[0],
            "user": getUserInfo()?.UserName,
            "where": emailFinal,
            "fieldArray": getUserInfo()?.CorporateID,
        }
        dispatch(sendSanDetails(payload));
        setOnLoad(true);
        let element = document.getElementById("emailContent");
        element?.remove();
    }

    const VanButtons = () => {
        return (
            <div className="van-buttons" style={{ cursor: "pointer" }}>
                <button className="send-van" style={{ cursor: "pointer" }} onClick={() => sendSan()}>{"Send Email"}</button>
            </div>
        )
    }

    const ContentFunc = () => {
        try {
            let isRTO = getSendSanInfo?.isRTO == "N";
            return (
                <div className='email-container'>
                    <div className='wms360-icon'><WMSIcon2 /></div>
                    <div className="text-container">
                        <div className='content'>
                            <div style={{ fontFamily: "sans-serif", fontSize: 18, color: 'GrayText', marginLeft: 5, marginTop: 15 }}>Dear Customer,</div>
                            <div style={{ fontFamily: "sans-serif", fontSize: 18, color: 'GrayText', marginTop: 10, marginLeft: 5 }}>Greeting from 360WMS,</div>
                        </div>
                    </div>
                    <div id='sanFinalMail' style={{ paddingLeft: 15, paddingRight: 15 }}>
                        <div style={{ fontSize: 18, fontFamily: "sans-serif", color: "GrayText", marginLeft: 20, marginTop: 5 }}>
                            <span>
                                {isRTO ? 'This is Goods Received Note (GRN)' : 'This is RTO/Return'} for Invoice Number: {getSendSanInfo?.lineitems[0]?.RefernceNo} ( {getSendSanInfo?.lineitems[0]?.ID} )
                            </span>
                        </div>
                        <div>
                            <div style={{ background: '#F0F0F4', padding: 20, marginTop: 10 }}>
                                {/* <table > */}
                                <div style={{ fontSize: 16, fontFamily: "sans-serif", fontStyle: "normal", color: "#4E4E4E", display: "flex" }}>
                                    <div style={{ width: isRTO ? "20%" : '35%', marginLeft: 20 }}><b>{"Invoice Number"}</b></div>
                                    {isRTO && <div style={{ width: "20%", marginLeft: 20 }}><b>{"Vendor"}</b></div>}
                                    {isRTO && <div style={{ width: "20%", marginLeft: 20 }}><b>{"PO Type"}</b></div>}
                                    <div style={{ width: isRTO ? "20%" : '35%', marginLeft: 20 }}><b>{"PO Number"}</b></div>
                                    <div style={{ width: isRTO ? "20%" : '30%', marginLeft: 30 }}><b>{"Release No"}</b></div>
                                </div>
                                {/* {(getSendSanInfo?.lineitems || []).map((x: any) => {
                                    return ( */}
                                <div style={{ fontSize: 16, fontFamily: "sans-serif", color: "#747474", display: "flex" }}>
                                    <div style={{ width: isRTO ? "20%" : '35%', marginLeft: 20 }}>{sanInfo?.RefernceNo}</div>
                                    {isRTO && <div style={{ width: "20%", marginLeft: 20 }}>{sanInfo?.VendorName}</div>}
                                    {isRTO && <div style={{ width: "20%", marginLeft: 20 }}>{sanInfo?.Type}</div>}
                                    <div style={{ width: isRTO ? "20%" : '35%', marginLeft: 20 }}>{sanInfo?.ID}</div>
                                    <div style={{ width: isRTO ? "20%" : '30%', marginLeft: 30 }}>{sanInfo?.ReleaseNumber}</div>
                                </div>
                                {/* )
                                })} */}
                                {/* </table> */}
                                <br />
                                <table style={{ marginTop: "-13px" }} >
                                    <tr style={{ fontSize: 16, fontFamily: "sans-serif", color: "#FFFFFF", backgroundColor: "#505050" }}>
                                        <th style={{ width: 400, textAlign: "center", padding: 6, fontWeight: 200 }}>{"SKU Name"}</th>
                                        <th style={{ width: 400, textAlign: "center", padding: 6, fontWeight: 200 }}>{"SKU Description"}</th>
                                        <th style={{ width: 500, textAlign: "center", padding: 6, fontWeight: 200 }}>{"Invoice Qty"}</th>
                                        <th style={{ width: 500, textAlign: "center", padding: 6, fontWeight: 200 }}>{"GRN Qty"}</th>
                                        <th style={{ width: 500, textAlign: "center", padding: 6, fontWeight: 200 }}>{"Sellable"}</th>
                                        <th style={{ width: 500, textAlign: "center", padding: 6, fontWeight: 200 }}>{"Damaged"}</th>
                                    </tr>
                                    {(getSendSanInfo?.lineitems || []).map((x: any) => {
                                        return (
                                            <tr style={{ fontSize: 16, fontFamily: "sans-serif", color: "#232323", backgroundColor: "rgb(255, 255, 255)", marginTop: 5 }}>
                                                <td style={{ width: 400, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{x?.ItemID}</td>
                                                <td style={{ width: 400, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{x?.Description}</td>
                                                <td style={{ width: 500, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{x?.InvoiceQty}</td>
                                                <td style={{ width: 500, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{x?.GRNQTY}</td>
                                                <td style={{ width: 500, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{x?.Good}</td>
                                                <td style={{ width: 500, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{x?.Damaged}</td>
                                            </tr>
                                        )
                                    })}
                                </table>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "100%", padding: 15 }}>
                        <textarea style={{ padding: 10 }} className="text-field" placeholder='Enter Additional comment..' id='textAdditionalComment' onChange={(e: any) => setComment(e?.target.value)} ></textarea>
                    </div>
                    <div className="regards-container">
                        <div style={{ fontFamily: "sans-serif", fontSize: 18, color: "GrayText" }}>
                            {AppConstants.bestRegards}
                        </div>
                        <div style={{ fontFamily: "sans-serif", fontSize: 16, color: "GrayText"  }}>
                            {AppConstants.WarehouseManager}
                        </div>
                        <div style={{ fontFamily: "sans-serif", fontSize: 16 ,  color: "GrayText"}}>
                            {/* {getSendSanInfo?.lineitems[0]?.WarehouseName}
                            <span>, {getSendSanInfo?.lineitems[0]?.AddressLine1}</span> */}
                            ABCD-123456, abcd
                        </div>
                        <div style={{ fontFamily: "sans-serif",  color: "GrayText", fontSize: 16 }}>
                            {/* {getSendSanInfo?.lineitems[0]?.City}
                            <span>-{getSendSanInfo?.lineitems[0]?.PinCode}</span>, 
                            <span>{getSendSanInfo?.lineitems[0]?.State}</span> */}
                            chennai-654321,abcd
                        </div>
                    </div>
                </div>
            )
        }
        catch (ex) {
            console.log("error");
        }
    }

    const contentView = () => {
        return (
            <div className='main-container'>
                {ContentFunc()}
                {VanButtons()}
                <SpinLoader loading={onLoad} />
            </div>
        )
    }

    return (
        <div
            style={{ background: "white", paddingTop: 10, paddingRight: 10, paddingBottom: 0, paddingLeft: 10, borderRadius: 6 }}
        >
            {contentView()}
        </div>
    )
}

export default GrnEmailView;