import { CloseOutlined, EditOutlined, FilterFilled, MoreOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Dropdown, Form, Input, Layout, Menu, message, Radio, Row, Select, } from 'antd';
import form from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import AppReferences from '../../Globals/AppReferences';
import { createClientAction, editClientAction, getClientAction, getClientDetailsAction, getOnboardMenuAction, saveContractData, startOnBoardingAction } from '../../Store/Actions/ProcessWMSAction';

import { getUserInfo, getWarehouseInfo, setDashboardMenuId } from '../../Utils/SessionStorage';
import SpinLoader from '../SpinLoader';

const suffix = (
    <SearchOutlined
        style={{
            fontSize: 20,
            color: 'black',
            marginRight: 10
        }}
    />
);

const { Option } = Select;

function ClientScreen() {

    const [form] = Form.useForm();
    const [open, setOpen] = useState<any>();
    const [value, setValue] = useState()
    const [dataValue, setDataValue] = useState(false);
    const [viewProfile, setViewProfile] = useState(false);
    const getUserData = getUserInfo();
    const whData = getWarehouseInfo();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [clientInfo, setClientInfo] = useState<any>({});
    const [onload, setOnlaod] = useState({
        createOnload: false,
        contractOnload: false,
        editOnload: false,
        startOnBoardingOnLoad: false
    });
    const [clientMenu,stageDetails, setClientMenu, setMenuId,getDashboardMenu , warehouseDetails,setOnload]: any = useOutletContext();
    const reducerState = useSelector((state: any) => state.ProcessReducerState);
    const { createContractonload, clientDataInfo ,createClientInfo} = reducerState;

    const getClient = () => {
        let payload = {
            "opnfor": "100001",
            "activity": "A-09",
            "fieldArray": getUserInfo()?.CorporateID
        }
        dispatch(getClientDetailsAction(payload))
        
    }

    useEffect(() => {
        getClient()
    }, [])

    const getDashboardmenu = () => {
        let payload = {
            opnfor: AppReferences.opnfor.onboard,
            activity: AppReferences.activities.a06,
            transaction: getUserInfo()?.CorporateID,
            user: getUserInfo()?.UserName,
        }
        dispatch(getOnboardMenuAction(payload))
        setOnload({ ...onload, menuOnload: true })
    }

    useEffect(() => {
        if (onload.createOnload && !reducerState.onload) {
            if(createClientInfo?.msg === "Client created successfully"){
                message.success("Client created successfully")
                contractAdd();
                getClient();
                getDashboardmenu()
            }else{
                message.error(createClientInfo?.msg )
            }
            setOnlaod({ ...onload, createOnload: false });
            closeDrawer();
        }
    }, [onload.createOnload, reducerState.onload]);

    useEffect(() => {
        if (onload.editOnload && !reducerState.onload) {
            getClient()
            setOnlaod({ ...onload, editOnload: false });
            closeDrawer();
        }
    }, [onload.editOnload, reducerState.onload]);

    const contractAdd = () => {
        const startDate = moment().format("YYYY-MM-DD HH:mm:ss");
        const endDate = moment(startDate).add(1, 'year').format("YYYY-MM-DD HH:mm:ss");
        let user = getUserInfo();
        const headers = {
            activity: 'A-CC',
            fieldarray: `'${clientInfo?.clientId}',${102},'${startDate}' ,'${endDate}','${user?.UserName}',now(),'${whData?.id}'`,
            tableno: '77',
            'Content-Type': 'application/json',
            opnfor: 'C00555',
            user: "Akshya"
        }
        dispatch(saveContractData(headers))
        setOnlaod({ ...onload, contractOnload: true })
    }

    const createClient = () => {
        let findData = clientDataInfo?.lineitems.find((item: any) => item?.clientID === clientInfo?.clientId);
        console.log("findData", findData);
        if (findData) {
            message.error(" Client id already exists")
        } else {
            let payload = {
                opnfor: 4,
                tableno: 26,
                where: `CorporateID,ID,Name,CustCode,CompanyTypeID,GSTNumber,Website,Address,City,State,PostalCode,BillTo,IsPrepaid,SanDamagedApprovalRequired,ClientActivityMasterRecordNo,MinimumPieceQty,ReplenishTriggerQty,DedicatedStorage`,
                fieldarray: `'${getUserInfo()?.CorporateID}','${clientInfo?.clientId}' ,'${clientInfo?.clientName}','${clientInfo?.clientCode}','${clientInfo?.companyType}','${clientInfo?.gstNumber}','${clientInfo?.website}','${clientInfo?.address}','${clientInfo?.city}','${clientInfo?.state}','${clientInfo?.postalCode}','${clientInfo?.billTo}','${clientInfo?.prepaid}','${clientInfo?.dmgApprovalRqd}','${clientInfo?.activity}','${clientInfo?.minPeiceQty}','${clientInfo?.repTriQty}','${clientInfo?.dedicatedStg}'`
            }
            dispatch(createClientAction(payload));
            setOnlaod({ ...onload, createOnload: true })
        }

    }

    const EditClient = () => {
        let payload = {
            "opnfor": 3,
            "tableno": 26,
            "where": `ID= '${clientInfo?.clientId}'`,
            "fieldarray": ` Name='${clientInfo?.clientName}',CustCode='${clientInfo?.clientCode}',CompanyTypeID= '${clientInfo?.companyType}',GSTNumber='${clientInfo?.gstNumber}',Website='${clientInfo?.website}',Address='${clientInfo?.address}',City='${clientInfo?.city}',State='${clientInfo?.state}',PostalCode='${clientInfo?.postalCode}',BillTo='${clientInfo?.billTo}',IsPrepaid='${clientInfo?.prepaid}',SanDamagedApprovalRequired='${clientInfo?.dmgApprovalRqd}',ClientActivityMasterRecordNo='${clientInfo?.activity}',MinimumPieceQty='${clientInfo?.minPeiceQty}',ReplenishTriggerQty='${clientInfo?.repTriQty}',DedicatedStorage='${clientInfo?.dedicatedStg}',ModifiedBy='${getUserInfo()?.UserName}',ModifiedDate=now()`

        }
        dispatch(editClientAction(payload))
        setOnlaod({ ...onload, editOnload: true })
    }

    const drawerViewOpen = () => {
        setDataValue(true)
        setOpen({ ...open, isOPen: true, addToExist: 'No' });
    }



    // const menu = () => {
    //     return (
    //         <Menu
    //             className="booth-representative-menu-view"
    //         >
    //             <Menu.Item className="booth-representative-menu-view-item" key={1}>
    //                 <div onClick={() => setOpen(true)}>{"Edit"}</div>
    //             </Menu.Item>
    //             <Menu.Item className="booth-representative-menu-view-item" key={1}>
    //                 <div>{"Delete"}</div>
    //             </Menu.Item>
    //         </Menu>
    //     )
    // }

    const openEditDrawerFields = (item: any) => {
        setOpen({ ...open, isOPen: true, addToExist: 'Yes' });
        form.setFieldsValue({
            ['clientId']: item?.clientID,
            ['clientName']: item?.clientName,
            ['companyType']: item?.ClientCompanyType == "Primary" ? 1 : 2,
            ['gstinNumber']: item?.GSTNumber,
            ['website']: item?.Website,
            ['address']: item?.Address,
            ['city']: item?.City,
            ['dedicatedStorage']: item?.DedicatedStorage == "Yes" ? 'Y' : 'N',
            ['replenishTriggerQuantity']: item?.ReplenishTriggerQty,
            ['minimumPieceQuantity']: item?.MinimumPieceQty,
            ['activity']: item?.Activity == "Piece" ? 1 : item?.Activity == "Piece & Box" ? 3 : 2,
            ['sanDamagedApprovalRequired']: item?.SanDamagedApprovalRequired == "Yes" ? 'Y' : 'N',
            ['isPrepaid']: item?.isPrepaid == "Yes" ? 'Y' : 'N',
            ['billto']: item?.billto == "self" ? 'S' : 'P',
            ['postalCode']: item?.postalCode,
            ['state']: item?.state
        });
        setClientInfo({
            ...clientInfo,
            clientId: item?.clientID,
            clientName: item?.clientName,
            companyType: item?.ClientCompanyType == "Primary" ? 1 : 2,
            gstNumber: item?.GSTNumber,
            website: item?.Website,
            address: item?.Address,
            city: item?.City,
            postalCode: item?.postalCode,
            state: item?.state,
            billTo: item?.billto == "self" ? 'S' : 'P',
            prepaid: item?.isPrepaid == "Yes" ? 'Y' : 'N',
            dmgApprovalRqd: item?.SanDamagedApprovalRequired == "Yes" ? 'Y' : 'N',
            activity: item?.Activity == "Piece" ? 1 : item?.Activity == "Piece & Box" ? 3 : 2,
            minPeiceQty: item?.MinimumPieceQty,
            repTriQty: item?.ReplenishTriggerQty,
            dedicatedStg: item?.DedicatedStorage == "Yes" ? 'Y' : 'N'
        });
    }

    useEffect(() => {
        if (onload?.startOnBoardingOnLoad && !reducerState?.onBoardingLoad) {
            getClient()
            setOnlaod({ ...onload, startOnBoardingOnLoad: false })
        }

    }, [onload?.startOnBoardingOnLoad, reducerState?.onBoardingLoad])

    const startOnBoarding = (ContractID: any, clientId: any) => {
        let payload = {
            "opnfor": "C00555",
            "activity": "A-START-ON-BOARDING",
            "transaction": ContractID,
            "user": getUserInfo()?.UserName,
            "customer": clientId,
            "fieldarray": getUserInfo()?.CorporateID

        }
        dispatch(startOnBoardingAction(payload))
        setOnlaod({ ...onload, startOnBoardingOnLoad: true })
    }

    const setClientDetails = (value: any, key: any) => {
        setClientInfo({ ...clientInfo, [key]: value })
    }

    const closeDrawer = () => {
        setOpen({ ...open, isOPen: false, addToExist: 'No' });
        setClientInfo(null);
        form.resetFields();
        setDataValue(false);

    }

    const formView = () => {
        try {
            return (
                <>
                    <div className='mt-10'>
                        {open?.addToExist === "Yes" ?
                            <>
                                <div style={{ fontFamily: 'RedHat-Bold', marginTop: 10 }}>Client ID</div>
                                <div className='showDate' style={{ marginTop: 10 }}>
                                    {clientInfo?.clientId}
                                </div>
                            </> :
                            <Form.Item
                                label="Client ID"
                                name="clientId"
                                required
                                rules={[
                                    { required: true, message: 'Please enter Client ID!' },
                                    //{ pattern: /^(?:[1-9]\d*|0)$/, message: "Please enter valid Client ID!" },
                                    { pattern: /^[a-zA-Z0-9]+$/, message: "Space or No Special Character allowed" },
                                    {
                                        validator(rule, value, callback) {
                                            if (value && value.length < 3) {
                                                return Promise.reject("Enter minimum 3 character")
                                            } else if (value && value[0] == 0) {
                                                return Promise.reject("Please enter valid Client ID!")
                                            } else {
                                                return Promise.resolve()
                                            }
                                        }
                                    }
                                ]}
                            >
                                <Input
                                    onChange={(e) => setClientDetails(e.target.value, 'clientId')}
                                    className='input-field'
                                    placeholder="Enter"
                                    maxLength={3}
                                />
                            </Form.Item>
                        }
                    </div>
                    <div className='mt-10'>
                        <Form.Item
                            className='mt-10'
                            label="Client Name"
                            name="clientName"
                            required
                            rules={[{ required: true, message: 'Please enter Client Name!' }]}
                        >
                            <Input
                                onChange={(e) => setClientDetails(e.target.value, 'clientName')}
                                className='input-field'
                                placeholder="Enter"
                            />
                        </Form.Item>
                    </div>
                    <div className='mt-10'>
                        <Form.Item

                            label="Client Company Type"
                            name="companyType"
                            required
                            rules={[{ required: true, message: 'Please selecte ClientCompanyType!' }]}
                        >
                            <Select
                                className='select-field'
                                placeholder="Select"
                                onChange={(e) => setClientDetails(e, 'companyType')}
                            >
                                <Option value={1}>Primary</Option>
                                <Option value={2}>Secondary</Option>
                            </Select>
                        </Form.Item>
                    </div>

                    <div className='mt-10'>
                        <Form.Item
                            className='mt-10'
                            name="gstinNumber"
                            label="GSTIN Number"
                            required
                            rules={[{ required: true, message: 'Please enter GSTIN Number!' }]}
                        >
                            <Input
                                onChange={(e) => setClientDetails(e.target.value, 'gstNumber')}
                                className='input-field'
                                placeholder="Enter"
                            />
                        </Form.Item>

                    </div>
                    <div className='mt-10'>
                        <Form.Item
                            className='mt-10'
                            label="Website"
                            name="website"
                            required
                            rules={[{ required: true, message: 'Please enter Website!' }]}
                        >
                            <Input
                                onChange={(e) => setClientDetails(e.target.value, 'website')}
                                className='input-field'
                                placeholder="Enter"
                            />
                        </Form.Item>
                    </div>

                    <div className='mt-10'>
                        <Form.Item
                            className='mt-10'
                            label="Address"
                            name="address"
                            required
                            rules={[{ required: true, message: 'Please enter Address!' }]}
                        >
                            <TextArea
                                onChange={(e) => setClientDetails(e.target.value, 'address')}
                                className='text-field'
                                placeholder="Enter"
                                maxLength={250}
                            />
                        </Form.Item>
                    </div>

                    <div className='mt-10'>
                        <Form.Item
                            className='mt-10'
                            label="City"
                            name="city"
                            required
                            rules={[{ required: true, message: 'Please enter City!' }]}
                        >
                            <Input
                                onChange={(e) => setClientDetails(e.target.value, 'city')}
                                className='input-field'
                                placeholder="Enter"
                            />
                        </Form.Item>
                    </div>
                    <div className='mt-10'>
                        <Form.Item
                            className='mt-10'
                            label="Postal Code"
                            name="postalCode"
                            required
                            rules={[{ required: true, message: 'Please enter Postal Code!' }]}
                        >
                            <Input
                                onChange={(e) => setClientDetails(e.target.value, 'postalCode')}
                                className='input-field'
                                placeholder="Enter"
                            />
                        </Form.Item>
                    </div>
                    <div className='mt-10'>
                        <Form.Item
                            className='mt-10'
                            label="State"
                            name="state"
                            required
                            rules={[{ required: true, message: 'Please Select State!' }]}
                        >
                            <Input
                                className='input-field'
                                onChange={(e) => setClientDetails(e.target.value, 'state')}
                                placeholder="Enter"
                            />
                        </Form.Item>
                    </div>
                    <div className='mt-10'>
                        <Form.Item
                            className='mt-10'
                            label="Bill to"
                            name="billto"
                            required
                            rules={[{ required: true, message: 'Choose Bill to!' }]}
                        >
                            <Radio.Group
                                onChange={(e) => setClientDetails(e.target.value, 'billTo')}
                                value={value}
                            >
                                <Radio value={'S'} style={{ fontFamily: "RedHat-Bold" }}>Self</Radio>
                                <Radio value={'P'} style={{ fontFamily: "RedHat-Bold" }}>Parent</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className='mt-10'>
                        <Form.Item
                            label="IsPrepaid"
                            name="isPrepaid"
                            required
                            rules={[{ required: true, message: 'Choose IsPrepaid!' }]}
                        >
                            <Radio.Group
                                onChange={(e) => setClientDetails(e.target.value, 'prepaid')}
                                value={value}
                            >
                                <Radio value={'Y'} style={{ fontFamily: "RedHat-Bold" }}>Yes</Radio>
                                <Radio value={"N"} style={{ fontFamily: "RedHat-Bold" }}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className='mt-10'>
                        <Form.Item
                            label="SanDamagedApprovalRequired"
                            name="sanDamagedApprovalRequired"
                            required
                            rules={[{ required: true, message: 'Choose SanDamagedApprovalRequired!' }]}
                        >
                            <Radio.Group
                                onChange={(e) => setClientDetails(e.target.value, 'dmgApprovalRqd')}
                                value={value}
                            >
                                <Radio value={'Y'} style={{ fontFamily: "RedHat-Bold" }}>Yes</Radio>
                                <Radio value={"N"} style={{ fontFamily: "RedHat-Bold" }}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className='mt-10'>
                        <Form.Item
                            label="Activity"
                            name="activity"
                            required
                            rules={[{ required: true, message: 'Please selecte Activity!' }]}
                        >
                            <Select
                                className='select-field'
                                onChange={(e) => setClientDetails(e, 'activity')}
                                placeholder="Select"
                            >
                                <Option value={1}>Piece</Option>
                                <Option value={2}>Box</Option>
                                <Option value={3}>Both</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='mt-10'>
                        <Form.Item
                            label="Minimum Piece Quantity"
                            name="minimumPieceQuantity"
                            required
                            rules={[{ required: true, message: 'Please enter Minimum Piece Quantity!' }]}
                        >
                            <Input
                                onChange={(e) => setClientDetails(e.target.value, 'minPeiceQty')}
                                className='input-field'
                                placeholder="Enter"
                            />
                        </Form.Item>
                    </div>
                    <div className='mt-10'>
                        <Form.Item
                            label="Replenish Trigger Quantity"
                            name="replenishTriggerQuantity"
                            required
                            rules={[{ required: true, message: 'Please enter Replenish Trigger Quantity!' }]}
                        >
                            <Input
                                onChange={(e) => setClientDetails(e.target.value, 'repTriQty')}
                                className='input-field'
                                placeholder="Enter"
                            />
                        </Form.Item>
                    </div>
                    <div className='mt-10'>
                        <Form.Item
                            label="DedicatedStorage"
                            name="dedicatedStorage"
                            required
                            rules={[{ required: true, message: 'Choose DedicatedStorage!' }]}
                        >
                            <Radio.Group
                                onChange={(e) => setClientDetails(e.target.value, 'dedicatedStg')}
                                value={value}
                            >
                                <Radio value={'Y'} style={{ fontFamily: "RedHat-Bold" }}>Yes</Radio>
                                <Radio value={'N'} style={{ fontFamily: "RedHat-Bold" }}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>

                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: 40 }}>
                        <AppButtons
                            onClick={closeDrawer}
                            text={'Cancel'}
                            buttonType={AppConstants.buttonTypes.whiteButton}
                        />
                        <AppButtons
                            htmlType={'submit'}
                            text={dataValue === true ? "Create" : "Update"}
                            buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        />
                    </div>
                </>
            )
        } catch (ex) {
            console.log("Error in formView::" + ex)
        }
    }

    const viewDrawer = () => {
        try {
            return (
                <Drawer
                    title={(
                        <div className="client-drawer" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                            <div className='add-invoice-text'>{open?.addToExist == 'Yes' ? "Edit Client" : "Create Client"}</div>
                            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button className="close-btn" onClick={() => closeDrawer()}>
                                    <CloseOutlined />
                                </Button>
                            </div>
                        </div>
                    )}
                    width={400}
                    open={open?.isOPen}
                    bodyStyle={{ paddingBottom: 80 }}
                >
                    <Form
                        id='form'
                        form={form}
                        autoComplete="off"
                        noValidate
                        layout="vertical"
                        onFinish={open?.addToExist == 'Yes' ? EditClient : createClient}
                    >
                        {formView()}
                    </Form>

                </Drawer>
            )
        } catch (ex) {
            console.log("Error in viewDrawer::" + ex)
        }
    }




    const ContentView = () => {
        try {
            return (
                <div >
                    <div className="content-view-container" style={{ borderLeft: "none" }}>
                        <div className="title-header">
                            <Row>
                                <Col span={10}>
                                    <div style={{ fontSize: 18, fontFamily: 'RedHat-Bold' }}>All Clients</div>
                                </Col>
                                <Col span={14} className='d-flex flex-end'>
                                    <div className="btn-container">
                                        {/* <Input placeholder="Search By Customer Name" className="search-input" prefix={suffix} />
                                        <Button className="green-btn" style={{ background: "#004986", marginLeft: 10 }}>
                                            <FilterFilled />
                                        </Button> */}
                                        <AppButtons
                                            onClick={() => drawerViewOpen()}
                                            text={'Create Client'}
                                            buttonType={AppConstants.buttonTypes.whiteButton}
                                            block={true}
                                        />

                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="view-content" style={{ background: "white" }}>
                            <Row gutter={16}>
                                {(clientDataInfo?.lineitems || []).map((x: any) => {
                                    return (
                                        <Col span={12}>
                                            <div className="content-box content-box-design">
                                                <div className="warehouse-create-container">
                                                    <div >{x?.clientName ? x?.clientName : '-'}</div>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        {x?.status === "Pending" &&
                                                            <AppButtons
                                                                //onClick={() => drawerViewOpen()}
                                                                text={'Save'}
                                                                buttonType={AppConstants.buttonTypes.whiteButton}
                                                                block={true}
                                                            />
                                                        }
                                                        <div className={x?.status === "Pending" ? "view-btn" : "onBoardedBtn"} style={{ padding: 10, }} onClick={() => { x?.status === "Pending" && startOnBoarding(x?.ContractID, x?.clientID) }}>{x?.status === "Pending" ? "Start OnBoarding" : "OnBoarded"}</div>
                                                        {x?.status === "Pending" && <div className='d-flex align-c jc-c eye-btn' style={{ border: "1px soild #004986", cursor: "pointer" }} onClick={() => openEditDrawerFields(x)}>
                                                            <EditOutlined />
                                                        </div>}
                                                    </div>
                                                </div>
                                                <Row gutter={16}>
                                                    <Col span={9}>
                                                        <div className="content">
                                                            <div className="title">GSTIN Number</div>
                                                            <div className="value">{x?.GSTNumber ? x?.GSTNumber : '-'}</div>
                                                        </div>
                                                    </Col>
                                                    <Col span={11}>
                                                        <div className="content">
                                                            <div className="title">Website</div>
                                                            <div className="value" title={x?.Website}>{x?.Website}</div>
                                                        </div>
                                                    </Col>
                                                    <Col span={4}>
                                                        <div className="content">
                                                            <div className="title">Type</div>
                                                            <div className="value">{x?.ClientCompanyType ? x?.ClientCompanyType : '-'}</div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* <Row gutter={16} style={{ display: "flex", alignItems: "center", paddingTop: 10 }}>
                                                    <Col span={20}>
                                                        <div className="contract-container" >
                                                            <div className="title">Contact</div>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <div className="value" style={{ width: 205 }}>Primary <span>- 1</span></div>
                                                                <div className="value" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>Secondary<span>- 3</span></div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col span={4}>
                                                        <div className="content">
                                                            <div className="title">Subsidiary</div>
                                                            <div className="history-text">5</div>
                                                        </div>
                                                    </Col>
                                                </Row> */}
                                                <Row>
                                                    <Col span={21}>
                                                        <div className="content">
                                                            <div className="title">Address</div>
                                                            <div className="value">{x?.Address ? x?.Address : '-'}</div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </div>
                    </div>
                </div>
            )
        } catch (ex) {
            console.log("Error in ContentView::" + ex)
        }
    }
    return (
        <div className="create-wareHouse-Container">
            {ContentView()}
            {open && <div className='view-container'>{viewDrawer()}</div>}
            <SpinLoader loading={onload.createOnload || onload.contractOnload} />
        </div>

    );
}

export default ClientScreen;