import { CaretRightOutlined, CloseOutlined, DeleteFilled, EditFilled, PlusOutlined, SaveFilled, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Drawer, Dropdown, Form, Input, Layout, Menu, Radio, Row, Select, Table, Tooltip, } from 'antd';
import form from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppButtons from '../../../CustomComponent/AppButtons';
import NoData from '../../../CustomComponent/NoData';
import AppConstants from '../../../Globals/AppConstants';
import { AppImages } from '../../../Globals/AppImages';
import { AppModuleIds } from '../../../Globals/AppReferenceIds';
import { isArrayNotEmpty } from '../../../Globals/Helper';
import { addSKUforPoAction, createPoAction, getCustomersAction, getPOAction, getPurchaseOrderAction, getSKUandPOAction, getSkuDescAction, getSkuDetailAction, updatePoAction } from '../../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../../Utils/SessionStorage';
import CardContainer from '../../CardContainer';
// import Calendar from '../../Images/Calendar';

import NavbarLayout from '../../NavbarLayout/NavbarLayout';
import "./CreatePO.scss";
import POModalView from './POModalView';

const { Option } = Select;

const menus: any = [
    {
        id: "0",
        name: 'Saved'
    },
    {
        id: "1",
        name: "Open",
    },
    {
        id: "2",
        name: "Received",
    },
    {
        id: "3",
        name: "Consolidated",
    },
    {
        id: "4",
        name: "Associated",
    },
    {
        id: "5",
        name: "Closed",
    },
]

function CreatePO() {
    const dispatch = useDispatch();
    const { customers,
        customerOnLoad,
        getPO,
        getSKU,
        getSKUandPO,
        getSKUandPOonLoad,
        createPOonLoad,
        getSkuDesc,
        addSKUonLoad,
        getSearchPO,
        updatePoOnLoad,
        searchPOData

    } = useSelector((state: any) => state?.ProcessReducerState)
    const [value, setValue] = useState<any>();
    const [form] = Form.useForm();
    const [formSku] = Form.useForm();
    const [expandRow, setExpandRow] = useState(-1);
    const [openDrawer, setOpenDrawer] = useState<any>();
    const [createPoValues, setCreatePoValues] = useState<any>({ purchase_order: "", ref_no: "", for_client: "", vendor: "", expected_date: "", po_type: "", comment: "" });
    const [addSku, setAddSku] = useState<any>({ sku: "", qty: "", mrp: "" });
    const [openSkuDrawer, setOpenSkuDrawer] = useState<any>();
    const [indicator, setIndicator] = useState<any>();
    const [status, setStatus] = useState<any>();
    const warehouseId = getWareHouseId();
    const user = getUserInfo();
    const [selectClient, setSelectClient] = useState<any>('');
    const [getCustomer, setGetCustomer] = useState<any>(false);
    const [poOnLoad, setPoOnLoad] = useState<any>(false);
    const [headerCustomer, setHeaderCustomer] = useState<any>();
    const [poNumber, setPoNumber] = useState<any>();
    const [skuDetailOnLoad, setSkuDetailOnLoad] = useState<any>(false);
    const [isCreatedPO, setIsCreatedPO] = useState<any>(false);
    const [addSkuOnLoad, setAddSkuOnLoad] = useState<any>(false);
    const [isAddPoCompleted, setIsAddPoCompleted] = useState<any>(false);

    useEffect(() => {
        if (value) {
            let obj = menus?.find((x: any) => x?.id == value);
            setStatus(obj?.name);
        }
    }, [value]);

    useEffect(() => {
        if (searchPOData && openDrawer?.search) {
            setOpenDrawer({ ...openDrawer, searchPopup: true, search: false, soid: "" });
        }
    }, [searchPOData])

    useEffect(() => {
        getCustomersApi();
    }, []);

    useEffect(() => {
        if (getCustomer == true && customerOnLoad == false) {
            setSelectClient(customers?.header[0]?.ID);
        }
    }, [getCustomer, customerOnLoad]);

    useEffect(() => {
        if (status) {
            getPOAPI();
        }
    }, [status])

    useEffect(() => {
        if (selectClient) {
            setValue("0");
            let obj = (customers?.header).find((x: any) => x?.ID == selectClient);
            setHeaderCustomer({ ...obj })
            if (status) {
                getPOAPI();
            }
        }
    }, [selectClient])

    useEffect(() => {
        if (expandRow != -1) {
            getSkuDetailAPi()
        }
    }, [expandRow]);

    useEffect(() => {
        if (openDrawer?.isOPen == true) {
            getSKUandPoTypeApi()
        }
    }, [openDrawer])

    useEffect(() => {
        if (skuDetailOnLoad == true && getSKUandPOonLoad == false) {
            let obj = getSKUandPO?.ClientList.find((x: any) => x?.ID == selectClient);
            form.setFieldsValue({
                ["forclient"]: obj?.Name
            });
            setCreatePoValues({ ...createPoValues, for_client: obj?.ID });
            setPoNumber(getSKUandPO?.POID?.POID);
        }
    }, [skuDetailOnLoad, getSKUandPOonLoad]);

    useEffect(() => {
        if (isCreatedPO == true && createPOonLoad == false) {
            setOpenDrawer({ ...openDrawer, isOPen: false });
            form.resetFields();
            openAddEditDrawer('Add', poNumber);
            getPOAPI();


        }
    }, [isCreatedPO, createPOonLoad])

    useEffect(() => {
        if (openSkuDrawer?.isOPen == true) {
            getSkuDescApi();
        }
    }, [openSkuDrawer]);

    useEffect(() => {
        if (addSkuOnLoad == true && addSKUonLoad == false) {
            setAddSku({ ...addSku, sku: "", qty: "", mrp: "" });
            formSku.setFieldsValue({
                ['sku']: null,
                ["qty"]: "",
                ["mrp"]: ""
            })
            setPoNumber(null);
            getPOAPI();
        }
    }, [addSkuOnLoad, addSKUonLoad]);

    useEffect(() => {
        if (isAddPoCompleted == true && updatePoOnLoad == false) {
            getPOAPI();
        }
    }, [isAddPoCompleted, updatePoOnLoad])

    // useEffect(() => {
    //     if (searchPo) {
    //         getPOAPI()
    //     }
    // }, [searchPo])

    const searchPOAction = (e: any) => {

        let payload = {
            "opnfor": "130000",
            "activity": "A-02",
            "transaction": e?.target?.value,
            "user": getUserInfo()?.UserName,
            "warehouse": getWareHouseId()
        }
        dispatch(getPurchaseOrderAction(payload));
        setOpenDrawer({ ...openDrawer, search: true })
    }

    const getCustomersApi = () => {
        let payload = {
            "activity": "A-V",
            "opnfor": "130000",
            "transaction": 1,
            "user": user?.UserName,
            "warehouse": warehouseId,
            "where": 0
        }
        dispatch(getCustomersAction(payload));
        setGetCustomer(true)
    }

    const getPOAPI = () => {
        // let payload: any;
        // if (searchPo) {
        //     payload = {
        //         activity: "A-02",
        //         opnfor: "130000",
        //         transaction: searchPo,
        //         user: user?.UserName,
        //         warehouse: warehouseId
        //     }
        // }
        // else {
        let payload = {
            "activity": "A-01",
            "opnfor": "130000",
            "transaction": selectClient,
            "user": user?.UserName,
            "warehouse": warehouseId,
            "where": status
        }
        // }
        dispatch(getPOAction(payload));
        setPoOnLoad(true);
    }

    const getSkuDetailAPi = () => {
        let payload = {

            opnfor: "130000",
            activity: "A-04",
            transaction: poNumber,
            user: user?.UserName,
            // activity: "A-04",
            // customer: selectClient,
            // opnfor: "130000",
            // transaction: poNumber,
            // user: user?.UserName,
            // where: status
        }
        console.log("payload", payload);

        dispatch(getSkuDetailAction(payload));

    }

    const getSKUandPoTypeApi = () => {
        let payload = {
            activity: "A-V",
            opnfor: "130001",
            transaction: 1,
            user: user?.UserName,
            warehouse: status
        }
        dispatch(getSKUandPOAction(payload));
        setSkuDetailOnLoad(true);
    }

    const createPoApi = () => {
        let payload = {
            activity: "AX-01",
            customer: createPoValues?.for_client,
            fieldArray: getSKUandPO?.POID?.POID,
            opnfor: "130001",
            tableNo: 14,
            transaction: `'${warehouseId}', '${createPoValues?.for_client}', '${getSKUandPO?.POID?.POID}', '${createPoValues?.ref_no}', '${createPoValues?.vendor}', '${createPoValues?.po_type}', 'Saved', '${createPoValues?.comment}', '${createPoValues?.expected_date}', '${user?.UserName}'`,
            user: user?.UserName,
            warehouse: warehouseId,
            where: `WarehouseID, ClientID, POID, RefernceNo, VendorName, PoTypeID, Status, Comment, ExpectedDate, CreatedBy`
        }
        dispatch(createPoAction(payload));
        setIsCreatedPO(true);
    }

    const getSkuDescApi = () => {
        let payload = {
            customer: selectClient,
            fieldarray: `SELECT ItemId, CONCAT(ItemId, ', ',Description) FROM ItemMaster WHERE ClientID = '${selectClient}';`,
            opnfor: 2,
            tableno: 33,
            warehouse: warehouseId,
            where: 3
        }
        dispatch(getSkuDescAction(payload));
    }

    const addSKUApi = () => {
        let payload = {
            fieldarray: `'${poNumber}', '${addSku?.sku}', '${addSku?.qty}', '${addSku?.mrp}', '${user?.UserName}'`,
            opnfor: 4,
            tableno: 33,
            where: `POID, ItemID, OrderedQuantity, UnitPrice, CreatedBy`
        }
        dispatch(addSKUforPoAction(payload));
        setAddSkuOnLoad(true);
    }

    const completePoApi = () => {
        console.log("poNumber", poNumber);

        let payload = {
            activity: "AX-01",
            fieldArray: 1,
            opnfor: "130002",
            tableNo: 14,
            transaction: `Status = 'Open'`,
            user: user?.UserName,
            warehouse: warehouseId,
            where: `POID = '${poNumber}'`
        }
        dispatch(updatePoAction(payload));
        setIsAddPoCompleted(true);

    }

    const saveAndProcess = (item: any) => {
        setPoNumber(item)
        completePoApi()
    }

    const editSKUApi = () => {

    }

    const statusOnChange = (e: any) => {
        setValue(e.key);
    };

    const customerOnChange = (e: any) => {
        setSelectClient(e.target.value);
    };

    const handleExpand = (data: any, index: any) => {
        setPoNumber(data?.POID)
        setExpandRow(expandRow == index ? null : index);

    }

    const listingView = (data: any, index: any) => {
        try {
            let isExpandopen = (expandRow == index);
            return (
                <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={4}
                            className='d-flex align-c'
                        >
                            <div className="title-container">
                                <div className='d-flex align-c'>
                                    <CaretRightOutlined
                                        onClick={() => handleExpand(data, index)}
                                        className={index === expandRow ? "arrow arrow-down" : "arrow arrow-right"}
                                    />
                                    <div style={{ width: 150, marginLeft: 5 }}>
                                        <div className='text-2'>
                                            {data?.POID}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={3}
                            className='d-flex align-c'
                        >
                            <div className='text-2'> {data?.Type}</div>
                        </Col>
                        <Col span={3}
                            className='d-flex align-c'
                        >
                            <div className='text-2 d-flex align-c'> {moment(data?.CreatedDate).format("DD-MM-YYYY")}</div>
                        </Col>
                        <Col span={(status == "Saved" || status == "Open") ? 3 : 4}
                            className='d-flex align-c'
                        >
                            <div className='text-2 d-flex align-c'> {data?.RefernceNo}</div>
                        </Col>
                        <Col span={(status == "Saved" || status == "Open") ? 4 : 5}
                            className='d-flex align-c'>
                            <div className='text-2 d-flex align-c'> {data?.VendorName}</div>
                        </Col>
                        <Col className='d-flex align-c'
                            span={(status == "Saved" || status == "Open") ? 3 : 4}>
                            <div className='text-2 d-flex align-c'> {moment(data?.ExpectedDate).format("DD-MM-YYYY")}</div>
                        </Col>
                        {(status == "Saved" || status == "Open") && < Col span={4}
                            className='d-flex align-c jc-c'>
                            <div className='app-buttons-container'>
                                <Tooltip title="Add SKU">
                                    <Button
                                        className='solid-linear-green'
                                        onClick={() => openAddEditDrawer("Add", data?.POID)}
                                    >
                                        <PlusOutlined />
                                    </Button>
                                </Tooltip>
                            </div>
                            {(status == "Saved") && <div className={status == "Open" ? "app-buttons-container ml-10" : "app-buttons-container"}
                            >
                                <Tooltip title="Save and process">
                                    <Button
                                        className='solid-linear-green'
                                        onClick={() => saveAndProcess(data?.POID)}
                                    >
                                        <SaveFilled />
                                    </Button>
                                </Tooltip>
                            </div>}
                        </Col>}
                    </Row>
                    {
                        isExpandopen &&
                        <div style={{ marginTop: 10 }}>
                            {tableView()}
                        </div>

                    }
                </div >
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const tableList = (data: any, index: any) => {
        try {
            return (
                <div className="content-container" style={{ marginTop: 10 }}>
                    <Row>
                        <Col className='d-flex align-c' span={status == "Saved" ? 7 : 10}>
                            <div className='text'>{data?.ItemID}</div>
                        </Col>
                        <Col className='d-flex align-c jc-c' span={status == "Saved" ? 7 : 10}>
                            <div className='text-1'>{data?.OrderedQuantity}</div>
                        </Col>
                        <Col className='d-flex align-c jc-c' span={status == "Saved" ? 5 : 4}>
                            <div className='text-1'>{data?.UnitPrice}</div>
                        </Col>
                        {status == "Saved" && <Col className='d-flex align-c jc-c' span={5}>
                            <div style={{ display: "flex", justifyContent: 'center' }}>
                                {/* <div
                                    onClick={() => openAddEditDrawer("Edit", data?.POID)}
                                    className="eye-btn"
                                    style={{ cursor: "pointer" }}>
                                    <EditFilled style={{ color: "white", marginLeft: 5, marginRight: 5 }} />
                                </div > */}
                                <div
                                    className="eye-btn"
                                    style={{ cursor: "pointer", marginLeft: 30 }}>
                                    <DeleteFilled style={{ color: "white", marginLeft: 5, marginRight: 5 }} />
                                </div >
                            </div>
                        </Col>}
                    </Row>
                </div >
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const tableView = () => {
        try {
            return (
                <div style={{ background: "white", padding: 10, borderRadius: 6 }}>
                    <div className='header-part-2'>
                        {tableTitleContainer()}
                    </div>
                    <div >
                        {(getSKU?.lineitems || []).map((data: any, index: any) =>
                            tableList(data, index)
                        )}

                    </div>
                </div>

            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const tableTitleContainer = () => {
        try {
            return (
                <Row>
                    <Col span={status == "Saved" ? 7 : 10}>
                        <div className='redhat-semibold font-16'>SKU - Description</div>
                    </Col>
                    <Col className='d-flex align-c jc-c' span={status == "Saved" ? 7 : 10}>
                        <div className='redhat-semibold font-16'> Quantity</div>
                    </Col>
                    <Col className='d-flex align-c jc-c' span={status == "Saved" ? 5 : 4}>
                        <div className='redhat-semibold font-16'> MRP </div>
                    </Col>
                    {status == "Saved" && <Col className='d-flex align-c' span={4}>
                        <div className='redhat-semibold font-16 ml-50'>Action</div>
                    </Col>}
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }

    const titleContainer = () => {
        try {
            return (
                <Row className='table-header'>
                    <Col span={4}>
                        <div style={{ marginLeft: 25 }} className='text'>PO Number</div>
                    </Col>
                    <Col span={3}>
                        <div className='text'> PO Type</div>
                    </Col>
                    <Col span={3}>
                        <div className='text'> Created Date </div>
                    </Col>
                    <Col span={(status == "Saved" || status == "Open") ? 3 : 4}>
                        <div className='text'> Ref no </div>
                    </Col>
                    <Col span={(status == "Saved" || status == "Open") ? 4 : 5}>
                        <div className='text'> Vendor  </div>
                    </Col>
                    <Col span={(status == "Saved" || status == "Open") ? 3 : 4}>
                        <div className='text'>Expected Date</div>
                    </Col>
                    {(status == "Saved" || status == "Open") && <Col span={4}>
                        <div className='text d-flex jc-c'>Action</div>
                    </Col>}
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }

    const warehouseDetails = () => {
        try {
            return (
                <div className="content-view-container">
                    <div className="title-header d-flex align-c jc-sb">
                        <div className='title '>{headerCustomer?.Name}</div>
                        <div style={{ marginRight: 10 }}>
                            <AppButtons
                                block={true}
                                buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                text={"Create New PO"}
                                onClick={() => setOpenDrawer({ ...openDrawer, isOPen: true })}
                            />
                        </div>
                    </div>
                    <Divider />
                    <div className='p-10'>
                        {titleContainer()}
                    </div>
                    {isArrayNotEmpty(getPO?.lineitems) ?
                        <div style={{ height: "73.3vh", overflow: 'scroll', paddingLeft: 10, paddingRight: 6 }}>
                            {(getPO?.lineitems || []).map((data: any, index: any) =>
                                listingView(data, index)
                            )}

                        </div> :
                        <div className='p-20'>
                            <NoData
                                className='mt-45'
                                description={
                                    <> No Data Available</>
                                }
                            />
                        </div>
                    }
                </div>
            )
        } catch (ex) {
            console.log("Error in warehouseDetails::" + ex)
        }
    }

    const ContentView = () => {
        try {
            return (
                <div className="create-wareHouse-Container">
                    <Row>
                        <Col span={4} style={{ backgroundColor: "white" }} className="client-column">
                            <Row>
                                <Col span={24}>
                                    {isArrayNotEmpty(customers?.header) ?
                                        <div className="select-right-container">
                                            <Input
                                                className='sales-input'
                                                suffix={<SearchOutlined />}
                                                placeholder="Search Po..."
                                                value={openDrawer?.searchPoid}
                                                onChange={(e) => setOpenDrawer({ ...openDrawer, searchPoid: e?.target?.value })}
                                                onBlur={(e: any) => e?.target.value !== "" && searchPOAction(e)}
                                                onPressEnter={(e: any) => e?.target.value !== "" && searchPOAction(e)} />
                                            <div className="content-container-po">
                                                <Radio.Group
                                                    style={{ width: "100%" }}
                                                    buttonStyle="solid"
                                                    onChange={(e: any) => customerOnChange(e)}
                                                    value={selectClient} >
                                                    {(customers?.header || []).map((item: any) => {
                                                        return (
                                                            <Radio.Button value={item?.ID} >
                                                                <div className="card-title-container" >
                                                                    <div className='label'>{item.Name}</div>
                                                                </div>
                                                            </Radio.Button>
                                                        )
                                                    })}
                                                </Radio.Group>
                                            </div>
                                        </div> :
                                        <div className='p-20'>
                                            <NoData
                                                className='mt-45'
                                                description={
                                                    <> No Data</>
                                                }
                                            />
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col span={4}>
                            <div className="radio-container">
                                <div className='left-side-top'>
                                    Total PO
                                </div>
                                <div className='sub-sider-menu'>
                                    <CardContainer
                                        fromOrigin="RTO"
                                        scrollable={true}
                                        menu={menus}
                                        handleMenuSelect={statusOnChange}
                                        value={value}
                                    // load={onload?.statusOnload}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col span={16} style={{ backgroundColor: "white" }}>
                            <div className="select-right-container">
                                {warehouseDetails()}
                            </div>
                        </Col>
                    </Row>
                </div >
            )
        } catch (ex) {
            console.log("Error in ContentView::" + ex)
        }
    }

    const openAddEditDrawer = (item: any, po_id: any) => {
        setPoNumber(po_id);

        if (item == "Add") {
            setOpenSkuDrawer({ ...openSkuDrawer, isOPen: true })
            setIndicator(item);
        }
        // else if (item == "Edit") {
        //     setOpenSkuDrawer({ ...openSkuDrawer, isOPen: true })
        //     setIndicator(item);
        // }
    }

    const closeDrawerFields = () => {
        setOpenDrawer({ ...openDrawer, isOPen: false });
        setCreatePoValues("");
    }

    const closeSkuDrawerFields = () => {
        setOpenSkuDrawer({ ...openSkuDrawer, isOPen: false });
        setCreatePoValues("");
        setAddSku({ ...addSku, sku: "", qty: "", mrp: "" });
        formSku.setFieldsValue({
            ['sku']: null,
            ["qty"]: "",
            ["mrp"]: ""
        });
    }

    const saveCreatedPO = () => {
        createPoApi();
    }

    const saveSKU = () => {
        if (indicator == 'Add') {
            addSKUApi();
        }
        else if (indicator == 'Edit') {
            editSKUApi();
        }
    }

    const drawerView = () => {
        try {
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                Create New PO
                            </div>
                            {/* <Button onClick={() => closeDrawerFields()}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button> */}
                            <img onClick={() => closeDrawerFields()} className="c-p" src={AppImages.closeimg} alt='' />
                        </div>
                    }
                    onClose={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                    open={openDrawer?.isOPen}>
                    <div style={{ paddingLeft: 5, borderRadius: 2 }}>
                        <Form
                            onFinish={saveCreatedPO}
                            form={form}
                        >
                            <div style={{ fontFamily: 'RedHat-Bold' }}>Purchase Order Number</div>
                            <div className='d-flex align-c'
                                style={{ marginTop: 10, width: "100%", height: 35, background: '#F1F1F1', borderRadius: 4, paddingLeft: 10 }}
                            >
                                {getSKUandPO?.POID?.POID}
                            </div>
                            <div style={{ fontFamily: 'RedHat-Bold', marginTop: 20 }}>{"Ref Number (Invoice Number)"}<span style={{ color: 'red', fontSize: 18 }}>*</span></div>
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    name={'refno'}
                                    rules={
                                        [{ required: true, message: "Ref Number is required" }]
                                    }
                                >
                                    <Input
                                        style={{ borderRadius: 4, height: 35 }}
                                        placeholder={"Enter"}
                                        onChange={(e: any) => setCreatePoValues({ ...createPoValues, ref_no: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ fontFamily: 'RedHat-Bold' }}>For Client<span style={{ color: 'red', fontSize: 18 }}>*</span></div>
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    name={'forclient'}
                                    rules={
                                        [{ required: true, message: "Client is required" }]
                                    }
                                >
                                    <Select
                                        onChange={(e: any) => setCreatePoValues({ ...createPoValues, for_client: e })}
                                        value={selectClient}
                                    >
                                        {(getSKUandPO?.ClientList || []).map((item: any) =>
                                            <Option value={item?.ID}>
                                                {item?.Name}
                                            </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div style={{ fontFamily: 'RedHat-Bold' }}>Vendor Name<span style={{ color: 'red', fontSize: 18 }}>*</span></div>
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    name={'vendorname'}
                                    rules={
                                        [{ required: true, message: 'Vendor Name is required' }]
                                    }
                                >
                                    <Input
                                        style={{ borderRadius: 4, height: 35 }}
                                        placeholder={"Enter"}
                                        onChange={(e: any) => setCreatePoValues({ ...createPoValues, vendor: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ fontFamily: 'RedHat-Bold' }}>PO Type<span style={{ color: 'red', fontSize: 18 }}>*</span></div>
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    name={'potype'}
                                    rules={
                                        [{ required: true, message: 'PO Type is required' }]
                                    }
                                >
                                    <Select
                                        placeholder={"Select"}
                                        onChange={(e: any) => setCreatePoValues({ ...createPoValues, po_type: e })}
                                    >
                                        {(getSKUandPO?.POTypes || []).map((item: any) =>
                                            <Option value={item?.TypeID}>{item?.Type}</Option>)}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div style={{ fontFamily: 'RedHat-Bold' }}>Expected date of delivery<span style={{ color: 'red', fontSize: 18 }}>*</span></div>
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    name={'expdate'}
                                    rules={
                                        [{ required: true, message: 'Date is required' }]
                                    }
                                >
                                    <DatePicker format={'DD-MM-YYYY'}
                                        disabledDate={(e) => e < moment().subtract(1, 'd')}
                                        onChange={(e: any) => setCreatePoValues({ ...createPoValues, expected_date: moment(e)?.format("YYYY-MM-DD") })}
                                        style={{ width: '100%', height: 35, borderRadius: 4 }} />
                                </Form.Item>
                            </div>
                            <div style={{ fontFamily: 'RedHat-Bold', marginTop: 20 }}>Comment<span style={{ color: 'red', fontSize: 18 }}>*</span></div>
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    name={'commet'}
                                    rules={
                                        [{ required: true, message: 'Commect is required' }]
                                    }
                                >
                                    <TextArea
                                         maxLength={250}
                                        style={{ borderRadius: 4 }}
                                        placeholder={"Comment here..."}
                                        onChange={(e: any) => setCreatePoValues({ ...createPoValues, comment: e.target.value })}

                                    />
                                </Form.Item>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, marginRight: 10 }}>
                                <Button className='cancel-btn'
                                    onClick={() => closeDrawerFields()}
                                >
                                    Cancel
                                </Button>
                                <AppButtons
                                    block={true}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                    text={"Save"}
                                    htmlType={'submit'}
                                >
                                </AppButtons>
                            </div>
                        </Form>
                    </div>
                </Drawer >
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }
    }

    const AddSkuDrawer = () => {
        try {
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                {indicator == 'Add' ? "Add SKU" : "Edit SKU"}
                            </div>
                            {/* <Button onClick={() => closeSkuDrawerFields()}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button> */}
                            <img onClick={() => closeSkuDrawerFields()} className="c-p" src={AppImages.closeimg} alt='' />
                        </div>
                    }
                    onClose={() => closeSkuDrawerFields()}
                    open={openSkuDrawer?.isOPen}>
                    <div style={{ paddingLeft: 5, borderRadius: 2 }}>
                        <Form
                            onFinish={saveSKU}
                            form={formSku}
                        >
                            <div style={{ fontFamily: 'RedHat-Bold' }}>SKU<span style={{ color: "red", fontSize: 18 }}>*</span></div>
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    name='sku'
                                    rules={
                                        [{ required: true, message: "SKU is required" }]
                                    }
                                >
                                    <Select
                                        onChange={(e: any) => setAddSku({ ...addSku, sku: e })}
                                        placeholder={"Select"}
                                        showSearch={true}
                                        optionFilterProp="children"
                                    >
                                        {(getSkuDesc?.rData || []).map((item: any) => {
                                            let desc = item?.c?.split("|")
                                            return (
                                                <Option value={desc[0]}>{desc[1]}</Option>
                                            )
                                        })}
                                    </Select>

                                </Form.Item>
                            </div>
                            <div style={{ fontFamily: 'RedHat-Bold' }}>Quantity<span style={{ color: "red", fontSize: 18 }}>*</span></div>
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    name={'qty'}
                                    rules={
                                        [{ required: true, message: "Quantity is required" }]
                                    }
                                >
                                    <Input
                                        style={{ borderRadius: 4, height: 35 }}
                                        placeholder={"Enter"}
                                        onChange={(e: any) => setAddSku({ ...addSku, qty: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ fontFamily: 'RedHat-Bold' }}>MRP<span style={{ color: "red", fontSize: 18 }}>*</span></div>
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    name={'mrp'}
                                    rules={
                                        [{ required: true, message: "MRP is required" }]
                                    }
                                >
                                    <Input
                                        style={{ borderRadius: 4, height: 35 }}
                                        placeholder={"Enter"}
                                        onChange={(e: any) => setAddSku({ ...addSku, mrp: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, marginRight: 10 }}>
                                <Button className='cancel-btn'
                                    onClick={() => closeSkuDrawerFields()}
                                >
                                    Cancel
                                </Button>
                                <AppButtons
                                    block={true}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                    text={"Save"}
                                    htmlType={'submit'}
                                >
                                </AppButtons>
                            </div>
                        </Form>
                    </div >
                </Drawer >
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }
    }

    return (
        <Layout>
            <Layout>
                <NavbarLayout
                    titleContent="main-head"
                    process={customers?.title}
                    initial={AppModuleIds.main}
                    selectedMain={'5'}
                />
            </Layout>
            <Content>
                <div className="create-wareHouse-Container" style={{ background: 'white' }}>
                    {ContentView()}
                </div>
                <POModalView
                    poid={openDrawer}
                    openSearch={openDrawer?.searchPopup}
                    close={() => setOpenDrawer({ ...openDrawer, searchPopup: false, isCancelSo: false, searchPoid: "" })}
                    data={searchPOData}
                />
            </Content>

            <div>
                {drawerView()}
            </div>
            <div>
                {AddSkuDrawer()}
            </div>
        </Layout>
    );
}

export default CreatePO;