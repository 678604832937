import { CaretDownFilled, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Drawer, Form, Input, message, Modal, Row, Select, Table } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import AppReferences from '../../Globals/AppReferences';
import { generateGuId, isArrayEmpty, isArrayNotEmpty, pipefy } from '../../Globals/Helper';
import CloseCartonIcon from '../../Images/CloseCartonIcon';
import DownloadIcon from '../../Images/DownloadIcon';
import { addSKUBatchDetailsAction, clearReducerAction, editSKUBatchDetailsAction } from '../../Store/Actions/ProcessWMSAction';
import { getClientId, getUserInfo } from '../../Utils/SessionStorage';
import SpinLoader from '../SpinLoader';

function CreatSku(props: any) {
    const {
        openDrawer,
        onload,
        setOnload,
        setOpenModal,
        openImageviewr,
        recordNo,
        openModal,
        setOpenDrawer,
        editOnLoad,
        setEditOnload,
        getSKUList,
    } = props;
    const reducerState = useSelector((state: any) => state.ProcessReducerState);
    const {
        formMeta,
        getSkuDesc,
        onBoardingEditData,
        onBoardingEditOnLoad,
        skuBatchDetails,
        editOnload,
        inventorySkuData,
        skuBatchOnLoad
    } = reducerState;
    const [lastFileUuid, setLastFileUuid] = useState('');
    const client = getClientId();
    let user = getUserInfo();
    const dispatch = useDispatch();
    const [eanCode, setEanCodeArr] = useState<any>({
        code: '',
        peices: '',
        eanArr: []
    });
    const [files, setfiles] = useState<any>([]);
    const [addSkuDetails, setAddSkuDetails] = useState<any>({
        // childSKUArr: [],
        skuCode: "",
        skuDescription: "",
        eanCode: "",
        category: "",
        length: "",
        breadth: "",
        height: "",
        weight: "",
        pickingCode: "",
        shelfLife: "",
        flipkart: "",
        amazon: "",
        isShelfRequired: false
    });

    const [childSkuArr, setChildSkuArr] = useState<any>([]);
    const [uploadView, setUploadView] = useState(false);
    const [load, setLoad] = useState(false);
    const [form]: any = Form.useForm();


    useEffect(() => {
        if (editOnLoad == true && onBoardingEditOnLoad == false) {
            setEditData();
            setEditOnload(false);
        }
    }, [editOnLoad, onBoardingEditOnLoad]);

    useEffect(() => {
        if (onload.addOnload === true && skuBatchOnLoad === false) {
            closeDrawer();
            if (skuBatchDetails?.msg == 'SKU added successfully') {
                setOnload({ ...onload, addOnload: false });
                getSKUList();
                setAddSkuDetails(null);
                setLastFileUuid("");
                if (files?.length > 0) {
                    uploadToAWS();
                }
                
            }
        }
    }, [onload.addOnload, skuBatchOnLoad]);

    useEffect(() => {
        if (editOnload == false && onload?.editOnload == true) {
            getSKUList();
            if (files?.length > 0) {
                uploadToAWS();
            }
            setLastFileUuid("")
            closeDrawer();
            setOnload({ ...onload, editOnload: false })
        }
    }, [editOnload, onload?.editOnload])

    const validateEanbom = () => {
        setOnload({
            ...onload,
            isEanAdded: true,
            validateBom: isArrayNotEmpty(childSkuArr) ? false : true,
            validateEan: isArrayNotEmpty(eanCode.eanArr) ? false : true
        });
    }

    const setEditData = () => {
        try {
            let editData = onBoardingEditData?.lineitems[0]
            if (onBoardingEditData?.lineitems.length != 0) {
                form.setFieldsValue({
                    ['skucode']: onBoardingEditData?.lineitems[0].ItemId ? onBoardingEditData?.lineitems[0].ItemId : "",
                    ['skuDescription']: onBoardingEditData?.lineitems[0].Description ? onBoardingEditData?.lineitems[0].Description : "",
                    ['length']: onBoardingEditData?.lineitems[0].L ? onBoardingEditData?.lineitems[0].L : "",
                    ['weight']: onBoardingEditData?.lineitems[0].Weight ? onBoardingEditData?.lineitems[0].Weight : "",
                    ['height']: onBoardingEditData?.lineitems[0].H ? onBoardingEditData?.lineitems[0].H : "",
                    ['breadth']: onBoardingEditData?.lineitems[0].B ? onBoardingEditData?.lineitems[0].B : "",
                    ['shelfLife']: onBoardingEditData?.lineitems[0].ShelfLife != '-' ? onBoardingEditData?.lineitems[0].ShelfLife : '',
                    ['eanCode']: onBoardingEditData?.lineitems[0].EanCode ? onBoardingEditData?.lineitems[0].EanCode : "",
                    ['category']: onBoardingEditData?.lineitems[0].CategoryID ? onBoardingEditData?.lineitems[0].CategoryID : "",
                    ['flipkart']: onBoardingEditData?.lineitems[0].FlipkartID ? onBoardingEditData?.lineitems[0].FlipkartID : "",
                    ['amazon']: onBoardingEditData?.lineitems[0].AmazonID ? onBoardingEditData?.lineitems[0].AmazonID : "",
                    ['pickingCode']: onBoardingEditData?.lineitems[0].PickingCode ? onBoardingEditData?.lineitems[0].PickingCode : ""
                });
                setOpenModal({ ...openModal, sku: editData })
                setAddSkuDetails({
                    ...addSkuDetails,
                    skuCode: editData?.ItemId,
                    skuDescription: editData?.Description,
                    eanCode: editData?.EanCode,
                    category: editData?.CategoryID,
                    length: editData?.L,
                    breadth: editData?.B,
                    height: editData?.H,
                    weight: editData?.Weight,
                    pickingCode: editData?.PickingCode,
                    shelfLife: editData?.ShelfLife != '-' ? onBoardingEditData?.lineitems[0].ShelfLife : '',
                    flipkart: editData?.FlipkartID,
                    amazon: editData?.AmazonID,
                    isBOM: editData?.Lines?.length != 0,
                    isShelfRequired: editData?.ShelfLife != '-',
                    isEdit: isArrayNotEmpty(onBoardingEditData?.media?.objects),
                    // isStickeringRequired: false
                });
                let media = onBoardingEditData?.media?.objects;
                let lastUuid = isArrayNotEmpty(media) && media[media?.length - 1]?.split('/');
                lastUuid && setLastFileUuid(lastUuid?.pop());
                isArrayNotEmpty(media) && setUploadView(true);
                isArrayEmpty(media) && setLastFileUuid('-');
                let arr: any = [];
                let eanArr: any = [];
                {
                    (onBoardingEditData?.lineitems[0]?.Lines).map((x: any) =>
                        arr.push(`${x?.ChildItemID + " : " + x?.PiecesRequired}`)
                    )
                };
                setChildSkuArr(arr);
                {
                    (onBoardingEditData?.EANCodes)?.map((x: any) =>
                        eanArr.push(`${x?.EANCode + " : " + x?.PiecesPerBox}`)
                    )
                };
                setEanCodeArr({ ...eanCode, eanArr: eanArr })
                setOpenDrawer({ ...openDrawer, addSKU: true, editSkuData: "YES" });
            } else {
                message.error("Empty Arr")
            }
        } catch (error) {
            console.log("Error in setEditdata::", error)
        }
    }


    const uploadToAWS = async () => {
        setLoad(true)
        // let fileType = file?.type?.split('/');
        let fileCount = 0;
        files?.map(async (originFileObj: any, index: any) => {
            let randomGuid = '';
            let extension = originFileObj.name.substring(originFileObj.name.lastIndexOf('.'));
            if (files?.length - 1 == index) {
                randomGuid = lastFileUuid; //this condition is to send last file uuid in transaction, last files uuid has already set
            }
            else {
                randomGuid = generateGuId() + extension;
            }
            let path = `${inventorySkuData?.customerCode.toLowerCase()}/${addSkuDetails?.skuCode}/${randomGuid}`
            let Params = {
                contentType: originFileObj?.type,
                bucket: process.env.REACT_APP_BUCKET_NAME,
                resumable: true,
                completeCallback: () => {
                    if (fileCount + 1 == files.length) {
                        message.success(`Attachment Uploaded Successfully`);
                        setfiles([]);
                        setLoad(false);
                        window.location.reload()
                    }
                    else {
                        fileCount += 1;
                    }
                },
                //Key: `A-${whareHouseId}-${header?.stageNo}-${year}-${month}-${date}-${data?.TxnNo}-${randomGuid}${extension}`,
            };
            await Storage.put(path, originFileObj, Params);
        })
    }


    const addSku = () => {
        try {
            let firstval = eanCode?.eanArr[0]?.split(':');
            let eanValue = `${firstval[0]}|${firstval[1]}`
            eanCode?.eanArr?.slice(1, eanCode?.eanArr?.length).map((x: any) => {
                let value = x?.split(':');
                eanValue += `,${value[0]}|${value[1]}`
            })
            let stickering = addSkuDetails?.isStickeringRequired ? 'Y' : 'N';
            let extension = isArrayNotEmpty(files) && files[files.length - 1]?.type?.split('/')[1]
            let lastUuid = isArrayNotEmpty(files) ? generateGuId() + "." + extension : '-';
            setLastFileUuid(lastUuid);
            let payload = {
                opnfor: AppReferences.opnfor.itemMaster,
                activity: AppReferences.activities.a03,
                transaction: `${addSkuDetails?.skuCode}|${addSkuDetails?.skuDescription}|0|${addSkuDetails?.category}|${addSkuDetails?.length}|${addSkuDetails?.breadth}|${addSkuDetails?.height}|${addSkuDetails?.weight}|${addSkuDetails?.pickingCode}|${addSkuDetails?.shelfLife}|${addSkuDetails?.flipkart}|${addSkuDetails?.amazon}|${stickering}|${lastUuid}`,
                where: pipefy(childSkuArr),
                customer: client?.Id,
                user: user?.UserName,
                tableNo: eanValue,
                fieldArray: getUserInfo()?.CorporateID,
            }
            dispatch(addSKUBatchDetailsAction(payload));
            setOnload({ ...onload, addOnload: true });
        } catch (error) {
            console.log("Error in addSku::", error)
        }
    }

    const editSKu = () => {
        let firstval = eanCode?.eanArr[0]?.split(':');
        let eanValue = `${firstval[0]}|${firstval[1]}`
        eanCode?.eanArr?.slice(1, eanCode?.eanArr?.length).map((x: any) => {
            let value = x?.split(':');
            eanValue += `,${value[0]}|${value[1]}`
        });
        let extension = isArrayNotEmpty(files) && files[files.length - 1]?.type?.split('/')[1]
        let lastUuid = isArrayNotEmpty(files) ? generateGuId() + "." + extension : lastFileUuid;
        setLastFileUuid(lastUuid)
        let stickering = addSkuDetails?.isStickeringRequired ? 'Y' : 'N';
        // let lastUuid = lastFileUuid ? lastFileUuid : '-'
        let payload = {
            opnfor: AppReferences.opnfor.itemMaster,
            activity: AppReferences.activities.a04,
            transaction: `${addSkuDetails?.skuCode ? addSkuDetails?.skuCode : onBoardingEditData?.lineitems[0].ItemId}|${addSkuDetails?.skuDescription ? addSkuDetails?.skuDescription : onBoardingEditData?.lineitems[0]?.Description}|0|${addSkuDetails?.category ? addSkuDetails?.category : onBoardingEditData?.lineitems[0]?.CategoryID}|${addSkuDetails?.length ? addSkuDetails?.length : onBoardingEditData?.lineitems[0]?.L}|${addSkuDetails?.breadth ? addSkuDetails?.breadth : onBoardingEditData?.lineitems[0]?.B}|${addSkuDetails?.height ? addSkuDetails?.height : onBoardingEditData?.lineitems[0]?.H}|${addSkuDetails?.weight ? addSkuDetails?.weight : onBoardingEditData?.lineitems[0].Weight}|${addSkuDetails?.pickingCode ? addSkuDetails?.pickingCode : onBoardingEditData?.lineitems[0].PickingCode}|${addSkuDetails?.shelfLife ? addSkuDetails?.shelfLife : onBoardingEditData?.lineitems[0].ShelfLife}|${addSkuDetails?.flipkart ? addSkuDetails?.flipkart : onBoardingEditData?.lineitems[0].FlipkartID}|${addSkuDetails?.amazon ? addSkuDetails?.amazon : onBoardingEditData?.lineitems[0].AmazonID}|${stickering}|${lastUuid}`,
            where: pipefy(childSkuArr),
            fieldArray: onBoardingEditData?.lineitems[0].ItemId,
            tableno: recordNo,
            customer: client?.Id,
            user: user?.UserName,
            warehouse: eanValue
        }
        dispatch(editSKUBatchDetailsAction(payload));
        setOnload({ ...onload, editOnload: true })
    }

    const setAddSkuData = (value: any, key: any) => {
        setAddSkuDetails({ ...addSkuDetails, [key]: value })
    }

    const closeDragger = (e: any) => {
        e.stopPropagation();
        setUploadView(false)
    }

    const closeDrawer = () => {
        setOpenDrawer({ ...openDrawer, addSKU: false, editSkuData: 'No' });
        setAddSkuDetails({ ...addSkuDetails, isShelfRequired: false })
        form.resetFields();
        setChildSkuArr([]);
        setEanCodeArr({
            code: '',
            peices: '',
            eanArr: []
        });
        setfiles([])
        setOnload({ ...onload, validateBom: true, validateEan: true })
        setUploadView(false);
        dispatch(clearReducerAction('onBoardingEditData'))

    }

    const setBomData = (data: any, key: any) => {
        if (key == 'add') {
            let exist = childSkuArr?.find((x: any) =>
                x?.split(':')[0] == addSkuDetails?.childSKU
            )
            if (!exist) {
                let value = `${addSkuDetails?.childSKU}:${addSkuDetails?.piecesRqd}`;
                setAddSkuDetails({
                    ...addSkuDetails,
                    // childSKUArr: [...addSkuDetails.childSKUArr, value],
                    childSKU: '',
                    piecesRqd: ''
                });
                setChildSkuArr([...childSkuArr, value])
            }
        }
        else {
            setChildSkuArr(childSkuArr?.filter((x: any) => x != data))
            // setAddSkuDetails({
            //     ...addSkuDetails,
            //     childSKUArr: addSkuDetails?.childSKUArr?.filter((x: any) => x != data),
            // });
        }

    }

    const setEanCodeData = (data: any, key: any) => {
        if (key == 'add') {
            let exist = eanCode?.eanArr?.find((x: any) =>
                x?.split(':')[0] == eanCode?.code
            )
            if (!exist) {
                let value = `${eanCode?.code}:${eanCode?.peices}`;
                setEanCodeArr({
                    ...eanCode,
                    code: '',
                    peices: '',
                    eanArr: [...eanCode.eanArr, value]
                });
            }
        }
        else {
            setEanCodeArr({
                ...eanCode,
                code: '',
                peices: '',
                eanArr: (eanCode.eanArr?.filter((x: any) => x != data))
            });
        }

    }


    const addChildSku = () => {
        form.validateFields([`childSKU`, 'piecesRequired'], (err: any, values: any) => {
        }).then(() => {
            setBomData("", 'add')
            form.setFieldsValue({
                ['childSKU']: " ",
                ['piecesRequired']: ''
            });
        }).catch(() => {

        });
    }

    const addEanCode = () => {
        setOnload({ ...onload, isEanAdded: false, validateEan: true });
        form.validateFields([`eanCode`, 'pieceperbox'], (err: any, values: any) => {
        }).then(() => {
            setEanCodeData("", 'add')
            form.setFieldsValue({
                ['eanCode']: "",
                ['pieceperbox']: ''
            });
        }).catch(() => {

        });
    }


    const getAtachments = (file: any) => {
        let fileType = file?.type?.split('/');
        if (fileType[1] == 'jpeg' || fileType[1] == 'png') {
            if (file?.size <= 5000000) {
                setfiles([...files, file]);
            } else {
                message.error("File size should not exceed more than 5MB");
            }
        }
        else {
            message.error("Selected file type is not supported");
        }
    }

    const removeImage = (item: any) => {
        let filesArr = files?.filter((originFileObj: any) => originFileObj?.uid != item?.uid);
        setfiles(filesArr);
    }

    const ImgView = () => {
        return (
            <Row >
                {files?.map((originFileObj: any) => {
                    let url = URL.createObjectURL(originFileObj);
                    return (
                        <Col className='preview-attribute position-relative'>
                            <Button
                                onClick={() => removeImage(originFileObj)}
                                className="close-btn"
                                style={{ position: 'absolute', top: '14px', right: '-15px' }}>
                                <CloseOutlined />
                            </Button>
                            <img src={url} alt='Loading' height={100} width={100} />
                        </Col>
                    )
                })}
                {onBoardingEditData?.media?.objects?.map((url: any, index: any) => {
                    return (
                        <Col className='preview-attribute' style={{ cursor: "pointer" }} onClick={() => openImageviewr(url, index, null)}>
                            {/* <span className='img-Link'> */}
                            <img src={onBoardingEditData?.media?.prefix + url} alt='Loading' height={100} width={100} />
                            {/* </span> */}
                        </Col>
                    )
                })}

            </Row>
        )
    }


    const uploadContainerView = () => {
        try {
            let required = isArrayEmpty(onBoardingEditData?.media?.objects) && isArrayEmpty(files)
            return (
                <>
                    <Form.Item name="attachment" required rules={[{ required: required, message: "Select Attachment" }]}>
                        <Dragger beforeUpload={(e) => { getAtachments(e); return false }} accept='.jpeg, .png'>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <DownloadIcon />
                                <div className='dragger-content ' style={{ marginLeft: 5, textAlign: "left" }}>
                                    <div className='drag-txt '>Drag & Drop files or <span className='browse-txt'>Browse</span></div>
                                    <div className='note-content '>Supported Formates: JPEG,PNG</div>
                                </div>
                            </div>
                            {/* <Button className="close-btn" onClick={(e) => closeDragger(e)}>
                                <CloseOutlined />
                            </Button> */}
                        </Dragger>
                    </Form.Item>
                    <div className='mt-10 mb-10'>{ImgView()}</div>
                </>
            )
        } catch (ex) {
            console.log("Error in uploadContainerView::" + ex)
        }
    }
    const eanTableView = (value: any) => {
        try {
            let splitData = value.split(':')
            return (
                <Row className="content-container1 mt-5">
                    <Col span={8} className="text-1">{splitData[0]}</Col>
                    <Col span={13} className=' d-flex jc-c text-1'>{splitData[1]}</Col>
                    <Col span={2} className=' d-flex jc-c text-1'>
                        <div className='red-box' onClick={() => setEanCodeData(value, 'remove')}><CloseCartonIcon /></div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Error in eanTableView")
        }
    }

    const eanCodeView = () => {
        return (
            <div className='type-container mt-10'>
                <div className='lable'>EAN Code (SKU Packages)</div>
                <Row gutter={16} style={{ background: "white", marginTop: 5, borderRadius: 10, padding: 10 }}>

                    <Col span={12}>
                        <div className='font-16 redhat-bold mt-10 required'>
                            {"Ean Code"}
                        </div>
                        <Form.Item
                            required
                            name='eanCode'
                            rules={[{ required: onload.validateEan, message: 'Enter EAN Code' },
                            { pattern: /^[0-9]*$/, message: "Enter valid EAN Code" },
                            { pattern: /^\S*$/, message: "Space not allowed" },
                            ]}
                        >
                            <Input className='input-field'
                                placeholder='Enter EAN Code'
                                onChange={(e) => setEanCodeArr({ ...eanCode, code: e.target.value })}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <div className='font-16 redhat-bold mt-10 required'>
                            {"Piece per box"}
                        </div>
                        <Form.Item name='pieceperbox' required
                            rules={[
                                { required: onload.validateEan, message: 'Enter Qty' },
                                { pattern: /^[0-9]*$/, message: "Enter valid Qty" },
                            ]}
                        >
                            <Input className='input-field'
                                onChange={(e) => setEanCodeArr({ ...eanCode, peices: e.target.value })}
                                placeholder='Pieces per box'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <div style={{ display: 'flex', alignItems: "center", marginTop: 34 }}>
                            <AppButtons
                                onClick={() => addEanCode()}
                                text={<PlusOutlined />}
                                buttonType={AppConstants.buttonTypes.solidLinearGreen}
                            />
                        </div>
                    </Col>

                    {isArrayNotEmpty(eanCode?.eanArr) &&
                        <Row className='mt-10'  style={{padding: 8}}>
                            <Col span={24}>
                                <Row className='table-header'>
                                    <Col span={8}>EAN Code</Col>
                                    <Col span={13} className=' d-flex jc-c'>Pieces per box</Col>
                                    <Col span={2} className=' d-flex jc-c'>Action</Col>
                                </Row>
                                {(eanCode?.eanArr || []).map((value: any) => {
                                    return (
                                        eanTableView(value)
                                    )

                                })}
                            </Col>
                        </Row>
                    }
                </Row>


            </div>
        )
    }

    const bomTableView = (value: any) => {
        try {
            let splitData = value.split(':')
            return (
                <Row className="content-container1 mt-5">
                    <Col span={8} className="text-1">{splitData[0]}</Col>
                    <Col span={13} className=' d-flex jc-c text-1'>{splitData[1]}</Col>
                    <Col span={2} className=' d-flex jc-c text-1'>
                        <div className='red-box' onClick={() => setBomData(value, 'remove')}><CloseCartonIcon /></div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in bomTableView")
        }
    }

    const bomView = () => {
        return (
            <div className='type-container mt-10'>
                <div className='lable d-flex font-16 redhat-bold mt-10 mb-10'>
                    <Checkbox
                    checked={addSkuDetails?.isBOM}
                    onChange={(e) => setAddSkuDetails({ ...addSkuDetails, isBOM: e?.target?.checked })} />
                    <span className='ml-10'>BOM (Bill of Material)</span>
                </div>
                <Row gutter={16} style={{ background: "white", marginTop: 5, borderRadius: 10, padding: 10 }}>
                    <Col span={11}>
                        <div className='font-16 redhat-bold mt-10 required'>
                            {"SKU"}
                        </div>
                        <Form.Item
                            name='childSKU'
                            rules={[{ required: onload?.validateBom, message: 'Select SKU' }]}
                        >
                            <Select
                                style={{ border: '1px solid #d9d9d9', borderRadius: '5px' }}
                                placeholder={'Select SKU'}
                                suffixIcon={<CaretDownFilled />}
                                showSearch={true}
                                optionFilterProp="children"
                                onChange={(e) => setAddSkuData(e, 'childSKU')}
                            >
                                {(getSkuDesc?.rData || []).map((item: any) => {
                                    let desc = item?.c?.split("|")
                                    return (
                                        <Select.Option value={desc[0]}>
                                            {desc[1]}
                                        </Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <div className='font-16 redhat-bold mt-10 required'>
                            {"Qty"}
                        </div>
                        <Form.Item
                            name='piecesRequired'
                            rules={[{ required: onload?.validateBom, message: 'Enter Qty' }]}
                        >
                            <Input className='input-field'
                                onChange={(e) => setAddSkuData(e.target.value, 'piecesRqd')}
                                placeholder='Required Qty' />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <div style={{ display: 'flex', alignItems: "center", marginTop: 34 }}>
                            <AppButtons
                                onClick={() => addChildSku()}
                                text={<PlusOutlined />}
                                buttonType={AppConstants.buttonTypes.solidLinearGreen}
                            />
                        </div>
                    </Col>

                    {isArrayNotEmpty(childSkuArr) &&
                        <Row className='mt-10 ' style={{padding: 8}}>
                            <Col span={24}>
                                <Row className='table-header'>
                                    <Col span={8}>SKU</Col>
                                    <Col span={13} className=' d-flex jc-c'>Qty</Col>
                                    <Col span={2} className=' d-flex jc-c'>Action</Col>
                                </Row>
                                {(childSkuArr || []).map((value: any) => {
                                    return (
                                        bomTableView(value)
                                    )

                                })}
                            </Col>
                        </Row>
                    }

                </Row>

            </div>
        )
    }

    const createSkuFormView = () => {
        return (
            <Form form={form} onFinish={openDrawer?.editSkuData == "YES" ? editSKu : addSku} requiredMark={true}>
                <Row className='d-flex align-c mt-10' gutter={16}>
                    <Col span={12}>
                        <>
                            <div className='font-16 redhat-bold mt-10 required'>
                                {AppConstants.skuCode}
                            </div>
                            <Form.Item name='skucode'
                                rules={[{ required: true, message: "Enter SKU Code" }]}
                            >
                                <Input className='input-field'
                                    onChange={(e) => setAddSkuDetails({ ...addSkuDetails, skuCode: e?.target?.value })}
                                />
                            </Form.Item>
                        </>
                    </Col>
                    <Col span={12}>
                        <>
                            <div className='font-16 redhat-bold mt-10 required'>
                                {AppConstants.SKUDescription}
                            </div>
                            <Form.Item name='skuDescription'
                                rules={[{ required: true, message: "Enter SKU Description" }]}
                            >
                                <Input className='input-field'
                                    onChange={(e) => setAddSkuDetails({ ...addSkuDetails, skuDescription: e?.target?.value })}
                                />
                            </Form.Item>
                        </>
                    </Col>
                </Row>
                {eanCodeView()}
                <Row className='d-flex align-c mt-10' gutter={16}>
                    <Col span={12}>
                        <>
                            <div className='font-16 redhat-bold mt-10 required'>
                                {AppConstants.category}
                            </div>
                            <Form.Item name='category'
                                rules={[{ required: true, message: "Select Category" }]}
                            >
                                <Select
                                    className='select-field'
                                    showSearch={true}
                                    optionFilterProp="children"
                                    suffixIcon={<CaretDownFilled />}
                                    onChange={(e) => setAddSkuDetails({ ...addSkuDetails, category: e })}
                                >
                                    {formMeta?.ClientCategories?.map((x: any) =>
                                        <Select.Option value={x?.ID}>
                                            {x?.Name}
                                        </Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </>
                    </Col>
                    <Col span={12}>
                        <>
                            <div className='font-16 redhat-bold mt-10 required'>
                                {AppConstants.pickingCode}
                            </div>
                            <Form.Item name='pickingCode'
                                rules={[{ required: true, message: "Select Picking Code" }]}
                            >
                                <Select
                                    className='select-field'
                                    showSearch={true}
                                    optionFilterProp="children"
                                    suffixIcon={<CaretDownFilled />}
                                    onChange={(e) => setAddSkuDetails({ ...addSkuDetails, pickingCode: e })}
                                >
                                    {formMeta?.PickingCodes?.map((x: any) =>
                                        <Select.Option value={x?.ID}>
                                            {x?.Name}
                                        </Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </>
                    </Col>
                </Row>

                <Row className='d-flex align-c mt-10' gutter={16}>
                    <Col span={6}>
                        <div className='font-16 redhat-bold required'>
                            {AppConstants.length}
                        </div>
                        <Form.Item name='length'
                            rules={[
                                { required: true, message: "Enter Length" },
                                { pattern: /^[0-9]*$/, message: "Enter valid data" },
                            ]}
                        >
                            <Input className='input-field'
                                onChange={(e) => setAddSkuDetails({ ...addSkuDetails, length: e?.target?.value })}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6} >
                        <div className='font-16 redhat-bold required'>
                            {AppConstants.breadth}
                        </div>
                        <Form.Item name='breadth'
                            rules={[
                                { required: true, message: "Enter Breadth" },
                                { pattern: /^[0-9]*$/, message: "Enter valid data" },
                            ]}
                        >
                            <Input className='input-field'
                                onChange={(e) => setAddSkuDetails({ ...addSkuDetails, breadth: e?.target?.value })}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <div className='font-16 redhat-bold required'>
                            {AppConstants.height}
                        </div>
                        <Form.Item name='height'
                            rules={[
                                { required: true, message: "Enter Height" },
                                { pattern: /^[0-9]*$/, message: "Enter valid data" },
                            ]}
                        >
                            <Input className='input-field'
                                onChange={(e) => setAddSkuDetails({ ...addSkuDetails, height: e?.target?.value })}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <div className='font-16 redhat-bold mt-10 required'>
                            {AppConstants.weight}
                        </div>
                        <Form.Item name='weight'
                            rules={[
                                { required: true, message: "Enter Weight" },
                                { pattern: /^[0-9]*$/, message: "Enter valid data" },
                            ]}
                        >
                            <Input className='input-field'
                                onChange={(e) => setAddSkuDetails({ ...addSkuDetails, weight: e?.target?.value })}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row className='d-flex align-c mt-10' gutter={16}>
                    <Col span={12}>
                        <div className='d-flex align-c '>
                            <Checkbox
                                checked={addSkuDetails?.isShelfRequired}
                                onChange={(e) => setAddSkuDetails({ ...addSkuDetails, isShelfRequired: e?.target?.checked })} />
                            <span className='ml-10 font-16  redhat-bold mt-10 mb-10'>Is Shelf life required</span>
                            <span className='ml-5 '>
                                {addSkuDetails?.isShelfRequired &&
                                    <Form.Item
                                        name='shelfLife'
                                        rules={[
                                            { required: true, message: "Enter Shelf Life" },
                                            { pattern: /^[0-9]*$/, message: "Enter valid data" },
                                        ]}
                                    >
                                        <Input style={{ width: '100px', borderRadius: '4px', border: '2px solid #646464' }}
                                            // placeholder='Enter Shelf life (in months)'
                                            onChange={(e) => setAddSkuDetails({ ...addSkuDetails, shelfLife: e.target.value })}
                                        />
                                    </Form.Item>}
                            </span>
                            {addSkuDetails?.isShelfRequired &&
                                <span className='ml-5 font-16  redhat-bold mt-10 mb-10'>
                                    Months
                                </span>}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='d-flex font-16 redhat-bold mt-10 mb-10'>
                            <Checkbox
                                checked={addSkuDetails?.isStickeringRequired}
                                onChange={(e) => setAddSkuDetails({ ...addSkuDetails, isStickeringRequired: e?.target?.checked })} />
                            <span className='ml-10'>Is Stickering required</span>
                        </div>
                    </Col>
                </Row>


                {/* <div className='font-16 redhat-bold mt-10'>
                    {AppConstants.flitkartId}
                </div>
                <Form.Item required name='flipkart'>
                    <Input className='input-field'
                        onChange={(e) => setAddSkuDetails({ ...addSkuDetails, flipkart: e.target.value })}
                    />
                </Form.Item>
                <div className='font-16 redhat-bold mt-10'>
                    {AppConstants.amazonId}
                </div>
                <Form.Item name='amazon'>
                    <Input className='input-field'
                        onChange={(e) => setAddSkuDetails({ ...addSkuDetails, amazon: e.target.value })}
                    />
                </Form.Item> */}
                {!addSkuDetails?.isBOM &&
                    <div className='d-flex font-16 redhat-bold mt-10 mb-10'>
                        <Checkbox
                            checked={addSkuDetails?.isBOM}
                            onChange={(e) => setAddSkuDetails({ ...addSkuDetails, isBOM: e?.target?.checked })} />
                        <span className='ml-10'>BOM (Bill of Material)</span>
                    </div>}
                {addSkuDetails?.isBOM &&
                    bomView()
                }
                <div>
                    <div className='attachment-title font-16 redhat-bold mt-10 mb-10 required'>
                        {AppConstants.attachment}
                        {/* {!uploadView && */}
                        {/* <Button className="add-btn" onClick={() => setUploadView(true)}>
                            <PlusOutlined />
                        </Button> */}
                        {/* } */}
                    </div>
                </div>
                {/* {uploadView && */}
                <div className='dragger-container' style={{ marginTop: 20 }}>
                    {uploadContainerView()}
                </div>
                {/* } */}
                <div className='d-flex flex-end mt-10'>
                    <AppButtons
                        buttonType={AppConstants.buttonTypes.whiteButton}
                        text={AppConstants.cancel}
                        onClick={() => closeDrawer()}
                    />
                    <AppButtons
                        onClick={validateEanbom}
                        htmlType={'submit'}
                        buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                        text={AppConstants.save}
                    />
                </div>
            </Form>
        )
    }


    const createSku = () => {
        return (
            <Modal
                title={<div className='d-flex align-c jc-sb'>
                    <div className='redhat-bold font-18'>
                        {openDrawer?.editSkuData == "YES" ? "Edit SKU" : "Add SKU"}
                    </div>
                    <Button
                        onClick={closeDrawer}
                        className="round-cross-btn">
                        <CloseOutlined />
                    </Button>
                </div>}
                style={{ top: 5 }}
                className='modal-view-2'
                open={openDrawer?.addSKU}
                footer={null}
                closable={false}
            >
                {createSkuFormView()}
            </Modal>
        )
    }

    return (
        <div>
            {createSku()}
            <SpinLoader loading={load || onload.attachmentOnload} />
        </div>
    );
}

export default CreatSku;