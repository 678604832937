import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AppButtons from '../../../CustomComponent/AppButtons';
import AppConstants from '../../../Globals/AppConstants';
import AppReferences from '../../../Globals/AppReferences';
import { AssociatePoAction, getReceivingInProgressBarCodeAction } from '../../../Store/Actions/ProcessWMSAction';
import { getDashboardMenuId, getUserInfo, getWareHouseId } from '../../../Utils/SessionStorage';
import SubHeader from '../../Header/Header';


function AssociatePoListing(props: any) {
    const { stageDetails } = props;
    const dispatch = useDispatch();
    const [startOnload, setStartOnload] = useState(false);
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const navigate = useNavigate();
    const [receivingdetails, setReceivingDetails] = useState<any>();

    useEffect(()=>{
        if(startOnload && !reducerState.recievingInProgressBarcodeOnLoad){
            setStartOnload(false);
            navigate("/start-recieving-view",{ state:{ client_id: receivingdetails?.client, transId: receivingdetails?.transid}});
        }
    }, [startOnload, reducerState.recievingInProgressBarcodeOnLoad])

    const startReceiving = (client: any, trans: any) => {
        setReceivingDetails({...receivingdetails, client: client, transid: trans})
        let payload = {
            "activity": AppReferences.activities.av,
            "customer": client,
            "opnfor": 130100,
            " tableNo": "",
            "transaction": trans,
            "user": getUserInfo()?.UserName,
            "warehouse": getWareHouseId(),
            "where": ""
        }
        dispatch(getReceivingInProgressBarCodeAction(payload));
        setStartOnload(true);
    } 

    const getInvoiceNumber = (string: any) => {
        try {
            let afterLifeStr = string?.split(" : ")?.pop();
            return afterLifeStr
        } catch (error) {
            console.log("Error In getInvoiceNumber:", error);

        }
    }

    const getTotalBoxes = (item: any) => {
        let invoice = getInvoiceNumber(item?.show);
        let invoiceDetails = stageDetails?.header?.find((x: any) => x?.RefernceNo == invoice);
        return invoiceDetails?.RecievedBoxQty;
    }

    const columns: any = [
        {
            title: "Invoice Number",
            dataIndex: "",
            with: "30%",
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {getInvoiceNumber(item?.show)}
                    </div>
                )
            }
        },
        {
            title: "Client",
            dataIndex: 'name',
            with: "30%",
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Total Number Of Boxes",
            dataIndex: "",
            with: "30%",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {getTotalBoxes(item)}
                    </div>
                )
            }
        },
        {
            title: "Action",
            dataIndex: "",
            with: "10%",
            align: "center",
            width: 200,
            render: (item: any) => {
                try {
                    let client = item?.id?.split("|");
                    return (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Link to="/start-recieving-view" state={{ client_id: client[0], transId: client[2], stage: getDashboardMenuId() }}>
                                <AppButtons
                                onClick={()=>startReceiving(client[0], client[2])}
                                    block={true}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                    text={"Start Receiving"}
                                />
                            </Link>
                        </div>
                    )

                } catch (error) {
                    console.log("Error In Table Invoice::", error);

                }
            }
        },
    ];

    const listingView = () => {
        try {
            return (
                <div className='table-screen-container '>
                    <Table
                        columns={columns}
                        dataSource={stageDetails?.lineitems}
                        pagination={false}
                    />
                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }


    return (
        <>
            <SubHeader
                title={"Pending Receiving"}
                inputView="true"
                paginationView="false"
                btnView="false">
            </SubHeader>
            <div className='sendsan-main-container'>
                {listingView()}
            </div>
        </>

    )
}


export default AssociatePoListing;
