import { Col, Layout, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { isArrayNotEmpty } from '../../Globals/Helper';
import { getUserInfo, setClientId } from '../../Utils/SessionStorage';
import './OnBoarding.scss';

function LastLiveScreen(props: any) {
    const navigate = useNavigate();
    const reducerState = useSelector((state: any) => state.ProcessReducerState);
    const [clientMenu, setClientMenu, setMenuId, warehouseDetails]: any = useOutletContext();
    const { clientData } = reducerState;

    const goToOnboarding = (item: any) => {
        setClientId(item);
        navigate('/on-boarding/categories')
    }

    console.log("warehouseDetails", warehouseDetails?.ThirdColumn)

    const contentView = () => {
        return (
            <>
                {warehouseDetails?.ThirdColumn?.map((item: any) =>
                    <div className='last-live-view'
                    >
                        <div className='warehouse-location'>
                            <div>
                                Warehouse - {item?.WarehouseName}
                            </div>
                        </div>
                        <Row className='p-10'>
                            <Col span={6}>
                                <div className='font-16 redhat-bold mb-10'>
                                    {AppConstants.startDate}
                                </div>
                                <div className='redhat-regular'>
                                    {item?.LiveDate}
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className='font-16 redhat-bold mb-10'>
                                    {AppConstants.version}
                                </div>
                                <div className='redhat-regular'>
                                    {item?.VersionNo}
                                </div>
                            </Col>
                        </Row>
                        <div className='status'>
                            <span className='redhat-bold mr-5'>{AppConstants.approved}: </span>{item?.ApprovedBy} - {item?.ApproveDate}
                        </div>
                    </div>)}
            </>
        )
    }

    return (
        <>
        {/* {isArrayNotEmpty(warehouseDetails?.ThirdColumn) ? */}
            <div >
                {contentView()}
            </div>
            {/* :
            <div className='p-20'>
                <NoData
                    className='mt-45'
                    description={
                        <>There is no data available</>
                    }
                />
            </div>
        } */}
        </>
    );
}

export default LastLiveScreen;