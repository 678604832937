import React from "react";

export default function HamburgerIcon() {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
<rect width="22" height="3" rx="1.5" fill="white"/>
<rect y="7.5" width="22" height="3" rx="1.5" fill="white"/>
<rect y="15" width="22" height="3" rx="1.5" fill="white"/>
</svg>
    )
}