import React from 'react';
import { Pie, G2 } from '@ant-design/plots';

function OverAllChartComponent(props: any) {
    let { data } = props;
    const G = G2.getEngine('canvas');

    interface PieChartData {
        type: any;
        value: number;
    }

    const pieChartData: any = [
        {
            type: "On Hand",
            value: 60,
        },
        {
            type: "Inward Processing",
            value: 40,
        },
        {
            type: "GRN Pending",
            value: 30
        },
        {
            type: "RTO",
            value: 20
        },
        {
            type: "Return",
            value: 20
        },
        {
            type: "Dispatched",
            value: 30
        },
    ];
    console.log("data", data)
    const cfg: any = {
        appendPadding: 10,
        data: data,
        angleField: "Qty",
        colorField: "Type",
        radius: 1,
        legend: false,
        //innerRadius: 0.5,
        label: false,
        // interactions: [{ type: "element-selected" }, { type: "element-active" }],
        statistic: {
            title: false as const,
            content: {
                style: {
                    whiteSpace: "pre-wrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
                // formatter: function formatter() {
                //   return `total\n134`;
                // }
            }
        },
        theme: {
            colors10: [
                '#20CD7A',
                '#70D3F7',
                '#032454',
                '#78E0AE',
                '#334E75',
                '#ACB7C7'
            ]
        }
    };
    const config = cfg;
    return <Pie {...config} />;
}

export default OverAllChartComponent;