import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Button, Col, Drawer, Input, message, Row, Steps, Table } from 'antd';
import "../Header/Header.scss"
import CSVFile from '../../Images/CSVFile';
import AppConstants from '../../Globals/AppConstants';
import AppButtons from '../../CustomComponent/AppButtons';
import { CloseOutlined } from '@ant-design/icons';
import { AppImages } from '../../Globals/AppImages';
import { useOutletContext } from 'react-router-dom';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import { useDispatch, useSelector } from 'react-redux';
import { generatePicklistAction, getPickerAction } from '../../Store/Actions/ProcessWMSAction';
import SubHeader from '../Header/Header';
import SkeletonLoader from './SkeletonLoader';
import AppReferences from '../../Globals/AppReferences';
import { checkHasValue, getLocalDateTime, isArrayNotEmpty } from '../../Globals/Helper';
import NoData from '../../CustomComponent/NoData';
import SpinLoader from '../SpinLoader';
import moment from 'moment';

function VerifiedScreen() {
    const [value, setValue] = React.useState('');
    const [checkedValue, setCheckedValue] = useState<any>([]);
    const [openDrawer, setOpenDrawer] = useState<any>(false);
    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);
    };
    const [
        statusDetails,
        channel, tatClient,
        clientValue, process,
        getStatusDetails,
        getChannelStatus,
        setProcess,
        getChannelsApi,
    ]: any = useOutletContext();
    const { pickers, statusDetailsOnload, pickedListOnLoad } = useSelector((state: any) => state?.ProcessReducerState);
    const dispatch = useDispatch();
    const [onload, setOnload] = useState<any>();
    const [orders, setOrders] = useState('0');
    const [showOrders, setShowOrders] = useState("");

    useEffect(() => {
        getPickers()
    }, [])

    useEffect(() => {
        if (onload?.generateOnload == true && pickedListOnLoad == false) {
            setOnload({ ...onload, generateOnload: false });
            getChannelsApi();
            setOrders('0')
            setValue('');
        }
    }, [onload?.generateOnload, pickedListOnLoad])

    const getPickers = () => {
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a06,
            warehouse: getWareHouseId(),
            fieldArray: getUserInfo()?.CorporateID
        }
        dispatch(getPickerAction(payload))
    }

    const genertePicklist = () => {
        let records: any = checkedValue[0];
        checkedValue?.slice(1, checkedValue.length).map((x: any) => {
            records += ',' + x;
        })
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a03,
            transaction: statusDetails?.BatchID?.BatchID,
            where: records,
            user: getUserInfo()?.UserName,
            warehouse: getWareHouseId(),
            customer: clientValue,
            fieldarray: value
        }
        dispatch(generatePicklistAction(payload));
        setOnload({ ...onload, generateOnload: true })
    }

    const checkAll = (e: any, item: any) => {
        if (!item) {
            if (e.target.checked == true) {
                let arr: any = [];
                statusDetails?.lineitems?.map((x: any, index: any) => {
                    arr.push(x?.RECNO ? x?.RECNO : x?.RecNo)
                });
                setCheckedValue(arr)
            }
            else {
                setCheckedValue([])
            }
        }
        else {
            if (e.target.checked == true) {
                setCheckedValue([...checkedValue, (item?.RECNO ? item?.RECNO : item?.RecNo)]);
            }
            else {
                setCheckedValue(checkedValue?.filter((x: any) => x != (item?.RECNO ? item?.RECNO : item?.RecNo)));
            }
        }
    }

    const drawerView = () => {
        try {
            return (
                <Drawer
                    className="drawer-view-verified"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "White" }}>
                            <div className='d-flex'>
                                <div>
                                    Sub Order ID:
                                </div>
                                <div>
                                    000001
                                </div>
                            </div>
                            <Button onClick={() => setOpenDrawer(false)}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>
                        </div>
                    }
                    onClose={() => setOpenDrawer(false)}
                    open={openDrawer == true}>
                    <div>
                        <div className='d-flex'>
                            <div className='icon-container'>
                                <img src={AppImages.productIcon} alt="" />
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <div className='sku-desc'>
                                    SKU-001-Lorem ipsm dolor
                                </div>
                                <div className='order-id'>
                                    Order ID :1243565467
                                </div>
                                <div className='d-flex' style={{ marginTop: 5 }}>
                                    <div className='brand-icon'>
                                        <img src={AppImages.brandIcon} alt='' />
                                    </div>
                                    <div className='time'>
                                        24 hrs
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: "#FAFAFA", marginTop: 25, borderRadius: 6, padding: 15 }}>
                        <div style={{ fontFamily: 'Redhat-Bold', marginTop: 5, fontSize: 16 }} >
                            Overview
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Steps
                                direction='vertical'
                                current={3}
                                items={[
                                    {
                                        title: 'Order Received ',
                                        description: "09-Sep-2022",
                                    },
                                    {
                                        title: 'Pick Released',
                                        description: "09-Sep-2022",
                                    },
                                    {
                                        title: 'Scanning Completed',
                                        description: "10-Sep-2022",
                                    },
                                ]}
                            />
                        </div>
                    </div>

                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }
    }

    const addToBatch = () => {
        if (checkedValue?.length > 0) {
            setOrders(checkedValue.length);

            message.success("Batch Added Successfully")
        } else {
            // message.error({
            //     content: (
            //         <>
            //             Please select the Orders for Add to Batch
            //         </>
            //     ),
            //     duration: 2
            // })
            message.error("Please select the Orders for Add to Batch")
        }

    }

    const header = () => {
        return (
            <Row className='w-100'>
                <Col span={4}></Col>
                <Col span={8} className='d-flex align-c flex-end'>
                    <div style={{ marginRight: 16 }}>
                        <AppButtons
                            disabled={!isArrayNotEmpty(statusDetails?.lineitems)}
                            className='mr-10'
                            onClick={() => (orders == '0') && addToBatch()}
                            text="Add To Batch"
                            buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        />
                    </div>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Picker</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            label="Picker"
                            onChange={handleChange}
                        >
                            {pickers?.Pickers?.map((user: any) =>
                                <MenuItem value={user?.UserID}>{user?.Name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Col>
                <Col span={7} className='d-flex align-c'>
                    <div style={{ marginLeft: 5 }}>
                        <AppButtons
                            buttonType={AppConstants.buttonTypes.whiteButton}
                            text="Generate Picklist"
                            onClick={() => genertePicklist()}
                            disabled={!value || checkedValue.length < 1 || orders == '0'}
                        />
                    </div>
                    <div className='csv-box' style={{ cursor: "pointer", marginLeft: 15 }}>
                        <CSVFile />
                        <div>CSV</div>
                    </div>
                </Col>
                <Col span={5} className='d-flex align-c'>
                    {/* <Input placeholder='Search By Sub order ID ' className="input" style={{ marginLeft: -10, marginRight: 15 }} /> */}
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Show Orders</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={showOrders}
                            label="Show Orders"
                            variant='outlined'
                            onChange={(e) => setShowOrders(e.target.value)}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>25</MenuItem>
                            <MenuItem value={30}>50</MenuItem>
                        </Select>
                    </FormControl>
                </Col>
            </Row>
        )
    }

    const columns: any = [
        {
            title: <div className='d-flex align-c'>
                <div className='mr-10'>
                    <input type={"checkbox"}
                        className="check-box"
                        checked={checkedValue?.length > 1 && checkedValue?.length == statusDetails?.lineitems?.length}
                        onChange={(e) => checkAll(e, null)}
                    />
                </div>
                <div>{AppConstants.orderId}</div>
            </div>,
            dataIndex: "",
            render: (item: any, _: any, index: any) =>
                <div className='d-flex align-c'>
                    <div className='mr-10'>
                        <input type={"checkbox"}
                            className="check-box"
                            onChange={(e) => checkAll(e, item)}
                            checked={checkedValue?.find((x: any) => x == (item?.RECNO ? item?.RECNO : item?.RecNo))} />
                    </div>
                    <div className='orderid' style={{ cursor: "pointer" }} onClick={() => setOpenDrawer(true)} >{item?.ID}</div>
                </div>
        },
        {
            title: AppConstants.skuDescription,
            dataIndex: "",
            render: (item: any) => {
                let [data] = item?.Lines || [];
                return (
                    <div className='redhat-semibold'>
                        {data?.SKU}
                    </div>
                )
            }
        },
        {
            title: AppConstants.orderQty,
            dataIndex: "",
            align: "center",
            render: (item: any) => {
                let [data] = item?.Lines || [];
                return (
                    <div className='text-1'>
                        {data?.OrderedQuantity}
                    </div>
                )
            }
        },
        {
            title: channel == AppReferences.channel.b2b ? AppConstants.rcdDate : AppConstants.crtDate,
            dataIndex: "",
            render: (item: any) =>
                <div className='redhat-semibold'>
                    {checkHasValue(channel == AppReferences.channel.b2b ? moment(item?.ReceivedDate).format("DD-MM-YYYY") : moment(getLocalDateTime(item?.CreatedDate)).format("DD-MM-YYYY"))}
                </div>
        },
        {
            title: channel == AppReferences.channel.b2b ? AppConstants.shipByDate : AppConstants.rcdDate,
            dataIndex: "",
            render: (item: any) =>
                <div className='redhat-semibold'>
                    {checkHasValue(channel == AppReferences.channel.b2b ? moment(item?.ShipByDate).format("DD-MM-YYYY") : moment(getLocalDateTime(item?.ReceivedDate)).format("DD-MM-YYYY"))}
                </div>

        },
        {
            title: AppConstants.tat,
            dataIndex: "TAT",
            align: 'center',
            render: (item: any) =>
                <div className='d-flex jc-c'>
                    <div className='redhat-semibold tat'>
                        {checkHasValue(item)}
                    </div>
                </div>
        }
    ]

    const contentView = () => {
        let column = (channel == AppReferences.channel.b2b) ? (columns?.slice(0, 5)) : columns;
        let dataSource = showOrders ? statusDetails?.lineitems?.slice(0, showOrders) : statusDetails?.lineitems;
        return (
            <>
                {isArrayNotEmpty(statusDetails?.lineitems) ?
                    <div className='table-screen-container'>
                        <Table
                            columns={column}
                            dataSource={dataSource}
                            pagination={false}
                        />
                    </div>
                    :
                    <div className='p-20'>
                        <NoData
                            description={
                                <>{AppConstants.nodata}<span className='redhat-bold ml-5'>Verified</span></>
                            }
                        />
                    </div>
                }
            </>
        )
    }

    return (
        <>
            {!statusDetailsOnload ?
                <div className='verified-container'>
                    <SubHeader
                        title={
                            <div>
                                <div>Batch ID: {statusDetails?.BatchID?.BatchID}</div>
                                {(orders != '0') &&
                                    <div className='font-14'>{orders} Orders Added</div>}
                            </div>
                        }
                        inputView="true"
                        paginationView="false"
                        btnView="true"
                        fromOrgin="orderProcessing"
                    >
                        <div className='w-100'>
                            {header()}
                        </div>
                    </SubHeader>
                    {contentView()}
                    {drawerView()}
                    <SpinLoader
                        loading={onload?.generateOnload}
                    />
                </div>
                :
                <SkeletonLoader />
            }
        </>
    );
}

export default VerifiedScreen;
