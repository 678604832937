import { CaretRightOutlined, CloseOutlined, DeleteFilled, PlusOutlined, SaveFilled } from '@ant-design/icons';
import { Button, Col, DatePicker, Row, Select, Table, Tooltip } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import AppReferences from '../../Globals/AppReferences';
import { checkHasValue } from '../../Globals/Helper';
import Calender from '../../Images/Calender';
import { getDetailedDataAction } from '../../Store/Actions/ProcessWMSAction';
import SubHeader from '../Header/Header';

function Kitting(props: any) {
    const [expandRow, setExpandRow] = useState(-1);
    const reducerstate: any = useSelector((state: any) => state?.ProcessReducerState);
    const [viewBOM, setViewBOM] = useState(-1);
    const {
        salesClients,
        expandDetailedData
    } = reducerstate;
    const [
        value,
        individualStageData,
        processId,
        getStatges,
        getIndividualStageDetails
    ]: any = useOutletContext();
    const dispatch = useDispatch();

    const getExpandViewData = (sku: any, data: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.itemMaster,
            activity: AppReferences.activities.a02,
            customer: data?.ClientID,
            transaction: sku,
        }
        dispatch(getDetailedDataAction(payload))
    }

    const header = () => {
        try {
            return (
                <SubHeader
                    title={'Kitting Pending'}
                    paginationView="false"
                    inputView="true"
                    btnView="true"
                >
                </SubHeader>
            )
        } catch (error) {
            console.log("Error in header::", error);
        }
    }

    const tableHeader = () => {
        return (
            <Row className='table-header'>
                <Col span={6}>
                    {AppConstants.salesOrder}
                </Col>
                <Col span={5}>
                    {AppConstants.referenceNumber}
                </Col>
                <Col span={5}>
                    {AppConstants.crtDate}
                </Col>
                <Col span={8} className='text-center'>
                    {AppConstants.action}
                </Col>
            </Row>
        )
    }

    const tableTitleContainer = () => {
        return (
            <Row>
                <Col span={8}>
                    <div className='redhat-bold  ' style={{fontSize : "14px" , fontFamily: "RedHat-regular"}}>
                        {AppConstants.skuDescription}
                    </div>
                </Col>
                <Col span={8} className='d-flex jc-c'>
                    <div className='redhat-bold  ' style={{fontSize : "14px" , fontFamily: "RedHat-regular"}}>
                        {AppConstants.orderedqty}
                    </div>
                </Col>
                <Col span={8} className='d-flex jc-c'>
                    <div className='redhat-bold  ' style={{fontSize : "14px" , fontFamily: "RedHat-regular"}}>
                        {AppConstants.shelfLife}
                    </div>
                </Col>
            </Row>
        )
    }

    const expandRecord = (index: any, data: any, info: any) => {
        setViewBOM(viewBOM == index ? null : index);
        getExpandViewData(data?.ItemId, info)
    }

    const bomSKUList = () => {
        const column = [
            {
                title: AppConstants.SKUDescription,
                dataIndex: '',
                render: (item: any) =>
                    <div>
                        {item?.ChildItemID + "," + item?.Description}
                    </div>
            },
            {
                title: AppConstants.requiredQty,
                dataIndex: 'PiecesRequired'
            }
        ]
        return (
            <div className='pending-san-view-container pl-10'>
                <Table
                    columns={column}
                    dataSource={expandDetailedData?.ChildItemList}
                />
            </div>
        )
    }


    const tableList = (data: any, index: any, info: any) => {
        try {
            return (
                <div className="content-container" style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={8} className='d-flex align-c'>
                            <div
                                onClick={() => data?.IsBom != 'N' && expandRecord(index, data, info)}
                                className={data?.IsBom != 'N' ? 'text show-label cursor-pointer' : 'text'}
                            >
                                {checkHasValue(data?.ItemId)}
                            </div>
                        </Col>
                        <Col span={8} className='d-flex align-c jc-c'>
                            <div className='text  '>
                                {checkHasValue(data?.OrderedQuantity)}
                            </div>
                        </Col>
                        <Col span={8} className='d-flex jc-c'>
                            <div className='text  '>
                                {checkHasValue(data?.ExpectedShelfLife)}
                            </div>
                        </Col>
                        {(viewBOM == index && data?.IsBom != 'N') &&
                            <Col span={24}>
                                {bomSKUList()}
                            </Col>}
                    </Row>
                </div >
            )
        } catch (error) {
            console.log("Error in tableList::", error)
        }
    }

    const tableView = (skuList: any, info: any) => {
        try {
            return (
                <div style={{ background: "white", padding: 10, borderRadius: 6 }}>
                    {/* {detailsView(item)} */}
                    {skuList && <div>
                        <div className='header-part-2'>
                            {tableTitleContainer()}
                        </div>
                        <div >
                            {(skuList || []).map((data: any, index: any) =>
                                tableList(data, index, info)
                            )}
                        </div>
                    </div>}
                </div>

            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const handleExpand = (data: any, index: any) => {
        setExpandRow(expandRow == index ? null : index);
    }

    const listingView = (data: any, index: any) => {
        try {
            let isExpandopen = (expandRow == index);
            return (
                <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                    <Row className='align-c'>
                        <Col span={6}>
                            <div className="title-container">
                                <div className='d-flex align-c'>
                                    <CaretRightOutlined
                                        onClick={() => handleExpand(data, index)}
                                        className={index === expandRow ? "arrow arrow-down" : "arrow arrow-right"} />
                                    <div
                                        className='text overflow-ellipse  '
                                        title={data?.SOID}
                                        style={{ width: 150, marginLeft: 5 }}
                                    >
                                        {data?.SOID}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={5}>
                            <div className='text  '>
                                {checkHasValue(data?.ReferenceId)}
                            </div>
                        </Col>
                        <Col span={5}>
                            <div className='text  '>
                                {checkHasValue(data?.CreatedDate)}
                            </div>
                        </Col>
                        <Col span={8} className='d-flex align-c jc-c'>
                            <AppButtons
                                text={"Complete Kitting"}
                                buttonType={AppConstants.buttonTypes.solidLinearGreen}
                            />
                            <AppButtons
                                text={"Cancel Kitting"}
                                buttonType={AppConstants.buttonTypes.whiteButton}
                            />
                        </Col>
                    </Row>
                    {isExpandopen &&
                        <div style={{ marginTop: 10 }}>
                            {tableView(data?.Lines, data)}
                        </div>
                    }
                </div >
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const dataView = () => {
        let dataList =  individualStageData?.lineitems;
        return (
            <div className="select-right-container">
                {header()}
                <div className='p-10'>
                    {tableHeader()}
                    <div>
                        {(dataList || []).map((item: any, index: Number) =>
                            listingView(item, index)
                        )}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            {dataView()}
        </div>
    );
}

export default Kitting;