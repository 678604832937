import { CaretRightOutlined, CloseOutlined, EyeFilled } from '@ant-design/icons';
import { Button, Col, Drawer, Input, Layout, Row } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import NoData from '../../../CustomComponent/NoData';
import AppConstants from '../../../Globals/AppConstants';
import { getDashboardMenuId } from '../../../Utils/SessionStorage';
import SubHeader from '../../Header/Header';
import GrnPending from './GrnPending';

const { Content } = Layout;

function GrnPendingListing(props: any) {
    const { stageDetails, value } = props;
    const [expandRow, setExpandRow] = useState(-1);
    const [openDrawer, setOpenDrawer] = useState<any>();
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const navigate = useNavigate();
    const handleExpand = (data: any, index: any) => {
        // { expandRow != index && getGrnDetails(data) };
        setExpandRow(expandRow == index ? null : index);
        // }
    }

    const titleContainer = () => {
        try {
            return (
                <Row>
                    <Col span={value == 145100 ? 5 : 6}>
                        <div className='text'>Invoice Number</div>
                    </Col>
                    <Col span={value == 145100 ? 3 : 4}>
                        <div className='text'>Client</div>
                    </Col>
                    <Col span={value == 145100 ? 2 : 3}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                            <div className='text'>Invoice Box Qty</div>
                        </div>
                    </Col>
                    <Col span={value == 145100 ? 3 : 4}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className='text'>Received Box Qty</div>
                        </div>
                    </Col>
                    {value == 145100 &&
                        <Col span={2}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className='text'>SKU Count</div>
                            </div>
                        </Col>
                    }
                    {value == 145100 &&
                        <Col span={3}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className='text'>Total SKU Units</div>
                            </div>
                        </Col>
                    }
                    <Col span={value == 145100 ? 2 : 3}>
                        <div className='d-flex align-c jc-c'>
                            <div className='text'>PO Number</div>
                        </div>
                    </Col>
                    <Col span={value == 145100 ? 3 : 4}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className='text'>Release Number</div>
                        </div>
                    </Col>
                    {value == 145100 &&
                        <Col span={1}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className='text'>Action</div>
                            </div>
                        </Col>
                    }
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }

    const drawerView = () => {
        try {
            let [, , warehouseId] = (openDrawer?.data?.id?.split("|") || []);//to get id from third index
            let drawerData = stageDetails?.header[0];
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                {warehouseId}
                            </div>
                            <Button onClick={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>
                        </div>
                    }
                    onClose={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                    open={openDrawer?.isOPen}>
                    <Row>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                {AppConstants.client}
                            </div>
                            <div className='value-field'>
                                {drawerData?.ClientID}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Transaction Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.InwardTxnTypeID}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.VehicleTypeId}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.VehicleNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Seal Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.SealNo}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Docket Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.DocketNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Dock Station
                            </div>
                            <div className='value-field'>
                                {drawerData?.DockStationID}
                            </div>
                        </Col>
                    </Row>
                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }

    }

    const pendingSansView = (data: any, index: any) => {
        try {
            let isExpandopen = (expandRow == index);
            return (
                <div className={isExpandopen ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={value == 145100 ? 5 : 6} className=' d-flex align-c'>
                            {/* <div className="title-container"> */}
                            <div className='down-arrow d-flex align-c'>
                                <CaretRightOutlined
                                    onClick={() => handleExpand(data, index)}
                                    className={isExpandopen ? "arrow arrow-down" : "arrow arrow-right"} />
                                <div className='text'>{data?.InvoiceNo}</div>
                            </div>
                            {/* </div> */}
                        </Col>
                        <Col span={value == 145100 ? 3 : 4} className=' d-flex align-c'>
                            <div className='text'> {data?.Name}</div>
                        </Col>
                        <Col span={value == 145100 ? 2 : 3} className=' d-flex align-c jc-c'>
                            <div className='text-1'> {data?.InvoiceBoxQty}</div>
                        </Col>
                        <Col span={value == 145100 ? 3 : 4} className=' d-flex align-c jc-c'>
                            <div className='text-1'> {data?.RecievedBoxQty}</div>
                        </Col>
                        {value == 145100 &&
                            <Col span={2} className=' d-flex align-c jc-c'>
                                <div className='text-1'> {data?.SKUCount}</div>
                            </Col>
                        }
                        {value == 145100 &&
                            <Col span={3} className=' d-flex align-c jc-c'>
                                <div className='text-1'> {data?.SKUUnits}</div>
                            </Col>
                        }
                        <Col span={value == 145100 ? 2 : 3} className=' d-flex align-c jc-c'>
                            <div className='text'> {data?.PurchaseOrderID}</div>
                        </Col>
                        <Col span={value == 145100 ? 3 : 4} className=' d-flex align-c jc-c'>
                            <div className='text'>{data?.ReleaseNumber}</div>
                        </Col>
                        {value == 145100 &&
                            <Col span={1} className=' d-flex align-c jc-c'>
                                <Button className='eye-btn'
                                    onClick={() => navigate("/grn-pending", { state: { pendingGrn: data, isCutomer: stageDetails?.isCustomerLogin?.CustomerLogin, value: value } })}
                                >
                                    <EyeFilled />
                                </Button>
                            </Col>
                        }
                    </Row>
                    {isExpandopen &&
                        <div style={{ marginTop: 10 }}>
                            <GrnPending
                                isCustomer={stageDetails?.isCustomerLogin?.CustomerLogin}
                                pendingGrn={data}
                                stageDetails={stageDetails}
                                value={value}
                                tableColor="false" />
                        </div>
                    }
                </div>
            )
        } catch (error) {
            console.log("Error in pendingSansView::", error)
        }
    }

    return (
        <div>
            <SubHeader
                title={value == 145100 ? "Pending GRN" : "Assignment Pending"}
                inputView="true"
                paginationView="false"
                btnView="false">
            </SubHeader>
            <div style={{ padding: 15 }} className="">
                <div className='header-part'>
                    {titleContainer()}
                </div>
                <div>
                    {stageDetails?.lineitems?.length != 0 ?
                        (stageDetails?.lineitems || []).map((item: any, index: any) => {
                            return (
                                <div>
                                    {pendingSansView(item, index)}
                                </div>
                            )
                        }) :
                        <NoData />}
                </div>
                {drawerView()}
            </div>
        </div>
    )
}

export default GrnPendingListing;