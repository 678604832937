import { ConsoleSqlOutlined } from '@ant-design/icons';
import { useSelect } from '@mui/base';
import { Col, Layout, message, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import NoData from '../../CustomComponent/NoData';
import { AppModuleIds } from '../../Globals/AppReferenceIds';
import AppReferences from '../../Globals/AppReferences';
import { isArrayEmpty, isArrayNotEmpty } from '../../Globals/Helper';
import { getClientListAction, getOnboardMenuAction, getStageDetailsAction } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, setClientId } from '../../Utils/SessionStorage';
import CardContainer from '../CardContainer';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import SalesOrderMenu from '../SalesOrder/SalesOrderMenu';
import './OnBoarding.scss';

const menu = [
   
    {
        name: 'Clients',
        cnt: '-',
        id: '4',
        path: '/on-boarding/client'
    },
    {
        name: 'Being Onboarded',
        cnt: '-',
        id: '1',
        path: '/on-boarding/dashboard'
    },
   
    // {
    //     name: 'Last 30 Days Live',
    //     cnt: '-',
    //     id: '2',
    //     path: '/on-boarding/last-lives'
    // },
    // {
    //     name: 'Live',
    //     cnt: '-',
    //     id: '3',
    //     path: '/on-boarding/overall-lives'
    // },

]
function OnBoardingdashboard(props: any) {
    const [menuId, setMenuId] = useState('1');
    const [menuDetails, setMenuDetails] = useState<any>({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reducerState = useSelector((state: any) => state.ProcessReducerState);
    const { clientData } = reducerState;
    const [clientMenu, setClientMenu] = useState<any>('');
    const [onload, setOnload] = useState({
        clientOnload: false,
        menuOnload: false
    });
    const [menus, setMenus] = useState(menu);
    const { onboardingDetails, stageDetails } = reducerState;
    let warehouseDetails = stageDetails;

    console.log("warehouseDetails warehouseDetails",warehouseDetails)


    useEffect(() => {
        getDashboardMenu();
    }, []);

    useEffect(() => {
        let path = window.location.pathname?.split('/');
        if (path[2] == 'client') {
            setMenuId('4');
        }
        else if (path[2] == 'dashboard') {
            setMenuId('1');
        }
        else {
            setMenuId('4')
        }
    }, []);

    useEffect(() => {
        try {
            console.log("onboardingDetails?.FirstColumn",onboardingDetails?.FirstColumn)
            if (onload.menuOnload && !reducerState.onload) {
                setOnload({ ...onload, menuOnload: false });
                let menuArr: any = [];
                onboardingDetails?.FirstColumn?.map((item: any) => {
                    let menuItem: any = menu?.find((x: any) => x.name == item?.Status);
                    console.log("menuArr",menuItem)
                    if (menuItem) {
                        menuItem.cnt = item?.Cnt;
                        menuItem = { ...menuItem, ...item };
                        menuArr.push(menuItem)
                    }
                    
                })
                setMenus(menuArr);
                let client = menuArr?.find((x: any) => x?.id == menuId);
                getClients(client);
            }
        } catch (error) {
            console.log("Error in setClientMenu::", error)
        }
    }, [onload.menuOnload, reducerState.onload]);

    useEffect(() => {
        try {
            if (isArrayEmpty(clientData?.Clients)) {
                warehouseDetails = []
            } else {
                setOnload({ ...onload, clientOnload: false });
                let client = clientData?.Clients[0];
                setClientMenu(client?.Id);
            }
        } catch (error) {
            console.log("Error in setClientMenu::", error)
        }
    }, [clientData?.Clients]);

    useEffect(() => {
        if (clientMenu) {
            let client = clientData?.Clients?.find((x: any) => x?.Id == clientMenu)
            getWarehouseDetails(client);
        }
    }, [clientMenu]);

    const getDashboardMenu = () => {
        let payload = {
            opnfor: AppReferences.opnfor.onboard,
            activity: AppReferences.activities.a06,
            transaction: getUserInfo()?.CorporateID,
            user: getUserInfo()?.UserName,
        }
        dispatch(getOnboardMenuAction(payload))
        setOnload({ ...onload, menuOnload: true })
    }


    const getWarehouseDetails = (client: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.onboard,
            activity: AppReferences.activities.a07,
            transaction: clientMenu,
            user: getUserInfo()?.UserName,
            where: client?.StatusID,
            fieldArray: client?.IsLastThirtyDaysLive,
            tableno: getUserInfo()?.CorporateID
        }
        dispatch(getStageDetailsAction(payload))
    }

    const getClients = (data: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.onboard,
            activity: AppReferences.activities.av,
            transaction: getUserInfo()?.CorporateID,
            user: getUserInfo()?.UserName,
            where: data?.StatusID,
            fieldArray: data?.IsLastThirtyDaysLive,
        }
        dispatch(getClientListAction(payload));
        setClientMenu(null);
        // setOnload({...onload, clientOnload: true});
    }

    const onChange = (e: any) => {
        setMenuId(e.key);
        let menuObj: any = menu?.find((x: any) => x?.id == e.key);
        let data = onboardingDetails?.FirstColumn?.find((x: any) => x?.Status == menuObj?.name);
        getClients(data);
        navigate(menuObj?.path)
    }

    const clientMenuView = () => {
        return (
            <>{isArrayNotEmpty(clientData?.Clients) ?
                <div className='mt-10'>
                    <SalesOrderMenu
                        menuId={clientMenu}
                        menus={clientData?.Clients}
                        onChange={(e: any) => setClientMenu(e.target.value)}
                    />
                </div>
                :
                <div className='p-20'>
                    <NoData
                        className='mt-45'
                        description={
                            <> Client not available</>
                        }
                    />
                </div>
            }</>

        )
    }


    return (
        <Layout>
            <NavbarLayout
                titleContent="On-Boarding Dashboard"
                process={clientData?.title}
                fromOrigin='onboard'
                initial={AppModuleIds.main}
                selectedMain={'9'}
            />
            <Content className="antd-content-white">
                <Row className="row-container" gutter={10}>
                    <Col span={4}>
                        <div className="sub-sider-menu mt-10">
                            <CardContainer
                                scrollable={true}
                                menu={menu}
                                handleMenuSelect={onChange}
                                value={menuId}
                            />
                        </div>

                    </Col>
                    {(menuId !== '4' ) &&
                        <Col span={4} className='Onboarding-dashboard-screen'>
                            {clientMenuView()}
                        </Col>
                    }
                    <Col span={menuId !== '4' ? 16: 20} className='Onboarding-dashboard-screen'>
                        <Outlet context={[clientMenu,stageDetails, setClientMenu, setMenuId, getDashboardMenu, isArrayEmpty(clientData?.Clients) ? warehouseDetails = [] : warehouseDetails,setOnload]} />
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}

export default OnBoardingdashboard;