const 
ApiConstants = {
    API_FAIL: "API_FAIL",
    API_ERROR: "API_ERROR",
    API_LOGIN: "API_LOGIN",
    API_GET_WAREHOUSES_ACTION_LOAD: "API_GET_WAREHOUSES_ACTION_LOAD",
    API_GET_WAREHOUSES_ACTION_SUCCESS: "API_GET_WAREHOUSES_ACTION_SUCCESS",
    API_GET_GATE_IN_ENTRY_LOAD: "API_GET_GATE_IN_ENTRY_LOAD",
    API_GET_GATE_IN_ENTRY_SUCCESS: "API_GET_GATE_IN_ENTRY_SUCCESS",
    API_GET_PROCESS_DATA_LOAD: "API_GET_PROCESS_DATA_LOAD",
    API_GET_PROCESS_DATA_SUCCESS: "API_GET_PROCESS_DATA_SUCCESS",
    API_GET_STAGE_DETAILS_LOAD: "API_GET_STAGE_DETAILS_LOAD",
    API_GET_STAGE_DETAILS_SUCCESS: "API_GET_STAGE_DETAILS_SUCCESS",
    API_GET_INDIVIDUAL_STAGE_LOAD: "API_GET_INDIVIDUAL_STAGE_LOAD",
    API_GET_INDIVIDUAL_STAGE_SUCCESS: "API_GET_INDIVIDUAL_STAGE_SUCCESS",
    API_GET_GATE_IN_ENTRY_META_LOAD: "API_GET_GATE_IN_ENTRY_META_LOAD",
    API_GET_GATE_IN_ENTRY_META_SUCCESS: "API_GET_GATE_IN_ENTRY_META_SUCCESS",
    API_GATE_IN_ENTRY_ADD_LOAD: "API_GATE_IN_ENTRY_ADD_LOAD",
    API_GATE_IN_ENTRY_ADD_SUCCESS: "API_GATE_IN_ENTRY_ADD_SUCCESS",
    API_GET_TRANSACTION_LOAD: "API_GET_TRANSACTION_LOAD",
    API_GET_TRANSACTION_SUCCESS: "API_GET_TRANSACTION_SUCCESS",
    API_GET_ASSOCIATEPO_LOAD: "API_GET_ASSOCIATEPO_LOAD",
    API_GET_ASSOCIATEPO_SUCCESS: "API_GET_ASSOCIATEPO_SUCCESS",
    API_GET_PENDING_SAN_DETAILS_LOAD: "API_GET_PENDING_SAN_DETAILS_LOAD",
    API_GET_PENDING_SAN_DETAILS_SUCCESS: "API_GET_PENDING_SAN_DETAILS_SUCCESS",
    API_GET_ASSOCIATEPO_SEND_LOAD: "API_GET_ASSOCIATEPO_SEND_LOAD",
    API_GET_ASSOCIATEPO_SEND_SUCCESS: "API_GET_ASSOCIATEPO_SEND_SUCCESS",
    API_GET_RECEIVING_IN_PROGRESS_BARCODE_LOAD: "API_GET_RECEIVING_IN_PROGRESS_BARCODE_LOAD",
    API_GET_RECEIVING_IN_PROGRESS_BARCODE_SUCCESS: "API_GET_RECEIVING_IN_PROGRESS_BARCODE_SUCCESS",
    API_SAVE_RECEIVING_IN_PROGRESS_LOAD: "API_SAVE_RECEIVING_IN_PROGRESS_LOAD",
    API_SAVE_RECEIVING_IN_PROGRESS_SUCCESS: "API_SAVE_RECEIVING_IN_PROGRESS_SUCCESS",
    API_GET_RECEIVING_IN_PROGRESS_VIEW_LOAD: "API_GET_RECEIVING_IN_PROGRESS_VIEW_LOAD",
    API_GET_RECEIVING_IN_PROGRESS_VIEW_SUCCESS: "API_GET_RECEIVING_IN_PROGRESS_VIEW_SUCCESS",
    API_GET_UNLOADING_DETAILS_LOAD: "API_GET_UNLOADING_DETAILS_LOAD",
    API_GET_UNLOADING_DETAILS_SUCCESS: "API_GET_UNLOADING_DETAILS_SUCCESS",
    API_SEND_TO_STICKERING_LOAD: "API_SEND_TO_STICKERING_LOAD",
    API_SEND_TO_STICKERING_SUCCESS: "API_SEND_TO_STICKERING_SUCCESS",
    API_GET_STICKER_DETAILS_LOAD: ",API_GET_STICKER_DETAILS_LOAD",
    API_GET_STICKER_DETAILS_SUCCESS: "API_GET_STICKER_DETAILS_SUCCESS",
    API_COMPLETE_STICKERING_LOAD: "API_COMPLETE_STICKERING_LOAD",
    API_COMPLETE_STICKERING_SUCCESS: "API_COMPLETE_STICKERING_SUCCESS",
    API_SEND_SAN_INFO_LOAD: "API_SEND_SAN_INFO_LOAD",
    API_SEND_SAN_INFO_SUCCESS: "API_SEND_SAN_INFO_SUCCESS",
    API_GRN_INFO_LOAD: "API_GRN_INFO_LOAD",
    API_GRN_INFO_SUCCESS: "API_GRN_INFO_SUCCESS",
    API_GET_CAPTURE_INVOICE_DETAIL_LIST_LOAD: "API_GET_CAPTURE_INVOICE_DETAIL_LIST_LOAD",
    API_GET_CAPTURE_INVOICE_DETAIL_LIST_SUCCESS: "API_GET_CAPTURE_INVOICE_DETAIL_LIST_SUCCESS",
    API_GET_PUTAWAY_DEATAILS_LOAD: "API_GET_PUTAWAY_DEATAILS_LOAD",
    API_GET_PUTAWAY_DEATAILS_SUCCESS: "API_GET_PUTAWAY_DEATAILS_SUCCESS",
    API_GET_META_DATA_LOAD: "API_GET_META_DATA_LOAD",
    API_GET_META_DATA_SUCCESS: "API_GET_META_DATA_SUCCESS",
    API_ADD_INVOICE_LOAD: "API_ADD_INVOICE_LOAD",
    API_ADD_INVOICE_SUCCESS: "API_ADD_INVOICE_SUCCESS",
    API_GET_CAPTURE_INVOICE_DETAILS_LOAD: "API_GET_CAPTURE_INVOICE_DETAILS_LOAD",
    API_GET_CAPTURE_INVOICE_DETAILS_SUCCESS: "API_GET_CAPTURE_INVOICE_DETAILS_SUCCESS",
    API_CAPTURE_SKU_WISE_LOAD: "API_CAPTURE_SKU_WISE_LOAD",
    API_CAPTURE_SKU_WISE_SUCCESS: "API_CAPTURE_SKU_WISE_SUCCESS",
    API_SEND_SAN_LOAD: "API_SEND_SAN_LOAD",
    API_SEND_SAN_SUCCESS: "API_SEND_SAN_SUCCESS",
    API_GET_SKU_LOAD: "API_GET_SKU_LOAD",
    API_GET_SKU_SUCCESS: "API_GET_SKU_SUCCESS",
    API_GET_CONDITION_LOAD: "API_GET_CONDITION_LOAD",
    API_GET_CONDITION_SUCCESS: "API_GET_CONDITION_SUCCESS",
    API_SAVE_SKU_LOAD: "API_SAVE_SKU_LOAD",
    API_SAVE_SKU_SUCCESS: "API_SAVE_SKU_SUCCESS",
    API_COMPLETE_RECEIVE_IN_PROGRESS_LOAD: "API_COMPLETE_RECEIVE_IN_PROGRESS_LOAD",
    API_COMPLETE_RECEIVE_IN_PROGRESS_SECCESS: "API_COMPLETE_RECEIVE_IN_PROGRESS_SECCESS", API_GET_CAPTURE_SKU_DETAILS_LOAD: "API_GET_CAPTURE_SKU_DETAILS_LOAD",
    API_GET_CAPTURE_SKU_DETAILS_SUCCESS: "API_GET_CAPTURE_SKu_DETAILS_SUCCESS",
    API_GET_GENERATE_PUTAWAY_INFO_LOAD: "API_GET_GENERATE_PUTAWAY_INFO_LOAD",
    API_GET_GENERATE_PUTAWAY_INFO_SUCCESS: "API_GET_GENERATE_PUTAWAY_INFO_SUCCESS",
    API_GET_ASSIGN_MOVER_INFO_LOAD: "API_GET_ASSIGN_MOVER_INFO_LOAD",
    API_GET_ASSIGN_MOVER_INFO_SUCCESS: "API_GET_ASSIGN_MOVER_INFO_SUCCESS",
    API_GET_BAR_CODE_SCAN_SUCCESS_DATA_LOAD: "API_GET_BAR_CODE_SCAN_SUCCESS_DATA_LOAD",
    API_GET_BAR_CODE_SCAN_SUCCESS_DATA_SUCCESS: "API_GET_BAR_CODE_SCAN_SUCCESS_DATA_SUCCESS",
    API_START_PUTAWAY_LOAD: "API_START_PUTAWAY_LOAD",
    API_START_PUTAWAY_SUCCESS: "API_START_PUTAWAY_SUCCESS",
    API_GET_CLIENT_DATA_LOAD: "API_GET_CLIENT_DATA_LOAD",
    API_GET_CLIENT_DATA_SUCCESS: "API_GET_CLIENT_DATA_SUCCESS",
    API_GET_SKU_DATA_LOAD: "API_GET_SKU_DATA_LOAD",
    API_GET_SKU_DATA_SUCCESS: "API_GET_SKU_DATA_SUCCESS",
    API_GET_INVENTORY_DATA_LOAD: "API_GET_INVENTORY_DATA_LOAD",
    API_GET_INVENTORY_DATA_SUCCESS: "API_GET_INVENTORY_DATA_SUCCESS",
    API_DELETE_RECEIVE_INPROGRESS_LOAD: "API_DELETE_RECEIVE_INPROGRESS_LOAD",
    API_DELETE_RECEIVE_INPROGRESS_SUCCESS: "API_DELETE_RECEIVE_INPROGRESS_SUCCESS",
    API_ASSIGN_MOVER_LOAD: "API_ASSIGN_MOVER_LOAD",
    API_ASSIGN_MOVER_SUCCESS: "API_ASSIGN_MOVER_SUCCESS",
    API_GET_CUSTOMER_LOAD: "API_GET_CUSTOMER_LOAD",
    API_GET_CUSTOMER_SUCCESS: "API_GET_CUSTOMER_SUCCESS",
    API_GET_PO_LOAD: "API_GET_PO_LOAD",
    API_GET_PO_SUCCESS: "API_GET_PO_SUCCESS",
    API_GET_SKU_BATCH_DETAILS_LOAD: "API_GET_SKU_BATCH_DETAILS_LOAD",
    API_GET_SKU_BATCH_DETAILS_SUCCESS: "API_GET_SKU_BATCH_DETAILS_SUCCESS",
    API_ADD_SKU_BATCH_DETAILS_LOAD: "API_ADD_SKU_BATCH_DETAILS_LOAD",
    API_ADD_SKU_BATCH_DETAILS_SUCCESS: "API_ADD_SKU_BATCH_DETAILS_SUCCESS",
    API_EDIT_SKU_BATCH_DETAILS_LOAD: "API_EDIT_SKU_BATCH_DETAILS_LOAD",
    API_EDIT_SKU_BATCH_DETAILS_SUCCESS: "API_EDIT_SKU_BATCH_DETAILS_SUCCESS",
    API_GET_SKU_DETAILS_LOAD: "API_GET_SKU_DETAILS_LOAD",
    API_GET_SKU_DETAILS_SUCCESS: "API_GET_SKU_DETAILS_SUCCESS",
    API_GET_SKU_AND_PO_LOAD: "API_GET_SKU_AND_PO_LOAD",
    API_GET_SKU_AND_PO_SUCCESS: "API_GET_SKU_AND_PO_SUCCESS",
    API_CREATE_PO_LOAD: "API_CREATE_PO_LOAD",
    API_CREATE_PO_SUCCESS: "API_CREATE_PO_SUCCESS",
    API_ADD_SKU_FOR_PO_LOAD: "API_ADD_SKU_FOR_PO_LOAD",
    API_ADD_SKU_FOR_PO_SUCCESS: "API_ADD_SKU_FOR_PO_SUCCESS",
    API_EDIT_SKU_FOR_PO_LOAD: "API_EDIT_SKU_FOR_PO_LOAD",
    API_EDIT_SKU_FOR_PO_SUCCESS: "API_EDIT_SKU_FOR_PO_SUCCESS",
    API_GET_SKU_DESC_LOAD: "API_GET_SKU_DESC_LOAD",
    API_GET_SKU_DESC_SUCCESS: "API_GET_SKU_DESC_SUCCESS",
    API_UPDATE_PO_LOAD: "API_UPDATE_PO_LOAD",
    API_UPDATE_PO_SUCCESS: "API_UPDATE_PO_SUCCESS",
    API_DELETE_LOAD: "API_DELETE_LOAD",
    API_DELETE_SUCCESS: "API_DELETE_SUCCESS",
    API_CONSIGNMENT_LOAD: "API_CONSIGNMENT_LOAD",
    API_CONSIGNMENT_SUCCESS: "API_CONSIGNMENT_SUCCESS",
    API_GET_CLIENT_LOAD: "API_GET_CLIENT_LOAD",
    API_GET_CLIENT_SUCCESS: "API_GET_CLIENT_SUCCESS",
    API_GET_LEFT_MENU_LOAD: "API_GET_LEFT_MENU_LOAD",
    API_GET_LEFT_MENU_SUCCESS: "API_GET_LEFT_MENU_SUCCESS",
    GET_SELECTED_PRODUCT:"GET_SELECTED_PRODUCT",
    API_GET_ADHOC_CLIENT_DATA_LOAD:"API_GET_ADHOC_CLIENT_DATA_LOAD",
    API_GET_ADHOC_CLIENT_DATA_SUCCESS:"API_GET_ADHOC_CLIENT_DATA_SUCCESS",
    API_GET_ADHOC_SAVE_DATA_LOAD:"API_GET_ADHOC_SAVE_DATA_LOAD",
    API_GET_ADHOC_SAVE_DATA_SUCCESS:"API_GET_ADHOC_SAVE_DATA_SUCCESS",
    API_GET_ADHOC_EXPENSE_NO_LOAD:"API_GET_ADHOC_EXPENSE_NO_LOAD",
    API_GET_ADHOC_EXPENSE_NO_SUCCESS:"API_GET_ADHOC_EXPENSE_NO_SUCCESS",
    API_GET_ADHOC_EXPAND_DATA_LOAD:"API_GET_ADHOC_EXPAND_DATA_LOAD",
    API_GET_ADHOC_EXPAND_DATA_SUCCESS:"API_GET_ADHOC_EXPAND_DATA_SUCCESS",
    API_UPDATE_ADHOC_LOAD:"API_UPDATE_ADHOC_LOAD",
    API_UPDATE_ADHOC_SUCCESS:"API_UPDATE_ADHOC_SUCCESS",
    API_GET_CHANNEL_STATUS_LOAD: "API_GET_CHANNEL_STATUS_LOAD",
    API_GET_CHANNEL_STATUS_SUCCESS: "API_GET_CHANNEL_STATUS_SUCCESS",
    API_GET_CHANNEL_DETAILS_LOAD: "API_GET_CHANNEL_DETAILS_LOAD",
    API_GET_CHANNEL_DETAILS_SUCCESS: "API_GET_CHANNEL_DETAILS_SUCCESS",
    API_GET_PICKER_LOAD: "API_GET_PICKER_LOAD",
    API_GET_PICKER_SUCCESS: "API_GET_PICKER_SUCCESS",
    API_GET_SKU_SUBORDER_LOAD: "API_GET_SKU_SUBORDER_LOAD",
    API_GET_SKU_SUBORDER_SUCCESS: "API_GET_SKU_SUBORDER_SUCCESS",
    API_GENERATE_PICKLIST_LOAD: "API_GENERATE_PICKLIST_LOAD",
    API_GENERATE_PICKLIST_SUCCESS: "API_GENERATE_PICKLIST_SUCCESS",
    API_PICKING_COMPLETE_LOAD: "API_PICKING_COMPLETE_LOAD",
    API_PICKING_COMPLETE_SUCCESS: "API_PICKING_COMPLETE_SUCCESS",
    API_SCAN_PICKED_LOAD: "API_SCAN_PICKED_LOAD",
    API_SCAN_PICKED_SUCCESS: "API_SCAN_PICKED_SUCCESS",
    API_GET_COURIER_SERVICE_LOAD: "API_GET_COURIER_SERVICE_LOAD",
    API_GET_COURIER_SERVICE_SUCCESS: "API_GET_COURIER_SERVICE_SUCCESS",
    API_GET_SHOW_ORDERS_LOAD: "API_GET_SHOW_ORDERS_LOAD",
    API_GET_SHOW_ORDERS_SUCCESS: "API_GET_SHOW_ORDERS_SUCCESS",
    API_SCAN_SKU_LOAD:"API_SCAN_SKU_LOAD",
    API_SCAN_SKU_SUCCESS:"API_SCAN_SKU_SUCCESS",
    API_POPUP_SCAN_SKU_LOAD:"API_POPUP_SCAN_SKU_LOAD",
    API_POPUP_SCAN_SKU_SUCCESS:"API_POPUP_SCAN_SKU_SUCCESS",
    API_CREATE_MANIFEST_LOAD: "API_CREATE_MANIFEST_LOAD",
    API_CREATE_MANIFEST_SUCCESS: "API_CREATE_MANIFEST_SUCCESS",
    API_COMPLETE_PACKING_LOAD:"API_COMPLETE_PACKING_LOAD",
    API_COMPLETE_PACKING_SUCCESS:"API_COMPLETE_PACKING_SUCCESS",
    API_GET_CONTINUE_PUTAWAY_LOAD:"API_GET_CONTINUE_PUTAWAY_LOAD",
    API_GET_CONTINUE_PUTAWAY_SUCCESS:"API_GET_CONTINUE_PUTAWAY_SUCCESS",    API_VALIDATE_GATEOUT_BOX_COUNT_LOAD: "API_VALIDATE_GATEOUT_BOX_COUNT_LOAD",
    API_VALIDATE_GATEOUT_BOX_COUNT_SUCCESS: "API_VALIDATE_GATEOUT_BOX_COUNT_SUCCESS",
    API_SHIPMENT_OTP_LOAD: "API_SHIPMENT_OTP_LOAD",
    API_SHIPMENT_OTP_SUCCESS: "API_SHIPMENT_OTP_SUCCESS",
    API_HAND_OVER_MANIFEST_ACTION_LOAD: "API_HAND_OVER_MANIFEST_ACTION_LOAD",
    API_HAND_OVER_MANIFEST_ACTION_SUCCESS: "API_HAND_OVER_MANIFEST_ACTION_SUCCESS",
    API_GET_SALES_ORDER_CLIENT_LOAD: "API_GET_SALES_ORDER_CLIENT_LOAD",
    API_GET_SALES_ORDER_CLIENT_SUCCESS: "API_GET_SALES_ORDER_CLIENT_SUCCESS",
    API_GET_DETAILS_FOR_SO_LOAD: "API_GET_DETAILS_FOR_SO_LOAD",
    API_GET_DETAILS_FOR_SO_SUCCESS: "API_GET_DETAILS_FOR_SO_SUCCESS",
    API_GET_SALES_ORDERS_TYPE_LOAD: "API_GET_SALES_ORDERS_TYPE_LOAD",
    API_GET_SALES_ORDERS_TYPE_SUCCESS: "API_GET_SALES_ORDERS_TYPE_SUCCESS",
    API_CREATE_SO_LOAD: "API_CREATE_SO_LOAD",
    API_CREATE_SO_SUCCESS: "API_CREATE_SO_SUCCESS",
    API_GET_CUSTOMER_LIST_LOAD:"API_GET_CUSTOMER_LIST_LOAD",
    API_GET_CUSTOMER_LIST_SUCCESS:"API_GET_CUSTOMER_LIST_SUCCESS",
    API_GET_SKU_LIST_LOAD:"API_GET_SKU_LIST_LOAD",
    API_GET_SKU_LIST_SUCCESS:"API_GET_SKU_LIST_SUCCESS",
    API_GET_LOCATION_QTY_LOAD:"API_GET_LOCATION_QTY_LOAD",
    API_GET_LOCATION_QTY_SUCCESS:"API_GET_LOCATION_QTY_SUCCESS",
    API_GET_STOCK_DATA_LOAD:"API_GET_STOCK_DATA_LOAD",
    API_GET_STOCK_DATA_SUCCESS:"API_GET_STOCK_DATA_SUCCESS",
    API_GET_SALES_ORDER_STATUS_DETAILS_LOAD: "API_GET_SALES_ORDER_STATUS_DETAILS_LOAD",
    API_GET_SALES_ORDER_STATUS_DETAILS_SUCCESS: "API_GET_SALES_ORDER_STATUS_DETAILS_SUCCESS",
    API_LOCK_AND_PROCESS_LOAD: "API_LOCK_AND_PROCESS_LOAD",
    API_LOCK_AND_PROCESS_SUCCESS: "API_LOCK_AND_PROCESS_SUCCESS",
    API_GET_UPDATE_ATTRIBUTE_LOAD:"API_GET_UPDATE_ATTRIBUTE_LOAD",
    API_GET_UPDATE_ATTRIBUTE_SUCCESS:"API_GET_UPDATE_ATTRIBUTE_SUCCESS",
    API_CANCEL_SALES_ORDER_LOAD: "API_CANCEL_SALES_ORDER_LOAD",
    API_CANCEL_SALES_ORDER_SUCCESS: "API_CANCEL_SALES_ORDER_SUCCESS",
    API_SEARCH_PURCHASE_ORDER_LOAD:"API_SEARCH_PURCHASE_ORDER_LOAD",
    API_SEARCH_PURCHASE_ORDER_SUCCESS:"API_SEARCH_PURCHASE_ORDER_SUCCESS",
    API_WM_CONFIGURE_LOAD:"API_WM_CONFIGURE_LOAD",
    API_WM_CONFIGURE_SUCCESS:"API_WM_CONFIGURE_SUCCESS",
    API_WM_ADD_LOAD:"API_WM_ADD_LOAD",
    API_WM_ADD_SUCCESS:"API_WM_ADD_SUCCESS",
    API_WM_EDIT_LOAD:"API_WM_EDIT_LOAD",
    API_WM_EDIT_SUCCESS:"API_WM_EDIT_SUCCESS",
    API_GET_CATEGORY_LIST_LOAD: "API_GET_CATEGORY_LIST_LOAD",
    API_GET_CATEGORY_LIST_SUCCESS: "API_GET_CATEGORY_LIST_SUCCESS",
    API_CREATE_CATEGORY_LOAD: "API_CREATE_CATEGORY_LOAD",
    API_CREATE_CATEGORY_SUCCESS: "API_CREATE_CATEGORY_SUCCESS",
    API_GET_LOCATION_DATA_LOAD:"API_GET_LOCATION_DATA_LOAD",
    API_GET_LOCATION_DATA_SUCCESS:"API_GET_LOCATION_DATA_SUCCESS",
    API_CONFIGURE_DATA_LOAD:"API_CONFIGURE_DATA_LOAD",
    API_CONFIGURE_DATA_SUCCESS:"API_CONFIGURE_DATA_SUCCESS",
    API_GET_FORM_DROPDOWN_LOAD: "API_GET_FORM_DROPDOWN_LOAD",
    API_GET_FORM_DROPDOWN_SUCCESS: "API_GET_FORM_DROPDOWN_SUCCESS",
    API_MOVE_ADJUST_INVENTORY_LOAD:"API_MOVE_ADJUST_INVENTORY_LOAD",
    API_MOVE_ADJUST_INVENTORY_SUCCESS:"API_MOVE_ADJUST_INVENTORY_SUCCESS",
    API_MOVE_QUARANTINE_INVENTORY_LOAD:"API_MOVE_QUARANTINE_INVENTORY_LOAD",
    API_MOVE_QUARANTINE_INVENTORY_SUCCESS:"API_MOVE_QUARANTINE_INVENTORY_SUCCESS",
    API_CHANGE_SKU_LOCATION_LOAD:"API_CHANGE_SKU_LOCATION_LOAD",
    API_CHANGE_SKU_LOCATION_SUCCESS:"API_CHANGE_SKU_LOCATION_SUCCESS",
    API_CHANGE_BIN_BOX_LOCATION_LOAD:"API_CHANGE_BIN_BOX_LOCATION_LOAD",
    API_CHANGE_BIN_BOX_LOCATION_SUCCESS:"API_CHANGE_BIN_BOX_LOCATION_SUCCESS",
    API_VIEW_CONFIGURE_MODAL_DATA_LOAD:"API_VIEW_CONFIGURE_MODAL_DATA_LOAD",
    API_VIEW_CONFIGURE_MODAL_DATA_SUCCESS:"API_VIEW_CONFIGURE_MODAL_DATA_SUCCESS",
    API_CONFIGURE_WAREHOUSE_LOAD: "API_CONFIGURE_WAREHOUSE_LOAD",
    API_CONFIGURE_WAREHOUSE_SUCCESS: "API_CONFIGURE_WAREHOUSE_SUCCESS",
    API_GET_DASHBOARD_MENU_LOAD: "API_GET_DASHBOARD_MENU_LOAD",
    API_GET_DASHBOARD_MENU_SUCCESS: "API_GET_DASHBOARD_MENU_SUCCESS",
    API_GET_ON_BOARDING_EDIT_DATA_LOAD:'API_GET_ON_BOARDING_EDIT_DATA_LOAD',
    API_GET_ON_BOARDING_EDIT_DATA_SUCCESS:"API_GET_ON_BOARDING_EDIT_DATA_SUCCESS",
    API_UPLOAD_FILE_LOAD: "API_UPLOAD_FILE_LOAD",
    API_UPLOAD_FILE_SUCCESS: "API_UPLOAD_FILE_SUCCESS",
    API_VIEW_STORAGE_DETAIL_LOAD: "API_VIEW_STORAGE_DETAIL_LOAD",
    API_VIEW_STORAGE_DETAIL_SUCCESS: "API_VIEW_STORAGE_DETAIL_SUCCESS",
    API_GET_CLIENT_DEDICATED_LOCATION_LOAD: "API_GET_CLIENT_DEDICATED_LOCATION_LOAD",
    API_GET_CLIENT_DEDICATED_LOCATION_SUCCESS: "API_GET_CLIENT_DEDICATED_LOCATION_SUCCESS",
    API_GO_LIVE_LOAD: "API_GO_LIVE_LOAD",
    API_GO_LIVE_SUCCESS: "API_GO_LIVE_SUCCESS",
    API_GET_FILES_LOAD: "API_GET_FILES_LOAD",
    API_GET_FILES_SUCCESS: "API_GET_FILES_SUCCESS",
    API_SET_ATTACHMENT_AS_DEFUALT_LOAD: "API_SET_ATTACHMENT_AS_DEFUALT_LOAD",
    API_SET_ATTACHMENT_AS_DEFUALT_SUCCESS: "API_SET_ATTACHMENT_AS_DEFUALT_SUCCESS",
    API_GET_REPORT_LIST_LOAD:"API_GET_REPORT_LIST_LOAD",
    API_GET_REPORT_LIST_SUCCESS:"API_GET_REPORT_LIST_SUCCESS",
    API_GET_DATE_REPORT_LIST_LOAD:"API_GET_DATE_REPORT_LIST_LOAD",
    API_GET_DATE_REPORT_LIST_SUCCESS:"API_GET_DATE_REPORT_LIST_SUCCESS",
    API_GET_GATEIN_REPORT_LIST_LOAD:"API_GET_GATEIN_REPORT_LIST_LOAD",
    API_GET_GATEIN_REPORT_LIST_SUCCESS:"API_GET_GATEIN_REPORT_LIST_SUCCESS",
    API_GET_EXPAND_GATEIN_REPORT_LIST_LOAD:"API_GET_EXPAND_GATEIN_REPORT_LIST_LOAD",
    API_GET_EXPAND_GATEIN_REPORT_LIST_SUCCESS:"API_GET_EXPAND_GATEIN_REPORT_LIST_SUCCESS",
    API_GET_SEARCH_GATEIN_REPORT_LIST_LOAD:"API_GET_SEARCH_GATEIN_REPORT_LIST_LOAD",
    API_GET_SEARCH_GATEIN_REPORT_LIST_SUCCESS:"API_GET_SEARCH_GATEIN_REPORT_LIST_SUCCESS",
    API_GET_SAN_REPORT_LIST_LOAD:"API_GET_SAN_REPORT_LIST_LOAD",
    API_GET_SAN_REPORT_LIST_SUCCESS:"API_GET_SAN_REPORT_LIST_SUCCESS",
    API_GET_EXPAND_SAN_REPORT_LIST_LOAD:"API_GET_EXPAND_SAN_REPORT_LIST_LOAD",
    API_GET_EXPAND_SAN_REPORT_LIST_SUCCESS:"API_GET_EXPAND_SAN_REPORT_LIST_SUCCESS",
    API_GET_GRN_REPORT_LIST_LOAD:"API_GET_GRN_REPORT_LIST_LOAD",
    API_GET_GRN_REPORT_LIST_SUCCESS:"API_GET_GRN_REPORT_LIST_SUCCESS",
    API_GET_EXPAND_GRN_REPORT_LIST_LOAD:"API_GET_EXPAND_GRN_REPORT_LIST_LOAD",
    API_GET_EXPAND_GRN_REPORT_LIST_SUCCESS:"API_GET_EXPAND_GRN_REPORT_LIST_SUCCESS",
    API_GET_OD_REPORT_LIST_LOAD:"API_GET_OD_REPORT_LIST_LOAD",
    API_GET_OD_REPORT_LIST_SUCCESS:"API_GET_OD_REPORT_LIST_SUCCESS",
    API_GET_EXPAND_OD_REPORT_LIST_LOAD:"API_GET_EXPAND_OD_REPORT_LIST_LOAD",
    API_GET_EXPAND_OD_REPORT_LIST_SUCCESS:"API_GET_EXPAND_OD_REPORT_LIST_SUCCESS",
    API_GET_GAE_REPORT_LIST_LOAD:"API_GET_GAE_REPORT_LIST_LOAD",
    API_GET_GAE_REPORT_LIST_SUCCESS:"API_GET_GAE_REPORT_LIST_SUCCESS",
    API_GET_EXPAND_GAE_REPORT_LIST_LOAD:"API_GET_EXPAND_GAE_REPORT_LIST_LOAD",
    API_GET_EXPAND_GAE_REPORT_LIST_SUCCESS:"API_GET_EXPAND_GAE_REPORT_LIST_SUCCESS",
    API_GET_EXPAND_CARTON_DATA_LOAD:"API_GET_EXPAND_CARTON_DATA_LOAD",
    API_GET_EXPAND_CARTON_DATA_SUCCESS:"API_GET_EXPAND_CARTON_DATA_SUCCESS",
    API_GET_EXPAND_DATA_LOAD:"API_GET_EXPAND_DATA_LOAD",
    API_GET_EXPAND_DATA_SUCCESS:"API_GET_EXPAND_DATA_SUCCESS",
    API_GET_DIMENSION_LOAD:"API_GET_DIMENSION_LOAD",
    API_GET_DIMENSION_SUCCESS:"API_GET_DIMENSION_SUCCESS",
    API_UPDATE_DIMENSION_LOAD:"API_UPDATE_DIMENSION_LOAD",
    API_UPDATE_DIMENSION_SUCCESS:"API_UPDATE_DIMENSION_SUCCESS",
    API_CARTON_REQC_LOAD:"API_CARTON_REQC_LOAD",
    API_CARTON_REQC_SUCCESS:"API_CARTON_REQC_SUCCESS",
    API_CLOSE_CARTON_LOAD:"API_CLOSE_CARTON_LOAD",
    API_CLOSE_CARTON_SUCCESS:"API_CLOSE_CARTON_SUCCESS",
    API_DELETE_CARTON_LOAD:"API_DELETE_CARTON_LOAD",
    API_DELETE_CARTON_SUCCESS:"API_DELETE_CARTON_SUCCESS",
    API_PRINT_SPECIFIC_CARTON_STICKER_LOAD:"API_PRINT_SPECIFIC_CARTON_STICKER_LOAD",
    API_PRINT_SPECIFIC_CARTON_STICKER_SUCCESS:"API_PRINT_SPECIFIC_CARTON_STICKER_SUCCESS",
    API_PRINT_ALL_CARTON_STICKER_LOAD:"API_PRINT_ALL_CARTON_STICKER_LOAD",
    API_PRINT_ALL_CARTON_STICKER_SUCCESS:"API_PRINT_ALL_CARTON_STICKER_SUCCESS",
    API_PRINT_SPECIFIC_CARTON_PACKING_SLIP_LOAD:"API_PRINT_SPECIFIC_CARTON_PACKING_SLIP_LOAD",
    API_PRINT_SPECIFIC_CARTON_PACKING_SLIP_SUCCESS:"API_PRINT_SPECIFIC_CARTON_PACKING_SLIP_SUCCESS",
    API_PRINT_ALL_CARTON_PACKING_SLIP_LOAD:"API_PRINT_ALL_CARTON_PACKING_SLIP_LOAD",
    API_PRINT_ALL_CARTON_PACKING_SLIP_SUCCESS:"API_PRINT_ALL_CARTON_PACKING_SLIP_SUCCESS",
    API_SCAN_CARTON_SKU_LOAD: "API_SCAN_CARTON_SKU_LOAD",
    API_SCAN_CARTON_SKU_SUCCESS:"API_SCAN_CARTON_SKU_SUCCESS",
    API_WORK_ON_THIS_LOAD: "API_WORK_ON_THIS_LOAD",
    API_WORK_ON_THIS_SUCCESS:"API_WORK_ON_THIS_SUCCESS",
    API_MOVE_TO_RTO_LOAD:"API_MOVE_TO_RTO_LOAD",
    API_MOVE_TO_RTO_SUCCESS:"API_MOVE_TO_RTO_SUCCESS",
    API_REQC_SAVE_LOAD: "API_REQC_SAVE_LOAD",
    API_REQC_SAVE_SUCCESS: "API_REQC_SAVE_SUCCESS",
    CLEAR_REDUCER: "CLEAR_REDUCER",
    API_CYCLE_COUNT_SCANNING_LOAD: "API_CYCLE_COUNT_SCANNING_LOAD",
    API_CYCLE_COUNT_SCANNING_SUCCESS: "API_CYCLE_COUNT_SCANNING_SUCCESS",
    API_CYCLE_COUNT_MASTER_DATA_LOAD:"API_CYCLE_COUNT_MASTER_DATA_LOAD",
    API_CYCLE_COUNT_MASTER_DATA_SUCCESS:"API_CYCLE_COUNT_MASTER_DATA_SUCCESS",
    API_CYCLE_COUNT_EXPAND_DATA_LOAD:"API_CYCLE_COUNT_EXPAND_DATA_LOAD",
    API_CYCLE_COUNT_EXPAND_DATA_SUCCESS:"API_CYCLE_COUNT_EXPAND_DATA_SUCCESS",
    API_START_CYCLE_COUNT_LOAD: "API_START_CYCLE_COUNT_LOAD",
    API_START_CYCLE_COUNT_SUCCESS: "API_START_CYCLE_COUNT_SUCCESS",
    API_CYCLE_COUNT_STORAGE_SKU_LIST_LOAD:"API_CYCLE_COUNT_STORAGE_SKU_LIST_LOAD",
    API_CYCLE_COUNT_STORAGE_SKU_LIST_SUCCESS:"API_CYCLE_COUNT_STORAGE_SKU_LIST_SUCCESS",
    API_CYCLE_COUNT_STORAGE_SKU_SELECT_LOAD:"API_CYCLE_COUNT_STORAGE_SKU_SELECT_LOAD",
    API_CYCLE_COUNT_STORAGE_SKU_SELECT_SUCCESS:"API_CYCLE_COUNT_STORAGE_SKU_SELECT_SUCCESS",
    API_CYCLE_COUNT_STORAGE_SKU_QTY_UPDATE_LOAD:"API_CYCLE_COUNT_STORAGE_SKU_QTY_UPDATE_LOAD",
    API_CYCLE_COUNT_STORAGE_SKU_QTY_UPDATE_SUCCESS:"API_CYCLE_COUNT_STORAGE_SKU_QTY_UPDATE_SUCCESS",
    API_CLOSE_CYCLE_COUNT_LOAD:'API_CLOSE_CYCLE_COUNT_LOAD',
    API_CLOSE_CYCLE_COUNT_SUCCESS:"API_CLOSE_CYCLE_COUNT_SUCCESS",
    API_CLOSE_CYCLE_APPROVE_REJECT_LOAD:"API_CLOSE_CYCLE_APPROVE_REJECT_LOAD",
    API_CLOSE_CYCLE_APPROVE_REJECT_SUCCESS:"API_CLOSE_CYCLE_APPROVE_REJECT_SUCCESS",
    API_CREATE_CLIENT_LOAD:"API_CREATE_CLIENT_LOAD",
    API_CREATE_CLIENT_SUCCESS:"API_CREATE_CLIENT_SUCCESS",
    API_EDIT_CLIENT_LOAD:"API_EDIT_CLIENT_LOAD",
    API_EDIT_CLIENT_SUCCESS:"API_EDIT_CLIENT_SUCCESS",
    API_CREATE_CONTRACT_LOAD:"API_CREATE_CONTRACT_LOAD",
    API_CREATE_CONTRACT_SUCCESS:"API_CREATE_CONTRACT_SUCCESS",
    API_CLIENT_INFO_LOAD:"API_CLIENT_INFO_LOAD",
    API_CLIENT_INFO_SUCCESS:"API_CLIENT_INFO_SUCCESS",
    API_START_ONBOARDING_LOAD:"API_START_ONBOARDING_LOAD",
    API_START_ONBOARDING_SUCCESS:"API_START_ONBOARDING_SUCCESS",
    API_GET_MOVE_TO_BIN_META_DATA_LOAD:"API_GET_MOVE_TO_BIN_META_DATA_LOAD",
    API_GET_MOVE_TO_BIN_META_DATA_SUCCESS:"API_GET_MOVE_TO_BIN_META_DATA_SUCCESS"


}
export default ApiConstants;