import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Layout, message, Row, Select } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import { AppModuleIds } from '../../Globals/AppReferenceIds';
import AppReferences from '../../Globals/AppReferences';
import { goLiveAction } from '../../Store/Actions/ProcessWMSAction';
import { getClientId, getDashboardMenuId, getUserInfo, getWareHouseId, setDashboardMenuId } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import SpinLoader from '../SpinLoader';
import './OnBoarding.scss';
import OnBoardingMenu from './OnBoardingMenu';

function OnBoardingIndex(props: any) {
    const [menuId, setMenuId] = useState<any>('');
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState({
        addCategory: false,
        addSKU: false,
        isEnableAddCategory: false
    });
    const reducerState = useSelector((state: any) => state.ProcessReducerState);
    const { inventorySkuData, categoryInfo, liveEnable } = reducerState;
    const clientInfo = getClientId();
    const dispatch = useDispatch();
    const [onload, setOnload] = useState({
        liveOnload: false
    });

    useEffect(() => {
        let path = window.location.pathname?.split('/');
        if (path[2] == 'SKUs') {
            setMenuId('2');
        }
        else {
            setMenuId('1')
        }
        return () => {
            setDashboardMenuId('')
        };
    }, []);

    useEffect(() => {
        if (onload.liveOnload && !reducerState.onload) {
            setOnload({ ...onload, liveOnload: false });
            if (liveEnable.msg == "Client Successfully went Live") {
                message.success(liveEnable.msg);
                navigate(-1);
            }
            else {
                message.error(liveEnable.msg)
            }
        }
    }, [onload.liveOnload, reducerState.onload]);

    const goLive = () =>{
        let user = getUserInfo();
        let payload = {
            opnfor: AppReferences.opnfor.onboard,
            activity: AppReferences.activities.a08,
            transaction: `Status = '1008', ModifiedBy = '${user?.UserName}', ModifiedDate = now()`,
            where:`ContractID = '${clientInfo?.ContractID}' AND ClientID = '${clientInfo?.ClientID}' AND Warehouse = '${clientInfo?.WarehouseId}'`,
            tableNo:"77",
            fieldArray: clientInfo?.ContractID,
            warehouse: clientInfo?.WarehouseId,
            customer: clientInfo?.ClientID,
            user: user?.UserName,

        }
        dispatch(goLiveAction(payload));
        setOnload({...onload, liveOnload: true})
    }


    const openAddScreen = () => {
        if (menuId == '1') setOpenDrawer({ ...openDrawer, isEnableAddCategory: true });
        else setOpenDrawer({ ...openDrawer, addSKU: true });
    }

    const headerview = () => {
        return (
            <div className='header-view'>
                <div>
                    <AppButtons
                        onClick={() => openAddScreen()}
                        text={menuId == '1' ? 'Add Cateogry' : 'Add SKU'}
                        buttonType={AppConstants.buttonTypes.whiteButton}
                        block={true}
                    />
                </div>
                <div className='ml-10'>
                    <Button className="close-btn-3" onClick={() => navigate('/on-boarding/dashboard')}>
                        <CloseOutlined />
                    </Button>
                    {/* <img onClick={() => navigate('/on-boarding/dashboard')} className="c-p"  src={AppImages.closeimg} alt='' /> */}
                </div>
            </div>
        )
    }

    const onMenuChange = (e: any) => {
        setMenuId(e.key);
        setDashboardMenuId(e.key)
    }

    return (
        <Layout>
            <NavbarLayout
                fromOrigin='onboarding'
                goLive={goLive}
                process={menuId == '1' ? categoryInfo?.title : inventorySkuData?.title}
                initial={AppModuleIds.main}
                selectedMain={'9'}
            />
            <Layout>
                <Content className="antd-content-white">
                    <Row>
                        <Col span={3}>
                            <OnBoardingMenu
                                selectedKey={menuId}
                                onChange={onMenuChange}
                            />
                        </Col>
                        <Col span={21} className='content-view'>
                            <SubHeader
                                // className='subheader'
                                title={
                                    <div>
                                        <span className='font-18 redhat-bold'></span>{menuId == '1' ? AppConstants.category : 'SKUs'}
                                    </div>
                                }
                                btnView="true"
                            >
                                {headerview()}
                            </SubHeader >
                            <Outlet context={[openDrawer, setOpenDrawer]} />
                            <SpinLoader loading={onload.liveOnload} />
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </Layout>
    );
}

export default OnBoardingIndex;