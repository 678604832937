import { DatabaseOutlined } from '@ant-design/icons';
import { Layout} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import { AppModuleIds } from '../../Globals/AppReferenceIds';
import AppReferences from '../../Globals/AppReferences';
import {  isArrayNotEmpty } from '../../Globals/Helper';
import AddNewGateInIcon from '../../Images/AddNewGateInIcon';
import AdhocExpense from '../../Images/AdhocExpenseIcon';
import CycleCountIcon from '../../Images/CycleCountIcon';
import DashBoardIcon from '../../Images/DashBoardIcon';
import GateInEntryIcon from '../../Images/GateInEntryIcon';
import GRNIcon from '../../Images/GRNIcon';
import GrnIcon2 from '../../Images/GrnIcon2';
import HomeIcon from '../../Images/HomeIcon';
import InventoryManagement from '../../Images/InventoryManagement';
import InventryManagementIconNew from '../../Images/InventryManagementIconNew';
import InwardIcon from '../../Images/InwardIcon';
import MenuCloseIcon from '../../Images/MenuCloseIcon';
import MenuIcon from '../../Images/MenuIcon';
import OmsIcon from '../../Images/OmsIcon';
import OnBoarding from '../../Images/OnBoardingIcon';
import PurchaseOrder from '../../Images/PurchaseOrderIcon';
import PutAwayIcon360 from '../../Images/PutAwayIcon360';
import QCIcon from '../../Images/QCIcon';
import SanIcon2 from '../../Images/SanIcon2';
import WarehouseIcon from '../../Images/WarehouseIcon';
import WMSIcon2 from '../../Images/WMSIcon2';
import { getProcessData, getStageDetailsAction } from '../../Store/Actions/ProcessWMSAction';
import { clearStorage, getDashboardMenuId, getMenuName, getProcess, getUserInfo, getWareHouseId, getWarehouseInfo, setMenuName, } from '../../Utils/SessionStorage';
import SearchScreen from './SearchScreen';
import './SideSubMenu.scss';

const { Sider } = Layout;

interface DataType {
    setValue?: any,
    clientData?: any,
    tatData?: any,
    clientValue?: any,
    setClientValue?: any
    setTatValue?: any
    tatValue?: any
    intialMenu?: any,
    pageRefresh?: any,
    menus?: any,
    channelList?: any,
    setChannel?: any,
    channel?: any,
    setProcess?: any,
    process?: any,
    channelStatus?: any,
    selectedOms?: string,
    selectedGateIn?: string
    selectedMain?: any,
    warehouse?: any,
    tatClient?:any,

}
function SideSubmenu(props: DataType) {
    const {
        setValue,
        intialMenu,
        pageRefresh,
        clientData,
        tatData,
        clientValue,
        setClientValue,
        setTatValue,
        tatValue,
        menus,
        channelList,
        setChannel,
        channel,
        setProcess,
        process,
        channelStatus,
        selectedOms,
        selectedGateIn,
        selectedMain,
        tatClient
    } = props;
    let user = getUserInfo();
    const dispatch = useDispatch();
    const { processDataInfo, stageDetails, stageOnload, processLoad }: any = useSelector((state: any) => state.ProcessReducerState);
    const menu = processDataInfo?.header;
    const wmsSubMenu = stageDetails?.header
    const [updatedMenu, setUpdatedMenu] = useState<any>([]);
    const [stagesOnload, setStagesOnload] = useState(false);
    const [seletedProcess, setSelectedProcess] = useState<any>("");
    const [enableSubmenu, setEnableSubMenu] = useState<any>(false);
    const [seletedState, setSeletedState] = useState<any>();
    const [selectedMainMenu, setSelectedMainMenu] = useState<any>(intialMenu);
    const [showPopupScreen, setShowPopupScreen] = useState<any>(false);
    const [indicator, setIndicator] = useState<any>("");
    const [tatName, setTatName] = useState<any>("");
    const [clientName, setClientName] = useState<any>("");
    const [channelName, setChannelName] = useState<any>("");
    const [selectedStatus, setSelectedStatus] = useState<any>('');
    const [processOnload, setProcessOnlaod] = useState(false);

    console.log("getWarehouseInfo",getWarehouseInfo)

    const navigate = useNavigate();

    const gateInData: any = [
        {
            key: 1,
            label: "GateIn Entries",
            icon: <GateInEntryIcon />,
            path: "/gateinentries"
        },
        {
            key: 2,
            label: "Add New GateIn",
            icon: <AddNewGateInIcon />,
            path: "/gate-in-add"
        }
    ];

    const mainData: any = [
        {
            key: 1,
            label: "Gate In",
            icon: <HomeIcon />,
            path: '/gateinentries'
        },
        {
            key: 2,
            label: "WMS",
            icon: <WarehouseIcon />,
            path: '/process/SAN'
        },
        {
            key: 3,
            label: "OMS",
            icon: <OmsIcon />,
            path: '/order-processing/verified'
        },
        {
            key: 5,
            label: "Create PO",
            icon: <PurchaseOrder />,
            path: '/create-po'
        },
        {
            key: 6,
            label: "Stock Movement",
            icon: < InventoryManagement />,
            path: '/stockmoment'
        },
        {
            key: 7,
            label: "Inventory Management",
            icon: <InventryManagementIconNew />,
            path: '/inventory-management'
        },
        {
            key: 8,
            label: "Warehouse Master",
            icon: < InventoryManagement />,
            path: '/warehouse-master'
        },
        {
            key: 9,
            label: "Onboarding",
            icon: <OnBoarding />,
            path: '/on-boarding/client'
        },
        {
            key: 11,
            label: "General Admin Expenses",
            icon: <AdhocExpense />,
            path: '/admin-expense'
        },
        {
            key: 10,
            label: "Cycle Count",
            icon: <CycleCountIcon />,
            path: '/cycle-count'
        },
        {
            key: 12,
            label: "Reports",
            icon: <DatabaseOutlined />,
            path: '/reports'
        },
    ];

    const omsData: any = [
        {
            key: '101',
            label: "Dashboard",
            icon: <DashBoardIcon />,
            path: ''
        },
        {
            key: '102',
            label: "Sales Order",
            icon: <SanIcon2 />,
            path: '/order-processing/verified'
        },
        {
            key: '103',
            label: "Create Sales Order",
            icon: <SanIcon2 />,
            path: '/sales-order'
        },

    ];

    const wmsHeaders: any = [
        {
            name: "Dashboard",
            label: "Dashboard",
            icon: <DashBoardIcon />,
            path: ""
        },
        {
            name: "Shipment Arrival Notice",
            label: AppConstants.san,
            icon: <SanIcon2 />,
            path: "/process/SAN",
            sublinks: []
        },
        {
            name: AppConstants.inward,
            label: AppConstants.inward,
            icon: <InwardIcon />,
            path: "/process/QC",
            sublinks: []
        },
        {
            name: AppConstants.customStickering,
            label: AppConstants.customStickering,
            icon: <QCIcon />,
            path: "/process/custom-stickering"
        },
        {
            name: "Putaway",
            label: AppConstants.putAway,
            icon: <PutAwayIcon360 />,
            path: "/process/put-away",
            sublinks: []
        },
        {
            name: AppConstants.grn,
            label: AppConstants.grn,
            icon: <GrnIcon2 />,
            path: "/process/GRN",
            sublinks: []
        },
        {
            name: AppConstants.outward,
            label: AppConstants.outward,
            icon: <GRNIcon />,
            path: "/process/kitting"
        },
        {
            name: 'RTO (B2B)',
            label: 'RTO (B2B)',
            icon: <GRNIcon />,
            path: "/process/rto-b2b"
        },
    ];

    useEffect(() => {
        if (selectedMainMenu === AppModuleIds.wms) {
            processData();
        }
    }, [selectedMainMenu]);

    useEffect(() => {
        if (processOnload && !processLoad) {
            setProcessOnlaod(false);
            setMenu();
        }
    }, [processOnload, processLoad])

    useEffect(() => {
        if (pageRefresh == true) {
            if (selectedMainMenu === AppModuleIds.wms) {
                getStages();
            }
        }
    }, [pageRefresh])

    const processData = () => {
        try {
            let payload = {
                "activity": AppReferences.activities.av,
                "warehouse": getWareHouseId(),
                "opnfor": AppReferences.opnfor.wms,
                "user": getUserInfo()?.UserName,
                "fieldarray": getUserInfo()?.roleId,
            }
            dispatch(getProcessData(payload));
            setProcessOnlaod(true);
        } catch (error) {
            console.log("Error in processData", error)
        }
    }

    useEffect(() => {
        getStages();
    }, [seletedProcess])

    const getStages = () => {
        try {
            let payload = {
                "activity": AppReferences.activities.av2,
                "warehouse": getWareHouseId(),
                "opnfor": AppReferences.opnfor.wms,
                "where": seletedProcess,
                "user": getUserInfo()?.UserName,
                "fieldarray": getUserInfo()?.roleId,
            }
            dispatch(getStageDetailsAction(payload))
            setStagesOnload(true);
        } catch (error) {
            console.log("Error in getStatges:", error)
        }
    }

    useEffect(() => {
        if (isArrayNotEmpty(stageDetails?.header)) {
            setStagesOnload(false);
            setValue && setValue(null);
            let exist = stageDetails?.header?.find((x: any) => x.id == getDashboardMenuId());
            if (getDashboardMenuId() && exist) {
                setValue && setValue(getDashboardMenuId());
                setSeletedState(getDashboardMenuId())
            }
            else {
                setValue && setValue(stageDetails?.header[0]?.id);
                setSeletedState(stageDetails?.header[0]?.id)
            }
        }
    }, [wmsSubMenu]);

    useEffect(() => {
        if (stageOnload == false && stagesOnload == true) {
            setEnableSubMenu(stageOnload == false && stagesOnload == true);
        }
    }, [stageOnload, stagesOnload])

    useEffect(() => {
        try {
            let temp: any = [];
            (menu || []).map((x: any) => {
                wmsHeaders?.map((y: any) => {
                    if (x.name == y.name) {
                        x["key"] = x.id;
                        x.label = y.label;
                        x['icon'] = y.icon;
                        x['path'] = y.path;
                    }
                });
                temp.push(x);
            });
            setUpdatedMenu(temp);
        } catch (error) {
            console.log("Error in sider bar");
        }
    }, [menu]);

    useEffect(() => {
        setValue && setValue(seletedState);
    }, [seletedState]);

    const openWMSHandler = (e: any) => {
        setSelectedProcess(e);
        let obj = updatedMenu?.find((x: any) => e == x?.key);
        navigate(obj?.path);
    }
    const handleSubMenu = (e: any) => {
        setSeletedState(e);
    }

    const setMenu = () => {
        try {
            let menu = processDataInfo?.header;
            const checkProcessExist = (id: any) => {
                let exist = menu.find((x: any) => x.id == id);
                if (exist) {
                    return true
                }
                else {
                    setSelectedProcess(menu[0]?.id);
                    let menuObj = wmsHeaders?.find((item: any) => item?.name == menu[0]?.name);
                    console.log("menuObj", menuObj)
                    navigate(menuObj?.path);
                    return false;
                }
            }
            let path = window.location.pathname.split("/");
            if (path[2] == "SAN") { // these conditions is to set the side menu while refresh
                if (checkProcessExist("20")) {
                    setSelectedProcess('20');
                }
            }
            else if (path[2] == "QC") {
                if (checkProcessExist("30")) {
                    setSelectedProcess('30');
                }
            }
            else if (path[2] == "custom-stickering") {
                if (checkProcessExist("33")) {
                    setSelectedProcess('33');
                }
            }
            else if (path[2] == "GRN") {
                if (checkProcessExist("45")) {
                    setSelectedProcess('45');
                }
            }
            else if (path[2] == "kitting") {
                if (checkProcessExist("50")) {
                    setSelectedProcess('50');
                }
            }
            else if (path[2] == "rto-b2b") {
                if (checkProcessExist("29")) {
                    setSelectedProcess('29');
                }
            }
            else {
                if (checkProcessExist("40")) {
                    setSelectedProcess('40');
                }
            }
        } catch (error) {
            console.log("Error in setMenu::", error)
        }
    }

    const mainMenuHandler = (e: any) => {
        let obj = mainData.find((x: any) => x.key == e);
        setMenuName(obj.label);
        setSelectedMainMenu(obj.key);
        setSeletedState("");
        obj.path && navigate(obj.path);
    }


    const omsMenuHandler = (e: any) => {
        setShowPopupScreen(false);
        let obj = omsData.find((x: any) => e == x.key)
        navigate(obj.path);
        // setSelectedOms(e);
    }

    const handleClientTat = (e: any) => {
        setIndicator(e);
        setShowPopupScreen(true);
    }

    const handleChannel = (e: any) => {
        setIndicator(e);
        setShowPopupScreen(true);
    }

    const handleChannelStatus = (e: any) => {
        setShowPopupScreen(false);
        setSelectedStatus(e)
        setProcess(e);
        let menu = menus?.find((x: any) => x.id == e);
        navigate(menu?.path);
    }

    useEffect(() => {
        if (clientValue != '') {
            let obj = clientData?.find((x: any) => x?.Id == clientValue);
            setClientName(obj?.Name);
        }
    }, [clientValue])

    useEffect(() => {
        if (tatValue != '') {
            let obj = tatData && tatData?.find((x: any) => x?.Id == tatValue);
            setTatName(obj?.Name);
        }
    }, [tatValue])

    useEffect(() => {
        if (channel != null) {
            let obj = channelList?.find((x: any) => x?.id == channel);
            setChannelName(obj?.name);
        }
        else if (channel == '' || channel == undefined) {
            setChannelName('');
        }
    }, [channel]);

    useEffect(() => {
        if (isArrayNotEmpty(channelStatus) && (selectedMainMenu == AppModuleIds.oms)) {
            let obj = channelStatus[0];
            setSelectedStatus(obj?.id)
            setProcess(obj?.id);
            let menu = menus?.find((x: any) => x.id == obj?.id);
            navigate(menu?.path);
        }

    }, [channelStatus])


    const handleMainMenu = () => {
        setShowPopupScreen(false);
        setSelectedMainMenu('0')
    }


    return (
        <div style={{
            height: "100%", width: "100%"
        }}>
            < div className='menubar-container' >
                <div className='app-icon d-flex align-c jc-c p-5'>
                    <WMSIcon2 />
                </div>
                <div className='wms-container'>
                    <div className='d-flex align-c'>
                        {selectedMainMenu != '0' ? <div
                            onClick={() => handleMainMenu()}
                            className='icon-backbround  c-p'
                        >
                            <MenuIcon />
                        </div>
                            :
                            <div
                                onClick={() => setSelectedMainMenu(AppModuleIds.wms)}
                                className='icon-backbround c-p'>
                                <MenuCloseIcon />
                            </div>
                        }
                        <div className='wms ml-10 overflow-ellipse'>
                            {(selectedMainMenu == (AppModuleIds.wms)) ? "WMS" : getMenuName()}
                        </div>
                    </div>
                </div>
                <div className='bottom-line-container'></div>
                {/* <aside className='asside'> */}
                    <div className='submenu-container'>
                        {selectedMainMenu === AppModuleIds.wms && (updatedMenu || []).map((item: any) => {
                            return (
                                <>
                                    {/* <Link to={item?.path}> */}
                                    <div className='wms-main-container' onClick={() => openWMSHandler(item?.key)} >
                                        <div className={item?.key == seletedProcess ? 'icon-container-seleted d-flex align-c' : 'icon-container d-flex align-c'}>
                                            {item?.icon}
                                        </div>
                                        <div className={item?.key == seletedProcess ? 'color-orange ml-10' : "color-white ml-10"}>
                                            {item?.label}
                                        </div>
                                    </div>
                                    {/* </Link> */}
                                    {(enableSubmenu == true) && ((item?.key == seletedProcess) &&
                                        (wmsSubMenu || []).map((item1: any) => {
                                            return (
                                                <div className={seletedState == item1?.id ? 'wms-submenu-container-selected' : 'wms-submenu-container'} onClick={() => handleSubMenu(item1?.id)}>
                                                    <div className='inner-menu'>{item1?.name}</div>
                                                    <div className={seletedState == item1?.id ? 'menu-count-bg-selected' : 'menu-count-bg'}>{item1?.cnt}</div>
                                                </div>
                                            )
                                        }))
                                    }
                                </>
                            )
                        })}

                        {(selectedMainMenu === AppModuleIds.oms) && (omsData || []).map((item: any) => {
                            return (
                                <>
                                    <div className='wms-main-container' onClick={() => omsMenuHandler(item?.key)} >
                                        <div className={item?.key == selectedOms ? 'icon-container-seleted d-flex align-c' : 'icon-container d-flex align-c'}>
                                            {item?.icon}
                                        </div>
                                        <div className={item?.key == selectedOms ? 'color-orange ml-10' : "color-white ml-10"}>
                                            {item?.label}
                                        </div>
                                    </div>
                                    {selectedOms === item.key && (selectedOms == '102' &&
                                        <>
                                            <div className='oms-client-tat' onClick={() => handleClientTat("client")}>
                                                <div className='inner-menu'>
                                                    Client: {clientName}
                                                </div>
                                                <div className='menu-count-bg'>
                                                    {'>'}
                                                </div>
                                            </div>
                                            <div className='oms-client-tat' onClick={() => handleClientTat("tat")}>
                                                <div className='inner-menu'>
                                                    TAT: {tatName}
                                                </div>
                                                <div className='menu-count-bg'>
                                                    {'>'}
                                                </div>
                                            </div>
                                            <div className='oms-client-tat' onClick={() => handleChannel('channel')}>
                                                <div className='inner-menu'>
                                                    Channel: {channelName}
                                                </div>
                                                <div className='menu-count-bg'>
                                                    {'>'}
                                                </div>
                                            </div>
                                            {(channelName != '') && <div>
                                                {(channelStatus || []).map((data: any) => {
                                                    return (
                                                        <div
                                                            className={process == data?.id ? 'wms-submenu-container-selected' : 'wms-submenu-container'}
                                                            onClick={() => handleChannelStatus(data?.id)}>
                                                            <div className='inner-menu'>{data?.name}</div>
                                                            <div className={process == data.id ? 'menu-count-bg-selected' : 'menu-count-bg'}>{data?.cnt == '-' ? '0' : data?.cnt}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>}
                                        </>)
                                    }
                                </>
                            )
                        })}
                        {(selectedMainMenu == AppModuleIds.gateIn) && (gateInData || []).map((item: any) => {
                            return (
                                <Link to={item?.path} state={{ Option: "Add" }}>
                                    <div className='wms-main-container'>
                                        <div className={selectedGateIn == item?.key ? 'icon-container-seleted d-flex align-c' : 'icon-container d-flex align-c'}>
                                            {item?.icon}
                                        </div>
                                        <div className={selectedGateIn == item?.key ? 'color-orange ml-10' : "color-white ml-10"}>
                                            {item?.label}
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}

                        {(selectedMainMenu == AppModuleIds.main) &&
                            ((mainData || []).map((item: any) => {
                                return (
                                    <div className='wms-main-container' onClick={() => mainMenuHandler(item.key)}>
                                        <div className={selectedMain == item?.key ? 'icon-container-seleted d-flex align-c' : 'icon-container d-flex align-c'}>
                                            {item?.icon}
                                        </div>
                                        <div className={selectedMain == item?.key ? 'color-orange ml-10' : "color-white ml-10"}>
                                            {item?.label}
                                        </div>
                                    </div>
                                )
                            }))
                        }
                    </div>
                    <div className='user-detail-container'>
                        <div className='warehouse-container'>
                            <div className='d-flex align-c'>
                                <img src={AppImages.warehouseIcon} alt='' />
                            </div>
                            <div className='warehouse-name'>
                                {getWarehouseInfo()?.name}
                            </div>
                        </div>
                        <div className='d-flex align-c jc-sb mt-10'>
                            <div className='use-detail-container'>
                                <div>
                                    <img src={AppImages.userIcon} alt='' />
                                </div>
                                <div className='name-view ml-10'>
                                    {user?.FirstName}
                                </div>
                            </div>
                            <div className='exit-container' onClick={() => clearStorage()}>
                                <img src={AppImages.exitIcon} alt='' />
                            </div>
                        </div>
                    </div>
                {/* </aside> */}
            </div >
            {(showPopupScreen == true &&
                < div className='search-component-view mfp-move-horizontal' >
                    <SearchScreen
                        setShowPopupScreen={setShowPopupScreen}
                        clientTatData={indicator == 'client' ? clientData : tatData}
                        indicator={indicator}
                        setClientValue={setClientValue}
                        setTatValue={setTatValue}
                        channels={channelList}
                        setChannel={setChannel}
                        menus={menus}
                        clientValue={clientValue}
                        channel={channel}
                        tatClient={tatClient}
                    />
                </div>)
            }
        </ div >
    )
}

export default SideSubmenu;