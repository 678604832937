import { SearchOutlined, EyeFilled, CaretRightOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, InputNumber, Button, Row, Col, Table, DatePicker, Drawer, Form, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import Calender from '../../Images/Calender';
import CSVFile from '../../Images/CSVFile';
import EditIcon from '../../Images/EditIcon';
import { getadhocRecordNumberAction, getAttachmentsAction, getClientData, getDetailedDataAction, getMetaDataAction, getProcessData, getStageDetailsAction, saveExpenseAction, updateExpenseAction } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import moment from 'moment';
import { display } from '@mui/system';
import AppReferences from '../../Globals/AppReferences';
import { AppModuleIds } from '../../Globals/AppReferenceIds';
import { AppImages } from '../../Globals/AppImages';
import Dragger from 'antd/lib/upload/Dragger';
import DownloadIcon from '../../Images/DownloadIcon';
import { generateGuId, isArrayNotEmpty } from '../../Globals/Helper';
import { Amplify, Storage } from "aws-amplify";
import ImgModelView from '../ImgModelView';
import NoData from '../../CustomComponent/NoData';


Amplify.configure({
    Auth: {
        identityPoolId: "ap-south-1:0fed2e35-15bf-46d3-b794-c938dc515509", //REQUIRED - Amazon Cognito Identity Pool ID
        region: "ap-south-1", // REQUIRED - Amazon Cognito Region
        userPoolId: "ap-south-1_wGjzBalCU", //OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: "1ptdtdunusepaj6440qh7c11b2", //OPTIONAL - Amazon Cognito Web Client ID 
    },
    Storage: {
        AWSS3: {
            bucket: process.env.REACT_APP_BUCKET_NAME, //REQUIRED -  Amazon S3 bucket name
            region: "ap-south-1", //OPTIONAL -  Amazon service region
        }
    }
});

const { Option } = Select;

function AdminExpense() {
    const dispatch = useDispatch();
    const [expandRow, setExpandRow] = useState<any>();
    const [form]: any = Form.useForm();
    const [openDrawer, setOpenDrawer] = useState<any>();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const [onload, setOnLoad] = useState({
        expenseOnLoad: false,
        updateOnload: false
    })
    const {
        processDataInfo,
        adhocClient,
        expenseDataOnLoad,
        expenseData,
        updateExpenseData,
        expenseNumber,
        expandDetailedData,
        updateExpenseOnLoad,
        attachementDetails,
        getAttachmentOnLoad,

    }: any = reducerState;
    const [formValues, setFormValues] = useState<any>({ expenseTitle: "", date: "", warehouse: "", customer: "", expenseType: "", approvedBy: "", amount: 0 });
    let adhocRecordNumber = expenseNumber?.header?.adhocRecordNumber;
    const [editValue, setEditValue] = useState<any>();
    const [expandData, setData] = useState<any>();
    const [uploadView, setUploadView] = useState(false);
    const [openModal, setOpenModal] = useState<any>({});
    const [files, setfiles] = useState<any>([]);
    const [recordNumber, setRecordNumber] = useState<any>();
    const currentDate = new Date();
    const [custCode, setCustCode] = useState<any>();
    const [attachmentOnLoad, setAttachmentOnLoad] = useState<any>(false);
    const [images, setImages] = useState<any>([]);
    useEffect(() => {
        if (onload.expenseOnLoad == true && expenseDataOnLoad == false) {
            setOpenDrawer({ ...openDrawer, isOPen: false })
            form.resetFields();
            if (files?.length > 0) {
                uploadToAWS();
            }
            getData();
            setOnLoad({ ...onload, expenseOnLoad: false })
        }
    }, [onload.expenseOnLoad, expenseDataOnLoad])

    useEffect(() => {
        if (onload.updateOnload && updateExpenseOnLoad === false) {
            form.resetFields();
            getData();
            getDetailedData(expandData);
            setOnLoad({ ...onload, updateOnload: false })
            if (files?.length > 0) {
                uploadToAWS();
            }
            setOpenDrawer({ ...openDrawer, isOPen: false, addToExist: 'No' })
        }
    }, [updateExpenseOnLoad, onload.updateOnload])

    const saveExpense = (item: any) => {
        let expenseHeaderColumn = "RecordNumber, Title, ExpenseDate, CreatedBy"
        let insertValuesInExpenseHeader = "'" + adhocRecordNumber + "','" + formValues?.expenseTitle + "','" + formValues?.date + "','" + getUserInfo()?.UserName + "'";
        let expenseLineColumn = "AdhocExpenseHeaderRecordNumber, WarehouseID, ClientID, ExpenseType, ApprovedBy, Amount, CreatedBy";
        let insertValuesInExpenseLine = "'" + adhocRecordNumber + "','" + formValues?.warehouse + "','" + formValues?.customer + "'," + formValues?.expenseType + ",'" + formValues?.approvedBy + "'," + formValues?.amount + ",'" + getUserInfo()?.UserName + "'";
        let insertValuesInExpenseLine1 = "'" + openDrawer?.recNo + "','" + formValues?.warehouse + "','" + formValues?.customer + "'," + formValues?.expenseType + ",'" + formValues?.approvedBy + "'," + formValues?.amount + ",'" + getUserInfo()?.UserName + "'";
        let payload: any;
        if (item?.isTable == 'No') {
            payload = {
                "opnfor": 'A00001',
                "activity": "A-03",
                "customer": expenseHeaderColumn,
                "transaction": insertValuesInExpenseHeader,
                "where": insertValuesInExpenseLine,
                "warehouse": expenseLineColumn,
                "user": getUserInfo()?.UserName,
                "fieldArray": 1
            }
        }
        else {
            payload = {
                "opnfor": "A00001",
                "activity": "A-08",
                "transaction": expenseLineColumn,
                "where": insertValuesInExpenseLine1,
                "user": getUserInfo()?.UserName
            }
        }
        console.log("payload", payload);

        dispatch(saveExpenseAction(payload));
        setOnLoad({ ...onload, expenseOnLoad: true })
    }

    const getData = () => {
        try {
            let payload = {
                "activity": AppReferences.activities.av,
                "warehouse": getWareHouseId(),
                "opnfor": "A00001",
                "user": getUserInfo()?.UserName,
                "transaction": 1
            }
            dispatch(getProcessData(payload));
        } catch (error) {
            console.log("Error in processData", error)
        }
    }

    const getFilteredData = (date: any) => {
        try {
            let payload = {
                activity: AppReferences.activities.a01,
                // "warehouse": getWareHouseId(),
                opnfor: "A00001",
                user: getUserInfo()?.UserName,
                transaction: moment(date).year(),
                where: moment(date).month() + 1
            }
            dispatch(getProcessData(payload));
        } catch (error) {
            console.log("Error in getFilteredData", error)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        let payload = {
            "activity": "A-02",
            "opnfor": "A00001",
            "where": 1,
            "user": getUserInfo()?.UserName,
            "transaction": formValues?.warehouse
        }
        dispatch(getClientData(payload))
    }, [formValues?.warehouse])


    const getadhocRecordNumber = () => {
        let payload = {
            "activity": "A-04",
            "opnfor": "A00001",
            "user": getUserInfo()?.UserName,
        }
        dispatch(getadhocRecordNumberAction(payload))
    }

    const getDetailedData = (data: any) => {
        let payload =
        {
            "opnfor": "A00001",
            "activity": "A-07",
            "transaction": data?.RecordNumber,
            "user": getUserInfo()?.UserName
        }
        dispatch(getDetailedDataAction(payload))
    }

    const updateExpense = () => {
        let expenseHeaderWhere = "RecordNumber='" + recordNumber + "'";
        let updateValuesInExpenseHeader = "Title='" + formValues?.expenseTitle + "',ExpenseDate='" + moment(formValues?.date).format('YYYY-MM-DD') + "',ModifiedBy='" + getUserInfo()?.UserName + "',ModifiedDate=now()";
        // let expenseLineWhere = "AdhocExpenseHeaderRecordNumber='" + expandData?.RecordNumber + "'";
        let updateValuesInExpenseLine = "WarehouseID='" + formValues?.warehouse + "',ClientID='" + formValues?.customer + "',ExpenseType=" + formValues?.expenseType + ",ApprovedBy='" + formValues?.approvedBy + "',Amount=" + formValues?.amount + ",ModifiedBy='" + getUserInfo()?.UserName + "',ModifiedDate=now()";
        let payload = {
            opnfor: 'A00001',
            activity: 'A-06',
            transaction: expenseHeaderWhere,
            where: updateValuesInExpenseLine,
            // customer: expenseHeaderWhere,
            // warehouse: expenseLineWhere,
            user: getUserInfo()?.UserName
        }
        dispatch(updateExpenseAction(payload));
        setOnLoad({ ...onload, updateOnload: true })
    }

    useEffect(() => {
        if (openDrawer?.isEdit === true) {
            getAttachment();
        }
        else if (openDrawer?.isOpen == true) {
            setfiles([]);
        }
    }, [openDrawer]);
    useEffect(() => {
        if (attachmentOnLoad == true && getAttachmentOnLoad == false) {
            setAttachmentOnLoad(false)
            setImages(attachementDetails?.media?.objects);
        }
    }, [attachmentOnLoad, getAttachmentOnLoad])
    const getAttachment = () => {
        let payload = {
            opnfor: "A00001",
            activity: AppReferences.activities.a09,
            transaction: expandData?.RecordNumber,
            warehouse: formValues?.warehouse,
            customer: custCode
        }
        dispatch(getAttachmentsAction(payload));
        setAttachmentOnLoad(true);
    }

    function fun_A00001_downloadAdhocExpense() {
        if (processDataInfo?.lineitems?.length > 0) {
            let year = new Date().getFullYear();
            let nameOfMonth = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(new Date(),);
            let dd: any = new Date().getDate();
            if (dd < 10) dd = '0' + dd;
            let time = new Date().getHours() + "-" + new Date().getMinutes() + "-" + new Date().getSeconds();
            let finalDateAndTime = dd + "-" + nameOfMonth + "-" + year + "-" + time
            let fName = "GeneralAdminExpense-" + finalDateAndTime + ".csv"; // CSV file name
            let colAdd = 'toAdd_CSV' // Column & Row ID to Add in CSV file
            fun_A00001_tableToCSV(fName, colAdd);
        } else {
            message.error('Sorry!!! nothing to download.')
        }
    }

    function fun_A00001_tableToCSV(fileName: any, colAdd: any) {

        // Variable to store the final csv data
        let csv_data: any = [];

        // Get each row data
        let rows = document.getElementsByTagName('tr');
        for (let i = 0; i < rows.length; i++) {

            // Get each column data
            let cols = rows[i].querySelectorAll('td,th');

            // Stores each csv row data
            let csvrow = [];
            for (var j = 0; j < cols.length; j++) {

                // Get the text data of each cell
                // of a row and push it to csvrow
                if (cols[j].id == colAdd) {
                    csvrow.push(cols[j].innerHTML);
                }
            }

            // Combine each column value with comma
            csv_data.push(csvrow.join(","));
        }

        // Combine each row data with new line character
        csv_data = csv_data.join('\n');
        // Call this function to download csv file 
        // fun_downloadCSVFile(csv_data, fileName);
    }

    const uploadToAWS = async () => {
        let obj = adhocClient?.Clients?.find((e: any) => e?.ID == formValues?.customer);
        let fileCount = 0;
        files?.map(async (originFileObj: any) => {
            let randomGuid = generateGuId();
            let extension = originFileObj.name.substring(originFileObj.name.lastIndexOf('.'));
            let path = `${obj?.CustCode?.toLowerCase()}/${formValues?.warehouse?.toLowerCase()}/${processDataInfo?.title?.stageNo}/${expandData?.RecordNumber}/${randomGuid + extension}`;
            let Params = {
                contentType: originFileObj?.type,
                bucket: process.env.REACT_APP_BUCKET_NAME,
                resumable: true,
                completeCallback: () => {
                    if (fileCount + 1 == files.length) {
                        message.success(`Attachment Uploaded Successfully`);
                        setfiles([]);
                    }
                    else {
                        fileCount += 1;
                    }
                },
                //Key: `A-${whareHouseId}-${header?.stageNo}-${year}-${month}-${date}-${data?.TxnNo}-${randomGuid}${extension}`,
            };
            console.log("path", path, 'file', originFileObj, "params", Params);

            await Storage.put(path, originFileObj, Params);
        })
    }

    const getAtachments = (file: any) => {
        let fileType = file?.type?.split('/');
        if (fileType[1] == 'jpeg' || fileType[1] == 'png') {
            if (file?.size <= 5000000) {
                setfiles([...files, file]);
            } else {
                message.error("File size should not exceed more than 5MB");
            }
        }
        else {
            message.error("Selected file type is not supported");
        }
    }

    const removeImage = (item: any) => {
        let filesArr = files?.filter((originFileObj: any) => originFileObj?.uid != item?.uid);
        setfiles(filesArr);
    }

    const closeDragger = (e: any) => {
        e.stopPropagation();
        setUploadView(false);
        setfiles([]);
    }

    const ImgView = () => {
        return (
            <Row >
                {files?.map((originFileObj: any) => {
                    let url = URL.createObjectURL(originFileObj);
                    return (
                        <Col className='preview-attribute position-relative cursor-pointer' >
                            <Button
                                onClick={() => removeImage(originFileObj)}
                                className="close-btn"
                                style={{ position: 'absolute', top: '14px', right: '-15px' }}>
                                <CloseOutlined />
                            </Button>
                            <img src={url} alt='Loading'
                                height={100} width={100}
                                onClick={() => setOpenModal({ isOpen: true, url: url })} />
                        </Col>
                    )
                })}
            </Row>
        )
    }

    const openImageviewr = (url: any, index: any) => {
        let attachments = attachementDetails?.media?.objects;
        let startIndex = attachments?.slice(index, attachments.length);
        let restOfData = attachments?.slice(0, index);
        setOpenModal({ isOpen: true, urlArray: [...startIndex, ...restOfData], index: index });
    }

    const ImgViewTwo = () => {
        let prefixUrl = attachementDetails?.media?.prefix;
        return (
            <Row >
                {images?.map((url: any, index: any) => {
                    return (
                        <Col className='preview-attribute' style={{ cursor: "pointer" }} onClick={() => openImageviewr(prefixUrl + url, index)}>
                            {/* <span className='img-Link'> */}
                            <img src={prefixUrl + url} alt='Loading' height={100} width={100} />
                            {/* </span> */}
                        </Col>
                    )
                })}
            </Row>
        )
    }

    const uploadContainerView = () => {
        try {
            return (
                <>
                    <Dragger beforeUpload={(e) => { getAtachments(e); return false }} accept='.jpeg, .png'>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <DownloadIcon />
                            <div className='dragger-content ' style={{ marginLeft: 5, textAlign: "left" }}>
                                <div className='drag-txt '>Drag & Drop files or <span className='browse-txt'>Browse</span></div>
                                <div className='note-content '>Supported Formates: JPEG,PNG</div>
                            </div>
                        </div>
                        <Button className="close-btn" onClick={(e) => closeDragger(e)}>
                            <CloseOutlined />
                        </Button>
                    </Dragger>
                    <div className='mt-10 mb-10'>{ImgView()}</div>
                </>
            )
        } catch (ex) {
            console.log("Error in uploadContainerView::" + ex)
        }
    }

    useEffect(() => {
        if (openDrawer?.isOPen == false) {
            setUploadView(false);
            setfiles([]);
        }
    }, [openDrawer])

    const header = () => {
        return (
            <SubHeader
                title={"General Admin expenses"}
                paginationView="false"
                inputView="true"
                btnView="true"
            >
                <div className='d-flex flex-end'>
                    <DatePicker 
                        onChange={(e) => getFilteredData(e)}
                        allowClear={false}
                        picker="month"
                        format={"MMM-YYYY"}
                        className='date-select'
                        suffixIcon={<Calender />}
                        defaultValue={moment()}
                    // disabledDate={(e) => attributeDetails?.manuFactDate && (e < moment(attributeDetails?.manuFactDate) || e > moment())}
                    // onChange={(e: any) => setAttributeDetails({ ...attributeDetails, importMth: moment(e).format("MMM-YYYY") })}
                    />
                    <div className='expense' style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 15 }}>
                        <CSVFile />
                        <div style={{ marginLeft: 5 }}>Download Expense</div>
                    </div>
                    <AppButtons
                        style={{ paddingRight: '10px' }}
                        block={true}
                        buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                        text={'Add Expense'}
                        onClick={() => {
                            openDrawerField()
                            setOpenDrawer({ ...openDrawer, isOPen: true, addToExist: 'No', isTable: "No" });
                        }}
                    >
                    </AppButtons>
                </div>
            </SubHeader>
        )
    }

    const openDrawerField = () => {
        form.resetFields();
        form.setFieldsValue({
            ['expensedate']: "",
            ['expenseTitle']: "",
            ['warehouse']: "",
            ['customer']: "",
            ['expensetype']: "",
            ['approvedby']: "",
            ['amount']: ""
        });
    }

    const clearFieldValue = () => {
        setOpenDrawer({ ...openDrawer, isOPen: false, isEdit: false });
        form.resetFields();
        form.setFieldsValue({
            ['expensedate']: "",
            ['expenseTitle']: "",
            ['warehouse']: "",
            ['customer']: "",
            ['expensetype']: "",
            ['approvedby']: "",
            ['amount']: ""
        });

    }

    const drawerView = () => {
        try {
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                {openDrawer?.addToExist == 'Yes' ? "Edit Expense" : "Add Expense"}
                            </div>
                            {/* <Button
                                onClick={() => setOpenDrawer({ ...openDrawer, isOPen: false, addToExist: 'No', isEdit: false })}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button> */}
                            <img onClick={() => setOpenDrawer({ ...openDrawer, isOPen: false, addToExist: 'No', isEdit: false })} className="c-p" src={AppImages.closeimg} alt='' />
                        </div>
                    }
                    onClose={() => {

                        clearFieldValue()
                    }}
                    open={openDrawer?.isOPen}>
                    <div style={{ borderRadius: 2 }}>
                        <Form
                            id='form'
                            form={form}
                            autoComplete="off"
                            noValidate
                            layout="vertical"
                            onFinish={() => (openDrawer?.addToExist == 'No') ? saveExpense(openDrawer) : updateExpense()}
                        >

                            <div style={{ marginTop: 10 }}>
                                {((openDrawer?.isEdit == true) || (openDrawer?.addToExist == 'No' && openDrawer?.isTable == 'Yes')) ?
                                    <>
                                        <div style={{ fontFamily: 'RedHat-Bold', marginTop: 10 }}>Expense Title</div>
                                        <div className='showDate' style={{ marginTop: 10 }}>
                                            {expandData?.Title}
                                        </div>
                                    </> :
                                    <Form.Item
                                        label="Expense Title"
                                        name='expenseTitle'
                                        required
                                        rules={
                                            [
                                                { required: true, message: "Enter Expense Title" },
                                                { pattern: /^[a-zA-Z0-9 ]+$/, message: "No Special Character allowed " },
                                            ]
                                        }
                                    >
                                        <Input
                                            onChange={(e: any) => setFormValues({ ...formValues, expenseTitle: e.target.value })}
                                            onBlur={(e: any) => ((e?.target?.value) !== "" && getadhocRecordNumber())}
                                            className='input-field'
                                            placeholder='Enter' />
                                    </Form.Item>
                                }
                            </div>
                            <div className="mt-10">
                                {((openDrawer?.isEdit == true) || (openDrawer?.addToExist == 'No' && openDrawer?.isTable == 'Yes')) ?
                                    <>
                                        <div style={{ fontFamily: 'RedHat-Bold', marginTop: 10 }}>Expense Date</div>
                                        <div className='showDate' style={{ marginTop: 10 }}>
                                            {expandData?.ExpenseDate}
                                        </div>
                                    </> :

                                    <Form.Item
                                        label="Expense Date"
                                        name="expensedate">

                                        <DatePicker format={'DD-MM-YYYY'}
                                            disabledDate={(e) => e > moment()}
                                            className='input-field'
                                            onChange={(e: any) => setFormValues({ ...formValues, date: moment(e).format("YYYY-MM-DD") })}
                                        />
                                    </Form.Item>
                                }
                            </div>
                            {/* <div style={{ fontFamily: 'RedHat-Bold', marginTop: 10 }}></div> */}
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    label="Warehouse"
                                    name='warehouse'
                                    required
                                    rules={
                                        [
                                            { required: true, message: "Select Warehouse" },

                                            // { pattern: /^\S*$/, message: "Space not allowed" }
                                        ]

                                    }
                                >
                                    <Select
                                        style={{ borderRadius: 4 }}
                                        onChange={(e: any) => setFormValues({ ...formValues, warehouse: e })}
                                        placeholder='Select'
                                    >
                                        {(processDataInfo?.WHs || []).map((item: any) => {
                                            return (
                                                <Option value={item?.WarehouseId}>{item?.WarehouseName}</Option>
                                            )
                                        })}

                                    </Select>
                                </Form.Item>
                            </div>

                            {/* <div style={{ fontFamily: 'RedHat-Bold', marginTop: 10 }}>Customer</div> */}
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    label="Customer"
                                    name='customer'
                                    required
                                    rules={
                                        [{ required: true, message: "Select Customer" }]
                                    }
                                >
                                    <Select
                                        style={{ borderRadius: 4 }}
                                        onChange={(e: any) => setFormValues({ ...formValues, customer: e })}
                                        placeholder='Select'
                                    >
                                        {(adhocClient?.Clients || []).map((item: any) => {
                                            return (
                                                <Option value={item?.ID}>{item?.Name}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            {/* <div style={{ fontFamily: 'RedHat-Bold', marginTop: 10 }}>Expense Type</div> */}
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    label="Expense Type"
                                    name='expensetype'
                                // rules={
                                //     [{ required: true, message: "Select expense type" }]
                                // }
                                >
                                    <Select
                                        style={{ borderRadius: 4 }}
                                        onChange={(e: any) => setFormValues({ ...formValues, expenseType: e })}
                                        placeholder='Select'
                                    >
                                        {(processDataInfo?.ExpenseTypes || []).map((item: any) => {
                                            return (
                                                <Option value={item?.ExpenseTypeId}>{item?.Description}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            {/* <div style={{ fontFamily: 'RedHat-Bold', marginTop: 10 }}>Approved By</div> */}
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    label="Approved By"
                                    name='approvedby'
                                    rules={
                                        [
                                            { required: true, message: "Select approvedby" },
                                            { pattern: /^[a-zA-Z ]+$/, message: "Numbers and No Special Character  allowed " },
                                        ]
                                    }
                                >
                                    <Input
                                        onChange={(e: any) => setFormValues({ ...formValues, approvedBy: e.target.value })}
                                        className='input-field'
                                        placeholder='Enter' />
                                </Form.Item>
                            </div>
                            {/* <div style={{ fontFamily: 'RedHat-Bold', marginTop: 10 }}>Amount</div> */}
                            <div style={{ marginTop: 10 }}>
                                <Form.Item
                                    label="Amount"
                                    name='amount'
                                    rules={
                                        [
                                            { required: true, message: "Enter amount" },
                                            { pattern: /^[\d]*$/, message: "Alphabets and No Special Character allowed" }
                                        ]
                                    }
                                >
                                    <Input
                                        onChange={(e: any) => setFormValues({ ...formValues, amount: e.target.value })}
                                        className='input-field'
                                        placeholder='Enter' />
                                </Form.Item>
                            </div>
                            <div className='d-flex font-16 redhat-bold mt-10 mb-10'>
                                <div className='attachment-title-1'>
                                    {AppConstants.attachment}
                                    {!uploadView &&
                                        <Button className="add-btn" onClick={() => setUploadView(true)}>
                                            <PlusOutlined />
                                        </Button>}
                                </div>
                            </div>
                            {(openDrawer?.isEdit == true) && (isArrayNotEmpty(images) &&
                                < div className='mt-10 mb-10'>
                                    {ImgViewTwo()}
                                </div>)
                            }
                            {uploadView && <div className='dragger-container' style={{ marginTop: 10 }}>
                                {uploadContainerView()}
                            </div>}
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, marginRight: 10 }}>
                                {/* <Button className='cancel-btn'
                                // onClick={() => closeDrawerFields()}
                                >
                                    Cancel
                                </Button> */}
                                <AppButtons
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                    text={"Cancel"}
                                />
                                <AppButtons
                                    block={true}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                    text={(openDrawer?.addToExist == 'Yes') ? "Save" : "Add"}
                                    htmlType={'submit'}
                                >
                                </AppButtons>
                            </div>

                        </Form>
                    </div >
                </Drawer >
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }
    }

    const openEditDrawerFields = (item: any) => {
        setRecordNumber(item?.RecordNumber);
        setCustCode(item?.CustCode)
        setOpenDrawer({ ...openDrawer, isOPen: true, addToExist: 'Yes', isTable: 'No', isEdit: true })
        form.setFieldsValue({
            //['expensedate']:expandData?.ExpenseDate, 
            ['expenseTitle']: expandData?.Title,
            ['warehouse']: item?.WarehouseName,
            ['customer']: item?.CustomerName,
            ['expensetype']: item?.ExpenseType,
            ['approvedby']: item?.ApprovedBy,
            ['amount']: item?.Amount
        });
        setEditValue(item);
        setFormValues({
            ...formValues,
            expenseTitle: expandData?.Title,
            date: expandData?.ExpenseTypeID,
            warehouse: item?.WarehouseID,
            customer: item?.ClientID,
            expenseType: item?.ExpenseTypeID,
            approvedBy: item?.ApprovedBy,
            amount: item?.Amount
        });
    }

    const columns: any = [
        {
            title: "Warehouse",
            dataIndex: "WarehouseName",
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Customer",
            dataIndex: "CustomerName",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Expense Type",
            dataIndex: "ExpenseType",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Approved By",
            dataIndex: "ApprovedBy",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Amount",
            dataIndex: "Amount",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }

        },
        {
            title: AppConstants.action,
            dataIndex: "",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='d-flex align-c jc-c' style={{ cursor: " pointer" }} onClick={() => openEditDrawerFields(item)}>
                        <EditIcon />
                    </div>
                )
            }

        }
    ];


    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={4}>
                    {"Expense ID #"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Date"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Create - till days"}
                </Col>
                <Col span={6} className=' d-flex jc-c'>
                    {"Title"}
                </Col>
                <Col span={4} className=' d-flex jc-c'>
                    {"Amount"}
                </Col>
                <Col span={2} className=' d-flex jc-c'>
                    {AppConstants.action}
                </Col>
            </Row>
        )
    }

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
            setData(data)
            getDetailedData(data)
        }
    }

    const skuDetailsView = () => {
        return (
            <div className='table-view-container' style={{ background: 'white', padding: 10, borderRadius: 6 }}>
                <Table
                    columns={columns}
                    dataSource={expandDetailedData?.lineitems ? expandDetailedData?.lineitems : []}
                    scroll={{ y: '490px' }}
                    pagination={false}
                />
            </div>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        return (
            <div className={open ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={4} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='text-1'>
                            {item?.RecordNumber}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.ExpenseDate}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.CreateTillDays}
                        </div>
                    </Col>
                    <Col span={6} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.Title}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.Amount}
                        </div>
                    </Col>
                    <Col span={2} className="d-flex align-c jc-c">
                        <div className='add-btn' style={{ cursor: " pointer" }} onClick={(e: any) => {
                            openDrawerField();
                            setOpenDrawer({ ...openDrawer, isOPen: true, addToExist: 'No', isTable: "Yes", recNo: item?.RecordNumber });
                            handleExpand(e, index, item);
                        }}>
                            Add
                        </div>
                    </Col>
                    {open && <Col span={24} className="sku-details">
                        {skuDetailsView()}
                    </Col>}
                </Row>
            </div>
        )
    }

    return (
        <div>
            <NavbarLayout
                titleContent="sub-head"
                initial={AppModuleIds.main}
                selectedMain={'11'}
            />
            {header()}
            <div className='table-screen-container'>
                {tableHeaderView()}
                {isArrayNotEmpty(processDataInfo?.lineitems ) ?
                    <div style={{ height: "71.5vh", overflow: 'scroll' }}>
                        {(processDataInfo?.lineitems || []).map((x: any, index: any) => tableBodyView(x, index))}
                    </div> :
                    <div className='p-20'>
                        <NoData
                            className='mt-45'
                            description={
                                <>There is no data available in <span className='redhat-bold ml-5'>Admin Expense</span></>
                            }
                        />
                    </div>
                }
                {drawerView()}
                <ImgModelView
                    open={openModal?.isOpen}
                    // srcUrl={openModal?.url}	
                    urlArray={openModal?.urlArray}
                    close={() => setOpenModal({ isOpen: false, urlArray: [] })}
                />
            </div>
        </div>
    );
}

export default AdminExpense;