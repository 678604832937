import { Empty } from 'antd';
import React from 'react';
import AppConstants from '../Globals/AppConstants';

function NoData(props: any) {
    const {
        description,
        className,
        height
    } = props;
    return (
        <div className={`background-container-component ${className}`} style={{height: height ? height : '70vh'}}>
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{
                    height: 40,
                }}
                description={
                    <div className='noData'>
                        {description ? description : AppConstants.noDataAvailable}
                    </div>
                }
            >
            </Empty>
            {/* <div className='no-data-available-icon'>
                {AppConstants.noDataAvailable}
            </div> */}
        </div>
    );
}

export default NoData;