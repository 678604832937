import React from 'react';

function SellableIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
            <path d="M25.0307 39.3486C24.263 40.1162 23.3118 40.5 22.1771 40.5C21.0423 40.5 20.0912 40.1162 19.3235 39.3486L1.7015 21.7265C1.33438 21.3594 1.04268 20.9255 0.826408 20.4249C0.608803 19.9243 0.5 19.3903 0.5 18.8229V4.50501C0.5 3.40363 0.892491 2.46045 1.67747 1.67547C2.46112 0.891823 3.40363 0.5 4.50501 0.5H18.8229C19.3903 0.5 19.9243 0.608135 20.4249 0.824405C20.9255 1.04201 21.3594 1.33438 21.7265 1.7015L39.3486 19.3736C40.1162 20.1412 40.5 21.0837 40.5 22.2011C40.5 23.3199 40.1162 24.263 39.3486 25.0307L25.0307 39.3486ZM9.51126 12.515C10.3456 12.515 11.0545 12.2227 11.6379 11.6379C12.2227 11.0545 12.515 10.3456 12.515 9.51126C12.515 8.67689 12.2227 7.968 11.6379 7.38461C11.0545 6.79988 10.3456 6.50751 9.51126 6.50751C8.67689 6.50751 7.968 6.79988 7.38461 7.38461C6.79988 7.968 6.50751 8.67689 6.50751 9.51126C6.50751 10.3456 6.79988 11.0545 7.38461 11.6379C7.968 12.2227 8.67689 12.515 9.51126 12.515Z" fill="#57BFAA" />
        </svg>
    );
}

export default SellableIcon;