import React from 'react';

function PackingListIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM4 4H6V6H4V4ZM4 8H6V10H4V8ZM4 12H6V14H4V12ZM14 14H8V12H14V14ZM14 10H8V8H14V10ZM14 6H8V4H14V6Z" fill="var(--app-004986)" />
        </svg>
    );
}

export default PackingListIcon;