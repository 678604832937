import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, } from 'antd';
import React from 'react';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import { $ } from 'react-jquery-plugin'
import { useState } from 'react';
import SpinLoader from '../SpinLoader';


function CartonningPackingListModal(props, ref) {
    const {
        viewPackingListModal,
        setPackingListModal,
        dataValue,
        fromOrgin
    } = props

    const [printLoad, setPrintLoad] = useState(false);

    const printPackingList = () => {
        try {
            setPrintLoad(true);
            let frame1 = $('<iframe />');
            frame1[0].name = "frame1";

            $("body").append(frame1);
            var frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow : frame1[0].contentDocument.document ? frame1[0].contentDocument.document : frame1[0].contentDocument;
            frameDoc.document.open();
            //Create a new HTML document.
            frameDoc.document.write('<html><head><style type="text/css">');
            frameDoc.document.write('@page {size:  600px 842px !important;margin: 2rem !important;padding: 0.5rem !important;}');
            frameDoc.document.write('@media print{.packing-list{ page-break-inside: avoid; page-break-before: always; padding: 0.5rem !important ; position: relative}');
            frameDoc.document.write('.barcode-container {padding-right: 3% !important}');
            frameDoc.document.write('.barcode-container img {max-width: 100% !important; , max-height: 2.5rem !important;}');
            frameDoc.document.write('.table-data{padding: 0.5rem !important}');
            frameDoc.document.write('.total-position{padding-left: 0.5rem !important}');

            frameDoc.document.write('.bill-ship-container{background-color: "#EEEEEE"}');
            frameDoc.document.write(' body{-webkit-print-color-adjust:exact !important; print-color-adjust:exact !important;}');


            frameDoc.document.write('</style></head><body>');
            let element = document.getElementById("viewPackingList-container");
            let data = element.innerHTML;
            // let barcode = document.getElementById((key == 'all' ? sticker?.Barcode : sticker)+'img');
            // barcode.setAttribute('onError', '');
            let replacedValue = data.replaceAll("&amp;", '&');
            replacedValue = data.replace('<div class="icon"><span role="img" aria-label="check-circle" class="anticon anticon-check-circle select-icon"><svg viewBox="64 64 896 896" focusable="false" data-icon="check-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path></svg></span></div></div>', '');
            frameDoc.document.write(replacedValue);
            frameDoc.document.write('</body></html>');
            frameDoc.document.close();
            setTimeout(() => {
                window.frames["frame1"].focus();
                window.frames["frame1"].print();
                frame1.remove();
                setPrintLoad(false)
            }, 2000)

            return true;
        } catch (error) {
            console.log("Error in printStickers::", error);
        }
    }


    const viewPackingList = (item) => {
        try {
            let barcodeUrl = "http://bwipjs-api.metafloor.com/?bcid=code128&text=" + item?.CartonNumber;
            return (
                <div id="componentRef"
                    className='packing-list'
                    style={{ width: "100%" }}>
                    <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div
                            style={{ textAlign: "center", fontSize: '2rem', fontFamily: "RedHat-Bold", color: "#2D2D2D", width: "40%" }}>
                            Packing List
                        </div>
                        <div
                            style={{ fontSize: 16, fontFamily: "RedHat-Bold", color: "#2D2D2D", width: "40%" }}
                        //className="order-title "
                        >
                            <div style={{ paddingLeft: 10, paddingRight: 10, alignItems: "center", display: "flex", justifyContent: "space-between", }}>
                                <div style={{ width: "40%" }}>Order #</div>
                                <div style={{ display: "flex", justifyContent: "flex-end", width: "60%" }}>{item?.SOID}</div>
                            </div>
                            <div style={{ paddingLeft: 10, paddingRight: 10, alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                                <div style={{ width: "40%" }}>Order Date</div>
                                <div style={{ display: "flex", justifyContent: "flex-end", width: "60%" }}>{item?.OrderDate}</div>
                            </div>
                        </div>
                    </div>
                    <div
                        //className=' carton-container'
                        style={{ marginTop: 20, alignItems: "center", display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div
                            style={{ fontSize: 20, fontFamily: "RedHat-Bold", color: "#2D2D2D", width: "40%" }}
                        //className="carton-num"
                        >{item?.CartonNumber}</div>
                        <div
                            style={{ width: "60%" }}
                            className="barcode-container"
                        >
                            <img style={{ width: "100%", height: 40}} src={barcodeUrl} alt={`Barcode ${item?.CartonNumber}`} />
                        </div>
                    </div>
                    <div
                        className=' bill-ship-container'
                        style={{ printColorAdjust: "exact", width: "100%", marginTop: 10, fontSize: 16, fontFamily: "RedHat-Bold", color: "#2D2D2D", border: "1px solid #EEEEEE", backgroundColor: "#EEEEEE", paddingLeft: 15, paddingRight: 15, paddingTop: 5, paddingBottom: 5, alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "50%" }} >Bill To</div>
                        <div style={{ width: "50%" }} >Ship To</div>
                    </div>

                    <div
                        className='bill-ship'
                        style={{ width: "100%", alignItems: "center", display: "flex", justifyContent: "space-between", marginTop: 5, fontSize: 14, fontFamily: "RedHat-Medium", color: "#2D2D2D", paddingLeft: 15, paddingRight: 15 }}
                    //className='mt-5 address-container'
                    >
                        <div style={{ width: "50%", marginRight: '0.5rem' }} >
                            <div>{item?.CustomerName}</div>
                            <div>{item?.AddressLine1}</div>
                            <div>{item?.City}</div>
                            <div>{item?.StateCode}</div>
                            <div>{item?.PinCode}</div>
                        </div>
                        <div style={{ width: "50%" , marginRight: '0.5rem'}} >
                            <div>{item?.CustomerName}</div>
                            <div>{item?.AddressLine1}</div>
                            <div>{item?.City}</div>
                            <div>{item?.StateCode}</div>
                            <div>{item?.PinCode}</div>
                        </div>
                    </div>
                    <div className='table-data' style={{ marginTop: 20, }}>
                        <div style={{ width: "100%", alignItems: "center", display: "flex", fontSize: 16, fontFamily: "RedHat-Bold", color: "#2D2D2D", background: "#EEEEEE", paddingTop: 5, paddingBottom: 5, border: "1px solid #C7C7C7" }}>
                            <div style={{ width: "10%", paddingLeft: 10, paddingRight: 10, }}>#</div>
                            <div style={{ textAlign: "center", width: "50%", paddingLeft: 10, paddingRight: 10, }}>SKU,Description</div>
                            <div style={{ textAlign: "center", width: "20%", paddingLeft: 10, paddingRight: 10, }}>Ordered QTY</div>
                            <div style={{ textAlign: "center", width: "20%", paddingLeft: 10, paddingRight: 10, }}>Packed Qty</div>
                        </div>
                        {(item?.Lines || []).map((x, index) => {
                            return (
                                <div style={{ width: "100%", alignItems: "center", display: "flex", fontSize: 14, fontFamily: "RedHat-Medium", color: "#2D2D2D", background: "#FFFFFF", border: "1px solid #C7C7C7", paddingTop: 5, paddingBottom: 5, }}>
                                    <div style={{ width: "10%", paddingLeft: 10, paddingRight: 10, }}>{index + 1}</div>
                                    <div style={{ textAlign: "center", width: "50%", paddingLeft: 10, paddingRight: 10, }}>{x?.ItemId}, {x?.Description}</div>
                                    <div style={{ textAlign: "center", width: "20%", paddingLeft: 10, paddingRight: 10, }}>{x?.OrderedQuantity}</div>
                                    <div style={{ textAlign: "center", width: "20%", paddingLeft: 10, paddingRight: 10, }}>{x?.PackedQuantity}</div>
                                </div>
                            )
                        })}
                        <div style={{ width: "100%", alignItems: "center", display: "flex", fontSize: 18, fontFamily: "RedHat-Bold", color: "#2D2D2D", background: "#EEEEEE", paddingTop: 5, paddingBottom: 5, border: "1px solid #C7C7C7" }}>
                            <div className='total-position' style={{ textAlign: "center", width: "60%", paddingLeft: 10, paddingRight: 10, }}>Total</div>
                            <div className='total-position' style={{ textAlign: "center", width: "20%", paddingLeft: 10, paddingRight: 10, }}>{item?.OrderedQtySUM}</div>
                            <div className='total-position' style={{ textAlign: "center", width: "20%", paddingLeft: 10, paddingRight: 10, }}>{item?.PackedQtySUM}</div>
                        </div>

                    </div>
                    <div className='address' style={{fontSize: 12}}>
                        <div>Dispatched from(Please use this address in case of return)</div>
                        <div>360WMS</div>
                        <div>ABD,EFG,HIJK-421308</div>
                    </div>
                </div>
            )
        } catch (ex) {
            console.log("Err in viewPackingList::" + ex)
        }
    }
    return (

        <Modal
            title={
                <div className='d-flex align-c jc-sb'>
                    <div className='redhat-bold mb-10 font-18'>
                        Packing List
                    </div>
                    <div className='d-flex align-c'>
                        {fromOrgin != 'rts' && <AppButtons
                            onClick={() => {printPackingList()}}
                            buttonType={AppConstants.buttonTypes.whiteButton}
                            text="Print Packing List"
                        />}
                        <Button
                            style={{ marginLeft: 16 }}
                            onClick={() => {setPackingListModal(false);}}
                            className="cross-btn">
                            <CloseOutlined />
                        </Button>
                    </div>
                </div>}
            className='modal-view'
            onCancel={() => {setPackingListModal(false)}}
            // onOk={}
            open={viewPackingListModal}
            footer={null}
        >
            <div className='viewPackingList-container' id='viewPackingList-container'>
                {(dataValue || []).map((item) => viewPackingList(item))}
            </div>
            <SpinLoader loading={printLoad}/>
        </Modal>
    );
}
export default React.forwardRef(CartonningPackingListModal);