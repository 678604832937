import React, { useEffect, useState, } from 'react';
import { EyeFilled, SearchOutlined } from "@ant-design/icons";
import {
    Button,
    DatePicker,
    Layout,
    Table,
    Tooltip
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import AppConstants from "../../Globals/AppConstants";
import SubHeader from "../Header/Header";
import NavbarLayout from "../NavbarLayout/NavbarLayout";
import "../TableContainer.scss"
import Calender from "../../Images/Calender";
import { useDispatch, useSelector } from 'react-redux';
import { getGateInEntryAction } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import AppButtons from '../../CustomComponent/AppButtons';
import moment from 'moment';
import { AppModuleIds } from '../../Globals/AppReferenceIds';

const {
    Content
} = Layout;
const suffix = (
    <SearchOutlined
        style={{
            fontSize: 20,
            color: 'black',
        }}
    />
);

function GateInEntries() {
    const dispatch = useDispatch();
    const reducerState = useSelector((state: any) => state.ProcessReducerState)
    const { dataInfo } = reducerState;
    const gateInEntryData = dataInfo?.lineitems;
    const headerData = dataInfo?.title;
    const whareHouseId = getWareHouseId();
    const navigate = useNavigate();
    const [date, setdate] = useState<any>();
    const [filterOnLoad, setFilterOnLoad] = useState<any>(false);

    useEffect(() => {
        getFilterApi();
    }, []);

    useEffect(() => {
        setdate(moment());
    }, [])

    useEffect(() => {
        if (date != undefined) {
            getFilterApi();
        }
    }, [date]);

    const increaseDate = () => {
        if (date?.format('DD-MMM-YYYY') != moment()?.format('DD-MMM-YYYY')) {
            let dateIncreased = moment(date, "DD-MMM-YYYY")?.add(1, 'd');
            setdate(dateIncreased);
        }
    }

    const decreaseDate = () => {
        let datedecreased = moment(date, "DD-MMM-YYYY")?.subtract(1, 'd');
        setdate(datedecreased);
    }

    const tooltip = (data: any) => {
        return (
            <Tooltip title={data}>
                {data}
            </Tooltip>
        )
    }

    const getFilterApi = () => {
        let payload = {
            activity: "A-01",
            opnfor: "110000",
            transaction: date?.format('YYYY-MM-DD'),
            warehouse: whareHouseId,
            customer: getUserInfo()?.CorporateID,
            user: getUserInfo().UserName,
            
        }
        dispatch(getGateInEntryAction(payload))
    }

    const boxescolumns: any = [
        {
            title: "Client",
            dataIndex: 'Customer',
            width: '15%',
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                );
            }
        },
        {
            title: "GateIn ID",
            dataIndex: 'TxnNo',
            width: '35%',
            render: (item: any) => {
                return (
                    <div className='text overflow-ellipse'  >
                        {item}
                    </div>
                );
            }
        },
        {
            title: AppConstants.inwardType,
            dataIndex: 'InwardTxnType',
            width: '15%',
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                );
            }
        },
        {
            title: "Vehicle Type",
            dataIndex: 'Type',
            width: '20%',
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text overflow-ellipse'>
                        {item}
                    </div>
                );
            }
        },
        {
            title: AppConstants.vehicleNumber,
            dataIndex: 'VehicleNumber',
            width: '13%',
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text overflow-ellipse'>
                        {tooltip(item)}
                    </div>
                );
            }
        },
        {
            title: AppConstants.sealNumber,
            dataIndex: 'SealNo',
            width: '15%',
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text overflow-ellipse'>
                        {item}
                    </div>
                );
            }
        },
        {
            title: AppConstants.docketNumber,
            dataIndex: 'DocketNumber',
            width: '15%',
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text overflow-ellipse'>
                        {item}
                    </div>
                );
            }
        },
        {
            title: "Dock Station",
            dataIndex: 'DockStationName',
            width: '15%',
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                );
            }
        },
        {
            title: AppConstants.action,
            dataIndex: '',
            width: '10%',
            align: "center",
            render: (item: any) => {
                return (
                    <div className='d-flex align-c jc-c'>
                        <Button className='eye-btn' onClick={() => navigate("/gatein-entry-view", { state: { data: item, header: headerData } })}>
                            <EyeFilled />
                        </Button>
                    </div>

                );
            },
        }
    ];

    const childrenView = () => {
        return (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Link to="/gate-in-add" state={{ Option: "Add" }} style={{ marginRight: 10 }}>
                    <AppButtons
                        buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                        text='Add New GateIn'
                    />
                </Link>
            </div>
        )
    }

    return (
        <Layout>
            <NavbarLayout
                titleContent="sub-head"
                process={headerData}
                initial={AppModuleIds.gateIn}
                selectedGateIn={'1'}
            />
            <Layout>
                <SubHeader
                    title={
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "76%" }}>
                            <div>{"GateIn Entries"}</div>
                            <div className='piece-box-container' style={{ cursor: "pointer" }}>
                                <span
                                    style={{
                                        cursor: "pointer",
                                        marginRight: 10,
                                        textDecorationLine: "none",
                                        color: "var(--app-004986)"
                                    }}
                                    onClick={() => decreaseDate()}
                                >
                                    Previous
                                </span>
                                <DatePicker format={'DD-MM-YYYY'}
                                    allowClear={false}
                                    disabledDate={(e) => e > moment()}
                                    value={date}
                                    onChange={(e) => setdate(moment(e))}
                                    suffixIcon={<Calender />}
                                />
                                <span
                                    style={{
                                        marginLeft: 10,
                                        textDecorationLine: "none",
                                        color: (date?.format('DD-MMM-YYYY') != moment()?.format('DD-MMM-YYYY')) ? "var(--app-004986)" : "rgba(0,0,0,.25)",
                                        cursor: (date?.format('DD-MMM-YYYY') != moment()?.format('DD-MMM-YYYY')) ? "pointer" : "not-allowed",
                                        fontFamily: (date?.format('DD-MMM-YYYY') != moment()?.format('DD-MMM-YYYY')) ? "RedHat-Medium" : "RedHat-SemiBold"
                                    }}
                                    onClick={() => increaseDate()}

                                >
                                    Next
                                </span>
                            </div>
                        </div>
                    }
                    paginationView="false"
                    inputView="true"
                    btnView="false">
                    {childrenView()}
                </SubHeader>
                <Content className="antd-content-white">
                    <div className='table-screen-container '>
                        <Table
                            columns={boxescolumns}
                            dataSource={gateInEntryData}
                            pagination={false}
                            scroll={{ y: 485 }}
                        />
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default GateInEntries;