import { Menu, Radio, Skeleton } from "antd";
import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import './CardContainer.scss';

interface CardContainerProps {
    menu: any;
    handleMenuSelect: (value: any) => void;
    value: any;
    load?: boolean;
    scrollable?: any;
    fromOrigin?: any;
}

function CardContainer(CardContainerProps : any) {
    // let menus = menu?.filter((x: any) => (x.id != 120130 && x.id != 540200));
    const  { menu, handleMenuSelect, value, load, scrollable, fromOrigin } = CardContainerProps;
    const navigate = useNavigate();

    const skeletonView = () => {
        let list = [1, 2, 3, 4]
        return (
            <Menu>
                {list?.map((x: any) =>
                    <Menu.Item className="skeleton">
                        <Skeleton paragraph={{ rows: 1 }} active />
                    </Menu.Item>)}
            </Menu>
        )
    }

    return (
        <div>
            {load ?
                    skeletonView()
                :
                <Menu
                    className="mt-10"
                    // buttonStyle="solid"
                    onClick={handleMenuSelect}
                    selectedKeys={[value]} >
                    {(menu || []).map((item: any) => {
                        let mismatched = (item?.id != "301" && item?.id != "302" && item?.id != "303" && item?.id != "350")
                        return (
                            <Menu.Item key={item?.id} 
                            className={mismatched ? fromOrigin == 'RTO' ? "rto" : " " : "mismatched"}
                            >
                                <div className="card-title">{item?.name}</div>
                                {item?.cnt && <div className="count">
                                    {item?.cnt}
                                </div>}
                            </Menu.Item>
                        )
                    })}
                </Menu>}
        </div>
    )
}

export default CardContainer

