import { CaretRightOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Input, message, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import NoData from '../../CustomComponent/NoData';
import AppReferences from '../../Globals/AppReferences';
import { isArrayNotEmpty } from '../../Globals/Helper';
import {
    ExpandDataAction,
    getDetailedDataAction,
    startCycleCountAction,
    scan0rEnterToPackAction as updateCurrentCycleCount,
    sendSanDetails as senForApprovalAction
} from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import SpinLoader from '../SpinLoader';

function StartCycleCount(props: any) {
    const pageTitle = {
        title: "Cycle Count"
    }
    const [expandRow, setExpandRow] = useState<any>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const locationProps = useLocation().state;
    const reducerState = useSelector((state: any) => state.ProcessReducerState);
    const { expandDetailedData, emailSent } = reducerState;
    let startCycleCountData = locationProps?.startCycleCountData;
    let cycleCountId= locationProps?.cycleCountId
    const [locationtype, setLocationType] = useState<any>({});

    const [onload, setOnload] = useState({
        sendApprovalOnload: false,
        updateOnload: false
    });
    console.log("emailSent", emailSent)
    useEffect(() => {
        if (onload.sendApprovalOnload && !reducerState.sendSanonload) {
            setOnload({ ...onload, sendApprovalOnload: false });
            if(emailSent?.msg == "Cycle Count Sent for Approval Successfully"){
                message.success(emailSent?.msg);
                navigate(-1);
            }
            else{
                message.error(emailSent?.msg);
            }
        }
    }, [onload.sendApprovalOnload, reducerState.sendSanonload]);

    useEffect(() => {
        if (onload.updateOnload && !reducerState.scanSKUOnLoad) {
            setOnload({ ...onload, updateOnload: false })
            getSkuDetails(locationtype);
        }
    }, [onload.updateOnload, reducerState.scanSKUOnLoad])

    const getSkuDetails = (data: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.cycleCount,
            activity: AppReferences.activities.a02,
            transaction: locationProps?.cycleCountId,
            where: data?.LocationId,
            user: getUserInfo()?.UserName
        }
        dispatch(getDetailedDataAction(payload))
    }

    const updateCurrentCount = (sku: any, value: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.cycleCount,
            activity: AppReferences.activities.a03,
            transaction: locationProps?.cycleCountId,
            where: locationtype?.LocationId,
            warehouse: getWareHouseId(),
            fieldArray: value,
            tableNo: sku?.ItemIDRecNo,
            user: getUserInfo()?.UserName,
	        customer: locationtype?.InventoryID
        }
        dispatch(updateCurrentCycleCount(payload));
        setOnload({ ...onload, updateOnload: true })
    }

    const sendForApproval = () => {
        let payload = {
            opnfor: AppReferences.opnfor.cycleCount,
            activity: AppReferences.activities.a04,
            transaction: "IsCycleCountSentToApproval='Y'",
            where: `WarehouseID='${getWareHouseId()}'AND CycleCountRecNo='${locationProps?.cycleCountId}'`,
            tableNo: "95",
            user: getUserInfo()?.UserName
        }
        dispatch(senForApprovalAction(payload));
        setOnload({ ...onload, sendApprovalOnload: true })
    }

    const header = () => {
        return (
            <SubHeader
                title={`Start Cycle Count -${cycleCountId}`}
                paginationView="false"
                inputView="true"
                btnView="true"
            >
                <div className='d-flex flex-end'>
                    <div
                        onClick={sendForApproval}
                        className='default-btn'
                        style={{ cursor: "pointer" }}
                    >
                        {'Send for Approval'}
                    </div>
                    {/* <div className="btn-container">
                        <Button className="close-btn" onClick={() => navigate(-1)}>
                            <CloseOutlined />
                        </Button>
                    </div> */}
                </div>
            </SubHeader >
        )
    }

    const titleContainer = () => {
        try {
            return (
                <Row>
                    <Col span={10}>
                        <div className='text'>Location</div>
                    </Col>
                    <Col span={8}>
                        <div className='text'>Storage Type</div>
                    </Col>
                    <Col span={6}>
                        <div className='text'>Structure Type</div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }

    const columns: any = [
        {
            title: "SKU, Description",
            dataIndex: '',
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item?.SKU}
                    </div>
                )
            }
        },
        {
            title: "Pallet",
            dataIndex: '',
            align: "left",
            render: (item: any) => {
                let data = (item?.XofY).split(',')
                return (
                    <div className='text'>
                        {data[0]}
                    </div>
                )
            }
        },
        {
            title: "Box",
            dataIndex: '',
            align: "left",
            render: (item: any) => {
                let data = (item?.XofY).split(',')
                return (
                    <div className='text'>
                        {data[1]}
                    </div>
                )
            }
        },
        {
            title: "Current Cycle Count",
            dataIndex: '',
            align: "left",
            width: '27%',
            render: (item: any) => {
                return (
                    <div style={{ width: '75%' }}>
                        <Input
                            defaultValue={item?.CurrentInventoryCnt}
                            onPressEnter={(e: any) => updateCurrentCount(item, e?.target?.value)}
                            className="box-input"
                        />
                    </div>
                )
            }
        }
    ];


    const listingView = () => {
        try {
            return (
                <div className='pending-san-view-container' style={{ paddingLeft: 10, paddingRight: 10 }}>
                    < Table
                        columns={columns}
                        dataSource={expandDetailedData?.lineitems}
                        pagination={false}
                    />
                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }
    const handleExpand = (index: any, data: any) => {
        if (expandRow == index) {
            setExpandRow(null);
        }
        else {
            setExpandRow(index);
            setLocationType(data)
            getSkuDetails(data)
        }

    }

    const bodyContainer = (data: any, index: any) => {
        try {
            let isExpandopen = (expandRow == index);
            return (
                <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={10}>
                            <div className="title-container">
                                <div className='d-flex align-c'>
                                    <CaretRightOutlined
                                        onClick={() => handleExpand(index, data)}
                                        className={index === expandRow ? "arrow arrow-down" : "arrow arrow-right"} />
                                    <div className='text ml-5' >
                                        {data?.LocationId}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className='text'>{data?.SystemType}</div>
                        </Col>
                        <Col span={6}>
                            <div className='text'> {data?.StructureType}</div>
                        </Col>
                    </Row>
                    {isExpandopen &&
                        <div>{listingView()}</div>
                    }
                </div>
            )
        } catch (error) {
            console.log("Error in pendingSansView::", error)
        }

    }

    return (
        <>
            <NavbarLayout
                titleContent="main-head"
                process={pageTitle}
            />
            <div>
                {header()}
            </div>
            <div className='p-10'>
                <div className='header-part'>
                    {titleContainer()}
                </div>
                <div className='expand-collape-container'>
                    {isArrayNotEmpty(startCycleCountData?.rawData?.lineitems) ?
                        (startCycleCountData?.rawData?.lineitems || []).map((data: any, index: any) =>
                            bodyContainer(data, index)
                        )
                        :
                        (
                            <NoData className='height-class' />
                        )}
                </div>
                <SpinLoader loading={onload?.sendApprovalOnload}/>
            </div>
        </>
    );
}

export default StartCycleCount;