import { CaretRightOutlined, CloseOutlined, DeleteRowOutlined, EditFilled, ExportOutlined, InteractionFilled, ReloadOutlined, RotateRightOutlined, SearchOutlined } from '@ant-design/icons';
import { Col, Layout, Radio, Row, Select, Input, Table, Button, DatePicker, Drawer, Form, Space, Dropdown, Menu, AutoComplete, message, Tooltip, Empty } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import Array from '../../Images/Array';
import CSVFile from '../../Images/CSVFile';
import { changeBinOrBoxLocationAction, clearReducerAction, getCustomerListForUserAction, getLocationDataAction, getLocationWiseQtyAction, getMetaDataAction, getMoveAdjustInventoryAction, getQuarantineInventoryAction, getSKUAction, getStockMovementDataAction, moveChangeSKULocationAction, updateAttributeAction } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import SkeletonLoader from '../OrderProcessing/SkeletonLoader';
import './StockMomentScreen.scss';
import type { RadioChangeEvent } from 'antd';
import { isArrayNotEmpty } from '../../Globals/Helper';
import NoData from '../../CustomComponent/NoData';
import { AppModuleIds } from '../../Globals/AppReferenceIds';
import SpinLoader from '../SpinLoader';
import { AnyAaaaRecord } from 'dns';

const { Option } = Select;

let selectedSku: any
let totalLocationQty: any = 0
let storageType: any
let locationType: any
let skuList: any

function StockMomentScreen(props: any) {
    const dispatch = useDispatch();
    const [stockForm]: any = Form.useForm();
    const [form]: any = Form.useForm();
    const [attributeForm]: any = Form.useForm();
    const [customer, setCustomer] = useState<any>();
    const [typeValue, setTypeValue] = useState(1);
    const [expandRow, setExpandRow] = useState<any>();
    const [expandLocationRow, setExpandLocationRow] = useState<any>();
    const [innerExpander, setInnnerExpander] = useState<any>();
    const [openDrawer, setOpenDrawer] = useState<any>();
    const [option, setOption] = useState<any>("sku");
    const [skuOption, setSkUOption] = useState<any>([]);
    const [onLoad, setOnLoad] = useState(false);
    const [attributeDetails, setAttributeDetails] = useState<any>({ qty: "", batch: "", manuFactDate: "", importMth: "", expiryDate: "", mrp: "" });
    const [locationData, setLocationData] = useState<any>({ storageType: "", structureType: "", locationID: "", locationType: "" });
    const [stockData, setStockData] = useState<any>({ location: "", locationType: "", moveQty: "", bin: "", reason: "", stockMovement: "" });
    const [expandLocationData, setExpandLocationData] = useState<any>();
    const [expandedAttributeId, setExpandedAttributeId] = useState<any>();
    const [stock, setStock] = useState<any>(locationType?.Locationtype === "Blocked (Quarantine)" ? "Quarantine Inventory" : "Change SKU Location");
    const [skuValue, setSkUValue] = useState<any>();
    const [locationOnLoad, setLocationOnLoad] = useState<any>(false);
    const [locationVisible, setLocationVisible] = useState<any>(false);
    const [stockOnLoad, setStockDataOnLoad] = useState<any>(false);
    const [visibleTableView, setVisibleTableView] = useState<any>(false);
    const [AttributeId, setAttributeId] = useState<any>();
    const [updateAttributeOnLoad, setUpdateAttributeOnLoad] = useState<any>(false);
    const [barCode, setBarCode] = useState<any>();
    const [moveAdjustOnLoad, setmoveAdjustInventoryOnLoad] = useState(false);
    const [quarantineInventoryOnLoad, setQuarantineInventoryOnLoad] = useState(false);
    const [changeLocationSkuOnLoad, setChangeLocationSkuOnLoad] = useState(false);
    const [changeLocationBinBoxOnLoad, setChangeLocationBinBoxOnLoad] = useState(false);
    const [boxQty, setBoxOty] = useState<any>(false);
    const [quantity, setQuantity] = useState<any>();


    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const {
        stockMovementCustList,
        stockMovementSKUList,
        skuListOnLoad,
        stockMovementlocationQTY,
        locationQTYOnLoad,
        stockMovementDataOnLoad,
        stockMovementData,
        stockMovementATTOnLoad,
        stockMovementlocationData,
        moveAdjustInventoryOnload,
        moveQuarantineInventoryOnload,
        changeSKULocationOnload,
        changeBinBoxLocationOnload
    }: any = reducerState;

    useEffect(() => {
        setStockData({ location: "", locationType: "", moveQty: "", assignToMover: "", reason: "", stockMovement: "" })
        stockForm.resetFields();

        stockForm.setFieldsValue({
            ['stockMovement']: "",
            ['location']: "",
            ['locationType']: "",
            ['moveQty']: "",
            ['assignToMover']: "",
            ['reason']: "",
        })
    }, [stock]);
    useEffect(() => {
        if (changeLocationBinBoxOnLoad && !changeBinBoxLocationOnload) {
            closeStock();
            setChangeLocationBinBoxOnLoad(false);
            getData(typeValue);
        }
    }, [changeLocationBinBoxOnLoad, changeBinBoxLocationOnload])

    useEffect(() => {
        if (changeLocationSkuOnLoad && !changeSKULocationOnload) {
            closeStock();
            setChangeLocationSkuOnLoad(false);
            getLocationData(expandLocationData);
            getData(typeValue);
        }
    }, [changeLocationSkuOnLoad, changeSKULocationOnload])

    useEffect(() => {
        if (quarantineInventoryOnLoad && !moveQuarantineInventoryOnload) {
            closeStock();
            setOpenDrawer({ ...openDrawer, openStock: false });
            setQuarantineInventoryOnLoad(false);
            getLocationData(expandLocationData);
            getLocationWiseQty(skuValue);
        }
    }, [quarantineInventoryOnLoad, moveQuarantineInventoryOnload])

    useEffect(() => {
        if (moveAdjustOnLoad == true && moveAdjustInventoryOnload == false) {
            closeStock()
            setOpenDrawer({ ...openDrawer, openStock: false });
            setmoveAdjustInventoryOnLoad(false);
            getLocationData(expandLocationData);

        }
    }, [moveAdjustOnLoad, moveAdjustInventoryOnload])

    useEffect(() => {
        setOpenDrawer({ ...openDrawer, openAttribute: false })
        let payload = {
            "opnfor": '540500',
            "activity": "A-01",
            "warehouse": getWareHouseId(),
            "user": getUserInfo()?.UserName,
            "transaction": getUserInfo()?.CorporateID

        }
        dispatch(getCustomerListForUserAction(payload))
    }, [])

    useEffect(() => {
        if (!skuListOnLoad && onLoad) {
            skuList = (stockMovementSKUList?.lineitems || []).map((item: any) => {
                let Obj = {
                    "value": item?.ItemID
                }
                return Obj
            }
            )
            setSkUOption(skuList)
        }
    }, [onLoad, skuListOnLoad])

    useEffect(() => {
        try {
            if (locationOnLoad && !locationQTYOnLoad) {
                setLocationOnLoad(false)
                selectedSku = (stockMovementSKUList?.lineitems || []).find((item: any) => skuValue === item?.ItemID);
                let qtyValue = (stockMovementlocationQTY?.lineitems || []).map((item: any) => item?.Qty)
                totalLocationQty = qtyValue.reduce((result: any, number: any) => result + number);
                setLocationVisible(true)
                let [location] = stockMovementlocationQTY?.lineitems;
                locationType = location;
                setTypeValue(location?.LocationTypeId);
                getData(location?.LocationTypeId)
            }
        } catch (error) {
            console.log("Error in reduce::", error)
        }
    }, [locationOnLoad, locationQTYOnLoad])

    const onSelect = (value: string) => {
        setSkUValue(value)
        getLocationWiseQty(value)
    };

    useEffect(() => {
        if (stockOnLoad && !stockMovementDataOnLoad) {
            setVisibleTableView(true)
        }
    }, [stockOnLoad, stockMovementDataOnLoad])


    useEffect(() => {

        setSkUValue("")
        setSkUOption("")
        setLocationVisible(false)
        setVisibleTableView(false)
    }, [customer])

    const getData = (e: any) => {
        let payload = {
            "opnfor": '540500',
            "activity": "A-04",
            "warehouse": getWareHouseId(),
            "transaction": skuValue,
            "customer": customer,
            "where": `${e}|${locationType?.Locationtype}`,
            "user": getUserInfo()?.UserName,
        }
        dispatch(getStockMovementDataAction(payload))
        setStockDataOnLoad(true)
    }

    const onChange = ({ target: { value } }: RadioChangeEvent) => {
        locationType = stockMovementlocationQTY?.lineitems?.find((x: any) => value == x?.Locationtype)
        setTypeValue(value);
        getData(locationType?.LocationTypeId)
    };

    const getLocationWiseQty = (sku: any) => {
        setExpandRow(-1);
        setExpandLocationRow(-1);
        setVisibleTableView(false);
        let skuExist = stockMovementSKUList?.lineitems?.find((x: any) => x?.ItemID == sku);
        if (skuExist) {
            let payload = {
                "opnfor": '540500',
                "activity": "A-02",
                "warehouse": getWareHouseId(),
                "transaction": sku,
                "customer": customer,
                "user": getUserInfo()?.UserName,
            }
            dispatch(getLocationWiseQtyAction(payload))
            setLocationOnLoad(true)
        }
        dispatch(clearReducerAction('stockMovementlocationQTY'));
        // else{
        //     message.error("SKU not exist");
        // }
    }

    useEffect(() => {
        if (updateAttributeOnLoad === true && stockMovementATTOnLoad === false) {
            getData(typeValue);
            setOpenDrawer({ ...openDrawer, openAttribute: false });
            getLocationData(expandLocationData);
        }
    }, [updateAttributeOnLoad, stockMovementATTOnLoad])

    const updateAtribute = () => {
        let transaction = `${1}|${attributeDetails?.batch},${2}|${moment(attributeDetails?.manuFactDate).format("DD-MMM-YYYY")},${3}|${moment(attributeDetails?.importMth).format("MMM-YYYY")} ,${4}|${moment(attributeDetails?.expiryDate).format("DD-MMM-YYYY")} ,${5}|${attributeDetails?.mrp}`
        let payload = {
            "opnfor": '540500',
            "activity": "A-05",
            "transaction": AttributeId,
            "where": transaction,
            "user": getUserInfo()?.UserName,
        }
        dispatch(updateAttributeAction(payload));
        setUpdateAttributeOnLoad(true);
    }

    const getLocationTypeList = (e: any) => {
        let payload = {
            "opnfor": '540500',
            "activity": "A-03",
            "transaction": e,
            "customer": customer,
            "user": getUserInfo()?.UserName,
        }
        dispatch(getSKUAction(payload))
        setOnLoad(true)
    }
    const searchSku = (e: any) => {
        setSkUValue(e)
        if (e.length >= 3) {
            getLocationTypeList(e)
        } else if (e === "") {
            setLocationVisible(false)
            setVisibleTableView(false)
            setSkUOption("")
        }
    }

    const getLocationData = (locationId: any) => {
        let payload = {
            "opnfor": '540500',
            "activity": "A-06",
            "warehouse": getWareHouseId(),
            "transaction": skuValue,
            "customer": customer,
            "user": getUserInfo()?.UserName,
            "where": locationId,
            "fieldarray": `${typeValue}|${locationType?.Locationtype}`
        }
        dispatch(getLocationDataAction(payload))
    }

    const menu = () => {
        return (
            <Menu
                className="booth-representative-menu-view"
            >
                <Menu.Item className="booth-representative-menu-view-item" key={1}>
                    <div>{"Download Searched Inventory"}</div>
                </Menu.Item>
                <Menu.Item className="booth-representative-menu-view-item" key={1}>
                    <div>{"Download All Inventory"}</div>
                </Menu.Item>
            </Menu>
        )
    }

    const clearAttributeForm = () => {
        attributeForm.resetFields();
        setAttributeDetails({ qty: "", batch: "", manuFactDate: "", importMth: "", expiryDate: "", mrp: "", reason: "" });
    }

    const closeAttribute = () => {
        setOpenDrawer({ ...openDrawer, openAttribute: false })
        setAttributeDetails({ ...attributeDetails, qty: "", batch: "", manuFactDate: "", importMth: "", expiryDate: "", mrp: "", reason: "" });

        attributeForm.setFieldsValue({
            ['batch']: "",
            ['manuFactDate']: "",
            ['importMth']: "",
            ['expirydate']: "",
            ['mrp']: "",
        })
    }

    const closeStock = () => {
        setOpenDrawer({ ...openDrawer, openStock: false });
        setStockData({ location: "", locationType: "", moveQty: "", bin: "", reason: "", stockMovement: "" })
        stockForm.resetFields()
        stockForm.setFieldsValue({
            ['stockMovement']: "",
            ['location']: "",
            ['locationType']: "",
            ['moveQty']: "",
            ['bin']: "",
            ['reason']: "",
        })
    }

    const openAttributes = (item: any, id: any) => {
        setAttributeId(id)
        setOpenDrawer({ ...openDrawer, openAttribute: true })
        attributeForm.setFieldsValue({
            ['batch']: item[0],
            ['manuFactDate']: (item[1] && item[1] == 'Invalid date') ? null : moment(item[1]),
            ['importMth']: (item[2] && item[2] != 'Invalid date ') ? moment(item[2]) : null,
            ['expirydate']: (item[3] && item[3] != 'Invalid date ') ? moment(item[3]) : null,
            ['mrp']: item[4],
        });
        setAttributeDetails(
            {
                ...attributeDetails,
                batch: item[0],
                manuFactDate: (item[1] && item[1] != 'Invalid date') ? moment(item[1]) : '',
                importMth: (item[2] && item[2] != 'Invalid date ') ? moment(item[2]) : '',
                expiryDate: (item[3] && item[3] != 'Invalid date ') ? moment(item[3]) : '',
                mrp: item[4],
            });
    }




    const openStackMovement = (item: any, attributeId: any) => {
        setExpandedAttributeId(attributeId)
        setBarCode(item?.BoxBarcode)
        setBoxOty(item?.Qty)
        setOpenDrawer({ ...openDrawer, openStock: true });
        setStock(locationType?.Locationtype === "Blocked (Quarantine)" ? "Quarantine Inventory" : "Change SKU Location");
        stockForm.setFieldsValue({
            ['stockMovement']: locationType?.Locationtype === "Blocked (Quarantine)" ? "Quarantine Inventory" : "Change SKU Location",
            ['location']: "",
            ['locationType']: "",
            ['moveQty']: "",
            ['bin']: "",
            ['reason']: "",
        });
        setStockData({ ...stockData, stockMovement: locationType?.Locationtype === "Blocked (Quarantine)" ? "Quarantine Inventory" : "Change SKU Location" });
    }


    const onChangeStock = (e: any) => {

        // setStockData({ ...stockData, stockMovement: e?.target?.value });
        // stockForm.setFieldsValue({
        //     ['stockMovement']: e?.target?.value
        // });
        setStock(e?.target?.value)
    }

    const moveAdjustInventory = () => {
        // if (boxQty >= stockData?.moveQty) {
        let payload = {
            "opnfor": '540500',
            "activity": "A-07",
            "warehouse": getWareHouseId(),
            "customer": customer,
            "user": getUserInfo()?.UserName,
            "transaction": `${barCode}|${stockData?.moveQty}`,
            "fieldarray": expandLocationData,
            "where": skuValue,
            "tableno": stockData?.reason
        }
        dispatch(getMoveAdjustInventoryAction(payload));
        setmoveAdjustInventoryOnLoad(true);
        // } else {
        //     message.error("The move quantity is more than  the box quantity")
        // }
    }


    const moveQuarantineInventory = () => {
        if (boxQty >= stockData?.moveQty) {
            let payload = {
                "opnfor": '540500',
                "activity": locationType?.Locationtype === "Blocked (Quarantine)" ? "A-09" : "A-08",
                "warehouse": getWareHouseId(),
                "customer": customer,
                "user": getUserInfo()?.UserName,
                "transaction": `${barCode}|${stockData?.moveQty}`,
                "fieldarray": expandLocationData,
                "where": skuValue,
                "tableno": stockData?.reason

            }
            dispatch(getQuarantineInventoryAction(payload))
            setQuarantineInventoryOnLoad(true)

        } else {
            message.error("The move quantity is more than  the box quantity")
        }


    }

    const moveChangeSKULocation = () => {
        let payload = {
            "opnfor": '540500',
            "activity": "A-10",
            "warehouse": getWareHouseId(),
            "customer": customer,
            "user": getUserInfo()?.UserName,
            "tableno": stockData?.reason,
            "transaction": `${expandLocationData}|${barCode}`,
            "fieldarray": `${stockData?.location}|${stockData?.bin}`,
            "where": `${skuValue}|${stockData?.moveQty}|${expandedAttributeId}`,
        }
        dispatch(moveChangeSKULocationAction(payload))
        setChangeLocationSkuOnLoad(true)
    }

    const changeBinOrBoxLocation = () => {
        let payload = {
            "opnfor": '540500',
            "activity": "A-11",
            "warehouse": getWareHouseId(),
            "customer": customer,
            "user": getUserInfo()?.UserName,
            "tableno": stockData?.reason,
            "transaction": `${expandLocationData}|${barCode}`,
            "fieldarray": stockData?.location,
            "where": `${skuValue}`

        }
        dispatch(changeBinOrBoxLocationAction(payload))
        setChangeLocationBinBoxOnLoad(true)
    }

    const addstockMovement = () => {
        try {
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={420}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                Move Stock
                            </div>
                            <Button
                                onClick={() => closeStock()}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>
                        </div>
                    }
                    onClose={() => {
                        setOpenDrawer({ ...openDrawer, openStock: false });
                        closeStock();
                    }}
                    open={openDrawer?.openStock}>
                    <div style={{ borderRadius: 2 }}>
                        <Form
                            onFinish={() => stock === "Adjust Inventory" ? moveAdjustInventory() : stock === "Quarantine Inventory" ? moveQuarantineInventory() : stock === "Change Bin/Box Location" ? changeBinOrBoxLocation() : moveChangeSKULocation()}
                            form={stockForm}
                            layout="vertical"
                        >
                            <div className='redhat-bold mt-10'>Select Stock Movement Type</div>
                            <div className="mt-10">
                                <Radio.Group onChange={onChangeStock} value={stock} className='redhat-bold'>
                                    <Space direction="vertical">
                                        {locationType?.Locationtype !== "Blocked (Quarantine)" &&
                                            <Radio value={"Change SKU Location"}>{"Change SKU Location"}</Radio>
                                        }
                                        <Radio value={"Quarantine Inventory"}>{locationType?.Locationtype === "Blocked (Quarantine)" ? "Release Quarantine Inventory" : "Quarantine Inventory"}</Radio>
                                        <Radio value={"Adjust Inventory"}>{"Adjust Inventory"}</Radio>
                                        <Radio value={"Change Bin/Box Location"}>{"Change Bin/Box Location"}</Radio>
                                    </Space>
                                </Radio.Group>
                            </div>
                            {stock !== "Change Bin/Box Location" &&
                                <>
                                    <div className='redhat-bold' style={{ marginTop: 20 }}>
                                        {((stock === "Change SKU Location") || (stock === "Quarantine Inventory")) ?
                                            <>{stock === "Change SKU Location" ? "Enter Move Quantity" : "Quarantine Inventory (In Place)"}</> : <> Quantity To Adjust</>
                                        }
                                    </div>
                                    <div className="mt-10">
                                        <Form.Item
                                            name="moveQty"
                                            rules={[{ required: true, message: "Enter Qty" },
                                            { pattern: /^[0-9]*$/, message: "Enter valid Qty" }
                                            ]}
                                        >
                                            <Input
                                                className='input-field'
                                                onChange={(e: any) => setStockData({ ...stockData, moveQty: e.target.value })}
                                                placeholder={'Enter'}
                                            />
                                        </Form.Item>
                                    </div>
                                </>
                            }

                            {(stock === "Change SKU Location" || stock === "Change Bin/Box Location") &&
                                <>
                                    <div className='redhat-bold mt-10'>To Location</div>
                                    <div className="mt-10">
                                        <Form.Item
                                            name="location"
                                            rules={[{ required: true, message: "Enter location" }]}
                                        >
                                            <Input
                                                className='input-field'
                                                onChange={(e: any) => setStockData({ ...stockData, location: e.target.value })}
                                                placeholder={'Enter or Scan Location'}
                                            />
                                        </Form.Item>
                                    </div>
                                </>
                            }
                            {stock === "Change SKU Location" &&
                                <>
                                    <div className='redhat-bold mt-10'>To Box/Bin</div>
                                    <div className="mt-10">
                                        <Form.Item
                                            name="bin"
                                            rules={[{ required: true, message: "Enter Box/Bin" }]}
                                        >
                                            <Input
                                                className='input-field'
                                                onChange={(e: any) => setStockData({ ...stockData, bin: e.target.value })}
                                                placeholder={'Enter or Scan Box/Bin'}
                                            />
                                        </Form.Item>
                                    </div>
                                </>
                            }
                            <div className="mt-10">
                                <Form.Item
                                    label="Reason"
                                    name="reason"
                                    rules={[{ required: true, message: "Enter Reason" }]}
                                >
                                    <TextArea
                                        className='text-field'
                                        onChange={(e: any) => setStockData({ ...stockData, reason: e.target.value })}
                                        placeholder={'Enter'}
                                        maxLength={250}
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, marginRight: 10, marginBottom: 20 }}>
                                <Button className='cancel-btn'
                                    onClick={() => closeStock()}
                                >
                                    Cancel
                                </Button>
                                <AppButtons
                                    block={true}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                    text={"Move"}
                                    htmlType={'submit'}
                                >
                                </AppButtons>
                            </div>
                        </Form>
                    </div>
                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }
    }


    const addAttribute = () => {
        try {
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={420}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                Change Attribute
                            </div>
                            {/* <Button onClick={() => closeAttribute()}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button> */}
                            <div>
                                <img onClick={() => closeAttribute()} className="c-p" src={AppImages.closeimg} alt='' />
                            </div>
                        </div>
                    }
                    onClose={() => {
                        setOpenDrawer({ ...openDrawer, openAttribute: false });
                        clearAttributeForm();
                    }}
                    open={openDrawer?.openAttribute}>
                    <div style={{ borderRadius: 2 }}>
                        <Form
                            onFinish={updateAtribute}
                            form={attributeForm}
                        >
                            <div className='redhat-bold mt-10'>Batch ID</div>
                            <div className="mt-10">
                                <Form.Item
                                    name="batch"
                                    rules={
                                        [
                                            { required: true, message: "Enter Batch ID" },
                                            { pattern: /^[a-zA-Z0-9]*$/, message: "Enter Valid Batch ID" }
                                        ]
                                    }                                >
                                    <Input
                                        className='input-field'
                                        onChange={(e: any) => setAttributeDetails({ ...attributeDetails, batch: e.target.value })}
                                        placeholder={'Enter'}
                                    />
                                </Form.Item>
                            </div>
                            <div className='redhat-bold mt-10'>Manufacturing date</div>
                            <div className="mt-10">
                                <Form.Item name="manuFactDate">
                                    <DatePicker 
                                        format={"DD-MMM-YYYY"}
                                        disabledDate={(e) => e > moment()}
                                        onChange={(e: any) => setAttributeDetails({ ...attributeDetails, manuFactDate: moment(e).format("DD-MMM-YYYY") })}
                                        className='input-field'
                                    />
                                </Form.Item>
                            </div>
                            <div className='redhat-bold mt-10'>Import month</div>
                            <div className="mt-10">
                                <Form.Item name="importMth">
                                    <DatePicker
                                        disabled={!attributeDetails?.manuFactDate}
                                        picker="month"
                                        format={"MMM-YYYY"}
                                        className='input-field'
                                        disabledDate={(e) => attributeDetails?.manuFactDate && (e < moment(attributeDetails?.manuFactDate) || e > moment())}
                                        onChange={(e: any) => setAttributeDetails({ ...attributeDetails, importMth: moment(e).format("MMM-YYYY") })}
                                    />
                                </Form.Item>
                            </div>
                            <div className='redhat-bold mt-10'>Expiry date</div>
                            <div className="mt-10">
                                <Form.Item name="expirydate">
                                    <DatePicker format={'DD-MM-YYYY'}
                                        disabled={!attributeDetails?.manuFactDate}
                                        disabledDate={(e: any) => (e < moment(attributeDetails?.manuFactDate).add(3, 'M'))}
                                        className='input-field'
                                        onChange={(e: any) => setAttributeDetails({ ...attributeDetails, expiryDate: moment(e).format("DD-MMM-YYYY") })}
                                    />
                                </Form.Item>
                            </div>
                            <div className='redhat-bold mt-10'>MRP</div>
                            <div className="mt-10">
                                <Form.Item
                                    name="mrp"
                                    rules={
                                        [
                                            { required: true, message: "Enter Valid MRP" },
                                            { pattern: /^[0-9]*$/, message: "No alphabets or Special Character allowed" }
                                        ]
                                    }
                                >
                                    <Input
                                        onChange={(e: any) => setAttributeDetails({ ...attributeDetails, mrp: e.target.value })}
                                        className='input-field'
                                        placeholder='Enter'
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, marginRight: 10, marginBottom: 20 }}>
                                <Button className='cancel-btn'
                                    onClick={() => closeAttribute()}
                                >
                                    Cancel
                                </Button>
                                <AppButtons
                                    block={true}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                    text={"Save"}
                                    htmlType={'submit'}
                                >
                                </AppButtons>
                            </div>
                        </Form>
                    </div>
                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }

    }

    const titleContainer = () => {
        try {
            let Client = (stockMovementCustList?.Clients || []).find((item: any) => customer == item?.Id)
            storageType = stockMovementData?.lineitemstwo[0].find((item: any) => item?.StorageType)
            let TotalQty = stockMovementData?.lineitemstwo[0].Qty
            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center", fontFamily: "RedHat-Medium", }}>SKU:<div style={{ marginLeft: 5, fontFamily: "RedHat-Bold" }}>{selectedSku?.ItemID},{selectedSku?.Description}</div></div>
                    <div style={{ marginLeft: 15, display: "flex", alignItems: "center", fontFamily: "RedHat-Medium", }}>Client: <div style={{ marginLeft: 5, fontFamily: "RedHat-Bold" }}>{Client?.Name}</div></div>
                    <div style={{ marginLeft: 15, display: "flex", alignItems: "center", fontFamily: "RedHat-Medium", }}>Storage Type: <div style={{ marginLeft: 5, fontFamily: "RedHat-Bold" }}>{storageType?.StorageType}</div></div>
                    <div style={{ marginLeft: 15, display: "flex", alignItems: "center", fontFamily: "RedHat-Medium", }}>Total Qty: <div style={{ marginLeft: 5, fontFamily: "RedHat-Bold" }}>{storageType ? storageType?.Qty : totalLocationQty}</div></div>
                </div>
            )
        } catch (ex) {
            console.log("Err in titleContainer::" + ex)
        }
    }

    const header = () => {
        return (
            <SubHeader
                title={titleContainer()}
                paginationView="false"
                inputView="true"
                btnView="false"
            >
                <div className='d-flex flex-end'>
                    <div className="preview-button-container" style={{ cursor: "pointer" }}>
                        <div>
                            <Dropdown
                                overlayStyle={{ width: 244, left: 1094 }}
                                overlay={() => menu()} placement="bottomRight" trigger={['click']}>
                                <div><Array /></div>

                            </Dropdown>
                            <div style={{ marginBottom: 5, marginTop: -5 }}>Download</div>
                        </div>
                    </div>
                    <div className="preview-button-container" style={{ cursor: "pointer", marginLeft: 10 }}>
                        <div>
                            <div style={{ marginBottom: 2, marginTop: 10 }}><ReloadOutlined /></div>
                            <div style={{ marginBottom: 5, marginTop: -5 }}>Refresh</div>
                        </div>
                    </div>
                </div>
            </SubHeader>
        )
    }

    const tooltip = (data: any) => {
        return (
            <Tooltip placement="top" title={data}>
                {data}
            </Tooltip>
        )
    }

    const totalQtyFomat = (id: any) => {
        try {
            let tempArr = isArrayNotEmpty(stockMovementlocationData?.lineitemstwo[0]) &&
                stockMovementlocationData?.lineitemstwo[0].find((e: any) => e?.AttributeID == id);
            return tempArr?.Qty
        } catch (error) {
            console.log("Error in totalQtyFomat", error);

        }
    }


    const tableContent = (item: any, index: any, id: any) => {
        let innerExpanderOpen = index == innerExpander;
        return (
            <div className={"content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={3} className="open-icon d-flex align-c">
                        <CaretRightOutlined
                            onClick={(e: any) => handleInnerExpand(e, index)}
                            className={innerExpanderOpen ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='text-ellipsis' >
                            {tooltip(item[0])}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-2'  >
                            {(item[1] == 'Invalid date' || item[1] == undefined) ? '-' : item[1]}
                        </div>
                    </Col>
                    <Col span={3} className=' d-flex align-c jc-c'>
                        <div className='text-2' >
                            {(item[2] == 'Invalid date ' || item[2] == undefined) ? '-' : item[2]}
                        </div>
                    </Col>
                    <Col span={4} className=' d-flex align-c jc-c'>
                        <div className='text-2' >
                            {(item[3] == 'Invalid date ' || item[3] == undefined) ? '-' : item[3]}
                        </div>
                    </Col>
                    <Col span={3} className=' d-flex align-c jc-c'>
                        <div className='text-2' >
                            {item[4]}
                        </div>
                    </Col>
                    <Col span={3} className=' d-flex align-c jc-c'>
                        <div className='text-2'>
                            {totalQtyFomat(id) ? totalQtyFomat(id) : "-"}
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className='d-flex align-c jc-sb'
                            style={{ cursor: " pointer" }}
                        //onClick={() => openEditDrawerFields(item)}
                        >
                            <div className='stock-action-btn' onClick={() => openAttributes(item, id)}>Change Attribute</div>
                            {/* <div className='stock-action-btn' style={{ marginLeft: 5 }} onClick={() => openStackMovement()}>Manage Stock</div> */}
                        </div>
                    </Col>
                    {innerExpanderOpen &&
                        <Col span={24}>
                            {isArrayNotEmpty(stockMovementlocationData?.lineitemstwo[0]) ?
                                <div style={{ marginTop: '10px' }} className="barListContainer">
                                    {barList(stockMovementlocationData?.lineitemstwo[0], id)}
                                </div>
                                :
                                <div className='d-flex align-c jc-c'>
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        imageStyle={{
                                            height: 40,
                                        }}
                                        description={
                                            <div className='noData'>
                                                {AppConstants.noDataAvailable}
                                            </div>
                                        }
                                    >
                                    </Empty>
                                </div>
                            }
                        </Col>
                    }
                </Row>
            </div>
        )

    }

    const tableView = (value: any, index: any) => {
        try {
            let dataArr = (value?.Values).split(",")
            let valueId = value?.ID;
            return (
                <>
                    {tableContent(dataArr, index, valueId)}
                </>
            )
        } catch (ex) {
            console.log("Err in tableView::" + ex)
        }
    }

    const subTableHeaderView = () => {
        return (
            <Row className='table-header header-color'>
                <Col span={3} style={{ color: 'black' }}>
                    {"Batch ID"}
                </Col>
                <Col span={4} className=' d-flex jc-c' style={{ color: 'black' }}>
                    {"MFG Date"}
                </Col>
                <Col span={3} className=' d-flex jc-c' style={{ color: 'black' }}>
                    {"Import Month"}
                </Col>
                <Col span={4} className=' d-flex jc-c' style={{ color: 'black' }}>
                    {"Expiry Date"}
                </Col>
                <Col span={3} className=' d-flex jc-c' style={{ color: 'black' }}>
                    {"MRP"}
                </Col>
                <Col span={3} className=' d-flex jc-c' style={{ color: 'black' }}>
                    {"Qty"}
                </Col>
                <Col span={4} className=' d-flex jc-c' style={{ color: 'black' }}>
                    {"Action"}
                </Col>
            </Row>
        )
    }

    const handleLocationExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandLocationRow) {
            setExpandLocationRow(-1)
        }
        else {
            setExpandLocationRow(index);
            setExpandLocationData(data?.Name)
            setExpandedAttributeId(data?.AttributeID)
            getLocationData(data?.Name)
        }
    }

    useEffect(() => {
        if (expandLocationRow == '-1') {
            setInnnerExpander(-1)
        }
    }, [expandLocationRow])

    const handleInnerExpand = (e: any, index: any) => {
        e.stopPropagation();
        if (index == innerExpander) {
            setInnnerExpander(-1)
        }
        else {
            setInnnerExpander(index);
        }
    }

    const getBarCode = (item: any) => {
        try {
            let barcodeUrl = "http://bwipjs-api.metafloor.com/?bcid=code128&text=" + item?.BoxBarcode;
            return (
                <div className='bar-code-img'><img src={barcodeUrl} alt="" /></div>
            )
        } catch (ex) {
            console.log("Error in getBarCode::" + ex)
        }
    }

    const viewBarCode = (value: any, attributeId: any) => {
        try {
            return (
                <>
                    {/* {attributeMatch === true && */}
                    <>
                        {(value?.Boxes[0] === null) ?
                            <div style={{ marginTop: 10, display: "flex", justifyContent: "center", alignItems: "center", fontSize: 17, fontFamily: 'RedHat-Bold' }}>No Box Available</div> :
                            <Row className='box-container mt-10' gutter={20}>
                                {(value?.Boxes || []).map((x: any) => {
                                    return (
                                        <Col span={6}>
                                            <div
                                                className={'Scanned-barcode-button'}>
                                                <div title='Manage Stock'
                                                    className='manageStockIconContainer'
                                                    onClick={() => openStackMovement(x, attributeId)}
                                                >
                                                    <div>{x?.Invoice}</div>
                                                    <EditFilled />
                                                </div>
                                                <div className='d-flex align-c jc-sa ' style={{ marginTop: 10 }}>
                                                    {locationType?.Locationtype === "Sellable" ?
                                                        <div className='d-flex align-c bin-container-green'>
                                                            {x?.Qty}
                                                        </div> :
                                                        <>
                                                            {locationType?.Locationtype === "Blocked (Quarantine)" ?
                                                                <div className='d-flex align-c bin-container-ambur'>
                                                                    {x?.Qty}
                                                                </div> :
                                                                <div className='d-flex align-c bin-container-red-1'>
                                                                    {x?.Qty}
                                                                </div>
                                                            }
                                                        </>

                                                    }

                                                    <div className='rip-count'>{x?.XofY ? x?.XofY : 0 / 0}</div>
                                                </div>
                                                {/* <div style={{ marginTop: 5 }}>
                                                        {getBarCode(x)}
                                                    </div> */}
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        }
                    </>
                    {/* } */}
                </>
            )

        } catch (ex) {
            console.log("Err in viewBarCode::" + ex)
        }
    }

    const barList = (dataArray: any, id: any) => {
        try {
            let currentExpanderData = dataArray?.filter((e: any) => id === e?.AttributeID);
            return (
                <>
                    {(currentExpanderData || []).map((value: any) => {

                        return (
                            <>
                                {viewBarCode(value, id)}
                            </>
                        )
                    })}
                </>
            )
        } catch (ex) {
            console.log("Error in barList::" + ex)
        }
    }




    const skuDetailsView = (item: any, index: any) => {
        try {
            let locationOpen = index == expandLocationRow
            return (
                <div style={{ marginBottom: 10 }}>
                    <div

                        className={!locationOpen ? 'table-view-container beforeExpand' : locationType?.Locationtype === "Sellable" ? " table-view-container  green-expand" : locationType?.Locationtype === "Blocked (Quarantine)" ? " table-view-container  repair-expand" : "table-view-container  bad-expand"}

                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CaretRightOutlined
                                onClick={(e: any) => handleLocationExpand(e, index, item)}
                                className={locationOpen ? "arrow arrow-down" : "arrow arrow-right"}
                            />
                            <Row gutter={16} style={{ fontFamily: "RedHat-Bold", fontSize: 17, width: "100%", marginLeft: 10 }}>
                                <Col span={17}>
                                    Location Id: <span>{item?.Name}</span>
                                </Col>
                                <Col span={7}>QTY: <span>{item?.Qty}</span></Col>
                            </Row>
                        </div>
                        {locationOpen &&
                            <div style={{ background: "white", borderRadius: 6, paddingTop: 1, paddingBottom: 1 }}>
                                <div className='table-screen-container'>
                                    {subTableHeaderView()}
                                    {isArrayNotEmpty(stockMovementlocationData?.lineitems) ?
                                        <>{(stockMovementlocationData?.lineitems || []).map((x: any, index: any) => tableView(x, index))}</>
                                        :
                                        <NoData className='height-class' />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )

        } catch (ex) {
            console.log("Err in skuDetailsView::" + ex)
        }

    }

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1);
            setExpandLocationRow(-1);
        }
        else {
            setExpandRow(index);
        }
    }

    const tableContentView = (item: any, index: any) => {
        try {
            let open = index == expandRow;
            return (
                <div className={open ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={12} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                            <CaretRightOutlined
                                className={open ? "arrow arrow-down" : "arrow arrow-right"}
                            />
                            <div className='text-3'>
                                {item?.StructureType}
                            </div>
                        </Col>
                        <Col span={12} className=' d-flex align-c jc-c'>
                            <div className='text-3'>
                                {item?.Qty}
                            </div>
                        </Col>
                        {open && <Col span={24} className="sku-details location-view-scroll">
                            {(item?.Locations || []).map((x: any, index: any) => skuDetailsView(x, index))}
                        </Col>}
                    </Row>
                </div>
            )
        } catch (ex) {
            console.log("Err in tableContentView::" + ex)
        }
    }


    const tableBodyView = (x: any) => {
        return (
            <>
                {(x?.StructureType || []).map((item: any, index: any) => {
                    return (
                        <>{tableContentView(item, index)}</>
                    )
                })}

            </>
        )
    }

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={12}>
                    {"Structure Type"}
                </Col>
                <Col span={12} className=' d-flex jc-c'>
                    {"Qty In Stock"}
                </Col>
            </Row>
        )
    }

    const onChangeSKULocation = (e: any) => {
        setOption(e.target.value)
    }

    const locationView = () => {
        try {
            return (
                <div className='location-container' style={{ padding: 10 }}>
                    <Row gutter={16} style={{ marginTop: 10 }}>
                        <Col span={12}>
                            <Form.Item
                                name="storageid"
                                label="Storage Type"
                            // rules={[{ required: true, message: 'Select Storage Type' }
                            // ]}
                            >
                                <Select
                                    className='width'
                                    placeholder="Select"
                                    onChange={(e) => setLocationData({ ...locationData, storageType: e.target.value })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="strctureType"
                                label="Strcture Type"
                            // rules={[{ required: true, message: 'Select Strcture Type' }
                            // ]}
                            >
                                <Select
                                    className='width'
                                    placeholder="Select"
                                    onChange={(e) => setLocationData({ ...locationData, strctureType: e.target.value })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ marginTop: 10 }}>
                        <Col span={12}>
                            <Form.Item
                                name="locationID"
                                label="Location ID"
                            // rules={[{ required: true, message: "Select Location Id" }]}
                            >
                                <Select
                                    className='width'
                                    placeholder="Select"
                                    onChange={(e) => setLocationData({ ...locationData, locationID: e.target.value })}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="locationType"
                                label="Location Type"
                            // rules={[{ required: true, message: 'Select Location Type' }
                            // ]}
                            >
                                <Select
                                    className='width'
                                    placeholder="Select"
                                    onChange={(e) => setLocationData({ ...locationData, locationType: e.target.value })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, marginRight: 10, marginBottom: 20 }}>
                        <AppButtons
                            block={true}
                            buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                            text={"Save"}
                            htmlType={'submit'}
                        >
                        </AppButtons>
                    </div>
                </div>
            )
        } catch (ex) {
            console.log("Err in locationView::" + ex)
        }
    }

    const skuDataView = () => {
        return (
            <>
                {visibleTableView === true ?
                    <div className='right-column'>
                        {header()}
                        <div className='table-screen-container' style={{ marginTop: '15px' }}>
                            {tableHeaderView()}
                            {isArrayNotEmpty(stockMovementData?.lineitemstwo) ?
                                <>
                                    {(stockMovementData?.lineitemstwo[0] || []).map((x: any) => tableBodyView(x))}
                                </> :
                                <NoData className='height-class' />

                            }
                            {addAttribute()}
                            {addstockMovement()}
                        </div>
                    </div>
                    :
                    <NoData />
                }
            </>
        )
    }

    const locationTypeView = () => {
        return (
            <div className='sku-list-container'>
                <div className='sku-title'>
                    <div>Location Type</div>
                    <div>Qty In Stock</div>
                </div>
                <div className='radio-container'>
                    <Radio.Group
                        buttonStyle="solid"
                        // onChange={onChange}
                        value={typeValue} >
                        {(stockMovementlocationQTY?.lineitems || []).map((item: any) => {
                            return (
                                <Radio.Button
                                    onClick={(e: any) => onChange(e)}
                                    value={item.Locationtype}
                                    className={item?.Locationtype === "Sellable" ? "radio-good" : item?.Locationtype === "Blocked (Quarantine)" ? "radio-repair" : "radio-bad"}
                                >
                                    <div className="card-title" >
                                        <div className='label'>{item?.Locationtype}</div>

                                        <div className="count">
                                            {item.Qty}
                                        </div>
                                    </div>
                                </Radio.Button>
                            )
                        })}
                    </Radio.Group>
                </div>
            </div>
        )
    }

    const skuSelectView = () => {
        return (
            <div className='left-column'>
                <div className='sku-location'>
                    <Radio.Group value={option} onChange={onChangeSKULocation}>
                        <Radio className='radio' value={"sku"} style={{ fontFamily: "RedHat-Bold" }}>SKU</Radio>
                        <Radio className='radio' style={{ marginLeft: 45, fontFamily: "RedHat-Bold" }} value={"location"} >Location</Radio>
                    </Radio.Group>
                </div>
                {option === "sku" &&
                    <div className='customer-container'>
                        <div className='customer' style={{ fontFamily: "RedHat-Bold", }}>
                            {AppConstants.customer}
                        </div>
                        <div>
                            <Select
                                onChange={(e: any) => setCustomer(e)}
                                className='width'
                                placeholder="Select" >
                                {(stockMovementCustList?.Clients || []).map((x: any) => {
                                    return (
                                        <Option value={x?.Id}>{x?.Name}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                }
                {option === "location" &&
                    <Form
                        //onFinish={saveSkuApi}
                        form={form}
                        layout="vertical"
                    >
                        <>
                            {locationView()}
                        </>
                    </Form>
                }
                <div className='search-container'>
                    <AutoComplete
                        options={skuOption}
                        onSelect={onSelect}
                        value={skuValue}
                    >
                        <Input
                            onPressEnter={(e: any) => getLocationWiseQty(e?.target?.value)}
                            onChange={(e: any) => searchSku(e?.target?.value)}
                            allowClear
                            suffix={<div onClick={() => getLocationWiseQty(skuValue)}><SearchOutlined /></div>}
                            placeholder='Search using SKU' />
                    </AutoComplete>
                </div>
                {locationVisible === true &&
                    locationTypeView()
                }
            </div>
        )
    }

    const contentView = () => {
        return (
            <Row style={{ height: "93.8vh" }}>
                <Col span={6} >
                    {skuSelectView()}
                </Col>
                <Col span={18} >
                    {skuDataView()}
                </Col>
            </Row>
        )
    }

    return (
        <Layout>
            <NavbarLayout
                titleContent="main-head"
                process={stockMovementCustList?.title}
                initial={AppModuleIds.main}
                selectedMain={'6'}
            />
            {contentView()}
            <SpinLoader loading={locationOnLoad} />
        </Layout>

    );
}

export default StockMomentScreen;