import { Col, Divider, Row } from "antd";
import React from "react";
import "../Header/Header.scss"
interface HeaderComponentProps {
  children: any,
  title: any,
  paginationView?: any,
  btnView: any,
  inputView?: any,
  fromOrgin?: String,
  className?: String
}

function SubHeader(props: HeaderComponentProps) {
  const {
    title,
    paginationView,
    btnView,
    inputView,
    children,
    fromOrgin,
    className
  } = props;

  return (
    <div>
      <Row className={ `title-header ${className}`}>
        <Col span={(btnView === "true") ? (fromOrgin == 'custom-sticker') ? 8 : 6 : 18}>
          <div style={{width:'100%'}} className="title">{title}</div>
        </Col>
        <Col style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}} span={(btnView === "true") ?(fromOrgin == 'custom-sticker') ? 16 : 18 : 6}>
          {children}
        </Col>
      </Row>
      <Divider />
    </div>

  );
}

export default SubHeader;
