import { Row, Col, Layout} from 'antd';
import React, { useState } from 'react';
import CardContainer from '../CardContainer';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import '../CardContainer.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import './RtoReturn.scss';
import NewManifestScreen from './NewManifestScreen';
import CreateRTOManifest from './CreateRTOManifest';
import MarkAsProcessed from './MarkAsProcessed';

function RTOReturnScreen(props: any) {
    const [process, setProcess] = useState("1");

    return (
        <div>
            {true ?
                <NewManifestScreen />
                :
                true ?
                <CreateRTOManifest />
                :
                <MarkAsProcessed />
            }
        </div>
    );
}

export default RTOReturnScreen;