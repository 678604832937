import React, { useState } from 'react';
import { CaretRightOutlined, EyeFilled } from '@ant-design/icons';
import './ExpandCollapseContainer.scss';
import { Link } from 'react-router-dom';
import { Button, Col, Progress, Row } from 'antd';

interface ExpandCollapseComponentProps {
    children: any,
    item: any,
    index: Number,
    key: any,
    setExpandRow: any,
    expandedRow: Number,
    path: any,
    expandIconView: any,
    clientId?: any
}

function ExpandCollapseContainer(props: ExpandCollapseComponentProps) {
    const {
        children,
        item,
        index,
        key,
        setExpandRow,
        expandedRow,
        path,
        expandIconView,
        clientId
    } = props;

    const handleExpand = () => {
        let Id = item?.id?.split("|");
        clientId(Id[0]);
        setExpandRow(index === expandedRow ? 0 : index, item)
    }
    let Id = item?.id?.split("|");

    const containerView = () => {
        try {
            return(
            <div className="d-flex">
            <div className={index === expandedRow? "content-container1":"content-container"}>
                <Row style={{ display: 'flex', alignItems: "center" }}>
                    <Col span={path == "/put-away" ? 12 : 5}>
                        <div className="title-container">
                            <div style={{ display: "flex" }}>
                                <CaretRightOutlined
                                    onClick={() => handleExpand()}
                                    className={index === expandedRow ? "arrow arrow-down" : "arrow arrow-right"} />

                                <div className='mama-container' style={{ width: 150 }}>
                                    <div className={path == "/put-away" ? 'list-num-2' : 'list-num'} title={Id[2]}> {Id[2]}</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={path === "/grnpending" ? 3 : path == "/put-away" ? 12 :  4}>
                        <div className='text'>  {item?.name}</div>
                    </Col>
                    {path != "/put-away" && <Col span={3}>
                        <div className='text'>-</div>
                    </Col>}
                    {path != "/put-away" && <Col span={path === "/grnpending" ? 3 : 4}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className='text'>-</div>
                        </div>
                    </Col>}
                    {path != "/put-away" && <Col span={path === "/grnpending" ? 2 : 3}>
                        <div className='text'>-</div>
                    </Col>}
                   {path != "/put-away" && <Col span={4}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className='text'>-</div>
                        </div>
                    </Col>}
                    {(path === "/grnpending" && path != "/put-away") &&
                        <Col span={3}>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <div className='status-btn'>
                                    -
                                </div>
                            </div>
                        </Col>
                    }

                   {path != "/put-away"  && <Col span={1}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Link to={path}>
                                <Button className="eye-btn" >
                                    <EyeFilled />
                                </Button>
                            </Link>
                        </div>
                    </Col>}
                </Row>
                {(index === expandedRow) && (
                    <div className="content-inner-container">
                        {children}
                    </div>
                )}
            </div>
            </div>
            );
        } catch (error) {
            console.log("Error in containerView::", error)
        }
    }
    return (
        <div
            key={key}
            className='expand-collape-container'
        >
           {containerView()}
        </div>
    );
}

export default ExpandCollapseContainer;