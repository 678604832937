import { CaretRightOutlined, FilterFilled } from '@ant-design/icons';
import { Checkbox, Col, Dropdown, Menu, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { isArrayNotEmpty, uniqueBy } from '../../Globals/Helper';
import EmizaIcon from '../../Images/emizaIcon';
import { GetExpandSANReportList, GetSANReportList } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import "../Report/Report.scss"
import SkeletonLoader from '../OrderProcessing/SkeletonLoader';
import WMSIcon2 from '../../Images/WMSIcon2';

let Arr: any = []
let uniqueClient: any = [];


function SANReport(props: any) {
    const {
        setSearchView,
        searchView,
        setDateView,
        dateView
    } = props
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const { reportSANList, reportExpandSANList, reportSearchGateInList, reportDateList, reportSANOnload } = reducerState;
    const [filterData, setFilterData] = useState<any>();
    const [filterDataView, setFilterDataView] = useState<any>(false)
    const [OnLoad, setOnLoad] = useState<any>(false)
    const [expandRow, setExpandRow] = useState<any>();
    let data = reportExpandSANList?.lineitems[0];

    useEffect(() => {
        if (OnLoad === true && reportSANOnload === false) {
            setFilterDataView(false)
            setSearchView(false)
            setDateView(false)
            getFilterData()
        }
    }, [OnLoad, reportSANOnload])

    const getData = (data: any) => {
        let payload = {
            "opnfor": "R00003",
            "activity": "A-02",
            "transaction": data ? data?.GateInID : 0,
            "where": data ? "Y" : "N",
            "customer": data ? data?.CustCode : 0,
            "warehouse": getWareHouseId(),
            "user": getUserInfo()?.UserName
        }
        data ? dispatch(GetExpandSANReportList(payload)) : dispatch(GetSANReportList(payload))
        setOnLoad(true)
    }



    useEffect(() => {
        getData(null)
    }, [])

    const getSearchFilterData = () => {
        let arr = (reportSearchGateInList?.lineitems || []).map((item: any) => {
            let Obj = {
                "ClientName": item?.ClientName
            }
            return Obj
        })
        uniqueClient = uniqueBy(arr, 'ClientName')
    }

    const getDateFilterData = () => {
        let arr = (reportDateList?.lineitems || []).map((item: any) => {
            let Obj = {
                "ClientName": item?.ClientName
            }
            return Obj
        })
        uniqueClient = uniqueBy(arr, 'ClientName')
    }

    const getFilterData = () => {
        let arr = (reportSANList?.lineitems || []).map((item: any) => {
            let Obj = {
                "ClientName": item?.ClientName
            }
            return Obj
        })
        uniqueClient = uniqueBy(arr, 'ClientName')
    }

    useEffect(() => {
        if (reportDateList) {
            setDateView(true)
            setSearchView(false)
            setFilterDataView(false)

        }
    }, [reportDateList])

    useEffect(() => {
        if (reportSearchGateInList) {
            setSearchView(true)
            setDateView(false)
            setFilterDataView(false)

        }
    }, [reportSearchGateInList])

    useEffect(() => {
        if (filterData?.length == 0) {
            setFilterDataView(false)
            setSearchView(false)
            setDateView(false)
        }
    }, [filterData])

    const filterTableData = () => {
        let filterData = (dateView ? reportDateList?.lineitems : searchView ? reportSearchGateInList?.lineitems : reportSANList?.lineitems).filter((item: any) => {
            return Arr.includes(item?.ClientName)
        });
        setFilterData(filterData)
        setFilterDataView(true)
        setSearchView(false)
        setDateView(false)
    }

    useEffect(() => {
        if (searchView === false) {
            getFilterData()
        } else {
            getSearchFilterData()
        }
    }, [searchView])

    useEffect(() => {
        if (dateView === false) {
            getFilterData()
        } else {
            getDateFilterData()
        }
    }, [dateView])


    const onChange = (e: CheckboxChangeEvent) => {

        if (e?.target.checked) {
            Arr.push(e?.target.value)
            filterTableData()
        } else {
            Arr = Arr.filter((item: any) => item !== e?.target.value)
            filterTableData()
        }


    };

    const menu = () => {
        return (
            <Menu
                className="booth-representative-menu-view"
            >
                {(uniqueClient || []).map((item: any) => {
                    return (
                        <Menu.Item className="booth-representative-menu-view-item" key={1}>
                            <div> <Checkbox value={item?.ClientName} onChange={onChange}>{item?.ClientName}</Checkbox></div>
                        </Menu.Item>
                    )
                })}
            </Menu>
        )
    }


    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
            getData(data)
        }
    }

    const EmailView = () => {
        try {
            return (
                <div className='send-san-container'>
                    <div className='emiza-logo-container wms360-logo-container'><WMSIcon2 /></div>
                    <div className='template-view'>
                        <div className='content'>
                            <div style={{ fontFamily: "sans-serif", fontSize: 18, color: 'GrayText' }}>Dear Customer,</div>
                            <div style={{ fontFamily: "sans-serif", fontSize: 18, color: 'GrayText', marginTop: 10 }}>Greeting from Emiza,</div>
                            {/* <div className='notes-sub1-title' style={{ marginBottom: 10, marginTop: 10 }}> This is shipment Arrival Notice for consignment number </div> */}
                        </div>
                        <div id='sanFinalMail'>
                            <div style={{ fontSize: 18, fontFamily: "sans-serif", color: "GrayText", marginLeft: 20, marginTop: 5 }}><span>This is Shipment Arrival Notice (SAN) for Consignment Number: {data?.GateEntryRecordNo}</span></div>
                            <div>
                                <div style={{ background: '#F0F0F4', padding: 20, marginTop: 10 }}>
                                    <table >
                                        <tr style={{ fontSize: 16, fontFamily: "sans-serif", color: "#FFFFFF", backgroundColor: "#022E3A", fontWeight: 100 }}>
                                            <th style={{ width: 400, textAlign: "center", padding: 6, fontWeight: 200 }}>Invoice Number</th>
                                            <th style={{ width: 400, textAlign: "center", padding: 6, fontWeight: 200 }}>Invoice Box Qty</th>
                                            <th style={{ width: 800, textAlign: "center", padding: 6, fontWeight: 200 }}>Total Unloaded boxes including Damaged</th>
                                            <th style={{ width: 800, textAlign: "center", padding: 6, fontWeight: 200 }}>Received Damaged Box Qty</th>

                                        </tr>
                                        {((reportExpandSANList?.lineitems) || []).map((data: any) => {
                                            return (
                                                <tr style={{ fontSize: 18, fontFamily: "sans-serif", color: "#232323", backgroundColor: "rgb(255, 255, 255)", marginTop: 5 }}>
                                                    <td style={{ width: 400, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{data?.InvoiceNo}</td>
                                                    <td style={{ width: 400, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{data?.InvoiceBoxQty}</td>
                                                    <td style={{ width: 800, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{data?.RecievedBoxQty}</td>
                                                    <td style={{ width: 800, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{data?.RecievedDamagedBoxQty}</td>
                                                </tr>
                                            )
                                        })}
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* <div>test&nbsp;comment&nbsp;<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;test&nbsp;comment</div> */}

                        <div style={{ marginBottom: 10, marginTop: 10, fontFamily: "sans-serif", fontSize: 18, color: "GrayText" }}> For any queries/concerns please contact your warehouse manager</div>
                        <div className='regard-container' style={{ marginTop: 20 ,color: "GrayText"}}>
                            <div style={{ fontFamily: "sans-serif", fontSize: 18 }}>{AppConstants.bestRegards}</div>
                            <div style={{ fontFamily: "sans-serif", fontSize: 16 }}>Emiza Warehouse Manager</div>
                            <div style={{ fontFamily: "sans-serif", fontSize: 16 }}>{data?.WarehouseName}<span >, {data?.AddressLine1}</span></div>
                            <div style={{ fontFamily: "sans-serif", fontSize: 16 }}>{data?.City}<span>-{data?.PinCode}</span>, <span>{data?.State}</span></div>
                        </div>
                    </div>
                </div>
            )
        } catch (ex) {
            console.log("Err in EmailView" + ex)
        }
    }
    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={8}>
                    {"GateIn ID"}
                </Col>
                <Col span={8} className=' d-flex jc-c'>
                    {"Clients"}
                    <span style={{ marginLeft: 20 }}>
                        <Dropdown
                            overlayStyle={{ width: 244, left: 1094 }}
                            overlay={() => menu()} placement="bottomRight" trigger={['click']}>
                            <div><FilterFilled /></div>

                        </Dropdown>
                    </span>
                </Col>
                <Col span={8} className=' d-flex jc-c'>
                    {"SAN Sent Date & Time"}
                </Col>
            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        return (
            <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={8} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='title-blue'>
                            {item?.GateInID}
                        </div>
                    </Col>
                    <Col span={8} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.ClientName}
                        </div>
                    </Col>
                    <Col span={8} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.SANDateAndTime}
                        </div>
                    </Col>
                    {open && <Col span={24}>
                        <div style={{ marginTop: 10 }} className='send-san-view-screen-container'>
                            {EmailView()}
                        </div>
                    </Col>}
                </Row>
            </div >
        )
    }
    return (
        <div className='GateInReport-Container'>
            {(dateView ? isArrayNotEmpty(reportDateList?.lineitems) : searchView ? isArrayNotEmpty(reportSearchGateInList?.lineitems) : filterDataView ? isArrayNotEmpty(filterData) : isArrayNotEmpty(reportSANList?.lineitems)) ?
                <div style={{ padding: 20 }}>
                    {tableHeaderView()}
                    <div className='table-scroll'>
                        {(dateView ? reportDateList?.lineitems : searchView ? reportSearchGateInList?.lineitems : filterDataView ? isArrayNotEmpty(filterData) ? filterData : reportSANList?.lineitems : reportSANList?.lineitems || []).map((x: any, index: any) => tableBodyView(x, index))}
                    </div>
                </div>
                :
                <div className='p-20'>
                    <NoData
                        className='mt-45'
                        description={
                            <>There is no reports available in <span className='redhat-bold ml-5'>SAN</span></>
                        }
                    />
                </div>
            }
        </div>
    );
}

export default SANReport;