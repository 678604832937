import { CaretRightOutlined, CloseCircleFilled, CloseOutlined, EyeFilled, SearchOutlined } from '@ant-design/icons';
import { Table, Row, Col, Progress, Button, InputNumber, Input, Modal, Checkbox, message, Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import SubHeader from '../Header/Header';
import "./OrderProcessing.scss"
import { useNavigate, useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import CSVFile from '../../Images/CSVFile';
import { getClientId, getDashboardMenuId, getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import { useDispatch, useSelector } from 'react-redux';
import { completePackingAction, popupSKUScanAction, scanOnPackingSkuAction } from '../../Store/Actions/ProcessWMSAction';
import { display } from '@mui/system';
import Navigates from '../Navigates/Navigates';
import SkeletonLoader from './SkeletonLoader';
import AppReferences from '../../Globals/AppReferences';
import { isArrayNotEmpty } from '../../Globals/Helper';
import NoData from '../../CustomComponent/NoData';
import SpinLoader from '../SpinLoader';

let recordNumber: any
let PiecesPacked: any
let orderQty: any
let scannedQty = -1;

function OnPackingStationScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [barCode, setBarCode] = useState("");
    const [showModal, setShowModal] = useState<any>(false);
    const [expandRow, setExpandRow] = useState<any>();
    const [statusDetails,
        channel, tatClient,
        clientValue, process,
        getStatusDetails,
        getChannelStatus,
        setProcess,
        getChannelsApi
    ]: any = useOutletContext();
    const user = getUserInfo();
    const reducerstate = useSelector((state: any) => state?.ProcessReducerState);
    const { skuScanningResponse, popupSkuScanningResponse, statusDetailsOnload, completePackingData } = reducerstate;
    const cleintId = getClientId();
    const [onload, setOnload] = useState({
        scanOnload: false
    });
    const [sku, setSKU] = useState<any>()
    const [scannedSku, setScannedSku] = useState<any>()
    const [packingOnLoad, setPackingOnLoad] = useState(false)
    const [popUpSKUScan, setPopUpSKUScan] = useState(false)
    const [selectOrderId, setSelectOrderId] = useState<any>([]);
    const [scanQty, setScanQty] = useState(-1);

    useEffect(() => {
        if (popUpSKUScan === true && !reducerstate.scanOnload) {
            setScannedSku('')
            scanBarcode(sku)
            setPopUpSKUScan(false);
            let [skuDetails] = (skuScanningResponse?.lineitems || []);

        }
    }, [popUpSKUScan, reducerstate.scanOnload])

    const popupSKUScan = (value: any) => {
        if (scanQty  > 0) {
            setScannedSku(value)
            if (value?.length > 3) {
                if (sku === value) {
                    let payload = {
                        "opnfor": AppReferences.opnfor.salesOrder,
                        "activity": "A-19",
                        "transaction": recordNumber,
                        "where": "1",
                        "customer": cleintId,
                        "fieldArray": value,
                        "user": user?.UserName
                    }
                    dispatch(popupSKUScanAction(payload));
                    setPopUpSKUScan(true);
                    scannedQty = scannedQty - 1;
                    setScanQty(scanQty - 1)
                } else {
                    message.error("Wrong SKU scanned")
                }
            }
        }
    }

    useEffect(() => {
        if (onload.scanOnload && !reducerstate.scanOnload) {
            setOnload({ ...onload, scanOnload: false });
            if (skuScanningResponse?.IsSKUMatchingToTheOrder?.ScannedSKUIsCorrect === true) {
                setShowModal(true)
            }
            else if (skuScanningResponse?.IsSKUMatchingToTheOrder?.ScannedSKUIsCorrect === false) {
                message.error("Wrong SKU scanned")
            }
            let [skuDetails] = (skuScanningResponse?.lineitems || []);
            if(scannedQty <= 0){
                setScanQty(skuDetails?.PiecesPicked - skuDetails?.PiecesPacked)
                scannedQty = skuDetails?.PiecesPicked - skuDetails?.PiecesPacked;
            }
        }
    }, [onload.scanOnload, reducerstate.scanOnload])

    const scanBarcode = (value: any) => {
        let checkExist = statusDetails?.lineitems?.find(({ItemId}: any) => ItemId?.includes(value));
        let skuDetails = statusDetails?.lineitems?.find(({ItemId}: any) => ItemId == value);
        setSKU(value);
        if(skuDetails?.ItemId == value){
            let payload = {
                "opnfor": AppReferences.opnfor.salesOrder,
                "activity": AppReferences.activities.a12,
                "transaction": value,
                "where": cleintId,
                "fieldArray": channel,
                "warehouse": getWareHouseId(),
                "user": user?.UserName,
                "tableNo": tatClient,
            }
            dispatch(scanOnPackingSkuAction(payload));
            setOnload({ ...onload, scanOnload: true })
        }
        else if(!checkExist){
            message.error("Wrong SKU Scanned");
        }
    }

    useEffect(() => {
        if (packingOnLoad === true && !reducerstate.completePackingLoad) {
            setShowModal(false)
            setPackingOnLoad(false)
            if (completePackingData?.msg == "Order Moved To RTS successfully") {
                getChannelsApi();

            }


        }
    }, [packingOnLoad, reducerstate.completePackingLoad])

    const closePopUp = () => {
        setSKU('')
        setShowModal(false)
    }

    const moveToRTS = () => {
        setSKU('')
        let payload = {
            "opnfor": AppReferences.opnfor.salesOrder,
            "activity": "A-13",
            "transaction": recordNumber,
            "user": user?.UserName
        }
        dispatch(completePackingAction(payload))
        setPackingOnLoad(true)
    }
    console.log("scanned", scannedQty, scanQty)
    const skuScanModal = () => {
        try {
            let prefixUrl = skuScanningResponse?.media?.prefix
            return (
                <Modal
                    className="start-receivingscreen-modal"
                    open={showModal}
                    onOk={() => setShowModal(false)}
                    width={'65%'}
                    title={
                        <div className='d-flex align-c jc-sb'>
                            <div className='d-flex align-c'>
                                <div>Start Packing</div>
                            </div>
                            <div className='d-flex align-c'>
                                <div className='d-flex align-c' style={{ marginRight: 10 }}>
                                    {scanQty > 0 &&
                                        <Input
                                            value={scannedSku}
                                            className='input-field'
                                            onPaste={({ target }: any) => target?.value?.length > 3 && popupSKUScan(target?.value)}
                                            onChange={({ target }) => target?.value?.length > 3 && popupSKUScan(target?.value)}
                                            placeholder='Scan SKU'
                                            autoFocus
                                        />
                                    }
                                </div>
                                <div className='d-flex align-c ' style={{ marginRight: 15 }}>
                                    <AppButtons
                                        block={true}
                                        buttonType={AppConstants.buttonTypes.whiteButton}
                                        text={"Print Invoice"}
                                    />
                                </div>
                                <div className='d-flex align-c ' style={{ marginRight: 15 }}>
                                    <AppButtons
                                        block={true}
                                        buttonType={AppConstants.buttonTypes.whiteButton}
                                        text={"Print Label"}
                                    />
                                </div>
                                <div className='d-flex align-c '>
                                    <AppButtons
                                        onClick={() => moveToRTS()}
                                        disabled={!(scanQty <= 0)} block={true}
                                        buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                        text={"Complete Packing"}
                                    />
                                </div>
                                <div style={{ paddingLeft: 25 }}>
                                    {/* <img className='  round-cross-btn c-p' onClick={() => closePopUp()}  src={AppImages.closeimg} alt='' />   */}
                                    <Button
                                        onClick={() => closePopUp()}
                                        className="round-cross-btn">
                                        <CloseOutlined />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                    onCancel={() => setShowModal(false)}
                    footer={null}
                >
                    <div className='model-title-container-view'>
                        {(skuScanningResponse?.lineitems || []).map((item: any) => {
                            recordNumber = item?.RecordNo
                            orderQty = item?.PiecesPicked
                            PiecesPacked = item?.PiecesPacked
                            return (
                                <Row gutter={20}>
                                    <Col span={3}>
                                        <div style={{ width: "90%" }}>
                                            {skuScanningResponse?.media?.objects.map((url: any) => {
                                                return (
                                                    <div className='product-view'>
                                                        <img src={prefixUrl + url} alt='Loading' />
                                                    </div>
                                                )
                                            })}
                                            <div className='brand-view'>
                                                <img src={AppImages.brandIcon} alt='' />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={10}>
                                        <div className='d-flex align-c' style={{ marginTop: 10, fontSize: 16 }}>
                                            <div style={{ fontFamily: 'RedHat-SemiBold' }}>
                                                Order ID:
                                            </div>
                                            <div style={{ marginLeft: 3, fontFamily: 'RedHat-Regular' }}>
                                                {item?.OrderID}
                                            </div>
                                        </div>
                                        <div className='d-flex align-c' style={{ marginTop: 20 }}>
                                            <div style={{ fontFamily: 'RedHat-SemiBold', fontSize: 16 }}>
                                                SKU,Description:<span style={{ marginLeft: 3, whiteSpace: 'normal', fontFamily: 'RedHat-Regular', width: "100%" }}>{item?.SKU} , {item?.Description} </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={11}>
                                        <Row>
                                            <Col span={8}>
                                                <div style={{ fontFamily: "RedHat-SemiBold" }}>
                                                    Order Qty
                                                </div>
                                                <div className='to-pack-qty-count'>
                                                    {item?.OrderedQuantity}
                                                </div>
                                            </Col>
                                            <Col span={8}>
                                                <div style={{ fontFamily: "RedHat-SemiBold" }}>
                                                    To Pack Qty
                                                </div>
                                                <div className={(Number(item?.OrderedQuantity) > Number(item?.PiecesPicked)) ? 'less-packed-qty' : 'packed-qty-count'}>
                                                    {item?.PiecesPicked}
                                                </div>
                                            </Col>
                                            <Col span={8}>
                                                <div style={{ fontFamily: "RedHat-SemiBold" }}>
                                                    Packed Qty
                                                </div>
                                                <div className={(Number(item?.PiecesPicked) > Number(item?.PiecesPacked)) ? 'less-remaining-qty-count' : 'remaining-qty-count'}>
                                                    {item?.PiecesPacked}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        })}
                        {/* {(skuScanningResponse?.lineitems || []).map((item: any) => {
                            return ( */}
                        <Row gutter={10} style={{ marginTop: 30 }}>
                            <Col span={8}>
                                <div style={{ fontSize: 18, fontFamily: "RedHat-SemiBold" }}>
                                    Packing Materials
                                </div>
                                <div className='packed-type'>
                                    {skuScanningResponse?.PackingMaterial?.map((material: any) => {
                                        return (
                                            <div className='types'>
                                                {material?.PackagingMaterial}
                                            </div>
                                        )
                                    })}
                                </div>
                            </Col>
                            <Col span={16}>
                                <div style={{ fontSize: 18, fontFamily: "RedHat-SemiBold" }}>
                                    Method of Packing Process
                                </div>
                                <div style={{ height: "90%", background: "white", borderRadius: 6 }} className='d-flex align-c jc-c'>
                                    <NoData
                                        description={<>No Packing Video is available for<span className='redhat-bold ml-5'>{sku}</span></>}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            )
        } catch (ex) {
            console.log("Err in skuScanModal")
        }
    }

    const header = () => {
        return (
            <SubHeader
                title={"On Packing Station"}
                paginationView="false"
                inputView="true"
                btnView="false"
                fromOrgin="orderProcessing"
            >
                <div className='d-flex flex-end'>
                    <div style={{ marginRight: 15 }} className='csv-box'>
                        <CSVFile />
                        <div>CSV</div>
                    </div>
                    <div>
                        <Input
                            value={sku}
                            onPaste={(e: any) => scanBarcode(e.target.value)}
                            onChange={(e: any) => scanBarcode(e.target.value)}
                            style={{ height: 39, borderRadius: 4 }}
                            placeholder='Scan SKU'
                            autoFocus
                        />
                    </div>
                </div>
            </SubHeader>
        )
    }

    const selectOrders = (e: any, key: any) => {
        let orders: any = []
        if (e.target.checked) {
            if (key == 'all') {
                statusDetails?.lineitems?.map((x: any) => {
                    orders.push(x?.ID)
                })
                setSelectOrderId(orders)
            }
            else {
                setSelectOrderId([...selectOrderId, e.target.value])
            }
        }
        else {
            if (key == 'all') {
                setSelectOrderId([])
            }
            else {
                setSelectOrderId(selectOrderId.filter((x: any) => x != e.target.value));
            }
        }
    }


    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={1}>
                    <Checkbox
                        checked={selectOrderId?.length == statusDetails?.lineitems?.length}
                        onChange={(e) => selectOrders(e, 'all')}
                    />
                </Col>
                <Col span={6}>
                    {"Order ID"}
                </Col>
                <Col span={5} className=' d-flex'>
                    {"SKU,Description"}
                </Col>
                <Col span={7} className=' d-flex jc-c'>
                    {"Order Qty"}
                </Col>
                <Col span={5} className=' d-flex'>
                    {"Packing Station"}
                </Col>
            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        let isChecked = selectOrderId?.find((x: any) => x == item?.ID)
        return (
            <div className={"content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={1} className="d-flex align-c">
                        <Checkbox
                            value={item?.ID}
                            checked={isChecked}
                            onChange={(e) => selectOrders(e, 'individual')}
                        />
                    </Col>
                    <Col span={6} className="open-icon d-flex align-c">
                        <div className='text'>
                            {item?.ID}
                        </div>
                    </Col>
                    <Col span={5} className=' d-flex align-c'>
                        <div className='text'>
                            {item?.SKU}
                        </div>
                    </Col>
                    <Col span={7} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.OrderedQuantity}
                        </div>
                    </Col>
                    <Col span={5} className=' d-flex align-c'>
                        <div className='text'>
                            {item?.PackingStation}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    const contentView = () => {
        return (
            <>
                {isArrayNotEmpty(statusDetails?.lineitems) ?
                    <div className='table-screen-container'>
                        {tableHeaderView()}
                        <div>
                            {statusDetails?.lineitems?.map((x: any, index: any) => tableBodyView(x, index))}
                        </div>
                    </div>
                    :
                    <div className='p-20'>
                        <NoData
                            description={
                                <>There is no sales order available in <span className='redhat-bold ml-5'>On Packing Station</span></>
                            }
                        />
                    </div>
                }
            </>
        )
    }


    return (
        <>
            {!statusDetailsOnload ?
                <div>
                    {header()}
                    {contentView()}
                    {skuScanModal()}
                    {/* <SpinLoader 
                    loading={onload?.scanOnload}
                    /> */}
                </div>
                :
                <SkeletonLoader />
            }
        </>
    );
}
export default OnPackingStationScreen;
