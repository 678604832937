import { CloseOutlined } from '@ant-design/icons';
import { SelectChangeEvent } from '@mui/material';
import { Button, Col, Drawer, Row, Steps, Table } from 'antd';
import Search from 'antd/lib/transfer/search';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import { isArrayNotEmpty } from '../../Globals/Helper';
import CSVFile from '../../Images/CSVFile';
import { getDashboardMenuId } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import SkeletonLoader from './SkeletonLoader';

function MismatchedCancelled() {
    const [value, setValue] = React.useState('');
    const [checkedValue, setCheckedValue] = useState<any>([]);
    const [openDrawer, setOpenDrawer] = useState<any>(false);
    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);
    };
    const reducerstate = useSelector((state: any) => state?.ProcessReducerState);
    const { statusDetailsOnload } = reducerstate;
    const dashboardMenuId = getDashboardMenuId();
    const [
        statusDetails, 
        channel, 
        tatClient, 
        clientValue, 
        process, 
        getStatusDetails,
        getChannelStatus
    ]: any = useOutletContext();

    const checkAll = (e: any, data: any) => {
        if (!data) {
            if (e.target.checked == true) {
                let arr: any = [];
                statusDetails?.lineitems?.map((x: any, index: any) => {
                    arr.push(x?.ID )
                });
                setCheckedValue(arr)
            }
            else {
                setCheckedValue([])
            }
        }
        else {
            if (e.target.checked == true) {
                setCheckedValue(checkedValue?.filter((x: any) => x != data?.ID));
            }
            else {
                setCheckedValue([...checkedValue, data?.ID]);
            }
        }
    }

    const drawerView = () => {
        try {

            return (
                <Drawer
                    className="drawer-view-verified"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "White" }}>
                            <div className='d-flex'>
                                <div>
                                    Sub Order ID:
                                </div>
                                <div>
                                    000001
                                </div>
                            </div>
                            <Button onClick={() => setOpenDrawer(false)}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>
                        </div>
                    }
                    onClose={() => setOpenDrawer(false)}
                    open={openDrawer == true}>
                    <div>
                        <div className='d-flex'>
                            <div className='icon-container'>
                                <img src={AppImages.productIcon} alt="" />
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <div className='sku-desc'>
                                    SKU-001-Lorem ipsm dolor
                                </div>
                                <div className='order-id'>
                                    Order ID :1243565467
                                </div>
                                <div className='d-flex' style={{ marginTop: 5 }}>
                                    <div className='brand-icon'>
                                        <img src={AppImages.brandIcon} alt='' />
                                    </div>
                                    <div className='time'>
                                        24 hrs
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: "#FAFAFA", marginTop: 25, borderRadius: 6, padding: 15 }}>
                        <div style={{ fontFamily: 'Redhat-Bold', marginTop: 5 }} >
                            Overview
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Steps
                                direction='vertical'
                                current={3}
                                items={[
                                    {
                                        title: 'Order Received ',
                                        description: "09-Sep-2022",
                                    },
                                    {
                                        title: 'Pick Released',
                                        description: "09-Sep-2022",
                                    },
                                    {
                                        title: 'Scanning Completed',
                                        description: "10-Sep-2022",
                                    },
                                ]}
                            />
                        </div>
                    </div>

                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }

    }

    const header = () => {
        return (
            <div className="d-flex flex-end">
                <div className='csv-box'>
                    <CSVFile />
                    <div>CSV</div>
                </div>
            </div>
        )
    }

    const columns: any = [
        {
            title: <div className='d-flex align-c'>
                <div className='mr-10'><input type={"checkbox"} className="check-box" onChange={(e) => checkAll(e, null)} /></div>
                <div>{AppConstants.orderId}</div>
            </div>,
            dataIndex: "",
            width: "15%",
            render: (item: any, _: any, index: any) =>
                <div className='d-flex align-c'>
                    <div className='mr-10'>
                        <input type={"checkbox"}
                            className="check-box"
                            onChange={(e) => checkAll(e, item)}
                            checked={checkedValue?.find((x: any) => x == item?.ID)} />
                    </div>
                    <div className='orderid' style={{ cursor: "pointer" }} onClick={() => setOpenDrawer(true)} title={item?.ID}>{item?.ID}</div>
                </div>
        },
        {
            title: AppConstants.sku,
            dataIndex: "SKU",
            width: "20%",
            render: (item: any) =>
                <div className='redhat-semibold'>
                    {item}
                </div>

        },
        {
            title: AppConstants.orderQty,
            dataIndex: "OrderedQuantity",
            align: "center",
            width: "15%",
            render: (item: any) =>
                <div className='text-1'>
                    {item}
                </div>

        },
        {
            title: AppConstants.toPickQty,
            dataIndex: "ToPickQty",
            width: "15%",
            align: 'center',
            render: (item: any) =>
                <div className='redhat-semibold'>
                    {item}
                </div>
        },
        {
            title: AppConstants.pickedQty,
            dataIndex: "PickedQty",
            width: "15%",
            align: 'center',
            render: (item: any) =>
                <div className='redhat-semibold'>
                    {item}
                </div>

        },
        {
            title: AppConstants.noInventory,
            dataIndex: "NoInventoryFoundQty",
            align: 'center',
            width: "20%",
            render: (item: any) =>
                <div className='d-flex jc-c'>
                    <div className='redhat-bold'>
                        {item}
                    </div>
                </div>


        }
    ]

    const contentView = () => {
        return (
            <>
            {isArrayNotEmpty(statusDetails?.lineitems) ?
             <div className='table-screen-container'>
                <Table
                    columns={columns}
                    dataSource={statusDetails?.lineitems}
                    pagination={false}
                    scroll={{ y: '490px' }}
                />
            </div>
            :
            <div className='p-20'>
                <NoData
                    className='mt-45'
                    description={
                        <>{AppConstants.nodata}<span className='redhat-bold ml-5'>Error</span></>
                    }
                />
            </div>}
        </>
        )
    }

    return (
        <>
            {!statusDetailsOnload ?
                <div className='verified-container'>
                    <SubHeader
                        title={dashboardMenuId == '301' ? "SKU Mismatch"
                            : dashboardMenuId == '302' ? "Non-Servicable"
                                : dashboardMenuId == '303' ? "No Inventory"
                                    : "Cancelled"}
                        paginationView="false"
                        inputView="true"
                        btnView="false"
                        fromOrgin="orderProcessing"
                    >
                        {header()}
                    </SubHeader>
                    {contentView()}
                    {/* {drawerView()} */}
                </div>
                :
                <SkeletonLoader />
            }
        </>

    );

}

export default MismatchedCancelled; 