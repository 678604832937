import { CloseOutlined, SearchOutlined, CaretRightOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { Drawer, Button, Steps, Input, Row, Col, Table, message } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { userInfo } from 'os';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import AppReferences from '../../Globals/AppReferences';
import { isArrayNotEmpty } from '../../Globals/Helper';
import DownloadIcon from '../../Images/DownloadIcon';
import { handoverManifestAction } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import SpinLoader from '../SpinLoader';
import SkeletonLoader from './SkeletonLoader';

function Manifested(props: any) {
    const [expandRow, setExpandRow] = useState<any>();
    const [openDrawer, setOpenDrawer] = useState<any>(false);
    const [draggers, setDraggers] = useState<any>({ dragger: 0 });
    const reducerstate = useSelector((state: any) => state?.ProcessReducerState);
    const { statusDetailsOnload } = reducerstate;
    const [
        statusDetails,
        channel,
        tatClient,
        clientValue,
        process,
        getStatusDetails,
        getChannelStatus,
        setProcess,
        getChannelsApi
    ]: any = useOutletContext();
    const [onload, setOnload] = useState({
        handOverOnload: false
    })
    const dispatch = useDispatch();

    useEffect(() => {
        if(onload.handOverOnload && !reducerstate.onload){
            if(reducerstate?.handover?.msg == 'Handed Over Successfully'){
                message.success(reducerstate?.handover?.msg);
                getChannelsApi();
            }
            else{
                message.error(reducerstate?.handover?.msg);
            }
            setOnload({...onload, handOverOnload: false})
        }
    }, [onload.handOverOnload, reducerstate.onload])

    const drawerView = () => {
        try {
            return (
                <Drawer
                    className="drawer-view-verified"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "White" }}>
                            <div className='d-flex'>
                                <div>
                                    Order ID:
                                </div>
                                <div>
                                    000001
                                </div>
                            </div>
                            <Button onClick={() => setOpenDrawer(false)}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>
                        </div>
                    }
                    onClose={() => setOpenDrawer(false)}
                    open={openDrawer == true}>
                    <div>
                        <div className='d-flex'>
                            <div className='icon-container'>
                                <img src={AppImages.productIcon} alt="" />
                            </div>
                            <div style={{ marginLeft: 10 }}>
                                <div className='sku-desc'>
                                    SKU-001-Lorem ipsm dolor
                                </div>
                                <div className='order-id'>
                                    Order ID :1243565467
                                </div>
                                <div className='d-flex' style={{ marginTop: 5 }}>
                                    <div className='brand-icon'>
                                        <img src={AppImages.brandIcon} alt='' />
                                    </div>
                                    <div className='time'>
                                        24 hrs
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: "#FAFAFA", marginTop: 25, borderRadius: 6, padding: 15 }}>
                        <div style={{ fontFamily: 'Redhat-Bold', marginTop: 5 }} >
                            Overview
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Steps
                                direction='vertical'
                                current={3}
                                items={[
                                    {
                                        title: 'Order Received ',
                                        description: "09-Sep-2022",
                                    },
                                    {
                                        title: 'Pick Released',
                                        description: "09-Sep-2022",
                                    },
                                    {
                                        title: 'Scanning Completed',
                                        description: "10-Sep-2022",
                                    },
                                ]}
                            />
                        </div>
                    </div>

                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }

    }
    const header = () => {
        return (
            <SubHeader
                title={"Manifested"}
                paginationView="false"
                inputView="true"
                btnView="false"
                fromOrgin="orderProcessing"
            >
            </SubHeader>
        )
    };

    const columns: any = [
        {
            title: "AWB Number",
            dataIndex: "AWBNumber",
            width:"20%",
            align: "left",
            render: (item: any) => {
                let barCodeUrl = `http://bwipjs-api.metafloor.com/?bcid=code128&text=${item}`
                return (
                    <div className='d-flex align-c'>
                        <img style={{ height: 40, width: "100%" }} src={barCodeUrl} alt="" />
                    </div>
                )
            }
        },
        {
            title: "Order ID",
            dataIndex: "ID",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1' style={{ cursor: "pointer" }} onClick={() => setOpenDrawer(true)}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "SKU,Description",
            dataIndex: "",
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item?.ItemId + ", " + item?.Description}
                    </div>
                )
            }
        },
        {
            title: "Order Qty",
            dataIndex: "OrderedQuantity",
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        }
    ];

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={7}>
                    {"Courier service"}
                </Col>
                <Col span={5} className=' d-flex jc-c'>
                    {"Total Manifest"}
                </Col>

            </Row>
        )
    };

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
        }
    };

    const handover = (data: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.salesOrder,
            activity: AppReferences.activities.a23,
            transaction: data?.ManifestIDRecNumber,
            user: getUserInfo()?.UserName
        }
        dispatch(handoverManifestAction(payload));
        setOnload({...onload, handOverOnload: true})
    }

    const detailsView = (data: any, index: any) => {
        return (
            <Row>
                <Col span={6} className='d-flex align-c'>
                    <div className='content-title-16'>
                        Manifest# :
                    </div>
                    <div className='text-2' style={{ marginLeft: 5 }}>
                        {data?.ManifestID}
                    </div>
                </Col>
                <Col span={6} className='d-flex align-c'>
                    <div className='content-title-16'>
                        Box Qty :
                    </div>
                    <div className='text-2' style={{ marginLeft: 5 }}>
                        {data?.PiecesPicked}
                    </div>
                </Col>
                <Col span={6} className='d-flex align-c'>
                    <div className='content-title-16'>
                        Packed orders :
                    </div>
                    <div className='text-2' style={{ marginLeft: 5 }}>
                        {data?.PackedOrders}
                    </div>
                </Col>
                <Col span={3} className='d-flex align-c jc-c'>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: 40 }} >
                        <div className='RTS-btn-container   d-flex align-c'>
                            <CloudUploadOutlined onClick={() => openDragger(index)} />
                        </div>
                        <div className='RTS-btn-container   d-flex align-c'  style={{ cursor: "pointer", marginLeft: 25 }}>
                            <img  style={{padding:"4px"}} src={AppImages.downloadIcon} alt="" />
                        </div>
                    </div>
                </Col>
                <Col span={3}>
                    <AppButtons
                    onClick={() => handover(data)}
                    text="Handed over"
                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                    />
                </Col>
            </Row>
        )
    }

    const skuDetailsView = (data: any, index: any) => {
        return (
            <>
                {data?.ManifestHeader?.map((item: any) => {
                    return (
                        <div style={{ background: 'white', padding: 10, borderRadius: 6 }}>
                            {detailsView(item, index)}
                            {draggers[`dragger${index}${index}`] == index &&
                                <div className='dragger-container' style={{ marginTop: 20 }}>
                                    {uploadContainerView(index)}
                                </div>}
                            <div className='table-view-container' style={{ marginTop: 10 }}>
                            <Table
                                columns={columns}
                                dataSource={item?.ManifestedLines}
                                scroll={{ y: '490px' }}
                                pagination={false}
                            />
                            </div>
                        </div >
                    )
                })}
            </>
        )
    };

    const closeDragger = (e: any, index: any) => {
        e.stopPropagation();
        setDraggers({ ...draggers, [`dragger${index}${index}`]: null })
    };

    const openDragger = (index: any) => {
        setDraggers({ ...draggers, [`dragger${index}${index}`]: index })
    };

    const uploadContainerView = (index: any) => {
        try {
            return (
                <Dragger >
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <DownloadIcon />
                        <div className='dragger-content ' style={{ marginLeft: 5, textAlign: "left" }}>
                            <div className='drag-txt '>Drag & Drop files or <span className='browse-txt'>Browse</span></div>
                            <div className='note-content '>Supported Formates: JPEG,PNG</div>
                        </div>
                    </div>
                    <Button className="close-btn" onClick={(e) => closeDragger(e, index)}>
                        <CloseOutlined />
                    </Button>
                </Dragger>
            )
        } catch (ex) {
            console.log("Error in uploadContainerView::" + ex)
        }
    };

    const tableBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        return (
            <div className={open ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={7} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='text'>
                            {item?.Name}
                        </div>
                    </Col>
                    <Col span={5} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.Cnt}
                        </div>
                    </Col>

                    {open && <Col span={24} className="sku-details">
                        {skuDetailsView(item, index)}
                    </Col>}
                </Row>
            </div>
        )
    };

    const contentView = () => {
        return (
            <>
            {isArrayNotEmpty(statusDetails?.lineitems) ?
             <div className='table-screen-container'>
                {tableHeaderView()}
                {statusDetails?.lineitems?.map((x: any, index: any) => tableBodyView(x, index))}
                {drawerView()}
            </div>
            :
            <div className='p-20'>
                <NoData
                    className='mt-45'
                    description={
                        <>{AppConstants.nodata}<span className='redhat-bold ml-5'>Manifested</span></>
                    }
                />
            </div>}
        </>
        )
    }

    return (
        <>
            {!statusDetailsOnload ?
                <div>
                    {header()}
                    {contentView()}
                    <SpinLoader 
                    loading={onload?.handOverOnload}
                    />
                </div>
                :
                <SkeletonLoader />
            }
        </>
    );
};

export default Manifested;