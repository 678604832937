const AppConstants: any = {
  mamaEarth: "mamaearth",
  inwardType: "Inward Type ",
  vehicleNumber: "Vehicle Number",
  sealMatched: "Seal Matched",
  sealNumber: "Seal Number",
  docketNumber: "Docket Number",
  noOfInvoices: "No of Invoices",
  noOfBoxes: "No of Boxes",
  tentativeUnloading: "Tentative Unloading",
  vehicleType: "Vehicle Type",
  docketStation: "Dock Station",
  comments: "Comments",
  attachment: "Attachments",
  addComment: "Add Comment",
  bestRegards: "Best Regards,",
  emizaAdmin: "EMIZA Admin",
  vanCancel: "Cancel",
  vanSend: "Send VAN",
  invoiceBox: "Invoice Box",
  totalUnloadedBoxes: "Total Unloaded boxes",
  receivedDamagedBox: "Received Damaged Box",
  unloadingManager: "Unloading Manager",
  unloadingAssignee: "Unloading Assignee",
  printSelectedStickers: "Print Selected Stickers",
  printAllStickers: "Print All Stickers",
  stickeringCompleted: "Stickering Completed",
  totalNumberofInvoice: "Total No. of Invoices",
  unloadingManager_: "Unloading Manager - ",
  managerName: "Manager Name",
  invoiceNumber: "Invoice Number",
  invoiceBoxs: "Invoice Box(s)",
  sendToUnloading: "Send to Unloading",
  purchseOrder: "Purchase Order -PO ",
  termsAndConditions: "As per the contract terms and conditions, Excess quantity shall be received for 10% of Invoice quantity.",
  skuDescription: "SKU, Description",
  SKUDescription: "SKU Description",
  orderQuantity: "Order Quantity",
  invoiceQuantity: "Invoice Quantity",
  invoiceQty: "Invoice Qty",
  MoveToAssociatePO: "Move to Associate PO",
  startReceiving: "Start Receiving",
  invoiceNo: "Invoice No.",
  invoiceBox_: "Invoice Box :",
  TotalUnloadedBoxesIncludingDamaged: "Total Unloaded Boxes including damaged:",
  recivedDamagedBox: "Recived Damaged Box :",
  Admin: "EMIZA Admin",
  status: "Status",
  totalUnloading: "Total Unloaded Boxes including damaged",
  damagedBoxReceived: "Recived Damaged Box ",
  box: "Box- ",
  sampling: "Sampling %",
  good: "Good",
  damaged: "Damaged",
  repairable: "Repairable",
  cannedReason: "Canned Reason",
  Comments: "Comments",
  detailQcCondition: "Detailed QC Conditions",
  buttonTypes: {
    solidLinearGreen: "solid-linear-green",
    solidLightGreen: "solid-light-green",
    whiteButton: "solid-white-button",
    solidLinearGreen1: "solid-linear-green-1",
    solidLinearGreen2: "solid-linear-green-2",
    solidRedOutline: 'Solid-red-outline',
    solidLinearGreen3: "solid-linear-green-3",
    solidLinearGreen4: "solid-linear-green-4",
    solidLinearGreen5: "solid-linear-green-5",
    solidLinearGreen6: "solid-linear-green-6",
    borderlessWhiteButton: "borderless-white-button"
  },
  binSelection: "Bin Selection",
  gateIn: "GateIn",
  gateInId: "GateIn ID",
  onBoarding: "On-Boarding",
  salesOrder: "Sales Order",
  kitting: "Kitting",
  purchaseOrder: "Purchase Order",
  inventoryManagement: "Inventory Management",
  cycleCount: "Cycle Count",
  orderCancellation: "Order Cancellation",
  rtoReturn: "RTO/Return",
  adhocExpense: "Adhoc Expense",
  san: "SAN",
  qc: "QC",
  putAway: "Putaway",
  grn: "GRN",
  picking: "Picking",
  packing: "Packing",
  cartoning: "Cartoning",
  dispatch: "Dispatch",
  invoiceDetails: "Invoice Details",
  unloading: "Unloading",
  stickering: "Stickering",
  pendingSAN: "Pending SAN",
  sendSAN: "Send SAN",
  associatePO: "Associate PO",
  customDetailedQC: "Custom/DetailedQC",
  GRNPending: "GRN Pending",
  GRNEmail: "GRN Email",
  image: "Images",
  videos: "Videos",
  grnQty: "GRN Qty",
  sellable: "Sellable",
  skuName: "SKU Name",
  putAwayId: "Putaway ID - ",
  palletId: "Pallet ID : ",
  generalStorage: "General Storage",
  mover: "Mover -",
  pallateId: "Pallete ID - ",
  locationId: "Location ID - ",
  empty: "Empty",
  warehouse: "Warehouse",
  client: "Client",
  sendGrn: "Send GRN",
  sku: "SKU",
  batchNo: "Batch No. ",
  importMon: "Import Mon",
  mfgDate: "Mfg Date",
  bad: "Bad",
  ExpDate: "Exp Date",


  totalnoofInvoices: "Total No. Of Invoices",
  invoiceNum: "Invoice Number",
  receivedBox: "Received Box",
  damagedBox: "Damaged Box",
  cancel: "Cancel",
  sendTostickering: "Send to Stickering",
  attributes: "Attributes",
  batchLotNo: " Batch/Lot No",
  addSKU: "Add SKU",
  addBadQty: "+ Add Bad Quantity",
  createPutAway: "Create Put Away",
  storageType: "Storage Type",
  structureType: "Structure Type",
  palleteId: "Pallete ID",
  boxBin: "Box/Bin",
  quality: "Quantity",
  assignToMover: "Assign To Mover",
  recdQty: "Recd. Qty",
  inward: "Inward",
  invoiceWeight: "Invoice Weight (gms)",
  executive: "Executive",
  labours: "Labours",
  skuDetails: "SKU-Details",
  action: "Action",
  somethingwentwrong: "Something Went Wrong",
  totalBoxes: "Total Unloaded Boxes",
  Mover: "Mover",
  putawayBoxes: "Putaway Boxes",
  totalSkuQty: "Total SKU Qty",
  totalUnits: "Total Units",
  boxQuantity: "Box Quantity : ",
  orderId: "Order ID",
  orderQty: "Order Quantity",
  crtDate: "Created Date",
  rcdDate: "Received Date",
  tat: "TAT",
  batchId: "Batch ID",
  totalSubOrderId: "Total Order ID",
  orderqty: "Order Qty",
  fillRate: "Fill Rate",
  picker: "Picker",
  orderedqty: "Ordered Qty",
  toPickQty: "To Pick Qty",
  pickedQty: "Picked Qty",
  pendingQty: "Pending Qty",
  totalPickedQty: "Total Picked Qty",
  totalOrderQty: "Total Orders Qty",
  scannedQty: "Scanned Qty",
  manifestId: "Manifest ID",
  manifestNo: "Manifest No",
  courierService: "Courier Service",
  packedSubOrders: "Packed Orders",
  totalOrdersQty: "Total  Orders Qty",
  handedBoxQty: "Handed Box Qty",
  AWBno: "AWB No",
  boxgngOut: "Boxes Going Out",
  Courier: "Courier",
  awbNumber: "AWB Number",
  driverName: "Driver Name",
  drivercontact: "Driver Contact",
  gateInNumber: "Gate-In-Number",
  createdtill: "Created-Till Days",
  createdDate: "Created Date",
  manifestID: "Manifest ID",
  receivedPackages: "Received Packages",
  processedPackages: "Processes Packages",
  customer: "Customer",
  customerName: "Cutomer Name",
  channel: "Channel",
  saleOrderNu: "Sales Order / AWB No",
  hashOfOrders: "# Of Orders",
  totalQty: "Total Qty",
  picked: "Picked %",
  order: "Order",
  noDataAvailable: "No Data Available",
  totalManifest: "Total Manifest",
  createSalesOrder: "Create Sales Order",
  nodata: 'There is no sales order available in ',
  pickedFrom: "Picked From",
  soType: "SO Type",
  shipByDate: "Ship By Date",
  referenceNumber: "Reference No",
  savendProcess: "Process",
  noInventory: "Inventory Not Found Qty",
  release: "Release",
  releaseNo: "Release No",
  shelfLife: "Shelf Life",
  fullfilled: 'Fullfilled',
  balance: "Balance",
  packed: "Packed",
  Picked: "Picked",
  confirm: "Confirm",
  warehouseMaster: "Warehouse Master",
  category: "Category",
  others: 'Others',
  add: "Add",
  name: "Name",
  skus: "SKUs",
  pickingCode: "Picking Code",
  packingMaterial: "Packing Material",
  Box: "Box",
  categoryName: "Category Name",
  save: "Save",
  code: "Code",
  skuCode: "SKU Code",
  eanCode: "EAN Code (SKU Packages)",
  EANCode: 'EAN Code',
  flitkartId: "Flipkart ID",
  amazonId: "Amazon ID",
  length: "L (mm)",
  breadth: "B (mm)",
  height: "H (mm)",
  weight: "W (gms)",
  bom: "BOM",
  requiredQty: 'Required Qty',
  startDate: "Live Start Date",
  endDate: "End Date",
  version: "Version No.",
  approved: "Approved",
  boxes: "Boxes",
  contractCategory: "Contract Category",
  ponumber: "Po Number",
  receivedqty: "Received Qty",
  stickeredqty: "Stickered Qty",
  pendingsince: "Pending Since (In days)",
  received: 'Received',
  goodQty: "Good Qty",
  badQty: 'Bad Qty',
  outward: "Outward",
  customStickering: "Custom Stickering"
}

export default AppConstants