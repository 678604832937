import {
    Button,
    Dropdown,
    Input,
    Layout,
    Menu,
    Table
} from "antd";
import AppConstants from "../../../Globals/AppConstants";
import { Link } from "react-router-dom";
import SubHeader from "../../Header/Header";
import { EyeFilled, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { AppImages } from "../../../Globals/AppImages";
import File from "../../../Images/File";

const {
    Content
} = Layout;

const suffix = (
    <SearchOutlined
        style={{
            fontSize: 20,
            color: 'black',
        }}
    />
);

function CaptureSkuDetails(props: any) {
    const {stageDetails} = props;

    const columns: any = [
        {
            title: "GateIn ID",
            dataIndex: "",
            width: 600,
            align: "left",
            render: (item: any) => {
                try {
                    let gateIn = item?.show?.split(" : ");
                    return (
                        <div className='gateinid-text'>
                            <Link to="/captureinvoicedetails">{gateIn[0]}</Link>
                        </div>
                    )
                } catch (error) {
                    console.log("Error in GateIn ID", error)
                }
                
            }
        },
        {
            title: "Client",
            dataIndex: "name",
            width: 300,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: AppConstants.action,
            dataIndex: "",
            align: "center",
            width: 100,
            render: (item: any) => {
                return (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Link to={"/sku-details"}  state={{invoiceData: item}}>
                            <Button className="eye-btn" >
                                <EyeFilled />
                            </Button>
                        </Link>
                    </div>
                );
            },
        },
    ];

    const listingView = () => {
        try {
            return (
                <div className='table-screen-container mt-55'>
                    <Table
                        columns={columns}
                        dataSource={stageDetails?.lineitems}
                        pagination={false}
                    />
                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <Input placeholder="Search By GateIn ID" className="search-input" prefix={suffix} />
                <Button className="green-btn">
                    <File/>
                </Button>

                <div className="help-icon" style={{marginLeft:10,cursor:"pointer"}}>
                    <img src={AppImages.helpIcon} alt="help-icon" />
                </div> */}
            </div>
        )
    }

    return (
        <>
            <SubHeader
                title={"Capture SKU Details"}
                paginationView="true"
                inputView="true"
                btnView="false">
                {childrenView()}
            </SubHeader>
            {listingView()}
        </>
    )
}

export default CaptureSkuDetails;