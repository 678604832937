
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import GrnPendingListing from '../GRN/GRN-Pending/GrnListingScreen';
import GeneratePutAwayListing from './GeneratePutAwayListing';
import PutAwayListing from './PutAwayListing';
import PutAwayViewScreen from './PutAwayViewScreen';


function PutAwayIndex(props: any) {
    const [value, individualStageData, getIndividualStageDetails]: any = useOutletContext();

    return (
        <>
            {value == 540001 && <PutAwayListing />}
            {value == 540100 && <GeneratePutAwayListing />}
            {value == 540200 && <GrnPendingListing stageDetails={individualStageData} getIndividualStageDetails={getIndividualStageDetails} value={value} />}
        </>
    );
}

export default PutAwayIndex;