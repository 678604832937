import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import AppReferences from '../../Globals/AppReferences';
import WMSIcon4 from '../../Images/WMSIcon4';
import { getContinuePutAway, startPutawayAcyion } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import './PutAway.scss'


function ContinuePutAway() {
    const [onLoad, setOnLoad] = useState<any>({
        load: false,
        scanView: false,
        putaway: false,
        scanOnload: false,
        viewBox: false
    });
    const dispatch = useDispatch();
    let warehouse = getWareHouseId();
    const loactionProps = useLocation().state;
    const item = loactionProps?.item;
    const navigate = useNavigate();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const {
        startPutawayOnLoad,
        continuePutawayData
    } = reducerState;

    useEffect(() => {
        let payload = {
            "opnfor": "540200",
            "activity": "A-02",
            "user": getUserInfo()?.UserName,
            "warehouse": warehouse,
            "customer": item?.ClientID,
            "transaction": item?.PutawayID,
            "where": item?.InvoiceRecNo
        }
        console.log("payload?.", payload)
        dispatch(getContinuePutAway(payload))
    }, [item])

    useEffect(() => {
        if (onLoad.putaway && startPutawayOnLoad === false) {
            navigate(-1);
            setOnLoad({ ...onLoad, putaway: false })
        }
    }, [onLoad.putaway, startPutawayOnLoad])

    const startPutaway = () => {
        let payload = {
            "opnfor": "540200",
            "activity": "AX-01",
            "user": getUserInfo()?.UserName,
            "warehouse": warehouse,
            "customer": item?.ClientID,
            "transaction": item?.PutawayID
        }
        dispatch(startPutawayAcyion(payload))
        setOnLoad({ ...onLoad, putaway: true })
    }

    const contentView = () => {
        // let assignedInfo = getDashboardMenuId() == 540200 ?  generatePutawayHeaderInfo?.lineitems[0] : assignMoverInfo?.lineitems[0]; //condition is for assignment pending screen
        let assignedInfo = continuePutawayData?.header[0];
        try {
            return (
                <div className='put-away-view-container'>
                    <Row gutter={16} style={{ padding: 20 }}>
                        <Col span={8} className="selected-option">
                            <Row gutter={16} >
                                <Col span={24} style={{ padding: 20 }}>
                                    <div className='title'>{AppConstants.storageType}</div>
                                    <div className='put-away-id-container '>
                                        {assignedInfo?.SYSLOCTYPE}
                                    </div>
                                </Col>
                                <Col span={24} style={{ padding: 20 }}>
                                    <div className='title'>{AppConstants.structureType}</div>
                                    <div className='put-away-id-container'>
                                        {assignedInfo?.STRTYPE}
                                    </div>
                                </Col>
                                <Col span={24} style={{ padding: 20 }}>
                                    <div className='title'>{AppConstants.palleteId}</div>
                                    <div className='put-away-id-container'>
                                        {assignedInfo?.PALLETID}
                                    </div>
                                </Col>
                                <Col span={24} style={{ padding: 20 }}>
                                    <div className="assignToMover-container">
                                        <div className='title'>
                                            {AppConstants.assignToMover}
                                        </div>
                                        <div className='put-away-id-container'>
                                            {assignedInfo?.ASSIGNEDTO}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={16} >
                            <div className='scanned-box-list-container'>
                                <div className='scanned-box-title'>
                                    <div>Scanned Box</div>
                                    <div>Pallete ID:<span>{assignedInfo?.PALLETID}</span></div>
                                </div>
                                <Row className='box-container' gutter={20}>
                                    {(continuePutawayData?.lineitemstwo || []).map((value: any) => {
                                        return (
                                            <Col span={7}>
                                                <div
                                                    className={'Scanned-barcode-button'}>
                                                    <div className='d-flex align-c jc-sa'>
                                                        <>
                                                            {(value?.Lines || []).map((x: any) => {
                                                                return (
                                                                    <>
                                                                        {x?.Condition && x?.Condition == "Good" &&
                                                                            <div className={value?.BinID === null  ? 'd-flex align-c bin-container-green'  : 'd-flex align-c movebin-container-green' }>
                                                                                {x?.Qty}
                                                                            </div>
                                                                        }{x?.Condition && x?.Condition == "Bad" &&
                                                                            <div className={value?.BinID  === null ? 'd-flex align-c bin-container-red-1' :'d-flex align-c movebin-container-red'}>
                                                                                {x?.Qty}
                                                                            </div>
                                                                        }
                                                                    </>

                                                                )
                                                            })}
                                                        </>
                                                        <div className='rip-count'>{value?.SequenceNo + "/" + value?.RecievedBoxQty}</div>
                                                    </div>
                                                    <div className={value?.BinID  === null ?'storage-type':'storage-type mt-0'}>
                                                        {value?.StorageType}
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div >
            )
        } catch (ex) {
            console.log("Error in contentView::" + ex)
        }
    }

    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex",alignItems:"center", justifyContent: "flex-end" }}>
                <div style={{ marginLeft: 5 }}>
                    <AppButtons
                        // disabled={!scanView}
                        onClick={() => startPutaway()}
                        buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                        text="Assign Putaway">
                    </AppButtons>
                </div>
                <Link to="/process/put-away">
                    <Button className="close-btn">
                        <CloseOutlined />
                    </Button>
                </Link>
            </div>
        )
    }
    return (
        <>
            {/* <NavbarLayout
                titleContent="sub-head"
                process={continuePutawayData?.title}
            /> */}
            <SubHeader
                title={
                    <div className='d-flex align-c'>
                        <Link to={"/process/SAN"} state={{ Option: 2 }}>
                            <div className='c-p'>
                                <WMSIcon4 />
                            </div>
                        </Link>
                        <div className='ml-10'>Pending Assignment</div>
                    </div>
                }
                btnView="true"
                inputView="true"
                paginationView="false" >
                {childrenView()}
            </SubHeader>
            <div>
                {contentView()}
            </div>
        </>
    );
}

export default ContinuePutAway;
