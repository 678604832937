import { CaretRightOutlined, CloseOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Dropdown, Form, Input, Menu, message, Modal, Row, Select, Table } from 'antd';
import Search from 'antd/lib/transfer/search';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import AppReferences from '../../Globals/AppReferences';
import { isArrayNotEmpty } from '../../Globals/Helper';
import { ShiptmentetailsAndOTPAction, validateGateOutBoxCountAction } from '../../Store/Actions/ProcessWMSAction';
import { getChannelId, getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import SpinLoader from '../SpinLoader';
import SkeletonLoader from './SkeletonLoader';

const { Option } = Select;

function GateOutReady(props: any) {
    const [boxQty, setBoxQty] = useState<any>({});
    const [form]: any = Form.useForm();
    const [validationPopup, setValidationPopup] = useState<any>({
        matched: false,
        mismatched: false,
        enableDispatch: false,
        OTP: ''
    });
    const [shipment, setShipment] = useState<any>({
        driverName: null,
        driverContact: null,
        docket: '',
        vehicle: ''
    });
    const [
        statusDetails,
        channel,
        tatClient,
        clientValue,
        process,
        getStatusDetails,
        getChannelStatus,
        setProcess,
        getChannelsApi
    ]: any = useOutletContext();
    const [validationForm]: any = Form.useForm();
    const reducerstate = useSelector((state: any) => state?.ProcessReducerState);
    const { statusDetailsOnload, validateDetails, shipmentDetails } = reducerstate;
    const dispatch = useDispatch();
    const [onload, setOnload] = useState({
        validateOnload: false,
        otpOnload: false,
        shipmentOnload: false
    });
    const user = getUserInfo();
    const [index, setIndex] = useState<any>();
    const b2b = AppReferences.channel.b2b;
    const [sO_Id_ReleaseNumber, setSO_IdReleaseNumbe] = useState<any>();


    useEffect(() => {
        if (onload.validateOnload && !reducerstate.onload) {
            if (validateDetails?.isVerificationCodeRequired == "True") {
                setValidationPopup({ ...validationPopup, mismatched: true });
            }
            else {
                setValidationPopup({ ...validationPopup, matched: true });
            }
            setOnload({ ...onload, validateOnload: false });
        }
    }, [onload.validateOnload, reducerstate.onload]);

    useEffect(() => {
        if (onload.otpOnload && !reducerstate.onload) {
            setOnload({ ...onload, otpOnload: false })
            if (shipmentDetails?.rawData?.isOTPCorrect) {
                setValidationPopup({ ...validationPopup, mismatched: false });
                validationForm.resetFields();
                form.resetFields();
                message.success(shipmentDetails?.msg);
                setBoxQty({ ...boxQty, [`boxes${index}`]: '' })
            }
            else {
                message.error("Incorrect OTP");
            }
        }
    }, [onload.otpOnload, reducerstate.onload])

    useEffect(() => {
        if (onload.shipmentOnload && !reducerstate.onload) {
            setOnload({ ...onload, shipmentOnload: false });
            if (shipmentDetails?.msg == "Manifest Dispatched successfully") {
                message.success(shipmentDetails?.msg);
                setValidationPopup({ ...validationPopup, matched: false });
                setShipment({
                    ...shipment,
                    driverName: null,
                    driverContact: null
                })
                getChannelsApi();
                setBoxQty({});
                validationForm.resetFields();
                form.resetFields();
            }
            else {
                message.error(shipmentDetails?.msg)
            }
        }
    }, [onload.shipmentOnload, reducerstate.onload]);

    const b2bValidateBoxCount = (value: any, data: any) => {
        try {
            setShipment({ ...shipment, manifestRecord: data?.ManifestIDRecNumber })
            let payload = {
                opnfor: AppReferences.opnfor.salesOrder,
                activity: AppReferences.activities.a41,
                transaction: data?.SOID,
                where: value,
                customer: clientValue,
                warehouse: getWareHouseId(),
                user: user?.UserName,
                fieldArray: getUserInfo()?.CorporateID,

            }
            dispatch(validateGateOutBoxCountAction(payload));
            setOnload({ ...onload, validateOnload: true });
        } catch (error) {
            console.log("Error in validateBoxCountB2b::", error)
        }

    }

    const validateBoxCount = (value: any, data: any) => {
        try {
            setShipment({ ...shipment, manifestRecord: data?.ManifestIDRecNumber })
            let payload = {
                opnfor: AppReferences.opnfor.salesOrder,
                activity: AppReferences.activities.a21,
                transaction: data?.ManifestIDRecNumber,
                where: value,
                customer: clientValue,
                warehouse: getWareHouseId(),
                user: user?.UserName,
                fieldArray: getUserInfo()?.CorporateID,
            }
            dispatch(validateGateOutBoxCountAction(payload));
            setOnload({ ...onload, validateOnload: true });
        } catch (error) {
            console.log("Error in validateBoxCount::", error)
        }

    }

    const validateOTP = () => {
        try {
            let payload = {
                opnfor: AppReferences.opnfor.salesOrder,
                activity: AppReferences.activities.a22,
                transaction: validationPopup.OTP,
                user: user?.UserName
            }
            dispatch(ShiptmentetailsAndOTPAction(payload))
            setOnload({ ...onload, otpOnload: true })
        } catch (error) {
            console.log("Error in validateOTP::", error)
        }
    }

    const shipmentDetailsApi = () => {
        try {
            let payload = {
                opnfor: AppReferences.opnfor.salesOrder,
                activity: AppReferences.activities.a05,
                transaction: b2b == channel ? `${sO_Id_ReleaseNumber?.soId}|${sO_Id_ReleaseNumber?.relNumber}` : shipment.manifestRecord,
                user: user?.UserName,
                where: `${shipment?.driverName}|${shipment?.driverContact}|${shipment?.vehicle}|${shipment?.docket}`,
                fieldArray: b2b == channel ? "1" : "2"
            }
            dispatch(ShiptmentetailsAndOTPAction(payload));
            setOnload({ ...onload, shipmentOnload: true });
        } catch (error) {
            console.log("Error in shipmentDetailsApi::", error)
        }
    }

    const headerView = () => {
        return (
            <SubHeader
                title={"Gate-Out Ready"}
                paginationView="false"
                inputView="true"
                btnView="false"
                fromOrgin={'orderProcessing'}
            >
                {/* <div className='d-flex flex-end'>
                    <div style={{fontFamily:'Redhat-Regular'}}>
                        <Search placeholder='Search' />
                    </div>
                </div> */}
            </SubHeader>
        )
    }

    const moveToDispatch = (data: any, index: any) => {
        form.validateFields([`box${index}`], (err: any, values: any) => {
        }).then(() => {
            if (channel == AppReferences.channel.b2b) {
                setSO_IdReleaseNumbe({ ...sO_Id_ReleaseNumber, soId: data?.SOID, relNumber: data?.ReleaseNumber })
                b2bValidateBoxCount(boxQty[`boxes${index}`], data)
            }
            else {
                validateBoxCount(boxQty[`boxes${index}`], data)
            }
        }).catch(() => {
            form.setFields([{
                name: `box${index}`,
                error: "Please enter"
            }])
        })
    }

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={channel == b2b ? 10 : 6}>
                    {channel == b2b ? AppConstants.orderId : AppConstants.manifestNo}
                </Col>
                {channel != b2b && <Col span={6} >
                    {AppConstants.courierService}
                </Col>}
                {/* <Col span={5} className=' d-flex jc-c'>
                    {AppConstants.handedBoxQty}
                </Col> */}
                <Col span={channel == b2b ? 10 : 7} >
                    {AppConstants.boxgngOut}
                </Col>
                <Col span={channel == b2b ? 4 : 3} className='d-flex jc-c'>
                    {AppConstants.action}
                </Col>
            </Row>
        )
    }

    const validateCount = (e: any, index: any) => {
        setBoxQty({ ...boxQty, [`boxes${index}`]: e.target.value });
        setIndex(index)

    }

    const tableBodyView = (item: any, index: any) => {

        return (
            <Row className={'table-body'} style={{ marginTop: 10 }}>
                <Col span={channel == b2b ? 10 : 6} className="open-icon d-flex align-c">
                    <div className='text'>
                    {channel == b2b ? item?.ID : item?.ManifestID}
                    </div>
                </Col>
                {channel != b2b && <Col span={6} >
                    <div className='text'>
                        {item?.CourierService}
                    </div>
                </Col>}
                <Col span={channel == b2b ? 10 : 7} className=' d-flex align-c'>
                    <Form.Item
                        name={`box${index}`}
                        rules={[
                            // { required: true, message: "Enter Box Count" },
                            { pattern: /^[0-9]*$/, message: "Enter Valid Count" },
                        ]}
                    >
                        <Input
                            placeholder='Enter Box Qty'
                            onChange={(e: any) => validateCount(e, index)}
                        />
                    </Form.Item>
                </Col>
                <Col span={channel == b2b ? 4 : 3} className='d-flex align-c jc-c'>
                    <div style={{ marginLeft: "-15px" }}>
                        <AppButtons
                            block={true}
                            disabled={!boxQty[`boxes${index}`]}
                            htmlType="submit"
                            onClick={() => moveToDispatch(item, index)}
                            buttonType={AppConstants.buttonTypes.solidLinearGreen}
                            text="Save"
                        />
                    </div>
                </Col>
            </Row>
        )
    }

    const tableView = () => {
        return (
            <div>
                {tableHeaderView()}
                <Form
                    form={form}
                >
                    {statusDetails?.lineitems?.map((x: any, index: any) => tableBodyView(x, index))}
                </Form>
            </div>
        )
    }


    const contentView = () => {
        return (
            <>
                {isArrayNotEmpty(statusDetails?.lineitems) ?
                    <div className='table-screen-container'>
                        {tableView()}
                    </div>
                    :
                    <div className='p-20'>
                        <NoData
                            className='mt-45'
                            description={
                                <>{AppConstants.nodata}<span className='redhat-bold ml-5'>Gate-Out Ready</span></>
                            }
                        />
                    </div>}
            </>
        )
    }

    const boxesMismatched = () => {
        return (
            <>
                <div className='redhat-bold font-16 mb-10' style={{ color: '#CD3232' }}>
                    Number of boxes entered is incorrect.
                </div>
                <div className='mb-20 redhat-semibold font-16'>
                    Contact warehouse manager for OTP
                </div>
                <div className='redhat-bold'>
                    Enter OTP:
                </div>
                <div className='mt-10'>
                    <Form.Item
                        name="otp"
                        rules={[{ required: true, message: "Enter OTP to Process" }]}
                    >
                        <Input
                            placeholder='Enter'
                            onChange={(e) => setValidationPopup({ ...validationPopup, OTP: e.target.value })}
                        />
                    </Form.Item>
                </div>
                <div className='d-flex flex-end' style={{ marginTop: 10 }}>
                    {/* <AppButtons
                        buttonType={AppConstants.buttonTypes.whiteButton}
                        onClick={() =>{
                             setValidationPopup({...validationPopup, mismatched: false})
                             validationForm.resetFields();
                            }}
                        text="Cancel"
                    /> */}
                    <AppButtons
                        htmlType='submit'
                        buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                        // onClick={() => completeReceiveInProgressApi()}
                        text="Proceed"
                    />
                </div>
            </>
        )
    }

    const boxesMtched = () => {
        return (
            <>
                <Row gutter={20}>
                    <Col span={12} className="mb-15">
                        <div className='redhat-semibold mb-5 required'>
                            {AppConstants.docketNumber}
                        </div>
                        <Form.Item
                            name='docket'
                            rules={[{ required: true, message: "Enter Docket Value" },
                            { pattern: /^[a-zA-Z0-9]*$/, message: 'Enter valid Number' }
                            ]}
                        >
                            <Input className='w-100'
                                onChange={(e) => setShipment({ ...shipment, docket: e.target.value })}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} >
                        <div className='redhat-semibold mb-5 required'>
                            {AppConstants.vehicleNumber}
                        </div>
                        <Form.Item
                            name='vehicle'
                            rules={[{ required: true, message: "Enter vehicle number" },
                            { pattern: /^[a-zA-Z0-9]*$/, message: 'Enter valid Number' }
                            ]}
                        >
                            <Input className='w-100'
                                onChange={(e) => setShipment({ ...shipment, vehicle: e.target.value })}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <div className='redhat-semibold mb-5'>
                            {AppConstants.driverName} (Optional)
                        </div>
                        <Form.Item
                        name='driver'
                        rules={[{ pattern: /^([a-zA-Z]+\s?)*$/, message: 'Enter valid Name' }]}
                        >
                            <Input className='w-100'
                                onChange={(e) => setShipment({ ...shipment, driverName: e.target.value })}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="mb-15">
                        <div className='redhat-semibold mb-5'>
                            {AppConstants.drivercontact} (Optional)
                        </div>
                        <Form.Item
                            name='contact'
                            rules={[
                                { pattern: /^[0-9]*$/, message: 'Enter valid Contact' }
                            ]}
                        >

                            <Input className='w-100'
                                onChange={(e) => setShipment({ ...shipment, driverContact: e.target.value })}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div className='d-flex flex-end mt-10'>
                    <AppButtons
                        htmlType='submit'
                        buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                        // onClick={() => completeReceiveInProgressApi()}
                        text="Save"
                    />
                </div>
            </>
        )
    }

    const popupView = () => {
        return (
            <Modal
                title={validationPopup?.matched ? <div className='redhat-bold mb-10 font-18'>
                    Please Provide additional shipment Information
                </div>
                    :
                    <div className='redhat-bold font-16'>Stop the shipment</div>
                }
                className='modal-view'
                // onCancel={()=>setValidationPopup({...validationPopup, mismatched: false})}
                // onOk={}
                style={{ width: "55%" }}
                open={validationPopup?.mismatched || validationPopup?.matched}
                footer={null}
            >
                <Form form={validationForm}
                    onFinish={validationPopup?.mismatched ? validateOTP : shipmentDetailsApi}
                >
                    {validationPopup?.mismatched ?
                        boxesMismatched()
                        :
                        boxesMtched()
                    }
                </Form>
            </Modal>
        )
    }

    return (

        <>
            {!statusDetailsOnload ?
                <div className='gate-out-screen'>
                    {headerView()}
                    {contentView()}
                    {popupView()}
                    <SpinLoader
                        loading={onload?.validateOnload || onload?.otpOnload || onload?.shipmentOnload}
                    />
                </div>
                :
                <SkeletonLoader />
            }
        </>
    );
}

export default GateOutReady;