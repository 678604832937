import { Row, Col, Table } from 'antd';
import '../CardContainer.scss';
import SubHeader from '../Header/Header';
import AppConstants from '../../Globals/AppConstants';
import { useEffect } from 'react';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import AppReferences from '../../Globals/AppReferences';
import { getIndividualStageAction } from '../../Store/Actions/ProcessWMSAction';
import { useDispatch, useSelector } from 'react-redux';
import AppButtons from '../../CustomComponent/AppButtons';
import { useNavigate } from 'react-router-dom';

function RTOB2BPending(props: any) {
    const { callBack, setProcessId, individualStageData } = props;
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    // const { individualStageData}: any = reducerState;
    const navigate = useNavigate();
    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={8}>
                    {AppConstants.gateInId}
                </Col>
                <Col span={8} className='text-center'>
                    {AppConstants.client}
                </Col>
                <Col span={8} className='text-center'>
                    {AppConstants.status}
                </Col>
            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        return (
            <div className={"content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={8} className="open-icon d-flex align-c"
                    >
                        <div className='text-1 app-blue'>
                            {item?.GateINID}
                        </div>
                    </Col>
                    <Col span={8} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.ClientName}
                        </div>
                    </Col>
                    <Col span={8} className='d-flex align-c jc-c'>
                        <AppButtons
                            onClick={() => callBack({ rtoDetails: item, setProcessId: setProcessId })}
                            text="Work on this"
                            buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        />
                    </Col>
                </Row>
            </div>
        )
    }

    const tableView = () => {
        return (
            <div className='table-screen-container'>
                {tableHeaderView()}
                <div>
                    {individualStageData?.lineitems?.map((data: any, index: any) => tableBodyView(data, index))}
                </div>
            </div>
        )
    }

    const dataView = () => {
        return (
            <div>
                <SubHeader
                    title={"Pending RTO/Return"}
                    paginationView="false"
                    inputView="true"
                    btnView="false"
                >
                </SubHeader>
                {tableView()}
            </div>

        )
    }

    return (
        <div>
            {dataView()}
        </div>
    );
}


export default RTOB2BPending;