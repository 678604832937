import { CloseOutlined } from '@ant-design/icons';
import { Button, DatePicker, Drawer, Form, Input, message, Radio, Select, TimePicker } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import AppReferences from '../../Globals/AppReferences';
import { createSOAction, getDetailsForSoAction, getSkuDescAction, saveSkuAction } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import SpinLoader from '../SpinLoader';

let salesOrderValue : any

function SalesOrderDrawerView(props: any) {
    const {
        openDrawer,
        setOpenDrawer,
        Client,
        setClient,
        getSOstatusDetails,
        setSalesOrder,
        salesOrder,
        getSalesClients,
        setIsInTable,
        isInTable
    } = props;
    const { Option } = Select;
    const reducerstate = useSelector((state: any) => state?.ProcessReducerState);
    const {
        salesOrderTypes,
        SODetails,
        getSkuDesc,
        createSOInfo,
    } = reducerstate;
    const [SOInfo, setSoDetails] = useState<any>({});
    const dispatch = useDispatch();
    const [form]: any = Form.useForm();
    const [form1]: any = Form.useForm();
    const [skuDetails, setSKUDetails] = useState<any>({});
    const [onload, setOnload] = useState<any>({
        SODetailsOnload: false,
        createOnload: false,
        skuOnload: false
    });
    let clientId = Client;

    useEffect(() => {
        setSoDetails({ ...SOInfo, client: Client })
    }, [Client]);

    useEffect(() => {
        if (salesOrder) {
            salesOrderValue = salesOrder
            form.setFieldsValue({
                ['reference']: '',
                ['kitting']: '',
                ['customer']: '',
                ['city']: '',
                ['state']: '',
                ['pincode']: '',
                ['email']: '',
                ['address']: '',
                ['shipDate']: null,
                ['orderPortal']: null,
                ['pickFrom']: '',
                ['channel']: '',
                ['aggregate']: '',
                ['comment']: ''
            })
            getSODetails()
        }
    }, [salesOrder]);

    useEffect(() => {
        if (openDrawer.openAddSku || openDrawer.openEditSku) {
            getSkuDescApi();
        }
    }, [openDrawer.openAddSku, openDrawer.openEditSku])

    const getSkuDescApi = () => {
        setClient(SOInfo?.client)
        let payload = {
            customer: SOInfo?.client,
            fieldarray: `SELECT ItemId, CONCAT(ItemId, ', ',Description) FROM ItemMaster WHERE ClientID = '${SOInfo?.client}';`,
            opnfor: 2,
            tableno: 33,
            warehouse: getWareHouseId(),
            where: 3
        }
        dispatch(getSkuDescAction(payload));
    }

    useEffect(() => {
        try {
            if (onload.SODetailsOnload && reducerstate.onload == false) {
                setOnload({ SODetailsOnload: false });
                console.log("SODetails", SODetails)
                form.setFieldsValue({
                    ['salesNo']: SODetails?.SOID?.SOID,
                    ['client']: clientId,

                })
                setOpenDrawer({ ...openDrawer, so_Id: SODetails?.SOID?.SOID })
                setSoDetails({ ...SOInfo, SOID: SODetails?.SOID?.SOID })
            }
        } catch (error) {
            console.log("Error in getSalesClients::", error)
        }
    }, [reducerstate.onload, onload.SODetailsOnload]);

    useEffect(() => {
        if (onload.createOnload && !reducerstate.onload) {
            closeSoDrawer();
            setOnload({ ...onload, createOnload: false });
            if (createSOInfo?.msg == 'Sales Order Header Created Successfully') {
                message.success(createSOInfo?.msg);
                setOpenDrawer({ ...openDrawer, openAddSku: true, openSo: false });
                // getSOstatusDetails();
                // getSalesClients();
            }
            else {
                message.error(createSOInfo?.msg);
            }
        }
    }, [onload.createOnload, reducerstate.onload]);

    useEffect(() => {
        if (onload.skuOnload && !reducerstate.saveSkuOnLoad) {
            setOnload({ ...onload, skuOnload: false });
            setSalesOrder(null);
            setSoDetails(null);
            setIsInTable(false);
            setOpenDrawer({ ...openDrawer, openAddSku: false, openEditSku: false });
            setClient(SOInfo?.client)
            getSalesClients();
            form1.resetFields();
        }
    }, [onload.skuOnload, reducerstate.saveSkuOnLoad]);


    const getSODetails = () => {
        let payload = {
            opnfor: AppReferences.opnfor.salesClient,
            activity: AppReferences.activities.a05,
            transaction: salesOrder,
            warehouse: getWareHouseId(),
            user: getUserInfo()?.UserName,
            fieldarray: getUserInfo()?.CorporateID
        }
        dispatch(getDetailsForSoAction(payload));
        setOnload({ SODetailsOnload: true })
    }

    const saveSku = () => {
        setClient(SOInfo?.client)
        console.log("(Number(salesOrderValue) === 3) ? skuDetails.tat : 'NULL'",((Number(salesOrderValue) === 3) ? skuDetails.tat : 'NULL'))
        let payload = {
            opnfor: 4,
            tableno: 45,
            where: 'SOID,ItemID,OrderedQuantity,ExpectedShelfLife,TAT,CreatedBy',
            fieldarray: `'${(isInTable === true) ? openDrawer?.soId : openDrawer?.so_Id}','${skuDetails.sku} ','${skuDetails.quantity} ','${skuDetails.shelfLife} ','${(Number(salesOrderValue) === 3) ? skuDetails.tat : 'NULL'} ','${getUserInfo()?.UserName} '`
        }
        dispatch(saveSkuAction(payload));
        setOnload({ ...onload, skuOnload: true })
    }

    const createSO = () => {
        let comment: any = SOInfo?.comment?.replaceAll(/[\r\n]/gm, " "); //to replace newlines with single space
        let commentText = comment.replaceAll(/  +/g, ' ');//to replace multiple spaces with single space
        let where = salesOrder == 1 ?
            'ClientID, SOID, CustomerName, AddressLine1, City, StateCode, PinCode, Email, SoTypeID, ReferenceId, Comment, ShipByDate, PickFrom, CreatedAt, CreatedBy' :
            salesOrder == 3 ?
                'ClientID, SOID, CustomerName, AddressLine1, City, StateCode, PinCode, Email, SoTypeID, ReferenceId, Comment, ShipByDate, Channel, Aggregator, PickFrom, OrderPortalDate, CreatedAt, CreatedBy'
                :
                'ClientID, SOID, SoTypeID, ReferenceId, Comment, KitLocationID, CreatedAt, CreatedBy';
        let transaction = salesOrder == 1 ? `'${SOInfo?.client}', '${SOInfo?.SOID}','${SOInfo?.customer}', '${SOInfo?.address}', '${SOInfo?.city}', '${SOInfo?.state}','${SOInfo?.pincode}', '${SOInfo?.email}', '${salesOrder}','${SOInfo?.reference}', '${commentText}', '${SOInfo?.shipDate}', '${SOInfo?.pickFrom}', '${getWareHouseId()}', '${getUserInfo()?.UserName}'`
            :
            salesOrder == 3 ?
                `'${SOInfo?.client}', '${SOInfo?.SOID}','${SOInfo?.customer}', '${SOInfo?.address}', '${SOInfo?.city}', '${SOInfo?.state}', '${SOInfo?.pincode}', '${SOInfo?.email}', '${salesOrder}','${SOInfo?.reference}','${commentText}', '${SOInfo?.shipDate}', '${SOInfo?.channel}', '${SOInfo?.aggregate}','${SOInfo?.pickFrom}','${SOInfo?.orderPortal}', '${getWareHouseId()}', '${getUserInfo()?.UserName}'`
                :
                `'${SOInfo?.client}', '${SOInfo?.SOID}','${salesOrder}','${SOInfo?.reference}','${commentText}','${SOInfo?.kittingLocation}','${getWareHouseId()}','${getUserInfo()?.UserName}'`;
        let payload = {
            opnfor: AppReferences.opnfor.createSo,
            activity: AppReferences.activities.ax01,
            tableno: 44,
            where: where,
            transaction: transaction,
            fieldarray: SODetails?.SOID?.SOID,
            warehouse: getWareHouseId(),
            customer: Client,
            user: getUserInfo()?.UserName
        }
        dispatch(createSOAction(payload));
        setOnload({ ...onload, createOnload: true });
    }

    const createSOFormView = () => {
        return (
            <Form
                onFinish={createSO}
                form={form}
                layout="vertical"
            >
                {/* <div className='text-1'>
                    Sales Order Type
                </div> */}
                <div className='mt-10'>
                    <Form.Item
                        label="Sales Order Type"
                        name={'sales_order_type'}
                        rules={
                            [{ required: true, message: "Select Sales Order Type" }]
                        }

                    >
                        <Select
                            placeholder='Select'
                            onChange={(e) => setSalesOrder(e)}
                        >
                            {(salesOrderTypes?.SoTypes || [])?.map((X: any) =>
                                <Option value={X?.id}>{X?.name}</Option>
                            )}
                        </Select>
                    </Form.Item>
                </div>
                {/* {(salesOrder == 4 && <div className='wr-in-progress' style={{ fontFamily: 'RedHat-Bold', marginTop: 20,display:"flex",alignItems:"center",justifyContent:'center',fontSize: 18 }}>Work in-progess</div> )} */}
                {salesOrder &&
                    <div>
                        <>
                            {/* <div style={{ fontFamily: 'RedHat-Bold', marginTop: 20 }}></div> */}
                            <div className='mt-10'>
                                <Form.Item
                                    label="Sales Order No."
                                    name='salesNo'
                                    rules={
                                        [{ required: true, message: "Enter Sales Order Number" }]
                                    }
                                >
                                    <Input
                                        className='input-for-so'
                                        readOnly={true}
                                    // onChange={()=>setSoDetails({...soDetails, sa})}
                                    />
                                </Form.Item>
                            </div>
                        </>
                        <div className='mt-10'>
                            <Form.Item
                                label="For Client"
                                name='client'
                                rules={
                                    [{ required: true, message: "Select Client" }]
                                }
                            >
                                <Select
                                    placeholder='Select'
                                    // value={clientId}
                                    onChange={(e) => {
                                        setSoDetails({ ...SOInfo, client: e });
                                        clientId = e
                                    }}
                                >
                                    {SODetails?.Clients?.map((x: any) =>
                                        <Option value={x.Id}>{x?.Name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='mt-10'>
                            <Form.Item
                                label="Reference No."
                                name={"reference"}
                                rules={
                                    [{ required: true, message: "Enter Reference number" }]
                                }
                            >
                                <Input className='input-for-so'
                                    maxLength={20}
                                    onChange={(e) => setSoDetails({ ...SOInfo, reference: e.target.value })}
                                />
                            </Form.Item>
                        </div>
                        {salesOrder == 4 &&
                            <div className='mt-10'>
                                <Form.Item
                                    label="Kitting Location"
                                    name='kitting'
                                    rules={
                                        [{ required: true, message: "Select Location" }]
                                    }
                                >
                                    <Select
                                        placeholder='Select'
                                        // value={clientId}
                                        onChange={(e) => {
                                            setSoDetails({ ...SOInfo, kittingLocation: e });
                                            clientId = e
                                        }}
                                    >
                                        {SODetails?.KittingLocations?.map((x: any) =>
                                            <Option value={x.ID}>{x?.Name}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </div>}
                        {salesOrder != 4 && <>
                            <div className='mt-10'>
                                <Form.Item
                                    label="Customer Name"
                                    name="customer"
                                    rules={
                                        [{ required: true, message: "Enter Customer Name" }]
                                    }
                                >
                                    <Input
                                        className='input-for-so'
                                        onChange={(e) => setSoDetails({ ...SOInfo, customer: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                            <div className='mt-10'>
                                <Form.Item
                                    label="Address"
                                    name="address"
                                    rules={
                                        [{ required: true, message: "Enter Address" }]
                                    }
                                >
                                    <Input className='input-for-so'
                                        onChange={(e) => setSoDetails({ ...SOInfo, address: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                            <div className='mt-10'>
                                <Form.Item
                                    label="City"
                                    name="city"
                                    rules={
                                        [{ required: true, message: "Enter City" },
                                        { pattern: /^([a-zA-Z]+\s)*[a-zA-Z]+$/, message: "Enter Valid Character" }
                                        ]
                                    }
                                >
                                    <Input className='input-for-so'
                                        maxLength={20}
                                        onChange={(e) => setSoDetails({ ...SOInfo, city: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                            <div className='mt-10'>
                                <Form.Item
                                    label="State Code"
                                    name="state"
                                    rules={
                                        [{ required: true, message: "Enter State Code" },
                                        { pattern: /^[a-zA-Z]*$/, message: "State Code must be alphabet ( example: Delhi = DL)" }
                                        ]
                                    }
                                >
                                    <Input className='input-for-so'
                                        maxLength={2}
                                        onChange={(e) => setSoDetails({ ...SOInfo, state: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                            <div className='mt-10'>
                                <Form.Item
                                    label="Pin Code "
                                    name="pincode"
                                    rules={[{ required: true, message: "Enter Pin Code " },
                                    { pattern: /^[0-9]*$/, message: "Pincode must be Numeric ( example: Delhi - 110001)" },
                                        // { pattern: /^\S*$/, message: "Space not allowed" }
                                    ]}
                                >
                                    <Input className='input-for-so'
                                        maxLength={6}
                                        onChange={(e) => setSoDetails({ ...SOInfo, pincode: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                            <div className='mt-10'>
                                <Form.Item
                                    label="E-mail"
                                    name="email"
                                    rules={
                                        [{ required: true, message: "Enter E-mail " },
                                        { pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message: 'Enter valid email' }
                                        ]
                                    }
                                >
                                    <Input className='input-for-so'
                                        onChange={(e) => setSoDetails({ ...SOInfo, email: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                            <div className='mt-10'>
                                <Form.Item
                                    label="Ship By Date "
                                    name="shipDate"
                                    rules={
                                        [{ required: true, message: "Enter Ship By Date  " }]
                                    }
                                >
                                    <DatePicker format={'DD-MM-YYYY'}
                                        className='date-picker'
                                        disabledDate={(e) => moment(e).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')}
                                        onChange={(e) => setSoDetails({ ...SOInfo, shipDate: moment(e).format('YYYY-MM-DD') })}
                                    />
                                </Form.Item>
                            </div>
                            {salesOrder == 3 && <>
                                <div className='mt-10'>
                                    <Form.Item
                                        label="Order Portal Date  "
                                        name="orderPortal"
                                        rules={
                                            [{ required: true, message: "Enter Order Portal Date  " }]
                                        }
                                    >
                                        <DatePicker format={'DD-MM-YYYY'} className='date-picker'
                                            disabledDate={(e) => moment(e).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')}
                                            onChange={(e) => setSoDetails({ ...SOInfo, orderPortal: moment(e).format('YYYY-MM-DD') })}
                                        />
                                    </Form.Item>
                                </div>
                            </>}
                            {salesOrder == 1 &&
                                <>
                                    <div className='mt-10'>
                                        <Form.Item
                                            label="Pick From   "
                                            name="pickFrom"
                                            rules={
                                                [{ required: true, message: "Select Pick From  " }]
                                            }
                                        >
                                            <Radio.Group className='radio-background'
                                                onChange={(e) => setSoDetails({ ...SOInfo, pickFrom: e.target.value })}
                                            >
                                                {SODetails?.LocationTypes?.map((x: any) =>
                                                    <Radio value={x?.id}>{x?.name}</Radio>)}
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                </>
                            }
                            {salesOrder == 3 && <div>
                                <div className='mt-10'>
                                    <Form.Item
                                        label="From Channel  "
                                        name="channel"
                                        rules={
                                            [{ required: true, message: "Select From Channel" }]
                                        }
                                    >
                                        <Select placeholder='Select'
                                            onChange={(e) => setSoDetails({ ...SOInfo, channel: e })}
                                        >
                                            {SODetails?.Channels?.map((x: any) =>
                                                <Option value={x?.id}>
                                                    {x?.name}
                                                </Option>
                                            )}

                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className='mt-10'>
                                    <Form.Item
                                        label="From Aggregator "
                                        name="aggregate"
                                        rules={
                                            [{ required: true, message: "Select From Aggregator" }]
                                        }
                                    >
                                        <Radio.Group className='radio-background'
                                            onChange={(e) => setSoDetails({ ...SOInfo, aggregate: e.target.value })}
                                        >

                                            {SODetails?.Aggregators?.map((x: any) =>
                                                <Radio value={x?.id}>{x?.name}</Radio>)}
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </div>}
                        </>}
                        <div className='mt-10'>
                            <Form.Item
                                label="Comment "
                                name="comment"
                                rules={
                                    [{ required: true, message: "Enter Comment" }]
                                }
                            >
                                <TextArea className='comment'
                                    onChange={(e) => setSoDetails({ ...SOInfo, comment: e.target.value })}
                                    maxLength={250}
                                />
                            </Form.Item>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, marginRight: 10 }}>
                            {/* <Button className='cancel-btn'
                                onClick={() => closeSoDrawer()}
                            >
                                Cancel
                            </Button> */}
                            <AppButtons
                                buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                onClick={() => closeSoDrawer()}
                                text={"Cancel"}
                            >
                            </AppButtons>
                            <AppButtons
                                block={true}
                                buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                text={"Save"}
                                htmlType={'submit'}
                            >
                            </AppButtons>
                        </div>
                    </div>}
            </Form>
        )
    }



    const closeSoDrawer = () => {
        setOpenDrawer({ ...openDrawer, openSo: false });
        form.resetFields();
        setSalesOrder('')
        setClient(SOInfo?.client)
    }

    const drawerView = () => {
        try {
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                Create New SO
                            </div>
                            {/* <Button onClick={() => closeSoDrawer()}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button> */}
                            <img onClick={() => closeSoDrawer()} className="c-p" src={AppImages.closeimg} alt='' />
                        </div>
                    }
                    // onClose={closeSoDrawer}
                    open={openDrawer?.openSo}>
                    <div>
                        {createSOFormView()}
                    </div >
                </Drawer >
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }
    }

    const closeSKUDrawer = () => {
        setOpenDrawer({ ...openDrawer, openAddSku: false, openEditSku: false });
        setSalesOrder(null)
        form1.resetFields()
    }

    const skuView = () => {
        try {
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            {openDrawer?.openAddSku == true && <div>
                                {"Add SKU"}
                            </div>}
                            {openDrawer?.openEditSku == true && <div>
                                {"Edit SKU"}
                            </div>}
                            {(openDrawer?.openAddSku == true || openDrawer?.openEditSku == true) &&
                                // <Button onClick={() => closeSKUDrawer()}
                                //     className="cross-btn">
                                //     <CloseOutlined />
                                // </Button>
                                <img onClick={() => closeSKUDrawer()} className="c-p" src={AppImages.closeimg} alt='' />
                            }
                        </div>
                    }
                    // onClose={() => closeSKUDrawer()}
                    open={openDrawer?.openAddSku == true || openDrawer?.openEditSku == true}>
                    <div>
                        <Form
                            onFinish={saveSku}
                            form={form1}
                        >
                            <div className='text-1'>
                                SKU,Description
                            </div>
                            <div className='mt-10'>
                                <Form.Item
                                    name={'sales_order_type'}
                                    rules={[{ required: true, message: "Select SKU" }]}
                                >
                                    <Select
                                        placeholder='Select'
                                        showSearch={true}
                                        optionFilterProp="children"
                                        onChange={(e) => setSKUDetails({ ...skuDetails, sku: e })}
                                    >
                                        {(getSkuDesc?.rData || []).map((item: any) => {
                                            let desc = item?.c?.split("|");
                                            return (
                                                <Option value={desc[0]}>{desc[1]}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div style={{ fontFamily: 'RedHat-Bold', marginTop: 20 }}>Quantity</div>
                            <div className='mt-10'>
                                <Form.Item
                                    name='quantity'
                                    rules={[
                                        { required: true, message: "Enter Quantity" },
                                        { pattern: /^[0-9]*$/, message: "No alphabets or Special Character allowed" },
                                        {
                                            validator(rule, value, callback) {
                                                if (value && (value == 0)) {
                                                    return Promise.reject('Enter Valid Qty')
                                                }
                                                else {
                                                    return Promise.resolve()
                                                }
                                            }
                                        }
                                    ]}
                                >
                                    <Input
                                        min="1"
                                        className='input-for-so'
                                        onChange={(e) => setSKUDetails({ ...skuDetails, quantity: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                            <div style={{ fontFamily: 'RedHat-Bold', marginTop: 20 }}>Shelf Life (In Months)</div>
                            <div className='mt-10'>
                                <Form.Item
                                    name='shelfLife'
                                    rules={[
                                        { required: true, message: "Enter Shelf Life" },
                                        { pattern: /^[0-9]*$/, message: "No alphabets or Special Character allowed" },
                                        {
                                            validator(rule, value, callback) {
                                                if (value && (value == 0)) {
                                                    return Promise.reject('Enter Valid Shelf Life')
                                                }
                                                else {
                                                    return Promise.resolve()
                                                }
                                            }
                                        }
                                    ]}
                                >
                                    <Input
                                        min='1'
                                        className='input-for-so'
                                        onChange={(e) => setSKUDetails({ ...skuDetails, shelfLife: e.target.value })}
                                    />
                                </Form.Item>
                            </div>
                            {(Number(salesOrderValue) === 3 )&& <>
                                <div style={{ fontFamily: 'RedHat-Bold', marginTop: 20 }}>Dispatch by Date</div>
                                <div className='mt-10'>
                                    <Form.Item
                                        name={"tat"}
                                    >
                                        <DatePicker format={'DD-MM-YYYY'}
                                            placement='bottomLeft'
                                            disabledDate={(e) => moment(e).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')}
                                            //  format={'HH:MM:SS'}
                                            onChange={(e) => setSKUDetails({ ...skuDetails, tat: moment(e).format('YYYY-MM-DD') })}
                                            className='w-100 input-for-so' />
                                    </Form.Item>
                                </div>
                            </>}
                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, marginRight: 10 }}>
                                <Button className='cancel-btn'
                                    onClick={() => closeSKUDrawer()}
                                >
                                    Cancel
                                </Button>
                                <AppButtons
                                    block={true}
                                    buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                                    text={"Save"}
                                    htmlType={'submit'}
                                >
                                </AppButtons>
                            </div>
                        </Form>
                    </div >
                </Drawer >
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }
    }
    return (
        <div>
            {drawerView()}
            {skuView()}
            <SpinLoader loading={onload?.skuOnload || onload?.createOnload} />
        </div>
    );
}

export default SalesOrderDrawerView;