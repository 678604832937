import { CaretDownFilled, SearchOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Calender from '../../Images/Calender';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import Array from '../../Images/Array';
import SANReport from './SANReport';
import GRNReport from './GRNReport';
import GeneralAdminExpenseReport from './GeneralAdminExpenseReport';
import OrderDispatchedReport from './OrderDispatchedReport';
import KittingReport from './KittingReport';
import RTOReturnReport from './RTOReturnReport';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import { useDispatch, useSelector } from 'react-redux';
import { GetDateReportList, GetReportList, GetSearchGateInReportList } from '../../Store/Actions/ProcessWMSAction';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { isArrayNotEmpty } from '../../Globals/Helper';
import GateInReport from './GateInReport';
import { AppModuleIds } from '../../Globals/AppReferenceIds';

let { RangePicker } = DatePicker;
const { Option } = Select;

function ReportIndexScreen() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const { reportList,reportOnload } = reducerState;
    const [reportValue, setReportValue] = useState<any>();
    const [searchView, setSearchView] = useState<any>(false)
    const [dateView, setDateView] = useState<any>(false)
    const [onLoad, setOnLoad] = useState<any>(false)
    const [date, setDate] = useState<any>({});
    const [inputValue,setInputValue] = useState<any>();

    useEffect(()=>{
        if(onLoad && !reportOnload){
            if(reportList?.ReportList.length > 0){
                setReportValue("A-01")
            }
        }
    },[onLoad,reportOnload])

    useEffect(() => {
        let payload = {
            "opnfor": "R00003",
            "activity": "A-V",
            "transaction": getWareHouseId(),
            "user": getUserInfo()?.UserName
        }
        dispatch(GetReportList(payload))
        setOnLoad(true)
    }, [])

    const getFilteredData = () => {
        
        let Date = `${date?.dateString[0]}|${date?.dateString[1]}`
        if (reportValue == "A-01" || reportValue == "A-04" || reportValue == "A-07") {
            let payload = {
                "opnfor": "R00003",
                "activity": reportValue == "A-04" ? "A-04" : reportValue == "A-07" ? "A-07" : "A-01",
                "transaction": Date,
                "where": "D",
                "warehouse": getWareHouseId(),
                "user": getUserInfo()?.UserName
            }
            dispatch(GetDateReportList(payload))
        } else if (reportValue == "A-02" || reportValue == "A-03") {
            let payload = {
                "opnfor": "R00003",
                "activity": reportValue == "A-02" ? "A-02" : "A-03",
                "transaction": Date,
                "where": "D",
                "customer": 0,
                "warehouse": getWareHouseId(),
                "user": getUserInfo()?.UserName
            }
            dispatch(GetDateReportList(payload))
        }
    }


    const searchData = (searchData: any) => {
        if (reportValue == "A-01" || reportValue == "A-04" || reportValue == "A-07") {
            let payload = {
                "opnfor": "R00003",
                "activity": reportValue == "A-04" ? "A-04" : reportValue == "A-07" ? "A-07" : "A-01",
                "transaction": searchData,
                "where": "S",
                "warehouse": getWareHouseId(),
                "user": getUserInfo()?.UserName
            }
            dispatch(GetSearchGateInReportList(payload))
        } else if (reportValue == "A-02" || reportValue == "A-03") {
            let payload = {
                "opnfor": "R00003",
                "activity": reportValue == "A-02" ? "A-02" : "A-03",
                "transaction": searchData,
                "where": "S",
                "customer": 0,
                "warehouse": getWareHouseId(),
                "user": getUserInfo()?.UserName
            }
            dispatch(GetSearchGateInReportList(payload))
        }

    }

    useEffect(() => {
        if (date?.value != null) {
            getFilteredData()
            setInputValue('')
            form.setFieldsValue({
                ['input_value']: ''
            });
        } else {
            setDateView(false)
        }
    }, [date])

    const onChange = (
            value: DatePickerProps['value'] | RangePickerProps['value'],
            dateString: [string, string] | string,
        ) => {
        setDate({ ...date, dateString: dateString, value: value });
    };

    const handleReport = (id: any) => {
        setReportValue(id);
        setDateView(false)
        setSearchView(false)
        setInputValue('')
        form.setFieldsValue({
            ['range_picker']: ['', ''],
            ['input_value']: ''
        });
    }

    const SearchValue = (value: any) => {

        if (value == "") {
            setSearchView(false)
        } else {
            setInputValue(value)
            form.setFieldsValue({
                ['range_picker']: ['', ''],
                ['input_value']: value
            });
        }
    }

    const header = () => {
        try {
            let selectedReport = reportList?.ReportList.find((item: any) => item?.Activity == reportValue)
            return (
                <SubHeader
                    title={<div>Reports - {selectedReport?.ReportName}</div>}
                    paginationView="false"
                    inputView="true"
                    btnView="true"
                >
                    <div className='d-flex flex-end'>
                        <div style={{ fontFamily: 'Redhat-Bold', marginRight: 10, display: "flex", fontSize: 16, color: "#2D2D2D" }}>
                            <div style={{ marginRight: 5, width: 120, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                Select Report :
                            </div>
                            <Select
                                className="select-field select-width"
                                suffixIcon={<CaretDownFilled />}
                                value={reportValue}
                                onChange={(e) => handleReport(e)}
                            >
                                {(reportList?.ReportList || []).map((item: any) =>
                                    <Option value={item?.Activity}>{item?.ReportName}</Option>
                                )}
                            </Select>
                        </div>
                        <div style={{ fontFamily: 'Redhat-Regular', marginRight: 10, width: "100%" }}>
                            <Form
                                form={form}
                            >
                                <Row gutter={16} style={{ width: "100%" }}>
                                    <Col span={10}>
                                        <Form.Item
                                            name="input_value"
                                        >
                                            <Input
                                                value={inputValue}
                                                placeholder='Search'
                                                allowClear={true}
                                                onChange={(e: any) => SearchValue(e?.target?.value)}
                                                onPressEnter={(e: any) => e?.target?.value !== "" && searchData(e?.target?.value)}
                                                prefix={<SearchOutlined onClick={(e: any) => e?.target?.value !== "" && searchData(e?.target?.value)} />} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={14}>
                                        <Form.Item
                                            name="range_picker"
                                        //rules={[{ required: true, message: 'Please select date' }]}
                                        >
                                            <RangePicker
                                                style={{ width: "100%" }}
                                                onChange={onChange}
                                                allowClear={true}
                                                className='date-select'
                                                suffixIcon={<Calender />}
                                                format="YYYY-MM-DD"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className='preview-button-container' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Array />
                        </div>
                    </div>
                </SubHeader>
            )
        } catch (ex) {
            console.log("Err in header::" + ex)
        }

    }
    const ContentView = () => {
        try {
            return (
                <div>
                    {reportValue == "A-01" && <GateInReport setSearchView={setSearchView} searchView={searchView} setDateView={setDateView} dateView={dateView} />}
                    {reportValue == "A-02" && <SANReport setSearchView={setSearchView} searchView={searchView} setDateView={setDateView} dateView={dateView} />}
                    {reportValue == "A-03" && <GRNReport setSearchView={setSearchView} searchView={searchView} setDateView={setDateView} dateView={dateView} />}
                    {reportValue == "A-04" && <OrderDispatchedReport setSearchView={setSearchView} searchView={searchView} setDateView={setDateView} dateView={dateView} />}
                    {reportValue == "A-05" && <KittingReport setSearchView={setSearchView} searchView={searchView} setDateView={setDateView} dateView={dateView} date={date}/>}
                    {reportValue == "A-06" && <RTOReturnReport  date={date} setDate={setDate}/>}
                    {reportValue == "A-07" && <GeneralAdminExpenseReport setSearchView={setSearchView} searchView={searchView} setDateView={setDateView} dateView={dateView} />}
                </div>

            )
        } catch (ex) {
            console.log("Err in ContentView::" + ex)
        }
    }
    return (
        <div>
            <NavbarLayout
                titleContent="sub-head"
                process={reportList?.title}
                initial={AppModuleIds.main}
                selectedMain={'12'}
            />
            <div>
                {header()}
            </div>
            {ContentView()}
        </div>
    );
}

export default ReportIndexScreen;