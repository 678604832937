import { CloseOutlined } from '@ant-design/icons';
import { Button, Progress, Table } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import AppConstants from '../../../Globals/AppConstants';
import SubHeader from '../../Header/Header';
import NavbarLayout from '../../NavbarLayout/NavbarLayout';

function StartRecievingDataView() {

    const boxescolumns: any = [
        {
            title: AppConstants.sku,
            dataIndex: 'sku',
            width: 600,
        },
        {
            title: AppConstants.batchNo,
            dataIndex: 'batchNo',
            width: 250,
            align: "left"
        },
        {
            title: AppConstants.importMon,
            dataIndex: 'importMon',
            width: 250,
            align: "center"
        },
        {
            title: AppConstants.mfgDate,
            dataIndex: 'mfgDate',
            width: 250,
            align: "center"
        },
        {
            title: AppConstants.ExpDate,
            dataIndex: 'ExpDate',
            width: 250,
            align: "center"
        },
        {
            title: AppConstants.invoiceQty,
            dataIndex: 'qty',
            width: 250,
            align: "center"
        },
        {
            title: AppConstants.recdQty,
            dataIndex: 'recdqty',
            width: 250,
            align: "center"
        },
        {
            title: AppConstants.good,
            dataIndex: 'good',
            width: 200,
            align: "center"
        },
        {
            title: AppConstants.bad,
            dataIndex: 'bad',
            width: 200,
            align: "center"
        }
    ]

    const dataSource = [

        {
            sku: 'SKU001 - Lorem Ipsum',
            batchNo: '983',
            importMon: 'Jun,23',
            mfgDate: "28/12/2019",
            ExpDate: "28/12/2021",
            qty:120,
            recdqty:200,
            good: 4,
            bad: 4
        },
        {
            sku: 'SKU001 - Lorem Ipsum',
            batchNo: '983',
            importMon: 'Jun,23',
            mfgDate: "28/12/2019",
            ExpDate: "28/12/2021",
            qty:120,
            recdqty:200,
            good: 4,
            bad: 4
        },


    ]

    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Progress type="circle" percent={5} format={percent => `${percent} /20`}  width={50} />
                <span className="complete-text" style={{ marginLeft: 15, marginRight: 15 }}>{"Completed"}</span>
                <Link  to="/start-recieving-view">
                    <Button className="close-btn">
                        <CloseOutlined />
                    </Button>
                </Link>
            </div>
        )
    }
    const contentView = () => {
        try {
            return (
                <div className="table-screen-container mt-45">
                    <Table
                        bordered
                        dataSource={dataSource}
                        columns={boxescolumns}
                        pagination={false}
                    />
                </div>
            )
        } catch (ex) {
            console.log("Error in contentView::" + ex)
        }
    }
    return (
        <>
            <NavbarLayout
                titleContent="sub-head"
                process="Start Receiving"
            />
            <SubHeader
                title={<div>Start Receiving<span>-WHM01-CUST6537-IN763756</span></div>}
                btnView="true"
                inputView="true"
                paginationView="false">
                {childrenView()}
            </SubHeader>
            { contentView() }
        </>
       
    );
}

export default StartRecievingDataView;