import React from 'react';

function PutAwayIcon360() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 18V14.8H18V18H15.6V16.4H11.2V18H8.8V16.4H4.4V18H2ZM5.2 13.2C4.97333 13.2 4.7832 13.1232 4.6296 12.9696C4.47653 12.8165 4.4 12.6267 4.4 12.4V2.8C4.4 2.57333 4.47653 2.3832 4.6296 2.2296C4.7832 2.07653 4.97333 2 5.2 2H14.8C15.0267 2 15.2165 2.07653 15.3696 2.2296C15.5232 2.3832 15.6 2.57333 15.6 2.8V12.4C15.6 12.6267 15.5232 12.8165 15.3696 12.9696C15.2165 13.1232 15.0267 13.2 14.8 13.2H5.2ZM7.6 6.8H12.4V5.2H7.6V6.8Z" fill="white"/>
        </svg>
    );
}

export default PutAwayIcon360;

