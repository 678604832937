

export const AppImages = {
    appLogo: require("../Images/EmizaMenuLogo.png"),
    sampleImage: require("../Images/sampleimage.png"),
    videoImage: require("../Images/videoImage.png"),
    helpIcon: require("../Images/helpIcon.png"),
    userImg: require("../Images/userImg.png"),
    personImg: require("../Images/Person.png"),
    loginBackground: require("../Images/loginbg.png"),
    emizaIcon: require("../Images/emizaIcon2.png"),
    boxIcon: require("../Images/box.png"),
    logintheme: require("../Images/loginTheme.png"),
    logincontainer: require("../Images/loginContainer.svg").default,
    logoutIcon: require("../Images/logoutIcon.svg").default,
    greenBin: require("../Images/greenBox.svg").default,
    redBin: require("../Images/redBox.svg").default,
    downloadIcon: require("../Images/downloadIcon.svg").default,
    productIcon: require("../Images/productIconCN2.svg").default,
    brandIcon: require("../Images/brandIcon.svg").default,
    prctIcon: require("../Images/ProductionImg.svg").default,
    tickIcon: require("../Images/checkedIcon.svg").default,
    productIcon2C: require("../Images/product2C.svg").default,
    filterIcon: require("../Images/filterIcon.svg").default,
    uploadIcon: require("../Images/UploadIcon.svg").default,
    // printerIcon: require("../Images/printer.svg").default,
    // exitArrowIcon: require("../Images/leftArrow.svg").default,
    // tempbrandIcon: require("../Images/tempBrand.svg").default,
    warehouseIcon:require("../Images/WarehouseIcon-360.svg").default,
    userIcon:require("../Images/User-360.svg").default,
    exitIcon:require("../Images/Exit-360.svg").default,
    closeIcon:require("../Images/closeIcon001.svg").default,
    closeimg: require("../Images/closeicon.png"),

}