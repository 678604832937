
import GrnListingScreen from '../GRN/GRN-Pending/GrnListingScreen';
import GrnEmailListingScreen from '../GRN/GRN-Email/GrnEmailListingScreen';
import { useLocation, useOutletContext } from 'react-router-dom';


function GRNIndex() {
    const [value, individualStageData, getStatges, getIndividualStageDetails]: any = useOutletContext();

    return (
        <>
            {value == 145100 && <GrnListingScreen stageDetails={individualStageData} getIndividualStageDetails={getIndividualStageDetails} value={value}/>}
            {value == 145200 && <GrnEmailListingScreen 
            getStatges={getStatges}
            getIndividualStageDetails={getIndividualStageDetails}
            stageDetails = {individualStageData} 
            />}
        </>
    );
}

export default GRNIndex;