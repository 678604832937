import { message, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import AppConstants from '../../../Globals/AppConstants';
import EmizaIcon from '../../../Images/emizaIcon';
import { Input } from 'antd';
import '../SAN.scss'
import AppButtons from '../../../CustomComponent/AppButtons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getWareHouseId, getUserInfo, setDashboardMenuId } from '../../../Utils/SessionStorage';
import { getSendSanInfoAction, sendSanDetails } from '../../../Store/Actions/ProcessWMSAction';
import SpinLoader from '../../SpinLoader';
import AppReferences from '../../../Globals/AppReferences';
import WMSIcon2 from '../../../Images/WMSIcon2';

interface DataType {
    item: any,
    callBack: any,
}

function SendSANViewScreen(props: DataType) {
    const {
        item,
        callBack,
    } = props;
    let warehouse = getWareHouseId();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const { getSendSanInfo, sendSanonload, emailSent} = reducerState;
    const dispatch = useDispatch();
    let data = getSendSanInfo?.lineitems[0];
    const [comment, setComment] = useState("");
    const [sendMailOnLoad, setSendMailOnLoad] = useState(false);
    const navigate = useNavigate();
    const [pageRefresh, setPageRefresh] = useState<any>(false);

    useEffect(() => {
        if (item) {
            getSendSanInfoData();
        }
    }, []);

    const getSendSanInfoData = () => {
        try {
            let custOpnfor = (item?.id).split("|")
            let payload = {
                "activity": AppReferences.activities.av,
                "opnfor": custOpnfor[1],
                "customer": custOpnfor[0],
                "transaction": custOpnfor[2],
                "user": getUserInfo()?.UserName,
                "warehouse": warehouse,
                "fieldArray": getUserInfo()?.CorporateID,
            }
            dispatch(getSendSanInfoAction(payload));
        } catch (error) {
            console.log("Error in getSendSanInfoData::", error);
        }
    }

    useEffect(() => {
        if (sendMailOnLoad == true && sendSanonload == false) {
            setSendMailOnLoad(false);
            if(emailSent?.msg == ""){
                message.success("Email sent Successfully");
                callBack();
                getSendSanInfoData();
            }
            else{
                message.error(emailSent?.msg);
            }
        }
    }, [sendMailOnLoad, sendSanonload])

    const sendSan = () => {
        try {
            let transaction = item?.show?.split(":")
            let custOpnfor = (item?.id).split("|")
            let emailBody: any = document.getElementById('sanFinalMail'); // get the email body which needs to send
            // As it's String and we have to pass this body to JSON we need to make this email body in single quotes(') 
            let tag = document.createElement("p");
            let content = comment?.replaceAll(/[\r\n]/gm," "); //to replace newlines with single space
            let text = document.createTextNode(content.replaceAll(/  +/g, ' ')); //to replace multiple spaces with single space
            tag.setAttribute("id", "emailContent");
            tag.setAttribute("style", "font-size: 16px; font-family: sans-serif;color: GrayText; padding:20px");
            tag.appendChild(text);
            emailBody.append(tag);
            let finalBody = emailBody?.innerHTML.replace(/"/g, "'");
            // Making of final email, concat the email body and the style/css into the emailFinal
            let emailFinal = "<html><head><style>.text-field{width:100%,border:1px solid darkgrey;height:40px;}</style></head><body>" + finalBody + "</body></html>"
            console.log("emailFinal", emailFinal);
            let payload = {
                "activity": AppReferences.activities.ax01,
                "transaction": transaction[1],
                "warehouse": warehouse,
                "opnfor": custOpnfor[1],
                "customer": custOpnfor[0],
                "user": getUserInfo()?.UserName,
                "where": emailFinal,
                "fieldArray": getUserInfo()?.CorporateID,
            }
            dispatch(sendSanDetails(payload));
            setSendMailOnLoad(true);
            let element = document.getElementById("emailContent");
            element?.remove();
            setPageRefresh(true)

        } catch (error) {
            console.log("Error in sendSan::", error);
        }
    }

    const columns: any = [
        {
            title: AppConstants.invoiceNo,
            dataIndex: "InvoiceNo",
            width: 300,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: AppConstants.invoiceBox,
            dataIndex: "InvoiceBoxQty",
            width: 200,
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: " Total Unloaded Boxes Including Damaged",
            dataIndex: "RecievedBoxQty",
            width: 500,
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Received Damaged  Box",
            dataIndex: "RecievedDamagedBoxQty",
            align: "center",
            width: 300,
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                );
            },
        },
    ];


    const SANDetailView = () => {
        try {
            return (
                <div className='table-screen-container ' style={{ margin: 0 }}>
                    <Table
                        columns={columns}
                        dataSource={getSendSanInfo?.lineitems}
                        pagination={false}
                    />
                </div>
            )
        } catch (ex) {
            console.log("Error in SanDetailView::" + ex)
        }
    }
    
    const contentView = () => {
        try {
            let [, , wharehouse_id] = (item?.show)?.split(" ");
            return (
                <div className='send-san-container'>
                    <div className='wms360-logo-container'><WMSIcon2 /></div>
                    <div className='template-view'>
                        <div className='content'>
                            <div style={{ fontFamily: "sans-serif", fontSize: 18, color: 'GrayText' }}>Dear Customer,</div>
                            <div style={{ fontFamily: "sans-serif", fontSize: 18, color: 'GrayText', marginTop: 10 }}>Greeting from 360WMS,</div>
                            {/* <div className='notes-sub1-title' style={{ marginBottom: 10, marginTop: 10 }}> This is shipment Arrival Notice for consignment number </div> */}
                        </div>
                        <div id='sanFinalMail'>
                            <div 
                                style={{ fontSize: 18, fontFamily: "sans-serif", color: "GrayText", marginLeft: 20, marginTop: 5 }}>
                                <span>This is Shipment Arrival Notice (SAN) for Consignment Number: {wharehouse_id}</span>
                            </div>
                            <div>
                                <div style={{ background: '#F0F0F4', padding: 20, marginTop: 10 }}>
                                    <table >
                                        <tr style={{ fontSize: 16, fontFamily: "sans-serif", color: "#FFFFFF", backgroundColor: "#505050", fontWeight: 100 }}>
                                            <th style={{ width: 400, textAlign: "center", padding: 6, fontWeight: 200 }}>Invoice Number</th>
                                            <th style={{ width: 400, textAlign: "center", padding: 6, fontWeight: 200 }}>Invoice Box Qty</th>
                                            <th style={{ width: 800, textAlign: "center", padding: 6, fontWeight: 200 }}>Total Unloaded boxes including Damaged</th>
                                            <th style={{ width: 800, textAlign: "center", padding: 6, fontWeight: 200 }}>Received Damaged Box Qty</th>

                                        </tr>
                                        {(getSendSanInfo?.lineitems || []).map((data: any) => {
                                            return (
                                                <tr style={{ fontSize: 18, fontFamily: "sans-serif", color: "#232323", backgroundColor: "rgb(255, 255, 255)", marginTop: 5 }}>
                                                    <td style={{ width: 400, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{data?.InvoiceNo}</td>
                                                    <td style={{ width: 400, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{data?.InvoiceBoxQty}</td>
                                                    <td style={{ width: 800, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{data?.RecievedBoxQty}</td>
                                                    <td style={{ width: 800, textAlign: "center", padding: 6, borderBottom: "1px solid #E0E0E0", fontWeight: 600 }}>{data?.RecievedDamagedBoxQty}</td>
                                                </tr>
                                            )
                                        })}
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* <div>test&nbsp;comment&nbsp;<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;test&nbsp;comment</div> */}
                        <textarea
                            style={{ padding: 10, marginTop: 20, fontSize: 16, fontFamily: "sans-serif", color: "GrayText" }}
                            className="text-field"
                            placeholder='Enter Additional comment..'
                            id='textAdditionalComment'
                            onChange={(e: any) => setComment(e?.target.value)}
                        >
                        </textarea>
                        <div style={{ marginBottom: 10, marginTop: 10, fontFamily: "sans-serif", fontSize: 18, color: "GrayText" }}> For any queries/concerns please contact your warehouse manager</div>
                        <div className='regard-container' style={{ marginTop: 20 }}>
                            <div style={{ fontFamily: "sans-serif", fontSize: 18, color: "GrayText" }}>{AppConstants.bestRegards}</div>
                            <div style={{ fontFamily: "sans-serif", fontSize: 16, color: "GrayText"  }}>Warehouse Manager</div>
                            <div style={{ fontFamily: "sans-serif", fontSize: 16, color: "GrayText"  }}>
                                {/* {data?.WarehouseName}<span >, {data?.AddressLine1}</span> */}
                                ABCD-123456, abcd
                            </div>
                            <div style={{ fontFamily: "sans-serif", fontSize: 16, color: "GrayText" }}>
                                {/* {data?.City}<span>-{data?.PinCode}</span>, <span>{data?.State}</span> */}
                                chennai-654321,abcd
                            </div>
                        </div>
                    </div>
                </div>
            )
        } catch (ex) {
            console.log("Error in contentView::" + ex)
        }
    }
    const viewAll = () => {
        try {
            return (
                <div className='send-san-view-screen-container'>
                    {contentView()}
                    <div className='btn-container'
                        style={{ marginRight: 10 }}
                    >
                        <AppButtons
                            onClick={() => sendSan()}
                            pageRefresh={pageRefresh}
                            block={true}
                            buttonType={AppConstants.buttonTypes.solidLinearGreen3} text="Send Email SAN Completed">
                        </AppButtons>
                    </div>

                </div>
            )
        } catch (ex) {
            console.log("Error in viewAll::" + ex)
        }

    }
    return (
        <>
            {viewAll()}
            <SpinLoader loading={sendMailOnLoad}/>
        </>
    );
}

export default SendSANViewScreen;