import { CaretRightOutlined, FilterFilled } from '@ant-design/icons';
import { Checkbox, Col, Dropdown, Menu, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoData from '../../CustomComponent/NoData';
import { isArrayNotEmpty, uniqueBy } from '../../Globals/Helper';
import { getEditData, GetExpandGateInReportList, GetGateInReportList } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import "../Report/Report.scss"


let Arr: any = [];
let uniqueClient: any = [];

function GateInReport(props: any) {
    const {
        setSearchView,
        searchView,
        setDateView,
        dateView
    } = props
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);

    const [expandRow, setExpandRow] = useState<any>();
    const [filterData, setFilterData] = useState<any>();
    const [filterDataView, setFilterDataView] = useState<any>(false)
    const [expandCartonRow, setExpandCartonRow] = useState<any>();
    const [OnLoad, setOnLoad] = useState<any>(false)
    const { reportGateInList, reportExpandGateInList, reportSearchGateInList, reportDateList ,reportGateInOnload} = reducerState;

    useEffect(() => {
        if (OnLoad === true && reportGateInOnload === false) {
            setFilterDataView(false)
            setSearchView(false)
            setDateView(false)
            getGateInFilterData()
        }
    }, [OnLoad, reportGateInOnload])

    const getData = (data: any) => {
        let payload = {
            "opnfor": "R00003",
            "activity": "A-01",
            "transaction": data ? data?.GateInID : 0,
            "where": data ? "Y" : "N",
            "warehouse": getWareHouseId(),
            "user": getUserInfo()?.UserName
        }
        data ? dispatch(GetExpandGateInReportList(payload)) : dispatch(GetGateInReportList(payload))
        setOnLoad(true)
    }

    useEffect(() => {
        getData(null)
    }, [])


    const getGateInFilterData = () => {
        let arr = (reportGateInList?.lineitems || []).map((item: any) => {
            let Obj = {
                "ClientName": item?.ClientName
            }
            return Obj
        })
        uniqueClient = uniqueBy(arr, 'ClientName')
    }

    const getSearchFilterData = () => {
        let arr = (reportSearchGateInList?.lineitems || []).map((item: any) => {
            let Obj = {
                "ClientName": item?.ClientName
            }
            return Obj
        })
        uniqueClient = uniqueBy(arr, 'ClientName')
    }

    const getDateFilterData = () => {
        let arr = (reportDateList?.lineitems || []).map((item: any) => {
            let Obj = {
                "ClientName": item?.ClientName
            }
            return Obj
        })
        uniqueClient = uniqueBy(arr, 'ClientName')
    }



    useEffect(() => {
        if (searchView === false) {
            getGateInFilterData()
        }
    }, [searchView])

    useEffect(() => {
        if (dateView === false) {
            getGateInFilterData()
        }
    }, [dateView])



    useEffect(() => {
        if (reportDateList) {
            setDateView(true)
            setSearchView(false)
            setFilterDataView(false)
            getDateFilterData()
        }
    }, [reportDateList])

    useEffect(() => {
        if (reportSearchGateInList) {
            setSearchView(true)
            setDateView(false)
            setFilterDataView(false)
            getSearchFilterData()
        }
    }, [reportSearchGateInList])

    useEffect(() => {
        if (filterData?.length == 0) {
            setFilterDataView(false)
            setSearchView(false)
            setDateView(false)
        }
    }, [filterData])

    const filterTableData = () => {
        let filterData = (dateView ? reportDateList?.lineitems : searchView ? reportSearchGateInList?.lineitems : reportGateInList?.lineitems).filter((item: any) => {
            return Arr.includes(item?.ClientName)
        });
        setFilterData(filterData)
        setFilterDataView(true)
        setSearchView(false)
        setDateView(false)
    }


    const onChange = (e: CheckboxChangeEvent) => {

        if (e?.target.checked) {
            Arr.push(e?.target.value)
            filterTableData()
        } else {
            Arr = Arr.filter((item: any) => item !== e?.target.value)
            filterTableData()
        }


    };

    const menu = () => {
        return (
            <Menu
                className="booth-representative-menu-view"
            >
                {(uniqueClient || []).map((item: any) => {
                    return (
                        <Menu.Item className="booth-representative-menu-view-item" key={1}>
                            <div> <Checkbox value={item?.ClientName} onChange={onChange}>{item?.ClientName}</Checkbox></div>
                        </Menu.Item>
                    )
                })}
            </Menu>
        )
    }

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
            getData(data)
        }
    }

    const skuInvoiceHeaderView = () => {
        try {
            return (
                <Row className='table-header sku-header  align-c'>
                    <Col span={12} className="title">
                        {"SKU,Description"}
                    </Col>
                    <Col span={12} className='title d-flex align-c jc-c'>
                        {"Invoice SKU Qty"}
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in cartontableHeaderView::" + ex)
        }
    }

    const invoicetableHeaderView = () => {
        try {
            return (
                <Row className='table-header invoice-header  align-c'>
                    <Col span={5} className="title">
                        {"Invoice"}
                    </Col>
                    <Col span={5} className='title d-flex align-c jc-c'>
                        {"Invoice Box Qty"}
                    </Col>
                    <Col span={5} className="title d-flex align-c jc-c">
                        {"Damaged Box Qty"}
                    </Col>
                    <Col span={4} className="title d-flex align-c jc-c">
                        {"Invoice Weight"}
                    </Col>
                    <Col span={5} className='title d-flex align-c jc-c'>
                        {"Executive"}
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Err in cartontableHeaderView::" + ex)
        }
    }

    const handleCartonExpand = (e: any, index: any, item: any) => {
        e.stopPropagation();
        if (index == expandCartonRow) {
            setExpandCartonRow(-1)
        }
        else {
            setExpandCartonRow(index);
        }
    }


    const invoicetableBodyView = (item: any, index: any) => {
        try {
            let open = index == expandCartonRow;
            return (
                <div className={index === expandCartonRow ? "content-container-gray" : "content-container"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={5} className="open-icon d-flex align-c" onClick={(e: any) => handleCartonExpand(e, index, item)}>
                            <CaretRightOutlined
                                className={open ? "arrow arrow-down" : "arrow arrow-right"}
                            />
                            <div className='title-blue'>
                                {item?.InvoiceNo}
                            </div>
                        </Col>
                        <Col span={5} className=' d-flex align-c jc-c'>
                            <div className='text-1'>
                                {item?.InvoiceBoxQty}
                            </div>
                        </Col>
                        <Col span={5} className=' d-flex align-c jc-c'>
                            <div className='text-1'>
                                {item?.ReceivedDamagedBoxQty}
                            </div>
                        </Col>
                        <Col span={4} className=' d-flex align-c jc-c'>
                            <div className='text-1'>
                                {item?.InvoiceWeight}
                            </div>
                        </Col>
                        <Col span={5} className=' d-flex align-c jc-c'>
                            <div className='text-1'>
                                {item?.ExecutiveName}
                            </div>
                        </Col>
                        {open && <Col span={24} style={{ padding: 10, marginTop: 10 }}>
                            {skuInvoiceHeaderView()}
                            <div className='scroll'>
                                {(item?.Lines || []).map((x: any) => {
                                    return (
                                        <Row className='content-container border-blue d-flex align-c jc-sb mt-10'>
                                            <Col span={12} className='text-1'>{x?.ItemIDDesc}</Col>
                                            <Col span={12} className='text-1 d-flex align-c jc-c'>{x?.InvQty}</Col>
                                        </Row>
                                    )
                                })}
                            </div>
                        </Col>}
                    </Row>
                </div >
            )
        } catch (ex) {
            console.log("Err in invoicetableBodyView::" + ex)
        }
    }

    const ViewExpandDetails = () => {
        try {
            return (
                <div style={{ padding: 10, background: "#FFFFFF", borderRadius: 6, marginTop: 10 }}>
                    {invoicetableHeaderView()}
                    <div className='body-view'>{(reportExpandGateInList?.lineitems || []).map((x: any, index: any) => invoicetableBodyView(x, index))}</div>

                </div>
            )
        } catch (ex) {
            console.log("Err in ViewExpandDetails::" + ex)
        }
    }

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={7}>
                    {"GateIn ID"}
                </Col>
                <Col span={6} className=' d-flex jc-c'>
                    {"Clients"}
                    <span style={{ marginLeft: 20 }}>
                        <Dropdown
                            overlayStyle={{ width: 244, left: 1094 }}
                            overlay={() => menu()} placement="bottomRight" trigger={['click']}>
                            <div><FilterFilled /></div>

                        </Dropdown>
                    </span>

                </Col>
                <Col span={6} className=' d-flex jc-c'>
                    {"Total SKU"}
                </Col>
                <Col span={5} className=' d-flex jc-c'>
                    {"Total units"}
                </Col>
            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        return (
            <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={7} className="open-icon d-flex align-c" onClick={(e: any) => handleExpand(e, index, item)}>
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='title-blue'>
                            {item?.GateInID}
                        </div>
                    </Col>
                    <Col span={6} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.ClientName}
                        </div>
                    </Col>
                    <Col span={6} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.TotalSKUQty}
                        </div>
                    </Col>
                    <Col span={5} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {item?.TotalUnits}
                        </div>
                    </Col>
                    {open && <Col span={24}>
                        {ViewExpandDetails()}
                    </Col>}
                </Row>
            </div >
        )
    }

    return (
        <div className='GateInReport-Container'>
            {(dateView ? isArrayNotEmpty(reportDateList?.lineitems) : searchView ? isArrayNotEmpty(reportSearchGateInList?.lineitems) : filterDataView ? isArrayNotEmpty(filterData) : isArrayNotEmpty(reportGateInList?.lineitems)) ?
                <div style={{ padding: 20 }}>
                    {tableHeaderView()}
                    <div className='table-scroll'>
                        {(dateView ? reportDateList?.lineitems : searchView ? reportSearchGateInList?.lineitems : filterDataView ? isArrayNotEmpty(filterData) ? filterData : reportGateInList?.lineitems : reportGateInList?.lineitems || []).map((x: any, index: any) => tableBodyView(x, index))}
                    </div>
                </div>
                :
                <div className='p-20'>
                    <NoData
                        className='mt-45'
                        description={
                            <>There is no reports available in <span className='redhat-bold ml-5'>GateIn</span></>
                        }
                    />
                </div>
            }
        </div>
    );
}

export default GateInReport;