import React from 'react';

function MenuIcon() {
    return (
        <svg width="22" height="22" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.0770874" y="0.0771484" width="13.8462" height="2.30769" rx="1.15385" fill="white" />
            <rect x="0.0770264" y="5.84644" width="13.8462" height="2.30769" rx="1.15385" fill="white" />
            <rect x="0.0770264" y="11.6155" width="13.8462" height="2.30769" rx="1.15385" fill="white" />
        </svg>
    );
}

export default MenuIcon;

