import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { isArrayNotEmpty } from '../../Globals/Helper';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';

let Arr: any = [1, 2, 3];

function PutAway(props: any) {


    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={8}>
                    {"GateIn ID"}
                </Col>
                <Col span={8} className=' d-flex jc-c'>
                    {"Client Name"}
                </Col>
                <Col span={8} className=' d-flex jc-c'>
                    {"Action"}
                </Col>
            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        return (
            <div className={"content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={8} className="open-icon d-flex align-c">
                        <div className='title-blue font-18'>
                            {`WH01-CUST-20230118-7265${index}`}
                        </div>
                    </Col>
                    <Col span={8} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                            {"Mamaearth"}
                        </div>
                    </Col>
                    <Col span={8} className=' d-flex align-c jc-c'>
                        <Link to="/packing-putaway-view" state={{wh: `WH01-CUST-20230118-7265${index}`}}>
                            <AppButtons
                                text={'Create Putaway'}
                                buttonType={AppConstants.buttonTypes.solidLinearGreen}
                            />
                        </Link>
                    </Col>
                </Row>
            </div >
        )
    }

    const header = () => {
        try {
            return (
                <SubHeader
                    title={<div>Putaway</div>}
                    paginationView="false"
                    inputView="true"
                    btnView="true"
                >
                </SubHeader>
            )
        } catch (ex) {
            console.log("Err in header::" + ex)
        }
    }

    const ContentView = () => {
        try {
            return (
                <div className='packing-material-container'>
                    {isArrayNotEmpty(Arr) ?
                        <div style={{ padding: 20 }}>
                            {tableHeaderView()}
                            <div className='table-scroll'>
                                {(Arr || []).map((x: any, index: any) => tableBodyView(x, index))}
                            </div>
                        </div>
                        :
                        <div className='p-20'>
                            <NoData
                                className='mt-45'
                                description={
                                    <>There is no reports available in <span className='redhat-bold ml-5'>Put Away</span></>
                                }
                            />
                        </div>
                    }
                </div>
            )
        } catch (ex) {
            console.log("Err in ContentView::" + ex)
        }
    }
    return (
        <div>
            <NavbarLayout
                titleContent="sub-head"
            //process={reportList?.title}
            />
            <div style={{ marginTop: 50 }}>
                {header()}
            </div>
            {ContentView()}
        </div>
    );
}

export default PutAway;