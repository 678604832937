import { CaretRightOutlined, CloseOutlined, EyeFilled, SearchOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, InputNumber, Modal, Row, Table, message, Tooltip } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppButtons from '../../../CustomComponent/AppButtons';
import NoData from '../../../CustomComponent/NoData';
import AppConstants from '../../../Globals/AppConstants';
import AppReferences from '../../../Globals/AppReferences';
import { generateGuId } from '../../../Globals/Helper';
import DownloadIcon from '../../../Images/DownloadIcon';
import { consignmentAction, getAttachmentsAction, getIndividualStageAction, getStageDetailsAction, getUloadingDetailsAction, sendStickeringAction } from '../../../Store/Actions/ProcessWMSAction';
import { getDashboardMenuId, getUserInfo, getWareHouseId, setDashboardMenuId } from '../../../Utils/SessionStorage';
import SubHeader from '../../Header/Header';
import { Amplify, Auth, Storage } from "aws-amplify";
import SpinLoader from '../../SpinLoader';
import ImgModelView from '../../ImgModelView';
import { AppImages } from '../../../Globals/AppImages';

const suffix = (
    <SearchOutlined
        style={{
            fontSize: 20,
            color: 'black',
        }}
    />
);

Amplify.configure({
    Auth: {
        identityPoolId: "ap-south-1:0fed2e35-15bf-46d3-b794-c938dc515509", //REQUIRED - Amazon Cognito Identity Pool ID
        region: "ap-south-1", // REQUIRED - Amazon Cognito Region
        userPoolId: "ap-south-1_wGjzBalCU", //OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: "1ptdtdunusepaj6440qh7c11b2", //OPTIONAL - Amazon Cognito Web Client ID 
    },
    Storage: {
        AWSS3: {
            bucket: process.env.REACT_APP_BUCKET_NAME, //REQUIRED -  Amazon S3 bucket name
            region: "ap-south-1", //OPTIONAL -  Amazon service region
        }
    }
});

function UnloadingLists(props: any) {
    const { stageDetails, getStatges } = props;
    const navigate = useNavigate();
    const [expandRow, setExpandRow] = useState<any>(-1);
    const dispatch = useDispatch();
    const [onload, setOnload] = useState(false);
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const { unloadingInfo, attachementDetails } = reducerState;
    const [boxdetails, setBoxDetails] = useState<any>({
        totalBox: 0,
        damagedBox: 0,
        datas: "",
        count: ""
    });
    let [uloadedBox] = (unloadingInfo?.header || []);
    const [stickeringOnload, sendStickeringOnload] = useState(false);
    const [openDrawer, setOpenDrawer] = useState<any>();
    const warehouseId = getWareHouseId();
    const [form]: any = Form.useForm();
    const [showModal, setShowModal] = useState<any>(false);
    const [complete, setComplete] = useState<any>(false);
    const [item, setItem] = useState<any>();
    const [index, setIndex] = useState<any>();
    const wareHouse_id = getWareHouseId();
    const [consignment, setConsignment] = useState<any>();
    const { consignmentData, unloadingComplete } = useSelector((state: any) => state?.ProcessReducerState)
    const drawerData = consignmentData?.lineitems[0];
    const [uploadIndex, setUploadIndex] = useState<any>({ index: -1, data: null });
    const [load, setLoad] = useState(false);
    const [openModal, setOpenModal] = useState<any>();

    const getIndividualStageDetails = async () => {
        let user = await getUserInfo();
        let payload = {
            "activity": AppReferences.activities.av3,
            "warehouse": warehouseId,
            "opnfor": AppReferences.opnfor.wms,
            "where": getDashboardMenuId(),
            "user": user?.UserName,
            "fieldarray": user?.roleId,
           
        }
        dispatch(getIndividualStageAction(payload));
        // setDataOnload(true);
    }

    useEffect(() => {
        if (uploadIndex?.data) {
            getAttchements();
        }
    }, [uploadIndex?.data])

    useEffect(() => {
        if (stageDetails?.lineitems?.length != 0) {
            getUnloadingData(stageDetails?.lineitems[0]);
        }
    }, [stageDetails])

    useEffect(() => {
        if (stickeringOnload && !reducerState?.sendOnload) {
            if (unloadingComplete?.msg == '') {
                sendStickeringOnload(false);
                getStatges();
                getIndividualStageDetails();
                form.resetFields();
                setExpandRow(-1);
                message.success("Unloaded Successfully");
            }
            else {
                message.error('')
            }


        }
    }, [stickeringOnload, reducerState?.sendOnload])

    const uploadToAWS = async (file: any) => {
        try {
            let fileType = file?.type?.split('/');
            let warehouse = getWareHouseId()?.toLowerCase();
            let invoice = uploadIndex?.data?.InvoiceNo;
            let custCode = stageDetails?.header?.find((item: any) => {
                let data = item?.show?.split(' : ');
                if (data[0] == uploadIndex?.gateIn) {
                    return item
                }
            });
            if (fileType[1] == 'jpeg' || fileType[1] == 'png') {
                setLoad(true)
                let randomGuid = generateGuId();
                let extension = file.name.substring(file.name.lastIndexOf('.'));
                let pathValue = `${custCode?.custCode?.toLowerCase()}/${warehouse}/120150/${invoice}/${randomGuid}${extension}`;
                var Params = {
                    resumable: true,
                    contentType: file?.type,
                    bucket: process.env.REACT_APP_BUCKET_NAME,
                    completeCallback: () => {
                        message.success(`Uploaded Successfully`);
                        getAttchements();
                        setLoad(false);
                        setUploadIndex({ ...uploadIndex, data: '', gateIn: '' });
                    },
                };
                if (file.size <= 5000000) {
                    await Storage.put(pathValue, file, Params)
                } else {
                    message.error("File size should not exceed 5MB")
                }
            }
            else {
                message.error("Selected file type is not supported");
            }
        } catch (error) {
            console.log("Error uploading file: ", error);
        }
    }

    const openImageviewr = (url: any, index: any) => {
        let attachments = attachementDetails?.media?.objects;
        let startIndex = attachments?.slice(index, attachments.length);
        let restOfData = attachments?.slice(0, index);
        setOpenModal({ isOpen: true, urlArray: [...startIndex, ...restOfData], index: index });
    }

    const getAttchements = () => {
        let custCode = stageDetails?.header?.find((item: any) => {
            let data = item?.show?.split(' : ');
            if (data[0] == uploadIndex?.gateIn) {
                return item
            }
        });
        let payload = {
            opnfor: AppReferences.opnfor.unloading,
            activity: AppReferences.activities.a07,
            transaction: uploadIndex?.data?.InvoiceNo,
            where: custCode?.custCode,
            warehouse: getWareHouseId()
        }
        dispatch(getAttachmentsAction(payload))
    }



    const savetotalCount = (item: any, index: any) => {
        if (Number(boxdetails[`damagedBox${index}`]) > 0) {
            if (attachementDetails?.media?.objects.length > 0) {
                if (Number(boxdetails.count) < Number(boxdetails[`damagedBox${index}`])) {
                    setShowModal(true);
                }
                else {
                    if (Number(boxdetails?.datas?.InvoiceBoxQty) != Number(boxdetails.count)) {
                        if (Number(boxdetails?.datas?.InvoiceBoxQty) < Number(boxdetails[`damagedBox${index}`])) {
                            setShowModal(true);
                        } else {
                            setShowModal(true)
                        }
                    } else if (Number(boxdetails?.datas?.InvoiceBoxQty) < Number(boxdetails[`damagedBox${index}`])) {
                        setShowModal(true);
                    }
                    else {
                        totalCountApi(item, index);
                        setShowModal(false)
                    }
                }
            } else {
                message.error("Must upload  the damaged  box image")
            }
        } else {
            if (Number(boxdetails.count) < Number(boxdetails[`damagedBox${index}`])) {
                setShowModal(true);
            }
            else {
                if (Number(boxdetails?.datas?.InvoiceBoxQty) != Number(boxdetails.count)) {
                    if (Number(boxdetails?.datas?.InvoiceBoxQty) < Number(boxdetails[`damagedBox${index}`])) {
                        setShowModal(true);
                    } else {
                        setShowModal(true)
                    }
                } else if (Number(boxdetails?.datas?.InvoiceBoxQty) < Number(boxdetails[`damagedBox${index}`])) {
                    setShowModal(true);
                }
                else {
                    totalCountApi(item, index);
                    setShowModal(false)
                }
            }
        }
    }

    const totalCountApi = (item: any, index: any) => {
        let damagedbox = boxdetails[`damagedBox${index}`] ? boxdetails[`damagedBox${index}`] : 0;
        console.log("boxdetails[`totalBox${index}`]", boxdetails[`totalBox${index}`], boxdetails[`damagedBox${index}`])
        let payload = {
            "warehouse": getWareHouseId(),
            "where": `${boxdetails[`totalBox${index}`]}|${damagedbox}`,
            "user": getUserInfo()?.UserName,
            "transaction": item?.GateEntryInvoiceRecordNumber,
            "customer": uloadedBox?.cId,
            "activity": AppReferences.activities.ax01,
            "opnfor": unloadingInfo?.title?.stageNo
        }
        dispatch(sendStickeringAction(payload));
        sendStickeringOnload(true);
    }

    const sendToSickering = (item: any, index: any) => {
        setItem(item)
        setIndex(index)
        form.validateFields([`totalBox${index}`, `damagedBox${index}`], (err: any, values: any) => {
            console.log("err", err)
        }).then(() => {
            savetotalCount(item, index);
        }).catch(() => {
            form.setFields([{
                name: `totalBox${index}`,
                error: "Please enter"
            }, {
                name: `damagedBox${index}`,
                error: "Please enter"
            }])
        })
    }

    useEffect(() => {
        if (onload && !reducerState?.onload) {
            //   boxdetails.totalBox = uloadedBox?.RecievedBoxQty;
            //   boxdetails.damagedBox = uloadedBox?.RecievedDamagedBoxQty;
            //   setBoxDetails({...boxdetails});
            setOnload(false);
        }
    }, [onload, reducerState?.onload])

    const getUnloadingData = (data: any) => {
        try {
            let clientDetails = stageDetails?.header?.find((x: any) => {// this is to find the transaction id, customer, opnfor
                let gatinId = x?.show?.split(" : ");
                if (gatinId[0] == data?.GateEntryRecordNo) {
                    return x;
                }
            })
            let client = clientDetails?.id?.split("|");
            let payload = {
                "customer": client[0],
                "activity": AppReferences.activities.av,
                "opnfor": client[1],
                "transaction": client[2],
                "warehouse": warehouseId,
                "user": getUserInfo()?.UserName,
            }
            dispatch(getUloadingDetailsAction(payload));
            setOnload(true)
        } catch (error) {
            console.log("Error in getData::", error);
        }
    }

    const closeDragger = (e: any) => {
        e.stopPropagation();
        setUploadIndex(-1);
    }

    const uploadContainerView = () => {
        try {
            let prefixUrl = attachementDetails?.media?.prefix;
            return (
                <>
                    <Dragger
                        beforeUpload={(e) => { uploadToAWS(e); return false }}
                        className='mt-10'
                        accept='jpeg, png'
                    >
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <DownloadIcon />
                            <div className='dragger-content ' style={{ marginLeft: 5, textAlign: "left" }}>
                                <div className='drag-txt '>Drag & Drop files or <span className='show-label '>Browse</span></div>
                                <div className='note-content '>Supported Formates: JPEG,PNG</div>
                            </div>
                        </div>
                        {/* <Button className="close-button"
                            shape='circle'
                            onClick={(e) => closeDragger(e)}
                        >
                            <CloseOutlined />
                        </Button> */}
                        <img onClick={(e) => closeDragger(e)} className="close-button  c-p" src={AppImages.closeimg} alt='' />
                    </Dragger>
                    <Row className='img-box'>
                        {attachementDetails?.media?.objects?.map((url: any, index: any) => {
                            return (

                                <Col className='img-comtainer' onClick={() => openImageviewr(prefixUrl + url, index)} style={{ cursor: "pointer" }}>
                                    <img src={prefixUrl + url} alt='Loading' />
                                </Col>
                            )
                        })}
                    </Row>
                </>
            )
        } catch (ex) {
            console.log("Error in uploadContainerView::" + ex)
        }
    }

    const invoiceTableBodyView = (data: any, index: any, gateIn: any) => {
        return (
            <Row className={'table-body'}>
                <Col span={4} >
                    <div className='text overflow-ellipse' title={data?.InvoiceNo}>
                        {data?.InvoiceNo}
                    </div>
                </Col>
                <Col span={4}>
                    <div className='text'>
                        {data?.UserName}
                    </div>
                </Col>
                <Col span={3} className='d-flex align-c jc-c'>
                    <div className='text-1'>
                        {data?.LaboursAssigned}
                    </div>
                </Col>
                <Col span={4} className='d-flex align-c'>
                    <Form.Item
                        className='d-flex align-c'
                        name={`totalBox${index}`}
                        rules={[
                            { required: true, message: "Enter Total Box Count" },
                            { pattern: /^[0-9]*$/, message: "Enter Valid Count" },
                            {
                                validator(rule, value, callback) {
                                    if (value && (value < 1 || value.length > 4)) {
                                        return Promise.reject('Enter Valid Count')
                                    }
                                    else {
                                        return Promise.resolve()
                                    }
                                }
                            }
                        ]}
                    >
                        <Input
                            className="box-input"
                            onChange={(e: any) => {
                                setBoxDetails({ ...boxdetails, [`totalBox${index}`]: e.target.value, datas: data, count: e.target.value })
                            }} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <div className='d-flex align-c'>
                        <Form.Item
                            name={`damagedBox${index}`}
                            rules={[
                                { pattern: /^[0-9]*$/, message: "Enter Valid Count" },

                            ]}
                        >
                            <Input
                                className="box-input"
                                onChange={(e: any) => setBoxDetails({ ...boxdetails, [`damagedBox${index}`]: e.target.value })}
                            />
                        </Form.Item>
                    </div>
                </Col>
                <Col span={5} className='d-flex'>
                    <div className='d-flex align-c jc-c'>
                        <AppButtons
                            disabled={!boxdetails[`totalBox${index}`]}
                            onClick={() => sendToSickering(data, index)}
                            buttonType={AppConstants.buttonTypes.solidLinearGreen}
                            text="Unloading Complete"
                        />
                    </div>
                    {(index != uploadIndex?.index) && <div className='upload-icon' onClick={() => setUploadIndex({ index: index, data: data, gateIn: gateIn })}>
                        <CloudUploadOutlined />
                    </div>}
                </Col>
                {(index == uploadIndex?.index) && <Col span={24}>
                    {uploadContainerView()}
                </Col>}
            </Row>
        )
    }

    const invoiceHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={4}>
                    {AppConstants.invoiceNum}
                </Col>
                <Col span={4}>
                    {AppConstants.executive}
                </Col>
                <Col span={3} className='d-flex align-c jc-c'>
                    {AppConstants.labours}
                </Col>
                <Col span={4}>
                    {AppConstants.totalBoxes}
                </Col>
                <Col span={4}>
                    {AppConstants.damagedBox}
                </Col>
                <Col span={5}>
                    <div className='d-flex align-c jc-c'>
                        {AppConstants.action}
                    </div>
                </Col>
            </Row>
        )
    }

    const listingView = (invoiceList: any, gateIn: any) => {
        try {
            return (
                <div className='table-theme-1' onClick={(e) => e.stopPropagation()}>
                    {invoiceHeaderView()}
                    <Form
                        form={form}
                    //onFinish={() => sendToSickering(data, index)}
                    >
                        {invoiceList?.length != 0 ?
                            invoiceList?.map((item: any, index: any) => invoiceTableBodyView(item, index, gateIn))
                            :
                            <NoData />
                        }
                    </Form>
                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const headerView = () => {
        return (
            <Row className='table-header'>
                <Col span={9}>
                    {AppConstants.gateInId}
                </Col>
                <Col span={5}>
                    {AppConstants.client}
                </Col>
                {/* <Col span={4}>
                    {AppConstants.invoiceNumber}
                </Col> */}
                <Col span={5}>
                    <div className='d-flex align-c jc-c'>
                        {AppConstants.totalSkuQty}
                    </div>
                </Col>
                <Col span={5}>
                    <div className='d-flex align-c jc-c'>
                        {AppConstants.totalUnits}
                    </div>
                </Col>
            </Row>
        )
    }

    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setUploadIndex(-1);
            setExpandRow(data?.GateEntryRecordNo);
            getUnloadingData(data);
            form.resetFields();
            setBoxDetails({
                totalBox: 0,
                damagedBox: 0,
                datas: "",
                count: ""
            })
        }
    }

    useEffect(() => {
        if (openDrawer?.isOPen == true) {
            consignmentApi();
        }
    }, [openDrawer]);

    const consignmentApi = () => {
        let payload = {
            opnfor: "110000",
            activity: "A-02",
            transaction: consignment,
            warehouse: wareHouse_id,
            where: getUserInfo()?.CorporateID
        }
        dispatch(consignmentAction(payload));
    }

    const openConsignment = (id: any) => {
        setConsignment(id);
        setOpenDrawer({ ...openDrawer, isOPen: true });


    }

    const drawerView = () => {
        try {
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                {drawerData?.TxnNo}
                            </div>
                            <Button onClick={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>
                        </div>
                    }
                    onClose={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                    open={openDrawer?.isOPen}>
                    <Row>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                {AppConstants.client}
                            </div>
                            <div className='value-field'>
                                {drawerData?.Customer}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Transaction Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.InwardTxnType}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.Type}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.VehicleNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Seal Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.SealNo}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Docket Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.DocketNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Dock Station
                            </div>
                            <div className='value-field'>
                                {drawerData?.DockStationName}
                            </div>
                        </Col>
                    </Row>
                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }

    }

    const unloadingBodyView = (data: any, index: any) => {
        let open = data?.GateEntryRecordNo == expandRow;
        return (
            <Row className={open ? 'table-body-blue' : 'table-body'} >
                <Col span={9} className={"open-icon gateinid"}>
                    <div onClick={(e: any) => handleExpand(e, index, data)}
                        className='d-flex align-c jc-c'
                    >
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                    </div>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => openConsignment(data?.GateEntryRecordNo)}
                    >
                        {data?.GateEntryRecordNo}
                    </div>
                </Col>
                <Col span={5}>
                    <div className='text'>
                        {data?.name}
                    </div>
                </Col>
                {/* <Col span={4}>
                    {data?.InvoiceNo}
                </Col> */}
                <Col span={5}>
                    <div className={data?.SKUCount == '-' ? 'text d-flex align-c jc-c' : 'text-1 d-flex align-c jc-c'}>
                        {data?.SKUCount}
                    </div>
                </Col>
                <Col span={5}>
                    <div className={data?.SKUSumQTY == '-' ? 'text d-flex align-c jc-c' : 'text-1 d-flex align-c jc-c'}>
                        {data?.SKUSumQTY}
                    </div>
                </Col>
                <Col span={24}>
                    {open && listingView(data?.Lines, data?.GateEntryRecordNo)}
                </Col>
            </Row>
        )
    }

    const unloadingListView = () => {
        return (
            <div className='table-view'>
                {headerView()}
                <div style={{ marginTop: 10 }}>
                    {stageDetails?.lineitems?.length != 0 ?
                        stageDetails?.lineitems?.map((item: any, index: any) => unloadingBodyView(item, index))
                        :
                        <NoData />}
                </div>
            </div>
        )
    }

    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <Input placeholder="Search" className="search-input" prefix={suffix} />

                <div className="help-icon" style={{ marginLeft: 10, cursor: "pointer" }}>
                    <img src={AppImages.helpIcon} alt="help-icon" />
                </div> */}
            </div>
        )
    }

    const continueUnload = () => {
        setShowModal(false);
    }

    const completeUnloading = () => {
        totalCountApi(item, index);
        setShowModal(false);
        setComplete(false);
    }

    const quantityNotMatchModal = () => {
        return (
            <Modal
                open={showModal}
                onOk={() => setShowModal(false)}
                // onCancel={() => setShowModal(false)}
                footer={null}
                className="start-receivingscreen-modal"
                title={'Unloading'}
            >

                <div className='margin redhat-bold font-18'>

                    {
                        Number(boxdetails.count) < Number(boxdetails[`damagedBox${index}`]) ?
                            'The damaged box quantity should not exceed  than the unloaded box quantity' :
                            (Number(boxdetails?.datas?.InvoiceBoxQty) != Number(boxdetails.count)) ?
                                ((Number(boxdetails?.datas?.InvoiceBoxQty) < Number(boxdetails[`damagedBox${index}`])) ?
                                    'The Invoice box quantity and damaged box quantity is not matching with the total unloaded box quantity'
                                    : 'The Invoice box quantity is not matching with the total unloaded box quantity.')
                                : ((Number(boxdetails?.datas?.InvoiceBoxQty) < Number(boxdetails[`damagedBox${index}`])) ?
                                    'The damaged box quantity should not exceed  than the total unloaded box quantity'
                                    :
                                    'The Invoice box quantity is not matching with the total unloaded box quantity.')
                    }
                </div>
                <div className='d-flex flex-end' style={{ marginTop: 10 }}>
                    {Number(boxdetails.count) < Number(boxdetails[`damagedBox${index}`]) ?
                        <>
                            <AppButtons
                                buttonType={AppConstants.buttonTypes.whiteButton}
                                onClick={() => continueUnload()}
                                text="Continue Unloading"
                            />
                        </>
                        :
                        <>
                            <AppButtons
                                buttonType={AppConstants.buttonTypes.whiteButton}
                                onClick={() => continueUnload()}
                                text="Continue Unloading"
                            />
                            <AppButtons
                                buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                onClick={() => completeUnloading()}
                                text="Complete unloading"
                            />
                        </>
                    }
                </div>
            </Modal>
        )
    }


    const contentView = () => {
        return (
            <>
                {unloadingListView()}
                {/* {listingView()} */}
                <SpinLoader loading={load} />
                <ImgModelView
                    open={openModal?.isOpen}
                    index={openModal?.index}
                    // srcUrl={openModal?.url}
                    urlArray={openModal?.urlArray}
                    close={() => setOpenModal({ isOpen: false, urlArray: [] })}
                />
                <div>
                    {drawerView()}
                    {quantityNotMatchModal()}
                </div>
            </>
        )
    }

    return (
        <>
            <SubHeader
                title={"Unloading"}
                paginationView="true"
                inputView="true"
                btnView="false">
                {childrenView()}
            </SubHeader>
            {contentView()}
        </>
    )
}

export default UnloadingLists;