import { CaretRightOutlined, CloseOutlined } from '@ant-design/icons';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Button, Col, Divider, Drawer, Form, Input, Layout, message, Modal, Radio, Row, Select, Table, Upload } from 'antd';
import { stringify } from 'querystring';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import { AppModuleIds } from '../../Globals/AppReferenceIds';
import AppReferences from '../../Globals/AppReferences';
import { isArrayNotEmpty } from '../../Globals/Helper';
import { ConfigureData, configureWarehouseAction, getClientDedicatedLocations, getDetailedDataAction, uploadFileAction, viewConfigurationModalDataAction, viewStorageDetailsAction } from '../../Store/Actions/ProcessWMSAction';
import { getUserInfo, getWareHouseId, setDashboardMenuId } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import SpinLoader from '../SpinLoader';

const title: any = {
    title: "Warehouse Master and Configuration"
}

function ConfigureWarehouse(props: any) {
    const dispatch = useDispatch();
    const [form]: any = Form.useForm();
    const location = useLocation()
    const { item } = location.state;
    const [openDrawer, setOpenDrawer] = useState<any>(false);
    const [fileDetails, setFileDetails] = useState<any>();
    const [expandRow, setExpandRow] = useState<any>(-1);
    const [innerExpandRow, setInnerExpandRow] = useState<any>(-1);
    const [addClientModal, setAddClientModal] = useState<any>(false);
    const [onLoad, setOnLoad] = useState<any>({
        getOnload: false,
        configureOnload: false,
        uploadOnload: false
    })

    const [fieldArray, setFieldArray] = useState<any>({
        smallBinQty: 0,
        mediumBinQty: 0,
        largeBinQty: 0
    })

    const [bin, setBin] = useState<any>(
        {
            smallBin: false,
            mediumBin: false,
            largeBin: false
        }
    )
    const [formValues, setFormValues] = useState<any>({ BinCount: "", DockStationCount: "", InwardStagingAreaCount: "", KittingTableCount: "", OutwardStagingAreaCount: "", PackingStationCount: "", PalleteCount: "", RTOAndReturn: "" });
    const reducerState = useSelector((state: any) => state.ProcessReducerState);
    const {
        configureData,
        configureOnLoad,
        viewConfigureModalData,
        warehouseConfigure,
        uploadFile,
        storageDetails,
        expandDetailedData,
        dedicatedLocation,
    } = reducerState;
    const [RTO, setRTO] = useState<any>("N");
    const [viewConfigure, setViewConfigure] = useState<any>(false);


    useEffect(() => {
        if (onLoad?.getOnload && !configureOnLoad) {
            form.setFieldsValue({
                ['BinCount']: configureData?.lineitems[0].BinCount,
                ['DockStationCount']: configureData?.lineitems[0].DockStationCount,
                ['InwardStagingAreaCount']: configureData?.lineitems[0].InwardStagingAreaCount,
                ['KittingTableCount']: configureData?.lineitems[0].KittingTableCount,
                ['OutwardStagingAreaCount']: configureData?.lineitems[0].OutwardStagingAreaCount,
                ['PackingStationCount']: configureData?.lineitems[0].PackingStationCount,
                ['PalleteCount']: configureData?.lineitems[0].PalleteCount,
                ['rtoReturn']: configureData?.lineitems[0].RTOAndReturn,
                ['largeBinInput']: configureData?.lineitems[0]?.BinDetails?.large,
                ['smallBinInput']: configureData?.lineitems[0]?.BinDetails?.small,
                ['mediumBinInput']: configureData?.lineitems[0]?.BinDetails?.medium
            });
            setRTO(configureData?.lineitems[0].RTOAndReturn)
            setFieldArray(
                {
                    ...fieldArray,
                    smallBinQty: configureData?.lineitems[0]?.BinDetails?.small,
                    mediumBinQty: configureData?.lineitems[0]?.BinDetails?.medium,
                    largeBinQty: configureData?.lineitems[0]?.BinDetails?.large
                }
            )
            setFormValues({
                ...formValues,
                RTOAndReturn: configureData?.lineitems[0].RTOAndReturn,
                PalleteCount: configureData?.lineitems[0].PalleteCount,
                PackingStationCount: configureData?.lineitems[0].PackingStationCount,
                OutwardStagingAreaCount: configureData?.lineitems[0].OutwardStagingAreaCount,
                KittingTableCount: configureData?.lineitems[0].KittingTableCount,
                InwardStagingAreaCount: configureData?.lineitems[0].InwardStagingAreaCount,
                DockStationCount: configureData?.lineitems[0].DockStationCount,
                BinCount: configureData?.lineitems[0].BinCount,
            })
            setOnLoad({ ...onLoad, getOnload: false })
        }

    }, [onLoad?.getOnload, configureOnLoad]);

    useEffect(() => {
        if (onLoad?.configureOnload && !reducerState?.onload) {
            setOnLoad({ ...onLoad, configureOnload: false });
            getwareHouseDetails();
            if (warehouseConfigure?.msg == "Warehouse Configured Successfully") {
                message.success(warehouseConfigure?.msg);
                setBin({ ...bin, smallBin: false, mediumBin: false, largeBin: false })
            }
            else {
                message.error(warehouseConfigure?.msg)
            }
        }
    }, [onLoad?.configureOnload, reducerState?.onload]);

    const onChange = (e: any) => {
        setRTO(e?.target?.value)
        setFormValues({
            ...formValues,
            RTOAndReturn: e?.target?.value
        })
    }

    useEffect(() => {
        getwareHouseDetails()
    }, []);

    const getwareHouseDetails = () => {
        let payload = {
            opnfor: AppReferences.opnfor.warehouseMaster,
            activity: AppReferences.activities.a02,
            transaction: item?.WarehouseId,
            user: getUserInfo()?.UserName,
            where: getUserInfo()?.CorporateID
        }
        dispatch(ConfigureData(payload))
        setOnLoad({ getOnload: true })
    }

    useEffect(() => {
        if (onLoad.uploadOnload && !reducerState.uploadOnload) {
            setOnLoad({ ...onLoad, uploadOnload: false });
            getwareHouseDetails();
            setFileDetails(null);
            if (uploadFile?.msg == 'Warehouse Location Created Successfully') {
                message.success(uploadFile?.msg);
                openViewConfigureModal();
                setOpenDrawer(false);
            }
            else {
                message.error(uploadFile?.msg)
            }
        }
    }, [onLoad.uploadOnload, reducerState.uploadOnload]);

    useEffect(() => {
        if (onLoad.uploadDecatedLocationOnload && !reducerState.uploadOnload) {
            setOnLoad({ ...onLoad, uploadDecatedLocationOnload: false });
            setFileDetails(null)
            if (uploadFile?.msg == 'Warehouse Location Assoicated Successfully for the Client') {
                message.success(uploadFile?.msg);
                getClientLocations();
            }
            else {
                message.error(uploadFile?.msg);
            }
        }
    }, [onLoad.uploadDecatedLocationOnload, reducerState.uploadOnload])

    const configure = () => {
        
        let payload = {
            opnfor: AppReferences.opnfor.warehouseMaster,
            activity: AppReferences.activities.a05,
            transaction: `${formValues?.InwardStagingAreaCount}|${formValues?.DockStationCount}|${formValues?.PalleteCount}|${formValues?.PackingStationCount}|${formValues?.KittingTableCount}|${formValues?.OutwardStagingAreaCount}`,
            user: getUserInfo()?.UserName,
            warehouse: item?.WarehouseId,
            where: `${formValues?.RTOAndReturn}|${getUserInfo()?.CorporateID}`,
            fieldarray: `${fieldArray?.smallBinQty}|${fieldArray?.mediumBinQty}|${fieldArray?.largeBinQty}`
        }
        dispatch(configureWarehouseAction(payload));
        setOnLoad({ ...onLoad, configureOnload: true })
    }

    const uploadFileApi = () => {
        let payload = {
            opnfor: AppReferences.opnfor.warehouseMaster,
            activity: AppReferences.activities.a06,
            transaction: fileDetails?.file,
            user: getUserInfo()?.UserName,
            warehouse: item?.WarehouseId,
        }
        dispatch(uploadFileAction(payload));
        setOnLoad({ ...onLoad, uploadOnload: true });
    }

    const getStorageDetails = (data: any, activity: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.warehouseMaster,
            activity: activity,
            transaction: data?.SystemLocationTypeID,
            user: getUserInfo()?.UserName,
            where: data?.LocationTypeId,
            warehouse: item?.WarehouseId,
        }
        dispatch(viewStorageDetailsAction(payload))
    }

    const getLocationDetails = (data: any, activity: any) => {
        let payload = {
            opnfor: AppReferences.opnfor.warehouseMaster,
            activity: activity,
            transaction: data?.StructureTypeID,
            user: getUserInfo()?.UserName,
            where: data?.LocationTypeIdRecNo,
            warehouse: item?.WarehouseId,
        }
        dispatch(getDetailedDataAction(payload))
    }

    const getClientLocations = () => {
        let payload = {
            opnfor: AppReferences.opnfor.warehouseMaster,
            activity: AppReferences.activities.a04,
            transaction: item?.WarehouseId,
            user: getUserInfo()?.UserName,
            fieldarray: getUserInfo()?.CorporateID
        }
        dispatch(getClientDedicatedLocations(payload))
    }

    const uploadClientDecatedLocation = () => {
        let payload = {
            opnfor: AppReferences.opnfor.warehouseMaster,
            activity: AppReferences.activities.a11,
            transaction: fileDetails?.file,
            user: getUserInfo()?.UserName,
            warehouse: item?.WarehouseId,
            customer: fileDetails?.client
        }
        dispatch(uploadFileAction(payload));
        setOnLoad({ ...onLoad, uploadDecatedLocationOnload: true });
    }

    const validateForm = () => {
        form.validateFields([`InwardStagingAreaCount`, `DockStationCount`,
            'PalleteCount',
            'PackingStationCount', 'KittingTableCount',
            'OutwardStagingAreaCount', 'rtoReturn'
        ], (err: any, values: any) => {
            console.log("err", err)
        }).then(() => {
            configure();
        }).catch(() => {
        })
    }

    const columns: any = [
        {
            title: "Location ID",
            dataIndex: 'LocationId',
            width: "30%",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Sequence Number",
            dataIndex: 'SequenceNo',
            align: "center",
            width: "45%",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: viewConfigure ? " General" : "Dedicated",
            dataIndex: 'Typ',
            with: "25%",
            render: (item: any) => {
                return (
                    <div className='text'>
                        {item}
                    </div>
                )
            }
        },

    ];

    const header = () => {
        return (
            <div>
                <SubHeader
                    title={<div className='d-flex align-c js-c'>
                        <div>
                            Warehouse:
                        </div>
                        <div className='ml-5'>
                            {configureData?.lineitems[0].WarehouseDetails}
                        </div>
                    </div>}
                    paginationView="false"
                    inputView="true"
                    btnView="false"
                >
                    <div className='d-flex align-c flex-end'>
                        <AppButtons
                            onClick={() => openViewConfigureModal()}
                            buttonType={AppConstants.buttonTypes.whiteButton}
                            text="View Configuration" />
                        <AppButtons
                            onClick={() => openAddClientModal()}
                            buttonType={AppConstants.buttonTypes.whiteButton}
                            text="Change/Add Client Dedicated Location" />
                        <AppButtons
                            onClick={() => openFieldDrawer()}
                            buttonType={AppConstants.buttonTypes.whiteButton}
                            text="Change/Add Locations" />
                        <AppButtons
                            text="Save"
                            buttonType={AppConstants.buttonTypes.solidLinearGreen3}
                            onClick={validateForm}
                        />
                        <Link to="/warehouse-master">
                            <Button className="close-btn">
                                <CloseOutlined />
                            </Button>
                        </Link>
                    </div>
                </SubHeader>
            </div>
        )
    }

    const setSmallBinValue = () => {
        setBin({ ...bin, smallBin: !bin?.smallBin, mediumBin: bin?.mediumBin, largeBin: bin?.largeBin})
    }

    const setMediumBinValue = () => {
        setBin({ ...bin, smallBin: bin?.smallBin, mediumBin: !bin?.mediumBin, largeBin: bin?.largeBin})
    }

    const setlargeBinValue = () => {
        setBin({ ...bin, smallBin: bin?.smallBin, mediumBin: bin?.mediumBin, largeBin: !bin?.largeBin})
    }

    const formContent = () => {
        try {
            return (
                <div className='pl-25 pr-25'>
                    <Form
                        id='form'
                        form={form}
                        autoComplete="off"
                        onFinish={configure}
                    >
                        <Row gutter={30}>
                            <Col span={6}>
                                <div className='title-bold'>Number of Inward Staging Area</div>
                                <Form.Item
                                    name="InwardStagingAreaCount"
                                    rules={
                                        [
                                            { pattern: /^[0-9]*$/, message: "Enter valid Count" },
                                            { pattern: /^\S*$/, message: "Space not allowed" }
                                        ]
                                    }
                                >
                                    <Input
                                        onChange={(e: any) => setFormValues({
                                            ...formValues,
                                            InwardStagingAreaCount: e?.target?.value
                                        })}
                                        className='input-field' />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <div className='title-bold'>Number Of Docking Stations</div>
                                <Form.Item
                                    name="DockStationCount"
                                    rules={
                                        [
                                            { pattern: /^[0-9]*$/, message: "Enter valid Count" },
                                            { pattern: /^\S*$/, message: "Space not allowed" }
                                        ]
                                    }
                                >
                                    <Input
                                        onChange={(e: any) => setFormValues({
                                            ...formValues,
                                            DockStationCount: e?.target?.value
                                        })}
                                        className='input-field' />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Row gutter={16}>
                                    <Col span={15}>
                                        <div className='title-bold'>Number Of Pallets</div>
                                        <Form.Item
                                            name="PalleteCount"
                                            rules={
                                                [
                                                    { pattern: /^[0-9]*$/, message: "Enter valid Count" },
                                                    { pattern: /^\S*$/, message: "Space not allowed" }
                                                ]
                                            }
                                        >
                                            <Input
                                                onChange={(e: any) => setFormValues({
                                                    ...formValues,
                                                    PalleteCount: e?.target?.value
                                                })}
                                                className='input-field' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={9} style={{ marginTop: 30 }}>
                                        <AppButtons
                                            buttonType={AppConstants.buttonTypes.borderlessWhiteButton}
                                            text="Print Sticker" />
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={6}>
                                <div className='title-bold'>Number Of Packing Stations</div>
                                <Form.Item
                                    name="PackingStationCount"
                                    rules={
                                        [
                                            { pattern: /^[0-9]*$/, message: "Enter valid Count" },
                                            { pattern: /^\S*$/, message: "Space not allowed" }
                                        ]
                                    }
                                >
                                    <Input
                                        onChange={(e: any) => setFormValues({
                                            ...formValues,
                                            PackingStationCount: e?.target?.value
                                        })}
                                        className='input-field' />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={30} className='mt-25'>

                            <Col span={6}>
                                <div className='title-bold'>Number Of Kitting Tables</div>
                                <Form.Item
                                    name="KittingTableCount"
                                    rules={
                                        [
                                            { pattern: /^[0-9]*$/, message: "Enter valid Count" },
                                            { pattern: /^\S*$/, message: "Space not allowed" }
                                        ]
                                    }
                                >
                                    <Input
                                        onChange={(e: any) => setFormValues({
                                            ...formValues,
                                            KittingTableCount: e?.target?.value
                                        })}
                                        className='input-field' />
                                </Form.Item>
                            </Col>
                            <Col span={6}>

                                <div className='title-bold'>RTO Return Location Available?</div>
                                <Form.Item
                                    name='rtoReturn'
                                >
                                    <Radio.Group onChange={onChange} value={RTO}>
                                        <Radio value={"Y"}>Yes</Radio>
                                        <Radio value={"N"}>No</Radio>
                                    </Radio.Group>

                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <div className='title-bold'>Number Of Outward Staging Area</div>
                                <Form.Item
                                    name="OutwardStagingAreaCount"
                                    rules={
                                        [
                                            { pattern: /^[0-9]*$/, message: "Enter valid Count" },
                                            { pattern: /^\S*$/, message: "Space not allowed" }
                                        ]
                                    }
                                >
                                    <Input
                                        onChange={(e: any) => setFormValues({
                                            ...formValues,
                                            OutwardStagingAreaCount: e?.target?.value
                                        })}
                                        className='input-field' />
                                </Form.Item>
                            </Col>


                        </Row>
                        <hr style={{ marginTop: 20 }} />
                        <div style={{ color: "#545454", fontFamily: "redHat-bold", fontSize: "16px" }}>{AppConstants?.binSelection}</div>
                        <div className='d-flex mt-10 w-500'>
                            <div onClick={() => setSmallBinValue()} style={{ cursor: "pointer" }} className={bin?.smallBin === false ? 'bin-btn' : 'btn-hover-btn'} >Small Bin</div>
                            <Form.Item
                                name="smallBinInput"
                                rules={
                                    [
                                        { pattern: /^[0-9]*$/, message: "Enter valid Count" },
                                        { pattern: /^\S*$/, message: "Space not allowed" }
                                    ]
                                }
                            >
                                <Input
                                    disabled={!(bin?.smallBin)}
                                    className='input-field'
                                    placeholder='Enter Qty'
                                    onChange={(e: any) => setFieldArray({ ...fieldArray, smallBinQty: e?.target?.value, mediumBinQty: fieldArray?.mediumBinQty, largeBinQty: fieldArray?.largeBinQty })}
                                />
                            </Form.Item>

                            <AppButtons
                                disabled={!(bin?.smallBin)}
                                buttonType={AppConstants.buttonTypes.borderlessWhiteButton}
                                text="Print Sticker" />
                        </div>
                        <div className='d-flex mt-10 w-500'>
                            <div onClick={() => setMediumBinValue()} style={{ cursor: "pointer" }} className={bin?.mediumBin === false ? 'bin-btn' : 'btn-hover-btn'}>Medium Bin</div>
                            <Form.Item
                                name="mediumBinInput"
                                rules={
                                    [
                                        { pattern: /^[0-9]*$/, message: "Enter valid Count" },
                                        { pattern: /^\S*$/, message: "Space not allowed" }
                                    ]
                                }
                            >
                                <Input
                                    disabled={!(bin?.mediumBin)} 
                                    className='input-field'
                                    placeholder='Enter Qty'
                                    onChange={(e: any) => setFieldArray({ ...fieldArray, smallBinQty: fieldArray?.smallBinQty, mediumBinQty: e?.target?.value, largeBinQty: fieldArray?.largeBinQty })} />
                            </Form.Item>

                            <AppButtons
                                disabled={!(bin?.mediumBin)}
                                buttonType={AppConstants.buttonTypes.borderlessWhiteButton}
                                text="Print Sticker" />
                        </div>
                        <div className='d-flex mt-10 w-500'>
                            <div onClick={() => setlargeBinValue()} style={{ cursor: "pointer" }} className={bin?.largeBin === false ? 'bin-btn' : 'btn-hover-btn'}>Large Bin</div>
                            <Form.Item
                                name="largeBinInput"
                                rules={
                                    [
                                        { pattern: /^[0-9]*$/, message: "Enter valid Count" },
                                        { pattern: /^\S*$/, message: "Space not allowed" }
                                    ]
                                }
                            >
                                <Input
                                    disabled={!(bin?.largeBin)}
                                    className='input-field'
                                    placeholder='Enter Qty'
                                    onChange={(e: any) => setFieldArray({ ...fieldArray, smallBinQty: fieldArray?.smallBinQty, mediumBinQty: fieldArray?.mediumBinQty, largeBinQty: e?.target?.value })} />
                            </Form.Item>

                            <AppButtons
                                disabled={!(bin?.largeBin)}
                                buttonType={AppConstants.buttonTypes.borderlessWhiteButton}
                                text="Print Sticker" />
                        </div>
                        <div></div>
                    </Form>
                </div>
            )
        } catch (error) {
            console.log("Error in formContent::", error);

        }
    }

    const openFieldDrawer = () => {
        setOpenDrawer(true)
    }
    const closeFieldDrawer = () => {
        setOpenDrawer(false)
        setFileDetails(null);
        setExpandRow(-1);
        setInnerExpandRow(-1);
    }
    const manageFile = (item: any) => {
        let fileType = item?.file?.type?.split('/');
        if (fileType[1] == 'csv') {
            const reader = new FileReader();
            reader.onload = function (event: any) {
                let result = event?.target?.result?.split(',');
                setFileDetails({ ...fileDetails, file: result[1], name: item.file?.originFileObj?.name }); // the CSV content as string
            };
            reader.readAsDataURL(item?.file?.originFileObj);
        }
        else {
            message.error(`File type ${fileType[1]} is not supported.`)
        }
    }

    const drawerView = () => {
        try {
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                {'Change/Add Locations'}
                            </div>
                            {/* <Button
                                onClick={() => closeFieldDrawer()}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button> */}
                            <img onClick={() => closeFieldDrawer()} className="c-p" src={AppImages.closeimg} alt='' />
                        </div>
                    }
                    open={openDrawer}>
                    <div className='pt-10'>
                        <a href={process.env.REACT_APP_GENERAL_TEMPLATE} download target={'_blank'}>
                            <div className='underline font-16-semi-bold c-p app-blue' >
                                Click here to download template
                            </div>
                        </a>
                        <div className=' mt-10 d-flex jc-sb'>
                            <div className='title-bold'>
                                Attachments
                            </div>
                            <div>
                                <Upload
                                    accept='.csv'
                                    maxCount={1}
                                    onChange={(e: any) => manageFile(e)}
                                >
                                    <Button className='cancel-btn-d'
                                    >
                                        <img src={AppImages.uploadIcon} alt='' />
                                        <span className='ml-5'>Upload file</span>
                                    </Button>
                                </Upload>
                            </div>
                        </div>
                        {fileDetails?.name &&
                            <div className='filefiled-2 mt-10 overflow-ellipse'
                                title={fileDetails?.name}
                            >
                                {fileDetails?.name}
                            </div>}
                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 30, marginRight: 10 }}>
                            <Button className='cancel-btn'
                                onClick={() => closeFieldDrawer()}

                            >
                                Cancel
                            </Button>
                            <AppButtons
                                onClick={uploadFileApi}
                                block={true}
                                disabled={!fileDetails?.file}
                                buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                text={"Update"}
                            >
                            </AppButtons>
                        </div>
                    </div>
                </Drawer >
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }
    }

    const openAddClientModal = () => {
        setAddClientModal(true);
        getClientLocations();
    }

    const closeAddCliendModal = () => {
        setAddClientModal(false);
        setFileDetails(null);
        setExpandRow(-1);
        setInnerExpandRow(-1);
    }

    const addClienModalView = () => {
        try {
            return (
                <Modal
                    title={<div className='d-flex align-c jc-sb'>
                        <div className='redhat-bold font-18'>
                            Change/Add Client Dedicated Location
                        </div>
                        <Button
                            onClick={() => closeAddCliendModal()}
                            className="round-cross-btn">
                            <CloseOutlined />
                        </Button>
                    </div>}
                    style={{ top: 5 }}
                    className='modal-view-2'
                    open={addClientModal}
                    footer={null}
                    closable={false}
                >
                    <>
                        <Row gutter={30}>
                            <Col span={7}>
                                <div className='title-bold'>Select Client</div>
                                <Select className='w-100'
                                    value={fileDetails?.client}
                                    onChange={(e) => setFileDetails({ ...fileDetails, client: e })}>
                                    {dedicatedLocation?.ClientList?.map((item: any) =>
                                        <Select.Option value={item?.Id}>
                                            {item?.Name}
                                        </Select.Option>
                                    )}
                                </Select>
                            </Col>
                            <Col span={11}>
                                <div className='title-bold'>
                                    Attachments
                                </div>
                                <div className='d-flex jc-sb'>
                                    {fileDetails?.name &&
                                        <div className='filefiled-2 overflow-ellipse-1'
                                            title={fileDetails?.name}
                                        >
                                            {fileDetails?.name}
                                        </div>}
                                    <div>
                                        <Upload
                                            accept='.csv'
                                            maxCount={1}
                                            onChange={(e: any) => manageFile(e)}
                                        >
                                            <Button className='cancel-btn-d'
                                            >
                                                <img src={AppImages.uploadIcon} alt='' />
                                                <span className='ml-5'>Upload file</span>
                                            </Button>
                                        </Upload>
                                    </div>
                                </div>
                                <a href={process.env.REACT_APP_CLIENT_TEMPLATE} download>
                                    <div className='mt-10 underline font-16-semi-bold c-p app-blue' >
                                        Click here to download template
                                    </div>
                                </a>
                            </Col>
                            <Col span={6} className='d-flex flex-end'>
                                <div className='mr-10 d-flex align-c'>
                                    <Button className='cancel-btn'
                                        onClick={closeAddCliendModal}
                                    >
                                        Cancel
                                    </Button>
                                    <AppButtons
                                        disabled={!fileDetails?.name || !fileDetails?.client}
                                        onClick={uploadClientDecatedLocation}
                                        block={true}
                                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                                        text={"Update"}
                                    >
                                    </AppButtons>
                                </div>
                            </Col>
                        </Row>
                        <Divider />
                        <div className='title-bold'>Warehouse Configuration is as followings</div>
                        <div className='scroll-cw'>
                            {isArrayNotEmpty(dedicatedLocation?.lineitems) ?
                                <>
                                    {(dedicatedLocation?.lineitems || []).map((item: any, index: any) => {
                                        return (
                                            <>
                                                {outerExpanderView(item, index)}
                                            </>
                                        )
                                    })}
                                </>
                                :
                                <NoData height={'50vh'} />
                            }
                        </div>
                    </>
                </Modal>
            )
        } catch (error) {
            console.log("Error in addClientModal", error);

        }
    }

    const viewConfigurationModalData = () => {
        let payload = {
            opnfor: AppReferences.opnfor.warehouseMaster,
            activity: AppReferences.activities.a03,
            transaction: item?.WarehouseId,
            user: getUserInfo()?.UserName
        }
        dispatch(viewConfigurationModalDataAction(payload))
    }

    const openViewConfigureModal = () => {
        viewConfigurationModalData()
        setViewConfigure(true)
    }

    const closeViewConfigureModal = () => {
        setViewConfigure(false);
        setExpandRow(-1);
        setInnerExpandRow(-1);
    }

    const viewConfiguration = () => {
        try {
            return (
                <Modal
                    title={<div className='d-flex align-c jc-sb'>
                        <div className='redhat-bold font-18'>
                            View Configuration
                        </div>
                        <Button
                            onClick={() => closeViewConfigureModal()}
                            className="round-cross-btn">
                            <CloseOutlined />
                        </Button>
                    </div>}
                    style={{ top: 5 }}
                    className='modal-view-2'
                    open={viewConfigure}
                    footer={null}
                    closable={false}

                >
                    <div className='title-bold'>Warehouse Configuration is as followings</div>
                    <div className='scroll-cw-2'>
                        {isArrayNotEmpty(viewConfigureModalData?.lineitems) ?
                            <>
                                {(viewConfigureModalData?.lineitems || []).map((item: any, index: any) => {
                                    return (
                                        <>
                                            {outerExpanderView(item, index)}
                                        </>
                                    )
                                })}
                            </>
                            :
                            <NoData />
                        }
                    </div>

                </Modal>
            )
        } catch (error) {
            console.log("Error in viewConfiguration::", error);

        }

    }

    const handleExpand = (data: any, index: any) => {
        setInnerExpandRow(-1);
        setExpandRow(expandRow == index ? null : index);
        if (addClientModal) {
            getStorageDetails(data, AppReferences.activities.a09);
        } else {
            getStorageDetails(data, AppReferences.activities.a07);
        }
    }

    const outerExpanderView = (data: any, index: any) => {
        try {
            let isExpandopen = (expandRow == index);
            return (
                <div className="content-container-gray" style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={24}>
                            <div className="title-container">
                                <div style={{ display: "flex" }}>
                                    <CaretRightOutlined
                                        onClick={() => handleExpand(data, index)}
                                        className={index === expandRow ? "arrow arrow-down" : "arrow arrow-right"} />

                                    <div className='text'>
                                        {data?.SystemType + " - " + data?.Type}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {isExpandopen &&
                        (storageDetails?.LocationHeader || []).map((item: any, index2: any) => {
                            return (
                                <div className='mt-10'>
                                    {innerExpanderView(item, index2)}
                                </div>
                            )
                        })
                    }
                </div>
            )
        } catch (error) {
            console.log("Error in pendingSansView::", error)
        }
    }

    const handleInnnerExpand = (data: any, index: any) => {
        setInnerExpandRow(innerExpandRow == index ? null : index);
        if (addClientModal) {
            getLocationDetails(data, AppReferences.activities.a10);
        }
        else {
            getLocationDetails(data, AppReferences.activities.a08);
        }
    }

    const innerExpanderView = (data: any, index: any) => {
        try {
            let isExpandopen = (innerExpandRow == index);
            return (
                <div className="content-container1">
                    <Row>
                        <Col span={24}>
                            <div className="title-container">
                                <div style={{ display: "flex" }}>
                                    <CaretRightOutlined
                                        onClick={() => handleInnnerExpand(data, index)}
                                        className={index === innerExpandRow ? "arrow arrow-down" : "arrow arrow-right"} />

                                    <div
                                        className='text'
                                    >
                                        {data?.StructureType}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {isExpandopen &&
                        <div>{listingView(expandDetailedData?.LocationLines)}</div>
                    }
                </div>
            )
        } catch (error) {
            console.log("Error in pendingSansView::", error)
        }
    }

    const listingView = (data: any) => {
        try {
            return (
                <div className='table-screen-container-bg-none'>
                    < Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                    />
                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    return (
        <Layout>
            <NavbarLayout
                titleContent="sub-head"
                process={title}
                initial={AppModuleIds.main}
                selectedMain={'8'}
            />
            <Layout>
                {header()}
                <div className='pt-15 bg-white'>
                    {formContent()}
                </div>
            </Layout>
            {drawerView()}
            {addClienModalView()}
            {viewConfiguration()}
            <SpinLoader loading={onLoad?.configureOnload} />
        </Layout>
    );
}

export default ConfigureWarehouse;