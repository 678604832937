import React, { useEffect, useState } from 'react';
import InvoiceDetailsListingScreen from './InvoiceDetails/InvoiceDetailsListingScreen';
import PendingSANView from './PendingSANView';
import UnloadingLists from '../SAN/Unloading/UnloadingListing';
import StickeringListing from '../SAN/Stickering/StickeringListing';
import SendSanListing from '../SAN/SendSan/SendSanListing';
import { useOutletContext } from 'react-router-dom';
import CaptureSkuDetails from './InvoiceDetails/CaptureSkuDetails';

function SanIndex(props: any) {
    const [value, individualStageData, getStatges,getIndividualStageDetails, processData ]: any = useOutletContext();
    return (
        <>
            {(value == 120001) && <PendingSANView 
                stageDetails = {individualStageData} 
            />}
            {(value == 120100) && <InvoiceDetailsListingScreen />}
            {(value == 120130) && <CaptureSkuDetails
             stageDetails = {individualStageData} 
             />}
            {(value == 120150) && <UnloadingLists 
               getStatges={getStatges}
               stageDetails = {individualStageData} 
               />}
            {(value == 120200) && <StickeringListing 
               stageDetails = {individualStageData} 
            />}
            {(value == 120300) && <SendSanListing 
              processData={processData}
              getStatges={getStatges}
              getIndividualStageDetails={getIndividualStageDetails}
              stageDetails = {individualStageData} 
            />}
        </>   
    );
}

export default SanIndex;