import React from 'react';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';

let user = getUserInfo();
function formatData(tbl_data_mdm: any) {
    var jObj = tbl_data_mdm;
    var tbl_col = 0;
    var fName: any = [];
    var fVal: any = [];

    for (let i = 0; i < jObj.lineMeta.length; i++) {
        var mR = jObj.lineMeta[i].mR;
        var mRVal = mR.split("|");
        var cvisible = mRVal[11]; // N / Y / N / Y / Y / N
        var canBenull = mRVal[3]; // N / N / N / N / N / N
        var cKey = mRVal[6];      // UK / UK / UK / UK / UK /
        var field_visible = true;

        if (mRVal[6] == 'UK') {
            cVal = '{UUID}';
        }

        if (cvisible == 'N') {
            if (canBenull == 'N') {
                fName.push(mRVal[1]);// PoRelHeaderRecNo /  / BoxBarcode /  /   / AttributeID
                fVal.push(mRVal[2]); // 983CFBE-6409-11ED-B1F1-029A4DF7CA8C /  / 83E5EA2E-6409-11ED-B1F1-029A4DF7CA8C /  /  / WH01-CUST02-20221114-161050-783

            }
            field_visible = false;
        }

        if (cKey == 'UK' || cKey == 'LK') {
            field_visible = false;
            if (cvisible == 'Y') {
                field_visible = true;
            }
        }

        if (cvisible == 'N') {
            field_visible = false;
        }

        if (field_visible) {

            var cVal = '';
            var cName = mRVal[1];
            fName.push(cName); //  / PoLineRecNo /  / ConditionType / Quantity /  /

            fVal.push(cVal); //  / ""  /  / ""/ ""/  /            
        }
    }

    fName = JSON.stringify(fName);
    fName = fName.replace(/"/g, '').replace(/[\[\]']+/g, '');
    fVal = JSON.stringify(fVal);
    fVal = fVal.replace(/"/g, "'").replace(/[\[\]]+/g, '');
    fName = fName + ",CreatedBy";
    fVal = fVal + ",'" + user?.UserName + "'";

    return [{ names: fName }, { values: fVal }];
}

export {
    formatData,
};