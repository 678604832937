import React from 'react';

function ChartInventory() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10.9154 9.08398H17.2862C17.057 7.40343 16.3582 5.97862 15.1898 4.80957C14.0207 3.64112 12.5959 2.94232 10.9154 2.71315V9.08398ZM9.08203 17.2882V2.71315C7.23342 2.94232 5.70198 3.74807 4.4877 5.1304C3.27281 6.51335 2.66536 8.13676 2.66536 10.0007C2.66536 11.8645 3.27281 13.4877 4.4877 14.87C5.70198 16.2529 7.23342 17.059 9.08203 17.2882ZM10.9154 17.2882C12.5959 17.0743 14.0244 16.3791 15.2008 15.2027C16.3772 14.0263 17.0723 12.5979 17.2862 10.9173H10.9154V17.2882ZM9.9987 19.1673C8.73064 19.1673 7.53898 18.9265 6.4237 18.445C5.30842 17.964 4.33828 17.3111 3.51328 16.4861C2.68828 15.6611 2.03531 14.6909 1.55436 13.5757C1.07281 12.4604 0.832031 11.2687 0.832031 10.0007C0.832031 8.73259 1.07281 7.54093 1.55436 6.42565C2.03531 5.31037 2.68828 4.34023 3.51328 3.51523C4.33828 2.69023 5.30842 2.03696 6.4237 1.5554C7.53898 1.07446 8.73064 0.833984 9.9987 0.833984C11.2668 0.833984 12.4544 1.07446 13.5618 1.5554C14.6697 2.03696 15.6399 2.69421 16.4722 3.52715C17.3051 4.35948 17.9621 5.32962 18.443 6.43757C18.9246 7.5449 19.1654 8.73259 19.1654 10.0007C19.1654 11.2534 18.9246 12.4375 18.443 13.5527C17.9621 14.668 17.3091 15.6418 16.4841 16.4742C15.6591 17.3071 14.689 17.964 13.5737 18.445C12.4584 18.9265 11.2668 19.1673 9.9987 19.1673Z" fill="var(--app-004986)" />
        </svg>
    );
}

export default ChartInventory;