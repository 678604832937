import React from 'react';

function BoxIcon1() {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.89499 0.0599821C7.65612 0.112187 5.35042 1.10056 5.25618 1.19122C5.23692 1.20969 6.94675 2.03976 9.05579 3.03583L12.8903 4.8468L14.4933 4.13097C15.3749 3.73723 16.1132 3.39819 16.1339 3.3775C16.1835 3.32779 15.7665 2.99938 15.4565 2.84401C15.3212 2.77623 13.9042 2.13682 12.3076 1.42314C10.319 0.534231 9.30767 0.104799 9.09701 0.0599821C8.72136 -0.019994 8.26125 -0.019994 7.89499 0.0599821ZM2.43064 2.43786C1.51628 2.85282 1.07858 3.09587 0.911631 3.28132C0.869429 3.32819 0.85114 3.37652 0.870979 3.38871C1.0238 3.48275 7.81532 6.49389 7.97027 6.53631C8.23442 6.60867 8.8279 6.60151 9.0801 6.52291C9.39982 6.42331 11.1584 5.62649 11.1236 5.59702C11.1064 5.58238 9.70728 4.91774 8.01455 4.12002C6.32182 3.32231 4.61802 2.5181 4.22832 2.33287C3.83863 2.14764 3.4899 1.99783 3.45336 1.99997C3.41683 2.00211 2.95659 2.19918 2.43064 2.43786ZM0.0493008 4.87555C0.0146712 5.04889 -0.000517946 6.18111 1.34543e-05 8.55654C0.000899122 12.4054 -0.00406051 12.3359 0.315355 12.987C0.539474 13.444 1.02469 13.9573 1.43847 14.1752C1.73043 14.329 7.53315 16.9216 7.6935 16.97L7.79313 17V12.484V7.96809L7.49267 7.87636C7.3274 7.82589 5.6585 7.1005 3.78394 6.26434C1.90938 5.42817 0.313495 4.71729 0.237505 4.68458L0.0993412 4.62516L0.0493008 4.87555ZM15.2438 5.36742L13.5943 6.10438V7.31827C13.5943 8.06596 13.5762 8.5803 13.5473 8.65748C13.4756 8.84858 13.2402 9.06857 13.0533 9.11913C12.8513 9.17383 12.5998 9.11664 12.4211 8.97542C12.2023 8.80239 12.1772 8.66829 12.1772 7.67155C12.1772 7.16895 12.1612 6.75777 12.1415 6.75777C12.122 6.75777 11.5988 6.98608 10.9791 7.26517C10.3594 7.54422 9.70786 7.81655 9.53126 7.87031L9.2102 7.96809V12.484V17L9.30984 16.9711C9.46563 16.9258 15.1733 14.376 15.5206 14.1964C15.9784 13.9598 16.445 13.4746 16.6852 12.9857C17.0092 12.3261 17.003 12.4143 16.9991 8.51573C16.9966 6.05262 16.9807 4.98423 16.9445 4.83741L16.8934 4.6305L15.2438 5.36742Z" fill="white"/>
        </svg>
    );
}

export default BoxIcon1;