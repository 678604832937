import { CaretRightOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Input, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { AppImages } from '../../Globals/AppImages';
import AppReferences from '../../Globals/AppReferences';
import { getPendingSanDetailsAction } from '../../Store/Actions/ProcessWMSAction';
import { getWareHouseId } from '../../Utils/SessionStorage';
import SubHeader from '../Header/Header';
import "./SAN.scss"

const suffix = (

    <SearchOutlined
        style={{
            fontSize: 20,
            color: 'black',
        }}
    />
);

function PendingSANView(props: any) {
    const { stageDetails } = props;
    const [expandRow, setExpandRow] = useState(-1);
    const dispatch = useDispatch();
    const reducerState: any = useSelector((state: any) => state.ProcessReducerState);
    const { pendingSanDetails } = reducerState;
    let tableData = pendingSanDetails?.lineitems;
    const [openDrawer, setOpenDrawer] = useState<any>();
    const warehouseId = getWareHouseId();


    const getPendingSanDetails = (data: any) => {
        tableData = [];
        try {
            let details = data?.id?.split("|");
            let payload = {
                "activity": AppReferences.activities.av,
                "warehouse": getWareHouseId(),
                "opnfor": details[1],
                "transaction": details[2],
                "customer": details[0]

            }
            dispatch(getPendingSanDetailsAction(payload))
        } catch (error) {
            console.log("Error in getPendingSanDetails::", error)
        }
    }

    // useEffect(() => {
    //     if (stageDetails) {
    //         getPendingSanDetails(stageDetails?.header[0])
    //     }
    // }, [stageDetails])

    useEffect(() => {
        if (openDrawer?.isOPen) {
            getPendingSanDetails(openDrawer?.data)
        }
    }, [openDrawer?.data])

    const handleExpand = (data: any, index: any) => {
        { expandRow != index && getPendingSanDetails(data) };
        setExpandRow(expandRow == index ? null : index);
    }

    const columns: any = [
        {
            title: "Invoice Number",
            dataIndex: 'InvoiceNo',
            width: 200,
            align: "left",
            render: (item: any) => {
                return (
                    <span title={item} className='text t-ellipsis'> {item}</span>
                )
            }
        },
        {
            title: "Invoice Box",
            dataIndex: 'InvoiceBoxQty',
            width: 300,
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Received Box",
            dataIndex: 'RecievedBoxQty',
            width: 300,
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Invoice Weight (gms)",
            dataIndex: 'INVOICEWEIGHT',
            width: 400,
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Executive",
            dataIndex: 'Name',
            width: 200,
            align: "left",
            render: (item: any) => {
                return (
                    <div className='text'>
                        <span title={item} className='overflow-tagline'> {item}</span>
                    </div>
                )
            }
        },
        {
            title: "Labours",
            dataIndex: 'LaboursAssigned',
            width: 200,
            align: "center",
            render: (item: any) => {
                return (
                    <div className='text-1'>
                        {item}
                    </div>
                )
            }
        },
        {
            title: "Status",
            dataIndex: 'Status',
            width: 200,
            align: "left",
            render: (item: any) => {
                return (
                    <div className={item == "Pending" ? 'process-text' : item == "Unloaded" ? 'complete-text' : "stickering-text"}>
                        <span title={item} className='overflow-tagline'> {item}</span>
                    </div>
                )
            }
        }
    ];

    const listingView = () => {
        try {
            return (

                <div className='pending-san-view-container' style={{ paddingLeft: 10, paddingRight: 10 }}>

                    < Table
                        columns={columns}
                        dataSource={tableData}
                        pagination={false}
                    />
                </div>
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <Input placeholder="Search By GateIn ID" className="search-input" prefix={suffix} />

                <div className="help-icon" style={{ marginLeft: 10, cursor: "pointer" }}>
                    <img src={AppImages.helpIcon} alt="help-icon" />
                </div> */}
            </div>
        )
    }

    const titleContainer = () => {
        try {
            return (
                <Row>
                    <Col span={12}>
                        <div className='text'>GateIn ID</div>
                    </Col>
                    <Col span={12}>
                        <div className='text'> Client</div>
                    </Col>
                </Row>
            )
        } catch (ex) {
            console.log("Error in title container::" + ex)
        }
    }

    const pendingSansView = (data: any, index: any) => {
        try {
            let [, , warehouseId] = (data?.id?.split("|") || []);// to get id from third index 
            let isExpandopen = (expandRow == index);
            return (
                <div className={index === expandRow ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={12}>
                            <div className="title-container">
                                <div style={{ display: "flex" }}>
                                    <CaretRightOutlined
                                        onClick={() => handleExpand(data, index)}
                                        className={index === expandRow ? "arrow arrow-down" : "arrow arrow-right"} />

                                    <div className='mama-container' style={{ width: 150 }}>
                                        <div
                                            onClick={() => setOpenDrawer({ ...openDrawer, isOPen: true, data: data })}
                                            className='title'
                                            title={warehouseId}
                                        >
                                            {warehouseId}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='text'> {data?.name}</div>
                        </Col>
                    </Row>
                    {isExpandopen &&
                        <div>{listingView()}</div>
                    }
                </div>
            )
        } catch (error) {
            console.log("Error in pendingSansView::", error)
        }

    }

    const drawerView = () => {
        try {
            let [, , warehouseId] = (openDrawer?.data?.id?.split("|") || []);//to get id from third index
            let drawerData = pendingSanDetails?.header[0];
            return (
                <Drawer
                    className="drawer-view"
                    placement={"right"}
                    width={400}
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                {warehouseId}
                            </div>
                            <Button onClick={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                                className="cross-btn">
                                <CloseOutlined />
                            </Button>
                        </div>
                    }
                    onClose={() => setOpenDrawer({ ...openDrawer, isOPen: false })}
                    open={openDrawer?.isOPen}>
                    <Row>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                {AppConstants.client}
                            </div>
                            <div className='value-field'>
                                {drawerData?.ClientID}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Transaction Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.InwardTxnTypeID}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Type
                            </div>
                            <div className='value-field'>
                                {drawerData?.VehicleTypeId}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Vehicle Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.VehicleNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Seal Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.SealNo}
                            </div>
                        </Col>
                        <Col span={10} className={"fields"}>
                            <div className='title-field'>
                                Docket Number
                            </div>
                            <div className='value-field'>
                                {drawerData?.DocketNumber}
                            </div>
                        </Col>
                        <Col span={14} className={"fields"}>
                            <div className='title-field'>
                                Dock Station
                            </div>
                            <div className='value-field'>
                                {drawerData?.DockStationID}
                            </div>
                        </Col>
                    </Row>
                </Drawer>
            )
        } catch (error) {
            console.log("Error in drawerView::", error)
        }

    }

    return (
        <div style={{ height: "100%" }}>
            <SubHeader
                title={"Pending SAN"}
                paginationView="true"
                inputView="true"
                btnView="false">
                {childrenView()}
            </SubHeader>
            <div
                style={{ padding: 10 }}>
                <div className='header-part'>
                    {titleContainer()}
                </div>
                <div
                    // style={{ height: 'calc(100% - 107px)', overflow: 'scroll' }}
                    className='expand-collape-container'>
                    {stageDetails?.header != 0 ?
                        (stageDetails?.header || []).map((data: any, index: any) =>
                            pendingSansView(data, index)
                        )
                        :
                        (
                            <NoData className='height-class' />
                        )}
                </div>
                {drawerView()}
            </div>
        </div>
    );
}

export default PendingSANView;