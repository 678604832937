import React from 'react';

function Edit() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.733 0.0646686C18.2882 0.171827 17.7002 0.454688 17.373 0.718992C17.2217 0.841245 16.5966 1.43116 15.984 2.02986L14.8702 3.11845L17.3142 5.45511L19.7582 7.79176L21.0087 6.58929C22.3696 5.28064 22.6175 4.97287 22.8481 4.30531C22.9709 3.94993 22.9924 3.79919 22.9921 3.29328C22.9918 2.78013 22.9715 2.64231 22.8432 2.28579C22.4696 1.24677 21.6439 0.456032 20.5944 0.132282C20.1312 -0.0105957 19.1878 -0.0448677 18.733 0.0646686ZM8.31097 9.38006L3.40729 14.0712L1.7217 17.3278C-0.056547 20.7635 -0.0903876 20.8431 0.0517316 21.2548C0.138171 21.5053 0.393704 21.7791 0.664374 21.9111C0.83536 21.9945 0.950882 22.0119 1.21009 21.9932C1.51152 21.9714 1.81992 21.8359 4.91751 20.3642L8.29616 18.7589L13.1866 14.0776L18.077 9.3963L17.568 8.89064C17.1817 8.50682 13.2279 4.68897 13.2168 4.68897C13.2168 4.68897 11.008 6.79999 8.31097 9.38006ZM10.9684 19.9544C10.8366 20.021 10.6558 20.1745 10.5666 20.2956C10.4202 20.4944 10.4044 20.5546 10.4044 20.9164C10.4044 21.269 10.4221 21.3412 10.5522 21.5193C10.6335 21.6306 10.7898 21.7798 10.8997 21.8509L11.0992 21.9801H16.7022H22.3052L22.5048 21.8509C22.6146 21.7798 22.771 21.6306 22.8522 21.5193C22.982 21.3416 23 21.2686 23 20.9204C23 20.5722 22.982 20.4992 22.8522 20.3216C22.771 20.2103 22.6146 20.061 22.5048 19.9899L22.3052 19.8607L16.7566 19.8471L11.2081 19.8335L10.9684 19.9544Z" fill="white" />
        </svg>
    );
}

export default Edit;