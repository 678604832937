import { Row, Col, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import '../CardContainer.scss';
import SubHeader from '../Header/Header';
import AppButtons from '../../CustomComponent/AppButtons';
import AppConstants from '../../Globals/AppConstants';
import { CaretRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';


function NewManifestScreen(props: any) {
    const [expandRow, setExpandRow] = useState<any>();
    const navigate = useNavigate();
    const b2c = false;
    const columnsTemp: any = [
       
        {
            title: AppConstants.AWBno,
            dataIndex: "awb",
            // align: "center",
            render: (item: any) =>
                <div className='text-1'>
                    {item}
                </div>
    
        },
        {
            title: AppConstants.pendingsince,
            dataIndex: "pending",
            // align: "center",
            render: (item: any) =>
                <div className='text-1'>
                    {item}
                </div>
    
        },
    ];
    const [columns, setColumns] = useState(columnsTemp);

    useEffect(() =>{
        if(b2c){
            let obj = {
                title: AppConstants.channel,
                dataIndex: "channel",
                render: (item: any) =>
                    <div className='text'>
                        {item}
                    </div>
            }
            columnsTemp.splice(2, 0, obj) // to insert channel column at second position
            setColumns(columnsTemp);
        }
    }, []);

    const data = [
        {
            batchId: "WH01-CUST02-22021218-67879",
            courier: "ABT Courier"
        },
        {
            batchId: "WH01-CUST02-22021218-67879",
            courier: "ABT Courier"
        },
        {
            batchId: "WH01-CUST02-22021218-67879",
            courier: "ABT Courier"
        },
        {
            batchId: "WH01-CUST02-22021218-67879",
            courier: "ABT Courier"
        },
        {
            batchId: "WH01-CUST02-22021218-67879",
            courier: "ABT Courier"
        },
        {
            batchId: "WH01-CUST02-22021218-67879",
            courier: "ABT Courier"
        },

    ];

    
    const handleExpand = (e: any, index: any, data: any) => {
        e.stopPropagation();
        if (index == expandRow) {
            setExpandRow(-1)
        }
        else {
            setExpandRow(index);
        }
    }

    const dataSource = [
        {
            customer: "Mamaearth",
            awb: "567587KYGYF76",
            courier: "Blue Dart",
            pending: 40,  
            channel: 'Amazon'
        },
        {
            customer: "Mamaearth",
            awb: "567587KYGYF76",
            courier: "Blue Dart",
            pending: 40,      
            channel: 'Flipkart'    },
        {
            customer: "Mamaearth",
            awb: "567587KYGYF76",
            courier: "Blue Dart",
            pending: 40,  
            channel: 'Amazon'     
        },

    ];

    const channelDetailsView = () =>{
        return(
            <div className='table-view-container' style={{ background: 'white', padding: 10, borderRadius: 6 }}>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    scroll={{ y: '490px' }}
                    pagination={false}
                />
            </div>
        )
    }

    const tableHeaderView = () => {
        return (
            <Row className='table-header'>
                <Col span={8}>
                    {AppConstants.gateInId}
                </Col>
                <Col span={8} className='text-center'>
                    {AppConstants.client}
                </Col>
                <Col span={8} className='text-center'>
                    {AppConstants.Courier}
                </Col>
                {/* <Col span={4} className=' d-flex jc-c'>
                    {AppConstants.courierService}
                </Col>
                <Col span={4}>
                    {AppConstants.gateInNumber}
                </Col>
                <Col span={4} className=' d-flex align-c jc-c'>
                    {AppConstants.createdtill}
                </Col>
                <Col span={4} className=' d-flex align-c jc-c'>
                    {AppConstants.receivedPackages}
                </Col>
                <Col span={4} className=' d-flex align-c jc-c'>
                    {AppConstants.processedPackages}
                </Col> */}
            </Row>
        )
    }

    const tableBodyView = (item: any, index: any) => {
        let open = index == expandRow;
        return (
            <div className={open ? "content-container1" : "content-container"} style={{ marginTop: 10 }}>
                <Row>
                    <Col span={8} className="open-icon d-flex align-c" 
                    onClick={(e: any) => handleExpand(e, index, item)}
                    >
                        <CaretRightOutlined
                            className={open ? "arrow arrow-down" : "arrow arrow-right"}
                        />
                        <div className='text'>
                            {item?.batchId}
                        </div>
                    </Col>
                    <Col span={8} className=' d-flex align-c jc-c'>
                        <div className='text-1'>
                           Mamaearth
                        </div>
                    </Col>
                    <Col span={8} className='d-flex align-c jc-c'>
                        <div className='text-1'>
                            Blue Dart
                        </div>
                    </Col>
                   {/*  <Col span={4} className=' d-flex align-c jc-c'>
                        43
                    </Col>
                    <Col span={4} className="d-flex align-c jc-c">
                        36
                    </Col>
                    <Col span={4} className="d-flex align-c jc-c">
                        40
                    </Col> */}
                    {open && <Col span={24} className="sku-details">
                        {channelDetailsView()}
                    </Col>}
                </Row>
            </div>
        )
    }

    const tableView = () => {
          return(
            <div className='table-screen-container' style={{marginTop: '60px'}}>
                {tableHeaderView()}
                {data?.map((x: any, index: any) => tableBodyView(x, index))}
            </div>
          )
    }

    const dataView = ()=>{
        return(
            <div>
                <SubHeader
                title={"Pending Manifest"}
                paginationView="false"
                inputView="true"
                btnView="false"
                >
                </SubHeader>
                {tableView()}
            </div>
            
        )
    } 

    return (
        <div>
            {dataView()}
        </div>
    );
}

export default NewManifestScreen;