import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, message, Row } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppConstants from '../../Globals/AppConstants';
import SubHeader from '../Header/Header';
import NavbarLayout from '../NavbarLayout/NavbarLayout';
import './GateIn.scss'
import DownloadIcon from '../../Images/DownloadIcon';
import { AppModuleIds } from '../../Globals/AppReferenceIds';
import { Amplify, Auth, Storage } from "aws-amplify";
import { generateGuId } from '../../Globals/Helper';
import { getUserInfo, getWareHouseId } from '../../Utils/SessionStorage';
import ImgModelView from '../ImgModelView';
import { useDispatch, useSelector } from 'react-redux';
import AppReferences from '../../Globals/AppReferences';
import { getAttachmentsAction } from '../../Store/Actions/ProcessWMSAction';
import SpinLoader from '../SpinLoader';

Amplify.configure({
    Auth: {
        identityPoolId: "ap-south-1:0fed2e35-15bf-46d3-b794-c938dc515509", //REQUIRED - Amazon Cognito Identity Pool ID
        region: "ap-south-1", // REQUIRED - Amazon Cognito Region
        userPoolId: "ap-south-1_wGjzBalCU", //OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: "1ptdtdunusepaj6440qh7c11b2", //OPTIONAL - Amazon Cognito Web Client ID 
    },
    Storage: {
        AWSS3: {
            bucket: process.env.REACT_APP_BUCKET_NAME, //REQUIRED -  Amazon S3 bucket name
            region: "ap-south-1", //OPTIONAL -  Amazon service region
        }
    }
});
function GateInEntryViewScreen() {
    const [uploadView, setUploadView] = useState(false);
    const location = useLocation();
    const warehouse = getWareHouseId();
    const { data, header } = location.state;
    const [openModal, setOpenModal] = useState<any>({
        isopen: false,
        url: ''
    });
    const dispatch = useDispatch();
    const reducerState = useSelector((state: any) => state.ProcessReducerState);
    const { attachementDetails } = reducerState;
    const [load, setLoad] = useState(false);

    useEffect(() => {
        getAttachemnts()
    }, []);

    const getAttachemnts = () => {
        let payload = {
            opnfor: AppReferences.opnfor.GateIn,
            activity: AppReferences.activities.a03,
            transaction: data?.TxnNo,
            where: data?.CustCode,
            warehouse: getWareHouseId()
        }
        dispatch(getAttachmentsAction(payload))
    }

    const uploadToAWS = async (file: any) => {
        try {
            let fileType = file?.type?.split('/');
            console.log("fileType",file)
            if (fileType[1] == 'jpeg' || fileType[1] == 'png') {
                setLoad(true)
                let randomGuid = generateGuId();
                let extension = file.name.substring(file.name.lastIndexOf('.'));
                let pathValue = `${data?.CustCode?.toLowerCase()}/${warehouse?.toLowerCase()}/${header?.stageNo}/${data?.TxnNo}/${randomGuid}${extension}`;
                var Params = {
                    resumable: true,
                    contentType: file?.type,
                    bucket: process.env.REACT_APP_BUCKET_NAME,
                    completeCallback: () => {
                        message.success(`Uploaded Successfully`);
                        getAttachemnts();
                        setLoad(false);
                    },
                    progressCallback: (progress: any) => {
                        console.log(`Uploaded: ${progress.loaded}/${progress.total} `)
                    },
                    errorCallback: (err: any) => {
                        console.log("Error while uploading ", err)
                    }
                };
                if (file.size <= 5000000) {
                    await Storage.put(pathValue, file, Params)
                } else {
                    message.error("File size should not exceed 5MB")
                }
            }
            else {
                message.error("Selected file type is not supported");
            }
            
        } catch (error) {
            console.log("Error uploading file: ", error);
        }
    }

    const openImageviewr = (url: any, index: any) => {
        let attachments = attachementDetails?.media?.objects;
        let startIndex = attachments?.slice(index, attachments.length);
        let restOfData = attachments?.slice(0, index);
        setOpenModal({ isOpen: true, urlArray: [...startIndex, ...restOfData], index: index });
    }

    const attachmentView = () => {
        try {
            let prefixUrl = attachementDetails?.media?.prefix;
            return (
                <div className='attachment-title-img-comtainer'>
                    <div className='attachment-title'>
                        {AppConstants.attachment}
                        {getUserInfo()?.roleId !== "14" &&
                            <Button className="add-btn" onClick={() => setUploadView(true)}>
                                <PlusOutlined />
                            </Button>
                        }
                    </div>
                    {uploadView === true &&
                        <div className='dragger-container' style={{ marginTop: 20 }}>
                            {uploadContainerView()}
                        </div>
                    }
                    <Row className='img-box'>
                        {attachementDetails?.media?.objects?.map((url: any, index: any) => {
                            return (
                                <Col className='img-comtainer' onClick={() => openImageviewr(prefixUrl + url, index)} style={{ cursor: "pointer" }}>
                                    <img src={prefixUrl + url} alt='Loading' />
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            )
        } catch (ex) {
            console.log("Error in AttachmentView::" + ex)
        }
    }
    const contentView = () => {
        try {
            return (
                <div className='content-containter'>
                    <div className='blue-box'></div>
                    <div className='entry-view-container'>
                        <Row>
                            <Col span={6}>
                                <div className='vehicle-detail-container'>
                                    <div className='vehicle-box'>
                                        <div className='vehicle-info-box1'>
                                            <div className='vehicle-info-title'>
                                                {AppConstants.client}
                                            </div>
                                            <div className='vehicle-info'>
                                                {data?.Customer}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div>
                                    <div className='vehicle-info-box1'>
                                        <div className='vehicle-info-title'>
                                            {AppConstants.inwardType}
                                        </div>
                                        <div className='vehicle-info'>
                                            {data?.InwardTxnType}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div>
                                    <div className='vehicle-info-box1'>
                                        <div className='vehicle-info-title'>
                                            {AppConstants.vehicleType}
                                        </div>
                                        <div className='vehicle-info'>
                                            {data?.Type}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div>
                                    <div className='vehicle-info-box1'>
                                        <div className='vehicle-info-title'>
                                            {AppConstants.vehicleNumber}
                                        </div>
                                        <div className='vehicle-info'>
                                            {data?.VehicleNumber}
                                        </div>
                                    </div>
                                    <div className='vehicle-info-box2'>
                                        {/* <div className='vehicle-info-title'>{AppConstants.noOfInvoices}</div>
                                        <div className='vehicle-info'>4</div> */}
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className='vehicle-detail-container'>
                                    <div className='vehicle-box'>
                                        <div className='vehicle-info-box1'>
                                            <div className='vehicle-info-title'>
                                                {AppConstants.sealNumber}
                                            </div>
                                            <div className='vehicle-info'>
                                                {data?.SealNo}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className='vehicle-detail-container'>
                                    <div className='vehicle-info-box1'>
                                        <div className='vehicle-info-title'>
                                            {AppConstants.docketNumber}
                                        </div>
                                        <div className='vehicle-info'>{data?.DocketNumber}</div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className='vehicle-detail-container'>
                                    <div className='vehicle-box'>
                                        <div className='vehicle-info-box1'>
                                            <div className='vehicle-info-title'>
                                                {AppConstants.docketStation}
                                            </div>
                                            <div className='vehicle-info'>
                                                {data?.DockStationName}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            )
        } catch (ex) {
            console.log("Error in ContentView::" + ex)
        }
    }

    const closeDragger = (e: any) => {
        e.stopPropagation();
        setUploadView(false)
    }

    const uploadContainerView = () => {
        try {
            return (
                <Dragger beforeUpload={(e) => {uploadToAWS(e); return false;}} accept='jpeg, png'>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <DownloadIcon />
                        <div className='dragger-content ' style={{ marginLeft: 5, textAlign: "left" }}>
                            <div className='drag-txt '>Drag & Drop files or <span className='browse-txt'>Browse</span></div>
                            <div className='note-content '>Supported Formates: JPEG,PNG</div>
                        </div>
                    </div>
                    <Button className="close-btn" onClick={(e) => closeDragger(e)}>
                        <CloseOutlined />
                    </Button>
                </Dragger>
            )
        } catch (ex) {
            console.log("Error in uploadContainerView::" + ex)
        }
    }


    const childrenView = () => {
        return (
            <div className="btn-container" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Link to="/gateinentries">
                    <Button className="close-btn">
                        <CloseOutlined />
                    </Button>
                </Link>
            </div>
        )
    }
    return (
        <>
            <NavbarLayout
                titleContent="sub-head"
                process={header}
                initial={AppModuleIds.gateIn}
                selectedGateIn={'1'}
            />
            <SubHeader
                title={<div>View GateIn <span>{data?.TxnNo}</span></div>}
                paginationView="false"
                inputView="false"
                btnView="false">
                {childrenView()}
            </SubHeader>
            <div className='gatein-entry-view-container'>
                {contentView()}
                {attachmentView()}
                <ImgModelView
                    open={openModal?.isOpen}
                    index={openModal?.index}
                    srcUrl={openModal?.url}
                    urlArray={openModal?.urlArray}
                    close={() => setOpenModal({ isOpen: false, urlArray: [] })}
                />
            </div>
            <SpinLoader loading={load} />
        </>
    );
}

export default GateInEntryViewScreen;