import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import AppButtons from '../../CustomComponent/AppButtons';
import NoData from '../../CustomComponent/NoData';
import AppConstants from '../../Globals/AppConstants';
import { checkHasValue, isArrayNotEmpty } from '../../Globals/Helper';

function SOModalView(props: any) {
    const {
        openSearch,
        close,
        data,
        soid,
        statusSaved,
        process,
        cancelSo
    } = props;

    let status = (data?.lineitems || []).map((item: any) => item?.Status)
    let time = (data?.lineitems || []).map((item: any) => item?.StatusChangedOn)

    const tableHeader = () => {
        return (
            <Row className='table-header'>
                <Col span={5}>
                    {AppConstants.salesOrder}
                </Col>
                <Col span={5}>
                    {AppConstants.referenceNumber}
                </Col>
                <Col span={4}>
                    {AppConstants.soType}
                </Col>
                <Col span={5}>
                    {AppConstants.crtDate}
                </Col>
                <Col span={5}>
                    {AppConstants.shipByDate}
                </Col>
            </Row>
        )
    }

    const listingView = (data: any) => {
        try {
            return (
                <div className={"content-container1"} style={{ marginTop: 10 }}>
                    <Row className='align-c'>
                        <Col span={5}>
                            <div className="title-container">
                                <div className='d-flex align-c'>
                                    <div
                                        className='text-2 overflow-ellipse font-16'
                                        title={data?.SOID}
                                        style={{ width: 150, marginLeft: 5 }}>
                                        {data?.id}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={5}>
                            <div className='text-2 font-16'>
                                {checkHasValue(data?.ReferenceId)}
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className='text-2 font-16'>
                                {checkHasValue(data?.SoType)}
                            </div>
                        </Col>
                        <Col span={5}>
                            <div className='text-2 font-16'>
                                {checkHasValue(data?.CreatedDate)}
                            </div>
                        </Col>
                        <Col span={5}>
                            <div className='text-2 font-16'>
                                {checkHasValue(data?.ShipByDate)}
                            </div>
                        </Col>
                    </Row>
                    <div style={{ marginTop: 10 }}>
                        {tableView(data?.Lines, data?.SOID)}
                    </div>
                </div >
            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const tableTitleContainer = () => {
        let saved = statusSaved == process;
        return (
            <Row>
                <Col span={6}>
                    <div className='redhat-bold font-16'>
                        {AppConstants.skuDescription}
                    </div>
                </Col>
                <Col span={saved ? 6 : 4} className='d-flex jc-c'>
                    <div className='redhat-bold font-16'>
                        {saved ? AppConstants.quality : AppConstants.orderedqty}
                    </div>
                </Col>
                <Col span={saved ? 6 : 4} className='d-flex jc-c'>
                    <div className='redhat-bold font-16'>
                        {saved ? AppConstants.shelfLife : AppConstants.fullfilled}
                    </div>
                </Col>
                {!saved &&
                    <Col span={4} className='d-flex jc-c'>
                        <div className='redhat-bold font-16'>
                            {AppConstants.Picked}
                        </div>
                    </Col>}
                {!saved &&
                    <Col span={3} className='d-flex jc-c'>
                        <div className='redhat-bold font-16'>
                            {AppConstants.packed}
                        </div>
                    </Col>}
                {!saved && <Col span={saved ? 6 : 3} className={'d-flex jc-c'}>
                    <div className='redhat-bold font-16'>
                        {AppConstants.balance}
                    </div>
                </Col>}
            </Row>
        )
    }

    const tableList = (data: any, index: any, size: any, soid: any) => {
        try {
            let saved = statusSaved == process;
            return (
                <div className="content-container" style={{ marginTop: 10 }}>
                    <Row>
                        <Col span={6} className='d-flex align-c'>
                            <div className='text font-16'>
                                {checkHasValue(data?.SKU)}
                            </div>
                        </Col>
                        <Col span={saved ? 6 : 4} className='d-flex align-c jc-c'>
                            <div className='text-1 font-16'>
                                {checkHasValue(saved ? data?.Quantity : data?.Ordered)}
                            </div>
                        </Col>
                        <Col span={saved ? 6 : 4} className='d-flex jc-c'>
                            <div className='text-1 font-16'>
                                {checkHasValue(saved ? data?.ExpectedShelfLife : data?.Allocated)}
                            </div>
                        </Col>
                        {!saved &&
                            <Col span={4} className='d-flex jc-c'>
                                <div className='redhat-bold font-16'>
                                    {checkHasValue(data?.Picked)}
                                </div>
                            </Col>}
                        {!saved &&
                            <Col span={3} className='d-flex jc-c'>
                                <div className='redhat-bold font-16'>
                                    {checkHasValue(data?.Packed)}
                                </div>
                            </Col>}
                        {!saved && <Col span={saved ? 6 : 3} className={!saved ? 'd-flex jc-c' : ''}>
                            <div className='redhat-bold font-16'>
                                {data?.Ordered - data?.Allocated}
                            </div>
                        </Col>}
                    </Row>
                </div >
            )
        } catch (error) {
            console.log("Error in tableList::", error)
        }
    }

    const tableView = (skuList: any, soid: any) => {
        try {
            let size = skuList?.length;
            return (
                <div style={{ background: "white", padding: 10, borderRadius: 6 }}>
                    {/* {detailsView(item)} */}
                    {skuList && <div>
                        <div className='header-part-2'>
                            {tableTitleContainer()}
                        </div>
                        <div >
                            {(skuList || []).map((data: any, index: any) =>
                                tableList(data, index, size, soid)
                            )}
                        </div>
                    </div>}
                </div>

            )
        } catch (ex) {
            console.log("Error in listingView::" + ex)
        }
    }

    const dataView = () => {
        return (
            <div>

                {isArrayNotEmpty(data?.lineitems) ?
                    <>
                        {tableHeader()}
                        <div>
                            {(data?.lineitems || []).map((item: any, index: Number) =>
                                listingView(item)
                            )}
                        </div>
                    </>
                    :
                    <div style={{ height: '40vh' }}>
                        <NoData
                            height={'30vh'}
                            description={`No Data available`}
                        />
                    </div>
                }
            </div>
        )
    }

    const searchModal = () => {
        return (
            <Modal
                title={
                    <div className='d-flex align-c jc-sb'>
                        <div className='redhat-bold mb-10 font-18'>
                            {soid?.searchSoid}
                            {isArrayNotEmpty(data?.lineitems) &&
                                <span style={{ marginLeft: 10 }}>Status : {status[0]} on {time[0]}</span>
                            }
                        </div>
                        <Button onClick={close}
                            className="round-cross-btn">
                            <CloseOutlined />
                        </Button>
                    </div>}
                className='search-modal'
                onCancel={close}
                // onOk={}
                open={openSearch}
                footer={null}
            >
                {dataView()}
            </Modal>
        )
    }

    const cancelModal = () => {
        // cancelSo
        return (
            <Modal
                title={
                    <div className='d-flex align-c jc-sb'>
                        <div className='redhat-bold mb-10 font-18'>
                            Cancel Order
                        </div>
                        <Button onClick={close}
                            className="round-cross-btn">
                            <CloseOutlined />
                        </Button>
                    </div>}
                className='modal-view'
                onCancel={close}
                // onOk={}
                open={soid?.isCancelSo}
                footer={null}
            >
                <div className='font-16 redhat-semibold'>
                    Do you want to cancel the order <b className='redhatd-bold'>{soid?.cancelSoData?.id}</b> ?
                </div>
                <div className='d-flex flex-end mt-10'>
                    <AppButtons
                        onClick={close}
                        buttonType={AppConstants.buttonTypes.whiteButton}
                        text="Cancel"
                    />
                    <AppButtons
                        onClick={cancelSo}
                        buttonType={AppConstants.buttonTypes.solidLinearGreen}
                        text="Confirm"
                    />
                </div>
            </Modal>
        )
    }

    return (
        <>
            {searchModal()}
            {cancelModal()}
        </>
    );
}

export default SOModalView;