import { Skeleton } from 'antd';
import React from 'react';

function SkeletonLoader(props: any) {
    let list = [1, 2, 3, 4, 5, 6, 7]
    return (
        <div>
            <div className='header-skeleton'>
                <Skeleton paragraph={{ rows: 0 }} active />
            </div>
            <div className='table-header margin'>
                <Skeleton paragraph={{ rows: 0 }} active />
            </div>
            {list?.map((item: any) =>
                <div className='list-skeleton'>
                    <Skeleton paragraph={{ rows: 0 }} active />
                </div>
            )}
        </div>
    )
}

export default SkeletonLoader;