import React from 'react';

function Array() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="23" viewBox="0 0 19 23" fill="none">
            <path d="M0.458008 22.8333H18.5413V20.25H0.458008V22.8333ZM18.5413 8.625H13.3747V0.875H5.62467V8.625H0.458008L9.49967 17.6667L18.5413 8.625Z" fill="#0A6F8B" />
        </svg>
    );
}

export default Array;