import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import GrnEmailView from '../GRN/GRN-Email/GrnEmailView';
import GRNPendingView from '../GRN/GRN-Pending/GRNPendingView';

import AssociatePo from '../QC/AssociatePo/AssociatePo';
import CustomDetailedQC from '../QC/CustomDetailedQC/CustomDetailedQC';
import GrnEmailListing from '../GRN/GRN-Email/GrnEmailListingScreen';
import AssociatePoListing from '../QC/AssociatePo/AssociatePoListing';
import CustomDetailedQCListing from '../QC/CustomDetailedQC/CustomDetailedQCListing';
import StartRecievingListing from '../QC/StartingRecieving/StartRecievingListing';
import InvoiceDetailsListingScreen from '../SAN/InvoiceDetails/InvoiceDetailsListingScreen';
import CaptureInvoiceDetails from '../SAN/InvoiceDetails/CaptureInvoiceDetails';
import UnloadingScreen from '../SAN/Unloading/UnloadingScreen';
import StickeringListing from '../SAN/Stickering/StickeringListing';
import SendSanListing from '../SAN/SendSan/SendSanListing';
import PreviewVan from '../GateIn/PreviewVan';
import GateInEntries from '../GateIn/GateInEntriesScreen';
import SendSANViewScreen from '../SAN/SendSan/SendSANViewScreen';
import QcInvoiceDetailsView from '../QC/InvoiceDetails/QcInvoiceDetailsView';
import GrnPendingListing from '../GRN/GRN-Pending/GrnListingScreen';
import PendingSANView from '../SAN/PendingSANView';
import SanIndex from '../SAN/SanIndex';
import QCIndex from '../QC/QCIndex';
import GRNIndex from '../GRN/GRNIndex';
import GateInEntryViewScreen from '../GateIn/GateInEntryViewScreen';
import PutAwayListing from '../PutAway/PutAwayListing';
import StartRecievingView from '../QC/StartingRecieving/StartRecievingView';
import PutAwayViewScreen from '../PutAway/PutAwayViewScreen';
import GateInAddScreen from '../GateIn/GateInAddScreen';
import StartRecievingDataView from '../QC/StartingRecieving/StartRecievingDataView';
import OrderProcessingIndex from '../OrderProcessing/OrderProcessingIndex';
import Login from '../Login/Login';
import PrivateRoute from './PrivateRoute';
import Processess from '../Process/Processes';
import PutAwayIndex from '../PutAway/PutAwayIndex';
import SKUDetailsView from '../SAN/InvoiceDetails/SKUDetailsView';
import InventoryManagemet from '../InventoryManagement/InventoryManagemet';
import CreatePO from '../QC/AssociatePo/CreatePO';
import InwardEmail from '../GRN/GRN-Email/InwardEmail';
import VerifiedScreen from '../OrderProcessing/VerifiedScreen';
import PicksReleasedScreen from '../OrderProcessing/PicksReleasedScreen';
import ScanningPending from '../OrderProcessing/ScanningPending';
import OnPackingStationScreen from '../OrderProcessing/OnPackingStationScreen';
import RTSListingScreen from '../OrderProcessing/RTS/RTSListingScreen';
import DispatchedScreen from '../OrderProcessing/DispatchedScreen';
import GateOutReady from '../OrderProcessing/GateOutReadyScreen';
import CreateManifestScreen from '../OrderProcessing/RTS/CreateManifestScreen';
import B2BVerifiedScreen from '../OrderProcessing/B2B/B2BVerifiedScreen';
import MismatchedCancelled from '../OrderProcessing/MismatchedCancelled';
import AdminExpense from '../AdminExpense/AdminExpense';
import NewManifestScreen from '../RTO/NewManifestScreen';
import RTOReturnScreen from '../RTO/RTOReturnIndex';
import CreateRTOManifest from '../RTO/CreateRTOManifest';
import MarkAsProcessed from '../RTO/MarkAsProcessed';
import Manifested from '../OrderProcessing/Manifested';
import StockMomentScreen from '../StockMovement/StockMomentScreen';
import SalesOrderScreen from '../SalesOrder/SalesOrderScreen';
import ContinuePutAway from '../PutAway/ContinuePutAway';
import WarehouseMaster from '../WarehouseMaster/WarehouseMaster';
import OnBoardingIndex from '../OnBoarding/OnBoardingIndex';
import CategoryListing from '../OnBoarding/CategoryListing';
import OnBoardingdashboard from '../OnBoarding/OnBoardingdashbord';
import SKUListing from '../OnBoarding/SKUListingScreen';
import ConfigureWarehouse from '../ConfigureWarehouse/ConfigureWarehouse';
import LastLiveScreen from '../OnBoarding/LastLiveScreen';
import BeingOnboarded from '../OnBoarding/BeingOnboarded';
import StickeringViewScreen from '../SAN/Stickering/StickeringViewScreen';
import CartonningScreen from '../OrderProcessing/CartonningScreen';
import ReportIndexScreen from '../Report/ReportIndexScreen';
import CustomStickering from '../CustomStickering/CustomStickering';
import PackagingMaterialScreen from '../PackagingMaterial/PackagingMaterialScreen';
import PutAway from '../PackagingMaterial/PutAway';
import PutAwayView from '../PackagingMaterial/PutAwayView';
import RTOB2B from '../RTO/RTOB2B';
import CycleCount from '../CycleCount/CycleCount';
import Kitting from '../Kitting/Kitting';
import SearchConsignment from '../RTO/SearchConsignment';
import RTOReturnB2CIndex from '../RTO/RTOB2C/RTOReturnB2CIndex';
import StartCycleCount from '../CycleCount/StartCycleCount';
import CycleCountIndex from '../CycleCount/CycleCountIndex';
import WarehouseManagerCycleCount from '../CycleCount/WarehouseManagerCycleCount';
import ClientScreen from '../OnBoarding/ClientScreen';

export default function Navigates() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <Navigate to={"process/SAN"} />
          } />
        <Route
          path='/login'
          element={
            <Login />
          } />
        <Route
          path="gateinentries"
          element={
            <PrivateRoute>
              <GateInEntries />
            </PrivateRoute>
          } />
        <Route
          path="reports"
          element={
            <PrivateRoute>
              <ReportIndexScreen />
            </PrivateRoute>
          } />
        <Route
          path="cycle-count-master"
          element={
            <PrivateRoute>
              <WarehouseManagerCycleCount />
            </PrivateRoute>
          } />
        <Route
          path="inventory-management"
          element={
            <PrivateRoute>
              <InventoryManagemet />
            </PrivateRoute>
          } />
        <Route
          path="admin-expense"
          element={
            <PrivateRoute>
              <AdminExpense />
            </PrivateRoute>
          } />
        <Route
          path="invoice-details"
          element={
            <PrivateRoute>
              <InvoiceDetailsListingScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="previewvan"
          element={
            <PrivateRoute>
              <PreviewVan />
            </PrivateRoute>
          }
        />
        <Route
          path="captureinvoicedetails"
          element={
            <PrivateRoute>
              <CaptureInvoiceDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="associatepolisting"
          element={
            <PrivateRoute>
              <AssociatePoListing />
            </PrivateRoute>
          }
        />

        {/* <Route
          path="cartonning"
          element={
            <PrivateRoute>
              <CartonningScreen />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="packagin-material"
          element={
            <PrivateRoute>
              <PackagingMaterialScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="packing-putaway"
          element={
            <PrivateRoute>
              <PutAway />
            </PrivateRoute>
          }
        />
        <Route
          path="packing-putaway-view"
          element={
            <PrivateRoute>
              <PutAwayView />
            </PrivateRoute>
          }
        />
        <Route
          path="process"
          element={
            <PrivateRoute>
              <Processess />
            </PrivateRoute>
          }
        >
          <Route
            path="SAN"
            element={
              <PrivateRoute>
                <SanIndex />
              </PrivateRoute>
            }
          />
          <Route
            path="QC"
            element={
              <PrivateRoute>
                <QCIndex />
              </PrivateRoute>
            }
          />
          <Route
            path="GRN"
            element={
              <PrivateRoute>
                <GRNIndex />
              </PrivateRoute>
            }
          />
          <Route
            path="put-away"
            element={
              <PrivateRoute>
                <PutAwayIndex />
              </PrivateRoute>
            }
          />
          <Route
            path="custom-stickering"
            element={
              <PrivateRoute>
                <CustomStickering />
              </PrivateRoute>
            }
          />
          <Route
            path="kitting"
            element={
              <PrivateRoute>
                <Kitting />
              </PrivateRoute>
            }
          />
          <Route
            path="rto-return"
            element={
              <PrivateRoute>
                <RTOReturnScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="rto-b2b"
            element={
              <PrivateRoute>
                <RTOB2B />
              </PrivateRoute>
            }
          />
          <Route
            path="rto-b2C"
            element={
              <PrivateRoute>
                <RTOReturnB2CIndex />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path="associatepo"
          element={
            <PrivateRoute>
              <AssociatePo />
            </PrivateRoute>
          }
        />

        <Route
          path="customdetailedqc"
          element={
            <PrivateRoute>
              <CustomDetailedQC />
            </PrivateRoute>
          }
        />
        <Route
          path="startrecievinglisting"
          element={
            <PrivateRoute>
              <StartRecievingListing />
            </PrivateRoute>
          }
        />
        <Route
          path="put-away-view"
          element={
            <PrivateRoute>
              <PutAwayViewScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="continue-putaway"
          element={
            <PrivateRoute>
              <ContinuePutAway />
            </PrivateRoute>
          }
        />
        <Route
          path="grnpendinglisting"
          element={
            <PrivateRoute>
              <GrnPendingListing />
            </PrivateRoute>
          }
        />
        <Route
          path="grn-pending"
          element={
            <PrivateRoute>
              <GRNPendingView />
            </PrivateRoute>
          }
        />
        <Route
          path="grnemaillisting"
          element={
            <PrivateRoute>
              <GrnEmailListing />
            </PrivateRoute>
          }
        />
        <Route
          path="unloading"
          element={
            <PrivateRoute>
              <UnloadingScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="stickering"
          element={
            <PrivateRoute>
              <StickeringListing />
            </PrivateRoute>
          }
        />
        <Route
          path="send-san"
          element={
            <SendSanListing />
          }
        />
        <Route
          path="stickering-details"
          element={
            <PrivateRoute>
              <StickeringViewScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="qc-invoice"
          element={
            <PrivateRoute>
              <QcInvoiceDetailsView />
            </PrivateRoute>
          }
        />
        <Route
          path="gatein-entry-view"
          element={
            <PrivateRoute>
              <GateInEntryViewScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="start-recieving-view"
          element={
            <StartRecievingView />
          }
        />
        <Route
          path="gate-in-add"
          element={
            <PrivateRoute>
              <GateInAddScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="start-recieving-data-view"
          element={
            <PrivateRoute>
              <StartRecievingDataView />
            </PrivateRoute>
          }
        />
        <Route
          path="pending-san"
          element={
            <PrivateRoute>
              <PendingSANView />
            </PrivateRoute>
          }
        />
        <Route
          path="order-processing"
          element={
            <PrivateRoute>
              <OrderProcessingIndex />
            </PrivateRoute>
          }
        >
          <Route
            path="cartonning"
            element={
              <PrivateRoute>
                <CartonningScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="verified"
            element={
              <PrivateRoute>
                <VerifiedScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="picks-released"
            element={
              <PrivateRoute>
                <PicksReleasedScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="scanning-pending"
            element={
              <PrivateRoute>
                <ScanningPending />
              </PrivateRoute>
            }
          />
          <Route
            path="onpackingstation"
            element={
              <PrivateRoute>
                <OnPackingStationScreen />
              </PrivateRoute>
            }
          />

          <Route
            path="rts"
            element={
              <PrivateRoute>
                <RTSListingScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="rts-createmanifest"
            element={
              <PrivateRoute>
                <CreateManifestScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="manifested"
            element={
              <PrivateRoute>
                <Manifested />
              </PrivateRoute>
            }
          />
          <Route
            path="gate-out-ready"
            element={
              <PrivateRoute>
                <GateOutReady />
              </PrivateRoute>
            }
          />
          <Route
            path="dispatched"
            element={
              <PrivateRoute>
                <DispatchedScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="sku-mismatched"
            element={
              <PrivateRoute>
                <MismatchedCancelled />
              </PrivateRoute>
            }
          />
          <Route
            path="non-servicable"
            element={
              <PrivateRoute>
                <MismatchedCancelled />
              </PrivateRoute>
            }
          />
          <Route
            path="no-inventory"
            element={
              <PrivateRoute>
                <MismatchedCancelled />
              </PrivateRoute>
            }
          />
          <Route
            path="cancelled"
            element={
              <PrivateRoute>
                <MismatchedCancelled />
              </PrivateRoute>
            }
          />
          <Route
            path="b2b-verified"
            element={
              <PrivateRoute>
                <B2BVerifiedScreen />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path="sku-details"
          element={
            <PrivateRoute>
              <SKUDetailsView />
            </PrivateRoute>
          }
        />
        <Route
          path="create-po"
          element={
            <PrivateRoute>
              <CreatePO />
            </PrivateRoute>
          }
        />
        <Route
          path="inward-email"
          element={
            <PrivateRoute>
              <InwardEmail />
            </PrivateRoute>
          }
        />
        <Route
          path="stockmoment"
          element={
            <PrivateRoute>
              <StockMomentScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="sales-order"
          element={
            <PrivateRoute>
              <SalesOrderScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="warehouse-master"
          element={
            <PrivateRoute>
              <WarehouseMaster />
            </PrivateRoute>
          }
        />
        <Route
          path="configure-warehouse"
          element={
            <PrivateRoute>
              <ConfigureWarehouse />
            </PrivateRoute>
          }
        />
        <Route
          path="on-boarding"
          element={
            <PrivateRoute>
              <OnBoardingdashboard />
            </PrivateRoute>
          }
        >
          <Route
            path="dashboard"
            element={
              <PrivateRoute>
                <BeingOnboarded />
              </PrivateRoute>
            }
          />
          <Route
            path="client"
            element={
              <PrivateRoute>
                <ClientScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="last-lives"
            element={
              <PrivateRoute>
                <LastLiveScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="overall-lives"
            element={
              <PrivateRoute>
                <LastLiveScreen />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path="on-boarding"
          element={
            <PrivateRoute>
              <OnBoardingIndex />
            </PrivateRoute>
          }
        >
          <Route
            path="categories"
            element={
              <PrivateRoute>
                <CategoryListing />
              </PrivateRoute>
            }
          />
          <Route
            path="SKUs"
            element={
              <PrivateRoute>
                <SKUListing />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path="cycle-count"
          element={
            <PrivateRoute>
              <CycleCountIndex />
            </PrivateRoute>
          }
        />
        <Route
          path="start-cycle-count"
          element={
            <PrivateRoute>
              <StartCycleCount />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}