import React from 'react';

function GrnIcon() {
    return (
        <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8334 18.3334H4.16669C3.50365 18.3334 2.86776 18.07 2.39892 17.6012C1.93008 17.1323 1.66669 16.4965 1.66669 15.8334V2.50008C1.66669 2.27907 1.75448 2.06711 1.91076 1.91083C2.06704 1.75455 2.27901 1.66675 2.50002 1.66675H14.1667C14.3877 1.66675 14.5997 1.75455 14.7559 1.91083C14.9122 2.06711 15 2.27907 15 2.50008V12.5001H18.3334V15.8334C18.3334 16.4965 18.07 17.1323 17.6011 17.6012C17.1323 18.07 16.4964 18.3334 15.8334 18.3334ZM15 14.1667V15.8334C15 16.0544 15.0878 16.2664 15.2441 16.4227C15.4004 16.5789 15.6123 16.6667 15.8334 16.6667C16.0544 16.6667 16.2663 16.5789 16.4226 16.4227C16.5789 16.2664 16.6667 16.0544 16.6667 15.8334V14.1667H15ZM5.00002 5.83341V7.50008H11.6667V5.83341H5.00002ZM5.00002 9.16675V10.8334H11.6667V9.16675H5.00002ZM5.00002 12.5001V14.1667H9.16669V12.5001H5.00002Z" fill="white" />
        </svg>
    );
}

export default GrnIcon;

