

import { DualAxes } from '@ant-design/charts';
import React from 'react';

function AnalyticsOfOverAllOrderChartComponent() {
    const data = [ 
        {
            time:  'Nov',
            value: 40700,
            count: 30200,
        },
        {
            time:  'Dec',
            value: 40700,
            count: 20200,
        },
        {
            time: 'Jan',
            value: 35000,
            count: 30000,
        },
        {
            time: 'Feb',
            value: 90000,
            count: 600,
        },
        {
            time:'Mar',
            value: 30000,
            count: 40000,
        },
        {
            time: 'Apr',
            value: 40050,
            count: 30080,
        },
        {
            time: 'May',
            value: 47000,
            count: 22000,
        },
        {
            time: 'June',
            value: 40070,
            count: 30000,
        },
        {
            time: 'July',
            value: 40700,
            count: 62990,
        },
        {
            time:  'Aug',
            value: 47000,
            count: 90020,
        },
        {
            time: 'Sept',
            value: 40700,
            count: 52000,
        },
        {
            time: 'Oct',
            value: 40700,
            count: 50200,
        },
       
    ];
    const cfg: any = {
        data: [data, data],
        xField: 'time',
        yField: ['value', 'count'],
        // yAxis: {
        //     value: [0,"1k","5k","10k","15k"]
        // },
        legend: false,
        geometryOptions: [
            {
                geometry: 'column',
                color: '#2A64F8',
                columnWidthRatio: 0.4,
                label: false,
            },
            {
                geometry: 'line',
                smooth: true,
                color: '#4FE3ED',
            },
        ],
        interactions: [
            {
                type: 'element-highlight',
            },
            {
                type: 'active-region',
            },
        ],
        annotations: {
            // value: [
            //     {
            //         type: 'text',
            //         position: ['2019-06', 'max'],
            //     },
            // ],
            // count: [
            //     {
            //         type: 'dataMarker',
            //         top: true,
            //         position: ['2019-05', 400],
            //         text: {
            //             content: '2019-05',
            //             style: {
            //                 textAlign: 'left',
            //             },
            //         },
            //     },
            // ],
        },
    };
    const config = cfg;
    return <DualAxes {...config} />;
};

export default AnalyticsOfOverAllOrderChartComponent;